import { ErrorToJson, GenericError, ResponseData } from 'elements/api/types';

export class CustomError extends Error {
  status: number;

  message: string;

  responseData?: ResponseData;

  constructor(error: GenericError) {
    super();
    if (error.response) {
      this.responseData = error.response?.data as ResponseData;
    }

    const errorToJson = error.toJSON() as ErrorToJson;
    this.status = errorToJson.status;
    this.message = errorToJson.message;
  }
}
