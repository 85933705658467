import {
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@mui/material';
import { pxToRem } from 'utils/styling-utils';

export const StyledDialog = styled(Dialog)(() => ({
  [`& .${dialogClasses.paper}`]: {
    width: pxToRem(576),
    height: pxToRem(375),
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: `${pxToRem(theme.gaps._40px)} ${pxToRem(theme.gaps._40px)} 0`,
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: pxToRem(theme.gaps._20px),
  right: pxToRem(theme.gaps._20px),
  height: pxToRem(36),
  width: pxToRem(36),
  padding: 0,
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: `0 ${pxToRem(theme.gaps._40px)}`,
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.overlineDefaultBold,
  color: theme.palette.grey[500],
  textTransform: 'uppercase',
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  ...theme.typography.bodyDefaultBook,
  width: '100%',
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  ...theme.typography.bodyDefaultBook,
  padding: pxToRem(theme.gaps._12px),
  // backgroundColor: theme.palette.secondary.light2,
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: `${pxToRem(theme.gaps._24px)} ${pxToRem(theme.gaps._40px)}`,
}));
