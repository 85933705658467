import { Box, Typography } from '@mui/material';
import { css } from '@mui/material/styles';
import { Stack } from 'elements/components';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { preventForwardProps } from 'elements/utils';

export const StyledContainer = elementsStyled(
  Stack,
  preventForwardProps(['isCustodialAccountIcon']),
)<{ isCustodialAccountIcon?: boolean }>(
  ({ theme, isCustodialAccountIcon }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12.5px 12px;
    height: auto;
    border: 1px solid ${theme.palette.tertiary.main};
    border-radius: ${theme.borderRadius};
    & svg {
      flex-shrink: 0;
      color: ${isCustodialAccountIcon ? theme.palette.secondary.main : theme.palette.primary.font};
    }
  `,
);

export const StyledTextContainer = elementsStyled(Stack)`
  margin-left: 12px;
  display: flex;
  flex-wrap: wrap;
`;

export const StyledTypography = elementsStyled(Typography)`
  margin-top: 6px;
  overflow-wrap: anywhere;
`;

export const StyledIconBackground = elementsStyled(
  Box,
  preventForwardProps(['isCustodialAccountIcon']),
)<{ isCustodialAccountIcon?: boolean }>(
  ({ theme, isCustodialAccountIcon }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    min-width: 40px;
    background-color: ${isCustodialAccountIcon
      ? theme.palette.secondary.mainTransparent10
      : theme.palette.tertiary.mainLighten40};
    border-radius: 8px;
  `,
);
