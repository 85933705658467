import { elementsStyled } from 'elements/theme/createCustomTheme';
import SourceDestinationComponent from '../SourceDestinationComponent';

export const StyledSourceDestinationComponent = elementsStyled(SourceDestinationComponent)`
  margin: 40px 0 24px;
`;

export const StyledDestinationTagComponent = elementsStyled(SourceDestinationComponent)`
  margin: 0 0 24px;
`;
