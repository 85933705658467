import { BuyCoin, SellCoin, TradeFlow } from 'elements/element-trade/types';
import { CustodialAccount, ExternalAccount, PaymentAccount } from 'elements/types';
import { Paths } from 'elements/element-trade/navigation/routes';
import { PaymentType } from 'ui-enums/response/transactions/payment-type';
import { AssetResponseModel } from 'models/response/assets/asset-response-model';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import { TradingPriceResponseModel } from 'models/response/trades/trading-price-response-model';
import { ACTION, ActionType, StateType } from './types';

const setElementStarted = (): ActionType => ({
  type: ACTION.SET_ELEMENT_STARTED,
});

const setFlow = (value: TradeFlow): ActionType => ({
  type: ACTION.SET_FLOW,
  payload: value,
});

const setCoinsList = (list: BuyCoin[] | SellCoin[], flowType: TradeFlow): ActionType => ({
  type: ACTION.SET_COINS_LIST,
  payload: { list, flowType },
});

const setBuySellCoinsList = (
  list: AssetResponseModel[] | CustodialAccountBalanceModel[],
  flowType: TradeFlow,
): ActionType => ({
  type: ACTION.SET_BUY_SELL_COINS_LIST,
  payload: { list, flowType },
});

const setSelectedCoin = (coin: BuyCoin | SellCoin | null, flowType?: TradeFlow): ActionType => ({
  type: ACTION.SET_SELECTED_COIN,
  payload: { coin, flowType },
});

const setBuySellSelectedCoin = (
  coin: AssetResponseModel | CustodialAccountBalanceModel | null,
  flowType?: TradeFlow,
): ActionType => ({
  type: ACTION.SET_BUY_SELL_SELECTED_COIN,
  payload: { coin, flowType },
});

const setAmount = (value: string): ActionType => ({
  type: ACTION.SET_AMOUNT,
  payload: value,
});

const setCoinPrice = (coinPrice: TradingPriceResponseModel): ActionType => ({
  type: ACTION.SET_COIN_PRICE,
  payload: coinPrice,
});

const setNote = (note: string): ActionType => ({
  type: ACTION.SET_NOTE,
  payload: note,
});

const setIsOrderSucceed = (value: boolean): ActionType => ({
  type: ACTION.SET_IS_ORDER_SUCCEED,
  payload: value,
});

const setOrderFailed = (isFailed: boolean, message?: string, traceId?: string): ActionType => ({
  type: ACTION.SET_IS_ORDER_FAILED,
  payload: { isFailed, message, traceId },
});

const setCustodialAccountList = (value: CustodialAccount[]): ActionType => ({
  type: ACTION.SET_CUSTODIAL_ACCOUNTS,
  payload: value,
});

const setExternalAccountList = (value: ExternalAccount[]): ActionType => ({
  type: ACTION.SET_EXTERNAL_ACCOUNTS,
  payload: value,
});

const setPaymentAccount = (value: PaymentAccount): ActionType => ({
  type: ACTION.SET_PAYMENT_ACCOUNT,
  payload: value,
});

const setPaymentType = (value: PaymentType): ActionType => ({
  type: ACTION.SET_PAYMENT_TYPE,
  payload: value,
});

const setShouldPriceReload = (value: boolean): ActionType => ({
  type: ACTION.SET_SHOULD_PRICE_RELOAD,
  payload: value,
});

const setSellPaymentId = (value: string): ActionType => ({
  type: ACTION.SET_SELL_PAYMENT_ID,
  payload: value,
});
const setBuyTradePaymentId = (value: string): ActionType => ({
  type: ACTION.SET_BUY_TRADE_PAYMENT_ID,
  payload: value,
});
const setBuyDepositPaymentId = (value: string): ActionType => ({
  type: ACTION.SET_BUY_DEPOSIT_PAYMENT_ID,
  payload: value,
});

const setCustodialAccountId = (value: string): ActionType => ({
  type: ACTION.SET_CUSTODIAL_ACCOUNT_ID,
  payload: value,
});

const setIsCoinPriceError = (value: boolean): ActionType => ({
  type: ACTION.SET_IS_COIN_PRICE_ERROR,
  payload: value,
});

const setIsServiceUnavailable = (value: boolean): ActionType => ({
  type: ACTION.SET_IS_SERVICE_UNAVAILABLE,
  payload: value,
});

const setPage = (location: Paths, state?: any): ActionType => ({
  type: ACTION.SET_PAGE,
  payload: {
    location,
    state: state || null,
  },
});

const setLockId = (lockId: string): ActionType => ({
  type: ACTION.SET_LOCK_ID,
  payload: lockId,
});

export const elementReducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case ACTION.SET_ELEMENT_STARTED: {
      return {
        ...state,
        isElementStarted: true,
      };
    }
    case ACTION.SET_FLOW: {
      return {
        ...state,
        flowType: action.payload,
      };
    }
    case ACTION.SET_COINS_LIST: {
      return {
        ...state,
        [action.payload.flowType]: {
          ...state[action.payload.flowType],
          coinsList: action.payload.list,
        },
      };
    }
    case ACTION.SET_BUY_SELL_COINS_LIST: {
      return {
        ...state,
        [action.payload.flowType]: {
          ...state[action.payload.flowType],
          coinsBuySellList: action.payload.list,
        },
      };
    }
    case ACTION.SET_SELECTED_COIN: {
      return {
        ...state,
        [action.payload.flowType || state.flowType]: {
          ...state[action.payload.flowType || state.flowType],
          selectedCoin: action.payload.coin,
        },
      };
    }
    case ACTION.SET_BUY_SELL_SELECTED_COIN: {
      return {
        ...state,
        [action.payload.flowType || state.flowType]: {
          ...state[action.payload.flowType || state.flowType],
          selectedBuySellCoin: action.payload.coin,
        },
      };
    }
    case ACTION.SET_AMOUNT: {
      return {
        ...state,
        [state.flowType]: {
          ...state[state.flowType],
          amount: action.payload,
        },
      };
    }
    case ACTION.SET_COIN_PRICE: {
      return {
        ...state,
        buyFlow: {
          ...state[TradeFlow.Buy],
          coinPrice: action.payload.price.buy,
        },
        sellFlow: {
          ...state[TradeFlow.Sell],
          coinPrice: action.payload.price.sell,
        },
      };
    }
    case ACTION.SET_NOTE: {
      return {
        ...state,
        [state.flowType]: {
          ...state[state.flowType],
          note: action.payload,
        },
      };
    }
    case ACTION.SET_IS_ORDER_SUCCEED: {
      return {
        ...state,
        isOrderSucceed: action.payload,
        failedOrderMessage: '',
        isOrderFailed: false,
      };
    }
    case ACTION.SET_IS_ORDER_FAILED: {
      return {
        ...state,
        isOrderFailed: action.payload.isFailed,
        failedOrderMessage: action.payload.message || '',
        failedOrderTraceId: action.payload.traceId || '',
        isOrderSucceed: false,
      };
    }
    case ACTION.SET_CUSTODIAL_ACCOUNTS: {
      return {
        ...state,
        custodialAccountList: action.payload,
      };
    }
    case ACTION.SET_EXTERNAL_ACCOUNTS: {
      return {
        ...state,
        externalAccountList: action.payload,
      };
    }
    case ACTION.SET_PAYMENT_ACCOUNT: {
      return {
        ...state,
        paymentAccount: action.payload,
      };
    }
    case ACTION.SET_PAYMENT_TYPE: {
      return {
        ...state,
        paymentType: action.payload,
      };
    }
    case ACTION.SET_SHOULD_PRICE_RELOAD: {
      return {
        ...state,
        shouldPriceReload: action.payload,
      };
    }
    case ACTION.SET_IS_COIN_PRICE_ERROR: {
      return {
        ...state,
        coinPriceError: action.payload,
      };
    }
    case ACTION.SET_SELL_PAYMENT_ID: {
      return {
        ...state,
        sellPaymentId: action.payload,
      };
    }
    case ACTION.SET_BUY_TRADE_PAYMENT_ID: {
      return {
        ...state,
        buyTradePaymentId: action.payload,
      };
    }
    case ACTION.SET_BUY_DEPOSIT_PAYMENT_ID: {
      return {
        ...state,
        buyDepositPaymentId: action.payload,
      };
    }

    case ACTION.SET_CUSTODIAL_ACCOUNT_ID: {
      return {
        ...state,
        sellFlow: {
          ...state[TradeFlow.Sell],
          custodialAccountId: action.payload,
        },
      };
    }

    case ACTION.SET_IS_SERVICE_UNAVAILABLE: {
      return {
        ...state,
        isServiceUnavailable: action.payload,
      };
    }

    case ACTION.SET_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }

    case ACTION.SET_LOCK_ID: {
      return {
        ...state,
        lockId: action.payload,
      };
    }

    default: {
      throw new Error('Unknown action.');
    }
  }
};

export {
  setElementStarted,
  setFlow,
  setCoinsList,
  setBuySellCoinsList,
  setSelectedCoin,
  setBuySellSelectedCoin,
  setAmount,
  setCoinPrice,
  setNote,
  setIsOrderSucceed,
  setOrderFailed,
  setCustodialAccountList,
  setExternalAccountList,
  setPaymentAccount,
  setShouldPriceReload,
  setSellPaymentId,
  setBuyTradePaymentId,
  setBuyDepositPaymentId,
  setCustodialAccountId,
  setIsCoinPriceError,
  setIsServiceUnavailable,
  setPage,
  setLockId,
  setPaymentType,
};
