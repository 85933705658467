import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';
import { Gap } from 'components/shared/Gap';

export const StyledBox = styled(Box)(({ theme }) => ({
  paddingTop: pxToRem(theme.gaps._32px),
  paddingBottom: pxToRem(theme.gaps._8px),
}));

export const GrayGap = styled(Gap)(({ theme }) => ({
  backgroundColor: theme.palette.grey['100'],
  marginLeft: pxToRem(-theme.gaps._24px),
  marginRight: pxToRem(-theme.gaps._24px),
}));
