import { Typography } from '@mui/material';
import { Theme, css } from '@mui/material/styles';
import { Stack } from 'elements/components';
import { preventForwardProps } from 'elements/utils';
import { Link } from 'elements/components/Link/index';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { BannerType } from './types';

const applyStylesToBannerType = (theme: Theme) => ({
  [BannerType.Error]: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.light,
  },
  [BannerType.Info]: {
    color: theme.palette.info.dark,
    backgroundColor: theme.palette.info.light,
  },
  [BannerType.Warning]: {
    color: theme.palette.warning.main,
    backgroundColor: theme.palette.warning.light,
  },
});

export const StyledStack = elementsStyled(
  Stack,
  preventForwardProps(['type']),
)<{ type: BannerType }>(({ theme, type }) => {
  const { color, backgroundColor } = applyStylesToBannerType(theme)[type];

  return css`
    background-color: ${backgroundColor};
    padding: 12px 16px;
    color: ${color};
    border-radius: 8px;

    & svg {
      flex-shrink: 0;
    }
  `;
});

export const StyledTypography = elementsStyled(Typography)`
  margin: 0 3px;
  letter-spacing: -0.02em;
`;

export const StyledLink = elementsStyled(
  Link,
  preventForwardProps(['bannerType']),
)<{ bannerType: BannerType }>(
  ({ theme, bannerType }) => css`
    color: ${applyStylesToBannerType(theme)[bannerType].color};

    &:visited {
      color: ${applyStylesToBannerType(theme)[bannerType].color};
    }
  `,
);
