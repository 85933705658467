import React from 'react';
import { Typography } from '@mui/material';
import { RightCellBox } from 'components/shared/detail-page/rows/shared/RightCellBox';
import { Gap } from 'components/shared/Gap';
import { getCardTypeIcon } from './utils/getCardTypeIcon';

type Props = {
  cardNetwork?: string;
  value: string;
};

export const RightCell: React.FC<Props> = ({ cardNetwork, value }) => {
  return (
    <RightCellBox>
      {getCardTypeIcon(cardNetwork)}
      <Gap size="_8px" isHorizontal />
      <Typography color="grey.600" variant="bodyDefaultBook">
        {value}
      </Typography>
    </RightCellBox>
  );
};
