import { TradeDirectionEnum, TradeFlow } from 'elements/element-trade/types';
import { getPaymentMethodTypesMap } from 'elements/features/PaymentMethods';
import { Paths } from 'elements/element-trade/navigation/routes';

const orderDetails = {
  amount: '',
  selectedCoin: null,
  selectedBuySellCoin: null,
  coinsList: [],
  coinsBuySellList: [],
  coinPrice: 0,
  note: '',
};

export const elementInitialState = {
  isElementStarted: false,
  isOrderSucceed: false,
  isOrderFailed: false,
  failedOrderMessage: '',
  failedOrderTraceId: '',
  shouldPriceReload: true,
  coinPriceError: false,

  tradeDirection: TradeDirectionEnum.BuySell,
  flowType: TradeFlow.Buy,

  [TradeFlow.Buy]: orderDetails,
  [TradeFlow.Sell]: { ...orderDetails, custodialAccountId: undefined },

  custodialAccountList: [],
  externalAccountList: [],
  paymentAccount: undefined,
  sellPaymentId: null,
  buyTradePaymentId: null,
  buyDepositPaymentId: null,
  paymentMethodTypesMap: getPaymentMethodTypesMap(),
  cryptoAssetNetwork: '',
  contactUsUrl: '',
  serviceUnavailable: false,
  custodialAccountId: '',
  identityId: '',
  lockId: '',
  paymentType: null,
  page: { location: Paths.Root, state: null },
};
