import { useState, useEffect } from 'react';
import { DocumentsResponse } from 'redux/api/types';
import { Document } from 'ui-interfaces/identity-details/document-list/document';
import { DocumentViewModel } from 'view-models/identities/details/document-view-model';

export const useTransformDocuments = (response: DocumentsResponse | undefined): Document[] => {
  const [documents, setDocuments] = useState<Document[]>([]);

  useEffect(() => {
    if (response) {
      const newDocs: Document[] = [];
      response.data.map(doc => {
        return doc.sides.map(side => {
          return newDocs.push(new DocumentViewModel(doc, side));
        });
      });
      setDocuments(newDocs);
    }
  }, [response]);
  return documents;
};
