import React from 'react';
import { Typography } from '@mui/material';
import {
  StyledCoinContainer,
  StyledContainer,
  StyledMessageIcon,
  StyledNoteIcon,
  StyledPre,
  StyledTextContainer,
} from './styled';

type Props = {
  note: string;
  title: string;
  withMessageIcon?: boolean;
};

const NoteReview: React.FC<Props> = ({ note, title, withMessageIcon }) => (
  <StyledContainer>
    <StyledCoinContainer>{withMessageIcon ? <StyledMessageIcon /> : <StyledNoteIcon />}</StyledCoinContainer>
    <StyledTextContainer>
      <Typography variant="captionMedium" color="secondary.font">
        {title}
      </Typography>
      <Typography variant="bodyDefaultBook" color="primary.font">
        <StyledPre>{note}</StyledPre>
      </Typography>
    </StyledTextContainer>
  </StyledContainer>
);

export default NoteReview;
