import { IRoleTemplatesInputs } from 'ui-interfaces/user-form/i-role-templates-inputs';
import { ILimitInputCollection } from 'ui-interfaces/user-form/i-limit-input-collection';
import { IPermissionsCheckbox } from 'ui-interfaces/user-form/i-permissions-checkbox';
import { IRoleRadio } from 'ui-interfaces/user-form/i-role-radio';
import { UserRoleTemplateResponseModel } from 'models/response/users/user-role-template-response-model';
import { UserDetailResponseModel } from 'models/response/users/user-detail-response-model';
import { LimitInputCollectionType } from 'ui-enums/create-user-form/limit-input-collection-type';
import { generateRoles } from 'utils/user-form/generate-roles';
import { LimitInputCollection } from 'view-models/user-form/limits/limit-input-collection';
import { DashboardRoles } from 'models/enums/user/dashboard-roles';
import { generatePermissions } from 'utils/user-form/generate-permissions';
import { getSelectedPermissionsIndexes } from 'utils/user-form/get-selected-permissions-indexes';
import { UserLimitModel } from 'models/response/users/user-limit-model';
import { generatePermissionsFromUserDetailResponseModel } from 'utils/user-form/generate-permissions-from-user-detail-response-model';
import { getSelectedPermissionsIndexesFromUserDetailResponseModel } from 'utils/user-form/get-selected-permissions-indexes-from-user-detail-response-model';
import { getSelectedUserRoleTemplateIndexFromUserDetailResponseModel } from 'utils/user-form/get-selected-user-role-template-index-from-user-detail-response-model';
import { UpdateUserDetailRequestModel } from 'models/request/users/update-user-detail-request-model';
import { groupBy } from 'utils/group-by';
import { mapUserLimitTypeToLimitInputCollectionType } from 'utils/user-form/map-user-limit-type-to-limit-input-collection-type';
import { ILimitInput } from 'ui-interfaces/user-form/i-limit-input';

export class EditUserFormRoleTemplatesViewModel
  implements IRoleTemplatesInputs<Pick<UpdateUserDetailRequestModel, 'roleTemplate' | 'permissions' | 'limits'>>
{
  readonly userRoleTemplates: UserRoleTemplateResponseModel[];

  private readonly userDetailResponseModel: UserDetailResponseModel;

  private _selectedUserRoleTemplateIndex: number;

  private readonly selectedUserRoleTemplateIndexInitialValue: number;

  private readonly _roles: IRoleRadio[];

  private _permissions: IPermissionsCheckbox[];

  private readonly permissionsInitialValue: IPermissionsCheckbox[];

  private _checkedPermissions: Set<number>;

  private readonly checkedPermissionsInitialValue: Array<number>;

  private readonly _approveLimitsCollection: ILimitInputCollection;

  private readonly approveLimitsCollectionItemsInitialValue: ILimitInput[];

  private readonly _initLimitsCollection: ILimitInputCollection;

  private readonly initLimitsCollectionItemsInitialValue: ILimitInput[];

  constructor(userDetailResponseModel: UserDetailResponseModel, userRoleTemplates: UserRoleTemplateResponseModel[]) {
    this.userRoleTemplates = userRoleTemplates;
    this.userDetailResponseModel = userDetailResponseModel;

    this._selectedUserRoleTemplateIndex = getSelectedUserRoleTemplateIndexFromUserDetailResponseModel(
      this.userRoleTemplates,
      this.userDetailResponseModel,
    );
    this.selectedUserRoleTemplateIndexInitialValue = this._selectedUserRoleTemplateIndex;

    this._roles = generateRoles(this.userRoleTemplates);

    this._permissions = generatePermissionsFromUserDetailResponseModel(this.userDetailResponseModel);
    this.permissionsInitialValue = this._permissions.map(i => ({ ...i }));

    this._checkedPermissions = getSelectedPermissionsIndexesFromUserDetailResponseModel(this.userDetailResponseModel);
    this.checkedPermissionsInitialValue = Array.from(this._checkedPermissions);

    this._approveLimitsCollection = new LimitInputCollection(LimitInputCollectionType.approve);
    this._initLimitsCollection = new LimitInputCollection(LimitInputCollectionType.init);

    const alreadyExistingLimits = this.userDetailResponseModel.limits ?? [];
    if (alreadyExistingLimits.length > 0) {
      this.prePopulateLimits(alreadyExistingLimits);
    } else {
      this.syncLimitCollections();
    }

    this.approveLimitsCollectionItemsInitialValue = this._approveLimitsCollection.items.map(i => {
      return {
        id: i.id,
        uiElementType: i.uiElementType,
        typeValue: i.typeValue,
        maxValue: i.maxValue,
        rangeValue: i.rangeValue,
        limitInputType: i.limitInputType,
      } as ILimitInput;
    });
    this.initLimitsCollectionItemsInitialValue = this._initLimitsCollection.items.map(i => {
      return {
        id: i.id,
        uiElementType: i.uiElementType,
        typeValue: i.typeValue,
        maxValue: i.maxValue,
        rangeValue: i.rangeValue,
        limitInputType: i.limitInputType,
      } as ILimitInput;
    });
  }

  private prePopulateLimits(limits: UserLimitModel[]): void {
    if (!limits.length) {
      return;
    }

    const groupedByLimitInputCollectionTypeLimitsMap = groupBy(limits, el =>
      mapUserLimitTypeToLimitInputCollectionType(el.type),
    );
    const values = Array.from(groupedByLimitInputCollectionTypeLimitsMap.values());
    const keys = Array.from(groupedByLimitInputCollectionTypeLimitsMap.keys());

    for (let i = 0; i < values.length; i += 1) {
      switch (keys[i] as LimitInputCollectionType) {
        case LimitInputCollectionType.approve: {
          values[i].forEach(l => {
            this._approveLimitsCollection.addByUserLimitModel(l);
          });
          break;
        }

        case LimitInputCollectionType.init: {
          values[i].forEach(l => {
            this._initLimitsCollection.addByUserLimitModel(l);
          });
          break;
        }
        default:
          break;
      }
    }

    // handle case when there's a permission but no limits
    if (this._approveLimitsCollection.items.length === 0 && this.shouldDisplayApproveLimitsCollection()) {
      this._approveLimitsCollection.add();
    }

    if (this._initLimitsCollection.items.length === 0 && this.shouldDisplayInitLimitsCollection()) {
      this._initLimitsCollection.add();
    }
  }

  private syncLimitCollections() {
    if (this.shouldDisplayApproveLimitsCollection()) {
      this._approveLimitsCollection.add();
    }

    if (this.shouldDisplayInitLimitsCollection()) {
      this._initLimitsCollection.add();
    }
  }

  private shouldDisplayApproveLimitsCollection(): boolean {
    return !!this._permissions.find(
      p => p.dataValue === DashboardRoles.paymentApprover && this._checkedPermissions.has(p.value),
    );
  }

  private shouldDisplayInitLimitsCollection(): boolean {
    return !!this._permissions.find(
      p => p.dataValue === DashboardRoles.paymentInitiator && this._checkedPermissions.has(p.value),
    );
  }

  private getLimitsState(): UserLimitModel[] {
    return this._approveLimitsCollection.getState().concat(this._initLimitsCollection.getState());
  }

  get roles(): IRoleRadio[] {
    return this._roles;
  }

  get selectedUserRoleTemplateIndex(): number {
    return this._selectedUserRoleTemplateIndex;
  }

  set selectedUserRoleTemplateIndex(value: number) {
    this._selectedUserRoleTemplateIndex = value;
    this._permissions = generatePermissions(this.userRoleTemplates[this.selectedUserRoleTemplateIndex]);

    this._checkedPermissions = getSelectedPermissionsIndexes(
      this.userRoleTemplates[this.selectedUserRoleTemplateIndex],
    );

    this._approveLimitsCollection.reset();
    this._initLimitsCollection.reset();

    this.syncLimitCollections();
  }

  get permissions(): IPermissionsCheckbox[] {
    return this._permissions.slice();
  }

  get checkedPermissions(): Set<number> {
    return this._checkedPermissions;
  }

  get approveLimitsCollection(): ILimitInputCollection {
    return this._approveLimitsCollection;
  }

  get initLimitsCollection(): ILimitInputCollection {
    return this._initLimitsCollection;
  }

  selectPermission(value: number) {
    if (this._checkedPermissions.has(value)) {
      this._checkedPermissions.delete(value);
    } else {
      this._checkedPermissions.add(value);
    }

    const permission = this._permissions.find(p => p.value === value)!;

    if (permission.dataValue === DashboardRoles.paymentApprover) {
      this._approveLimitsCollection.reset();
      if (this._checkedPermissions.has(permission.value)) {
        this._approveLimitsCollection.add();
      }
    }

    if (permission.dataValue === DashboardRoles.paymentInitiator) {
      this._initLimitsCollection.reset();

      if (this._checkedPermissions.has(permission.value)) {
        this._initLimitsCollection.add();
      }
    }
  }

  getState(): Pick<UpdateUserDetailRequestModel, 'roleTemplate' | 'permissions' | 'limits'> {
    const state: Pick<UpdateUserDetailRequestModel, 'roleTemplate' | 'permissions' | 'limits'> = {};

    if (this.hasSelectedUserRoleTemplateIndexChanged()) {
      state.roleTemplate = this.userRoleTemplates[this.selectedUserRoleTemplateIndex].roleTemplate;
    }

    if (this.havePermissionsChanged() || this.haveCheckedPermissionsChanged()) {
      state.permissions = this._permissions.filter(p => this._checkedPermissions.has(p.value)).map(p => p.dataValue);
    }

    if (
      (this.shouldDisplayApproveLimitsCollection() || this.shouldDisplayInitLimitsCollection()) &&
      (this.hasApproveLimitsCollectionChanged() || this.hasInitLimitsCollectionChanged())
    ) {
      const limitsState = this.getLimitsState();

      state.limits = limitsState;
    }

    return state;
  }

  reset(): void {
    // reset ui relevant fields
    this._selectedUserRoleTemplateIndex = this.selectedUserRoleTemplateIndexInitialValue;
    this._permissions = generatePermissionsFromUserDetailResponseModel(this.userDetailResponseModel);
    this._checkedPermissions = getSelectedPermissionsIndexesFromUserDetailResponseModel(this.userDetailResponseModel);
    this._approveLimitsCollection.reset();
    this._initLimitsCollection.reset();

    const alreadyExistingLimits = this.userDetailResponseModel.limits ?? [];
    if (alreadyExistingLimits.length > 0) {
      this.prePopulateLimits(alreadyExistingLimits);
    } else {
      this.syncLimitCollections();
    }
  }

  private hasSelectedUserRoleTemplateIndexChanged(): boolean {
    return this._selectedUserRoleTemplateIndex !== this.selectedUserRoleTemplateIndexInitialValue;
  }

  private havePermissionsChanged(): boolean {
    if (this._permissions.length !== this.permissionsInitialValue.length) {
      return true;
    }

    for (let i = 0; i < this._permissions.length; i += 1) {
      if (this._permissions[i].dataValue !== this.permissionsInitialValue[i].dataValue) {
        return true;
      }
    }
    return false;
  }

  private haveCheckedPermissionsChanged(): boolean {
    const oldValues = this.checkedPermissionsInitialValue;
    if (this._checkedPermissions.size !== oldValues.length) {
      return true;
    }

    for (let i = 0; i < oldValues.length; i += 1) {
      if (!this._checkedPermissions.has(oldValues[i])) {
        return true;
      }
    }

    return false;
  }

  private hasApproveLimitsCollectionChanged(): boolean {
    const newValues = this._approveLimitsCollection.items;
    const oldValues = this.approveLimitsCollectionItemsInitialValue;

    if (newValues.length !== oldValues.length) {
      return true;
    }

    for (let i = 0; i < newValues.length; i += 1) {
      if (newValues[i].typeValue !== oldValues[i].typeValue) {
        return true;
      }

      if (newValues[i].maxValue !== oldValues[i].maxValue) {
        return true;
      }

      if (newValues[i].rangeValue !== oldValues[i].rangeValue) {
        return true;
      }
    }

    return false;
  }

  private hasInitLimitsCollectionChanged(): boolean {
    const newValues = this._initLimitsCollection.items;
    const oldValues = this.initLimitsCollectionItemsInitialValue;
    if (newValues.length !== oldValues.length) {
      return true;
    }

    for (let i = 0; i < newValues.length; i += 1) {
      if (newValues[i].typeValue !== oldValues[i].typeValue) {
        return true;
      }

      if (newValues[i].maxValue !== oldValues[i].maxValue) {
        return true;
      }

      if (newValues[i].rangeValue !== oldValues[i].rangeValue) {
        return true;
      }
    }

    return false;
  }

  isStateChanged(): boolean {
    if (this.hasSelectedUserRoleTemplateIndexChanged()) {
      return true;
    }

    if (this.havePermissionsChanged()) {
      return true;
    }

    if (this.haveCheckedPermissionsChanged()) {
      return true;
    }

    if (this.hasApproveLimitsCollectionChanged()) {
      return true;
    }

    if (this.hasInitLimitsCollectionChanged()) {
      return true;
    }

    return false;
  }
}
