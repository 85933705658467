import React from 'react';
import { Column } from 'components/shared/Flex/Column';
import { Gap } from 'components/shared/Gap';
import { pxToRem } from 'utils/styling-utils';
import { StyledSkeleton } from './styled';

export const TextLoadingSkeleton: React.FC = () => {
  return (
    <Column>
      <StyledSkeleton animation="pulse" variant="rectangular" width={pxToRem(200)} height={pxToRem(23)} />
      <Gap size="_16px" />
      <StyledSkeleton animation="pulse" variant="rectangular" width={pxToRem(704)} height={pxToRem(16)} />
      <Gap size="_12px" />
      <StyledSkeleton animation="pulse" variant="rectangular" width={pxToRem(704)} height={pxToRem(16)} />
    </Column>
  );
};
