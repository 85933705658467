import { GridEventListener } from '@mui/x-data-grid';
import { NavigateFunction } from 'react-router-dom';
import { PATHS } from 'navigation/constants';
import { UserDetailsUrlParams } from 'navigation/url-params-type';
import { getRouteWithParams } from 'utils/get-route-with-params';
import { UsersListRow } from 'ui-interfaces/row-interfaces/users/users-list-row';

export const onUserCellClick =
  (navigate: NavigateFunction): GridEventListener<'cellClick'> =>
  props => {
    const { id } = props.row as UsersListRow;
    if (props.field !== 'actions') {
      navigate(
        getRouteWithParams(PATHS.USER_DETAILS, {
          userId: id,
        } as UserDetailsUrlParams),
      );
    }
  };
