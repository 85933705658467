import React from 'react';
import { LeftCell } from 'components/shared/detail-page/rows/shared/LeftCell';
import { RightCell } from 'components/shared/detail-page/rows/shared/RightCell';
import { IDefaultRow } from 'ui-interfaces/user-details/rows/i-default-row';
import { RowBox } from './RowBox';

type Props = {
  data: IDefaultRow;
};

export const DefaultNoBorderRow: React.FC<Props> = ({ data }) => {
  return (
    <RowBox>
      <LeftCell value={data.label} />
      <RightCell value={data.value} />
    </RowBox>
  );
};
