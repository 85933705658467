import { useState, useEffect, useMemo } from 'react';

export const useTransformCollectionItemResponse = <T, E>(
  response: T | undefined,
  transformCallback: (itemToTransform?: T) => E,
): [E] => {
  const initData = useMemo(() => {
    return transformCallback();
  }, [transformCallback]);

  const [item, setItem] = useState<E>(initData);

  useEffect(() => {
    if (response) {
      const transformedItem = transformCallback(response);
      setItem(transformedItem);
    }
  }, [response, transformCallback]);

  return [item];
};
