import i18n from 'localizations';
import { Identifiable } from 'ui-interfaces/user-details/identifiable';
import { UserDetailsRowType } from 'ui-enums/user-details/user-details-row-type';
import { ISubHeaderRow } from 'ui-interfaces/user-details/rows/i-sub-header-row';
import { IDefaultRow } from 'ui-interfaces/user-details/rows/i-default-row';

export const approveLimitsEmptyState: Identifiable[] = [
  {
    id: '0',
    rowType: UserDetailsRowType.subHeader,
    title: i18n.t('userDetailsPage.approveTransactions'),
  } as ISubHeaderRow,
  {
    id: '1',
    rowType: UserDetailsRowType.defaultNoBorder,
    label: i18n.t('userDetailsPage.dollarAmount'),
    value: i18n.t('userDetailsPage.emptyValue'),
  } as IDefaultRow,
  {
    id: '2',
    rowType: UserDetailsRowType.short,
    label: i18n.t('userDetailsPage.per'),
    value: i18n.t('userDetailsPage.emptyValue'),
  } as IDefaultRow,
  {
    id: '3',
    rowType: UserDetailsRowType.defaultNoBorder,
    label: i18n.t('userDetailsPage.numberOfTransactions'),
    value: i18n.t('userDetailsPage.emptyValue'),
  } as IDefaultRow,
  {
    id: '4',
    rowType: UserDetailsRowType.short,
    label: i18n.t('userDetailsPage.per'),
    value: i18n.t('userDetailsPage.emptyValue'),
  } as IDefaultRow,
];
