import i18n from 'localizations';
import { CompoundLabel, TransactionsRow } from 'ui-interfaces/row-interfaces/transactions';
import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';
import { get4LastChars } from 'utils/get-4-last-chars';
import { TransactionFiatBaseViewModel } from './base/transaction-fiat-base-vm';

export class TransactionDepositCardViewModel extends TransactionFiatBaseViewModel implements TransactionsRow {
  readonly source: CompoundLabel;

  readonly destination: CompoundLabel;

  readonly statusTooltipMessage?: string;

  constructor(transaction: TransactionResponseModel) {
    super(transaction);
    this.source = {
      start: `*${get4LastChars(transaction.sourceDisplayValue ?? '')}`,
      end: i18n.t('transactionsPage.card'),
    } as CompoundLabel;
    this.destination = {
      start: `*${get4LastChars(transaction.destinationDisplayValue ?? '')}`,
      end: i18n.t('transactionsPage.account'),
    } as CompoundLabel;
    this.statusTooltipMessage =
      this.status.toLowerCase() === TransactionStatus.processing.toLowerCase()
        ? i18n.t('transactionsPage.depositCardTooltipMessage')
        : undefined;
  }
}
