import { styled } from '@mui/material';
import { Row } from 'components/shared/Flex/Row';
import { omitCustomStyleProps } from 'utils/omitCustomStyleProps';
import { pxToRem } from 'utils/styling-utils';

export const StyledUserInfoRow = styled(Row)(({ theme }) => ({
  padding: pxToRem(theme.gaps._24px),
}));

type StyledButtonRowProps = {
  isSideBarExpanded: boolean;
};

export const StyledButtonRow = styled(
  Row,
  omitCustomStyleProps<StyledButtonRowProps>(['isSideBarExpanded']),
)<StyledButtonRowProps>(({ theme, isSideBarExpanded }) => ({
  padding: `0 ${pxToRem(isSideBarExpanded ? theme.gaps._24px : theme.gaps._12px)} ${pxToRem(theme.gaps._32px)}`,
}));
