import React from 'react';
import { ThemeProvider } from 'elements/theme';
import { SnackbarProvider } from 'elements/contexts';
import { OnCloseElement } from 'elements/models/types/element-result';
import { ElementProvider } from './contexts/Element';
import App from './App';

type Props = {
  onClose: OnCloseElement;
  custodialAccountId: string;
  identityId: string;
};

const TradeElement: React.FC<Props> = ({ onClose, custodialAccountId, identityId }) => {
  return (
    <ElementProvider state={{ custodialAccountId, identityId }}>
      <ThemeProvider>
        <SnackbarProvider>
          <App onClose={onClose} />
        </SnackbarProvider>
      </ThemeProvider>
    </ElementProvider>
  );
};

export { TradeElement };
