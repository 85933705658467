import { AddressModel } from 'models/response/address-model';

export const formatAddress = (address?: AddressModel): string => {
  if (!address) {
    return '';
  }
  const street1 = address.street1 ? `${address.street1}, ` : '';
  const street2 = address.street2 ? `${address.street2}, ` : '';
  const city = address.city ? `${address.city}, ` : '';
  const state = address.state ? `${address.state}, ` : '';
  const postalCode = address.postalCode ? `${address.postalCode}, ` : '';
  const country = address.country ? `${address.country}` : '';

  return `${street1}${street2}${city}${state}${postalCode}${country}`;
};
