import { FormikProps, FormikValues } from 'formik';
import { CustomFormFieldError } from 'elements/types';

type KeyOf<T extends object> = Extract<keyof T, string>;

export const getFormikHelpers = <Values extends FormikValues>(
  formik: FormikProps<Values>,
  isAlreadyValidated: boolean,
) => {
  const { getFieldProps: getFieldFormikProps, values, touched, errors, setFieldValue: setFieldFormikValue } = formik;

  const getFieldProps = (name: KeyOf<Values>, passValueParamInOnChangeHandler = false) => {
    const fieldProps = getFieldFormikProps(name);

    const onChangeValueParam = (data: Values[typeof fieldProps.name]) => setFieldFormikValue(fieldProps.name, data);

    return {
      ...fieldProps,
      onChange: passValueParamInOnChangeHandler ? onChangeValueParam : fieldProps.onChange,
    };
  };

  const getCheckboxProps = (name: KeyOf<Values>, passValueParamInOnChangeHandler = false) => {
    const fieldProps = getFieldFormikProps(name);

    return {
      ...getFieldProps(name, passValueParamInOnChangeHandler),
      checked: fieldProps.value,
    };
  };

  const getFieldPropsNoBlur = (name: KeyOf<Values>, passValueParamInOnChangeHandler = false) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { onBlur, ...props } = getFieldFormikProps(name);

    const onChangeValueParam = (data: Values[typeof props.name]) => setFieldFormikValue(props.name, data);

    return {
      ...props,
      onChange: passValueParamInOnChangeHandler ? onChangeValueParam : props.onChange,
    };
  };

  const getFieldPropsNoChange = (name: KeyOf<Values>) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { onChange, ...props } = getFieldFormikProps(name);
    return props;
  };

  const getFieldPropsNoHandlers = (name: KeyOf<Values>) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { onChange, onBlur, ...props } = getFieldFormikProps(name);
    return props;
  };

  const getIsFieldHasError = (name: KeyOf<Values>) => !!(touched[name] && errors[name]);

  const getErrorMessage = (name: KeyOf<Values>) => errors[name];

  const getFieldErrorProps = (name: KeyOf<Values>, helperText?: string) => {
    const error = getIsFieldHasError(name);
    return {
      error,
      helperText: error ? getErrorMessage(name) : helperText ?? '',
    };
  };

  const getFieldErrorPropsWithCustomError = (name: KeyOf<Values>, customError: CustomFormFieldError | null) => {
    const error = getIsFieldHasError(name);
    const isCustomError = !!customError && customError.matchedText === values[name];
    const defaultErrorText = error ? getErrorMessage(name) : '';
    return {
      error: error || isCustomError,
      helperText: isCustomError ? customError?.errorText : defaultErrorText,
    };
  };

  const setFieldValue = <T extends KeyOf<Values>, S extends Values[T]>(field: T, value: S, shouldValidate?: boolean) =>
    setFieldFormikValue(field, value, shouldValidate);

  const isFormDisabled = !formik.isValid || !isAlreadyValidated;

  return {
    getFieldProps,
    getCheckboxProps,
    getFieldPropsNoChange,
    getFieldPropsNoBlur,
    getFieldPropsNoHandlers,
    getIsFieldHasError,
    getErrorMessage,
    getFieldErrorProps,
    getFieldErrorPropsWithCustomError,
    setFieldValue,
    isFormDisabled,
  };
};

// eslint-disable-next-line import/no-unused-modules
export type FormCustomErrors<T extends object> = Partial<Record<keyof T, string>>;
