import { styled, Box } from '@mui/material';
import { Row } from 'components/shared/Flex/Row';
import { pxToRem } from 'utils/styling-utils';

export const StyledRow = styled(Row)(() => ({
  alignItems: 'flex-start',
}));

export const StyledBox = styled(Box)(() => ({
  marginTop: pxToRem(5),
}));
