import React from 'react';
import { Gap } from 'components/shared/Gap';
import { CroppedLabel } from 'components/shared/CroppedLabel';
import { StyledRow } from './styled';

type Props = {
  icon: React.ReactNode;
  operation: string;
  maxTextWidth: number;
};

export const CellContentWithoutMethod: React.FC<Props> = ({ icon, operation, maxTextWidth }) => {
  return (
    <StyledRow>
      {icon}
      <Gap size="_8px" isHorizontal />
      <CroppedLabel title={operation} maxWidth={maxTextWidth} />
    </StyledRow>
  );
};
