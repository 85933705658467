import { Box } from '@mui/material';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledContainer = elementsStyled(Box)`
  margin-top: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledNoAccountWrapper = elementsStyled(Box)(
  ({ theme }) => css`
    width: 56px;
    height: 56px;
    border-radius: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.palette.tertiary.mainTransparent60};

    & > svg {
      color: ${theme.palette.secondary.font};
    }
  `,
);
