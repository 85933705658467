import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Stack, Typography } from '@mui/material';
import useForm from 'elements/hooks/useForm';
import { WireBankInfoFormData } from 'elements/element-transfer/types';
import { BaseInput, BaseSelect, BaseSelectItem, Button, CountryDropdown } from 'elements/components';
import { COUNTRY_CODE_US, rawStatesObjectByCountryCode } from 'elements/constants';
import { getStateNameByValue } from 'elements/utils';
import BaseFieldset from 'elements/element-transfer/components/BaseFieldset';
import BaseStepper from 'elements/element-transfer/components/BaseStepper';
import Layout from 'elements/element-transfer/components/Layout';
import {
  MAX_CITY_LENGTH,
  MAX_POSTAL_CODE_LENGTH,
  MAX_STATE_NON_US_LENGTH,
  MAX_STREET1_LENGTH,
  ZIP_CODE_LENGTH,
  useYupRules,
} from 'elements/element-transfer/hooks/useYupRules';
import { StyledDisabledCountryDropdown } from './styled';

type Props = {
  initialFormData: WireBankInfoFormData;
  isDomestic: boolean;
  onContinue: (data: WireBankInfoFormData) => void;
  onClose: () => void;
  onBack: () => void;
};

const statesList = Object.values(rawStatesObjectByCountryCode);

const WireBankInfoPage: React.FC<Props> = ({ initialFormData, isDomestic, onContinue, onClose, onBack }) => {
  const { t } = useTranslation();
  const {
    baseOptionalRule,
    countryRule,
    street1Rule,
    street2Rule,
    cityRule,
    bankNameRule,
    stateUSRule,
    stateNonUSRule,
    zipCodeRule,
    postalCodeRule,
  } = useYupRules();

  const {
    formik: { submitForm, handleSubmit },
    helpers: { isFormDisabled, getFieldProps, getFieldErrorProps, getFieldPropsNoHandlers },
  } = useForm({
    initialValues: initialFormData,
    enableReinitialize: true,
    yupShape: {
      country: countryRule,
      street1: street1Rule,
      street2: street2Rule(t('elements.transfer.withdrawalWireBankInfoPage.street2Label')),
      city: cityRule,
      state: isDomestic ? stateUSRule : stateNonUSRule,
      postalCode: isDomestic ? zipCodeRule : postalCodeRule,
      bankName: isDomestic ? baseOptionalRule : bankNameRule,
    },
    onSubmit: (formValues, { setSubmitting }) => {
      onContinue(formValues);
      setSubmitting(false);
    },
  });

  return (
    <Layout
      title={t('elements.transfer.customizableLabels.withdrawalWireBankInfoPageTitle')}
      onClose={onClose}
      onBack={onBack}
      isAbove
      footerButtons={
        <Stack gap="16px">
          <BaseStepper variant="dots" position="static" steps={4} activeStep={1} backButton={null} nextButton={null} />
          <Button type="submit" disabled={isFormDisabled} onClick={submitForm}>
            <Typography variant="buttonLarge">{t('elements.common.continue')}</Typography>
            <ArrowForwardIcon />
          </Button>
        </Stack>
      }
    >
      <Stack component="form" onSubmit={handleSubmit} mb="24px">
        <BaseFieldset>
          <Typography component="legend" variant="overlineDefaultMedium" color="secondary.font">
            {t('elements.transfer.withdrawalWireBankInfoPage.fieldsHeader')}
          </Typography>
          {isDomestic ? (
            <StyledDisabledCountryDropdown
              label={t('elements.transfer.withdrawalWireBankInfoPage.countryLabel')}
              {...getFieldPropsNoHandlers('country')}
              searchPlaceholder={t('elements.transfer.withdrawalWireBankInfoPage.countrySearchLabel')}
              fullWidth
              disabled
            />
          ) : (
            <>
              <BaseInput
                label={t('elements.transfer.withdrawalWireBankInfoPage.bankNameLabel')}
                {...getFieldProps('bankName')}
                {...getFieldErrorProps('bankName')}
              />
              <CountryDropdown
                label={t('elements.transfer.withdrawalWireBankInfoPage.countryLabel')}
                {...getFieldProps('country')}
                searchPlaceholder={t('elements.transfer.withdrawalWireBankInfoPage.countrySearchLabel')}
                excludedCountries={[COUNTRY_CODE_US]}
                fullWidth
              />
            </>
          )}
          <BaseInput
            label={t('elements.transfer.withdrawalWireBankInfoPage.street1Label')}
            {...getFieldProps('street1')}
            {...getFieldErrorProps('street1')}
            maxLength={MAX_STREET1_LENGTH}
          />
          <BaseInput
            label={t('elements.transfer.withdrawalWireBankInfoPage.street2Label')}
            {...getFieldProps('street2')}
            {...getFieldErrorProps('street2')}
            maxLength={MAX_STREET1_LENGTH}
          />
          <BaseInput
            label={t('elements.transfer.withdrawalWireBankInfoPage.cityLabel')}
            {...getFieldProps('city')}
            {...getFieldErrorProps('city')}
            maxLength={MAX_CITY_LENGTH}
          />
          <Stack direction="row" spacing="24px">
            {isDomestic ? (
              <BaseSelect
                label={t('elements.transfer.withdrawalWireBankInfoPage.stateLabel')}
                {...getFieldProps('state')}
                topDropdown
                renderValueHandler={value => (
                  <Typography variant="bodyDefaultBook" color="primary.font">
                    {getStateNameByValue(value)}
                  </Typography>
                )}
              >
                {statesList.map(({ value, name }) => (
                  <BaseSelectItem value={value} key={value}>
                    <Typography variant="bodyDefaultBook" color="primary.font">
                      {name}
                    </Typography>
                  </BaseSelectItem>
                ))}
              </BaseSelect>
            ) : (
              <BaseInput
                label={t('elements.transfer.withdrawalWireBankInfoPage.provinceLabel')}
                {...getFieldProps('state')}
                {...getFieldErrorProps('state')}
                maxLength={MAX_STATE_NON_US_LENGTH}
              />
            )}
            <BaseInput
              label={
                isDomestic
                  ? t('elements.transfer.withdrawalWireBankInfoPage.zipCode')
                  : t('elements.transfer.withdrawalWireBankInfoPage.postalCode')
              }
              {...getFieldProps('postalCode')}
              {...getFieldErrorProps('postalCode')}
              maxLength={isDomestic ? ZIP_CODE_LENGTH : MAX_POSTAL_CODE_LENGTH}
            />
          </Stack>
        </BaseFieldset>
      </Stack>
    </Layout>
  );
};

export default WireBankInfoPage;
