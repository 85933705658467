import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { TransactionsRow } from 'ui-interfaces/row-interfaces/transactions';
import { PaymentType } from 'ui-enums/response/transactions/payment-type';
import { TransactionBaseViewModel } from 'view-models/transactions/list/transaction-base-vm';
// fiat - start
import { TransactionDepositAchViewModel } from 'view-models/transactions/list/fiat/transaction-deposit-ach-vm';
import { TransactionDepositAchIncomingViewModel } from 'view-models/transactions/list/fiat/transaction-deposit-ach-incoming-vm';
import { TransactionInternalTransferViewModel } from 'view-models/transactions/list/fiat/transaction-internal-transfer-vm';
import { TransactionDepositWireViewModel } from 'view-models/transactions/list/fiat/transaction-deposit-wire-vm';
import { TransactionDepositWireReversalViewModel } from 'view-models/transactions/list/fiat/transaction-deposit-wire-reversal-vm';
import { TransactionWithdrawalAchViewModel } from 'view-models/transactions/list/fiat/transaction-withdrawal-ach-vm';
import { TransactionDepositAchReturnViewModel } from 'view-models/transactions/list/fiat/transaction-deposit-ach-return-vm';
import { TransactionWithdrawalAchIncomingViewModels } from 'view-models/transactions/list/fiat/transaction-withdrawal-ach-incoming-vm';
import { TransactionWithdrawalWireViewModel } from 'view-models/transactions/list/fiat/transaction-withdrawal-wire-vm';
import { TransactionWithdrawalWireReversalViewModel } from 'view-models/transactions/list/fiat/transaction-withdrawal-wire-reversal-vm';
import { TransactionDepositCardViewModel } from 'view-models/transactions/list/fiat/transaction-deposit-card-vm';
import { TransactionDepositCheckViewModel } from 'view-models/transactions/list/fiat/transaction-deposit-check-vm';
import { TransactionDepositWireReturnViewModel } from 'view-models/transactions/list/fiat/transaction-deposit-wire-return-vm';
import { TransactionWithdrawalWireReturnViewModel } from 'view-models/transactions/list/fiat/transaction-withdrawal-wire-return-vm';
import { TransactionWithdrawalAchReturnViewModel } from 'view-models/transactions/list/fiat/transaction-withdrawal-ach-return-vm';
import { TransactionWithdrawalWireInternationalViewModel } from 'view-models/transactions/list/fiat/transaction-withdrawal-wire-international-vm';
import { TransactionWithdrawalAchReversalViewModel } from 'view-models/transactions/list/fiat/transaction-withdrawal-ach-reversal-vm';
import { TransactionDepositAchReversalViewModel } from 'view-models/transactions/list/fiat/transaction-deposit-ach-reversal-vm';
// fiat - end
// crypto - start
import { TransactionCryptoBuyViewModel } from 'view-models/transactions/list/crypto/transaction-crypto-buy-vm';
import { TransactionCryptoSellViewModel } from 'view-models/transactions/list/crypto/transaction-crypto-sell-vm';
import { TransactionCryptoDepositViewModel } from 'view-models/transactions/list/crypto/transaction-crypto-deposit-vm';
import { TransactionCryptoWithdrawalViewModel } from 'view-models/transactions/list/crypto/transaction-crypto-withdrawal-vm';
import { TransactionCryptoInternalViewModel } from 'view-models/transactions/list/crypto/transaction-crypto-internal-vm';
// crypto - end
// private security - start
import { TransactionPrivateSecurityDepositViewModel } from 'view-models/transactions/list/private-security/transaction-private-security-deposit-vm';
import { TransactionPrivateSecurityInternalViewModel } from 'view-models/transactions/list/private-security/transaction-private-security-internal-vm';
import { TransactionPrivateSecurityWithdrawalViewModel } from 'view-models/transactions/list/private-security/transaction-private-security-withdrawal-vm';
// private security - end
// precious metal - start
import { TransactionPreciousMetalBuyViewModel } from 'view-models/transactions/list/precious-metal/transaction-precious-metal-buy-vm';
import { TransactionPreciousMetalSellViewModel } from 'view-models/transactions/list/precious-metal/transaction-precious-metal-sell-vm';
// precious metal - end
// common
import { TransactionInitialDepositViewModel } from 'view-models/transactions/list/common/transaction-deposit-initial-vm';
import { TransactionManualViewModel } from 'view-models/transactions/list/manual/transactions-manual-vm';

export const createTransactionViewModel = (transaction: TransactionResponseModel): TransactionsRow => {
  switch (transaction.paymentType.toLowerCase()) {
    // fiat - start
    case PaymentType.internal.toLowerCase(): {
      return new TransactionInternalTransferViewModel(transaction);
    }
    case PaymentType.achDeposit.toLowerCase(): {
      return new TransactionDepositAchViewModel(transaction);
    }
    case PaymentType.incomingAchDeposit.toLowerCase(): {
      return new TransactionDepositAchIncomingViewModel(transaction);
    }
    case PaymentType.wireDeposit.toLowerCase(): {
      return new TransactionDepositWireViewModel(transaction);
    }
    case PaymentType.reversalWireDeposit.toLowerCase(): {
      return new TransactionDepositWireReversalViewModel(transaction);
    }
    case PaymentType.wireDepositReturn.toLowerCase(): {
      return new TransactionDepositWireReturnViewModel(transaction);
    }
    case PaymentType.achWithdrawal.toLowerCase(): {
      return new TransactionWithdrawalAchViewModel(transaction);
    }
    case PaymentType.returnAchDeposit.toLowerCase(): {
      return new TransactionDepositAchReturnViewModel(transaction);
    }
    case PaymentType.incomingAchWithdrawal.toLowerCase(): {
      return new TransactionWithdrawalAchIncomingViewModels(transaction);
    }
    case PaymentType.wireWithdrawal.toLowerCase(): {
      return new TransactionWithdrawalWireViewModel(transaction);
    }
    case PaymentType.internationalWireWithdrawal.toLowerCase(): {
      return new TransactionWithdrawalWireInternationalViewModel(transaction);
    }
    case PaymentType.reversalWireWithdrawal.toLowerCase(): {
      return new TransactionWithdrawalWireReversalViewModel(transaction);
    }
    case PaymentType.wireWithdrawalReturn.toLowerCase(): {
      return new TransactionWithdrawalWireReturnViewModel(transaction);
    }
    case PaymentType.cardDeposit.toLowerCase(): {
      return new TransactionDepositCardViewModel(transaction);
    }
    case PaymentType.checkDeposit.toLowerCase(): {
      return new TransactionDepositCheckViewModel(transaction);
    }
    case PaymentType.returnAchWithdrawal.toLowerCase(): {
      return new TransactionWithdrawalAchReturnViewModel(transaction);
    }
    case PaymentType.achDepositReverse.toLowerCase(): {
      return new TransactionDepositAchReversalViewModel(transaction);
    }
    case PaymentType.achWithdrawalReverse.toLowerCase(): {
      return new TransactionWithdrawalAchReversalViewModel(transaction);
    }
    // fiat - end
    // crypto - start
    case PaymentType.buyCrypto.toLowerCase(): {
      return new TransactionCryptoBuyViewModel(transaction);
    }
    case PaymentType.sellCrypto.toLowerCase(): {
      return new TransactionCryptoSellViewModel(transaction);
    }
    case PaymentType.depositCrypto.toLowerCase(): {
      return new TransactionCryptoDepositViewModel(transaction);
    }
    case PaymentType.withdrawalCrypto.toLowerCase(): {
      return new TransactionCryptoWithdrawalViewModel(transaction);
    }
    case PaymentType.internalCrypto.toLowerCase(): {
      return new TransactionCryptoInternalViewModel(transaction);
    }
    // crypto - end

    // private security - start
    case PaymentType.privateSecurityDeposit.toLowerCase(): {
      return new TransactionPrivateSecurityDepositViewModel(transaction);
    }
    case PaymentType.privateSecurityInternal.toLowerCase(): {
      return new TransactionPrivateSecurityInternalViewModel(transaction);
    }
    case PaymentType.privateSecurityWithdrawal.toLowerCase(): {
      return new TransactionPrivateSecurityWithdrawalViewModel(transaction);
    }
    // private security - start

    // precious metal - start
    case PaymentType.buyPreciousMetal.toLowerCase(): {
      return new TransactionPreciousMetalBuyViewModel(transaction);
    }
    case PaymentType.sellPreciousMetal.toLowerCase(): {
      return new TransactionPreciousMetalSellViewModel(transaction);
    }
    // precious metal - end

    // common
    case PaymentType.initialDeposit.toLowerCase(): {
      return new TransactionInitialDepositViewModel(transaction);
    }

    case PaymentType.manuallyProcessed.toLowerCase(): {
      return new TransactionManualViewModel(transaction);
    }

    default: {
      return new TransactionBaseViewModel(transaction);
    }
  }
};
