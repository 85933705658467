import { css, styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';
import { Row } from 'components/shared/Flex/Row';
import { ReactComponent as HelperErrorAttention } from 'assets/icons/helper-error-attention.svg';

export type UIDivProps = {
  isError: boolean;
};

export const StyledTextArea = styled('textarea')(({ theme }) => ({
  ...theme.typography.bodyDefaultBook,
  color: theme.palette.text.primary,
  textDecoration: 'none',
  resize: 'none',
  height: '100%',
  width: '100%',
  border: 'none',
}));

export const StyledDiv = styled('div')<UIDivProps>(({ theme, isError }) => ({
  border: `1px solid ${isError ? theme.palette.error.main : theme.palette.grey['200']}`,
  borderRadius: '8px',
  padding: '10px 12px',
}));

export const StyledRow = styled(Row)(() => ({
  marginTop: pxToRem(8),
}));

export const StyledTypography = styled(Typography)(() => ({
  fontSize: '0.75rem',
}));

export const StylesHelperErrorAttentionIcon = styled(HelperErrorAttention)(
  css`
    height: ${pxToRem(18)};
    flex-basis: ${pxToRem(18)};
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: ${pxToRem(4)};
    margin-right: ${pxToRem(8)};
  `,
);
