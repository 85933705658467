import React from 'react';
import { LeftCell } from 'components/shared/detail-page/rows/shared/LeftCell';
import { RowBox } from 'components/shared/detail-page/rows/shared/RowBox';
import { IRoleRow } from 'ui-interfaces/user-details/rows/i-role-row';
import { RightCell } from './RightCell';

type Props = {
  data: IRoleRow;
  hasBottomBorder: boolean;
};

export const RoleRow: React.FC<Props> = ({ data, hasBottomBorder }) => {
  return (
    <RowBox hasBottomBorder={hasBottomBorder}>
      <LeftCell value={data.label} />
      <RightCell statusCellProps={data.statusCellProps} />
    </RowBox>
  );
};
