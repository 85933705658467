import React from 'react';
import { StyledEditButton, StyledIcon } from './styled';

type Props = {
  onClick: () => void;
};

const EditEndAdorment: React.FC<Props> = ({ onClick }) => {
  const onMouseDown = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => event.preventDefault();

  return (
    <StyledEditButton aria-label="edit" onClick={onClick} onMouseDown={onMouseDown}>
      <StyledIcon />
    </StyledEditButton>
  );
};

export default EditEndAdorment;
