import React from 'react';
import { ReactComponent as DefaultAssetIcon36x36 } from 'assets/icons/default-asset-icon/default-asset-icon-36x36.svg';
import { ReactComponent as DefaultAssetIcon28x28 } from 'assets/icons/default-asset-icon/default-asset-icon-28x28.svg';
import { ReactComponent as DefaultAssetIcon34x34 } from 'assets/icons/default-asset-icon/default-asset-icon-34x34.svg';
import { AssetByNetworkIconSize } from 'ui-enums/asset-by-network-icon-size';

export const mapAssetByNetworkIconSizeToDefaultAssetIcon = (a: AssetByNetworkIconSize): JSX.Element => {
  switch (a) {
    case AssetByNetworkIconSize['28px']:
      return <DefaultAssetIcon28x28 />;
    case AssetByNetworkIconSize['34px']:
      return <DefaultAssetIcon34x34 />;
    case AssetByNetworkIconSize['36px']:
      return <DefaultAssetIcon36x36 />;
    default:
      return <DefaultAssetIcon28x28 />;
  }
};
