import { Filter } from 'filters/interfaces/filter';

export interface MultiValueFilterParams {
  property: string;
  displayProperty: string;
  operators: string[];
  operatorsDisplayValues: string[];
  initialOperator: string;
  values: string[];
  displayValues: string[];
  initialValue: string;
}

export class MultiValueFilter implements Filter {
  private readonly property: string; // properties on BE

  private readonly displayProperty: string;

  private readonly operators: string[]; // BE filter operators

  private readonly operatorsDisplayValues: string[];

  private selectedOperator: string;

  private readonly values: string[]; // enum strings

  private readonly displayValues: string[]; // enum strings representations

  private readonly defaultValue: string;

  private selectedValue: string;

  constructor(multiValueFilterParams: MultiValueFilterParams) {
    this.property = multiValueFilterParams.property;
    this.displayProperty = multiValueFilterParams.displayProperty;
    this.operators = multiValueFilterParams.operators;
    this.operatorsDisplayValues = multiValueFilterParams.operatorsDisplayValues;
    this.selectedOperator = multiValueFilterParams.initialOperator;
    this.values = multiValueFilterParams.values;
    this.displayValues = multiValueFilterParams.displayValues;
    this.selectedValue = multiValueFilterParams.initialValue;
    this.defaultValue = multiValueFilterParams.initialValue;
  }

  getProperty() {
    return this.property;
  }

  getDisplayProperty(): string {
    return this.displayProperty;
  }

  getSelectedOperator(): string {
    return this.selectedOperator;
  }

  setSelectedOperator(operator: string): void {
    this.selectedOperator = operator;
  }

  getAllOperators(): string[] {
    return this.operators;
  }

  getAllOperatorsDisplayValues(): string[] {
    return this.operatorsDisplayValues;
  }

  getSelectedValue() {
    return this.selectedValue;
  }

  getSelectedValueDisplayValue(): string {
    const selectedValueIndex = this.values.findIndex(value => value === this.selectedValue);
    return this.displayValues[selectedValueIndex];
  }

  setSelectedValue(value: string) {
    if (this.values.includes(value)) {
      this.selectedValue = value;
    } else {
      this.resetAll();
    }
  }

  getAllValues(): string[] {
    return this.values;
  }

  getAllValuesDisplayValues(): string[] {
    return this.displayValues;
  }

  resetAll(): void {
    this.selectedValue = this.defaultValue;
  }
}
