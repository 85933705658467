import React from 'react';
import { TextComponent } from 'components/shared/grids/CustomizedGrid/GridFilterPopup/FilterValueComponent/TextComponent';
import { ValueDropDown } from 'components/shared/grids/CustomizedGrid/GridFilterPopup/ValueDropDown';
import DatePicker from 'components/shared/DatePicker';

type Props = {
  label: string;
  data: string[] | string;
  onChange: (value: number | string) => void;
  selectedIndex?: number;
  selectedValue?: string;
  selectedDate?: string;
  dataDisplayValues?: string[];
  isDatePickerMode?: boolean;
};

export const FilterValueComponent: React.FC<Props> = ({
  label,
  data,
  selectedIndex,
  selectedValue,
  selectedDate,
  dataDisplayValues,
  onChange,
  isDatePickerMode,
}) => {
  if (isDatePickerMode) {
    return <DatePicker label={label} selectedDate={selectedDate} onChange={onChange} />;
  }

  if (typeof data === 'string') {
    return <TextComponent label={label} value={selectedValue!} onChange={onChange} />;
  }
  return (
    <ValueDropDown
      label={label}
      selectedValueIndex={selectedIndex!}
      items={data}
      itemsDisplayValues={dataDisplayValues!}
      onChange={onChange}
    />
  );
};
