import { UserDetailResponseModel } from 'models/response/users/user-detail-response-model';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { UserRoleTemplateResponseModel } from 'models/response/users/user-role-template-response-model';
import { IRoleTemplatesInputs } from 'ui-interfaces/user-form/i-role-templates-inputs';
import { IEditUserFormViewModel } from 'ui-interfaces/user-form/edit-user-form/i-edit-user-form-vm';
import { UpdateUserDetailRequestModel } from 'models/request/users/update-user-detail-request-model';
import { EditUserFormRoleTemplatesViewModel } from './edit-user-form-role-templates-vm';

export class EditUserFormViewModel implements IEditUserFormViewModel {
  private readonly userDetailResponseModel: UserDetailResponseModel;

  private shortUserResponseModel: ShortUserResponseModel;

  private readonly userRoleTemplateResponseModel: UserRoleTemplateResponseModel[];

  private readonly _roleTemplatesInputs: IRoleTemplatesInputs<
    Pick<UpdateUserDetailRequestModel, 'roleTemplate' | 'permissions' | 'limits'>
  >;

  private readonly _isCurrentUserBeingEdited: boolean;

  private _firstName: string;

  private firstNameInitialValue: string;

  private _lastName: string;

  private lastNameInitialValue: string;

  private readonly _email: string;

  private emailInitialValue: string;

  private _phone: string;

  private phoneInitialValue: string;

  constructor(
    userDetailResponseModel: UserDetailResponseModel,
    shortUserResponseModel: ShortUserResponseModel,
    userRoleTemplateResponseModel: UserRoleTemplateResponseModel[],
  ) {
    this.userDetailResponseModel = userDetailResponseModel;

    this.shortUserResponseModel = shortUserResponseModel;

    this.userRoleTemplateResponseModel = userRoleTemplateResponseModel;

    this._roleTemplatesInputs = new EditUserFormRoleTemplatesViewModel(
      this.userDetailResponseModel,
      this.userRoleTemplateResponseModel,
    );

    this._isCurrentUserBeingEdited = this.userDetailResponseModel.id === shortUserResponseModel.id;

    this._firstName = this.userDetailResponseModel.firstName;
    this.firstNameInitialValue = this._firstName;

    this._lastName = this.userDetailResponseModel.lastName;
    this.lastNameInitialValue = this._lastName;

    this._email = this.userDetailResponseModel.email;
    this.emailInitialValue = this._email;

    this._phone = this.userDetailResponseModel.phone.replace('+', '');
    this.phoneInitialValue = this._phone;
  }

  get roleTemplatesInputs(): IRoleTemplatesInputs<
    Pick<UpdateUserDetailRequestModel, 'roleTemplate' | 'permissions' | 'limits'>
  > {
    return this._roleTemplatesInputs;
  }

  get isCurrentUserBeingEdited(): boolean {
    return this._isCurrentUserBeingEdited;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get email(): string {
    return this._email;
  }

  get phone(): string {
    return this._phone;
  }

  set phone(value: string) {
    this._phone = value;
  }

  private hasFirstNameChanged(): boolean {
    return this._firstName !== this.firstNameInitialValue;
  }

  private hasLastNameChanged(): boolean {
    return this._lastName !== this.lastNameInitialValue;
  }

  private hasPhoneChanged(): boolean {
    return this._phone !== this.phoneInitialValue;
  }

  getState(): UpdateUserDetailRequestModel {
    const roleTemplatesInputsState: Pick<UpdateUserDetailRequestModel, 'roleTemplate' | 'permissions' | 'limits'> =
      this._roleTemplatesInputs.getState();

    const state: UpdateUserDetailRequestModel = {
      ...roleTemplatesInputsState,
    };

    if (this.hasFirstNameChanged()) {
      state.firstName = this._firstName;
    }

    if (this.hasLastNameChanged()) {
      state.lastName = this._lastName;
    }

    if (this.hasPhoneChanged()) {
      state.phone = `+${this._phone}`;
    }

    return state;
  }

  reset(): void {
    this._roleTemplatesInputs.reset();
    this._firstName = this.firstNameInitialValue;
    this._lastName = this.lastNameInitialValue;
    this._phone = this.phoneInitialValue;
  }

  isStateChanged(): boolean {
    if (this._roleTemplatesInputs.isStateChanged()) {
      return true;
    }

    if (this.hasFirstNameChanged()) {
      return true;
    }

    if (this.hasLastNameChanged()) {
      return true;
    }

    if (this.hasPhoneChanged()) {
      return true;
    }

    return false;
  }
}
