import React from 'react';
import { IdentityDetailsIdentifiable } from 'ui-interfaces/identity-details/identity-details-identifiable';
import { IdentityDetailRowType } from 'ui-enums/identities/identity-detail-row-type';
// rows components - start
import { LoadingRow } from 'components/shared/detail-page/rows/LoadingRow';
import { LinkRow } from 'components/identities/IdentityDetailsPageContent/Tabs/IdentityDetailsTab/rows/LinkRow';
import { PlainRow } from 'components/identities/IdentityDetailsPageContent/Tabs/IdentityDetailsTab/rows/PlainRow';
import { TextMultiLineRow } from 'components/identities/IdentityDetailsPageContent/Tabs/IdentityDetailsTab/rows/TextMultiLineRow';
// rows components - end
// rows interfaces - start
import { IPlainRow } from 'ui-interfaces/identity-details/rows/i-plain-row';
import { ILinkRow } from 'ui-interfaces/identity-details/rows/i-link-row';
// rows interfaces - end

export const createIdentityDetailsTabRow = (
  itemData: IdentityDetailsIdentifiable,
  isLastItem: boolean,
): React.ReactElement | null => {
  switch (itemData.rowType) {
    case IdentityDetailRowType.plain: {
      const i = itemData as IPlainRow;
      return <PlainRow key={i.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }

    case IdentityDetailRowType.textMultiLine: {
      const i = itemData as IPlainRow;
      return <TextMultiLineRow key={i.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }

    case IdentityDetailRowType.link: {
      const i = itemData as ILinkRow;
      return <LinkRow key={i.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }

    case IdentityDetailRowType.loading:
      return <LoadingRow key={itemData.id} hasBottomBorder={!isLastItem} />;

    default:
      return null;
  }
};
