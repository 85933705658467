import React from 'react';
import { TransferFlow, WireLocationType } from 'elements/element-transfer/types';
import ContentBlock from 'elements/element-transfer/components/Withdrawal/WirePlacedPage';
import { WireWithdrawalState, useElement } from 'elements/element-transfer/contexts/Element';
import { sendPaymentIdMessage } from 'elements/element-transfer/utils/sendPaymentIdMessage';
import { OnCloseElement } from 'elements/models/types/element-result';

const WirePlacedPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { state } = useElement();
  const wireWithdrawalState = state[TransferFlow.Withdrawal].WIRE as WireWithdrawalState;
  const { amount, recipientInfo, accountNumbers, bankInfo, note, memo, purposeOfPayment } = wireWithdrawalState;
  const isDomestic = recipientInfo.locationType === WireLocationType.Domestic;

  const onDone = () => {
    onClose(
      sendPaymentIdMessage({
        wireDomesticWithdrawalPaymentId: state.wireDomesticWithdrawalPaymentId,
        wireInternationalWithdrawalPaymentId: state.wireInternationalWithdrawalPaymentId,
      }),
    );
    // @TODO remove when element will use RTK query with common re-fetch approach
    window.location.reload();
  };

  return (
    <ContentBlock
      amount={amount}
      recipientName={recipientInfo.fullName}
      accountNumber={accountNumbers.accountNumber}
      bankName={bankInfo.bankName}
      bankCountry={bankInfo.country}
      custodialAccount={state.custodialAccount}
      purposeOfPayment={purposeOfPayment}
      isDomestic={isDomestic}
      note={note}
      memo={memo}
      onDone={onDone}
    />
  );
};

export default WirePlacedPage;
