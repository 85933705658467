import { Box } from '@mui/material';
import { alpha, css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledQrCodeContainer = elementsStyled(Box)(
  ({ theme }) => css`
    height: 259px;
    width: 259px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: ${theme.palette.background.default};
    box-shadow: 0 1px 2px ${alpha(theme.palette.grey[700], 0.1)}, 0 2px 4px ${alpha(theme.palette.grey[700], 0.1)};
  `,
);
