import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { WireInstructionsState } from 'elements/element-transfer/types';
import { BannerType, Button, InlineBanner, Spacer } from 'elements/components';
import { ErrorState } from 'elements/features/LoadingErrorState';
import { ReactComponent as DownloadIcon } from 'elements/element-transfer/assets/icons/download.svg';
import FlowFirstPageLayout from 'elements/element-transfer/components/FlowFirstPageLayout';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { OnCloseElement } from 'elements/models/types/element-result';
import ParticipantInfoSection from './ParticipantInfoSection';
import ValueWithCopyButton from './ValueWithCopyButton';
import { StyledReplyIcon } from './styled';

type Props = {
  isError: boolean;
  wireInstructions: WireInstructionsState | null;
  onShare: () => void;
  onClose: OnCloseElement;
  onPDFGenerate: () => void;
  onReload: () => void;
  onBack?: () => void;
};

const ACCOUNT_NUMBER_MASK_CHAR_COUNT = 12;
const ROUTING_NUMBER_MASK_CHAR_COUNT = 9;

const WireInstructionsPage: React.FC<Props> = ({
  isError,
  wireInstructions,
  onShare,
  onClose,
  onPDFGenerate,
  onReload,
  onBack,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  const footerButtons = isMobile ? (
    <Button onClick={onShare}>
      <Box display="flex" alignItems="center">
        <Typography mr="12px" variant="buttonLarge">
          {t('elements.common.share')}
        </Typography>
        <StyledReplyIcon />
      </Box>
    </Button>
  ) : (
    <Button buttonType="primary" endIcon={<DownloadIcon />} onClick={onPDFGenerate}>
      <Typography variant="buttonLarge">
        {t('elements.transfer.depositWireInstructionsPage.downloadPDFButtonLabel')}
      </Typography>
    </Button>
  );

  return (
    <FlowFirstPageLayout
      isAbove
      title={t('elements.transfer.customizableLabels.depositWireInstructionsPageTitle')}
      onBack={onBack}
      onClose={onClose}
      footerButtons={!isError && footerButtons}
      isContentCentered={isError}
    >
      {wireInstructions && !isError && (
        <>
          <Spacer size={8} />
          <InlineBanner type={BannerType.Info} text={t('elements.transfer.depositWireInstructionsPage.infoMessage')} />
          <Spacer size={32} />
          <ValueWithCopyButton
            label={t('elements.transfer.depositWireInstructionsPage.accountNumberFieldLabel')}
            value={wireInstructions.accountNumber}
            maskCharCount={ACCOUNT_NUMBER_MASK_CHAR_COUNT}
          />
          <Spacer size={16} />
          <ValueWithCopyButton
            label={t('elements.transfer.depositWireInstructionsPage.routingNumberFieldLabel')}
            value={wireInstructions.routingNumber}
            maskCharCount={ROUTING_NUMBER_MASK_CHAR_COUNT}
          />
          <Spacer size={16} />
          <ValueWithCopyButton
            label={t('elements.transfer.depositWireInstructionsPage.swiftCodeFieldLabel')}
            value={wireInstructions.swiftCode}
            isMasked={false}
          />
          <Spacer size={32} />
          <ParticipantInfoSection
            header={t('elements.transfer.depositWireInstructionsPage.accountHolderSectionHeader')}
            participantInfo={wireInstructions.accountHolder}
          />
          <Spacer size={32} />
          <ParticipantInfoSection
            header={t('elements.transfer.depositWireInstructionsPage.bankDetailsSectionHeader')}
            participantInfo={wireInstructions.bank}
          />
          <Spacer size={16} />
        </>
      )}
      {isError && <ErrorState onReload={onReload} />}
    </FlowFirstPageLayout>
  );
};

export default WireInstructionsPage;
