export enum PdfRowType {
  plain = 'plain',
  link = 'link',
  noLabelRow = 'noLabelRow',
  mt103 = 'mt103',
  mt103NoValue = 'mt103NoValue',
  mt103Indent1 = 'mt103Indent1',
  mt103Indent1NoValue = 'mt103Indent1NoValue',
  mt103Indent2 = 'mt103Indent2',
}
