import { ReactComponent as AAVEIcon } from 'elements/assets/icons/coins/AAVE.svg';
import { ReactComponent as AlgorandIcon } from 'elements/assets/icons/coins/algorand.svg';
import { ReactComponent as AvalancheIcon } from 'elements/assets/icons/coins/avalanche.svg';
import { ReactComponent as BasicAttentionTokenIcon } from 'elements/assets/icons/coins/basicAttentionToken.svg';
import { ReactComponent as BitcoinIcon } from 'elements/assets/icons/coins/bitcoin.svg';
import { ReactComponent as BitcoinCashIcon } from 'elements/assets/icons/coins/bitcoinCash.svg';
import { ReactComponent as BNBIcon } from 'elements/assets/icons/coins/bnb.svg';
import { ReactComponent as BinanceUSDIcon } from 'elements/assets/icons/coins/busd.svg';
import { ReactComponent as BinanceUSDEthereumIcon } from 'elements/assets/icons/coins/busdEthereum.svg';
import { ReactComponent as CardanoIcon } from 'elements/assets/icons/coins/cardano.svg';
import { ReactComponent as ChainlinkIcon } from 'elements/assets/icons/coins/chainlink.svg';
import AAVE from 'elements/assets/icons/coins/coloredSquared/AAVE.svg';
import algorand from 'elements/assets/icons/coins/coloredSquared/algorand.svg';
import avalanche from 'elements/assets/icons/coins/coloredSquared/avalanche.svg';
import basicAttentionToken from 'elements/assets/icons/coins/coloredSquared/basicAttentionToken.svg';
import bitcoin from 'elements/assets/icons/coins/coloredSquared/bitcoin.svg';
import bitcoinCash from 'elements/assets/icons/coins/coloredSquared/bitcoinCash.svg';
import bnb from 'elements/assets/icons/coins/coloredSquared/bnb.svg';
import busdCoin from 'elements/assets/icons/coins/coloredSquared/busd.svg';
import cardano from 'elements/assets/icons/coins/coloredSquared/cardano.svg';
import chainlink from 'elements/assets/icons/coins/coloredSquared/chainlink.svg';
import compound from 'elements/assets/icons/coins/coloredSquared/compound.svg';
import cosmos from 'elements/assets/icons/coins/coloredSquared/cosmos.svg';
import curveDAOToken from 'elements/assets/icons/coins/coloredSquared/curveDAOToken.svg';
import dogecoin from 'elements/assets/icons/coins/coloredSquared/dogecoin.svg';
import ethereum from 'elements/assets/icons/coins/coloredSquared/ethereum.svg';
import florins from 'elements/assets/icons/coins/coloredSquared/florins.svg';
import litecoin from 'elements/assets/icons/coins/coloredSquared/litecoin.svg';
import polkadot from 'elements/assets/icons/coins/coloredSquared/polkadot.svg';
import polygon from 'elements/assets/icons/coins/coloredSquared/polygon.svg';
import shibaInuCoin from 'elements/assets/icons/coins/coloredSquared/shibaInuCoin.svg';
import solana from 'elements/assets/icons/coins/coloredSquared/solana.svg';
import stellar from 'elements/assets/icons/coins/coloredSquared/stellar.svg';
import tezos from 'elements/assets/icons/coins/coloredSquared/tezos.svg';
import theGraph from 'elements/assets/icons/coins/coloredSquared/theGraph.svg';
import uniswap from 'elements/assets/icons/coins/coloredSquared/uniswap.svg';
import usdc from 'elements/assets/icons/coins/coloredSquared/usdc.svg';
import usdcEthereum from 'elements/assets/icons/coins/coloredSquared/usdcEthereum.svg';
import usdcPolygon from 'elements/assets/icons/coins/coloredSquared/usdcPolygon.svg';
import usdt from 'elements/assets/icons/coins/coloredSquared/usdt.svg';
import wrappedEth from 'elements/assets/icons/coins/coloredSquared/wrappedEth.svg';
import { ReactComponent as CompoundIcon } from 'elements/assets/icons/coins/compound.svg';
import { ReactComponent as CosmicIcon } from 'elements/assets/icons/coins/cosmos.svg';
import { ReactComponent as CurveDAOTokenIcon } from 'elements/assets/icons/coins/curveDAOToken.svg';
import { ReactComponent as DogecoinIcon } from 'elements/assets/icons/coins/dogecoin.svg';
import { ReactComponent as EthereumIcon } from 'elements/assets/icons/coins/ethereum.svg';
import { ReactComponent as FlorinsIcon } from 'elements/assets/icons/coins/florins.svg';
import { ReactComponent as LitecoinIcon } from 'elements/assets/icons/coins/litecoin.svg';
import { ReactComponent as PolkadotIcon } from 'elements/assets/icons/coins/polkadot.svg';
import { ReactComponent as PolygonIcon } from 'elements/assets/icons/coins/polygon.svg';
// TODO add support for trading later
// import { ReactComponent as PolygonEthereumIcon } from 'elements/assets/icons/coins/polygonEthereum.svg';
import { ReactComponent as AvalancheShadedSquaredIcon } from 'elements/assets/icons/coins/shadedSquared/avalanche.svg';
import { ReactComponent as BinanceCoinShadedSquaredIcon } from 'elements/assets/icons/coins/shadedSquared/binanceCoin.svg';
import { ReactComponent as BitcoinShadedSquaredIcon } from 'elements/assets/icons/coins/shadedSquared/bitcoin.svg';
import { ReactComponent as BnbShadedSquaredIcon } from 'elements/assets/icons/coins/shadedSquared/bnb.svg';
import { ReactComponent as CardanoShadedSquaredIcon } from 'elements/assets/icons/coins/shadedSquared/cardano.svg';
import { ReactComponent as EthereumShadedSquaredIcon } from 'elements/assets/icons/coins/shadedSquared/ethereum.svg';
import { ReactComponent as PolkadotShadedSquaredIcon } from 'elements/assets/icons/coins/shadedSquared/polkadot.svg';
import { ReactComponent as PolygonShadedSquaredIcon } from 'elements/assets/icons/coins/shadedSquared/polygon.svg';
import { ReactComponent as SolanaShadedSquaredIcon } from 'elements/assets/icons/coins/shadedSquared/solana.svg';
import { ReactComponent as ShibaInuCoinIcon } from 'elements/assets/icons/coins/shibaInuCoin.svg';
import { ReactComponent as SolanaIcon } from 'elements/assets/icons/coins/solana.svg';
import { ReactComponent as StellarIcon } from 'elements/assets/icons/coins/stellar.svg';
import { ReactComponent as TezosIcon } from 'elements/assets/icons/coins/tezos.svg';
import { ReactComponent as TheGraphIcon } from 'elements/assets/icons/coins/theGraph.svg';
import { ReactComponent as UniswapIcon } from 'elements/assets/icons/coins/uniswap.svg';
import { ReactComponent as UsdCoinIcon } from 'elements/assets/icons/coins/usdCoin.svg';
import { ReactComponent as UsdCoinEthereumIcon } from 'elements/assets/icons/coins/usdCoinEthereum.svg';
import { ReactComponent as UsdCoinPolygonIcon } from 'elements/assets/icons/coins/usdCoinPolygon.svg';
import { ReactComponent as UsdtIcon } from 'elements/assets/icons/coins/usdt.svg';
import { ReactComponent as WrappedETH } from 'elements/assets/icons/coins/wrappedEth.svg';
import { ReactComponent as WrappedETHPolygonIcon } from 'elements/assets/icons/coins/wrappedEthPolygon.svg';
import {
  AdditionalCryptoAsset,
  AdditionalCryptoAssetNetwork,
  AdditionalCryptoAssetSymbols,
  CombinedCryptoAssetNetwork,
  CryptoAssetNetwork,
  CryptoAssetSymbols,
  CryptoAssetType,
} from 'elements/types';

export const cryptoAssetMapping = {
  [CryptoAssetType.ether]: CryptoAssetSymbols.ETH,
  [CryptoAssetType.matic]: CryptoAssetSymbols.MAT,
  [CryptoAssetType.florins]: CryptoAssetSymbols.FLRNS,
  [CryptoAssetType.bitcoin]: CryptoAssetSymbols.BTC,
  [CryptoAssetType.usdCoin]: CryptoAssetSymbols.USDC,
  [CryptoAssetType.solana]: CryptoAssetSymbols.SOL,
  [CryptoAssetType.wrappedETH]: CryptoAssetSymbols.WETH,
  [CryptoAssetType.ada]: CryptoAssetSymbols.ADA,
  [CryptoAssetType.avax]: CryptoAssetSymbols.AVAX,
  [CryptoAssetType.shib]: CryptoAssetSymbols.SHIB,
  [CryptoAssetType.bat]: CryptoAssetSymbols.BAT,
  [CryptoAssetType.dot]: CryptoAssetSymbols.DOT,
  [CryptoAssetType.bnb]: CryptoAssetSymbols.BNB,
  [CryptoAssetType.busd]: CryptoAssetSymbols.BUSD,
  [AdditionalCryptoAsset.algo]: AdditionalCryptoAssetSymbols.ALGO,
  [AdditionalCryptoAsset.link]: AdditionalCryptoAssetSymbols.LINK,
  [AdditionalCryptoAsset.xlm]: AdditionalCryptoAssetSymbols.XLM,
  [AdditionalCryptoAsset.ltc]: AdditionalCryptoAssetSymbols.LTC,
  [AdditionalCryptoAsset.uni]: AdditionalCryptoAssetSymbols.UNI,

  [AdditionalCryptoAsset.doge]: AdditionalCryptoAssetSymbols.DOGE,
  [AdditionalCryptoAsset.crv]: AdditionalCryptoAssetSymbols.CRV,
  [AdditionalCryptoAsset.aave]: AdditionalCryptoAssetSymbols.AAVE,
  [AdditionalCryptoAsset.bch]: AdditionalCryptoAssetSymbols.BCH,
  [AdditionalCryptoAsset.xtz]: AdditionalCryptoAssetSymbols.XTZ,
  [AdditionalCryptoAsset.comp]: AdditionalCryptoAssetSymbols.COMP,

  [AdditionalCryptoAsset.grt]: AdditionalCryptoAssetSymbols.GRT,
  [AdditionalCryptoAsset.atom]: AdditionalCryptoAssetSymbols.ATOM,
  [AdditionalCryptoAsset.usdt]: AdditionalCryptoAssetSymbols.USDT,
};

export const cryptoAssetIconMapping = {
  [CryptoAssetType.ether]: EthereumIcon,
  [CryptoAssetType.matic]: PolygonIcon,
  [CryptoAssetType.florins]: FlorinsIcon,
  [CryptoAssetType.bitcoin]: BitcoinIcon,
  [CryptoAssetType.usdCoin]: UsdCoinIcon,
  [CryptoAssetType.solana]: SolanaIcon,
  [CryptoAssetType.wrappedETH]: WrappedETH,
  [CryptoAssetType.ada]: CardanoIcon,
  [CryptoAssetType.avax]: AvalancheIcon,
  [CryptoAssetType.shib]: ShibaInuCoinIcon,
  [CryptoAssetType.bat]: BasicAttentionTokenIcon,
  [CryptoAssetType.dot]: PolkadotIcon,
  [CryptoAssetType.bnb]: BNBIcon,
  [CryptoAssetType.busd]: BinanceUSDIcon,
  [AdditionalCryptoAsset.algo]: AlgorandIcon,
  [AdditionalCryptoAsset.link]: ChainlinkIcon,
  [AdditionalCryptoAsset.xlm]: StellarIcon,
  [AdditionalCryptoAsset.ltc]: LitecoinIcon,
  [AdditionalCryptoAsset.uni]: UniswapIcon,

  [AdditionalCryptoAsset.doge]: DogecoinIcon,
  [AdditionalCryptoAsset.crv]: CurveDAOTokenIcon,
  [AdditionalCryptoAsset.aave]: AAVEIcon,
  [AdditionalCryptoAsset.bch]: BitcoinCashIcon,
  [AdditionalCryptoAsset.xtz]: TezosIcon,
  [AdditionalCryptoAsset.comp]: CompoundIcon,

  [AdditionalCryptoAsset.grt]: TheGraphIcon,
  [AdditionalCryptoAsset.atom]: CosmicIcon,
  [AdditionalCryptoAsset.usdt]: UsdtIcon,
};

export const cryptoIconMapping = {
  // Ethereum
  [CryptoAssetNetwork.eth_mainnet]: EthereumIcon,
  [CryptoAssetNetwork.eth_goerli]: EthereumIcon,
  [CryptoAssetNetwork.eth_sepolia]: EthereumIcon,
  // Polygon
  [CryptoAssetNetwork.matic_polygonMainnet]: PolygonIcon,
  [CryptoAssetNetwork.matic_polygonMumbai]: PolygonIcon,
  // TODO add support for trading later
  // Polygon on Ethereum
  // [CryptoAssetNetwork.matic_mainnet]: PolygonEthereumIcon,
  // [CryptoAssetNetwork.matic_goerli]: PolygonEthereumIcon,
  // Florins
  [CryptoAssetNetwork.flrns_mainnet]: FlorinsIcon,
  [CryptoAssetNetwork.flrns_goerli]: FlorinsIcon,
  // Bitcoin
  [CryptoAssetNetwork.btc_bitcoinMainnet]: BitcoinIcon,
  [CryptoAssetNetwork.btc_bitcoinTestnet]: BitcoinIcon,
  // USD Coin
  [CryptoAssetNetwork.usdc_mainnet]: UsdCoinIcon,
  [CryptoAssetNetwork.usdc_goerli]: UsdCoinIcon,
  [CryptoAssetNetwork.usdc_sepolia]: UsdCoinIcon,
  // USD Coin on Polygon
  [CryptoAssetNetwork.usdc_polygonMainnet]: UsdCoinPolygonIcon,
  [CryptoAssetNetwork.usdc_polygonMumbai]: UsdCoinPolygonIcon,
  // Solana
  [CryptoAssetNetwork.sol_solanaMainnet]: SolanaIcon,
  [CryptoAssetNetwork.sol_solanaDevnet]: SolanaIcon,
  // Wrapped ETH
  [CryptoAssetNetwork.weth_mainnet]: WrappedETH,
  [CryptoAssetNetwork.weth_goerli]: WrappedETH,
  // Wrapped ETH on Polygon
  [CryptoAssetNetwork.weth_polygonMainnet]: WrappedETHPolygonIcon,
  [CryptoAssetNetwork.weth_polygonMumbai]: WrappedETHPolygonIcon,
  // Cardano
  [CryptoAssetNetwork.ada_cardanoMainnet]: CardanoIcon,
  [CryptoAssetNetwork.ada_cardanoTestnet]: CardanoIcon,
  // Avalanche
  [CryptoAssetNetwork.avax_avalancheMainnet]: AvalancheIcon,
  [CryptoAssetNetwork.avax_avalancheFuji]: AvalancheIcon,
  // Polkadot
  [CryptoAssetNetwork.dot_polkadotMainnet]: PolkadotIcon,
  [CryptoAssetNetwork.dot_polkadotWestend]: PolkadotIcon,
  // Shiba Inu
  [CryptoAssetNetwork.shib_mainnet]: ShibaInuCoinIcon,
  [CryptoAssetNetwork.shib_goerli]: ShibaInuCoinIcon,
  // Basic Attention Token
  [CryptoAssetNetwork.bat_mainnet]: BasicAttentionTokenIcon,
  [CryptoAssetNetwork.bat_goerli]: BasicAttentionTokenIcon,
  // BNB
  [CryptoAssetNetwork.bnb_bnbSmartChainMainnet]: BNBIcon,
  [CryptoAssetNetwork.bnb_bnbSmartChainTestnet]: BNBIcon,
  // Binance USD
  [CryptoAssetNetwork.busd_bnbSmartChainMainnet]: BinanceUSDIcon,
  [CryptoAssetNetwork.busd_bnbSmartChainTestnet]: BinanceUSDIcon,
  // Binance USD on Ethereum
  [CryptoAssetNetwork.busd_mainnet]: BinanceUSDEthereumIcon,
  [CryptoAssetNetwork.busd_goerli]: BinanceUSDEthereumIcon,
  // Stellar
  [AdditionalCryptoAssetNetwork.xlm_stellarMainnet]: StellarIcon,
  [AdditionalCryptoAssetNetwork.xlm_stellarTestnet]: StellarIcon,
  // Chainlink
  [AdditionalCryptoAssetNetwork.link_mainnet]: ChainlinkIcon,
  [AdditionalCryptoAssetNetwork.link_goerli]: ChainlinkIcon,
  // Algorand
  [AdditionalCryptoAssetNetwork.algo_algorandMainnet]: AlgorandIcon,
  [AdditionalCryptoAssetNetwork.algo_algorandTestnet]: AlgorandIcon,
  // Litecoin
  [AdditionalCryptoAssetNetwork.ltc_litecoinMainnet]: LitecoinIcon,
  [AdditionalCryptoAssetNetwork.ltc_litecoinTestnet]: LitecoinIcon,
  // Uniswap
  [AdditionalCryptoAssetNetwork.uni_mainnet]: UniswapIcon,
  [AdditionalCryptoAssetNetwork.uni_goerli]: UniswapIcon,

  // Dogecoin
  [AdditionalCryptoAssetNetwork.doge_dogeMainnet]: DogecoinIcon,
  [AdditionalCryptoAssetNetwork.doge_dogeTestnet]: DogecoinIcon,
  // Curve DAO Token
  [AdditionalCryptoAssetNetwork.crv_mainnet]: CurveDAOTokenIcon,
  [AdditionalCryptoAssetNetwork.crv_goerli]: CurveDAOTokenIcon,
  // AAVE
  [AdditionalCryptoAssetNetwork.aave_mainnet]: AAVEIcon,
  [AdditionalCryptoAssetNetwork.aave_goerli]: AAVEIcon,
  // Bitcoin Cash
  [AdditionalCryptoAssetNetwork.bch_bchMainnet]: BitcoinCashIcon,
  [AdditionalCryptoAssetNetwork.bch_bchTestnet]: BitcoinCashIcon,
  // Tezos
  [AdditionalCryptoAssetNetwork.xtz_tezosMainnet]: TezosIcon,
  [AdditionalCryptoAssetNetwork.xtz_tezosTestnet]: TezosIcon,
  // Compound
  [AdditionalCryptoAssetNetwork.comp_mainnet]: CompoundIcon,
  [AdditionalCryptoAssetNetwork.comp_goerli]: CompoundIcon,

  // The graph
  [AdditionalCryptoAssetNetwork.grt_mainnet]: TheGraphIcon,
  [AdditionalCryptoAssetNetwork.grt_goerli]: TheGraphIcon,
  // Cosmos
  [AdditionalCryptoAssetNetwork.atom_cosmosHub]: CosmicIcon,
  [AdditionalCryptoAssetNetwork.atom_cosmosHubTestnet]: CosmicIcon,
  // Usdt
  [AdditionalCryptoAssetNetwork.usdt_mainnet]: UsdtIcon,
  [AdditionalCryptoAssetNetwork.usdt_goerli]: UsdtIcon,
};

export const networkIconMapping = {
  [CryptoAssetNetwork.eth_mainnet]: EthereumShadedSquaredIcon,
  [CryptoAssetNetwork.eth_goerli]: EthereumShadedSquaredIcon,
  [CryptoAssetNetwork.eth_sepolia]: EthereumShadedSquaredIcon,
  [CryptoAssetNetwork.matic_polygonMainnet]: PolygonShadedSquaredIcon,
  [CryptoAssetNetwork.matic_polygonMumbai]: PolygonShadedSquaredIcon,
  // TODO add support for trading later
  // [CryptoAssetNetwork.matic_mainnet]: EthereumShadedSquaredIcon,
  // [CryptoAssetNetwork.matic_goerli]: EthereumShadedSquaredIcon,
  [CryptoAssetNetwork.flrns_mainnet]: EthereumShadedSquaredIcon,
  [CryptoAssetNetwork.flrns_goerli]: EthereumShadedSquaredIcon,
  [CryptoAssetNetwork.btc_bitcoinMainnet]: BitcoinShadedSquaredIcon,
  [CryptoAssetNetwork.btc_bitcoinTestnet]: BitcoinShadedSquaredIcon,
  [CryptoAssetNetwork.usdc_mainnet]: EthereumShadedSquaredIcon,
  [CryptoAssetNetwork.usdc_goerli]: EthereumShadedSquaredIcon,
  [CryptoAssetNetwork.usdc_sepolia]: EthereumShadedSquaredIcon,
  [CryptoAssetNetwork.usdc_polygonMainnet]: PolygonShadedSquaredIcon,
  [CryptoAssetNetwork.usdc_polygonMumbai]: PolygonShadedSquaredIcon,
  [CryptoAssetNetwork.sol_solanaMainnet]: SolanaShadedSquaredIcon,
  [CryptoAssetNetwork.sol_solanaDevnet]: SolanaShadedSquaredIcon,
  [CryptoAssetNetwork.weth_mainnet]: EthereumShadedSquaredIcon,
  [CryptoAssetNetwork.weth_goerli]: EthereumShadedSquaredIcon,
  [CryptoAssetNetwork.weth_polygonMainnet]: PolygonShadedSquaredIcon,
  [CryptoAssetNetwork.weth_polygonMumbai]: PolygonShadedSquaredIcon,
  [CryptoAssetNetwork.ada_cardanoMainnet]: CardanoShadedSquaredIcon,
  [CryptoAssetNetwork.ada_cardanoTestnet]: CardanoShadedSquaredIcon,
  [CryptoAssetNetwork.avax_avalancheMainnet]: AvalancheShadedSquaredIcon,
  [CryptoAssetNetwork.avax_avalancheFuji]: AvalancheShadedSquaredIcon,
  [CryptoAssetNetwork.dot_polkadotMainnet]: PolkadotShadedSquaredIcon,
  [CryptoAssetNetwork.dot_polkadotWestend]: PolkadotShadedSquaredIcon,
  [CryptoAssetNetwork.shib_mainnet]: EthereumShadedSquaredIcon,
  [CryptoAssetNetwork.shib_goerli]: EthereumShadedSquaredIcon,
  [CryptoAssetNetwork.bat_mainnet]: EthereumShadedSquaredIcon,
  [CryptoAssetNetwork.bat_goerli]: EthereumShadedSquaredIcon,
  [CryptoAssetNetwork.bnb_bnbSmartChainMainnet]: BnbShadedSquaredIcon,
  [CryptoAssetNetwork.bnb_bnbSmartChainTestnet]: BnbShadedSquaredIcon,
  [CryptoAssetNetwork.busd_bnbSmartChainMainnet]: BinanceCoinShadedSquaredIcon,
  [CryptoAssetNetwork.busd_bnbSmartChainTestnet]: BinanceCoinShadedSquaredIcon,
  [CryptoAssetNetwork.busd_mainnet]: EthereumShadedSquaredIcon,
  [CryptoAssetNetwork.busd_goerli]: EthereumShadedSquaredIcon,
};

export const CombinedCryptoAssetNetworkToIconSrcMapping: {
  [key in keyof typeof CombinedCryptoAssetNetwork]: string;
} = {
  [CombinedCryptoAssetNetwork.eth_mainnet]: ethereum,
  [CombinedCryptoAssetNetwork.eth_goerli]: ethereum,
  [CombinedCryptoAssetNetwork.eth_sepolia]: ethereum,
  [CombinedCryptoAssetNetwork.matic_polygonMainnet]: polygon,
  [CombinedCryptoAssetNetwork.matic_polygonMumbai]: polygon,
  // TODO add support for trading later
  // [CombinedCryptoAssetNetwork.matic_mainnet]: polygon,
  // [CombinedCryptoAssetNetwork.matic_goerli]: polygon,
  [CombinedCryptoAssetNetwork.flrns_mainnet]: florins,
  [CombinedCryptoAssetNetwork.flrns_goerli]: florins,
  [CombinedCryptoAssetNetwork.btc_bitcoinMainnet]: bitcoin,
  [CombinedCryptoAssetNetwork.btc_bitcoinTestnet]: bitcoin,
  [CombinedCryptoAssetNetwork.usdc_mainnet]: usdcEthereum,
  [CombinedCryptoAssetNetwork.usdc_goerli]: usdcEthereum,
  [CombinedCryptoAssetNetwork.usdc_sepolia]: usdcEthereum,
  [CombinedCryptoAssetNetwork.usdc_polygonMainnet]: usdcPolygon,
  [CombinedCryptoAssetNetwork.usdc_polygonMumbai]: usdcPolygon,
  [CombinedCryptoAssetNetwork.sol_solanaMainnet]: solana,
  [CombinedCryptoAssetNetwork.sol_solanaDevnet]: solana,
  [CombinedCryptoAssetNetwork.weth_mainnet]: wrappedEth,
  [CombinedCryptoAssetNetwork.weth_goerli]: wrappedEth,
  [CombinedCryptoAssetNetwork.weth_polygonMainnet]: wrappedEth,
  [CombinedCryptoAssetNetwork.weth_polygonMumbai]: wrappedEth,
  [CombinedCryptoAssetNetwork.ada_cardanoMainnet]: cardano,
  [CombinedCryptoAssetNetwork.ada_cardanoTestnet]: cardano,
  [CombinedCryptoAssetNetwork.avax_avalancheMainnet]: avalanche,
  [CombinedCryptoAssetNetwork.avax_avalancheFuji]: avalanche,
  [CombinedCryptoAssetNetwork.dot_polkadotMainnet]: polkadot,
  [CombinedCryptoAssetNetwork.dot_polkadotWestend]: polkadot,
  [CombinedCryptoAssetNetwork.shib_mainnet]: shibaInuCoin,
  [CombinedCryptoAssetNetwork.shib_goerli]: shibaInuCoin,
  [CombinedCryptoAssetNetwork.bat_mainnet]: basicAttentionToken,
  [CombinedCryptoAssetNetwork.bat_goerli]: basicAttentionToken,
  [CombinedCryptoAssetNetwork.bnb_bnbSmartChainMainnet]: bnb,
  [CombinedCryptoAssetNetwork.bnb_bnbSmartChainTestnet]: bnb,
  [CombinedCryptoAssetNetwork.busd_bnbSmartChainMainnet]: busdCoin,
  [CombinedCryptoAssetNetwork.busd_bnbSmartChainTestnet]: busdCoin,
  [CombinedCryptoAssetNetwork.busd_mainnet]: busdCoin,
  [CombinedCryptoAssetNetwork.busd_goerli]: busdCoin,
  [CombinedCryptoAssetNetwork.xlm_stellarMainnet]: stellar,
  [CombinedCryptoAssetNetwork.xlm_stellarTestnet]: stellar,
  [CombinedCryptoAssetNetwork.link_mainnet]: chainlink,
  [CombinedCryptoAssetNetwork.link_goerli]: chainlink,
  [CombinedCryptoAssetNetwork.uni_mainnet]: uniswap,
  [CombinedCryptoAssetNetwork.uni_goerli]: uniswap,
  [CombinedCryptoAssetNetwork.algo_algorandMainnet]: algorand,
  [CombinedCryptoAssetNetwork.algo_algorandTestnet]: algorand,
  [CombinedCryptoAssetNetwork.ltc_litecoinMainnet]: litecoin,
  [CombinedCryptoAssetNetwork.ltc_litecoinTestnet]: litecoin,
  [CombinedCryptoAssetNetwork.doge_dogeMainnet]: dogecoin,
  [CombinedCryptoAssetNetwork.doge_dogeTestnet]: dogecoin,
  [CombinedCryptoAssetNetwork.crv_mainnet]: curveDAOToken,
  [CombinedCryptoAssetNetwork.crv_goerli]: curveDAOToken,
  [CombinedCryptoAssetNetwork.aave_mainnet]: AAVE,
  [CombinedCryptoAssetNetwork.aave_goerli]: AAVE,
  [CombinedCryptoAssetNetwork.bch_bchMainnet]: bitcoinCash,
  [CombinedCryptoAssetNetwork.bch_bchTestnet]: bitcoinCash,
  [CombinedCryptoAssetNetwork.xtz_tezosMainnet]: tezos,
  [CombinedCryptoAssetNetwork.xtz_tezosTestnet]: tezos,
  [CombinedCryptoAssetNetwork.comp_mainnet]: compound,
  [CombinedCryptoAssetNetwork.comp_goerli]: compound,
  [CombinedCryptoAssetNetwork.grt_mainnet]: theGraph,
  [CombinedCryptoAssetNetwork.grt_goerli]: theGraph,
  [CombinedCryptoAssetNetwork.atom_cosmosHub]: cosmos,
  [CombinedCryptoAssetNetwork.atom_cosmosHubTestnet]: cosmos,
  [CombinedCryptoAssetNetwork.usdt_mainnet]: usdt,
  [CombinedCryptoAssetNetwork.usdt_goerli]: usdt,
};

export const tradeCoinAssetNetworkIconMapping = {
  // USD Coin
  [CryptoAssetNetwork.usdc_mainnet]: UsdCoinEthereumIcon,
  [CryptoAssetNetwork.usdc_goerli]: UsdCoinEthereumIcon,
  [CryptoAssetNetwork.usdc_sepolia]: UsdCoinEthereumIcon,
  // USD Coin on Polygon
  [CryptoAssetNetwork.usdc_polygonMainnet]: UsdCoinPolygonIcon,
  [CryptoAssetNetwork.usdc_polygonMumbai]: UsdCoinPolygonIcon,
};
