export const addSpaceAfterEvery4Chars = (element: string) => {
  return element
    .split('')
    .map((s, index, arr) => {
      if ((index + 1) % 4 === 0 && index !== arr.length - 1) {
        return `${s} `;
      }
      return s;
    })
    .join('');
};
