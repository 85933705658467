import { getDynamicAssetLabel } from 'utils/labels-mapping/get-dynamic-asset-label';
import { LabelLength } from 'ui-enums/label-length';
import { AssetResponseModel } from 'models/response/assets/asset-response-model';
import { ICoinListItem } from 'ui-interfaces/elements/i-coin-list-item';
import { AssetByNetworkIconSize } from 'ui-enums/asset-by-network-icon-size';
import { getStaticDefaultIcon } from 'utils/icons-mapping/get-static-default-icon';
import { mapAssetByNetworkIconSizeToNumber } from 'utils/multi-mapping/asset-by-network-icon-size/map-asset-by-network-icon-size-to-number';

export const mapAssetResponseModelToICoinListItem = (
  assetResponse: AssetResponseModel,
  assetIconSize = AssetByNetworkIconSize['40px'],
): ICoinListItem => {
  return {
    asset: {
      imageUrl: assetResponse.assetIconXs,
      shortName: getDynamicAssetLabel(LabelLength.short, assetResponse.assetTicker, assetResponse.assetName),
      longName: getDynamicAssetLabel(LabelLength.long, assetResponse.assetTicker, assetResponse.assetName),
      defaultAssetIcon: getStaticDefaultIcon(assetResponse.assetTicker, assetIconSize),
    },
    size: mapAssetByNetworkIconSizeToNumber(assetIconSize),
  };
};
