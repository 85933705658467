import React from 'react';
import { Typography } from '@mui/material';

type Props = {
  label?: string;
};

const HeaderRow: React.FC<Props> = ({ label }) => {
  if (!label) {
    return null;
  }

  return <Typography variant="h6">{label}</Typography>;
};

export default HeaderRow;
