import React from 'react';
import { MenuItem, ListItemIcon, Typography } from '@mui/material';
import { ActionCellAction } from 'ui-interfaces/users/action-cell-action';

interface Props extends Omit<ActionCellAction, 'isDisplayed'> {
  onClick: () => void;
}

export const ActionCellItem: React.FC<Props> = ({ icon, label, onClick }) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <Typography variant="bodyDefaultBook">{label}</Typography>
    </MenuItem>
  );
};
