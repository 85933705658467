import i18n from 'localizations';
import { RowType } from 'ui-enums/transaction-detail/row-type';
import { TransactionDetailNode } from 'ui-interfaces/transaction-details/transaction-detail-node';
import { formatDateAndTimeInUTC } from 'utils/format-date';
import { ITextRow } from 'ui-interfaces/transaction-details/rows/i-text-row';
import { TransactionDetailResponseModel } from 'models/response/transactions/transaction-detail-response-model';

export const generateFedReferenceSection = (imad: TransactionDetailResponseModel['imad']): TransactionDetailNode[] => {
  if (!imad) {
    return [];
  }

  return [
    {
      id: 'fedReference',
      blockHeaderLabel: i18n.t('transactionDetailsPage.fedReference'),
      blockItems: [
        {
          id: 'imadNumber',
          label: i18n.t('transactionDetailsPage.labels.imadNumberLabel'),
          uiElementType: RowType.text,
          value: imad.raw || i18n.t('transactionDetailsPage.labels.noData'),
        } as ITextRow,
        {
          id: 'dateInitiated',
          label: i18n.t('transactionDetailsPage.labels.dateLabel'),
          uiElementType: RowType.text,
          value: imad.parsed?.cycleDate
            ? `${formatDateAndTimeInUTC(imad?.parsed?.cycleDate)}`
            : i18n.t('transactionDetailsPage.labels.noData'),
        } as ITextRow,
        {
          id: 'sourceIdentifier',
          label: i18n.t('transactionDetailsPage.labels.sourceIdentifierLabel'),
          uiElementType: RowType.text,
          value: imad.parsed?.inputSource || i18n.t('transactionDetailsPage.labels.noData'),
        } as ITextRow,
        {
          id: 'inputSequenceNumber',
          label: i18n.t('transactionDetailsPage.labels.sequenceNumberLabel'),
          uiElementType: RowType.text,
          value: imad.parsed?.inputSequenceNumber || i18n.t('transactionDetailsPage.labels.noData'),
        } as ITextRow,
      ],
    },
  ];
};
