import React from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as ErrorIcon } from 'assets/icons/error-banner-icon.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/success-banner-icon.svg';
import { MessageType } from 'redux/banner';
import { StyledAlert, StyledSnackbar } from './styled';

const iconMapping = {
  success: <SuccessIcon />,
  error: <ErrorIcon />,
};

interface BannerProps {
  isOpen: boolean;
  onClose?: () => void;
  title: string;
  type: MessageType;
}

export const Banner: React.FC<BannerProps> = ({ isOpen, onClose, title, type }) => (
  <StyledSnackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    key={new Date().getTime()}
    open={isOpen}
    onClose={onClose}
  >
    <StyledAlert iconMapping={iconMapping} severity={type as MessageType} type={type as MessageType} onClose={onClose}>
      <Typography variant="bodyDefaultBook" color="background.paper">
        {title}
      </Typography>
    </StyledAlert>
  </StyledSnackbar>
);
