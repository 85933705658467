import { LabelLength } from 'ui-enums/label-length';
import { getDynamicAssetLabelLong } from './get-dynamic-asset-label-long';
import { getDynamicAssetLabelShort } from './get-dynamic-asset-label-short';

export const getDynamicAssetLabel = (length: LabelLength, assetTicker: string, assetName: string): string => {
  switch (length) {
    case LabelLength.long:
      return getDynamicAssetLabelLong(assetTicker, assetName);
    case LabelLength.short:
      return getDynamicAssetLabelShort(assetTicker);
    default:
      return getDynamicAssetLabelLong(assetTicker, assetName);
  }
};
