import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import * as api from 'elements/element-trade/api';
import { isUnavailableServiceError } from 'elements/api';
import { IdentityVerification, VerificationType } from 'elements/features/IdentityVerification';
import { LoadingPage } from 'elements/features/LoadingErrorState';
import { setErrorBanner } from 'elements/utils';
import {
  setCustodialAccountList,
  setIsServiceUnavailable,
  setPage,
  useElement,
} from 'elements/element-trade/contexts/Element';
import { Paths } from 'elements/element-trade/navigation/routes';
import { ElementName } from 'elements/models/enums/element-name';
import { WindowWithFortressElementsJS } from 'elements/models/types/window-with-fortress-elemets-js';
import { ElementClient } from 'elements/models/types/element-client';
import { ElementResultStatus } from 'elements/models/enums/element-result-status';

const IdentityVerificationPage = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { state: elementState, dispatch } = useElement();

  const verificationType = elementState.page.state?.verificationType as VerificationType;
  const [isLoading, setIsLoading] = useState(false);
  const [kycElement, setKycElement] = useState<ElementClient | null>(null);

  const [isGetCustodialAccountLoading, setIsGetCustodialAccountLoading] = useState(false);

  const onBack = (): void => {
    dispatch(setPage(Paths.Root));
  };

  const onLetsGo = async () => {
    try {
      setIsLoading(true);
      const { jwt } = await api.tradeElement.getKYCJwt(elementState.custodialAccountId);

      if (typeof (window as WindowWithFortressElementsJS).FortressElementsJS !== 'undefined') {
        const EJS = (window as WindowWithFortressElementsJS).FortressElementsJS;
        const el = EJS.createElementClient({
          elementName: ElementName.KYC,
          ...(elementState.contactUsUrl ? { contactUsUrl: elementState.contactUsUrl } : {}),
        });
        setKycElement(el);

        el.run(jwt);
      }
    } catch (e) {
      setIsLoading(false);
      setErrorBanner(e, enqueueSnackbar, true);
    }
  };

  const getCustodialAccount = async () => {
    try {
      setIsGetCustodialAccountLoading(true);

      const { data: custodialAccountList } = await api.tradeElement.getCustodialAccounts(
        elementState.custodialAccountId,
      );

      dispatch(setCustodialAccountList(custodialAccountList));
      dispatch(setIsServiceUnavailable(false));
      return true;
    } catch (e) {
      if (isUnavailableServiceError(e)) {
        dispatch(setIsServiceUnavailable(true));
        return false;
      }
      setErrorBanner(e, enqueueSnackbar, true);
      return false;
    } finally {
      setIsGetCustodialAccountLoading(false);
    }
  };

  useEffect(() => {
    if (kycElement) {
      kycElement.done(async result => {
        kycElement.destroy();
        setKycElement(null);

        if (result.status === ElementResultStatus.Success) {
          // eslint-disable-next-line no-console
          console.log('Personal identity successfully upgraded!');
          const isSuccess = await getCustodialAccount();
          dispatch(setPage(isSuccess ? Paths.Review : Paths.BuySell));
        } else {
          // eslint-disable-next-line no-console
          console.log('Personal identity was not upgraded');
          dispatch(setPage(Paths.BuySell));
        }
        setIsLoading(false);
      });
    }
    return () => kycElement?.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycElement]);

  return isLoading || isGetCustodialAccountLoading ? (
    <LoadingPage />
  ) : (
    <IdentityVerification
      pageTitle={t('elements.trade.customizableLabels.title')}
      verificationType={verificationType}
      onBack={onBack}
      onLetsGo={verificationType === VerificationType.VerifyIdentity ? onLetsGo : undefined}
      contactUsHref={verificationType === VerificationType.InactiveAccount ? elementState.contactUsUrl : null}
    />
  );
};

export default IdentityVerificationPage;
