import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import { Gap } from 'components/shared/Gap';
import { ReactComponent as ClockIcon } from 'assets/icons/transactions/clock.svg';
import { RightCellBox } from 'components/shared/detail-page/rows/shared/RightCellBox';

type Props = {
  value: string;
};

export const RightCell: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();
  return (
    <RightCellBox>
      <Typography color="grey.600" variant="bodyDefaultBook">
        {value}
      </Typography>
      <Gap isHorizontal size="_12px" />
      <CommonTooltip
        placement="top"
        title={
          <div>
            <Typography color="background.paper" variant="bodySmallBook">
              {t('transactionsPage.achWithdrawalTooltipMsgPart1')}
            </Typography>
            <br />
            <Typography color="background.paper" variant="bodySmallBook">
              {t('transactionsPage.achWithdrawalTooltipMsgPart2')}
            </Typography>
          </div>
        }
      >
        <ClockIcon />
      </CommonTooltip>
    </RightCellBox>
  );
};
