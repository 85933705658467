import { useEffect, useState } from 'react';
import { PathMatch, useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NESTED_PATHS, PATHS } from 'navigation/constants';
import { BreadCrumbsItem } from 'components/shared/Breadcrumbs';
import {
  CustodialAccountDetailsUnderIdentityDetailsUrlParams,
  CustodialAccountsDetailsUrlParams,
  IdentityDetailsUrlParams,
  TransactionDetailsUnderCustodialAccountDetailsUnderIdentityDetailsUrlParams,
  TransactionDetailsUnderCustodialAccountDetailsUrlParams,
} from 'navigation/url-params-type';
import { getRouteWithParams } from 'utils/get-route-with-params';

export const useBcTapiTransactionDetailsPage = () => {
  const MATCHES_TRANSACTION_DETAILS = useMatch({
    path: PATHS.TAPI.TRANSACTION_DETAILS,
    end: true,
  });

  const MATCHES_IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__TRANSACTION_DETAILS = useMatch({
    path: NESTED_PATHS.IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__TRANSACTION_DETAILS,
    end: true,
  });

  const MATCHES_CUSTODIAL_ACCOUNT_DETAILS__TRANSACTION_DETAILS = useMatch({
    path: NESTED_PATHS.CUSTODIAL_ACCOUNTS_DETAILS__TRANSACTION_DETAILS,
    end: true,
  });

  const { t } = useTranslation();
  const [breadCrumbs, setBreadCrumbs] = useState<BreadCrumbsItem[]>([]);
  const [pathMatch, setPathMatch] = useState<PathMatch | null>(null);

  useEffect(() => {
    if (MATCHES_TRANSACTION_DETAILS) {
      setBreadCrumbs([
        {
          label: t('transactionDetailsPage.breadcrumbs.transactions'),
          path: PATHS.TAPI.TRANSACTIONS,
        },
        {
          label: t('transactionDetailsPage.breadcrumbs.transactionDetails'),
        },
      ]);
      setPathMatch(MATCHES_TRANSACTION_DETAILS);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (MATCHES_IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__TRANSACTION_DETAILS) {
      const { custodialAccountId, identityId, identityType } =
        MATCHES_IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__TRANSACTION_DETAILS.params as TransactionDetailsUnderCustodialAccountDetailsUnderIdentityDetailsUrlParams;

      setBreadCrumbs([
        {
          label: t('identityDetailsPage.breadcrumbs.identities'),
          path: PATHS.IDENTITIES,
        },
        {
          label: t('identityDetailsPage.breadcrumbs.identityDetails'),
          path: getRouteWithParams(PATHS.IDENTITY_DETAILS, {
            identityId,
            identityType,
          } as IdentityDetailsUrlParams),
        },
        {
          label: t('custodialAccountsPage.breadcrumbs.account'),
          path: getRouteWithParams(NESTED_PATHS.IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS, {
            custodialAccountId,
            identityId,
            identityType,
          } as CustodialAccountDetailsUnderIdentityDetailsUrlParams),
        },
        {
          label: t('transactionDetailsPage.breadcrumbs.transactionDetails'),
        },
      ]);
      setPathMatch(MATCHES_IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__TRANSACTION_DETAILS);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (MATCHES_CUSTODIAL_ACCOUNT_DETAILS__TRANSACTION_DETAILS) {
      const { custodialAccountId } =
        MATCHES_CUSTODIAL_ACCOUNT_DETAILS__TRANSACTION_DETAILS.params as TransactionDetailsUnderCustodialAccountDetailsUrlParams;

      setBreadCrumbs([
        {
          label: t('custodialAccountsPage.breadcrumbs.accounts'),
          path: PATHS.TAPI.CUSTODIAL_ACCOUNTS,
        },
        {
          label: t('custodialAccountsPage.breadcrumbs.account'),
          path: getRouteWithParams(PATHS.TAPI.CUSTODIAL_ACCOUNTS_DETAILS, {
            custodialAccountId,
          } as CustodialAccountsDetailsUrlParams),
        },
        {
          label: t('transactionDetailsPage.breadcrumbs.transactionDetails'),
        },
      ]);
      setPathMatch(MATCHES_CUSTODIAL_ACCOUNT_DETAILS__TRANSACTION_DETAILS);
    }
    // eslint-disable-next-line
  }, []);

  return { breadCrumbs, pathMatch };
};
