import React from 'react';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/icons/grey-close-icon.svg';
import { Gap } from 'components/shared/Gap';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledIconButton,
  StyledRow,
  StyledTypography,
} from './styled';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  sourceOrganizationName: string;
  question: string;
  amount: string;
  actionButton: React.ReactNode;
};

export const ActionDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  sourceOrganizationName,
  question,
  amount,
  actionButton,
}) => {
  const { t } = useTranslation();

  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <StyledDialogTitle variant="h4">
        {t('transactionDetailsPage.actionDialog.title')}
        <StyledIconButton onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <StyledRow>
          <Typography variant="bodyDefaultBook" color="grey.600">
            {question}
          </Typography>
        </StyledRow>
        <Gap size="_24px" />
        <StyledRow>
          <StyledTypography variant="bodyDefaultMedium">{sourceOrganizationName}</StyledTypography>
        </StyledRow>
        <Gap size="_4px" />
        <StyledRow>
          <StyledTypography variant="h4">{amount}</StyledTypography>
        </StyledRow>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" size="large" onClick={onClose}>
          <Typography variant="buttonMedium">{t('transactionDetailsPage.actionDialog.cancelButtonLabel')}</Typography>
        </Button>
        <Gap isHorizontal size="_16px" />
        {actionButton}
      </StyledDialogActions>
    </StyledDialog>
  );
};
