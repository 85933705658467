import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import { Column } from 'components/shared/Flex/Column';
import { Row } from 'components/shared/Flex/Row';
import { Gap } from 'components/shared/Gap';
import { IImageRow } from 'ui-interfaces/token-details/rows/i-image-row';
import { pxToRem } from 'utils/styling-utils';
import { StyledAuthorTypography, StyledContainer } from './styled';
import { ImageThumbnail } from './ImageThumbnail';

type Props = {
  data: IImageRow;
};

export const ImageRow: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <ImageThumbnail imageUrl={data.imageUrl} mediaType={data.mediaType} />
      <Gap size="_16px" isHorizontal />
      <Column>
        <Typography variant="h6" color="grey.700" component={Box} flexWrap="wrap" width={pxToRem(704)}>
          {data.tokenName}
        </Typography>
        <Gap size="_16px" />
        <Row>
          {data.collection && (
            <>
              <Typography variant="bodyDefaultMedium" color="grey.600">
                {t('nft.nftDetails.collectionLabel')}
              </Typography>
              <Gap size="_8px" isHorizontal />
              <StyledAuthorTypography variant="bodyDefaultBook">{data.collection}</StyledAuthorTypography>
              <Gap size="_24px" isHorizontal />
            </>
          )}
          {data.creator && (
            <>
              <Typography variant="bodyDefaultMedium" color="grey.600">
                {t('nft.nftDetails.creatorLabel')}
              </Typography>
              <Gap size="_8px" isHorizontal />
              <StyledAuthorTypography variant="bodyDefaultBook">{data.creator}</StyledAuthorTypography>
            </>
          )}
        </Row>
        {(data.collection || data.creator) && <Gap size="_12px" />}
        <Typography variant="bodyDefaultBook" color="grey.600" component={Box} flexWrap="wrap" width={pxToRem(704)}>
          {data.description || t('nft.nftDetails.noDescription')}
        </Typography>
      </Column>
    </StyledContainer>
  );
};
