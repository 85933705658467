import React from 'react';
import { Backdrop } from '@mui/material';
import { CircularProgress, LayoutModal } from 'elements/components';
import { LayoutModalProps } from 'elements/components/LayoutModal';

export const LoadingPage: React.FC<LayoutModalProps> = ({ isAbove = true, ...props }) => (
  <LayoutModal withHeader={false} withFooter isAbove={isAbove} {...props}>
    <Backdrop open style={{ backgroundColor: 'transparent' }} {...(props.onClose ? { onClick: props.onClose } : {})}>
      <CircularProgress />
    </Backdrop>
  </LayoutModal>
);
