import React from 'react';
import { LeftCellBox } from 'components/shared/detail-page/rows/shared/LeftCellBox';
import { StyledSkeleton } from '../styled';

export const LoadingLeftCell: React.FC = () => {
  return (
    <LeftCellBox>
      <StyledSkeleton animation="pulse" variant="rectangular" />
    </LeftCellBox>
  );
};
