import { Typography } from '@mui/material';
import { css } from '@mui/material/styles';
import { Stack } from 'elements/components';
import { ReactComponent as BankAccountIcon } from 'elements/element-transfer/assets/icons/bankAccount.svg';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledContainer = elementsStyled(Stack)(
  ({ theme }) => css`
    width: 100%;
    border: 1px solid ${theme.palette.tertiary.main};
    border-radius: 8px;
  `,
);

export const StyledNameContainer = elementsStyled(Stack)(
  css`
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 16px;
  `,
);

export const StyledBankInfoContainer = elementsStyled(Stack)(
  css`
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;
    gap: 16px;
    overflow: hidden;
  `,
);

export const StyledTruncatedTypography = elementsStyled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledTruncatedCountryTypography = elementsStyled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

export const StyledNoWrapTypography = elementsStyled(Typography)`
  white-space: nowrap;
`;

export const StyledBankAccountIcon = elementsStyled(BankAccountIcon)`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;
