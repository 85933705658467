import React from 'react';
import { Box } from '@mui/material';
import { AssetImage } from 'components/shared/Cells/AssetImage';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import { ITransactionAssetCell } from 'ui-interfaces/row-interfaces/transactions/i-transaction-asset-cell';

type Props = {
  data: ITransactionAssetCell;
};

export const TransactionAssetCell: React.FC<Props> = ({ data }) => {
  return (
    <CommonTooltip placement="top" title={data.asset.longName}>
      <Box display="flex" alignItems="center">
        <AssetImage url={data.asset.imageUrl} size={data.size} defaultAssetIcon={data.asset.defaultAssetIcon} />
      </Box>
    </CommonTooltip>
  );
};
