import { styled } from '@mui/material/styles';
import { CircularProgress, Button } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';

export const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: pxToRem(114),
  marginTop: pxToRem(theme.gaps._16px),
}));

export const StyledLoader = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.grey[400],
  width: pxToRem(theme.gaps._16px),
  height: pxToRem(theme.gaps._16px),
}));
