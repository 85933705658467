import React from 'react';
import { useSelector } from 'react-redux';
import { ProtectedOutlet } from 'navigation/utils/ProtectedOutlet';
import { PATHS } from 'navigation/constants';
import { getUser } from 'redux/api/api-users';

export const ErrorRouteRestriction: React.FC = () => {
  const { error } = useSelector(getUser.select(undefined));

  return <ProtectedOutlet fallbackRoute={PATHS.INDEX} fallbackCondition={!error} />;
};
