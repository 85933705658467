import { UsersListRow } from 'ui-interfaces/row-interfaces/users/users-list-row';
import { UserBaseResponseModel } from 'models/response/users/user-base-response-model';
import { StatusCellProps } from 'ui-interfaces/statuses/status-cell-props';
import { AvatarCellProps } from 'ui-interfaces/users/avatar-cell-props';
import { ActionCellProps } from 'ui-interfaces/users/action-cell-props';
import { mapRoleToRoleCellProps } from 'utils/multi-mapping/role/map-role-to-role-cell-props';
import { formatDate } from 'utils/format-date';
import { getAvatarProps } from 'utils/settings/get-avatar-props';
import { formatPhoneNumber } from 'utils/format-phone-number';
import { getUserRoleLabel } from 'utils/labels-mapping/get-user-role-label';
import { RoleTemplate } from 'models/enums/user/role-template';

export class UserBaseViewModel implements UsersListRow {
  isCurrentUser = false;

  id: string;

  name: string = '';

  email: string = '';

  phone: string = '';

  roleTemplate: RoleTemplate = RoleTemplate.viewer;

  createdAt: string = '';

  roleCellProps: StatusCellProps;

  avatarCellProps: AvatarCellProps;

  isActive: boolean;

  actionsCellProps: ActionCellProps = { actions: [] };

  destroyModalMainText = '';

  constructor(user: UserBaseResponseModel) {
    this.id = user.id;
    this.name = `${user.firstName} ${user.lastName}`;
    this.email = user.email;
    this.phone = formatPhoneNumber(user.phone);
    this.roleTemplate = user.roleTemplate;
    this.createdAt = user.createdAt ? formatDate(user.createdAt) : '';
    this.roleCellProps = mapRoleToRoleCellProps(user.roleTemplate);
    this.avatarCellProps = getAvatarProps(`${user.firstName} ${user.lastName}`);
    this.isActive = user.isActive;
    this.destroyModalMainText = `${this.name} (${getUserRoleLabel(user.roleTemplate)})`;
  }
}
