import React, { useState, useRef, useLayoutEffect } from 'react';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import { isOverflown } from 'utils/helpers';
import { pxToRem } from 'utils/styling-utils';
import { TypographyVariant } from 'styles/themes/types';
import { StyledTypography } from './styled';

type Props = {
  value: string;
  width: number;
  color?: string;
  variant?: TypographyVariant;
};

export const GridCellExpand: React.FC<Props> = ({ value, width, color, variant }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const valueRef = useRef(null);

  useLayoutEffect(() => {
    const isValueCropped = isOverflown(valueRef.current!);
    setShowTooltip(isValueCropped);
  }, [value]);

  return (
    <CommonTooltip
      title={value}
      placement="top"
      disableHoverListener={!showTooltip}
      disableFocusListener={!showTooltip}
      disableTouchListener={!showTooltip}
    >
      <StyledTypography
        sx={{
          maxWidth: pxToRem(width),
        }}
        variant={variant}
        ref={valueRef}
        color={color}
      >
        {value}
      </StyledTypography>
    </CommonTooltip>
  );
};
