import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { useResetPasswordMutation } from 'redux/api/api-users';
import { setBannerData, MessageType } from 'redux/banner';
import { ActionCellAction } from 'ui-interfaces/users/action-cell-action';
import { ActionCellItem } from '../../ActionCellItem';

interface Props extends Omit<ActionCellAction, 'isDisplayed'> {
  userId: string;
  onCloseMenu: () => void;
}

export const ResetActionItem: React.FC<Props> = ({ userId, onCloseMenu, ...action }) => {
  const dispatch = useDispatch();
  const [resetPasswordTrigger, resetPasswordResult] = useResetPasswordMutation();

  useEffect(() => {
    if (resetPasswordResult.isLoading) {
      return;
    }
    if (resetPasswordResult.isSuccess) {
      dispatch(
        setBannerData({
          type: MessageType.success,
          message: t('settingsPage.banner.resetPasswordSuccessMessage'),
        }),
      );
      onCloseMenu();
    }
    if (resetPasswordResult.isError) {
      onCloseMenu();
    }
  }, [dispatch, resetPasswordResult, onCloseMenu]);

  const onResetClick = () => {
    resetPasswordTrigger({ userId });
  };
  return <ActionCellItem {...action} onClick={onResetClick} />;
};
