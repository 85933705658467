import { Filterable } from 'ui-interfaces/shared/filters/filterable';
import { Filter } from 'filters/interfaces/filter';
import { SelectionMacroCommand } from 'filters/commands/selection-macro-command';
import { getNonApprovedTransactionsFilters } from 'utils/transactions/list/get-non-approved-transactions-filters';
import { createRejectedTransactionsFilters } from './create-rejected-transactions-filters';

export class RejectedTransactionsListFiltersViewModel implements Filterable {
  readonly filters: Filter[];

  readonly selectionMacroCommand: SelectionMacroCommand;

  constructor() {
    const filterable = getNonApprovedTransactionsFilters(createRejectedTransactionsFilters());
    this.filters = filterable.filters;
    this.selectionMacroCommand = filterable.selectionMacroCommand;
  }
}
