import React from 'react';
import { IPlainRow } from 'ui-interfaces/identity-details/rows/i-plain-row';
import { RowBox } from './RowBox';
import { LeftCell } from './LeftCell';
import { RightCell } from './RightCell';

type Props = {
  data: IPlainRow;
  hasBottomBorder?: boolean;
};

export const TextMultiLineRow: React.FC<Props> = ({ data, hasBottomBorder = true }) => {
  return (
    <RowBox hasBottomBorder={hasBottomBorder}>
      <LeftCell value={data.label} />
      <RightCell value={data.value} />
    </RowBox>
  );
};
