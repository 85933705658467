import React from 'react';
import { alpha } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { SideMenuItem } from 'components/shared/SideMenu/SideMenuItem';
import { SideMenuListItem } from 'ui-interfaces/side-menu/side-menu-list-item';
import { SideMenuListNode } from 'ui-interfaces/side-menu/side-menu-list-node';
import { StyledSectionHeader } from './styled';
import { StyledList } from '../styled';

type Props = {
  node: SideMenuListNode;
  isSideMenuOpened: boolean;
};

export const SideMenuSection: React.FC<Props> = ({ node, isSideMenuOpened }) => {
  return (
    <>
      <Divider sx={{ bgcolor: theme => alpha(theme.palette.background.paper, 0.08) }} />
      {node.title ? (
        <StyledSectionHeader variant="overlineSmallBold" color="background.paper" open={isSideMenuOpened}>
          {isSideMenuOpened ? node.title : node.titleCollapsed}
        </StyledSectionHeader>
      ) : null}
      <StyledList>
        {node.items.map((el: SideMenuListItem) => (
          <SideMenuItem key={el.label} el={el} open={isSideMenuOpened} />
        ))}
      </StyledList>
    </>
  );
};
