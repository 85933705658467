import i18n from 'localizations';
import { TransactionDetailNode } from 'ui-interfaces/transaction-details/transaction-detail-node';
import { RowType } from 'ui-enums/transaction-detail/row-type';

export const loadingNodes: TransactionDetailNode[] = [
  {
    id: 'section_1',
    blockItems: [
      {
        id: '1',
        uiElementType: RowType.loading,
      },

      {
        id: '2',
        uiElementType: RowType.loading,
      },
      {
        id: '3',
        uiElementType: RowType.loading,
      },
      {
        id: '4',
        uiElementType: RowType.loading,
      },
    ],
  },
  {
    id: 'section_2',
    blockHeaderLabel: i18n.t('transactionDetailsPage.source'),
    blockItems: [
      {
        id: '5',
        uiElementType: RowType.loading,
      },
      {
        id: '6',
        uiElementType: RowType.loading,
      },
    ],
  },
  {
    id: 'section_3',
    blockHeaderLabel: i18n.t('transactionDetailsPage.destination'),
    blockItems: [
      {
        id: '7',
        uiElementType: RowType.loading,
      },
      {
        id: '8',
        uiElementType: RowType.loading,
      },
    ],
  },
];
