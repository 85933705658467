import React from 'react';
import { IDefaultRow } from 'ui-interfaces/user-details/rows/i-default-row';
import { LeftCell } from './LeftCell';
import { RightCell } from './RightCell';
import { RowBox } from './RowBox';

type Props = {
  data: IDefaultRow;
  hasBottomBorder?: boolean;
};

export const ShortRow: React.FC<Props> = ({ data, hasBottomBorder = true }) => {
  return (
    <RowBox hasBottomBorder={hasBottomBorder}>
      <LeftCell value={data.label} />
      <RightCell value={data.value} />
    </RowBox>
  );
};
