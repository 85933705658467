import React from 'react';
import { Typography } from '@mui/material';
import { CompoundLabel } from 'ui-interfaces/row-interfaces/transactions';
import { GridCellExpand } from 'components/shared/Cells/GridCellExpand';
import { Row } from 'components/shared/Flex/Row';
import { Gap } from 'components/shared/Gap';

type Props = {
  value: CompoundLabel | string;
  width: number;
};

export const CompoundLabelCell: React.FC<Props> = ({ value, width }) => {
  if (typeof value === 'string') {
    return <GridCellExpand value={value} width={width} />;
  }

  return (
    <div>
      <Row>
        <Typography variant="bodyDefaultItalic">{`${value.start} `}</Typography>
      </Row>
      <Gap size="_2px" />
      <Row>
        <Typography color="grey.600" variant="bodyDefaultBook">
          {value.end}
        </Typography>
      </Row>
    </div>
  );
};
