import { css } from '@mui/material/styles';
import { Stack } from 'elements/components';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { preventForwardProps } from 'elements/utils';

export const StyledStack = elementsStyled(
  Stack,
  preventForwardProps(['isLoading']),
)<{ isLoading?: boolean }>(
  ({ isLoading }) =>
    css`
      align-items: ${isLoading ? 'center' : 'flex-start'};
      gap: 12px;
    `,
);
