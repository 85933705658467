import React from 'react';
import { Box, Typography } from '@mui/material';
import { Spacer } from 'elements/components/Spacer';
import { AssetImage } from 'components/shared/Cells/AssetImage';
import { ICoinListItem } from 'ui-interfaces/elements/i-coin-list-item';
import {
  StyledAmountContainer,
  StyledArrowForwardIosIcon,
  StyledContainer,
  StyledItem,
  StyledLeftSection,
  StyledRightSection,
  StyledRow,
  StyledTypography,
} from './styled';

type Props = {
  data: ICoinListItem;
  onClick?: () => void;
  withArrow?: boolean;
  withDivider?: boolean;
};

export const CoinListItemNew: React.FC<Props> = ({ data, onClick, withArrow = true, withDivider = true }) => {
  return (
    <StyledItem onClick={onClick} withDivider={withDivider}>
      <AssetImage url={data.asset.imageUrl} size={data.size} defaultAssetIcon={data.asset.defaultAssetIcon} />
      <StyledContainer>
        <StyledRow width="100%">
          <StyledLeftSection>
            <StyledTypography variant="bodyDefaultMedium" color="primary.font">
              {data.asset.longName}
            </StyledTypography>
          </StyledLeftSection>
          <StyledRightSection>
            <StyledAmountContainer>
              <Spacer isHorizontal size={3} />
              <Typography fontWeight="500" component={Box} variant="bodyDefaultMedium" color="secondary.font">
                {data.asset.shortName}
              </Typography>
              {withArrow && <StyledArrowForwardIosIcon />}
            </StyledAmountContainer>
          </StyledRightSection>
        </StyledRow>
      </StyledContainer>
    </StyledItem>
  );
};
