import React, { useEffect, useState } from 'react';
import { Row } from 'components/shared/Flex/Row';
import { GuidCell } from 'components/shared/Cells/GuidCell';
import { StatusCell } from 'components/shared/Cells/StatusCell';
import { IdentityKycLevel } from 'components/identities/IdentityDetailsPageContent/IdentityKycLevel';
import { BeneficialOwnerRow } from 'ui-interfaces/row-interfaces/identities/beneficial-owner-row';
import { ReactComponent as OwnerIcon } from 'assets/icons/identity-details/personal.svg';
import { isOverflown } from 'utils/helpers';
import { StyledTooltip } from 'components/shared/StyledTooltip';
import { OwnerName, StyledDivider, IDItem, PillItem, NameItem, OwnerRow } from './styled';

interface IBeneficialOwnersRow extends BeneficialOwnerRow {
  isLastItem: boolean;
  onRowClick: (row: Pick<BeneficialOwnerRow, 'id' | 'businessIdentityId' | 'ownerType'>) => void;
}

export const BeneficialOwnersRow: React.FC<IBeneficialOwnersRow> = ({
  id,
  businessIdentityId,
  ownerName,
  ownerType,
  kycLevel,
  isLastItem,
  onRowClick,
  statusCellProps,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const titleRef = React.useRef<HTMLSpanElement | null>(null);
  useEffect(() => {
    const isTitleCurrentlyOverflown = isOverflown(titleRef.current!);
    setShowTooltip(isTitleCurrentlyOverflown);
  }, []);

  const onClick = () => {
    onRowClick({ id, ownerType, businessIdentityId });
  };
  return (
    <>
      <Row>
        <NameItem>
          <StyledTooltip title={ownerName} disableHoverListener={!showTooltip} disableFocusListener={!showTooltip}>
            <OwnerRow onClick={onClick}>
              <OwnerIcon />
              <OwnerName ref={titleRef} color="primary" variant="bodyDefaultBookLink">
                {ownerName}
              </OwnerName>
            </OwnerRow>
          </StyledTooltip>
        </NameItem>
        <IDItem>
          <GuidCell id={id} />
        </IDItem>
        <PillItem>
          <IdentityKycLevel kycLevel={kycLevel} />
        </PillItem>
        <PillItem>
          <StatusCell {...statusCellProps} />
        </PillItem>
      </Row>
      {!isLastItem && <StyledDivider />}
    </>
  );
};
