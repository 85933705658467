import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserDetailsPageContent } from 'components/settings/users/UserDetailsPageContent';
import { useBcUserDetailsPage } from 'hooks/breadcrumbs/use-bc-user-details-page';
import { UserDetails } from 'ui-interfaces/user-details/user-details';
import { UserDetailsUrlParams } from 'navigation/url-params-type';
import {
  getUser,
  useLazyGetUserByIdQuery,
  UserByIdApiParams,
  getRoleTemplates,
  useLazyGetRoleTemplatesQuery,
} from 'redux/api/api-users';
import { createUserDetailsViewModel } from 'factories/users/create-user-details-vm';

export const UserDetailsPage: React.FC = () => {
  const { breadCrumbs } = useBcUserDetailsPage();
  const { userId } = useParams<UserDetailsUrlParams>();

  // get data for user details page - start
  const { data: user } = useSelector(getUser.select(undefined));
  const { data: roleTemplates } = useSelector(getRoleTemplates.select(undefined));

  const [getUserByIdTrigger, userByIdResult] = useLazyGetUserByIdQuery();

  const fetchUserById = useCallback(
    () => getUserByIdTrigger({ userId: userId! } as UserByIdApiParams),
    [getUserByIdTrigger, userId],
  );

  const [userDetails, setUserDetails] = useState<UserDetails>();

  const { isLoading: areRoleTemplatesLoading } = useSelector(getRoleTemplates.select(undefined));
  const [fetchRoleTemplates] = useLazyGetRoleTemplatesQuery();
  useEffect(() => {
    fetchUserById();
    fetchRoleTemplates(undefined);
  }, [fetchRoleTemplates, fetchUserById]);
  useEffect(() => {
    if (userByIdResult.isSuccess && user) {
      setUserDetails(createUserDetailsViewModel(userByIdResult.data, user, roleTemplates?.data));
    }
  }, [userByIdResult, user, roleTemplates]);
  // get data for user details page - end
  const isLoading = userByIdResult.isFetching || areRoleTemplatesLoading;

  return (
    <UserDetailsPageContent
      breadCrumbs={breadCrumbs}
      isError={userByIdResult.isError}
      isLoading={isLoading}
      userDetails={userDetails}
    />
  );
};
