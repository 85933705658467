import React from 'react';
import { AssetByNetwork } from 'ui-enums/response/asset-by-network';
import { ReactComponent as UsdIcon } from 'assets/icons/asset-by-network-icons/size-36x36/usd.svg';
import { ReactComponent as CustomAsset } from 'assets/icons/asset-by-network-icons/size-36x36/custom-asset.svg';
import { ReactComponent as DefaultAssetIcon } from 'assets/icons/default-asset-icon/default-asset-icon-36x36.svg';
import { ReactComponent as GoldIcon } from 'assets/icons/asset-by-network-icons/size-36x36/gold.svg';
import { ReactComponent as SilverIcon } from 'assets/icons/asset-by-network-icons/size-36x36/silver.svg';

export const getStaticDefaultIconComponentForTransaction = (
  assetTicker: string,
  isPrivateSecurity = false,
): React.FunctionComponent<React.SVGProps<SVGSVGElement>> => {
  if (isPrivateSecurity) {
    return CustomAsset;
  }

  switch (assetTicker.toLowerCase()) {
    case AssetByNetwork.usd.toLowerCase():
      return UsdIcon;
    case AssetByNetwork.gold.toLowerCase():
      return GoldIcon;
    case AssetByNetwork.silver.toLowerCase():
      return SilverIcon;
    default:
      return DefaultAssetIcon;
  }
};
