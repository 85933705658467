import React from 'react';
import { OnCloseElement } from 'elements/models/types/element-result';
import useWireWithdrawalRecipientSearchPage from 'elements/element-transfer/hooks/useWireWithdrawalRecipientSearchPage';

import ContentBlock from 'elements/element-transfer/components/Withdrawal/WireRecipientSearchPage';

type Props = {
  onClose: OnCloseElement;
};

const WireRecipientSearchPage: React.FC<Props> = ({ onClose }) => {
  const {
    search,
    recipientList,
    isRecipientsLoading,
    recipientId,
    lastItemRef,
    onBack,
    onAddNewRecipient,
    onSearch,
    onContinue,
  } = useWireWithdrawalRecipientSearchPage();

  return (
    <ContentBlock
      search={search}
      recipients={recipientList}
      isRecipientsLoading={isRecipientsLoading}
      recipientId={recipientId}
      lastItemRef={lastItemRef}
      onAddNewRecipient={onAddNewRecipient}
      onSearch={onSearch}
      onClose={onClose}
      onBack={onBack}
      onContinue={onContinue}
    />
  );
};

export default WireRecipientSearchPage;
