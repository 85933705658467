import { css } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as InfoIcon } from 'elements/assets/icons/info.svg';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledInfoIcon = elementsStyled(InfoIcon)(
  ({ theme }) => css`
    color: ${theme.palette.secondary.font};
  `,
);

export const StyledIconButton = elementsStyled(IconButton)`
  padding: 0;
`;
