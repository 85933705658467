import { getDynamicAssetLabel } from 'utils/labels-mapping/get-dynamic-asset-label';
import { LabelLength } from 'ui-enums/label-length';
import { AssetByNetworkIconSize } from 'ui-enums/asset-by-network-icon-size';
import { getStaticDefaultIcon } from 'utils/icons-mapping/get-static-default-icon';
import { mapAssetByNetworkIconSizeToNumber } from 'utils/multi-mapping/asset-by-network-icon-size/map-asset-by-network-icon-size-to-number';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import { ISellCoinListItem } from 'ui-interfaces/elements/i-sell-coin-list-item';

export const mapCustodialAccountBalanceModelToISellCoinListItem = (
  cab: CustodialAccountBalanceModel,
): ISellCoinListItem => {
  const assetIconSize = AssetByNetworkIconSize['40px'];

  return {
    asset: {
      imageUrl: cab.assetIconXs,
      shortName: getDynamicAssetLabel(LabelLength.short, cab.assetTicker, cab.assetName),
      longName: getDynamicAssetLabel(LabelLength.long, cab.assetTicker, cab.assetName),
      defaultAssetIcon: getStaticDefaultIcon(cab.assetTicker, assetIconSize),
    },
    size: mapAssetByNetworkIconSizeToNumber(assetIconSize),
    balance: cab.disbursable,
  };
};
