import React from 'react';
import { Route } from 'react-router-dom';
import { LoginPage } from 'containers/auth/LoginPage';
import { PATHS } from 'navigation/constants';
import { PublicRoutesRestriction } from 'navigation/restrictions/PublicRoutesRestriction';

const authRoutes = (): React.ReactNode => (
  <Route element={<PublicRoutesRestriction />}>
    <Route path={PATHS.LOG_IN} element={<LoginPage />} />
  </Route>
);

export default authRoutes;
