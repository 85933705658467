import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';
import { LeftCellWidth } from 'components/shared/detail-page/rows/shared/types';
import { DEFAULT_LEFT_CELL_WIDTH, LARGE_LEFT_CELL_WIDTH } from 'components/shared/detail-page/rows/shared/constants';
import { UserDetailsRowHeight } from 'ui-enums/user-details/user-details-row-height';
import { omitCustomStyleProps } from 'utils/omitCustomStyleProps';

type StyledBoxProps = {
  large?: boolean;
};

export const StyledBox = styled(
  Box,
  omitCustomStyleProps(['large']),
)<BoxProps & StyledBoxProps>(({ theme, large }) => {
  const boxWidth: LeftCellWidth = large ? LARGE_LEFT_CELL_WIDTH : DEFAULT_LEFT_CELL_WIDTH;

  return {
    height: pxToRem(UserDetailsRowHeight['45px']),
    width: pxToRem(boxWidth),
    display: 'flex',
    alignItems: 'flex-start',
    paddingRight: pxToRem(theme.gaps._24px),
  };
});
