import { styled } from '@mui/material/styles';
import { Box, Typography, alpha, css, Link } from '@mui/material';
import { pxToRem, pxToVh, pxToVw } from 'utils/styling-utils';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import Button from 'components/shared/buttons/Button';
import { Row } from 'components/shared/Flex/Row';

export const StyledPageContainer = styled(Box)(
  ({ theme }) => css`
    height: 100vh;
    position: relative;
    display: flex;
    padding: ${pxToVh(theme.gaps._48px)} ${pxToVw(theme.gaps._56px)};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(260.49deg, #4589ee 0%, #100565 124.19%);
    overflow: hidden;
  `,
);

export const StyledLightenBox = styled(Box)(
  ({ theme }) => css`
      position: absolute;
      width: ${pxToVw(1995)};
      height: ${pxToVh(1994)};
      left: 38%;
      top: -19.5%;
      background-color: ${alpha(theme.palette.background.paper, 0.15)};
      filter: blur(250px);
    }
  `,
);

export const StyledRow = styled(Row)(
  ({ theme }) => css`
    align-items: center;
    position: absolute;
    top: ${pxToVh(theme.gaps._48px)};
    left: ${pxToVw(theme.gaps._56px)};
    z-index: 1;
  `,
);

export const StyledLogo = styled(Logo)(
  ({ theme }) => css`
    width: 181px;
    height: 48px;
    color: ${theme.palette.background.paper};
  `,
);

export const StyledContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`;

export const StyledTitle = styled(Typography)(
  ({ theme }) => css`
    font-size: ${pxToRem(56)};
    text-transform: uppercase;
    font-weight: 700;
    line-height: 130%;
    text-align: center;
    color: ${theme.palette.background.paper};

    & em {
      font-style: inherit;
      background: linear-gradient(90deg, #00d4ef 1.25%, #8aef93 64.1%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  `,
);

export const StyledDescriptionContent = styled(Box)`
  max-width: 760px;
  text-align: center;
`;

export const StyledDescription = styled(Typography)(
  ({ theme }) => css`
    font-size: ${pxToRem(18)};
    line-height: 180%;
    text-align: center;
    color: ${alpha(theme.palette.background.paper, 0.7)};
  `,
);

export const StyledButtonsContent = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: ${pxToRem(24)};
`;

export const StyledLoginButton = styled(Button)(
  ({ theme }) => css`
    display: flex;
    gap: ${pxToRem(14)};
    width: 166px;
    background-color: ${theme.palette.background.paper};
    color: ${theme.palette.secondary.main};

    & span {
      color: ${theme.palette.secondary.main};
    }

    &:hover {
      background-color: ${theme.palette.background.paper};
      color: ${theme.palette.secondary.dark2};

      & span {
        color: inherit;
      }
    }
  `,
);

export const StyledLink = styled(Link)(
  ({ theme }) => css`
    border-radius: ${pxToRem(theme.gaps._8px)};
    box-shadow: 0 0 0 1px ${alpha(theme.palette.background.paper, 0.5)};
    color: ${theme.palette.background.paper};
    padding: ${pxToRem(14)};
    min-width: 166px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${pxToRem(14)};

    & span {
      color: ${theme.palette.background.paper};
    }

    &:hover {
      background-color: ${alpha(theme.palette.background.paper, 0.05)};
    }
  `,
);
