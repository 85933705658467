import React, { forwardRef } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Typography } from '@mui/material';
import { StyledContainer } from './styled';

type Props = { message: string };

export const TextCopiedSnackbar = forwardRef<HTMLDivElement, Props>(({ message }, ref) => {
  return (
    <StyledContainer ref={ref}>
      <ContentCopyIcon />
      <Typography variant="bodyDefaultBook">{message}</Typography>
    </StyledContainer>
  );
});
