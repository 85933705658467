import React from 'react';
import { Text as _Text, View as _View } from '@react-pdf/renderer';
import { LeftCellBox } from 'components/tapi/transactions/TapiTransactionDetailsPage/pdf/rows/shared/LeftCellBox';
import { styles } from './styles';

const Text: any = _Text;
const View: any = _View;

type Props = {
  value: string;
};

export const LeftCell: React.FC<Props> = ({ value }) => {
  return (
    <LeftCellBox>
      <View style={styles.row}>
        <View style={styles.dot} />
        <Text style={styles.text}>{value}</Text>
      </View>
    </LeftCellBox>
  );
};
