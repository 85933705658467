import { t } from 'i18next';
import { DashboardRoles } from 'models/enums/user/dashboard-roles';

export const getPermissionCheckboxLabels = (permission: DashboardRoles): { title: string; description: string } => {
  switch (permission) {
    case DashboardRoles.paymentInitiator:
      return {
        title: t('createUserDialog.paymentInitiatorOptionTitle'),
        description: t('createUserDialog.paymentInitiatorOptionDescription'),
      };

    case DashboardRoles.paymentApprover:
      return {
        title: t('createUserDialog.paymentApproverOptionTitle'),
        description: t('createUserDialog.paymentApproverOptionDescription'),
      };

    case DashboardRoles.userManager:
      return {
        title: t('createUserDialog.userManagerOptionTitle'),
        description: t('createUserDialog.userManagerOptionDescription'),
      };

    case DashboardRoles.corporateAccountViewer:
      return {
        title: t('createUserDialog.corporateAccountViewerOptionTitle'),
        description: t('createUserDialog.corporateAccountViewerOptionDescription'),
      };

    case DashboardRoles.pii:
      return {
        title: t('createUserDialog.piiOptionTitle'),
        description: t('createUserDialog.piiOptionDescription'),
      };

    case DashboardRoles.caEditor:
      return {
        title: t('createUserDialog.caEditorOptionTitle'),
        description: t('createUserDialog.caEditorOptionDescription'),
      };

    case DashboardRoles.accountSuspender:
      return {
        title: t('createUserDialog.accountSuspenderOptionTitle'),
        description: t('createUserDialog.accountSuspenderOptionDescription'),
      };

    case DashboardRoles.identitySuspender:
      return {
        title: t('createUserDialog.identitySuspenderOptionTitle'),
        description: t('createUserDialog.identitySuspenderOptionDescription'),
      };

    default:
      return {
        title: t('common.notImplemented'),
        description: t('common.notImplemented'),
      };
  }
};
