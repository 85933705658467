import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import * as api from 'elements/element-transfer/api';
import { setCustodialAccount, setIdentityData, setPage, useElement } from 'elements/element-transfer/contexts/Element';
import { isUnavailableServiceError } from 'elements/api';
import { getVerificationData } from 'elements/features/IdentityCheck/utils/getVerificationData';
import { AccountStatusType, IdentityData, IdentityLevel } from 'elements/types';
import { setErrorBanner } from 'elements/utils';
import { ElementName } from 'elements/models/enums/element-name';
import { ElementClient } from 'elements/models/types/element-client';
import { WindowWithFortressElementsJS } from 'elements/models/types/window-with-fortress-elemets-js';
import { ElementResultStatus } from 'elements/models/enums/element-result-status';
import { Paths } from 'elements/element-transfer/navigation/routes';

const useIdentityCheck = (requiredKYCLevel?: IdentityLevel) => {
  const {
    state: { transferOptionsList, contactUsUrl, identityData, custodialAccount, custodialAccountId },
    dispatch,
  } = useElement();

  const [isVerificationLoading, setIsVerificationLoading] = useState(true);
  const [isVerificationError, setIsVerificationError] = useState(false);

  const [isKycLoading, setIsKycLoading] = useState(false);
  const [kycElement, setKycElement] = useState<ElementClient | null>(null);

  const [isGetCustodialAccountLoading, setIsGetCustodialAccountLoading] = useState(true);
  const [isGetCustodialAccountError, setIsGetCustodialAccountError] = useState(false);
  const [isServiceUnavailable, setIsServiceUnavailable] = useState(true);

  const withBackButton = transferOptionsList.length > 1;

  const getIdentityData = async () => {
    try {
      setIsVerificationLoading(true);

      const data = await api.transferElement.getIdentityData(custodialAccountId);
      dispatch(setIdentityData(data));
      setIsVerificationError(false);

      return data;
    } catch (e) {
      const isBannerError = setErrorBanner(e, enqueueSnackbar);

      if (isBannerError) {
        if (withBackButton) {
          dispatch(setPage(Paths.Transfer));
        }
      } else {
        setIsVerificationError(true);
      }
      return null;
    } finally {
      setIsVerificationLoading(false);
      setIsGetCustodialAccountLoading(false);
    }
  };

  const getCustodialAccount = async () => {
    try {
      setIsGetCustodialAccountLoading(true);
      setIsGetCustodialAccountError(false);

      const custodialAcc = await api.transferElement.getCustodialAccount(custodialAccountId);
      dispatch(setCustodialAccount(custodialAcc));
      setIsServiceUnavailable(false);
    } catch (e) {
      const isBannerError = setErrorBanner(e, enqueueSnackbar);

      if (isBannerError) {
        if (withBackButton) {
          dispatch(setPage(Paths.Transfer));
        }
        return;
      }

      if (isUnavailableServiceError(e)) {
        setIsServiceUnavailable(true);
        return;
      }

      setIsGetCustodialAccountError(true);
    } finally {
      setIsGetCustodialAccountLoading(false);
    }
  };

  const getIdentityDataAndCustodialAccount = async () => {
    const identityDataFromRequest: IdentityData | null = await getIdentityData();

    if (!identityDataFromRequest) {
      return;
    }

    const { isInactiveAccount, isNotVerified } = getVerificationData(
      identityDataFromRequest,
      custodialAccount,
      requiredKYCLevel || IdentityLevel.L1,
    );

    const isRequiredLevelIdentity = !isInactiveAccount && !isNotVerified;

    if (custodialAccountId && !custodialAccount && isRequiredLevelIdentity) {
      await getCustodialAccount();
    } else {
      setIsGetCustodialAccountLoading(false);
      setIsServiceUnavailable(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (
        !identityData ||
        !identityData.canUseCustodialAccounts ||
        identityData.status !== AccountStatusType.Active ||
        (requiredKYCLevel === IdentityLevel.L2 && identityData.kycLevel !== IdentityLevel.L2) ||
        (requiredKYCLevel === IdentityLevel.L1 && identityData.kycLevel !== IdentityLevel.L1) ||
        identityData.kycLevel === IdentityLevel.L0
      ) {
        await getIdentityDataAndCustodialAccount();
      } else {
        if (!custodialAccount) {
          await getCustodialAccount();
        } else {
          setIsGetCustodialAccountLoading(false);
          setIsServiceUnavailable(false);
        }

        setIsVerificationLoading(false);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onVerify = async () => {
    try {
      setIsKycLoading(true);

      const { jwt } = await api.transferElement.getKYCJwt(custodialAccountId);

      if (typeof (window as WindowWithFortressElementsJS).FortressElementsJS !== 'undefined') {
        const EJS = (window as WindowWithFortressElementsJS).FortressElementsJS;

        const el = EJS.createElementClient({
          elementName: ElementName.KYC,
          requiredKYCLevel,
          ...(contactUsUrl ? { contactUsUrl } : {}),
        });
        setKycElement(el);
        el.run(jwt);
      }
    } catch (e) {
      setIsKycLoading(false);
      setErrorBanner(e, enqueueSnackbar, true);
    }
  };
  const onReload = async () => {
    if (isVerificationError) {
      await getIdentityDataAndCustodialAccount();
    } else {
      await getCustodialAccount();
    }
  };

  useEffect(() => {
    if (kycElement) {
      kycElement.done(async result => {
        kycElement.destroy();
        setKycElement(null);

        if (result.status === ElementResultStatus.Success) {
          // eslint-disable-next-line no-console
          console.log('Personal identity successfully upgraded!');

          const identityDataFromRequest = await getIdentityData();

          if (identityDataFromRequest) {
            await getCustodialAccount();
          }
        } else {
          // eslint-disable-next-line no-console
          console.log('Personal identity was not upgraded');
        }
        setIsKycLoading(false);
      });
    }
    return () => kycElement?.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycElement]);

  return {
    isError: isGetCustodialAccountError || isVerificationError,
    isLoading: isVerificationLoading || isKycLoading || isGetCustodialAccountLoading,
    isServiceUnavailable,
    identityData,
    custodialAccount,
    contactUsUrl,
    onVerify,
    onReload,
  };
};

export default useIdentityCheck;
