import React from 'react';
import { DocumentStatus } from 'models/enums/documents/document-status';
import { StatusCellProps } from 'ui-interfaces/statuses/status-cell-props';

// labels - start
import { ReactComponent as AcceptedLabelIcon } from 'assets/icons/statuses/documents/labels/accepted.svg';
import { ReactComponent as AutomaticReviewLabelIcon } from 'assets/icons/statuses/documents/labels/automatic-review.svg';
import { ReactComponent as ManualReviewNeededLabelIcon } from 'assets/icons/statuses/documents/labels/manual-review-needed.svg';
import { ReactComponent as ResubmitNeededIcon } from 'assets/icons/statuses/documents/labels/resubmit-needed.svg';
import { ReactComponent as RejectedIcon } from 'assets/icons/statuses/shared/labels/rejected.svg';
import { ReactComponent as PendingLabelIcon } from 'assets/icons/statuses/shared/labels/pending.svg';
// labels - end

export const mapDocumentStatusToStatusCellProps = (status: DocumentStatus): StatusCellProps => {
  switch (status) {
    case DocumentStatus.accepted:
      return {
        labelIcon: <AcceptedLabelIcon />,
        pillowProps: {
          color: 'success',
          colorType: 'light2',
        },
      };

    case DocumentStatus.rejected:
      return {
        labelIcon: <RejectedIcon />,
        pillowProps: {
          color: 'error',
          colorType: 'light2',
        },
      };

    case DocumentStatus.automaticReviewInProgress:
      return {
        labelIcon: <AutomaticReviewLabelIcon />,
        pillowProps: {
          color: 'magenta',
          colorType: 'light',
        },
      };

    case DocumentStatus.manualReviewNeeded:
      return {
        labelIcon: <ManualReviewNeededLabelIcon />,
        pillowProps: {
          color: 'brown',
          colorType: 'light',
        },
      };

    case DocumentStatus.pending:
      return {
        labelIcon: <PendingLabelIcon />,
        pillowProps: {
          color: 'info',
          colorType: 'light3',
        },
      };

    case DocumentStatus.resubmit:
      return {
        labelIcon: <ResubmitNeededIcon />,
        pillowProps: {
          color: 'grey',
          colorType: 100,
        },
      };

    default:
      return {
        labelIcon: <AcceptedLabelIcon />,
        pillowProps: {
          color: 'success',
          colorType: 'light2',
        },
      };
  }
};
