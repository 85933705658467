import { trimZerosRight } from './stringUtils';

const FRACTION_PART_SEPARATOR = '.';

function formatPartsWithFractions(parts: string[], numberOfDecimals: number): string[] {
  /**
   * there are always 2 fraction related parts: '.' and fraction 'XXXXXXXX'
   */
  const numberOfFractionRelatedParts = 2;
  const formattedIntegerParts = parts.slice(0, -numberOfFractionRelatedParts);

  const fractionPart = parts[parts.length - 1];
  const formattedFractionPart = trimZerosRight(fractionPart.substring(0, numberOfDecimals)).padEnd(2, '0');

  return [...formattedIntegerParts, FRACTION_PART_SEPARATOR, formattedFractionPart];
}

function formatUsdAmount(balance: number, withCommas: boolean, decimals = 2) {
  const preFormatter = new Intl.NumberFormat('en', {
    style: 'decimal',
    minimumIntegerDigits: 1,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    useGrouping: withCommas,
  });
  const preFormattedParts = preFormatter.formatToParts(balance).map(part => part.value);
  const isFractionFormattingNeeded = decimals > 0;
  const defaultFractionPart = '00';
  const parts = isFractionFormattingNeeded
    ? formatPartsWithFractions(preFormattedParts, decimals)
    : [...preFormattedParts, FRACTION_PART_SEPARATOR, defaultFractionPart];

  return parts.join('');
}

const getNumberOfDecimalsByBalance = (balance: number, isMobile: boolean) => {
  switch (true) {
    case balance < 1 && !isMobile:
      return 6;
    case balance < 1 && isMobile:
      return 4;
    default:
      return 2;
  }
};

const formatUsdBalance = (balance: number | void, isMobile: boolean) => {
  if (balance === undefined) {
    return '$--';
  }

  if (balance === 0) {
    return '$0.00';
  }

  if (balance < 0.01) {
    return '< $0.01';
  }

  const value = formatUsdAmount(balance, true, getNumberOfDecimalsByBalance(balance, isMobile));
  return `$${value}`;
};

export { formatUsdBalance };
