import i18n from 'localizations';
import { MultiValueFilter } from 'filters/multi-value-filter';
import { Filter } from 'filters/interfaces/filter';
import { Command } from 'filters/interfaces/command';
import { FilterCommand } from 'filters/commands/filter-command';
import { SelectionMacroCommand } from 'filters/commands/selection-macro-command';
import { Operators } from 'filters/operators';
import { SingleValueFilter } from 'filters/single-value-filter';
import { NetworkChain } from 'ui-enums/response/network-chain';
import { TokenType } from 'models/enums/tokens/token-type';
import { FileType } from 'ui-enums/file-type';

const nameFilter = new SingleValueFilter({
  property: 'name',
  displayProperty: i18n.t('walletDetailsPage.tokens.cols.name'),
  operators: [Operators.contains, Operators.equals],
  operatorsDisplayValues: [i18n.t('filters.operators.contains'), i18n.t('filters.operators.equals')],
  initialOperator: Operators.contains,
  initialValue: '',
});

const contractAddressFilter = new SingleValueFilter({
  property: 'contractAddress',
  displayProperty: i18n.t('walletDetailsPage.tokens.cols.contractAddress'),
  operators: [Operators.contains, Operators.equals],
  operatorsDisplayValues: [i18n.t('filters.operators.contains'), i18n.t('filters.operators.equals')],
  initialOperator: Operators.contains,
  initialValue: '',
});

const blockchainTokenIdFilter = new SingleValueFilter({
  property: 'blockchainTokenId',
  displayProperty: i18n.t('walletDetailsPage.tokens.cols.tokenId'),
  operators: [Operators.contains, Operators.equals],
  operatorsDisplayValues: [i18n.t('filters.operators.contains'), i18n.t('filters.operators.equals')],
  initialOperator: Operators.contains,
  initialValue: '',
});

const blockchainFilter = new MultiValueFilter({
  property: 'network',
  displayProperty: i18n.t('walletDetailsPage.tokens.cols.blockchain'),
  operators: [Operators.in],
  operatorsDisplayValues: [i18n.t('filters.operators.equals')],
  initialOperator: Operators.in,
  values: [
    `${NetworkChain.mainnet},${NetworkChain.goerli}`,
    `${NetworkChain.polygonMainnet},${NetworkChain.polygonMumbai}`,
    `${NetworkChain.solanaMainnet},${NetworkChain.solanaDevnet},${NetworkChain.solanaTestnet}`,
  ],
  displayValues: [i18n.t('cryptoAssets.eth.long'), i18n.t('cryptoAssets.matic.long'), i18n.t('cryptoAssets.sol.long')],
  initialValue: '',
});

const tokenStandardFilter = new MultiValueFilter({
  property: 'tokenType',
  displayProperty: i18n.t('walletDetailsPage.tokens.cols.tokenStandard'),
  operators: [Operators.equals],
  operatorsDisplayValues: [i18n.t('filters.operators.equals')],
  initialOperator: Operators.equals,
  values: [TokenType.erc1155, TokenType.erc721, TokenType.solana],
  displayValues: [i18n.t('tokenType.erc1155'), i18n.t('tokenType.erc721'), i18n.t('tokenType.solana')],
  initialValue: '',
});

const fileTypeFilter = new MultiValueFilter({
  property: 'contentType',
  displayProperty: i18n.t('walletDetailsPage.tokens.cols.fileType'),
  operators: [Operators.in],
  operatorsDisplayValues: [i18n.t('filters.operators.equals')],
  initialOperator: Operators.in,
  values: [
    // image
    `${FileType['image/jpeg']},${FileType['image/tiff']},${FileType['image/webp']},${FileType['image/svg+xml']},${FileType['image/png']}`,
    // gif
    `${FileType['image/gif']}`,
    // audio
    `${FileType['audio/aac']},${FileType['audio/aiff']},${FileType['audio/flac']},${FileType['audio/mpeg']},${FileType['audio/ogg']},${FileType['audio/wav']},${FileType['audio/x-aac']},${FileType['audio/x-aiff']},${FileType['audio/x-flac']}`,
    // video
    `${FileType['video/mp4']},${FileType['video/quicktime']},${FileType['video/webm']},${FileType['video/x-matroska']},${FileType['video/x-msvideo']}`,
    // Doc
    `${FileType['application/msword']},${FileType['application/pdf']},${FileType['text/plain']}`,
    // 3D
    `${FileType['model/obj']},${FileType['application/x-3ds']},${FileType['image/x-3ds']},${FileType['application/x-blender']},${FileType['model/stl']},${FileType['model/x.stl-binary']},${FileType['model/x.stl-ascii']},${FileType['model/gltf+json']},${FileType['model/gltf-binary']}`,
  ],
  displayValues: [
    i18n.t('fileType.image'),
    i18n.t('fileType.gif'),
    i18n.t('fileType.audio'),
    i18n.t('fileType.video'),
    i18n.t('fileType.doc'),
    i18n.t('fileType.threeD'),
  ],
  initialValue: '',
});

const amountFilter = new SingleValueFilter({
  property: 'amount',
  displayProperty: i18n.t('walletDetailsPage.tokens.cols.amount'),
  operators: [Operators.contains, Operators.equals],
  operatorsDisplayValues: [i18n.t('filters.operators.contains'), i18n.t('filters.operators.equals')],
  initialOperator: Operators.contains,
  initialValue: '',
});

export const tokensFilters: Filter[] = [
  nameFilter,
  contractAddressFilter,
  blockchainTokenIdFilter,
  blockchainFilter,
  tokenStandardFilter,
  fileTypeFilter,
  amountFilter,
];

const commands: Command[] = [
  new FilterCommand(nameFilter),
  new FilterCommand(contractAddressFilter),
  new FilterCommand(blockchainTokenIdFilter),
  new FilterCommand(blockchainFilter),
  new FilterCommand(tokenStandardFilter),
  new FilterCommand(fileTypeFilter),
  new FilterCommand(amountFilter),
];

export const tokensSelectionMacroCommand = new SelectionMacroCommand(commands, 0); // slot is an index of tokenNameFilter
