import { useState, useEffect } from 'react';
import { CollectionResponse } from 'redux/api/types';

export const useTransformCollectionResponse = <T, E>(
  response: CollectionResponse<T> | undefined,
  transformCallback: (itemToTransform: T) => E,
  // TODO: make it NOT optional when changes are implemented in every place we use "useTransformCollectionResponse" (https://fortressgroup.atlassian.net/browse/ID-2042)
  isLoading?: boolean,
): [E[], number, boolean] => {
  const [items, setItems] = useState<E[]>([]);
  const [resourceCount, setResourceCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (response) {
      const transformedItems = response.data.map(transformCallback);
      setItems(transformedItems);
      setResourceCount(response.meta?.resourceCount ?? 0);
      setLoading(isLoading ?? false);
    }
  }, [response, transformCallback, isLoading]);

  return [items, resourceCount, loading];
};
