import i18n from 'i18next';
import { SideMenuBaseViewModel } from 'view-models/side-menu/base/side-menu-base-vm';
import { SideMenu } from 'ui-interfaces/side-menu/side-menu';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { PATHS } from 'navigation/constants';
import { SideMenuListNode } from 'ui-interfaces/side-menu/side-menu-list-node';
import { SideMenuIcon } from 'ui-enums/side-menu/side-menu-icon';
import { arraysHaveMatchedPermissions } from 'utils/user/arrays-have-matched-permissions';
import { DashboardRoles } from 'models/enums/user/dashboard-roles';
import { SETTINGS } from './constants';

export class SideMenuWapiViewModel extends SideMenuBaseViewModel implements SideMenu {
  readonly nodes: SideMenuListNode[] = [];

  readonly initialNavigationPath: string = PATHS.WAPI.WALLETS;

  constructor(user?: ShortUserResponseModel) {
    super(user);

    if (user) {
      this.nodes = [
        {
          id: 'wallet',
          items: [
            {
              id: 'wallets',
              label: `${i18n.t('sideMenu.labels.wallets')}`,
              icon: SideMenuIcon.wallets,
              iconFaded: SideMenuIcon.wallets,
              to: PATHS.WAPI.WALLETS,
            },
            // TODO: Reveal when wallet transaction feature will be ready for release
            // {
            //   id: 'transactions',
            //   label: `${i18n.t('sideMenu.labels.transactions')}`,
            //   icon: SideMenuIcon.transactions,
            //   iconFaded: SideMenuIcon.transactions,
            //   to: PATHS.WAPI.TRANSACTIONS,
            // },
            {
              id: SETTINGS,
              label: `${i18n.t('sideMenu.labels.settings')}`,
              icon: SideMenuIcon.settings,
              iconFaded: SideMenuIcon.settings,
              to: PATHS.SETTINGS,
            },
          ].filter(n => {
            if (n.id === SETTINGS) {
              return arraysHaveMatchedPermissions([DashboardRoles.userManager], user.permissions);
            }
            return true;
          }),
        },
      ];
    }
  }
}
