import React from 'react';
import { RowBox } from '../RowBox';
import { StyledBox } from './styled';

type Props = {
  hasBottomBorder?: boolean;
};

export const PaddedRowBox: React.FC<Props> = ({ hasBottomBorder, children }) => {
  return (
    <StyledBox>
      <RowBox hasBottomBorder={hasBottomBorder}>{children}</RowBox>
    </StyledBox>
  );
};
