import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Spacer } from 'elements/components';
import { ReactComponent as NoAccountIcon } from 'elements/assets/icons/noAccountIcon.svg';
import { StyledContainer, StyledNoAccountWrapper } from './styled';

type Props = {
  title?: string;
  icon?: React.ReactNode;
  description?: string;
};

const EmptyState: React.FC<Props> = ({ title, icon, description }) => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <StyledNoAccountWrapper>{icon || <NoAccountIcon />}</StyledNoAccountWrapper>
      <Spacer size={24} />
      <Typography variant="h5" color="main.font" textAlign="center">
        {title || t('elements.features.identityCheck.emptyStateTitle')}
      </Typography>
      <Spacer size={16} />
      <Typography variant="bodyDefaultBook" color="secondary.font" textAlign="center">
        {description || t('elements.features.identityCheck.emptyStateDescription')}
      </Typography>
    </StyledContainer>
  );
};

export { EmptyState };
