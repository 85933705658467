import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { DashboardRoles } from 'models/enums/user/dashboard-roles';
import { formatDate } from 'utils/format-date';

export const formatIdentityDate = (user: ShortUserResponseModel, date: string) => {
  if (!user.permissions.includes(DashboardRoles.pii)) {
    return date;
  }

  return formatDate(date);
};
