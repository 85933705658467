import { styled, Alert, alertClasses, css } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';
import { ReactComponent as ErrorIcon } from 'assets/icons/error-alert-icon.svg';

export const StyledAlert = styled(Alert)(({ theme }) => ({
  alignItems: 'center',
  marginInline: pxToRem(theme.gaps._40px),
  marginBottom: pxToRem(theme.gaps._8px),
  padding: `${pxToRem(theme.gaps._16px)} ${pxToRem(theme.gaps._20px)} ${pxToRem(theme.gaps._16px)} ${pxToRem(
    theme.gaps._16px,
  )}`,
  borderRadius: pxToRem(theme.gaps._8px),
  [`& .${alertClasses.icon}`]: {
    marginRight: pxToRem(theme.gaps._8px),
    padding: 0,
  },
  [`& .${alertClasses.message}`]: {
    ...theme.typography.bodyDefaultMedium,
    padding: 0,
  },
  [`&.${alertClasses.standardError}`]: {
    border: `1px solid ${theme.palette.error.light2}`,
    backgroundColor: theme.palette.error.light2,
    [`& .${alertClasses.message}`]: {
      color: theme.palette.error.dark,
    },
  },
}));

export const StyledErrorIcon = styled(ErrorIcon)(
  ({ theme }) => css`
    color: ${theme.palette.error.dark};
  `,
);
