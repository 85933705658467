import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PathMatch } from 'react-router-dom';
import { CommonLayoutContainer, CommonLayoutHeader } from 'components/shared/layouts/CommonLayout';
import { Gap } from 'components/shared/Gap';
import { BreadCrumbsItems } from 'components/shared/Breadcrumbs';
import { CustodialAccountsDetailsHeader } from 'ui-interfaces/custodial-accounts-details/custodial-accounts-details-header';
import { StyledTab, StyledTabs } from 'components/shared/Tabs';
import { Element } from 'ui-interfaces/elements/element';
import { CustodialAccountDetailsPageTabs } from 'ui-enums/tabs/accounts/custodial-account-details-page-tabs';
import { ICaNamingModal } from 'ui-interfaces/custodial-accounts-details/i-ca-naming-modal';
import { StyledTabBox } from './styled';
import { FiatCryptoTab } from './Tabs/FiatCryptoTab';
import { PrivateSecuritiesTab } from './Tabs/PrivateSecuritiesTab';
import { BeneficiariesTab } from './Tabs/BeneficiariesTab';
import { CaNamingModal } from './CaNamingModal';
import { SuspensionAccountModal } from './SuspensionAccountModal';
import { CADetailPageHeader } from './CADetailPageHeader';
import { Error } from './layout/Error';

type Props = {
  custodialAccountId: string;
  pathMatch: PathMatch | null;
  setIsDetailsPageError: React.Dispatch<React.SetStateAction<boolean>>;
  breadCrumbs: BreadCrumbsItems;
  loading?: boolean;
  error?: boolean;
  headerProps?: CustodialAccountsDetailsHeader;
  tab: CustodialAccountDetailsPageTabs;
  setTab: (tab: CustodialAccountDetailsPageTabs) => void;
  elements: Element[];
  caNamingModalData: ICaNamingModal | undefined;
};

const CustodialAccountsDetailsPage: React.FC<Props> = ({
  custodialAccountId,
  pathMatch,
  setIsDetailsPageError,
  breadCrumbs,
  headerProps,
  loading = false,
  error = false,
  tab,
  setTab,
  elements,
  caNamingModalData,
}) => {
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newTab: CustodialAccountDetailsPageTabs) => {
    setTab(newTab);
  };

  // CaNaming Modal - start
  const [caNamingModalIsOpen, setCaNamingModalIsOpen] = useState(false);
  const onCaNamingModalOpen = () => setCaNamingModalIsOpen(true);
  const onCaNamingModalClose = useCallback(() => {
    setCaNamingModalIsOpen(false);
  }, []);
  // CaNaming Modal - end

  // suspension modal - start
  const [isSuspensionModalOpen, setIsSuspensionModalOpen] = useState(false);

  const showSuspensionModal = () => setIsSuspensionModalOpen(true);
  const closeSuspensionModal = useCallback(() => setIsSuspensionModalOpen(false), []);
  // suspension modal - end

  if (error) {
    return (
      <Error
        breadCrumbs={breadCrumbs}
        tab={tab}
        handleChange={handleChange}
        isPrivateSecurityTabShown={headerProps?.isPrivateSecurityTabShown}
        isBeneficiariesTabShown={headerProps?.isBeneficiariesTabShown}
      />
    );
  }

  return (
    <CommonLayoutContainer>
      <CommonLayoutHeader>
        <CADetailPageHeader
          breadCrumbs={breadCrumbs}
          headerProps={headerProps}
          showSuspensionModal={showSuspensionModal}
          onCaNamingModalOpen={onCaNamingModalOpen}
          isLoading={loading}
          elements={elements}
        />
        <Gap size="_20px" />
        <StyledTabBox>
          <StyledTabs value={tab} onChange={handleChange}>
            <StyledTab
              label={t('custodialAccountDetailsPage.tabs.fiatCrypto')}
              value={CustodialAccountDetailsPageTabs.fiatCrypto}
            />
            {headerProps?.isPrivateSecurityTabShown && (
              <StyledTab
                label={t('custodialAccountDetailsPage.tabs.privateSecurities')}
                value={CustodialAccountDetailsPageTabs.privateSecurities}
              />
            )}
            {headerProps?.isBeneficiariesTabShown && (
              <StyledTab
                label={t('custodialAccountDetailsPage.tabs.beneficiaries')}
                value={CustodialAccountDetailsPageTabs.beneficiaries}
              />
            )}
          </StyledTabs>
        </StyledTabBox>
      </CommonLayoutHeader>

      {tab === CustodialAccountDetailsPageTabs.fiatCrypto && (
        <FiatCryptoTab
          custodialAccountId={custodialAccountId}
          pathMatch={pathMatch}
          setIsDetailsPageError={setIsDetailsPageError}
        />
      )}
      {tab === CustodialAccountDetailsPageTabs.privateSecurities && (
        <PrivateSecuritiesTab
          custodialAccountId={custodialAccountId}
          pathMatch={pathMatch}
          setIsDetailsPageError={setIsDetailsPageError}
        />
      )}
      {tab === CustodialAccountDetailsPageTabs.beneficiaries && (
        <BeneficiariesTab
          custodialAccountId={custodialAccountId}
          pathMatch={pathMatch}
          setIsDetailsPageError={setIsDetailsPageError}
        />
      )}

      {!!caNamingModalData && (
        <CaNamingModal
          isOpen={caNamingModalIsOpen}
          onClose={onCaNamingModalClose}
          custodialAccountId={custodialAccountId}
          data={caNamingModalData}
        />
      )}
      {headerProps && (
        <SuspensionAccountModal
          custodialAccountId={custodialAccountId}
          isOpen={isSuspensionModalOpen}
          onClose={closeSuspensionModal}
          isSuspendMode={headerProps.isSuspendMode}
          accountTitle={headerProps.title}
          accountName={headerProps.accountName}
        />
      )}
    </CommonLayoutContainer>
  );
};

export default CustodialAccountsDetailsPage;
