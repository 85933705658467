import { Box, Typography } from '@mui/material';
import { css } from '@mui/material/styles';
import { ReactComponent as AccountBalanceWalletIcon } from 'elements/element-transfer/assets/icons/accountBalanceWallet.svg';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledContainer = elementsStyled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    border: 1px solid ${theme.palette.tertiary.main};
    border-radius: ${theme.borderRadius};
    padding: 16px 16px 20px;
    gap: 16px;
  `,
);

export const StyledTypography = elementsStyled(Typography)(
  ({ theme }) => css`
    text-transform: uppercase;
    color: ${theme.palette.secondary.font};
  `,
);

export const StyledAccountBalanceWalletIcon = elementsStyled(AccountBalanceWalletIcon)(
  ({ theme }) => css`
    color: ${theme.palette.secondary.font};
  `,
);
