import { Filterable } from 'ui-interfaces/shared/filters/filterable';
import { Filter } from 'filters/interfaces/filter';
import { SelectionMacroCommand } from 'filters/commands/selection-macro-command';
import { getNonApprovedTransactionsFilters } from 'utils/transactions/list/get-non-approved-transactions-filters';
import { createApprovalNeededTransactionsFilters } from './create-approval-needed-transactions-filters';

export class ApprovalNeededTransactionsListFiltersViewModel implements Filterable {
  readonly filters: Filter[];

  readonly selectionMacroCommand: SelectionMacroCommand;

  constructor() {
    const filterable = getNonApprovedTransactionsFilters(createApprovalNeededTransactionsFilters());
    this.filters = filterable.filters;
    this.selectionMacroCommand = filterable.selectionMacroCommand;
  }
}
