import { Box, alpha, css } from '@mui/material';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledContainer = elementsStyled(Box)(
  ({ theme }) => css`
    display: flex;
    gap: 15px;
    background: ${alpha(theme.palette.grey[700], 0.9)};
    border-radius: 8px;
    padding: 16px 20px;
    color: ${theme.palette.common.white};
  `,
);
