import { PathMatch } from 'react-router-dom';
import { getRouteWithParams } from 'utils/get-route-with-params';
import { NESTED_PATHS, PATHS } from 'navigation/constants';
import { CustodialAccountType } from 'models/enums/custodial-accounts/custodial-account-type';
import { IdentityType } from 'models/enums/identities/identity-type';
import {
  AccountOwnerDetailsUnderCustodialAccountDetailsUnderIdentityDetailsUrlParams,
  AccountOwnerDetailsUnderCustodialAccountDetailsUrlParams,
  CustodialAccountDetailsUnderIdentityDetailsUrlParams,
} from 'navigation/url-params-type';
import { CustodialAccountExtendedResponseModel } from 'models/response/custodial-accounts/custodial-account-extended-response-model';

export const formAccountOwnerUrl = (ca: CustodialAccountExtendedResponseModel, pathMatch: PathMatch | null): string => {
  if (!pathMatch) {
    return PATHS.TAPI.CUSTODIAL_ACCOUNTS;
  }

  switch (pathMatch.pattern.path) {
    case PATHS.TAPI.CUSTODIAL_ACCOUNTS_DETAILS: {
      return getRouteWithParams(NESTED_PATHS.CUSTODIAL_ACCOUNTS_DETAILS__ACCOUNT_OWNER_DETAILS, {
        custodialAccountId: ca.id,
        identityId: ca.ownerIdentityId,
        identityType: ca.type === CustodialAccountType.business ? IdentityType.business : IdentityType.personal,
      } as AccountOwnerDetailsUnderCustodialAccountDetailsUrlParams);
    }

    case NESTED_PATHS.IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS: {
      const { identityId, identityType } = pathMatch.params as CustodialAccountDetailsUnderIdentityDetailsUrlParams;

      return getRouteWithParams(NESTED_PATHS.IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__ACCOUNT_OWNER_DETAILS, {
        custodialAccountId: ca.id,
        identityId: ca.ownerIdentityId,
        identityType: ca.type === CustodialAccountType.business ? IdentityType.business : IdentityType.personal,
        parentIdentityId: identityId,
        parentIdentityType: identityType,
      } as AccountOwnerDetailsUnderCustodialAccountDetailsUnderIdentityDetailsUrlParams);
    }

    default:
      return PATHS.TAPI.CUSTODIAL_ACCOUNTS;
  }
};
