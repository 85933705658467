import React from 'react';
import { useTranslation } from 'react-i18next';
import { Backdrop, Typography } from '@mui/material';
import { Button, CircularProgress, LayoutModal } from 'elements/components';
import { LayoutModalProps } from 'elements/components/LayoutModal';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { ErrorState } from '../ErrorState';

type Props = {
  isLoading: boolean;
  isError: boolean;
  onReload: () => void;
} & LayoutModalProps;

const LoadingErrorPage: React.FC<Props> = ({ isLoading, isError, onReload, onClose, isAbove = true, ...props }) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  return (
    <LayoutModal
      withHeader={isError}
      isContentCentered={isError}
      onClose={!isMobile && isError ? onClose : undefined}
      withFooter
      subFooter={
        isMobile &&
        isError && (
          <Button buttonType="tertiary" onClick={onClose}>
            <Typography variant="buttonLarge">{t('elements.features.loadingErrorState.cancel')}</Typography>
          </Button>
        )
      }
      isAbove={isAbove}
      {...props}
    >
      {isLoading && (
        <Backdrop open style={{ backgroundColor: 'transparent' }}>
          <CircularProgress />
        </Backdrop>
      )}
      {isError && <ErrorState onReload={onReload} />}
    </LayoutModal>
  );
};

export { LoadingErrorPage };
