import ReplyIcon from '@mui/icons-material/Reply';
import { Box, Button, Typography, alpha } from '@mui/material';
import { css } from '@mui/material/styles';
import { Divider } from 'elements/components';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { preventForwardProps } from 'elements/utils';

export const StyledContainer = elementsStyled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 10px 16px 0;
    border: 1px solid ${theme.palette.tertiary.main};
    border-radius: ${theme.borderRadius};
    margin-bottom: 10px;
  `,
);

export const StyledCenteredContainer = elementsStyled(
  Box,
  preventForwardProps(['isMobile']),
)<{ isMobile: boolean }>(({ isMobile }) => {
  const space = isMobile ? 24 : 42;

  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin: ${space}px 0;
    gap: ${space}px;
  `;
});

export const StyledButtonsContainer = elementsStyled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const StyledStrong = elementsStyled(Typography)`
  letter-spacing: -0.02em;
  word-break: break-word;
`;

export const StyledReplyIcon = elementsStyled(ReplyIcon)`
  transform: scaleX(-1);
`;

export const StyledNetworkButton = elementsStyled(Button)(
  ({ theme }) => css`
    border: 1px solid ${theme.palette.tertiary.main};
    border-radius: 22px;
    padding: 8px 16px;
    align-self: stretch;
    text-decoration: none;

    &:hover {
      background: ${alpha(theme.palette.secondary.main, 0.1)};
      text-decoration: none;
    }

    & svg {
      color: ${theme.palette.secondary.main};
      padding-left: 8px;
      vertical-align: middle;
    }
  `,
);

export const StyledDivider = elementsStyled(Divider)`
  margin: 8px -16px 0;
`;
