import { useEffect, useState } from 'react';
import { PathMatch, useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WalletDetailsUnderIdentityDetailsUrlParams } from 'navigation/url-params-type';
import { getRouteWithParams } from 'utils/get-route-with-params';
import { BreadCrumbsItem } from 'components/shared/Breadcrumbs';
import { NESTED_PATHS, PATHS } from 'navigation/constants';

export const useBcWalletDetailsPage = () => {
  const { t } = useTranslation();
  const [breadCrumbs, setBreadCrumbs] = useState<BreadCrumbsItem[]>([]);
  const [pathMatch, setPathMatch] = useState<PathMatch | null>(null);

  const MATCHES_WALLET_DETAILS = useMatch({
    path: PATHS.WAPI.WALLET_DETAILS,
    end: true,
  });

  const MATCHES_WALLET_DETAILS_UNDER_IDENTITIES = useMatch({
    path: NESTED_PATHS.IDENTITY_DETAILS__WALLET_DETAILS,
    end: true,
  });

  useEffect(() => {
    if (MATCHES_WALLET_DETAILS) {
      setBreadCrumbs([
        {
          label: t('walletDetailsPage.breadcrumbs.wallets'),
          path: PATHS.WAPI.WALLETS,
        },
        {
          label: t('walletDetailsPage.breadcrumbs.wallet'),
        },
      ]);
      setPathMatch(MATCHES_WALLET_DETAILS);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (MATCHES_WALLET_DETAILS_UNDER_IDENTITIES) {
      const { identityId, identityType } =
        MATCHES_WALLET_DETAILS_UNDER_IDENTITIES.params as WalletDetailsUnderIdentityDetailsUrlParams;
      setBreadCrumbs([
        {
          label: t('identityDetailsPage.breadcrumbs.identities'),
          path: PATHS.IDENTITIES,
        },
        {
          label: t('identityDetailsPage.breadcrumbs.identityDetails'),
          path: getRouteWithParams(PATHS.IDENTITY_DETAILS, {
            identityId,
            identityType,
          } as WalletDetailsUnderIdentityDetailsUrlParams),
        },
        {
          label: t('walletDetailsPage.breadcrumbs.wallet'),
        },
      ]);
      setPathMatch(MATCHES_WALLET_DETAILS_UNDER_IDENTITIES);
    }
    // eslint-disable-next-line
  }, []);

  return { breadCrumbs, pathMatch };
};
