export enum FileType {
  // image
  'image/jpeg' = 'image/jpeg',
  'image/tiff' = 'image/tiff',
  'image/webp' = 'image/webp',
  'image/svg+xml' = 'image/svg+xml',
  'image/png' = 'image/png',
  'image/gif' = 'image/gif',
  // audio
  'audio/aac' = 'audio/aac',
  'audio/aiff' = 'audio/aiff',
  'audio/flac' = 'audio/flac',
  'audio/mpeg' = 'audio/mpeg',
  'audio/ogg' = 'audio/ogg',
  'audio/wav' = 'audio/wav',
  'audio/x-aac' = 'audio/x-aac',
  'audio/x-aiff' = 'audio/x-aiff',
  'audio/x-flac' = 'audio/x-flac',
  // video
  'video/mp4' = 'video/mp4',
  'video/quicktime' = 'video/quicktime',
  'video/webm' = 'video/webm',
  'video/x-matroska' = 'video/x-matroska',
  'video/x-msvideo' = 'video/x-msvideo',
  // other
  'application/json' = 'application/json',
  'application/octet-stream' = 'application/octet-stream',
  'application/vnd.clonk.c4group' = 'application/vnd.clonk.c4group',
  'image/vnd.adobe.photoshop' = 'image/vnd.adobe.photoshop',
  // document
  'application/msword' = 'application/msword',
  'application/pdf' = 'application/pdf',
  'text/plain' = 'text/plain',
  // 3D
  'model/obj' = 'model/obj',
  'application/x-3ds' = 'application/x-3ds',
  'image/x-3ds' = ' image/x-3ds',
  'application/x-blender' = 'application/x-blender',
  'model/stl' = 'model/stl',
  'model/x.stl-binary' = 'model/x.stl-binary',
  'model/x.stl-ascii' = 'model/x.stl-ascii',
  'model/gltf+json' = 'model/gltf+json',
  'model/gltf-binary' = ' model/gltf-binary',
}
