import React from 'react';
import { StatusCellProps } from 'ui-interfaces/statuses/status-cell-props';

// labels - start
import { ReactComponent as ApprovedLabelIcon } from 'assets/icons/statuses/shared/labels/approved.svg';
import { ReactComponent as ApprovalNeededLabelIcon } from 'assets/icons/statuses/shared/labels/approval-needed.svg';
import { ReactComponent as RejectedLabelIcon } from 'assets/icons/statuses/shared/labels/rejected.svg';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';
// labels - end

export const mapApprovalStatusToStatusCellProps = (status: TransactionStatus): StatusCellProps => {
  switch (status.toLowerCase()) {
    case TransactionStatus.approvalNeeded.toLowerCase(): {
      return {
        labelIcon: <ApprovalNeededLabelIcon />,
        pillowProps: {
          color: 'warning',
          colorType: 'light3',
        },
      };
    }

    case TransactionStatus.rejected.toLowerCase(): {
      return {
        labelIcon: <RejectedLabelIcon />,
        pillowProps: {
          color: 'error',
          colorType: 'light3',
        },
      };
    }

    default:
      return {
        labelIcon: <ApprovedLabelIcon />,
        pillowProps: {
          color: 'success',
          colorType: 'light2',
        },
      };
  }
};
