import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { SideMenu } from 'components/shared/SideMenu';
import { SideMenu as SideMenuProps } from 'ui-interfaces/side-menu/side-menu';

type Props = {
  sideMenuProps: SideMenuProps;
  onLogOut: () => void;
};

export const InnerLayout: React.FC<Props> = ({ sideMenuProps, onLogOut }) => {
  const navigate = useNavigate();
  const onClickLogo = () => navigate(sideMenuProps.initialNavigationPath);

  return (
    <SideMenu sideMenuProps={sideMenuProps} onClickLogo={onClickLogo} onLogOut={onLogOut}>
      <Outlet />
    </SideMenu>
  );
};
