import { Box, Modal, backdropClasses } from '@mui/material';
import { alpha, css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { DESIGN_DESKTOP_DEVICE_DIMENSIONS, preventForwardProps } from 'elements/utils';

export const StyledModal = elementsStyled(
  Modal,
  preventForwardProps(['alignBottom']),
)<{ withBackground?: boolean; alignBottom?: boolean }>(
  ({ theme, alignBottom }) => css`
    display: flex;
    justify-content: center;
    align-items: ${alignBottom ? 'end' : 'center'};

    .${backdropClasses.root} {
      backdrop-filter: blur(4px);
      background-color: ${alpha(theme.palette.grey[700], 0.25)};
    }
  `,
);

export const StyledContainer = elementsStyled(
  Box,
  preventForwardProps(['alignBottom']),
)<{ alignBottom?: boolean }>(
  ({ theme, alignBottom }) => css`
    background: ${theme.palette.background.default};
    border-radius: ${alignBottom ? '8px 8px 0 0' : '8px'};
    max-width: 467px;
    width: 100%;

    // TODO for now, mobile styles are applied if the width of the
    //  iframe is less than the desktop width. This logic should be clarified
    @media (max-width: ${DESIGN_DESKTOP_DEVICE_DIMENSIONS.WIDTH - 1}px) {
      max-width: ${alignBottom ? '100%' : '330px'};
    }
    ${theme.palette.background.custom ? `background-color: ${theme.palette.background.custom}` : ''};
  `,
);
