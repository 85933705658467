import { CustodialAccountExtendedResponseModel } from 'models/response/custodial-accounts/custodial-account-extended-response-model';
import { CustodialAccountStatus } from 'models/enums/custodial-accounts/custodial-account-status';

export const checkIfTradeAndTransferButtonsShown = (
  data: Pick<CustodialAccountExtendedResponseModel, 'isOwnerSuspended' | 'status'>,
): boolean => {
  if (data.isOwnerSuspended) {
    return false;
  }

  if (data.status === CustodialAccountStatus.suspended || data.status === CustodialAccountStatus.closed) {
    return false;
  }

  return true;
};
