import React from 'react';
import { PaymentType } from 'ui-enums/response/transactions/payment-type';
import { ReactComponent as WithdrawalIcon } from 'assets/icons/transaction-types/withdrawal.svg';
import { ReactComponent as WithdrawalReversalIcon } from 'assets/icons/transaction-types/withdrawal-reversal.svg';
import { ReactComponent as DepositIcon } from 'assets/icons/transaction-types/deposit.svg';
import { ReactComponent as DepositReversalIcon } from 'assets/icons/transaction-types/deposit-reversal.svg';
import { ReactComponent as InternalTransferIcon } from 'assets/icons/transaction-types/internal-transfer.svg';
import { ReactComponent as BuyCryptoIcon } from 'assets/icons/transaction-types/buy-crypto.svg';
import { ReactComponent as SellCryptoIcon } from 'assets/icons/transaction-types/sell-crypto.svg';

export const getPaymentTypeIcon = (paymentType: PaymentType) => {
  switch (paymentType.toLowerCase()) {
    case PaymentType.internal.toLowerCase():
    case PaymentType.privateSecurityInternal.toLowerCase():
    case PaymentType.internalCrypto.toLowerCase():
    case PaymentType.manuallyProcessed.toLowerCase(): {
      return <InternalTransferIcon />;
    }
    case PaymentType.initialDeposit.toLowerCase():
    case PaymentType.wireDeposit.toLowerCase():
    case PaymentType.achDeposit.toLowerCase():
    case PaymentType.incomingAchDeposit.toLowerCase():
    case PaymentType.cardDeposit.toLowerCase():
    case PaymentType.checkDeposit.toLowerCase():
    case PaymentType.privateSecurityDeposit.toLowerCase():
    case PaymentType.depositCrypto.toLowerCase(): {
      return <DepositIcon />;
    }
    case PaymentType.returnAchDeposit.toLowerCase():
    case PaymentType.wireDepositReturn.toLowerCase():
    case PaymentType.achDepositReverse.toLowerCase():
    case PaymentType.reversalWireDeposit.toLowerCase(): {
      return <DepositReversalIcon />;
    }
    case PaymentType.achWithdrawalReverse.toLowerCase():
    case PaymentType.wireWithdrawalReturn.toLowerCase():
    case PaymentType.reversalWireWithdrawal.toLowerCase(): {
      return <WithdrawalReversalIcon />;
    }
    case PaymentType.privateSecurityWithdrawal.toLowerCase():
    case PaymentType.withdrawalCrypto.toLowerCase():
    case PaymentType.wireWithdrawal.toLowerCase():
    case PaymentType.internationalWireWithdrawal.toLowerCase():
    case PaymentType.achWithdrawal.toLowerCase():
    case PaymentType.incomingAchWithdrawal.toLowerCase(): {
      return <WithdrawalIcon />;
    }
    case PaymentType.buyCrypto.toLowerCase():
    case PaymentType.buyPreciousMetal.toLowerCase(): {
      return <BuyCryptoIcon />;
    }
    case PaymentType.sellCrypto.toLowerCase():
    case PaymentType.sellPreciousMetal.toLowerCase(): {
      return <SellCryptoIcon />;
    }
    case PaymentType.returnAchWithdrawal.toLowerCase(): {
      return <WithdrawalReversalIcon />;
    }
    default: {
      return <InternalTransferIcon />;
    }
  }
};
