import { CustodialAccount, ExternalAccount, ExternalAccountTypeEnum, PaymentAccount } from 'elements/types';
import { PaymentMethodTypesMap } from './getPaymentMethodTypesMap';

const sortAccountList = <T extends { createdAtUtc: string }>(accountList: T[]): T[] =>
  [...accountList].sort((a, b) => Date.parse(a.createdAtUtc) - Date.parse(b.createdAtUtc));

export const selectDefaultAccount = (
  custodialAccountList: CustodialAccount[],
  externalAccountList: ExternalAccount[],
  paymentMethodTypesMap: PaymentMethodTypesMap,
): PaymentAccount | null => {
  const sortedCustodialAccountList = sortAccountList(custodialAccountList);

  // the function returns the oldest custodial account with a non-zero balance (if balance is in payment methods).
  // If there is no such account - the oldest external account (if external account is in payment methods).
  // If there is no such account - undefined
  if (paymentMethodTypesMap.balance && sortedCustodialAccountList[0]) {
    return sortedCustodialAccountList[0];
  }
  if (paymentMethodTypesMap.ach && paymentMethodTypesMap.card) {
    return sortAccountList(externalAccountList).find(
      el =>
        el.type === ExternalAccountTypeEnum.AchExternalAccount ||
        el.type === ExternalAccountTypeEnum.CardExternalAccount,
    );
  }
  if (paymentMethodTypesMap.ach) {
    return sortAccountList(externalAccountList).find(el => el.type === ExternalAccountTypeEnum.AchExternalAccount);
  }
  if (paymentMethodTypesMap.card) {
    return sortAccountList(externalAccountList).find(el => el.type === ExternalAccountTypeEnum.CardExternalAccount);
  }

  return null;
};

export const selectDefaultExternalAccount = (externalAccountList: ExternalAccount[]): PaymentAccount =>
  sortAccountList(externalAccountList)[0];
