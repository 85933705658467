import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { pxToRem } from 'utils/styling-utils';
import { BOX_SIZE } from './constants';

export const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: pxToRem(theme.gaps._8px),
  width: pxToRem(BOX_SIZE),
  height: pxToRem(BOX_SIZE),
}));
