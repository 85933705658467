import React from 'react';
import Box from '@mui/material/Box';
import { Gap } from 'components/shared/Gap';
import { getAssetByNetworkIcon } from 'utils/icons-mapping/get-asset-by-network-icon';
import { AssetByNetwork } from 'ui-enums/response/asset-by-network';
import { AssetByNetworkIconSize } from 'ui-enums/asset-by-network-icon-size';
import { CroppedLabel } from 'components/shared/CroppedLabel';
import { calculateMaxWidthForFirstText, calculateMaxWidthForSecondText } from './helpers';

const BOX_MARGIN_RIGHT = 12;
const CELL_INLINE_PADDING = 20;
const BOX_SIZE = 36;

type Props = {
  name: string;
  symbol?: string;
  width: number;
};

export const NameCell: React.FC<Props> = ({ name, symbol, width }) => {
  const availableWidthForText = width - CELL_INLINE_PADDING - BOX_SIZE - BOX_MARGIN_RIGHT * 2;
  const tokenizedAssetIcon = getAssetByNetworkIcon(AssetByNetwork.privateSecurity, AssetByNetworkIconSize['36px']);

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {tokenizedAssetIcon}
      <Gap size="_12px" isHorizontal />
      <CroppedLabel
        title={name}
        maxWidth={calculateMaxWidthForFirstText(availableWidthForText, symbol)}
        variant="bodyDefaultMedium"
        color="grey.700"
      />
      {!!symbol && (
        <>
          <Gap size="_12px" isHorizontal />
          <CroppedLabel
            title={symbol}
            maxWidth={calculateMaxWidthForSecondText(availableWidthForText)}
            variant="bodyDefaultMedium"
            color="grey.500"
          />
        </>
      )}
    </Box>
  );
};
