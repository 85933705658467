import { CustodialAccountType } from 'models/enums/custodial-accounts/custodial-account-type';

export const getIsIra = (type: CustodialAccountType): boolean => {
  switch (type) {
    case CustodialAccountType.rothIra:
    case CustodialAccountType.traditionalIra:
    case CustodialAccountType.sepIra:
    case CustodialAccountType.simpleIra:
      return true;
    default:
      return false;
  }
};
