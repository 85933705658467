import { styled, css, alpha, darken } from '@mui/material/styles';
import { Drawer, List, Box, IconButton } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';
import { Row } from 'components/shared/Flex/Row';
import { closedMixin, openedMixin } from './mixins';
import { CLOSED_DRAWER_WIDTH, DRAWER_WIDTH } from './constants';

export const StyledDrawer = styled(Drawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme, pxToRem(DRAWER_WIDTH)),
    '& .MuiDrawer-paper': openedMixin(theme, pxToRem(DRAWER_WIDTH)),
  }),
  ...(!open && {
    ...closedMixin(theme, pxToRem(CLOSED_DRAWER_WIDTH)),
    '& .MuiDrawer-paper': closedMixin(theme, pxToRem(CLOSED_DRAWER_WIDTH)),
  }),
}));

export const StyledDrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: pxToRem(theme.gaps._24px),
}));

export const StyledList = styled(List)(({ theme }) => ({
  ...theme.typography.bodyDefaultMedium,
  color: darken(theme.palette.background.paper, 0.5),
  marginTop: pxToRem(theme.gaps._20px),
  padding: 0,
  marginInline: pxToRem(theme.gaps._12px),

  '&& .Mui-selected': {
    color: theme.palette.background.paper,
    backgroundColor: alpha(theme.palette.background.paper, 0.08),
    borderRadius: pxToRem(theme.gaps._8px / 2),
  },
  '&& .Mui-selected:hover': {
    color: theme.palette.background.paper,
    backgroundColor: alpha(theme.palette.background.paper, 0.08),
    borderRadius: pxToRem(theme.gaps._8px / 2),
  },
  '& .MuiListItemButton-root:hover': {
    backgroundColor: alpha(theme.palette.background.paper, 0.05),
    borderRadius: pxToRem(theme.gaps._8px / 2),
  },
}));

export const StyledLogoBox = styled(Box)(
  ({ theme }) => css`
    width: ${theme.gaps._40px};
    height: ${theme.gaps._40px};
  `,
);

export const StyledLogoIconButton = styled(IconButton)(
  () => css`
    padding: 0;
  `,
);

export const StyledCombineLogoEnvRow = styled(Row)(
  () => css`
    align-items: center;
  `,
);
