import { Currency } from 'ui-enums/response/transactions/currency';
import { NetworkChain } from 'ui-enums/response/network-chain';

export const getParsedCoinAssetType = (cabId: string): string | null => {
  if (!cabId) {
    return null;
  }

  const coinNetworkPair = cabId.split('/');
  let coinAssetType = coinNetworkPair[0].toLowerCase();
  const coinAssetNetwork = coinNetworkPair[1].toLowerCase();

  if (coinAssetType === Currency.usdc) {
    if (
      coinAssetNetwork === NetworkChain.solanaDevnet.toLowerCase() ||
      coinAssetNetwork === NetworkChain.solanaTestnet.toLowerCase() ||
      coinAssetNetwork === NetworkChain.solanaMainnet.toLowerCase()
    ) {
      coinAssetType = Currency.sol;
    }

    if (
      coinAssetNetwork === NetworkChain.stellarMainnet.toLowerCase() ||
      coinAssetNetwork === NetworkChain.stellarTestnet.toLowerCase()
    ) {
      coinAssetType = Currency.xlm;
    }
  }

  return coinAssetType;
};
