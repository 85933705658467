import { useSelector } from 'react-redux';
import React, { useEffect, useCallback } from 'react';
import { resetBannerData, selectBannerData } from 'redux/banner';
import { Banner } from 'components/Banner';
import { useAppDispatch } from 'redux/hooks';

const BannerContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { type, message } = useSelector(selectBannerData);
  const onClose = useCallback(() => dispatch(resetBannerData()), [dispatch]);

  useEffect(() => {
    if (message) {
      setTimeout(onClose, 5000);
    }
  }, [message, onClose]);

  if (type && message) {
    return <Banner type={type} title={message} isOpen={Boolean(message)} onClose={onClose} />;
  }

  return null;
};

export default BannerContainer;
