import { SelectorCommand } from 'filters/interfaces/selector-command';
import { Command } from 'filters/interfaces/command';
import { FilterResult } from 'filters/interfaces/filter-result';

export class SelectionMacroCommand implements SelectorCommand {
  private commands: Command[];

  private selectedCommandSlot: number;

  private savedCommandSlot: number = -1;

  private readonly defaultCommandSlot: number;

  constructor(commands: Command[], initiallySelectedSlot: number) {
    this.commands = commands;
    this.selectedCommandSlot = initiallySelectedSlot;
    this.defaultCommandSlot = initiallySelectedSlot;
  }

  setSelectedCommandSlot(slot: number) {
    this.selectedCommandSlot = slot;
  }

  getSelectedCommandSlot() {
    return this.selectedCommandSlot;
  }

  getSelectedCommand() {
    return this.commands[this.selectedCommandSlot];
  }

  private setSavedCommandSlot(slot: number) {
    this.savedCommandSlot = slot;
  }

  getSavedCommandSlot() {
    return this.savedCommandSlot;
  }

  reset() {
    this.resetAll();
    this.selectedCommandSlot = this.defaultCommandSlot;
    this.savedCommandSlot = -1;
  }

  private resetAll() {
    this.commands.forEach(c => c.reset());
  }

  getState(): FilterResult {
    return this.commands[this.selectedCommandSlot].getState();
  }

  save(): void {
    this.setSavedCommandSlot(this.selectedCommandSlot);
    this.commands[this.selectedCommandSlot].save();
  }

  resetToSavedState(): void {
    if (this.savedCommandSlot !== -1) {
      this.setSelectedCommandSlot(this.savedCommandSlot);
      this.commands[this.selectedCommandSlot].resetToSavedState();
    }
  }

  hasSavedCommandSlot(): boolean {
    return this.savedCommandSlot !== -1;
  }
}
