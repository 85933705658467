import { UserRoleTemplateResponseModel } from 'models/response/users/user-role-template-response-model';
import { UserDetailResponseModel } from 'models/response/users/user-detail-response-model';

export const getSelectedUserRoleTemplateIndexFromUserDetailResponseModel = (
  userRoleTemplates: UserRoleTemplateResponseModel[],
  userDetailResponseModel: UserDetailResponseModel,
): number => {
  const index = userRoleTemplates.findIndex(u => u.roleTemplate === userDetailResponseModel.roleTemplate);

  // to avoid case when user details response is out of sync with role template response
  // and crash can happen
  return index !== -1 ? index : 0;
};
