import { UserAllowedSubApi } from 'models/enums/user/user-allowed-sub-api';
import { DEFAULT_ROUTE, PATHS } from 'navigation/constants';
import { SubApiCases } from 'navigation/sub-api-cases';
import { checkIfArraysEqual } from 'utils/check-if-arrays-equal';

export const getInitialRoute = (subApis: UserAllowedSubApi[]) => {
  switch (true) {
    case checkIfArraysEqual(SubApiCases.trustWallet, subApis):
    case checkIfArraysEqual(SubApiCases.trustWalletCompliance, subApis):
    case checkIfArraysEqual(SubApiCases.trust, subApis):
    case checkIfArraysEqual(SubApiCases.trustCompliance, subApis):
    case checkIfArraysEqual(SubApiCases.default, subApis):
      return PATHS.TAPI.CUSTODIAL_ACCOUNTS;

    case checkIfArraysEqual(SubApiCases.wallet, subApis):
      return PATHS.WAPI.WALLETS;

    case checkIfArraysEqual(SubApiCases.walletCompliance, subApis):
      return PATHS.IDENTITIES;

    default:
      return DEFAULT_ROUTE;
  }
};
