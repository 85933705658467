import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Typography } from '@mui/material';
import { Button } from 'elements/components';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { AccountVerificationProps } from '../types';

const FooterButtons: React.FC<Omit<AccountVerificationProps, 'pageTitle' | 'title' | 'subtitle'>> = ({
  onBack,
  onLetsGo,
  contactUsHref,
  isTertiaryButtonShown,
}) => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation();
  return (
    <>
      {onLetsGo && (
        <Button onClick={onLetsGo}>
          <Typography variant="buttonLarge">{t('elements.common.letsGo')}</Typography>
          {!isMobile && <ArrowForwardIcon />}
        </Button>
      )}
      {contactUsHref && (
        <Button
          // @ts-ignore
          component="a"
          href={contactUsHref}
          target="_blank"
          rel="noopener noreferrer"
          buttonType="secondary"
        >
          <Typography variant="buttonLarge">{t('elements.common.contactUs')}</Typography>
        </Button>
      )}
      {isTertiaryButtonShown && (
        <Button buttonType="tertiary" onClick={onBack}>
          <Typography variant="buttonLarge">
            {isMobile ? t('elements.common.close') : t('elements.common.goBack')}
          </Typography>
        </Button>
      )}
    </>
  );
};

export default FooterButtons;
