// eslint-disable-next-line import/no-extraneous-dependencies
import { SerializedStyles } from '@emotion/serialize';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { Box } from '@mui/material';
import { css } from '@mui/material/styles';
import { ElementsCustomTheme, elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledNoAccountContainer = elementsStyled(Box)`
  display: flex;
  gap: 12px;
  margin: 16px 0;
`;

const applyIconStyles = (theme: ElementsCustomTheme): SerializedStyles => css`
  color: ${theme.palette.tertiary.main};
  height: 24px;
  width: 24px;
`;

export const StyledAccountBalanceOutlinedIcon = elementsStyled(AccountBalanceOutlinedIcon)(({ theme }) =>
  applyIconStyles(theme),
);

export const StyledAccountBalanceWalletOutlinedIcon = elementsStyled(AccountBalanceWalletOutlinedIcon)(({ theme }) =>
  applyIconStyles(theme),
);
