import React, { MouseEventHandler } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Typography } from '@mui/material';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { Spacer } from 'elements/components/Spacer';
import HeaderRightIcon from './HeaderRightIcon';
import { EmptyIconBox, StyledHeaderContainer, StyledIconButton, StyledTopContainer } from './styled';

export type LayoutHeaderProps = {
  title?: string;
  subtitle?: string;
  overHeader?: React.ReactNode;
  subHeader?: React.ReactNode;
  onClose?: () => void;
  onBack?: () => void;
  onHelperIconClick?: MouseEventHandler<SVGSVGElement> | null;
};

const LayoutHeader: React.FC<LayoutHeaderProps> = ({
  title,
  subtitle,
  overHeader,
  subHeader,
  onClose,
  onBack,
  onHelperIconClick,
}) => {
  const { isMobile } = useIsMobile();

  return (
    <StyledHeaderContainer withTitle={!!title} isMobile={isMobile}>
      {overHeader}
      <StyledTopContainer>
        {onBack ? (
          <StyledIconButton onClick={onBack}>
            <KeyboardBackspaceIcon />
          </StyledIconButton>
        ) : (
          <EmptyIconBox />
        )}
        <Typography variant="h4" color="primary.font" textAlign="center" alignItems="center">
          {title}
        </Typography>
        <HeaderRightIcon onClose={onClose} onHelperIconClick={onHelperIconClick} />
      </StyledTopContainer>
      {subtitle && (
        <>
          <Spacer size={isMobile ? 12 : 18} />
          <Typography color="secondary.font" variant="bodyDefaultBook" textAlign="center">
            {subtitle}
          </Typography>
        </>
      )}
      {subHeader}
    </StyledHeaderContainer>
  );
};

export { LayoutHeader };
