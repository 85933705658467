import React from 'react';
import { Text as _Text } from '@react-pdf/renderer';
import { styles } from './styles';
import { RightCellBox } from '../RightCellBox';

const Text: any = _Text;

type Props = {
  value: string;
};

export const RightCell: React.FC<Props> = ({ value }) => {
  return (
    <RightCellBox>
      <Text style={styles.text}>{value}</Text>
    </RightCellBox>
  );
};
