import React from 'react';
import { IIdentityDetailsPage } from 'ui-interfaces/identity-details/i-identity-details-page';
import { Content } from './Content';
import { Loading } from './Loading';

type Props = {
  data?: IIdentityDetailsPage;
  isLoading: boolean;
};

export const IdentityDetailsSection: React.FC<Props> = ({ data, isLoading }) => {
  if (isLoading) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  return <Content sectionHeader={data.sectionHeader} nodes={data.nodes} />;
};
