import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderDetailsItem } from 'components/shared/HeaderDetailsItem';
import { HeaderDetailsLinkItem } from 'components/shared/HeaderDetailsLinkItem';
// icons - start
import { ReactComponent as AccountIdIcon } from 'assets/icons/custodial-accounts/account-id.svg';
import { ReactComponent as AccountNumberIcon } from 'assets/icons/custodial-accounts/account-number.svg';
import { ReactComponent as OwnerIcon } from 'assets/icons/custodial-accounts/owner.svg';
import { ReactComponent as DateIcon } from 'assets/icons/identity-details/date.svg';
import { ReactComponent as LastActivityIcon } from 'assets/icons/custodial-accounts/last-activity-icon.svg';
import { ReactComponent as AccountTypeIcon } from 'assets/icons/custodial-accounts/account-type.svg';
// icons - end
import { StyledBox } from './styled';

export const LoadingCustodialAccountHeader: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledBox>
      <HeaderDetailsItem value="" label={t('custodialAccountsPage.accountId')} icon={<AccountIdIcon />} loading large />

      <HeaderDetailsItem
        value=""
        label={t('custodialAccountsPage.accountNumber')}
        icon={<AccountNumberIcon />}
        loading
      />

      <HeaderDetailsLinkItem
        url=""
        urlDisplayText=""
        label={t('custodialAccountsPage.owner')}
        icon={<OwnerIcon />}
        loading
      />

      <HeaderDetailsItem value="" label={t('custodialAccountsPage.dateOpened')} icon={<DateIcon />} loading />

      <HeaderDetailsItem
        value=""
        label={t('custodialAccountDetailsPage.detailsLabels.modifiedAt')}
        tooltip={t('custodialAccountDetailsPage.detailsLabels.modifiedAtTooltip')}
        icon={<LastActivityIcon />}
        loading
      />

      <HeaderDetailsItem value="" label={t('custodialAccountsPage.accountType')} icon={<AccountTypeIcon />} loading />
    </StyledBox>
  );
};
