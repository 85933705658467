import { IdentityType } from 'elements/types';
import i18n from 'localizations';
import { VerificationType } from '../types';

export const getVerificationType = (isInactiveAccount: boolean, identityType: IdentityType): VerificationType => {
  if (isInactiveAccount) {
    return VerificationType.InactiveAccount;
  }
  if (identityType === IdentityType.Personal) {
    return VerificationType.VerifyIdentity;
  }
  return VerificationType.TradingUnavailable;
};

export const getVerificationTypeTexts = (type: VerificationType) => {
  const types = {
    [VerificationType.VerifyIdentity]: {
      title: i18n.t('elements.features.identityVerification.verifyIdentityTitle'),
      subtitle: i18n.t('elements.features.identityVerification.verifyIdentitySubtitle'),
    },
    [VerificationType.TradingUnavailable]: {
      title: i18n.t('elements.features.identityVerification.tradingUnavailableTitle'),
      subtitle: i18n.t('elements.features.identityVerification.tradingUnavailableSubtitle'),
    },
    [VerificationType.InactiveAccount]: {
      title: i18n.t('elements.features.identityVerification.inactiveAccountTitle'),
      subtitle: i18n.t('elements.features.identityVerification.inactiveAccountSubtitle'),
    },
  };

  return types[type];
};
