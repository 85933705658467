import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { Box, Typography } from '@mui/material';
import { css } from '@mui/material/styles';
import { preventForwardProps } from 'elements/utils';
import { ElementsCustomTheme, elementsStyled } from 'elements/theme/createCustomTheme';
import { PaymentMethodItemState } from './types';

const getBorderColor = (theme: ElementsCustomTheme, state?: PaymentMethodItemState): string => {
  if (state === PaymentMethodItemState.Accented) {
    return theme.palette.primary.main;
  }
  if (state === PaymentMethodItemState.Error) {
    return theme.palette.error.main;
  }
  if (state === PaymentMethodItemState.NotEditable) {
    return 'transparent';
  }
  return theme.palette.tertiary.main;
};

export const StyledContainer = elementsStyled(
  Box,
  preventForwardProps(['state']),
)<{ state?: PaymentMethodItemState }>(
  ({ theme, state }) =>
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12.5px ${state === PaymentMethodItemState.NotEditable ? '0' : '12px'};
      gap: 12px;
      height: auto;
      border: ${state === PaymentMethodItemState.Accented ? 2 : 1}px solid ${getBorderColor(theme, state)};
      border-radius: ${theme.borderRadius};
      ${state !== PaymentMethodItemState.NotEditable ? 'cursor: pointer;' : ''}
    `,
);

export const StyledMainContent = elementsStyled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: 0;

  & svg {
    flex-shrink: 0;
  }
`;

export const StyledTextContainer = elementsStyled(
  Box,
  preventForwardProps(['reverse']),
)<{ reverse?: boolean }>(
  ({ reverse }) => css`
    display: flex;
    align-self: start;
    align-items: start;
    flex-direction: ${reverse ? 'column-reverse' : 'column'};
    gap: 6px;
    min-width: 0;
  `,
);

export const StyledTruncatedTypography = elementsStyled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledCreateOutlinedIcon = elementsStyled(
  CreateOutlinedIcon,
  preventForwardProps(['state']),
)<{
  state?: PaymentMethodItemState;
}>(
  ({ theme, state }) => css`
    color: ${state === PaymentMethodItemState.Error ? theme.palette.error.main : theme.palette.primary.main};
  `,
);
