import { DownloadCSV } from 'hooks/use-export/interfaces';
import { FilterResult } from 'filters/interfaces/filter-result';
import { API_ROUTES } from 'redux/api/api-routes';
import { CustodialAccountLabel } from 'models/enums/custodial-accounts/custodial-account-label';

export const onUserCustodialAccountsExportClick = (downloadCsv: DownloadCSV) => (filterResult?: FilterResult) => {
  downloadCsv({
    urlSegment: API_ROUTES.private.CUSTODIAL_ACCOUNTS,
    filterResult,
    preFilterParams: {
      [`filter[label eq]`]: CustodialAccountLabel.user,
    },
  });
};
