import React from 'react';
import { OnCloseElement } from 'elements/models/types/element-result';
import { Paths } from 'elements/element-trade/navigation/routes';
import EntryPage from 'elements/element-trade/pages/EntryPage';
import BuySellCoinPage from 'elements/element-trade/pages/BuySellCoinPage';
import ReviewPage from 'elements/element-trade/pages/ReviewPage';
import ProcessingOrderPage from 'elements/element-trade/pages/ProcessingOrderPage';
import OrderPage from 'elements/element-trade/pages/OrderPage';

const createAppContent = (route: Paths, onClose: OnCloseElement) => {
  switch (route) {
    case Paths.Root: {
      return <EntryPage onClose={onClose} />;
    }
    case Paths.BuySell:
    case Paths.Verification: {
      return <BuySellCoinPage onClose={onClose} />;
    }
    case Paths.Review:
    case Paths.PaymentMethod: {
      return <ReviewPage onClose={onClose} />;
    }
    case Paths.OrderProcessing: {
      return <ProcessingOrderPage />;
    }
    case Paths.Order: {
      return <OrderPage onClose={onClose} />;
    }
    default:
      return <EntryPage onClose={onClose} />;
  }
};

export { createAppContent };
