import React from 'react';
import Typography from '@mui/material/Typography';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import { ReactComponent as PendingIcon } from 'assets/icons/users/detail/pending.svg';
import { Row } from 'components/shared/Flex/Row';
import { Gap } from 'components/shared/Gap';
import { StyledBox, StyledRow } from './styled';

type Props = {
  value: string;
  tooltipMessage: string;
  isPending: boolean;
};

export const Title: React.FC<Props> = ({ value, tooltipMessage, isPending }) => {
  return (
    <StyledRow>
      <Typography typography="h3">{value}</Typography>
      {isPending && (
        <Row>
          <Gap isHorizontal size="_12px" />
          <CommonTooltip placement="top" title={tooltipMessage}>
            <StyledBox>
              <PendingIcon cursor="pointer" />
            </StyledBox>
          </CommonTooltip>
        </Row>
      )}
    </StyledRow>
  );
};
