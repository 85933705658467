import dayjs from 'dayjs';
import i18n from 'localizations';
import { SingleValueFilter } from 'filters/single-value-filter';
import { DateFilter } from 'filters/date-filter';
import { Filter } from 'filters/interfaces/filter';
import { Command } from 'filters/interfaces/command';
import { FilterCommand } from 'filters/commands/filter-command';
import { SelectionMacroCommand } from 'filters/commands/selection-macro-command';
import { Operators } from 'filters/operators';

const walletNameFilter = new SingleValueFilter({
  property: 'name',
  displayProperty: i18n.t('walletsPage.walletName'),
  operators: [Operators.contains, Operators.equals],
  operatorsDisplayValues: [i18n.t('filters.operators.contains'), i18n.t('filters.operators.equals')],
  initialOperator: Operators.contains,
  initialValue: '',
});

const walletIdFilter = new SingleValueFilter({
  property: 'id',
  displayProperty: i18n.t('walletsPage.walletId'),
  operators: [Operators.equals],
  operatorsDisplayValues: [i18n.t('filters.operators.equals')],
  initialOperator: Operators.equals,
  initialValue: '',
});

const walletDateFilter = new DateFilter({
  property: 'externalCreatedAt',
  displayProperty: i18n.t('walletsPage.date'),
  operators: [Operators.equals, Operators.greaterThan, Operators.lessThan],
  operatorsDisplayValues: [
    i18n.t('filters.operators.is'),
    i18n.t('filters.operators.isAfter'),
    i18n.t('filters.operators.isBefore'),
  ],
  initialOperator: Operators.equals,
  initialValue: dayjs().format(), // current date
});

const walletAssetFilter = new SingleValueFilter({
  property: 'assets',
  displayProperty: i18n.t('walletsPage.assets'),
  operators: [Operators.contains],
  operatorsDisplayValues: [i18n.t('filters.operators.contains')],
  initialOperator: Operators.contains,
  initialValue: '',
});

export const walletsFilters: Filter[] = [walletNameFilter, walletIdFilter, walletDateFilter, walletAssetFilter];

const commands: Command[] = [
  new FilterCommand(walletNameFilter),
  new FilterCommand(walletIdFilter),
  new FilterCommand(walletDateFilter),
  new FilterCommand(walletAssetFilter),
];

export const walletsSelectionMacroCommand = new SelectionMacroCommand(commands, 0); // slot is an index of transactionIdFilter
