import { KycLevel } from 'models/enums/identities/kyc-level';
import { BeneficialOwnerResponseModel } from 'models/response/identities/business/beneficial-owner-response-model';
import { BeneficialOwnerRow } from 'ui-interfaces/row-interfaces/identities/beneficial-owner-row';
import { IdentityType } from 'models/enums/identities/identity-type';
import { StatusCellProps } from 'ui-interfaces/statuses/status-cell-props';
import { mapIdentityStatusModelToStatusCellProps } from 'utils/multi-mapping/statuses/map-identity-status-model-to-status-cell-props';

export class BeneficialOwnerViewModel implements BeneficialOwnerRow {
  readonly id: string;

  readonly businessIdentityId: string;

  readonly ownerName: string;

  readonly ownerType: IdentityType;

  readonly kycLevel: KycLevel;

  readonly statusCellProps: StatusCellProps;

  constructor(beneficialOwner: BeneficialOwnerResponseModel) {
    this.id = beneficialOwner.ownerIdentityId;
    this.businessIdentityId = beneficialOwner.businessIdentityId;
    this.ownerName = beneficialOwner.ownerIdentityDisplayName;
    this.ownerType = beneficialOwner.ownerIdentityType;
    this.kycLevel = beneficialOwner.ownerIdentityKycLevel;
    this.statusCellProps = mapIdentityStatusModelToStatusCellProps(
      beneficialOwner.ownerIdentityStatus,
      !beneficialOwner.isSuspendedByIntegrator,
    );
  }
}
