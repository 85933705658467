import React from 'react';
import i18n from 'localizations';
import { ReactComponent as DateIcon } from 'assets/icons/identity-details/date.svg';
import { TransactionDetails } from 'ui-interfaces/transaction-details/transaction-details';
import { createTransactionDetailsSection } from 'ui-factories/transaction-details/create-transaction-details-section';
import Breadcrumbs, { BreadCrumbsItem } from 'components/shared/Breadcrumbs';
import { CommonLayoutContainer, CommonLayoutHeader, CommonLayoutContent } from 'components/shared/layouts/CommonLayout';
import { Gap } from 'components/shared/Gap';
import { HeaderBox } from 'components/shared/detail-page/HeaderBox';
import { HeaderDetailsItem } from 'components/shared/HeaderDetailsItem';
import { Row } from 'components/shared/Flex/Row';
import TitleRow from './TitleRow';
import { Actions } from './Actions';
import { StyledTopRow } from './styled';
import { Loading } from './layout/Loading';
import { Error } from './layout/Error';
import { PDfExportButton } from './pdf/PDfExportButton';

type Props = {
  breadCrumbs: BreadCrumbsItem[];
  error: boolean;
  loading: boolean;
  transactionProps?: TransactionDetails;
  onApprove: () => void;
  onReject: () => void;
  isApproveLoading: boolean;
  isRejectLoading: boolean;
};

const TapiTransactionDetailsPage: React.FC<Props> = ({
  breadCrumbs,
  error,
  loading,
  transactionProps,
  onApprove,
  onReject,
  isApproveLoading,
  isRejectLoading,
}) => {
  if (loading) {
    return <Loading breadCrumbs={breadCrumbs} />;
  }

  if (error) {
    return <Error breadCrumbs={breadCrumbs} />;
  }

  if (!transactionProps) {
    return null;
  }

  return (
    <CommonLayoutContainer>
      <CommonLayoutHeader>
        <HeaderBox>
          <StyledTopRow>
            <Breadcrumbs items={breadCrumbs} />
            <Row>
              <PDfExportButton
                pdfNodes={transactionProps.pdfNodes}
                showAsMainDesign={!transactionProps.areActionsDisplayed}
              />
              {transactionProps.areActionsDisplayed && (
                <>
                  <Gap isHorizontal size="_20px" />
                  <Actions
                    onApprove={onApprove}
                    onReject={onReject}
                    isApproveLoading={isApproveLoading}
                    isRejectLoading={isRejectLoading}
                    amount={transactionProps.amountForActionDialog}
                    sourceOrganizationName={transactionProps.sourceOrganizationName!}
                  />
                </>
              )}
            </Row>
          </StyledTopRow>
          <Gap size="_20px" />
          <TitleRow
            title={transactionProps.headerLabel}
            loading={loading}
            tooltipMessage={transactionProps.tooltipMessage}
            statusCellProps={transactionProps.statusCellProps}
          />
          <Gap size="_20px" />
          <HeaderDetailsItem
            value={transactionProps.createdAt}
            label={i18n.t('transactionDetailsPage.labels.date')}
            icon={<DateIcon />}
            loading={false}
          />
        </HeaderBox>
      </CommonLayoutHeader>
      <Gap size="_24px" />
      <CommonLayoutContent>{transactionProps.sections.map(createTransactionDetailsSection)}</CommonLayoutContent>
    </CommonLayoutContainer>
  );
};

export default TapiTransactionDetailsPage;
