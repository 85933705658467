import { UserBaseResponseModel } from 'models/response/users/user-base-response-model';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { UserRoleTemplateResponseModel } from 'models/response/users/user-role-template-response-model';
import { ActionCellAction } from 'ui-interfaces/users/action-cell-action';
import { Action } from 'ui-enums/users/action-cell-type';
// view models - start
import { EditUserActionViewModel } from 'view-models/users/list-actions/edit-user-action-vm';
import { RemoveUserActionViewModel } from 'view-models/users/list-actions/remove-user-action-vm';
import { ResetUserActionViewModel } from 'view-models/users/list-actions/reset-user-action-vm';
import { ResendUserActionViewModel } from 'view-models/users/list-actions/resend-user-action-vm';
// view models - end

export const createActionViewModel = (
  action: Action,
  user: UserBaseResponseModel,
  currentUser: ShortUserResponseModel,
  roleTemplates?: UserRoleTemplateResponseModel[],
): ActionCellAction => {
  switch (action) {
    case Action.edit:
      return new EditUserActionViewModel(user, currentUser, roleTemplates);
    case Action.remove:
      return new RemoveUserActionViewModel(user, currentUser, roleTemplates);
    case Action.reset:
      return new ResetUserActionViewModel(user, currentUser);
    case Action.resend:
      return new ResendUserActionViewModel(user, currentUser);
    default:
      return new EditUserActionViewModel(user, currentUser, roleTemplates);
  }
};
