import { Box } from '@mui/material';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { ReactComponent as MessageIcon } from 'elements/assets/icons/message.svg';
import { ReactComponent as NoteIcon } from 'elements/assets/icons/note.svg';

export const StyledContainer = elementsStyled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 12px;
  gap: 12px;
  height: auto;

  & svg {
    flex-shrink: 0;
  }
`;

export const StyledCoinContainer = elementsStyled(Box)(
  ({ theme }) =>
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      background: ${theme.palette.tertiary.mainTransparent60};
      border-radius: 8px;
    `,
);

export const StyledMessageIcon = elementsStyled(MessageIcon)(
  ({ theme }) =>
    css`
      height: 20px;
      width: 20px;
      color: ${theme.palette.secondary.font};
    `,
);

export const StyledNoteIcon = elementsStyled(NoteIcon)(
  ({ theme }) =>
    css`
      height: 20px;
      width: 20px;
      color: ${theme.palette.secondary.font};
    `,
);

export const StyledTextContainer = elementsStyled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 6px;
  width: 100%;
`;

export const StyledPre = elementsStyled('pre')`
  margin: 0;
  white-space: pre-wrap;
  font-family: inherit;
  overflow-wrap: anywhere;
`;
