import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import * as api from 'elements/element-transfer/api';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { TransferFlow, WireLocationType } from 'elements/element-transfer/types';
import { setErrorBanner } from 'elements/utils';
import ContentBlock from 'elements/element-transfer/components/Withdrawal/WireReviewPage';
import {
  setIsOrderPlaced,
  setLockId,
  setPage,
  setWireDomesticWithdrawalPaymentId,
  setWireInternationalWithdrawalPaymentId,
  useElement,
} from 'elements/element-transfer/contexts/Element';
import composeDomesticWireWithdrawalRequestBody from 'elements/element-transfer/utils/wireWithdrawalUtils/composeDomesticWireWithdrawalRequestBody';
import composeInternationalWireWithdrawalRequestBody from 'elements/element-transfer/utils/wireWithdrawalUtils/composeInternationalWireWithdrawalRequestBody';
import { ConfirmModal } from 'elements/components/ConfirmModal';
import { OnCloseElement } from 'elements/models/types/element-result';
import {
  CustomError,
  isOtherExceptions,
  isReachedLimits,
  isRoutingNumberDoesNotProcessFedwirePayments,
  isMaxLengthOfBankName,
  isNoBankInfoFoundWithRoutingNumber,
} from 'elements/api';
import { getParsedTraceId } from 'elements/element-transfer/utils/getParsedTraceId';

const WireReviewPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useElement();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorTraceId, setErrorTraceId] = useState('');

  const wireWithdrawalState = state[TransferFlow.Withdrawal].WIRE;
  const { amount, recipientInfo, accountNumbers, bankInfo, purposeOfPayment } = wireWithdrawalState;
  const isDomestic = recipientInfo.locationType === WireLocationType.Domestic;

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const onConfirmDecline = () => setIsConfirmModalOpen(false);

  const onCustomClose = () => setIsConfirmModalOpen(true);

  const onBack = (): void => {
    dispatch(setPage(Paths.WithdrawalWireAmount));
  };

  const sendWire = async () => {
    try {
      if (!state.custodialAccount) return;

      setLoading(true);
      setError(false);
      setErrorMessage('');
      setErrorTraceId('');

      if (isDomestic) {
        const result = await api.transferElement.initiateDomesticWireWithdrawal(
          composeDomesticWireWithdrawalRequestBody(
            amount,
            state.custodialAccount.id,
            state.lockId,
            wireWithdrawalState,
          ),
        );
        if (result?.id) {
          dispatch(setWireDomesticWithdrawalPaymentId(result?.id));
        }
      } else {
        const result = await api.transferElement.initiateInternationalWireWithdrawal(
          composeInternationalWireWithdrawalRequestBody(
            amount,
            state.custodialAccount.id,
            state.lockId,
            wireWithdrawalState,
          ),
        );
        if (result?.id) {
          dispatch(setWireInternationalWithdrawalPaymentId(result?.id));
        }
      }

      dispatch(setLockId(''));
      dispatch(setIsOrderPlaced(true));
      dispatch(setPage(Paths.WithdrawalWirePlaced));
    } catch (err) {
      const e = err as CustomError;
      setErrorBanner(err, enqueueSnackbar);

      setError(true);

      if (e?.responseData?.traceId) {
        const tId = getParsedTraceId(e.responseData.traceId);
        if (tId) {
          setErrorTraceId(tId);
        }
      }

      if (isRoutingNumberDoesNotProcessFedwirePayments(err)) {
        const message = t('backendErrorMessages.routingNumberDoesNotProcessFedwirePayments');
        setErrorMessage(message);
      }

      if (isMaxLengthOfBankName(err)) {
        const message = t('backendErrorMessages.bankNameCannotBeLongerThan');
        setErrorMessage(message);
      }

      if (isNoBankInfoFoundWithRoutingNumber(err)) {
        const message = t('backendErrorMessages.noBankInformationFound');
        setErrorMessage(message);
      }

      if (isOtherExceptions(err)) {
        const message = t('backendErrorMessages.otherExceptions');
        setErrorMessage(message);
      }

      if (isReachedLimits(err)) {
        setErrorMessage(t('serverError.transactionlimitsError'));
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state.isOrderPlaced) {
      dispatch(setPage(Paths.WithdrawalWirePlaced));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContentBlock
        amount={amount}
        recipientName={recipientInfo.fullName}
        bankName={bankInfo.bankName}
        bankCountry={bankInfo.country}
        accountNumber={accountNumbers.accountNumber}
        custodialAccount={state.custodialAccount}
        purposeOfPayment={purposeOfPayment}
        isDomestic={isDomestic}
        note={wireWithdrawalState.note}
        memo={wireWithdrawalState.memo}
        onClose={onCustomClose}
        onBack={onBack}
        onWire={sendWire}
        loading={loading}
        error={error}
        errorMessage={errorMessage}
        errorTraceId={errorTraceId}
      />
      {isConfirmModalOpen && <ConfirmModal onConfirm={onClose} onDecline={onConfirmDecline} />}
    </>
  );
};

export default WireReviewPage;
