import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseInput } from 'elements/components';
import { BaseInputProps } from 'elements/components/BaseInput';
import { StyledAccountBalanceWalletIcon, StyledContainer, StyledTypography } from './styled';

type Props = {
  walletAddressProps: BaseInputProps;
  destinationTagProps: BaseInputProps;
};

export const WalletAddressSection: React.FC<Props> = ({ walletAddressProps, destinationTagProps }) => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <StyledTypography variant="captionMedium">
        {t('elements.transfer.withdrawalCryptoAmountPage.recipientAddress')}
      </StyledTypography>
      <BaseInput
        {...walletAddressProps}
        placeholder={t('elements.transfer.withdrawalCryptoAmountPage.walletAddress')}
        InputProps={{
          startAdornment: <StyledAccountBalanceWalletIcon />,
        }}
      />
      <BaseInput
        {...destinationTagProps}
        placeholder={t('elements.transfer.withdrawalCryptoAmountPage.destinationTag')}
      />
    </StyledContainer>
  );
};
