import React from 'react';
import { InputAdornment, StandardTextFieldProps } from '@mui/material';
import { StyledCancelIcon, StyledSearchIcon, StyledSearchInput } from './styled';

type Props = Partial<StandardTextFieldProps> & {
  searchQuery: string;
  onSearch: (value: string) => void;
  placeholder?: string;
  withDiscardIcon?: boolean;
};

export const SearchInput: React.FC<Props> = ({
  searchQuery,
  onSearch,
  placeholder,
  withDiscardIcon,
  ...otherProps
}) => {
  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  const handleDiscardIcon = () => {
    onSearch('');
  };

  return (
    <StyledSearchInput
      size="medium"
      variant="outlined"
      placeholder={placeholder}
      autoComplete="off"
      value={searchQuery}
      fullWidth
      {...otherProps}
      InputProps={{
        startAdornment: (
          <InputAdornment sx={{ marginRight: 0 }} position="start">
            <StyledSearchIcon />
          </InputAdornment>
        ),

        endAdornment: (
          <InputAdornment position="end">
            {withDiscardIcon && searchQuery && (
              <StyledCancelIcon onClick={handleDiscardIcon} data-testid="CancelIcon-testId" />
            )}
          </InputAdornment>
        ),
      }}
      onChange={handleSearchInputChange}
    />
  );
};
