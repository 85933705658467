import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { ReactComponent as SuccessIcon } from 'elements/element-trade/assets/icons/successIcon.svg';
import { TradeFlow } from 'elements/element-trade/types';
import { Button, NoteReview, Spacer } from 'elements/components';
import { PaymentMethodButton, PaymentMethodItemState } from 'elements/features/PaymentMethods';
import { PaymentAccount } from 'elements/types';
import Layout from 'elements/element-trade/components/Layout';
import OrderDetails from 'elements/element-trade/components/OrderDetails';
import WideDivider from 'elements/element-trade/components/WideDivider';
import { orderPageLabels } from 'elements/element-trade/utils/labelsMapping';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { AssetResponseModel } from 'models/response/assets/asset-response-model';
import { PaddingContainer, StyledDivider } from './styled';

type Props = {
  amount: string;
  coinPrice: number;
  coin: AssetResponseModel | null;
  paymentAccount: PaymentAccount;
  note: string;
  onDone: () => void;
  flowType: TradeFlow;
};
const OrderPage: React.FC<Props> = ({ coin, coinPrice, amount, paymentAccount, note, onDone, flowType }) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  return (
    <Layout
      title={orderPageLabels[flowType].title}
      withCloseButton={false}
      onClose={onDone}
      footerButtons={
        <Button onClick={onDone}>
          <Typography variant="buttonLarge">{t('elements.trade.orderPage.doneButtonLabel')}</Typography>
        </Button>
      }
    >
      {coin?.assetTicker && coin?.network && (
        <>
          <OrderDetails
            amount={amount}
            coinPrice={coinPrice}
            coinName={coin.assetName}
            coinAbbreviation={coin.assetTicker}
            isRemark={false}
            icon={SuccessIcon}
            flowType={flowType}
            isInfoBanner
          />
          {flowType === TradeFlow.Buy && <WideDivider isMobile={isMobile} />}
        </>
      )}
      {flowType === TradeFlow.Buy && (
        <>
          <Spacer size={24} />
          <PaddingContainer>
            <PaymentMethodButton account={paymentAccount} state={PaymentMethodItemState.NotEditable} withBalance />
          </PaddingContainer>
          <StyledDivider />
          {!!note && (
            <>
              <NoteReview note={note} title={t('elements.trade.orderPage.internalNoteTitle')} withMessageIcon />
              <StyledDivider />
            </>
          )}
          <Spacer size={30} />
          <WideDivider isMobile={isMobile} />
          <Spacer size={22} />
          <Stack gap={2}>
            <Typography variant="subtitleBook" color="primary.font">
              {t('elements.trade.orderPage.aboutTradingTitle')}
            </Typography>
            <Typography variant="bodySmallBook" color="secondary.font">
              {t('elements.trade.orderPage.aboutTradingTextPart1')}
            </Typography>
            <Typography variant="bodySmallBook" color="secondary.font">
              {t('elements.trade.orderPage.aboutTradingTextPart2')}
            </Typography>
          </Stack>
          <Spacer size={38} />
        </>
      )}
      {flowType === TradeFlow.Sell && !!note && (
        <>
          <NoteReview note={note} title={t('elements.trade.orderPage.internalNoteTitle')} withMessageIcon />
          <StyledDivider />
          <Spacer size={34} />
        </>
      )}
    </Layout>
  );
};

export default OrderPage;
