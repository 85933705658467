import React from 'react';
import { Typography } from '@mui/material';
import { LoadingButtonWithSkeleton } from 'components/shared/buttons/LoadingButtonWithSkeleton';
import { ElementName } from 'elements/models/enums/element-name';
import { getButtonTextByElementName } from './utils/getButtonTextByElementName';
import { getButtonIconByElementName } from './utils/getButtonIconByElementName';

type Props = {
  name: ElementName.TRADE | ElementName.TRANSFER;
  isSkeletonDisplayed: boolean;
  isLoading: boolean;
  onClick: () => void;
};

export const LoadingElementButton: React.FC<Props> = ({ name, isSkeletonDisplayed, isLoading, onClick }) => {
  return (
    <LoadingButtonWithSkeleton
      variant="contained"
      size="large"
      icon={getButtonIconByElementName(name)}
      loading={isLoading}
      isSkeletonDisplayed={isSkeletonDisplayed}
      text={
        <Typography variant="buttonMedium" color="background.paper">
          {getButtonTextByElementName(name)}
        </Typography>
      }
      onClick={onClick}
    />
  );
};
