import { Backdrop, css } from '@mui/material';
import { Button } from 'elements/components';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledButton = elementsStyled(Button)(
  css`
    & .MuiButton-endIcon {
      margin-left: 0px;
      svg {
        font-size: 24px;
      }
    }
  `,
);

export const StyledBackdrop = elementsStyled(Backdrop)(
  ({ theme }) =>
    css`
      background-color: ${theme.palette.background.whiteTransparent50};
      z-index: 5;
      position: absolute;
    `,
);
