import { alpha, styled } from '@mui/material/styles';
import React from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { pxToRem } from 'utils/styling-utils';
import { closedMixinIconButton, openedMixinIconButton } from '../mixins';
import { CLOSED_DRAWER_WIDTH, DRAWER_WIDTH } from '../constants';

const FLOATING_BUTTON_WIDTH = 28;
const FLOATING_BUTTON_MARGIN = 24;
const OPEN_DRAWER_POS_FLOATING_BUTTON = DRAWER_WIDTH - FLOATING_BUTTON_WIDTH - FLOATING_BUTTON_MARGIN;
const CLOSED_DRAWER_WIDTH_FLOATING_BUTTON = pxToRem(CLOSED_DRAWER_WIDTH - FLOATING_BUTTON_WIDTH / 2);

export const StyledIconButton = styled<React.FC<IconButtonProps & { isShown: boolean }>>(IconButton, {
  shouldForwardProp: prop => prop !== 'isShown',
})(({ theme, isShown }) => {
  const bgOpenDefault = alpha(theme.palette.background.paper, 0.1);
  const bgOpenHover = alpha(theme.palette.background.paper, 0.15);
  const bgOpenPressed = alpha(theme.palette.background.paper, 0.25);

  return {
    paddingInline: pxToRem(theme.gaps._20px / 2),
    paddingBlock: pxToRem(theme.gaps._8px),
    position: 'fixed',
    ...(isShown
      ? {
          ...openedMixinIconButton(theme),
        }
      : {
          ...closedMixinIconButton(theme),
        }),

    backgroundColor: bgOpenDefault,
    left: pxToRem(OPEN_DRAWER_POS_FLOATING_BUTTON),

    '&:hover': {
      backgroundColor: bgOpenHover,
    },
    '&:active': {
      backgroundColor: bgOpenPressed,
    },
  };
});

export const StyledIconButtonCollapsed = styled<React.FC<IconButtonProps & { isShown: boolean }>>(IconButton, {
  shouldForwardProp: prop => prop !== 'isShown',
})(({ theme, isShown }) => {
  const bgCollapsedDefault = theme.palette.background.paper;
  const bgCollapsedHover = theme.palette.background.paper;
  const bgCollapsedPressed = theme.palette.background.paper;

  return {
    paddingInline: pxToRem(theme.gaps._20px / 2),
    paddingBlock: pxToRem(theme.gaps._8px),
    position: 'fixed',
    ...(isShown
      ? {
          ...openedMixinIconButton(theme),
        }
      : {
          ...closedMixinIconButton(theme),
        }),

    backgroundColor: bgCollapsedDefault,
    boxShadow: theme.customShadows.grey._2dp,
    left: CLOSED_DRAWER_WIDTH_FLOATING_BUTTON,

    '&:hover': {
      backgroundColor: bgCollapsedHover,
      boxShadow: theme.customShadows.grey._8dp,
    },
    '&:active': {
      backgroundColor: bgCollapsedPressed,
      boxShadow: theme.customShadows.grey._2dp,
    },
  };
});
