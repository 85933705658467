import i18n from 'localizations';
import { TransactionDetails } from 'ui-interfaces/transaction-details/transaction-details';
import { TransactionDetailResponseModel } from 'models/response/transactions/transaction-detail-response-model';
import { formatDateAndTimeInUTC } from 'utils/format-date';
import { TransactionDetailsBaseViewModel } from 'view-models/transactions/details/transaction-details-base-vm';
import { addSpaceAfterEvery4Chars } from 'utils/add-space-after-every-4-chars';
import { formatAddress } from 'containers/identities/utils/format-address';
import { RowType } from 'ui-enums/transaction-detail/row-type';
import { generateApprovalSection } from 'utils/transactions/generate-approval-section';
import { ITextRow } from 'ui-interfaces/transaction-details/rows/i-text-row';
import { IPaymentTypeRow } from 'ui-interfaces/transaction-details/rows/i-payment-type-row';
import { getOverviewSectionBasicProps } from 'utils/transactions/detail/get-overview-section-basic-props';
import { IPlainSection } from 'ui-interfaces/transaction-details/sections/i-plain-section';
import { getCurrencyRowPropsForFiat } from 'utils/transactions/detail/get-currency-row-props-for-fiat';
import { ICollapsibleSection } from 'ui-interfaces/transaction-details/sections/i-collapsible-section';
import { SectionType } from 'ui-enums/transaction-detail/section-type';
import { generateMt103Nodes } from 'utils/transactions/detail/generate-mt-103-nodes';
import { PdfRowType } from 'ui-enums/pdf/pdf-row-type';
import { IPlainRow } from 'ui-interfaces/transaction-details/pdf/rows/i-plain-row';
import { getTransactionStatusLabel } from 'utils/labels-mapping/get-transaction-status-label';
import { generateMt103PdfItems } from 'utils/transactions/detail/pdf/generate-mt-103-pdf-items';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';
import { MT_103 } from '../constants';

export class TransactionDetailsWithdrawalWireInternationalViewModel
  extends TransactionDetailsBaseViewModel
  implements TransactionDetails
{
  private readonly hasMt103Reference: boolean;

  constructor(transaction: TransactionDetailResponseModel) {
    super(transaction);

    const transactionStatus = transaction.status as TransactionStatus;

    this.hasMt103Reference = !!transaction.mt103Reference;

    this.sections = [
      {
        ...getOverviewSectionBasicProps(),
        currencyRow: getCurrencyRowPropsForFiat(transaction, this.assetIconSize),
        nodes: [
          {
            id: 'infoSection',
            blockItems: [
              {
                id: 'transactionId',
                label: i18n.t('transactionDetailsPage.labels.transactionId'),
                uiElementType: RowType.text,
                value: transaction.externalId || i18n.t('transactionDetailsPage.labels.noData'),
                largeLeftCell: this.hasMt103Reference,
              } as ITextRow,
              {
                id: 'paymentId',
                label: i18n.t('transactionDetailsPage.labels.paymentId'),
                uiElementType: RowType.text,
                value: transaction.paymentId,
                largeLeftCell: this.hasMt103Reference,
              } as ITextRow,
              {
                id: 'date',
                label: i18n.t('transactionDetailsPage.labels.date'),
                uiElementType: RowType.text,
                value: `${formatDateAndTimeInUTC(transaction.date)} UTC`,
                largeLeftCell: this.hasMt103Reference,
              } as ITextRow,
              {
                id: 'type',
                label: i18n.t('transactionDetailsPage.labels.type'),
                uiElementType: RowType.paymentType,
                value: i18n.t('transactionsPage.withdrawal'),
                paymentType: transaction.paymentType,
                largeLeftCell: this.hasMt103Reference,
              } as IPaymentTypeRow,
              {
                id: 'paymentMethod',
                label: i18n.t('transactionDetailsPage.labels.paymentMethod'),
                uiElementType: RowType.text,
                value: i18n.t('transactionDetailsPage.labels.internationalWireWithdrawal'),
                largeLeftCell: this.hasMt103Reference,
              } as ITextRow,
              {
                id: 'purposeOfPayment',
                uiElementType: RowType.textMultiLine,
                label: i18n.t('transactionDetailsPage.labels.purposeOfPayment'),
                value: transaction.purposeOfPayment || '',
                largeLeftCell: this.hasMt103Reference,
              } as ITextRow,
              {
                id: 'memo',
                label: i18n.t('transactionDetailsPage.labels.memo'),
                uiElementType: RowType.textMultiLine,
                value: transaction.memo || '',
                largeLeftCell: this.hasMt103Reference,
              } as ITextRow,
              {
                id: 'comment',
                label: i18n.t('transactionDetailsPage.labels.comment'),
                uiElementType: RowType.textMultiLine,
                value: (transaction.initiatedBy ? '' : transaction.comment) || '',
                largeLeftCell: this.hasMt103Reference,
              } as ITextRow,
            ].filter(i => {
              if (i.uiElementType === RowType.textMultiLine) {
                return !!i.value;
              }

              return true;
            }),
          },
          {
            id: 'sourceSection',
            blockHeaderLabel: i18n.t('transactionDetailsPage.source'),
            blockItems: [
              {
                id: 'custodialAccountNumber',
                label: i18n.t('transactionDetailsPage.labels.accountNumber'),
                uiElementType: RowType.text,
                value: addSpaceAfterEvery4Chars(transaction.sourceAccountNumber || ''),
                largeLeftCell: this.hasMt103Reference,
              } as ITextRow,
              {
                id: 'accountOwner',
                label: i18n.t('transactionDetailsPage.labels.accountOwner'),
                uiElementType: RowType.text,
                value: transaction.sourceOwnerIdentityDisplayName,
                largeLeftCell: this.hasMt103Reference,
              } as ITextRow,
            ].filter(i => !!i.value),
          },
          {
            id: 'destinationSection',
            blockHeaderLabel: i18n.t('transactionDetailsPage.destination'),
            blockItems: [
              {
                id: 'externalAccountNumber',
                label: i18n.t('transactionDetailsPage.labels.externalAccountNumber'),
                uiElementType: RowType.text,
                value: `*${transaction.destinationAccountNumber || ''}`,
                largeLeftCell: this.hasMt103Reference,
              } as ITextRow,
              {
                id: 'beneficiaryName',
                label: i18n.t('transactionDetailsPage.labels.beneficiaryName'),
                uiElementType: RowType.text,
                value: transaction.destinationWireDetails?.name ?? '',
                largeLeftCell: this.hasMt103Reference,
              } as ITextRow,
              {
                id: 'internationalBankAccountNumber',
                label: i18n.t('transactionDetailsPage.labels.internationalBankAccountNumber'),
                uiElementType: RowType.text,
                value: transaction.destinationWireDetails?.internationalBankAccountNumber || '',
                largeLeftCell: this.hasMt103Reference,
              } as ITextRow,
              {
                id: 'routingNumber',
                label: i18n.t('transactionDetailsPage.labels.routingNumber'),
                uiElementType: RowType.text,
                value: transaction.destinationWireDetails?.routingNumber || '',
                largeLeftCell: this.hasMt103Reference,
              } as ITextRow,
              {
                id: 'swift',
                label: i18n.t('transactionDetailsPage.labels.swift'),
                uiElementType: RowType.text,
                value: transaction.destinationWireDetails?.swift || '',
                largeLeftCell: this.hasMt103Reference,
              } as ITextRow,
              {
                id: 'destinationWireDetailsAddress',
                label: i18n.t('transactionDetailsPage.labels.address'),
                uiElementType: RowType.text,
                value: formatAddress(transaction.destinationWireDetails?.address),
                largeLeftCell: this.hasMt103Reference,
              } as ITextRow,
              {
                id: 'bankName',
                label: i18n.t('transactionDetailsPage.labels.bankName'),
                uiElementType: RowType.text,
                value: transaction.destinationWireDetails?.bankName || '',
                largeLeftCell: this.hasMt103Reference,
              } as ITextRow,
              {
                id: 'bankAddress',
                label: i18n.t('transactionDetailsPage.labels.bankAddress'),
                uiElementType: RowType.text,
                value: formatAddress(transaction.destinationWireDetails?.bankAddress),
                largeLeftCell: this.hasMt103Reference,
              } as ITextRow,
            ].filter(i => {
              if (i.uiElementType === RowType.text) {
                return !!(i as ITextRow).value;
              }

              return true;
            }),
          },
          {
            id: 'initiatedBySection',
            blockHeaderLabel: i18n.t('transactionDetailsPage.initiationSectionLabel'),
            blockItems: [
              {
                id: 'initiatedBy',
                label: i18n.t('transactionDetailsPage.labels.initiatedBy'),
                uiElementType: RowType.text,
                value: transaction.initiatedByDisplayValue || '',
                largeLeftCell: this.hasMt103Reference,
              } as ITextRow,
              {
                id: 'dateInitiated',
                label: i18n.t('transactionDetailsPage.labels.dateInitiated'),
                uiElementType: RowType.text,
                value: transaction.initiatedAt ? `${formatDateAndTimeInUTC(transaction.initiatedAt)} UTC` : '',
                largeLeftCell: this.hasMt103Reference,
              } as ITextRow,
              {
                id: 'comment',
                label: i18n.t('transactionDetailsPage.labels.comment'),
                uiElementType: RowType.textMultiLine,
                value: (transaction.initiatedBy ? transaction.comment : '') || '',
                largeLeftCell: this.hasMt103Reference,
              } as ITextRow,
            ].filter(i => !!i.value),
          },
          ...generateApprovalSection(transaction.approvals, transactionStatus, this.hasMt103Reference),
        ].filter(node => {
          if (node.blockHeaderLabel === i18n.t('transactionDetailsPage.initiationSectionLabel')) {
            return !!transaction.initiatedBy;
          }
          return true;
        }),
      } as IPlainSection,
      {
        id: MT_103,
        label: i18n.t('transactionDetailsPage.mt103.sectionTitle'),
        sectionType: SectionType.collapsible,
        nodes: generateMt103Nodes(transaction.mt103Reference),
      } as ICollapsibleSection,
    ].filter(s => {
      if (s.id === MT_103) {
        return this.hasMt103Reference;
      }

      return true;
    });

    this.pdfNodes = [
      {
        id: 'infoSection',
        title: i18n.t('transactionDetailsPage.labels.general'),
        items: [
          {
            id: 'amount',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.amountBlockHeaderLabel'),
            value: getCurrencyRowPropsForFiat(transaction, this.assetIconSize).label,
          } as IPlainRow,
          {
            id: 'status',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.status'),
            value: getTransactionStatusLabel(transactionStatus),
          } as IPlainRow,
          {
            id: 'transactionId',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.transactionId'),
            value: transaction.externalId || i18n.t('transactionDetailsPage.labels.noData'),
          } as IPlainRow,
          {
            id: 'paymentId',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.paymentId'),
            value: transaction.paymentId,
          } as IPlainRow,
          {
            id: 'date',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.date'),
            value: `${formatDateAndTimeInUTC(transaction.date)} UTC`,
          } as IPlainRow,
          {
            id: 'type',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.type'),
            value: i18n.t('transactionsPage.withdrawal'),
          } as IPlainRow,
          {
            id: 'paymentMethod',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.paymentMethod'),
            value: i18n.t('transactionDetailsPage.labels.internationalWireWithdrawal'),
          } as IPlainRow,
          {
            id: 'purposeOfPayment',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.purposeOfPayment'),
            value: transaction.purposeOfPayment || '',
          } as IPlainRow,
          {
            id: 'memo',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.memo'),
            value: transaction.memo || '',
          } as IPlainRow,
          {
            id: 'comment',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.comment'),
            value: transaction.comment || '',
          } as IPlainRow,
        ].filter(i => !!i.value),
      },
      {
        id: 'sourceSection',
        title: i18n.t('transactionDetailsPage.source'),
        items: [
          {
            id: 'custodialAccountNumber',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.accountNumber'),
            value: addSpaceAfterEvery4Chars(transaction.sourceAccountNumber || ''),
          } as IPlainRow,
          {
            id: 'accountOwner',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.accountOwner'),
            value: transaction.sourceOwnerIdentityDisplayName,
          } as IPlainRow,
        ].filter(i => !!i.value),
      },
      {
        id: 'destinationSection',
        title: i18n.t('transactionDetailsPage.destination'),
        items: [
          {
            id: 'externalAccountNumber',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.externalAccountNumber'),
            value: `*${transaction.destinationAccountNumber || ''}`,
          } as IPlainRow,
          {
            id: 'beneficiaryName',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.beneficiaryName'),
            value: transaction.destinationWireDetails?.name ?? '',
          } as IPlainRow,
          {
            id: 'internationalBankAccountNumber',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.internationalBankAccountNumber'),
            value: transaction.destinationWireDetails?.internationalBankAccountNumber || '',
          } as IPlainRow,
          {
            id: 'routingNumber',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.routingNumber'),
            value: transaction.destinationWireDetails?.routingNumber || '',
          } as IPlainRow,
          {
            id: 'swift',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.swift'),
            value: transaction.destinationWireDetails?.swift || '',
          } as IPlainRow,
          {
            id: 'destinationWireDetailsAddress',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.address'),
            value: formatAddress(transaction.destinationWireDetails?.address),
          } as IPlainRow,
          {
            id: 'bankName',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.bankName'),
            value: transaction.destinationWireDetails?.bankName || '',
          } as IPlainRow,
          {
            id: 'bankAddress',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.bankAddress'),
            value: formatAddress(transaction.destinationWireDetails?.bankAddress),
          } as IPlainRow,
        ].filter(i => !!i.value),
      },
      {
        id: MT_103,
        title: i18n.t('transactionDetailsPage.mt103.sectionTitle'),
        items: generateMt103PdfItems(transaction.mt103Reference),
      },
    ].filter(s => {
      if (s.id === MT_103) {
        return this.hasMt103Reference;
      }

      return true;
    });
  }
}
