import React from 'react';
import { DefaultValue } from './DefaultValue';

type Props = {
  value: string;
  large?: boolean;
};

export const RightCell: React.FC<Props> = ({ value, large }) => {
  return <DefaultValue value={value} large={large} />;
};
