const FIRST_TEXT_PROPORTION = 2 / 3;
const SECOND_TEXT_PROPORTION = 1 / 3;

export const calculateMaxWidthForFirstText = (availableWidthForText: number, symbol?: string) => {
  if (symbol) {
    return availableWidthForText * FIRST_TEXT_PROPORTION;
  }

  return availableWidthForText;
};

export const calculateMaxWidthForSecondText = (availableWidthForText: number) => {
  return availableWidthForText * SECOND_TEXT_PROPORTION;
};
