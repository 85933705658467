import React from 'react';
import { StyledBox } from './styled';

type Props = {
  hasBottomBorder?: boolean;
};

export const RowBox: React.FC<Props> = ({ hasBottomBorder = false, children }) => (
  <StyledBox hasBottomBorder={hasBottomBorder}>{children}</StyledBox>
);
