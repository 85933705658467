import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import i18n from 'localizations';
import { GridCellExpand } from 'components/shared/Cells/GridCellExpand';
import { StatusCell } from 'components/shared/Cells/StatusCell';
import { BeneficiaryRow } from 'ui-interfaces/row-interfaces/custodial-accounts/beneficiary-row';

export const columnsBeneficiaries: GridColDef[] = [
  {
    field: 'name',
    headerName: i18n.t('custodialAccountDetailsPage.beneficiaries.grid.cols.name'),
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <GridCellExpand value={params.value} width={params.colDef.computedWidth} variant="bodyDefaultBookLink" />
    ),
  },
  {
    field: 'relationship',
    headerName: i18n.t('custodialAccountDetailsPage.beneficiaries.grid.cols.relationship'),
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <GridCellExpand value={params.value} width={params.colDef.computedWidth} />
    ),
  },
  {
    field: 'percentage',
    headerName: i18n.t('custodialAccountDetailsPage.beneficiaries.grid.cols.percentage'),
    flex: 1,
  },
  {
    field: 'lastUpdate',
    headerName: i18n.t('custodialAccountDetailsPage.beneficiaries.grid.cols.lastUpdate'),
    flex: 1,
  },
  {
    field: 'status',
    headerName: i18n.t('custodialAccountDetailsPage.beneficiaries.grid.cols.status'),
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const { statusCellProps } = params.row as BeneficiaryRow;
      return <StatusCell {...statusCellProps} />;
    },
  },
];
