import { ICurrencyRow } from 'ui-interfaces/transaction-details/rows/i-currency-row';
import { TransactionDetailResponseModel } from 'models/response/transactions/transaction-detail-response-model';
import { mapAssetByNetworkIconSizeToNumber } from 'utils/multi-mapping/asset-by-network-icon-size/map-asset-by-network-icon-size-to-number';
import { getStaticDefaultIcon } from 'utils/icons-mapping/get-static-default-icon';
import { AssetByNetworkIconSize } from 'ui-enums/asset-by-network-icon-size';
import { getSublabelForPreciousMetalBuyTransactionDetailsPage } from './get-sublabel-for-precious-metal-buy-transaction-details-page';
import { getLabelForPreciousMetalBuyTransactionDetailsPage } from './get-label-for-precious-metal-buy-transaction-details-page';

export const getCurrencyPropsForPreciousMetalBuyDetailsPage = (
  transaction: TransactionDetailResponseModel,
  assetIconsSize: AssetByNetworkIconSize,
): ICurrencyRow => {
  return {
    imageUrl: transaction.assetIconXs,
    size: mapAssetByNetworkIconSizeToNumber(assetIconsSize),
    defaultAssetIcon: getStaticDefaultIcon(transaction.assetTicker, assetIconsSize),
    label: getLabelForPreciousMetalBuyTransactionDetailsPage(transaction),
    subLabel: getSublabelForPreciousMetalBuyTransactionDetailsPage(transaction),
  };
};
