import { css } from '@mui/material';
import { Stack } from 'elements/components';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { preventForwardProps } from 'elements/utils';

export const StyledContainer = elementsStyled(
  Stack,
  preventForwardProps(['isMobile']),
)<{ isMobile: boolean }>(
  ({ isMobile }) => css`
    padding: ${isMobile ? '16px' : '22px'} 0 40px;
  `,
);
