import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { Button, Spacer } from 'elements/components';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { StyledCloseButton, StyledContainer, StyledFooterContainer, StyledHeadline, StyledTypography } from './styled';

export type PopoverModalContentProps = {
  onClosePopoverModal: () => void;
  href?: string | null;
  title?: string;
  subtitle?: string;
  hrefButtonName?: string;
  closeButtonName?: string;
};
const PopoverModalContent: React.FC<PopoverModalContentProps> = ({
  onClosePopoverModal,
  href,
  title,
  subtitle,
  hrefButtonName,
  closeButtonName,
}) => {
  const { isMobile } = useIsMobile();

  return (
    <StyledContainer isMobile={isMobile}>
      <StyledHeadline>
        <Typography variant="h6" color="primary.font">
          {title}
        </Typography>
        <StyledCloseButton onClick={onClosePopoverModal}>
          <CloseIcon />
        </StyledCloseButton>
      </StyledHeadline>
      {subtitle && (
        <>
          <Spacer size={8} />
          <Typography variant="bodyDefaultBook" color="secondary.font">
            {subtitle}
          </Typography>
        </>
      )}
      <StyledFooterContainer>
        {href && (
          <Button
            buttonType="primary"
            // @ts-ignore
            component="a"
            href={href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledTypography variant="buttonLarge">{hrefButtonName}</StyledTypography>
          </Button>
        )}
        {isMobile && (
          <>
            <Spacer size={12} />
            <Button buttonType="tertiary" onClick={onClosePopoverModal}>
              <Typography variant="buttonLarge"> {closeButtonName}</Typography>
            </Button>
          </>
        )}
      </StyledFooterContainer>
    </StyledContainer>
  );
};
export default PopoverModalContent;
