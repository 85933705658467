import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { LoadingButtonWithSkeleton } from 'components/shared/buttons/LoadingButtonWithSkeleton';
import { ReactComponent as ResetIcon } from 'assets/icons/settings/user-action/reset-white.svg';

type Props = {
  isLoading: boolean;
  onClick: () => void;
};

export const ResetPasswordButton: React.FC<Props> = ({ isLoading, onClick }) => {
  const { t } = useTranslation();

  return (
    <LoadingButtonWithSkeleton
      variant="outlined"
      size="large"
      icon={<ResetIcon />}
      text={<Typography variant="buttonMedium">{t('userDetailsPage.buttons.resetPassword')}</Typography>}
      loading={isLoading}
      onClick={onClick}
    />
  );
};
