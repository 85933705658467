import React from 'react';
import { HorizontalSpace, VerticalSpace } from './styled';

interface Props {
  size: number;
  isHorizontal?: boolean;
}

export const Spacer: React.FC<Props> = ({ size, isHorizontal = false }) => {
  const Space = isHorizontal ? HorizontalSpace : VerticalSpace;
  return <Space size={size} />;
};
