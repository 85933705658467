import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LoginPageContent } from 'components/auth/LoginPageContent';
import { Auth0ClientSingleton } from 'auth0/auth0-client-singleton';
import { MessageType, setBannerData } from 'redux/banner';

export const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onLoginClick = async () => {
    if (!navigator.onLine) {
      dispatch(setBannerData({ type: MessageType.error, message: t('offlineNetworkMessage') }));
      return;
    }

    try {
      const auth0 = await Auth0ClientSingleton.getInstance();
      await auth0.loginWithRedirect();
    } catch (error) {
      dispatch(setBannerData({ type: MessageType.error, message: t('serverErrorBanner') }));
    }
  };

  return <LoginPageContent onLoginClick={onLoginClick} />;
};
