import { UserLimitType } from 'models/enums/user/user-limit-type';
import { LimitInputCollectionType } from 'ui-enums/create-user-form/limit-input-collection-type';

export const mapUserLimitTypeToLimitInputCollectionType = (limitType: UserLimitType): LimitInputCollectionType => {
  switch (limitType) {
    case UserLimitType.approveAmount:
    case UserLimitType.approveCount:
      return LimitInputCollectionType.approve;
    case UserLimitType.initAmount:
    case UserLimitType.initCount:
      return LimitInputCollectionType.init;
    default:
      return LimitInputCollectionType.approve;
  }
};
