import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccountBalanceOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Divider, Stack } from 'elements/components';
import { StyledButton, StyledKeyboardArrowRight } from './styled';

type Props = {
  onOpenAddBankAccount: () => void;
  withBankAccounts: boolean;
};
const AddPayment: React.FC<Props> = ({ onOpenAddBankAccount, withBankAccounts }) => {
  const { t } = useTranslation();

  if (!withBankAccounts) {
    return null;
  }

  return (
    <>
      <StyledButton onClick={onOpenAddBankAccount} disableRipple>
        <AccountBalanceOutlined color="secondary" />
        <Stack direction="row" fullWidth justifyContent="space-between">
          <Typography variant="buttonLarge" color="primary.font">
            {t('elements.features.paymentMethods.connectAccount')}
          </Typography>
          <StyledKeyboardArrowRight />
        </Stack>
      </StyledButton>
      <Divider />
    </>
  );
};

export default AddPayment;
