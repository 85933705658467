import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spacer } from 'elements/components';
import { PaymentMethodButton, PaymentMethodItemState } from 'elements/features/PaymentMethods';
import { CustodialAccount, PaymentAccount } from 'elements/types';
import { formatUsdBalance } from 'elements/utils';
import DepositTo from 'elements/element-transfer/components/Deposit/DepositTo';
import TransactionSummary from 'elements/element-transfer/components/TransactionSummary';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { StyledTertiaryDivider, StyledWideDivider } from './styled';

type Props = {
  depositAmount: number;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  paymentAccount: PaymentAccount | null;
  custodialAccount: CustodialAccount | null;
};

const DepositInfo: React.FC<Props> = ({ depositAmount, icon: Icon, paymentAccount, custodialAccount }) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  const total = depositAmount;

  return (
    <>
      <TransactionSummary
        icon={Icon}
        amount={formatUsdBalance(depositAmount, isMobile)}
        amountLabel={t('elements.transfer.depositAchReviewPage.depositAmount')}
        totalAmount={formatUsdBalance(total, isMobile)}
        totalLabel={t('elements.common.total')}
      />
      <Spacer size={32} />
      <StyledWideDivider isMobile={isMobile} />
      <Spacer size={isMobile ? 32 : 20} />
      <PaymentMethodButton account={paymentAccount} state={PaymentMethodItemState.NotEditable} withBalance />
      <StyledTertiaryDivider />
      {!!custodialAccount && (
        <>
          <DepositTo custodialAccount={custodialAccount} />
          <StyledTertiaryDivider />
        </>
      )}
    </>
  );
};

export default DepositInfo;
