import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';

type Props = {
  size: number;
};

export const HorizontalSpace = elementsStyled('div')<Props>(
  ({ size }) => css`
    width: ${size}px;
    flex: 0 0 ${size}px;
  `,
);

export const VerticalSpace = elementsStyled('div')<Props>(
  ({ size }) => css`
    padding-top: ${size}px;
  `,
);
