import React, { Reducer, ReducerAction, ReducerState, createContext, useMemo, useReducer } from 'react';

function createElementContext<R extends Reducer<ReducerState<R>, ReducerAction<R>>>(
  elementReducer: R,
  initialState: ReducerState<R>,
) {
  const ElementContext = createContext({
    state: initialState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: (value?: any) => value,
  });

  if (process.env.NODE_ENV !== 'production') {
    ElementContext.displayName = 'ElementContext';
  }

  function ElementProvider({
    children,
    state: _state = {},
  }: {
    children: React.ReactNode;
    state?: Partial<ReducerState<R>>;
  }) {
    const [state, dispatch] = useReducer(elementReducer, { ...initialState, ..._state });

    const context = useMemo(() => ({ state, dispatch }), [state, dispatch]);

    return <ElementContext.Provider value={context}>{children}</ElementContext.Provider>;
  }

  return {
    ElementContext,
    ElementProvider,
  };
}

export default createElementContext;
