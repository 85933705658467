import { TransactionDetailResponseModel } from 'models/response/transactions/transaction-detail-response-model';
import { getLabelForPreciousMetalSellTransactionDetailsPage } from 'utils/transactions/detail/precious-metal/sell/get-label-for-precious-metal-sell-transaction-details-page';
import { getSublabelForPreciousMetalSellTransactionDetailsPage } from 'utils/transactions/detail/precious-metal/sell/get-sublabel-for-precious-metal-sell-transaction-details-page';

export const getPdfAmountForPreciousMetalSell = (transaction: TransactionDetailResponseModel): string => {
  const label = getLabelForPreciousMetalSellTransactionDetailsPage(transaction);

  const subLabel = getSublabelForPreciousMetalSellTransactionDetailsPage(transaction);

  if (subLabel) {
    return `${label} / ${subLabel}`;
  }

  return label;
};
