import React from 'react';
import { Text as _Text, View as _View } from '@react-pdf/renderer';
import { createPdfRow } from 'components/tapi/transactions/TapiTransactionDetailsPage/pdf/create-pdf-row';
import { PdfNode } from 'ui-interfaces/transaction-details/pdf/pdf-node';
import { styles } from './styles';

const View: any = _View;
const Text: any = _Text;

type Props = {
  node: PdfNode;
  hasBottomBorder: boolean;
};

export const PdfNodeElement: React.FC<Props> = ({ node, hasBottomBorder }) => {
  return (
    <View>
      {node.title && <Text style={styles.titleText}>{node.title}</Text>}
      {node.items.map(createPdfRow)}
      {hasBottomBorder && <View style={styles.divider} />}
    </View>
  );
};
