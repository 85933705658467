import React, { useState } from 'react';
import ContentBlock from 'elements/element-transfer/components/Withdrawal/WireAccountNumbersPage';
import useWireWithdrawalAccountNumbersPage from 'elements/element-transfer/hooks/useWireWithdrawalAccountNumbersPage';
import { ConfirmModal } from 'elements/components/ConfirmModal';
import { OnCloseElement } from 'elements/models/types/element-result';

const WireAccountNumbersPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { loading, error, isDomestic, initialFormData, routingNumberCustomError, handleContinue, handleBack } =
    useWireWithdrawalAccountNumbersPage();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const onConfirmDecline = () => setIsConfirmModalOpen(false);

  const onCustomClose = () => setIsConfirmModalOpen(true);

  return (
    <>
      <ContentBlock
        loading={loading}
        error={error}
        isDomestic={isDomestic}
        initialFormData={initialFormData}
        routingNumberCustomError={routingNumberCustomError}
        onClose={onCustomClose}
        onContinue={handleContinue}
        onBack={handleBack}
      />
      {isConfirmModalOpen && <ConfirmModal onConfirm={onClose} onDecline={onConfirmDecline} />}
    </>
  );
};

export default WireAccountNumbersPage;
