import { Filter } from 'filters/interfaces/filter';
import { Filterable } from 'ui-interfaces/shared/filters/filterable';
import { SelectionMacroCommand } from 'filters/commands/selection-macro-command';
import { FilterCommand } from 'filters/commands/filter-command';
import { CustodialAccountCustomAssetBalanceProperty } from 'ui-enums/custodial-accounts/custodial-account-custom-asset-balance-property';

export const getCustodialAccountCustomAssetBalanceFilters = (defaultFilters: Filter[]): Filterable => {
  return {
    filters: defaultFilters,
    selectionMacroCommand: new SelectionMacroCommand(
      defaultFilters.map(f => new FilterCommand(f)),
      defaultFilters.findIndex(f => f.getProperty() === CustodialAccountCustomAssetBalanceProperty.name),
    ),
  };
};
