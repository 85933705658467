import React from 'react';
import { useCreateElement } from 'elements/hooks/use-create-element';
import { Element } from 'ui-interfaces/elements/element';
import { LoadingElementButton } from 'components/shared/buttons/LoadingElementButton';

type Props = Omit<Element, 'isNativeElement'> & {
  isSkeletonDisplayed: boolean;
};

export const NativeElementButton: React.FC<Props> = ({
  name,
  elementType,
  elementConfig,
  onDoneCb,
  custodialAccountId,
  isSkeletonDisplayed,
}) => {
  const { runElement, isLoading } = useCreateElement(custodialAccountId!, elementType, elementConfig, onDoneCb);

  return (
    <LoadingElementButton
      name={name}
      isLoading={isLoading}
      onClick={runElement}
      isSkeletonDisplayed={isSkeletonDisplayed}
    />
  );
};
