export enum TokenDetailsRowType {
  plain = 'plain',
  link = 'link',
  blockchain = 'blockchain',
  fileType = 'fileType',
  loading = 'loading',
  properties = 'properties',
  image = 'image',
  imageLoading = 'imageLoading',
}
