import React from 'react';
import { IPlainSection } from 'ui-interfaces/transaction-details/sections/i-plain-section';
import { ShadowBox } from 'components/shared/detail-page/rows/shared/ShadowBox';
import { Gap } from 'components/shared/Gap';
import HeaderRow from 'components/tapi/transactions/TapiTransactionDetailsPage/HeaderRow';
import CurrencyRow from 'components/tapi/transactions/TapiTransactionDetailsPage/CurencyRow';
import { DetailNode } from 'components/tapi/transactions/TapiTransactionDetailsPage/DetailNode';

type Props = {
  data: IPlainSection;
};

export const PlainSection: React.FC<Props> = ({ data }) => {
  return (
    <>
      <ShadowBox>
        <HeaderRow label={data.label} />
        <Gap size="_32px" />
        <CurrencyRow data={data.currencyRow} />
        <Gap size="_16px" />
        {data.nodes.map((node, index, originalArray) => (
          <DetailNode key={node.id} node={node} hasBottomBorder={index !== originalArray.length - 1} />
        ))}
      </ShadowBox>
      <Gap size="_24px" />
    </>
  );
};
