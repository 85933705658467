import { RangeType } from 'models/enums/user/range-type';
import { UserLimitType } from 'models/enums/user/user-limit-type';
import { UserLimitModel } from 'models/response/users/user-limit-model';
import { generateRandomUUID } from 'utils/generate-random-uuid';
import { performLimitsValidation } from 'utils/user-form/perform-limits-validation';
import { groupBy } from 'utils/group-by';
import { ILimitInputCollection } from 'ui-interfaces/user-form/i-limit-input-collection';
import { ILimitInput } from 'ui-interfaces/user-form/i-limit-input';
import { LimitInputCollectionType } from 'ui-enums/create-user-form/limit-input-collection-type';
import { LimitInputType } from 'ui-enums/create-user-form/limit-input-type';
import { mapUserLimitTypeToLimitInputType } from 'utils/user-form/map-user-limit-type-to-limit-input-type';
import { LimitInput } from './limit-input';

export class LimitInputCollection implements ILimitInputCollection {
  private _items: ILimitInput[] = [];

  private readonly _type: LimitInputCollectionType;

  private static maxNumOfLimits = 7;

  private _errorMessage: string | undefined;

  constructor(type: LimitInputCollectionType) {
    this._type = type;
  }

  get items(): ILimitInput[] {
    return this._items.slice();
  }

  get type(): LimitInputCollectionType {
    return this._type;
  }

  get errorMessage(): string | undefined {
    return this._errorMessage;
  }

  isFull(): boolean {
    return this._items.length === LimitInputCollection.maxNumOfLimits;
  }

  add(): void {
    if (this.isFull()) {
      return;
    }

    this._items.push(
      new LimitInput({
        id: generateRandomUUID(),
        limitInputType: LimitInputType.amount,
        rangeValue: RangeType.week,
        maxValue: '',
        typeValue:
          this._type === LimitInputCollectionType.approve ? UserLimitType.approveAmount : UserLimitType.initAmount,
      }),
    );
  }

  addByUserLimitModel(userLimitModel: UserLimitModel): void {
    if (this.isFull()) {
      return;
    }

    this._items.push(
      new LimitInput({
        id: generateRandomUUID(),
        limitInputType: mapUserLimitTypeToLimitInputType(userLimitModel.type),
        rangeValue: userLimitModel.rangeType,
        maxValue: userLimitModel.maxValue.toString(),
        typeValue: userLimitModel.type,
      }),
    );
  }

  hasValueChanged(): boolean {
    return this._items.some(i => i.hasValueChanged());
  }

  isValid(): boolean {
    return !this._errorMessage;
  }

  remove(id: string) {
    this._items = this._items.filter(i => i.id !== id);
  }

  reset(): void {
    this._items = [];
    this._errorMessage = undefined;
  }

  validate() {
    const limits = this.getState();

    if (!limits.length) {
      this._errorMessage = undefined;
      return;
    }

    const groupedByTypeLimitsMap = groupBy(limits, el => el.type);
    const groupedByTypeLimits = Array.from(groupedByTypeLimitsMap.values());

    for (let i = 0; i < groupedByTypeLimits.length; i += 1) {
      this._errorMessage = performLimitsValidation(groupedByTypeLimits[i]);
      if (this._errorMessage) {
        return;
      }
    }
  }

  getState(): UserLimitModel[] {
    return this._items.map(i => i.getState()).filter(i => !Number.isNaN(i.maxValue));
  }
}
