import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';

export const StyledSectionHeader = styled(Typography, { shouldForwardProp: prop => prop !== 'open' })<{
  open: Boolean;
}>(({ theme, open }) => ({
  paddingLeft: open ? pxToRem(theme.gaps._32px) : undefined,
  textAlign: open ? undefined : 'center',
  marginTop: pxToRem(theme.gaps._32px),
  opacity: '50%',
}));
