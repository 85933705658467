import React from 'react';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import AccountVerification from './VerificationLayout';
import { VerificationType } from './types';
import { getVerificationTypeTexts } from './utils/getVerificationType';

type Props = {
  pageTitle: string;
  onBack: () => void;
  onLetsGo?: () => void;
  contactUsHref?: string | null;
  verificationType: VerificationType;
};

const IdentityVerification: React.FC<Props> = ({ pageTitle, onBack, onLetsGo, verificationType, contactUsHref }) => {
  const { isMobile } = useIsMobile();

  const { title, subtitle } = getVerificationTypeTexts(verificationType);

  return (
    <AccountVerification
      pageTitle={pageTitle}
      title={title}
      subtitle={subtitle}
      onBack={onBack}
      onLetsGo={onLetsGo}
      contactUsHref={contactUsHref}
      isTertiaryButtonShown={isMobile || verificationType !== VerificationType.VerifyIdentity}
    />
  );
};

export { IdentityVerification };
