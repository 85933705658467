import React from 'react';
import Typography from '@mui/material/Typography';

type Props = {
  time: string;
};

export const Row2: React.FC<Props> = ({ time }) => {
  return (
    <Typography color="grey.600" variant="captionMedium">
      {time}
    </Typography>
  );
};
