import i18n from 'localizations';
import { TokenRow } from 'ui-interfaces/row-interfaces/wallets/token-row';
import { TokenResponseModel } from 'models/response/tokens/token-response-model';
import { getNetworkChainLabel } from 'utils/labels-mapping/get-network-chain-label';
import { formatWalletAddress } from 'utils/wallets/format-wallet-address';
import { getTokenTypeLabel } from 'utils/labels-mapping/get-token-type-label';

export class TokenViewModel implements TokenRow {
  readonly id: string;

  readonly imageUrl?: string;

  readonly tokenName?: string;

  readonly formattedContractAddress: string;

  readonly fullContractAddress: string;

  readonly contractAddressUrl: string;

  readonly blockchainTokenId?: string;

  readonly blockchainLabel: string;

  readonly tokenStandard: string;

  readonly fileType?: string;

  readonly amount: string;

  constructor(token: TokenResponseModel) {
    this.id = token.id;
    this.imageUrl = token.xsThumbnail;
    this.tokenName = token.name;
    this.formattedContractAddress = formatWalletAddress(token.contractAddress);
    this.fullContractAddress = token.contractAddress;
    this.contractAddressUrl = token.contractAddressUrl;
    this.blockchainTokenId = token.blockchainTokenId;
    this.blockchainLabel = getNetworkChainLabel(token.network);
    this.tokenStandard = getTokenTypeLabel(token.tokenType);
    this.fileType = token.fileType;
    this.amount = token.amount ?? i18n.t('walletDetailsPage.tokens.emptyFields.amount');
  }
}
