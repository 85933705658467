import React from 'react';
import { getSnackbarSetup } from 'elements/utils';
import { StyledSnackbarProvider } from './styled';

const SnackbarProvider: React.FC = ({ children }) => (
  <StyledSnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'top' }} {...getSnackbarSetup()}>
    {children}
  </StyledSnackbarProvider>
);

export default SnackbarProvider;
