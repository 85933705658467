import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustodialAccount } from 'elements/types';
import { formatCryptoBalance } from 'elements/utils';
import { CryptoSendState } from 'elements/element-transfer/contexts/Element';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import { ICoinListItem } from 'ui-interfaces/elements/i-coin-list-item';
import { AssetByNetworkIconSize } from 'ui-enums/asset-by-network-icon-size';
import { LabelLength } from 'ui-enums/label-length';
import { getStaticDefaultIcon } from 'utils/icons-mapping/get-static-default-icon';
import { mapAssetByNetworkIconSizeToNumber } from 'utils/multi-mapping/asset-by-network-icon-size/map-asset-by-network-icon-size-to-number';
import { getDynamicAssetLabel } from 'utils/labels-mapping/get-dynamic-asset-label';
import TransactionSummary from 'elements/element-transfer/components/TransactionSummary';
import SourceDestinationComponent from '../SourceDestinationComponent';
import { StyledSourceDestinationComponent, StyledDestinationTagComponent } from './styled';

type Props = {
  sendCryptoState: CryptoSendState;
  custodialAccount: CustodialAccount | null;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
};

const mapCustodialAccountBalanceModelToICoinListItem = (cab: CustodialAccountBalanceModel): ICoinListItem => {
  const assetIconSize = AssetByNetworkIconSize['56px'];

  return {
    asset: {
      imageUrl: cab.assetIconXs,
      shortName: getDynamicAssetLabel(LabelLength.short, cab.assetTicker, cab.assetName),
      longName: getDynamicAssetLabel(LabelLength.long, cab.assetTicker, cab.assetName),
      defaultAssetIcon: getStaticDefaultIcon(cab.assetTicker, assetIconSize),
    },
    size: mapAssetByNetworkIconSizeToNumber(assetIconSize),
  };
};

const TransactionDetails: React.FC<Props> = ({ sendCryptoState, custodialAccount, icon }) => {
  const { t } = useTranslation();
  const { amount, walletAddress, selectedCoin, destinationTag } = sendCryptoState;

  if (!selectedCoin) {
    return null;
  }

  const formattedAmount = amount
    ? formatCryptoBalance(amount, { withCommas: true, coinAbbreviation: selectedCoin.assetTicker })
    : '';

  const assetData = mapCustodialAccountBalanceModelToICoinListItem(selectedCoin);

  return (
    <>
      <TransactionSummary
        icon={icon}
        amount={formatCryptoBalance(amount, { withCommas: true })}
        amountLabel={assetData.asset.longName}
        totalAmount={formattedAmount}
        totalLabel={t('elements.common.total')}
        coinName={assetData.asset.longName}
        coinAbbreviation={assetData.asset.shortName}
      />

      <StyledSourceDestinationComponent label={t('elements.common.sendTo')} address={walletAddress} />
      {destinationTag && (
        <StyledDestinationTagComponent
          label={t('elements.transfer.withdrawalCryptoAmountPage.destinationTag')}
          address={destinationTag}
        />
      )}

      {!!custodialAccount && (
        <SourceDestinationComponent
          label={t('elements.common.from')}
          address={custodialAccount.accountNumber}
          accountType={custodialAccount.type}
        />
      )}
    </>
  );
};

export default TransactionDetails;
