import { Tab, Tabs } from '@mui/material';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { preventForwardProps } from 'elements/utils';

export const StyledTabs = elementsStyled(
  Tabs,
  preventForwardProps(['isMobile']),
)<{ isMobile: boolean }>(
  ({ isMobile }) => css`
    margin-top: ${isMobile ? '22px' : '28px'};
  `,
);

export const StyledTab = elementsStyled(Tab)(
  ({ theme }) => css`
    color: ${theme.palette.secondary.font};

    &:nth-of-type(1) {
      margin-right: 16px;
      margin-left: 12px;
    }

    &:nth-of-type(2) {
      margin-left: 16px;
      margin-right: 12px;
    }
  `,
);
