import { IPermissionsCheckbox } from 'ui-interfaces/user-form/i-permissions-checkbox';
import { UserFormInput } from 'ui-enums/create-user-form/user-form-input';
import { getPermissionCheckboxLabels } from 'utils/user-form/get-permission-checkbox-labels';
import { UserDetailResponseModel } from 'models/response/users/user-detail-response-model';

export const generatePermissionsFromUserDetailResponseModel = (
  userDetailResponseModel: UserDetailResponseModel,
): IPermissionsCheckbox[] => {
  return userDetailResponseModel.permissions.map((p, index) => {
    const { title, description } = getPermissionCheckboxLabels(p.key);

    return {
      id: p.key,
      value: index,
      dataValue: p.key,
      uiElementType: UserFormInput.checkbox,
      title,
      description,
    };
  });
};
