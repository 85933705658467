import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import * as api from 'elements/element-trade/api';
import ContentBlock from 'elements/element-trade/components/ReviewPage';
import { filterPositiveCustodialAccounts } from 'elements/element-trade/utils/filterPositiveCustodialAccounts';
import { LoadingPage } from 'elements/features/LoadingErrorState';
import { selectDefaultAccount } from 'elements/features/PaymentMethods';
import { setErrorBanner } from 'elements/utils';
import {
  setCustodialAccountList,
  setExternalAccountList,
  setOrderFailed,
  setNote,
  setPage,
  setPaymentAccount,
  useElement,
} from 'elements/element-trade/contexts/Element';
import { Paths } from 'elements/element-trade/navigation/routes';
import { ConfirmModal } from 'elements/components/ConfirmModal';
import { OnCloseElement } from 'elements/models/types/element-result';
import PaymentMethodPage from './PaymentMethodPage';

const ReviewPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch, state } = useElement();

  const [isLoading, setIsLoading] = useState(true);
  const [isGetAccountsFailed, setIsGetAccountsFailed] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const {
    flowType,
    isOrderFailed,
    failedOrderMessage,
    failedOrderTraceId,
    [flowType]: { amount, selectedBuySellCoin, coinPrice, note },
    paymentAccount,
    page,
    paymentMethodTypesMap,
    custodialAccountId,
    identityId,
  } = state;

  const newLinkedAccountId = page.state?.linkedAccountId as string | void;

  const onNoteChange = (value: string) => dispatch(setNote(value));

  const onBack = (): void => {
    dispatch(setPage(Paths.BuySell));
  };

  const onConfirmDecline = () => setIsConfirmModalOpen(false);

  const onCustomClose = () => setIsConfirmModalOpen(true);

  const onSubmit = (): void => {
    dispatch(setPage(Paths.OrderProcessing));
  };

  const requestAccounts = async () => {
    try {
      setIsLoading(true);
      setIsGetAccountsFailed(false);

      // TODO delete redundant getCustodialAccounts request
      const [custodialAccountList, externalAccountList] = await Promise.all([
        api.tradeElement.getCustodialAccounts(custodialAccountId),
        api.tradeElement.getExternalAccounts(identityId),
      ]);

      dispatch(setCustodialAccountList(custodialAccountList.data));
      dispatch(setExternalAccountList(externalAccountList.data));

      const defaultAccount = selectDefaultAccount(
        filterPositiveCustodialAccounts(custodialAccountList.data),
        externalAccountList.data,
        paymentMethodTypesMap,
      );

      if (defaultAccount) {
        dispatch(setPaymentAccount(defaultAccount));
      }

      dispatch(setOrderFailed(false));

      setIsLoading(false);
    } catch (e) {
      const isBannerShown = setErrorBanner(e, enqueueSnackbar);
      if (!isBannerShown) {
        setIsGetAccountsFailed(true);
      }
      setIsLoading(false);
    }
  };

  const requestAccountsWithNewExternal = async () => {
    try {
      setIsLoading(true);

      // TODO delete redundant getCustodialAccounts request
      const [custodialAccountList, externalAccountList] = await Promise.all([
        api.tradeElement.getCustodialAccounts(custodialAccountId),
        api.tradeElement.getExternalAccounts(identityId),
      ]);

      dispatch(setCustodialAccountList(custodialAccountList.data));
      dispatch(setExternalAccountList(externalAccountList.data));

      const newLinkedMethod = externalAccountList.data.find(el => el.id === newLinkedAccountId);

      dispatch(setPaymentAccount(newLinkedMethod));

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const onReloadPage = async () => {
    await requestAccounts();
  };

  useEffect(() => {
    if (newLinkedAccountId) {
      (async () => {
        await requestAccountsWithNewExternal();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLinkedAccountId]);

  useEffect(() => {
    (async () => {
      if (isOrderFailed) {
        setIsLoading(false);
      } else {
        await requestAccounts();
      }
    })();

    return () => {
      dispatch(setOrderFailed(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOrderFailed]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <ContentBlock
        amount={amount}
        coinPrice={coinPrice}
        coin={selectedBuySellCoin}
        isProcessingError={isOrderFailed}
        processingErrorMessage={failedOrderMessage}
        processingErrorTraceId={failedOrderTraceId}
        isLoadingError={isGetAccountsFailed}
        paymentAccount={paymentAccount}
        note={note}
        onNoteChange={onNoteChange}
        onClose={onCustomClose}
        onBack={onBack}
        onSubmit={onSubmit}
        onReload={onReloadPage}
        flowType={flowType}
      />
      {state.page.location === Paths.PaymentMethod ? <PaymentMethodPage onClose={onCustomClose} /> : null}
      {isConfirmModalOpen && <ConfirmModal onConfirm={onClose} onDecline={onConfirmDecline} />}
    </>
  );
};

export default ReviewPage;
