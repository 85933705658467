import { useState } from 'react';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { API_BASE_URL } from 'redux/api/api-routes';
import { MessageType, setBannerData } from 'redux/banner';
import { FilterResult } from 'filters/interfaces/filter-result';
import { UseExportReturnValue, FetchCSV } from 'hooks/use-export/interfaces';
import { Auth0ClientSingleton } from 'auth0/auth0-client-singleton';

export const useExport = (): UseExportReturnValue => {
  const dispatch = useDispatch();
  const [error, setError] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const downloadCsv = async ({ urlSegment, filterResult, preFilterParams }: FetchCSV) => {
    try {
      const auth0 = await Auth0ClientSingleton.getInstance();
      const token = await auth0.getTokenSilently();
      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: 'text/csv',
        },
      };

      setIsLoading(true);

      if (!navigator.onLine) {
        dispatch(setBannerData({ type: MessageType.error, message: i18next.t('offlineNetworkMessage') }));
        setIsLoading(false);
        setError(new Error());
        return;
      }

      let url: string;
      if (filterResult) {
        const { property, operator, value } = filterResult as FilterResult;
        url = `${API_BASE_URL}${urlSegment}?${new URLSearchParams({
          ...(preFilterParams ?? {}),
          [`filter[${property} ${operator}]`]: value.trim(),
        })}`;
      } else if (preFilterParams) {
        url = `${API_BASE_URL}${urlSegment}?${new URLSearchParams({
          ...preFilterParams,
        })}`;
      } else {
        url = `${API_BASE_URL}${urlSegment}`;
      }
      const res = await fetch(url, {
        ...header,
      });
      if ((res && res.status === 404) || res.status === 503) {
        throw new Error();
      }
      const contentType = res.headers.get('Content-Disposition');
      const fileName = contentType?.split('; ')?.[1]?.split('filename=')?.[1];
      const blob = await res.blob();
      // Create blob link to download
      const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName ?? 'sample.csv');
      // Append to html page
      document.body.appendChild(link);
      // Force download
      link.click();
      // Clean up and remove the link
      link.parentNode?.removeChild(link);
      setIsLoading(false);
    } catch (err) {
      setError(err);
      setIsLoading(false);
      dispatch(setBannerData({ type: MessageType.error, message: i18next.t('serverErrorBanner') }));
    }
  };

  return { isLoading, error, downloadCsv };
};
