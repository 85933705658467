import React from 'react';
import { Typography } from '@mui/material';
import { Gap } from 'components/shared/Gap';
import { Row } from 'components/shared/Flex/Row';
import { Column } from 'components/shared/Flex/Column';
import { AssetImage } from 'components/shared/Cells/AssetImage';
import { ICurrencyRow } from 'ui-interfaces/transaction-details/rows/i-currency-row';

type Props = {
  data: ICurrencyRow;
};

const CurrencyRow: React.FC<Props> = ({ data }) => {
  return (
    <Row sx={{ alignItems: 'center' }}>
      <Column sx={{ alignSelf: 'flex-start' }}>
        <AssetImage url={data.imageUrl} size={data.size} defaultAssetIcon={data.defaultAssetIcon} />
      </Column>
      <Gap size="_12px" isHorizontal />
      <Column>
        <Typography variant="h4">{data.label}</Typography>
        {data.subLabel ? (
          <Typography color="grey.600" variant="subtitleBook">
            {data.subLabel}
          </Typography>
        ) : null}
      </Column>
    </Row>
  );
};

export default CurrencyRow;
