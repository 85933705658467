import React from 'react';
import { UserDetailsRowType } from 'ui-enums/user-details/user-details-row-type';
// rows components - start
import { DefaultRow } from 'components/settings/users/UserDetailsPageContent/list-items/rows/DefaultRow';
import { LoadingRow } from 'components/shared/detail-page/rows/LoadingRow';
import { CanApproveRow } from 'components/settings/users/UserDetailsPageContent/list-items/rows/CanApproveRow';
import { RoleRow } from 'components/settings/users/UserDetailsPageContent/list-items/rows/RoleRow';
import { ShortRow } from 'components/settings/users/UserDetailsPageContent/list-items/rows/ShortRow';
import { SubHeaderRow } from 'components/settings/users/UserDetailsPageContent/list-items/rows/SubHeaderRow';
import { DefaultNoBorderRow } from 'components/settings/users/UserDetailsPageContent/list-items/rows/DefaultNoBorderRow';
// rows components - end
// rows interfaces - start
import { Identifiable } from 'ui-interfaces/user-details/identifiable';
import { IDefaultRow } from 'ui-interfaces/user-details/rows/i-default-row';
import { ICanApproveRow } from 'ui-interfaces/user-details/rows/i-can-approve-row';
import { IRoleRow } from 'ui-interfaces/user-details/rows/i-role-row';
import { ISubHeaderRow } from 'ui-interfaces/user-details/rows/i-sub-header-row';
// rows interfaces - end

export const createRow = (itemData: Identifiable, isLastItem: boolean): React.ReactElement => {
  switch (itemData.rowType) {
    case UserDetailsRowType.default: {
      const i = itemData as IDefaultRow;
      return <DefaultRow key={i.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }

    case UserDetailsRowType.defaultNoBorder: {
      const i = itemData as IDefaultRow;
      return <DefaultNoBorderRow key={i.id} data={{ ...i }} />;
    }

    case UserDetailsRowType.loading:
      return <LoadingRow key={itemData.id} hasBottomBorder={!isLastItem} />;

    case UserDetailsRowType.canApprove: {
      const i = itemData as ICanApproveRow;
      return (
        <CanApproveRow
          data={{
            ...i,
          }}
          key={i.id}
          hasBottomBorder={!isLastItem}
        />
      );
    }

    case UserDetailsRowType.role: {
      const i = itemData as IRoleRow;
      return <RoleRow data={{ ...i }} key={i.id} hasBottomBorder={!isLastItem} />;
    }

    case UserDetailsRowType.short: {
      const i = itemData as IDefaultRow;
      return <ShortRow key={i.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }

    case UserDetailsRowType.subHeader: {
      const i = itemData as ISubHeaderRow;
      return <SubHeaderRow key={i.id} data={{ ...i }} />;
    }

    default: {
      const i = itemData as IDefaultRow;
      return <DefaultRow key={i.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }
  }
};
