import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { Filterable } from 'ui-interfaces/shared/filters/filterable';
import { Filter } from 'filters/interfaces/filter';
import { DashboardRoles } from 'models/enums/user/dashboard-roles';
import { SelectionMacroCommand } from 'filters/commands/selection-macro-command';
import { FilterCommand } from 'filters/commands/filter-command';
import { IdentityFilterProperty } from 'ui-enums/identities/identity-filter-property';

export const getIdentitiesFilters = (defaultFilters: Filter[], currentUser?: ShortUserResponseModel): Filterable => {
  if (currentUser && !currentUser.permissions.includes(DashboardRoles.pii)) {
    const filters = defaultFilters.filter(
      f =>
        ![IdentityFilterProperty.email, IdentityFilterProperty.phone].includes(
          f.getProperty() as IdentityFilterProperty,
        ),
    );

    return {
      filters,
      selectionMacroCommand: new SelectionMacroCommand(
        filters.map(f => new FilterCommand(f)),
        filters.findIndex(f => f.getProperty() === IdentityFilterProperty.id),
      ),
    };
  }

  return {
    filters: defaultFilters,
    selectionMacroCommand: new SelectionMacroCommand(
      defaultFilters.map(f => new FilterCommand(f)),
      defaultFilters.findIndex(f => f.getProperty() === IdentityFilterProperty.email),
    ),
  };
};
