import React from 'react';
import { SideMenuIcon } from 'ui-enums/side-menu/side-menu-icon';
// icons - start
import { ReactComponent as IdentitiesIcon } from 'assets/icons/side-menu/identities-faded.svg';
import { ReactComponent as CustodialAccountsIcon } from 'assets/icons/side-menu/custodial-accounts.svg';
import { ReactComponent as TransactionsIcon } from 'assets/icons/side-menu/transactions-faded.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/side-menu/wallet-faded.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/side-menu/settings.svg';
// icons - end

export const getSideMenuIcon = (sideMenuIcon: SideMenuIcon) => {
  switch (sideMenuIcon) {
    case SideMenuIcon.identities: {
      return <IdentitiesIcon />;
    }
    case SideMenuIcon.custodialAccounts: {
      return <CustodialAccountsIcon />;
    }
    case SideMenuIcon.transactions: {
      return <TransactionsIcon />;
    }
    case SideMenuIcon.wallets: {
      return <WalletIcon />;
    }
    case SideMenuIcon.settings: {
      return <SettingsIcon />;
    }
    default: {
      return <TransactionsIcon />;
    }
  }
};
