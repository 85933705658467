import React from 'react';
import { Box, Typography } from '@mui/material';
import { GrayGap } from 'components/shared/detail-page/GrayGap';
import { IdentityDetailsNode } from 'ui-interfaces/identity-details/identity-details-node';
import { StyledBox } from './styled';
import { createIdentityDetailsTabRow } from './createIdentityDetailsTabRow';

type Props = {
  hasBottomBorder: boolean;
  node: IdentityDetailsNode;
};

export const DetailNode: React.FC<Props> = ({ node, hasBottomBorder }) => {
  return (
    <Box>
      {node.title && (
        <StyledBox>
          <Typography variant="h7">{node.title}</Typography>
        </StyledBox>
      )}
      {node.items.map((i, index, originalArray) => {
        return createIdentityDetailsTabRow(i, index === originalArray.length - 1);
      })}
      {hasBottomBorder && <GrayGap />}
    </Box>
  );
};
