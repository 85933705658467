export const getAvatarColor = () => {
  const colors = [
    '#EF5350',
    '#EC407A',
    '#AB47BC',
    '#7E57C2',
    '#5C6BC0',
    '#42A5F5',
    '#29B6F6',
    '#00BCD4',
    '#26A69A',
    '#4CAF50',
    '#8BC34A',
    '#C0CA33',
    '#FBC02D',
    '#FFA000',
    '#F57C00',
    '#FF5722',
  ];
  const key = Math.round(Math.random() * 16);
  return colors[key];
};
