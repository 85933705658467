import React from 'react';
import i18n from 'localizations';
import { CustodialAccountStatus } from 'models/enums/custodial-accounts/custodial-account-status';
import { StatusCellProps } from 'ui-interfaces/statuses/status-cell-props';

// labels - start
import { ReactComponent as PendingLabelIcon } from 'assets/icons/statuses/shared/labels/pending.svg';
import { ReactComponent as OpenIcon } from 'assets/icons/statuses/accounts/labels/open.svg';
import { ReactComponent as SuspendedIcon } from 'assets/icons/statuses/shared/labels/suspended.svg';
import { ReactComponent as ClosedIcon } from 'assets/icons/statuses/accounts/labels/closed.svg';
// labels - end

export const mapCustodialAccountStatusToStatusCellProps = (
  status: CustodialAccountStatus,
  isAccountSuspendedByFortressSystem?: boolean,
): StatusCellProps => {
  switch (status) {
    case CustodialAccountStatus.open:
      return {
        labelIcon: <OpenIcon />,
        pillowProps: {
          color: 'success',
          colorType: 'light2',
        },
        tooltipMessage: i18n.t('statuses.accounts.tooltip.open'),
      };

    case CustodialAccountStatus.pending:
      return {
        labelIcon: <PendingLabelIcon />,
        pillowProps: {
          color: 'info',
          colorType: 'light3',
        },
        tooltipMessage: i18n.t('statuses.accounts.tooltip.pending'),
      };

    case CustodialAccountStatus.suspended:
      return {
        labelIcon: <SuspendedIcon />,
        pillowProps: {
          color: 'error',
          colorType: 'light2',
        },
        tooltipMessage: isAccountSuspendedByFortressSystem
          ? i18n.t('custodialAccountDetailsPage.accountSuspendedByFortressSystem')
          : i18n.t('statuses.accounts.tooltip.suspended'),
      };

    case CustodialAccountStatus.closed:
      return {
        labelIcon: <ClosedIcon />,
        pillowProps: {
          color: 'grey',
          colorType: 100,
        },
        tooltipMessage: i18n.t('statuses.accounts.tooltip.closed'),
      };

    default:
      return {
        labelIcon: <OpenIcon />,
        pillowProps: {
          color: 'success',
          colorType: 'light2',
        },
      };
  }
};
