import i18n from 'localizations';
import { TokenType } from 'models/enums/tokens/token-type';

export const getTokenTypeLabel = (ts: TokenType) => {
  switch (ts) {
    case TokenType.erc721:
      return i18n.t('tokenType.erc721');
    case TokenType.erc1155:
      return i18n.t('tokenType.erc1155');
    case TokenType.erc20:
      return i18n.t('tokenType.erc20');
    case TokenType.solana:
      return i18n.t('tokenType.solana');
    case TokenType.cryptoTransfer:
      return i18n.t('tokenType.cryptoTransfer');
    default:
      return i18n.t('notImplemented');
  }
};
