import React from 'react';
import Typography from '@mui/material/Typography';
import i18n from 'localizations';
import { GridCellExpand } from 'components/shared/Cells/GridCellExpand';

type Props = {
  value?: string;
  width: number;
};

export const DescriptionCell: React.FC<Props> = ({ value, width }) => {
  if (!value) {
    return (
      <Typography color="grey.500" variant="bodyDefaultBook">
        {i18n.t('custodialAccountDetailsPage.customAssetBalance.emptyFields.description')}
      </Typography>
    );
  }

  return <GridCellExpand value={value} width={width} />;
};
