import { TextField } from '@mui/material';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { pxToRem } from 'elements/utils';

export const StyledBaseInput = elementsStyled(TextField)(({ theme, error, label, disabled }) => {
  const backgroundColor = theme.palette.background.custom || theme.palette.background.default;
  const borderColor = error ? theme.palette.error.light2 : theme.palette.tertiary.main;
  const borderColorFocused = error ? theme.palette.error.light2 : theme.palette.primary.main;

  return css`
    background: transparent;
    box-sizing: border-box;

    & .MuiFilledInput-root {
      color: ${theme.palette.primary.font};
      background-color: ${backgroundColor};
      border: ${disabled ? 'none' : `1px solid ${borderColor}`};
      border-radius: ${theme.borderRadius};
      overflow: hidden;
      height: ${pxToRem(52)};
      font-family: ${theme.fontFamily};
      font-style: normal;
      font-weight: normal;
      font-size: ${pxToRem(14)};
      line-height: ${pxToRem(24)};
      letter-spacing: -0.02em;
      box-sizing: border-box;
      padding-left: ${disabled && 0};

      &.Mui-disabled {
        color: ${theme.palette.primary.font};
        background: none;

        .MuiInputBase-input {
          -webkit-text-fill-color: ${theme.palette.primary.font};
          padding-left: ${disabled && 0};
        }

        .MuiButtonBase-root {
          color: ${theme.palette.primary.font};
        }
      }

      &:hover {
        background-color: ${backgroundColor};
      }

      @media (hover: hover) {
        &:hover {
          background-color: ${backgroundColor};
          border: ${disabled ? 'none' : `1px solid ${borderColorFocused}`};
        }
      }

      &.Mui-focused {
        background-color: ${backgroundColor};
        border: ${disabled ? 'none' : `1px solid ${borderColorFocused}`};
      }
    }

    & .MuiFilledInput-input {
      ${label
        ? css`
            padding: ${pxToRem(20.5)} ${pxToRem(12)} ${pxToRem(7.5)};
          `
        : css`
            padding: ${pxToRem(14)} ${pxToRem(12)};
          `}

      height: unset;

      //noinspection CssInvalidPseudoSelector
      &:-webkit-autofill,
      &:autofill {
        border-radius: 8px;
      }

      &::placeholder {
        color: ${theme.palette.secondary.font};
        opacity: 1;
      }

      &:focus {
        background: ${backgroundColor};
      }
    }

    & .MuiInputLabel-root {
      transform: translate(${pxToRem(12)}, ${pxToRem(14)}) scale(1);
      color: ${theme.palette.secondary.font};
      font-family: ${theme.fontFamily};
      font-style: normal;
      font-weight: normal;
      font-size: ${pxToRem(14)};
      line-height: ${pxToRem(24)};
      letter-spacing: -0.02em;

      &.Mui-error {
        color: ${theme.palette.secondary.font};
      }

      & .Mui-error {
        color: ${theme.palette.secondary.font};
      }

      &.MuiInputLabel-shrink {
        transform: translate(${pxToRem(13)}, ${pxToRem(7.5)}) scale(1);
        color: ${theme.palette.primary.font};
        font-family: ${theme.fontFamily};
        font-style: normal;
        font-weight: 500;
        font-size: ${pxToRem(11)};
        line-height: 100%;
        letter-spacing: 0;
        padding-bottom: ${pxToRem(2)};
        left: ${disabled && pxToRem(-12)};

        & .Mui-error {
          color: ${theme.palette.primary.font};
        }
      }
    }

    & .MuiFormHelperText-root {
      color: ${theme.palette.secondary.font};
      display: flex;
      align-items: center;
      font-family: ${theme.fontFamily};
      font-style: normal;
      font-weight: normal;
      font-size: ${pxToRem(12)};
      line-height: ${pxToRem(20)};
      margin: ${pxToRem(8)} 0px 0px 0px;
      white-space: pre-line;

      &.Mui-error {
        color: ${theme.palette.error.main};
      }
    }

    .MuiInputBase-multiline {
      height: auto;
    }

    .MuiInputBase-inputMultiline {
      padding: 0;
    }
  `;
});
