import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';

export const StyledBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'hasBottomBorder',
})<{ hasBottomBorder: boolean }>(({ theme, hasBottomBorder }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: pxToRem(theme.gaps._20px),
  paddingBottom: pxToRem(theme.gaps._20px),
  borderBottom: hasBottomBorder ? `1px solid ${theme.palette.grey['200']}` : 'none',
}));
