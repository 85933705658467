import { styled } from '@mui/material/styles';
import { Typography, TypographyProps } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';
import { DEFAULT_LEFT_CELL_WIDTH, LARGE_LEFT_CELL_WIDTH } from 'components/shared/detail-page/rows/shared/constants';

const ROW_WIDTH = 788;
const ROW_WIDTH_WHEN_LEFT_CELL_LARGE_MODE = ROW_WIDTH - (LARGE_LEFT_CELL_WIDTH - DEFAULT_LEFT_CELL_WIDTH);

export const BreakLineTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'large',
})<TypographyProps & { large?: boolean }>(({ large }) => {
  const maxWidth = large ? ROW_WIDTH : ROW_WIDTH_WHEN_LEFT_CELL_LARGE_MODE;

  return {
    display: 'block',
    maxWidth: pxToRem(maxWidth),
    overflowWrap: 'break-word',
  };
});
