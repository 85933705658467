import React from 'react';
import { Typography } from '@mui/material';
import { StyledBox, CodeBox, LabelBox } from './styled';

type Props = {
  code: string;
  label: string;
};

export const LeftCell: React.FC<Props> = ({ code, label }) => {
  return (
    <StyledBox>
      <CodeBox>
        <Typography variant="bodyDefaultMedium" color="secondary.main">
          {code}
        </Typography>
      </CodeBox>
      <LabelBox>
        <Typography variant="bodyDefaultMedium">{label}</Typography>
      </LabelBox>
    </StyledBox>
  );
};
