import { styled } from '@mui/material/styles';
import { Box, Skeleton } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';
import { Row } from 'components/shared/Flex/Row';

export const StyledHeaderBox = styled(Box)(({ theme }) => ({
  paddingRight: pxToRem(theme.gaps._32px),
  paddingLeft: pxToRem(theme.gaps._32px),
  paddingTop: pxToRem(theme.gaps._40px),
  paddingBottom: pxToRem(theme.gaps._16px),
}));

export const StyledTitleSkeleton = styled(Skeleton)(({ theme }) => ({
  height: '100%',
  width: pxToRem(320),
  backgroundColor: theme.palette.grey['100'],
  borderRadius: pxToRem(theme.gaps._20px),
}));

export const StyledRow = styled(Row)(({ theme }) => ({
  alignItems: 'center',
  height: pxToRem(theme.gaps._32px),
}));
