import React from 'react';
import { IPdfLinkRow } from 'ui-interfaces/transaction-details/pdf/rows/i-pdf-link-row';
import { Row } from '../shared/Row';
import { LeftCell } from '../shared/LeftCell';
import { RightCell } from './RightCell';

type Props = {
  data: IPdfLinkRow;
};

export const PdfLinkRow: React.FC<Props> = ({ data }) => {
  return (
    <Row>
      <LeftCell value={data.label} />
      <RightCell value={data.value} url={data.url} />
    </Row>
  );
};
