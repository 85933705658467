import React from 'react';
import { Trans } from 'react-i18next';
import { BannerType } from 'elements/components';
import {
  AccountStatusType,
  CustodialAccount,
  CustodialAccountTypeEnum,
  IdentityData,
  IdentityLevel,
  IdentityType,
} from 'elements/types';
import i18n from 'localizations';
import { elementsStyled } from 'elements/theme/createCustomTheme';

const StyledSpan = elementsStyled('span')`
  font-weight: 500;
`;

export const getVerificationBannerData = (
  type: IdentityType,
  isInactiveAccount: boolean,
  isNotVerified: boolean,
  isNotL2: boolean,
  isServiceUnavailable: boolean,
) => {
  if (isInactiveAccount) {
    return {
      type: BannerType.Warning,
      text: i18n.t('elements.features.identityCheck.inactiveBannerText1'),
      text2: i18n.t('elements.features.identityCheck.inactiveBannerText2'),
      buttonTitle: i18n.t('elements.features.identityCheck.contactUsLinkText'),
    };
  }

  if (type === IdentityType.Personal && isNotVerified && !isNotL2) {
    return {
      type: BannerType.Info,
      text: (
        <Trans
          t={i18n.t}
          i18nKey="elements.features.identityCheck.notVerifiedBannerText"
          components={{ bold: <StyledSpan /> }}
        >
          {null}
        </Trans>
      ),
      buttonTitle: i18n.t('elements.features.identityCheck.verifyNowBannerButtonText'),
      withRightIcon: true,
    };
  }

  if (type === IdentityType.Personal && isNotL2) {
    return {
      type: BannerType.Info,
      text: (
        <Trans
          t={i18n.t}
          i18nKey="elements.features.identityCheck.additionalVerificationBannerText"
          components={{ bold: <StyledSpan /> }}
        >
          {null}
        </Trans>
      ),
      buttonTitle: i18n.t('elements.features.identityCheck.uploadAndVerifyBannerButtonText'),
      withRightIcon: true,
    };
  }

  if (isServiceUnavailable) {
    return {
      type: BannerType.Error,
      text: i18n.t('elements.features.identityCheck.transferringUnavailableBannerText1'),
      text2: i18n.t('elements.features.identityCheck.transferringUnavailableText2'),
      buttonTitle: i18n.t('elements.features.identityCheck.contactUsLinkText'),
    };
  }

  return {
    type: BannerType.Info,
    text: i18n.t('elements.features.identityCheck.notVerifiedBusinessBannerText'),
    buttonTitle: i18n.t('elements.features.identityCheck.contactUsLinkText'),
  };
};

export const getVerificationData = (
  { status, kycLevel, canUseCustodialAccounts }: IdentityData,
  custodialAccount: CustodialAccount | null,
  requiredLevel: IdentityLevel,
) => {
  const isInactiveAccount = status !== AccountStatusType.Active || !canUseCustodialAccounts;
  const isNotVerified = kycLevel === IdentityLevel.L0;
  const isNotIraL1 =
    !custodialAccount ||
    kycLevel !== IdentityLevel.L1 ||
    (custodialAccount.type !== CustodialAccountTypeEnum.TraditionalIra &&
      custodialAccount.type !== CustodialAccountTypeEnum.SimpleIra &&
      custodialAccount.type !== CustodialAccountTypeEnum.SepIra &&
      custodialAccount.type !== CustodialAccountTypeEnum.RothIra);
  const isNotL2 = requiredLevel === IdentityLevel.L2 && kycLevel !== IdentityLevel.L2;

  return { isInactiveAccount, isNotVerified, isNotL2, isNotIraL1 };
};
