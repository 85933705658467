import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { ReactComponent as FortressLogo } from 'assets/icons/fortress.svg';
import { Column } from 'components/shared/Flex/Column';
import { SideMenu as SideMenuProps } from 'ui-interfaces/side-menu/side-menu';
import { EnvCell } from 'components/shared/Cells/EnvCell';
import { FloatingButton } from './FloatingButton';
import { Footer } from './Footer';
import {
  StyledDrawer,
  StyledDrawerHeader,
  StyledLogoBox,
  StyledLogoIconButton,
  StyledCombineLogoEnvRow,
} from './styled';
import { SideMenuSection } from './SideMenuSection';

type Props = {
  sideMenuProps: SideMenuProps;
  onClickLogo: () => void;
  onLogOut: () => void;
};

export const SideMenu: React.FC<Props> = ({ children, sideMenuProps, onClickLogo, onLogOut }) => {
  const [open, setOpen] = useState(true);
  const [isSideMenuBtnShown, setIsSideMenuBtnShown] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <StyledDrawer variant="permanent" open={open}>
        <Column
          onMouseEnter={() => {
            setIsSideMenuBtnShown(true);
          }}
          onMouseLeave={() => {
            setIsSideMenuBtnShown(false);
          }}
          sx={{
            flex: 1,
            justifyContent: 'space-between',
            backgroundColor: theme => theme.palette.primary.dark,
          }}
        >
          <Column>
            <StyledDrawerHeader>
              <StyledCombineLogoEnvRow>
                <StyledLogoBox>
                  <StyledLogoIconButton onClick={onClickLogo}>
                    <FortressLogo />
                  </StyledLogoIconButton>
                </StyledLogoBox>
                {open && <EnvCell />}
              </StyledCombineLogoEnvRow>
              <FloatingButton
                isShown={isSideMenuBtnShown}
                isDrawerOpen={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
              />
            </StyledDrawerHeader>
            {sideMenuProps.nodes.map(node => (
              <SideMenuSection key={node.id} node={node} isSideMenuOpened={open} />
            ))}
          </Column>
          <Footer
            open={open}
            title={sideMenuProps.footerTitle}
            subtitle={sideMenuProps.footerSubTitle}
            onLogOut={onLogOut}
          />
        </Column>
      </StyledDrawer>
      <Box
        sx={{
          width: '100%',
          height: '100vh',
        }}
        component="main"
      >
        {children}
      </Box>
    </Box>
  );
};
