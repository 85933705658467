import { Typography, styled } from '@mui/material';
import React from 'react';
import { pxToRem } from 'utils/styling-utils';

export const StyledTypography = styled<React.FC<{ isAccountNameExist: boolean }>>(Typography)(
  ({ isAccountNameExist, theme }) => ({
    ...theme.typography.h3,
    fontSize: isAccountNameExist ? pxToRem(24) : pxToRem(28),
  }),
);
