import { useEffect, useState } from 'react';
import { PathMatch, useMatch } from 'react-router-dom';
import i18n from 'localizations';
import { BreadCrumbsItem } from 'components/shared/Breadcrumbs';
import { NESTED_PATHS, PATHS } from 'navigation/constants';
import {
  CustodialAccountDetailsUnderIdentityDetailsUrlParams,
  IdentityDetailsUrlParams,
} from 'navigation/url-params-type';
import { getRouteWithParams } from 'utils/get-route-with-params';

export const useBcCustodialAccountDetailsPage = () => {
  const [breadCrumbs, setBreadCrumbs] = useState<BreadCrumbsItem[]>([]);
  const [pathMatch, setPathMatch] = useState<PathMatch | null>(null);

  const MATCHES_CUSTODIAL_ACCOUNTS_DETAILS = useMatch({
    path: PATHS.TAPI.CUSTODIAL_ACCOUNTS_DETAILS,
    end: true,
  });

  const MATCHES_CUSTODIAL_ACCOUNT_DETAILS_UNDER_IDENTITIES = useMatch({
    path: NESTED_PATHS.IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS,
    end: true,
  });

  useEffect(() => {
    if (MATCHES_CUSTODIAL_ACCOUNTS_DETAILS) {
      setBreadCrumbs([
        {
          label: i18n.t('custodialAccountsPage.breadcrumbs.accounts'),
          path: PATHS.TAPI.CUSTODIAL_ACCOUNTS,
        },
        {
          label: i18n.t('custodialAccountsPage.breadcrumbs.account'),
        },
      ]);
      setPathMatch(MATCHES_CUSTODIAL_ACCOUNTS_DETAILS);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (MATCHES_CUSTODIAL_ACCOUNT_DETAILS_UNDER_IDENTITIES) {
      const { identityId, identityType } =
        MATCHES_CUSTODIAL_ACCOUNT_DETAILS_UNDER_IDENTITIES.params as CustodialAccountDetailsUnderIdentityDetailsUrlParams;
      setBreadCrumbs([
        {
          label: i18n.t('identityDetailsPage.breadcrumbs.identities'),
          path: PATHS.IDENTITIES,
        },
        {
          label: i18n.t('identityDetailsPage.breadcrumbs.identityDetails'),
          path: getRouteWithParams(PATHS.IDENTITY_DETAILS, {
            identityId,
            identityType,
          } as IdentityDetailsUrlParams),
        },
        {
          label: i18n.t('custodialAccountsPage.breadcrumbs.account'),
        },
      ]);
      setPathMatch(MATCHES_CUSTODIAL_ACCOUNT_DETAILS_UNDER_IDENTITIES);
    }
    // eslint-disable-next-line
  }, []);

  return { breadCrumbs, pathMatch };
};
