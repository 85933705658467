import { IAction } from 'ui-interfaces/user-details/actions/i-action';
import { ActionButton } from 'ui-enums/user-details/action-button';
import { UserBaseResponseModel } from 'models/response/users/user-base-response-model';
import { UserDetails } from 'ui-interfaces/user-details/user-details';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { createEditUserFormViewModel } from 'factories/users/edit-user-form-vm';
import { RoleTemplate } from 'models/enums/user/role-template';
import { UserRoleTemplateResponseModel } from 'models/response/users/user-role-template-response-model';

const resendAction = {
  id: 'resend',
  buttonType: ActionButton.resend,
};
const resetAction = {
  id: 'reset',
  buttonType: ActionButton.reset,
};
export const createActions = (
  user: UserBaseResponseModel,
  userDetails: UserDetails,
  currentUser: ShortUserResponseModel,
  userRoleTemplates?: UserRoleTemplateResponseModel[],
): IAction[] => {
  const formViewModel = userRoleTemplates
    ? createEditUserFormViewModel(user, currentUser, userRoleTemplates)
    : undefined;
  const isCurrentUser = user.id === currentUser.id;
  const editAction = {
    id: 'edit',
    buttonType: ActionButton.edit,
    formViewModel,
  };
  const removeAction = {
    id: 'remove',
    buttonType: ActionButton.remove,
    destroyModalMainText: userDetails.headerDetails.destroyModalMainText,
  };
  const roleTemplatesOfEditableUsers = userRoleTemplates
    ? userRoleTemplates.map(({ roleTemplate }) => roleTemplate)
    : [];
  const isEditOrDeleteActionsPermitted = roleTemplatesOfEditableUsers.includes(user.roleTemplate);
  switch (true) {
    // I am Admin or Owner, I see Myself
    case isCurrentUser: {
      if (user.isActive) {
        return [resetAction, editAction];
      }
      return [];
    }

    case user.roleTemplate === RoleTemplate.owner: {
      return [];
    }

    // I am Admin, I see another Admin or Editor or Viewer
    case user.roleTemplate === RoleTemplate.admin:
    case user.roleTemplate === RoleTemplate.editor:
    case user.roleTemplate === RoleTemplate.viewer: {
      if (user.isActive) {
        return isEditOrDeleteActionsPermitted ? [resetAction, removeAction, editAction] : [resetAction];
      }

      return isEditOrDeleteActionsPermitted ? [removeAction, resendAction] : [resendAction];
    }

    default:
      return isEditOrDeleteActionsPermitted ? [resetAction, removeAction, editAction] : [resetAction];
  }
};
