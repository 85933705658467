import { useEffect, useMemo } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { setIsLoaderDisplayed } from 'redux/loader';
import { generateRandomUUID } from 'utils/generate-random-uuid';

export const useLoader = (isLoading: boolean) => {
  const componentId = useMemo(() => generateRandomUUID(), []);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setIsLoaderDisplayed({ componentId, isLoading }));

    return () => {
      dispatch(setIsLoaderDisplayed({ componentId, isLoading: false }));
    };
  }, [componentId, dispatch, isLoading]);
};
