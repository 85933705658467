import { Stack } from '@mui/material';
import { LayoutModal } from 'elements/components';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledContainer = elementsStyled(Stack)`
  max-height: 88vh;
  overflow: auto;
  padding: 25px 24px 60px;
`;

export const StyledLayoutModal = elementsStyled(LayoutModal)`
  & h4 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
