import i18n from 'localizations';
import { UserLimitModel } from 'models/response/users/user-limit-model';
import { UserDetailsNode } from 'ui-interfaces/user-details/user-details-node';
import { UserDetailsRowType } from 'ui-enums/user-details/user-details-row-type';
import { ISubHeaderRow } from 'ui-interfaces/user-details/rows/i-sub-header-row';
import { IDefaultRow } from 'ui-interfaces/user-details/rows/i-default-row';
import { groupBy } from 'utils/group-by';
import { Identifiable } from 'ui-interfaces/user-details/identifiable';
import { formatCurrency } from 'utils/format-currency';
import { capitalizeFirstLetter } from 'utils/capitalize-first-letter';
import { generateRandomUUID } from 'utils/generate-random-uuid';
import { keyFunc } from './utils/key-func';
import { LimitsGroup } from './utils/limits-group';
import { approveLimitsEmptyState } from './utils/approve-limits-empty-state';
import { initLimitsEmptyState } from './utils/init-limits-empty-state';
import { getTitle } from './utils/get-title';
import { isAmountType } from './utils/is-amount-type';

const LIMITS = 'limits';

export const generateLimits = (
  limits: UserLimitModel[] | undefined,
  canInitLimitsBeDisplayed: boolean,
  canApproveLimitsBeDisplayed: boolean,
): UserDetailsNode[] => {
  if (!canInitLimitsBeDisplayed && !canApproveLimitsBeDisplayed) {
    return [];
  }

  if (!limits?.length) {
    const emptyStateItems = [];

    if (canApproveLimitsBeDisplayed) {
      emptyStateItems.push(...approveLimitsEmptyState);
    }

    if (canInitLimitsBeDisplayed) {
      emptyStateItems.push(...initLimitsEmptyState);
    }

    return [
      {
        id: LIMITS,
        title: i18n.t('userDetailsPage.transactionLimits'),
        items: emptyStateItems,
      },
    ];
  }

  const groupedLimits = groupBy(limits, keyFunc);

  const items = Array.from(groupedLimits.keys()).reduce((acc, key) => {
    acc.push({
      id: key,
      rowType: UserDetailsRowType.subHeader,
      title: getTitle(key),
    } as ISubHeaderRow);

    groupedLimits.get(key)!.forEach(el => {
      acc.push(
        {
          id: generateRandomUUID(),
          rowType: UserDetailsRowType.defaultNoBorder,
          label: isAmountType(el.type)
            ? i18n.t('userDetailsPage.dollarAmount')
            : i18n.t('userDetailsPage.numberOfTransactions'),
          value: isAmountType(el.type) ? `$${formatCurrency(el.maxValue)}` : el.maxValue,
        } as IDefaultRow,
        {
          id: generateRandomUUID(),
          rowType: UserDetailsRowType.short,
          label: i18n.t('userDetailsPage.per'),
          value: capitalizeFirstLetter(el.rangeType),
        } as IDefaultRow,
      );
    });

    return acc;
  }, [] as Identifiable[]);

  if (!groupedLimits.has(LimitsGroup.approve) && canApproveLimitsBeDisplayed) {
    items.unshift(...approveLimitsEmptyState);
  }

  if (!groupedLimits.has(LimitsGroup.initiate) && canInitLimitsBeDisplayed) {
    items.push(...initLimitsEmptyState);
  }

  return [
    {
      id: LIMITS,
      title: i18n.t('userDetailsPage.transactionLimits'),
      items,
    },
  ];
};
