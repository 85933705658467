import React, { ForwardedRef, forwardRef } from 'react';
import Typography from '@mui/material/Typography';
import { Gap } from 'components/shared/Gap';
import { pxToRem } from 'utils/styling-utils';
import { StyledRow, StyledSkeleton } from './styled';

type Props = {
  loading?: boolean;
  label: string;
  value: string;
  icon: React.ReactNode;
  large?: boolean;
};

const Content = forwardRef(
  ({ label, loading = false, value, icon, large = false, ...restProps }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const skeletonWidth = large ? 280 : 120;

    return (
      <StyledRow {...restProps} ref={ref}>
        {icon}
        <Gap size="_12px" isHorizontal />
        <Typography color="grey.600" variant="bodyDefaultMedium">
          {label}:
        </Typography>
        <Gap size="_8px" isHorizontal />
        {loading ? (
          <StyledSkeleton animation="pulse" variant="circular" width={pxToRem(skeletonWidth)} />
        ) : (
          <Typography color="grey.600" variant="bodyDefaultBook">
            {value}
          </Typography>
        )}
      </StyledRow>
    );
  },
);

export default Content;
