import React, { MouseEventHandler } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { EmptyIconBox, StyledIconButton } from '../styled';
import { StyledQuestionMarkIcon } from './styled';

type Props = {
  onClose?: () => void;
  onHelperIconClick?: MouseEventHandler<SVGSVGElement> | null;
};

const HeaderRightIcon: React.FC<Props> = ({ onClose, onHelperIconClick }) => {
  const { isMobile } = useIsMobile();

  if (onClose && !isMobile) {
    return onHelperIconClick ? (
      <>
        <StyledIconButton onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
        <StyledQuestionMarkIcon onClick={onHelperIconClick} isMobile={isMobile} />
      </>
    ) : (
      <StyledIconButton onClick={onClose}>
        <CloseIcon />
      </StyledIconButton>
    );
  }
  if (onHelperIconClick) {
    return <StyledQuestionMarkIcon onClick={onHelperIconClick} isMobile={isMobile} />;
  }
  return <EmptyIconBox />;
};

export default HeaderRightIcon;
