import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { Typography } from '@mui/material';
import WideDivider from 'elements/element-trade/components/WideDivider';
import { TradeFlow } from 'elements/element-trade/types';
import { Button, InlineBanner, NoteField, Spacer } from 'elements/components';
import { ErrorState } from 'elements/features/LoadingErrorState';
import { PaymentAccount } from 'elements/types';
import { isExternalAccount } from 'elements/utils';
import Layout from 'elements/element-trade/components/Layout';
import { reviewLabels } from 'elements/element-trade/utils/labelsMapping';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { AssetResponseModel } from 'models/response/assets/asset-response-model';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import { ICoinListItem } from 'ui-interfaces/elements/i-coin-list-item';
import { getDynamicAssetLabel } from 'utils/labels-mapping/get-dynamic-asset-label';
import { LabelLength } from 'ui-enums/label-length';
import { getStaticDefaultIcon } from 'utils/icons-mapping/get-static-default-icon';
import { mapAssetByNetworkIconSizeToNumber } from 'utils/multi-mapping/asset-by-network-icon-size/map-asset-by-network-icon-size-to-number';
import { AssetByNetworkIconSize } from 'ui-enums/asset-by-network-icon-size';
import { OrderDetailsNew } from '../OrderDetailsNew';

type Props = {
  amount: string;
  coinPrice: number;
  coin: AssetResponseModel | CustodialAccountBalanceModel | null;
  note: string;
  isProcessingError?: boolean;
  processingErrorMessage?: string;
  processingErrorTraceId?: string;
  isLoadingError?: boolean;
  paymentAccount: PaymentAccount;
  onNoteChange: (value: string) => void;
  onBack: () => void;
  onClose: () => void;
  onSubmit: () => void;
  onReload: () => void;
  flowType: TradeFlow;
};

const getAssetData = (
  coin: AssetResponseModel | CustodialAccountBalanceModel | null,
  flowType: TradeFlow,
): ICoinListItem => {
  const assetIconSize = AssetByNetworkIconSize['56px'];

  if (flowType === TradeFlow.Sell) {
    const cab = coin as CustodialAccountBalanceModel;
    return {
      asset: {
        imageUrl: cab.assetIconXs,
        shortName: getDynamicAssetLabel(LabelLength.short, cab.assetTicker, cab.assetName),
        longName: getDynamicAssetLabel(LabelLength.long, cab.assetTicker, cab.assetName),
        defaultAssetIcon: getStaticDefaultIcon(cab.assetTicker, assetIconSize),
      },
      size: mapAssetByNetworkIconSizeToNumber(assetIconSize),
    };
  }

  const asset = coin as AssetResponseModel;

  return {
    asset: {
      imageUrl: asset.assetIconXs,
      shortName: getDynamicAssetLabel(LabelLength.short, asset.assetTicker, asset.assetName),
      longName: getDynamicAssetLabel(LabelLength.long, asset.assetTicker, asset.assetName),
      defaultAssetIcon: getStaticDefaultIcon(asset.assetTicker, assetIconSize),
    },
    size: mapAssetByNetworkIconSizeToNumber(assetIconSize),
  };
};

const ReviewPage: React.FC<Props> = ({
  coin,
  amount,
  coinPrice,
  note,
  isProcessingError,
  processingErrorMessage,
  processingErrorTraceId,
  isLoadingError,
  paymentAccount,
  onNoteChange,
  onBack,
  onClose,
  onSubmit,
  onReload,
  flowType,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  const isNotAcceptablePaymentMethod =
    paymentAccount && !isExternalAccount(paymentAccount) && paymentAccount.balance < parseFloat(amount);

  if (isLoadingError) {
    return (
      <Layout isContentCentered title={reviewLabels[flowType].title} onBack={onBack} onClose={onClose}>
        <ErrorState onReload={onReload} />
      </Layout>
    );
  }

  return (
    <Layout
      title={reviewLabels[flowType].title}
      onBack={onBack}
      onClose={onClose}
      footerButtons={
        <Button
          disabled={(!paymentAccount || isNotAcceptablePaymentMethod) && flowType === TradeFlow.Buy}
          onClick={onSubmit}
          endIcon={<CheckIcon />}
        >
          <Typography variant="buttonLarge">{reviewLabels[flowType].buttonLabel}</Typography>
        </Button>
      }
    >
      {coin?.assetType && coin?.network && (
        <>
          <OrderDetailsNew
            amount={amount}
            coinPrice={coinPrice}
            assetData={getAssetData(coin, flowType)}
            isProcessingError={isProcessingError}
            processingErrorMessage={processingErrorMessage}
            processingErrorTraceId={processingErrorTraceId}
            flowType={flowType}
          />
          <Spacer size={24} />
          <WideDivider isMobile={isMobile} />
        </>
      )}

      <Spacer size={30} />
      {flowType === TradeFlow.Buy && isNotAcceptablePaymentMethod && (
        <>
          <InlineBanner text={t('elements.trade.reviewPage.insufficientBalanceError')} />
          <Spacer size={24} />
        </>
      )}

      <NoteField
        name="note"
        value={note}
        onChange={onNoteChange}
        label={t('elements.trade.reviewPage.internalNoteLabel')}
        multiline
      />
      <Spacer size={24} />
    </Layout>
  );
};

export default ReviewPage;
