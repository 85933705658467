import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Gap } from 'components/shared/Gap';

type Props = {
  title: string;
  subtitle: string;
};

export const AutoHeightNoResultsOverlay: React.FC<Props> = ({ title, subtitle }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100%">
      <Typography variant="h6" color="grey.500">
        {title}
      </Typography>
      <Gap size="_12px" />
      <Typography variant="bodyDefaultBook" color="grey.600">
        {subtitle}
      </Typography>
    </Box>
  );
};
