import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Typography } from '@mui/material';
import { Button, NumberInput } from 'elements/components';
import { ErrorState } from 'elements/features/LoadingErrorState';
import { PaymentMethodButton, PaymentMethodItemState } from 'elements/features/PaymentMethods';
import { CustodialAccount, PaymentAccount } from 'elements/types';
import DepositTo from 'elements/element-transfer/components/Deposit/DepositTo';
import FlowFirstPageLayout from 'elements/element-transfer/components/FlowFirstPageLayout';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { OnCloseElement } from 'elements/models/types/element-result';
import { StyledContainer } from './styled';

type Props = {
  amount: string;
  paymentAccount: PaymentAccount | null;
  custodialAccount: CustodialAccount | null;
  isGetAccountsError?: boolean;
  isContinueButtonDisabled: boolean;
  onContinue: () => void;
  onAmountInputChange: (
    event: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } },
  ) => void;
  onPaymentMethod: () => void;
  onReload: () => void;
  onClose: OnCloseElement;
  onBack: () => void;
};

const AchAmountPage: React.FC<Props> = ({
  amount,
  paymentAccount,
  custodialAccount,
  isGetAccountsError,
  isContinueButtonDisabled,
  onContinue,
  onAmountInputChange,
  onPaymentMethod,
  onReload,
  onClose,
  onBack,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  return (
    <FlowFirstPageLayout
      title={t('elements.transfer.customizableLabels.depositAchAmountPageTitle')}
      withHeader
      isContentCentered={isGetAccountsError}
      onBack={onBack}
      onClose={onClose}
      isAbove
      footerButtons={
        !isGetAccountsError && (
          <Button type="submit" disabled={isContinueButtonDisabled} onClick={onContinue}>
            <Typography variant="buttonLarge">
              {t('elements.transfer.depositAchAmountPage.continueToSummary')}
            </Typography>
            <ArrowForwardIcon />
          </Button>
        )
      }
    >
      {isGetAccountsError ? (
        <ErrorState onReload={onReload} />
      ) : (
        <StyledContainer gap="32px" isMobile={isMobile}>
          <NumberInput
            prefix="$"
            decimalScale={2}
            name="amount"
            value={amount}
            onChange={onAmountInputChange}
            placeholder="$0.00"
            label={t('elements.transfer.depositAchAmountPage.depositAmount')}
          />
          <PaymentMethodButton
            account={paymentAccount}
            onClick={onPaymentMethod}
            withBalance
            state={paymentAccount ? PaymentMethodItemState.Default : PaymentMethodItemState.Accented}
          />
          {!!custodialAccount && <DepositTo custodialAccount={custodialAccount} horizontalPaddings />}
        </StyledContainer>
      )}
    </FlowFirstPageLayout>
  );
};

export default AchAmountPage;
