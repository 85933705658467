import { Divider } from '@mui/material';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledPrimaryDivider = elementsStyled(Divider)(
  ({ theme }) =>
    css`
      border-color: ${theme.palette.primary.font};
      margin: 20px 0 16px;
    `,
);
