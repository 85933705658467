import dayjs from 'dayjs';
import i18n from 'localizations';
import { SingleValueFilter } from 'filters/single-value-filter';
import { MultiValueFilter } from 'filters/multi-value-filter';
import { DateFilter } from 'filters/date-filter';
import { Filter } from 'filters/interfaces/filter';
import { Command } from 'filters/interfaces/command';
import { FilterCommand } from 'filters/commands/filter-command';
import { SelectionMacroCommand } from 'filters/commands/selection-macro-command';
import { Operators } from 'filters/operators';
import { custodialAccountNumberFormatter } from 'filters/value-formatters/custodial-account-number-formatter';
import { AccountsValueSets } from 'filters/common-value-sets/accounts-value-sets';

const custodialAccountNameFilter = new SingleValueFilter({
  property: 'name',
  displayProperty: i18n.t('custodialAccountsPage.name'),
  operators: [Operators.contains, Operators.equals],
  operatorsDisplayValues: [i18n.t('filters.operators.contains'), i18n.t('filters.operators.equals')],
  initialOperator: Operators.contains,
  initialValue: '',
});

const custodialAccountIdFilter = new SingleValueFilter({
  property: 'id',
  displayProperty: i18n.t('custodialAccountsPage.accountId'),
  operators: [Operators.equals],
  operatorsDisplayValues: [i18n.t('filters.operators.equals')],
  initialOperator: Operators.equals,
  initialValue: '',
});

const custodialAccountNumberFilter = new SingleValueFilter({
  property: 'number',
  displayProperty: i18n.t('custodialAccountsPage.accountNumber'),
  operators: [Operators.contains, Operators.equals],
  operatorsDisplayValues: [i18n.t('filters.operators.contains'), i18n.t('filters.operators.equals')],
  initialOperator: Operators.contains,
  initialValue: '',
  valueFormatter: custodialAccountNumberFormatter,
});

const lastActivityFilter = new DateFilter({
  property: 'lastActivityUtc',
  displayProperty: i18n.t('custodialAccountsPage.lastActivity'),
  operators: [Operators.equals, Operators.greaterThan, Operators.lessThan],
  operatorsDisplayValues: [
    i18n.t('filters.operators.is'),
    i18n.t('filters.operators.isAfter'),
    i18n.t('filters.operators.isBefore'),
  ],
  initialOperator: Operators.equals,
  initialValue: dayjs().format(), // current date
});

const statusFilter = new MultiValueFilter({
  property: 'status',
  displayProperty: i18n.t('custodialAccountsPage.status'),
  operators: [Operators.in],
  operatorsDisplayValues: [i18n.t('filters.operators.equals')],
  initialOperator: Operators.in,
  values: AccountsValueSets.statusValueSet.map(el => el.value),
  displayValues: AccountsValueSets.statusValueSet.map(el => el.displayValue),
  initialValue: '',
});

// TODO: reveal when backend sends up-to-date usd balance
// const balanceFilter = new SingleValueFilter({
//   property: 'totalUsdBalance',
//   displayProperty: i18n.t('custodialAccountsPage.usdBalance'),
//   operators: [Operators.equals, Operators.greaterThan, Operators.lessThan],
//   operatorsDisplayValues: [
//     i18n.t('filters.operators.equals'),
//     i18n.t('filters.operators.greaterThan'),
//     i18n.t('filters.operators.lessThan'),
//   ],
//   initialOperator: Operators.equals,
//   initialValue: '',
// });

export const businessCustodialAccountsTabFilters: Filter[] = [
  custodialAccountNameFilter,
  custodialAccountIdFilter,
  custodialAccountNumberFilter,
  lastActivityFilter,
  statusFilter,
];

const commands: Command[] = [
  new FilterCommand(custodialAccountNameFilter),
  new FilterCommand(custodialAccountIdFilter),
  new FilterCommand(custodialAccountNumberFilter),
  new FilterCommand(lastActivityFilter),
  new FilterCommand(statusFilter),
];

export const businessCustodialAccountsTabSelectionMacroCommand = new SelectionMacroCommand(commands, 1); // slot is an index of custodialAccountIdFilter
