import React from 'react';
import { RightCellBox } from 'components/shared/detail-page/rows/shared/RightCellBox';
import { StatusCellProps } from 'ui-interfaces/statuses/status-cell-props';
import { StatusCell } from 'components/shared/Cells/StatusCell';

type Props = {
  statusCellProps: StatusCellProps;
};

export const RightCell: React.FC<Props> = ({ statusCellProps }) => {
  return (
    <RightCellBox>
      <StatusCell {...statusCellProps} />
    </RightCellBox>
  );
};
