import React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import { StyledTitleTypography } from './styled';

type Props = {
  formattedAddress: string;
  fullAddress: string;
  addressUrl: string;
};

export const ContractAddressCell: React.FC<Props> = ({ formattedAddress, fullAddress, addressUrl }) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <CommonTooltip title={fullAddress} placement="top">
        <Link target="_blank" rel="noopener noreferrer" href={addressUrl}>
          <StyledTitleTypography variant="bodyDefaultBookLink">{formattedAddress}</StyledTitleTypography>
        </Link>
      </CommonTooltip>
    </Box>
  );
};
