import React from 'react';
import { StyledHeaderBox } from 'components/shared/detail-page/header/styled';
import { Row } from 'components/shared/Flex/Row';
import Breadcrumbs, { BreadCrumbsItem } from 'components/shared/Breadcrumbs';
import { LoadingElementButton } from 'components/shared/buttons/LoadingElementButton';
import { ElementName } from 'elements/models/enums/element-name';
import { Gap } from 'components/shared/Gap';
import { LoadingTitleRow } from './LoadingTitleRow';
import { LoadingCustodialAccountHeader } from './LoadingCustodialAccountHeader';
import { LoadingNameRow } from './LoadingNameRow';
import { LoadingLabelRow } from './LoadingLabelRow';
import { StyledTopRow } from './styles';

type Props = {
  breadCrumbs: BreadCrumbsItem[];
};

export const LoadingHeader: React.FC<Props> = ({ breadCrumbs }) => {
  return (
    <StyledHeaderBox>
      <StyledTopRow>
        <Breadcrumbs items={breadCrumbs} />
        <Row>
          <LoadingElementButton name={ElementName.TRADE} isLoading={false} onClick={() => {}} isSkeletonDisplayed />
          <Gap isHorizontal size="_20px" />
          <LoadingElementButton name={ElementName.TRADE} isLoading={false} onClick={() => {}} isSkeletonDisplayed />
          <Gap isHorizontal size="_20px" />
          <LoadingElementButton name={ElementName.TRANSFER} isLoading={false} onClick={() => {}} isSkeletonDisplayed />
          <Gap isHorizontal size="_20px" />
          <LoadingElementButton name={ElementName.TRADE} isLoading={false} onClick={() => {}} isSkeletonDisplayed />
        </Row>
      </StyledTopRow>
      <Gap size="_24px" />
      <LoadingLabelRow />
      <LoadingNameRow />
      <LoadingTitleRow />
      <Gap size="_20px" />
      <LoadingCustodialAccountHeader />
    </StyledHeaderBox>
  );
};
