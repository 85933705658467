import React, { ForwardedRef, forwardRef } from 'react';
import { StatusCellProps } from 'ui-interfaces/statuses/status-cell-props';
import { StyledRow } from './styled';

export const StatusCellRow = forwardRef(
  ({ labelIcon, ...restProps }: Pick<StatusCellProps, 'labelIcon'>, ref: ForwardedRef<HTMLDivElement>) => (
    <StyledRow {...restProps} ref={ref}>
      {labelIcon}
    </StyledRow>
  ),
);
