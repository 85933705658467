import i18n from 'localizations';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';
import { PaymentApprovalModel } from 'models/response/transactions/payment-approval-model';
import { RejectedReason } from 'models/enums/transactions/reject-reason';
import { RowType } from 'ui-enums/transaction-detail/row-type';
import { TransactionDetailNode } from 'ui-interfaces/transaction-details/transaction-detail-node';
import { getApprovalSectionRowLabel } from 'utils/multi-mapping/approval-statuses/get-approval-section-row-label';
import { getTransactionRejectedReason } from 'utils/transactions/get-transaction-rejected-reason';
import { formatDateAndTimeInUTC } from 'utils/format-date';
import { ITextRow } from 'ui-interfaces/transaction-details/rows/i-text-row';
import { IApprovalStatusRow } from 'ui-interfaces/transaction-details/rows/i-approval-status-row';
import { mapApprovalStatusToStatusCellProps } from 'utils/multi-mapping/approval-statuses/map-approval-status-to-status-cell-props';

export const generateApprovalSection = (
  approvals: PaymentApprovalModel[] | null,
  transactionStatus: TransactionStatus,
  hasMt103Reference?: boolean,
): TransactionDetailNode[] => {
  if (transactionStatus.toLowerCase() === TransactionStatus.approvalNeeded.toLowerCase() && !approvals) {
    return [
      {
        id: 'approvalSection',
        blockHeaderLabel: i18n.t('transactionDetailsPage.approvalSectionLabel'),
        blockItems: [
          {
            id: 'approvalStatus',
            label: i18n.t('transactionDetailsPage.labels.approvalRowLabels.status'),
            uiElementType: RowType.approvalStatus,
            statusCellProps: mapApprovalStatusToStatusCellProps(transactionStatus),
            largeLeftCell: hasMt103Reference,
          } as IApprovalStatusRow,
          {
            id: 'performedByDisplayValue',
            label: getApprovalSectionRowLabel(transactionStatus).by,
            uiElementType: RowType.text,
            value: '--',
            largeLeftCell: hasMt103Reference,
          } as ITextRow,
          {
            id: 'performedAt',
            label: getApprovalSectionRowLabel(transactionStatus).at,
            uiElementType: RowType.text,
            value: '--',
            largeLeftCell: hasMt103Reference,
          } as ITextRow,
        ],
      },
    ];
  }
  if (!approvals) {
    return [];
  }
  return approvals.map(approval => {
    return {
      id: 'approvalSection',
      blockHeaderLabel: i18n.t('transactionDetailsPage.approvalSectionLabel'),
      blockItems: [
        {
          id: 'approvalStatus',
          label: i18n.t('transactionDetailsPage.labels.approvalRowLabels.status'),
          uiElementType: RowType.approvalStatus,
          statusCellProps: mapApprovalStatusToStatusCellProps(transactionStatus),
          largeLeftCell: hasMt103Reference,
        } as IApprovalStatusRow,
        {
          id: 'performedByDisplayValue',
          label: getApprovalSectionRowLabel(transactionStatus).by,
          uiElementType: RowType.text,
          value: approval.performedByDisplayValue,
          largeLeftCell: hasMt103Reference,
        } as ITextRow,
        {
          id: 'rejectedReason',
          label: i18n.t(`transactionDetailsPage.labels.approvalRowLabels.rejected.reason`),
          uiElementType: RowType.text,
          value: getTransactionRejectedReason(approval.rejectedReason as RejectedReason),
          largeLeftCell: hasMt103Reference,
        } as ITextRow,
        {
          id: 'performedAt',
          label: getApprovalSectionRowLabel(transactionStatus).at,
          uiElementType: RowType.text,
          value: `${formatDateAndTimeInUTC(approval.performedAt)} UTC`,
          largeLeftCell: hasMt103Reference,
        } as ITextRow,
      ].filter(i => {
        if (i.uiElementType === RowType.text) {
          return !!(i as ITextRow).value;
        }

        return true;
      }),
    };
  });
};
