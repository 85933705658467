import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CommonLayoutContainer,
  CommonLayoutContent,
  CommonLayoutHeader,
  CommonPageTitle,
} from 'components/shared/layouts/CommonLayout';
import { Gap } from 'components/shared/Gap';
import { StyledTab } from 'components/shared/Tabs';
import { TransactionsPageTabs } from 'ui-enums/tabs/transactions/transactions-page-tabs';
import { StyledTabsBox } from './styled';
import { TransactionTabsContent } from './TransactionTabsContent';
import { TransactionList } from './TransactionList';

type Props = {
  isTabsMode?: boolean;
  tab: TransactionsPageTabs;
  handleTabChange: (event: React.SyntheticEvent, newTab: TransactionsPageTabs) => void;
};

const TapiTransactionsPage: React.FC<Props> = ({ isTabsMode = false, tab, handleTabChange }) => {
  const { t } = useTranslation();

  return (
    <CommonLayoutContainer>
      <CommonLayoutHeader>
        <Gap size="_40px" />
        <CommonPageTitle>{t('transactionsPage.title')}</CommonPageTitle>
        <Gap size="_32px" />
        {isTabsMode && (
          <StyledTabsBox value={tab} onChange={handleTabChange}>
            <StyledTab label={t('transactionsPage.tabs.all')} value={TransactionsPageTabs.all} />
            <StyledTab label={t('transactionsPage.tabs.approvalNeeded')} value={TransactionsPageTabs.approvalNeeded} />
            <StyledTab label={t('transactionsPage.tabs.rejected')} value={TransactionsPageTabs.rejected} />
          </StyledTabsBox>
        )}
      </CommonLayoutHeader>
      <CommonLayoutContent>
        {isTabsMode ? <TransactionTabsContent currentTab={tab} /> : <TransactionList />}
      </CommonLayoutContent>
    </CommonLayoutContainer>
  );
};

export default TapiTransactionsPage;
