import React, { useCallback, useEffect, useState } from 'react';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { LoadingPage } from 'elements/features/LoadingErrorState';
import ContentBlock from 'elements/element-transfer/components/Deposit/CryptoAddressesPage';
import { setPage, useElement } from 'elements/element-transfer/contexts/Element';
import { OnCloseElement } from 'elements/models/types/element-result';
import { AssetResponseModel } from 'models/response/assets/asset-response-model';
import { AssetsApiParams, useLazyGetAssetsQuery } from 'redux/api/api-assets';
import { OperationTypeModel } from 'models/enums/assets/operation-type-model';
import { AssetType } from 'ui-enums/assets/asset-type';

const CryptoAddressesPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { dispatch, state } = useElement();

  const onBack = () => {
    dispatch(setPage(Paths.Transfer));
  };

  // assets - start
  const [getAssetsTrigger, assetsResult] = useLazyGetAssetsQuery();

  const fetchAssets = useCallback(
    () => getAssetsTrigger({ operation: OperationTypeModel.instruction } as AssetsApiParams),
    [getAssetsTrigger],
  );

  useEffect(() => {
    fetchAssets();
  }, [fetchAssets]);

  const [assets, setAssets] = useState<AssetResponseModel[]>([]);

  useEffect(() => {
    if (assetsResult.isSuccess && assetsResult.data) {
      setAssets(assetsResult.data.data.filter(a => a.assetType === AssetType.crypto));
    }
  }, [assetsResult.data, assetsResult.isSuccess, dispatch]);

  useEffect(() => {
    if (assetsResult.isError) {
      const withBackNavigation = state.transferOptionsList.length > 1;

      if (withBackNavigation) {
        dispatch(setPage(Paths.Transfer));
      }
    }
  }, [assetsResult.isError, dispatch, state.transferOptionsList.length]);
  // assets - end

  const requestCoinList = () => {
    fetchAssets();
  };

  const onReload = () => {
    requestCoinList();
  };

  return assetsResult.isFetching || assetsResult.isUninitialized ? (
    <LoadingPage onClose={onClose} />
  ) : (
    <ContentBlock
      assets={assets}
      isRequestError={assetsResult.isError}
      onClose={onClose}
      onBack={onBack}
      onReload={onReload}
    />
  );
};

export default CryptoAddressesPage;
