import { ElementResult } from 'elements/models/types/element-result';
import { ElementsJwtTypeModel } from 'models/enums/identities/elements-jwt-type-model';

// elements - start
const onElementDone = ({
  closeJWTInitiator,
}: {
  elementType: ElementsJwtTypeModel;
  elementResult?: ElementResult;
  closeJWTInitiator?: () => void;
}) => {
  closeJWTInitiator?.();
};

export const onTradeElementDone =
  (closeJWTInitiator?: () => void) => (elementType: ElementsJwtTypeModel, elementResult?: ElementResult) => {
    onElementDone({ elementResult, elementType, closeJWTInitiator });
  };

export const onTransferElementDone =
  (closeJWTInitiator?: () => void) => (elementType: ElementsJwtTypeModel, elementResult?: ElementResult) => {
    onElementDone({ elementResult, elementType, closeJWTInitiator });
  };
// elements - end
