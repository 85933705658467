import { styled, tooltipClasses } from '@mui/material';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import { pxToRem } from 'utils/styling-utils';
import TransparentButton from './TransparentButton';

export const StyledTransparentButton = styled(TransparentButton)(({ theme }) => ({
  ...theme.typography.buttonSmall,
  width: '100%',
}));

export const StyledCommonTooltip = styled(CommonTooltip)(({ theme }) => ({
  [`& .${tooltipClasses.tooltipPlacementRight}`]: {
    marginLeft: `${pxToRem(theme.gaps._24px)} !important`,
  },
}));
