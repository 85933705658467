import { Box, IconButton } from '@mui/material';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledContainer = elementsStyled(Box)`
  display: flex;
  flex-direction: column;
  max-height: 88vh;
  overflow: auto;
`;

export const StyledHeadline = elementsStyled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 16px 20px 0;
`;

export const StyledScrollableContainer = elementsStyled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 20px;
`;

export const StyledFooterContainer = elementsStyled(Box)(
  ({ theme }) => css`
    padding: 16px 20px 60px;
    box-shadow: 0px -4px 16px ${theme.palette.background.custom || theme.palette.background.default};
  `,
);

export const StyledCloseButton = elementsStyled(IconButton)(
  ({ theme }) => css`
    background: ${theme.palette.tertiary.mainTransparent40};
    color: ${theme.palette.secondary.font};

    &:hover {
      background: ${theme.palette.tertiary.mainTransparent40};
    }
  `,
);
