import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import i18n from 'localizations';
import { GridCellExpand } from 'components/shared/Cells/GridCellExpand';
import { Balance } from 'ui-interfaces/custodial-accounts-details/custodial-account-balance';
import { AccountBalanceHeaderCell } from './AccountBalanceHeaderCell';
import { AccountBalanceNameCell } from './AccountBalanceNameCell';

export const columnsAccountBalances: GridColDef[] = [
  {
    field: 'balanceAssetName',
    headerName: i18n.t('custodialAccountDetailsPage.accountBalances.balanceAssetName'),
    renderCell: (params: GridRenderCellParams) => {
      const { accountBalanceNameCellProps } = params.row as Balance;
      return <AccountBalanceNameCell data={accountBalanceNameCellProps} />;
    },
    flex: 2.3,
    headerAlign: 'left',
    align: 'left',
  },
  {
    field: 'availableBalance',
    renderHeader: () => (
      <AccountBalanceHeaderCell
        tooltipMessage={i18n.t('custodialAccountDetailsPage.accountBalances.availableBalanceTooltipMessage')}
        columnName={i18n.t('custodialAccountDetailsPage.accountBalances.availableBalance')}
      />
    ),
    renderCell: (params: GridRenderCellParams) => (
      <GridCellExpand value={params.value} width={params.colDef.computedWidth} />
    ),
    flex: 1,
    headerAlign: 'right',
    align: 'right',
  },
  {
    field: 'lockedBalance',
    renderHeader: () => (
      <AccountBalanceHeaderCell
        tooltipMessage={i18n.t('custodialAccountDetailsPage.accountBalances.lockedBalanceTooltipMessage')}
        columnName={i18n.t('custodialAccountDetailsPage.accountBalances.lockedBalance')}
      />
    ),
    renderCell: (params: GridRenderCellParams) => (
      <GridCellExpand value={params.value} width={params.colDef.computedWidth} />
    ),
    flex: 1,
    headerAlign: 'right',
    align: 'right',
  },
  {
    field: 'pendingBalance',
    renderHeader: () => (
      <AccountBalanceHeaderCell
        tooltipMessage={i18n.t('custodialAccountDetailsPage.accountBalances.pendingBalanceTooltipMessage')}
        columnName={i18n.t('custodialAccountDetailsPage.accountBalances.pendingBalance')}
      />
    ),
    renderCell: (params: GridRenderCellParams) => (
      <GridCellExpand value={params.value} width={params.colDef.computedWidth} />
    ),
    flex: 0.9,
    headerAlign: 'right',
    align: 'right',
  },
  {
    field: 'totalBalance',
    renderHeader: () => (
      <AccountBalanceHeaderCell
        tooltipMessage={i18n.t('custodialAccountDetailsPage.accountBalances.totalBalanceTooltipMessage')}
        columnName={i18n.t('custodialAccountDetailsPage.accountBalances.totalBalance')}
      />
    ),
    renderCell: (params: GridRenderCellParams) => (
      <GridCellExpand value={params.value} width={params.colDef.computedWidth} />
    ),
    flex: 1,
    headerAlign: 'right',
    align: 'right',
  },
];
