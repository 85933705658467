import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { SideMenu } from 'ui-interfaces/side-menu/side-menu';

export class SideMenuBaseViewModel implements Pick<SideMenu, 'footerTitle' | 'footerSubTitle'> {
  readonly footerTitle: string = '';

  readonly footerSubTitle: string = '';

  constructor(user?: ShortUserResponseModel) {
    if (user) {
      this.footerTitle = `${user.firstName} ${user.lastName}`;
      this.footerSubTitle = user.email;
    }
  }
}
