import { useMemo } from 'react';
import { useLazyGetPersonalIdentityDocumentsQuery } from 'redux/api/api-personal-identities';
import { useLazyGetBusinessIdentityDocumentsQuery } from 'redux/api/api-business-identities';
import { useTransformDocuments } from 'hooks/use-transform-documents';
import { IdentityType } from 'models/enums/identities/identity-type';
import { DocumentResponseModel } from 'models/response/documents/document-response-model';
import { RtkLazyQueryHookReturnValue } from 'redux/api/types';
import { Document } from 'ui-interfaces/identity-details/document-list/document';

interface FetchDocumentsList {
  fetchIdentityDocumentsTrigger: Function;
  documentsResult: RtkLazyQueryHookReturnValue<DocumentResponseModel>;
  documents: Document[];
}

export const useFetchDocumentsList = (type: IdentityType): FetchDocumentsList => {
  const [fetchPersonalDocumentsTrigger, personalDocumentsResult] = useLazyGetPersonalIdentityDocumentsQuery();
  const [fetchBusinessDocumentsTrigger, businessDocumentsResult] = useLazyGetBusinessIdentityDocumentsQuery();

  const data = useMemo(() => {
    return type === IdentityType.personal ? personalDocumentsResult.data : businessDocumentsResult.data;
  }, [businessDocumentsResult.data, personalDocumentsResult.data, type]);

  const documents = useTransformDocuments(data);

  if (type === IdentityType.personal) {
    return {
      fetchIdentityDocumentsTrigger: fetchPersonalDocumentsTrigger,
      documentsResult: personalDocumentsResult as RtkLazyQueryHookReturnValue<DocumentResponseModel>,
      documents,
    };
  }
  return {
    fetchIdentityDocumentsTrigger: fetchBusinessDocumentsTrigger,
    documentsResult: businessDocumentsResult as RtkLazyQueryHookReturnValue<DocumentResponseModel>,
    documents,
  };
};
