import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Button, Spacer } from 'elements/components';

type Props = {
  spaceBetween?: boolean;
  withAddPaymentMethod: boolean;
  onAddPaymentMethod: () => void;
  onDoneClick: () => void;
};

const FooterButtons: React.FC<Props> = ({ spaceBetween, withAddPaymentMethod, onAddPaymentMethod, onDoneClick }) => {
  const { t } = useTranslation();

  return (
    <>
      {withAddPaymentMethod && (
        <>
          <Button buttonType="secondary" onClick={onAddPaymentMethod}>
            <Typography variant="buttonLarge">{t('elements.features.paymentMethods.addPayment')}</Typography>
          </Button>
          {spaceBetween && <Spacer size={16} />}
        </>
      )}
      <Button type="submit" onClick={onDoneClick}>
        <Typography variant="buttonLarge"> {t('elements.features.paymentMethods.done')}</Typography>
      </Button>
    </>
  );
};

export default FooterButtons;
