import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { Gap } from 'components/shared/Gap';
import { TokenImage } from './TokenImage';
import { Title } from './Title';
import { StyledBox } from './styled';

type Props = {
  imageUrl?: string;
  title?: string;
  fileType?: string;
  width: number;
};

export const NameCell: React.FC<Props> = ({ imageUrl, title, fileType, width }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <StyledBox>
        <TokenImage fileType={fileType} url={imageUrl} />
      </StyledBox>

      <Gap size="_12px" isHorizontal />
      <Title title={title ?? t('walletDetailsPage.tokens.emptyFields.name')} width={width} />
    </Box>
  );
};
