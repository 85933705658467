export enum TransactionStatus {
  new = 'new',
  processing = 'processing',
  completed = 'completed',
  failed = 'failed',
  pending = 'pending',
  aborted = 'aborted',
  inProgress = 'inProgress',
  approvalNeeded = 'approvalNeeded',
  rejected = 'rejected',
  approved = 'approved',
  cancelled = 'cancelled',
}
