export enum CardTypes {
  Visa = 'Visa',
  Mastercard = 'Mastercard',
  Diners = 'Diners',
  Discover = 'Discover',
  JCB = 'Jcb',
  AmericanExpress = 'American Express',
  Maestro = 'Maestro',
  Other = 'Other',
}
