import React from 'react';
import { Typography } from '@mui/material';
import { Stack } from 'elements/components';
import {
  StyledContainer,
  StyledCreateOutlinedIcon,
  StyledMainContent,
  StyledTextContainer,
  StyledTruncatedTypography,
} from './styled';
import { PaymentMethodItemState } from './types';

type Props = {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  postfix?: string;
  onClick?: () => void;
  state?: PaymentMethodItemState;
  reverse?: boolean;
};

const PaymentMethodItem: React.FC<Props> = ({ icon, title, subtitle, postfix, onClick, state, reverse }) => (
  <StyledContainer onClick={state !== PaymentMethodItemState.NotEditable ? onClick : undefined} state={state}>
    <StyledMainContent>
      {icon}
      <StyledTextContainer reverse={reverse}>
        <Typography variant="captionMedium" color="secondary.font">
          {title}
        </Typography>
        <Stack direction="row" fullWidth gap={0.5}>
          <StyledTruncatedTypography variant="bodyDefaultMedium" color="primary.font">
            {subtitle}
          </StyledTruncatedTypography>
          <Typography variant="bodyDefaultMedium" color="primary.font">
            {postfix}
          </Typography>
        </Stack>
      </StyledTextContainer>
    </StyledMainContent>
    {state !== PaymentMethodItemState.NotEditable && <StyledCreateOutlinedIcon state={state} />}
  </StyledContainer>
);
export default PaymentMethodItem;
