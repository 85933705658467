import { Accordion, AccordionSummary, styled } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '&:before': {
    height: 0,
  },
  '&:last-of-type': {
    borderBottomLeftRadius: pxToRem(theme.gaps._8px),
    borderBottomRightRadius: pxToRem(theme.gaps._8px),
  },
  alignSelf: 'center',
  backgroundColor: theme.palette.background.paper,
  width: pxToRem(1056),
  boxShadow: theme.customShadows.grey._2dp,
  borderRadius: pxToRem(theme.gaps._8px),
  paddingInline: pxToRem(theme.gaps._24px),
  paddingBlock: pxToRem(theme.gaps._32px),
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,
  maxHeight: theme.gaps._24px,
  minHeight: 0,
}));
