import { styled, css, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { pxToRem } from 'utils/styling-utils';

export const StyledIconBox = styled(Box)(
  ({ theme }) => css`
    width: ${pxToRem(theme.gaps._40px)};
    height: ${pxToRem(theme.gaps._40px)};
    border-radius: ${pxToRem(theme.gaps._20px)};
    background-color: ${alpha(theme.palette.background.paper, 0.1)};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${pxToRem(theme.gaps._12px)};
  `,
);
