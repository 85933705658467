import React from 'react';

export enum BannerType {
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
}

export type InlineBannerProps = {
  icon?: React.ReactNode;
  withIcon?: boolean;
  type?: BannerType;
  text?: string | React.ReactElement;
  text2?: string;
  buttonTitle?: string;
  onClick?: () => void;
  linkHref?: string | null;
  className?: string;
  errorTraceId?: string;
};
