import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/api/api-users';
import { getInitialRoute } from 'utils/navigation/get-initial-route';
import { PATHS } from 'navigation/constants';

export const Index: React.FC = () => {
  const { data, isSuccess } = useSelector(getUser.select(undefined));

  const [route, setRoute] = useState(PATHS.INDEX);

  useEffect(() => {
    if (isSuccess && data) {
      const initialRoute = getInitialRoute(data.organization.allowedSubApis!);
      setRoute(initialRoute);
    }
  }, [data, isSuccess]);

  if (isSuccess) {
    return <Navigate to={route} replace />;
  }

  return null;
};
