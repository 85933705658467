import React from 'react';
import { Text as _Text } from '@react-pdf/renderer';
import { styles } from './styles';
import { LeftCellBox } from '../LeftCellBox';

const Text: any = _Text;

type Props = {
  value: string;
};

export const LeftCell: React.FC<Props> = ({ value }) => {
  return (
    <LeftCellBox>
      <Text style={styles.text}>{value}</Text>
    </LeftCellBox>
  );
};
