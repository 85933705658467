import React from 'react';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import { TooltipTypography } from './styled';

const MAX_ASSETS_TOOLTIP = 10;

type Props = {
  messageArr: string[];
  children: React.ReactElement;
};

export const WalletMultiAssetTooltip: React.FC<Props> = ({ messageArr, children }) => {
  const renderTooltip = () =>
    messageArr.reduce<React.ReactNode[]>((acc, m, indx) => {
      if (indx + 1 > MAX_ASSETS_TOOLTIP) {
        return acc;
      }
      acc.push(
        <TooltipTypography key={m} color="background.paper" variant="bodySmallBook">
          {m}
        </TooltipTypography>,
      );
      return acc;
    }, []);
  return (
    <CommonTooltip
      placement="top"
      title={
        <>
          {renderTooltip()}
          {messageArr.length > MAX_ASSETS_TOOLTIP && (
            <TooltipTypography color="background.paper" variant="bodySmallBook">
              ...
            </TooltipTypography>
          )}
        </>
      }
    >
      {children}
    </CommonTooltip>
  );
};
