import React from 'react';
import { TextFieldProps } from '@mui/material';
import { PhoneInput } from 'components/shared/inputs/PhoneInput';
import { BaseTextField } from 'components/shared/inputs/BaseTextField';

type Props = Omit<TextFieldProps, 'variant' | 'hidden' | 'label'> & {
  name: string;
  label: string;
};

export const PhoneField: React.FC<Props> = props => {
  return (
    <BaseTextField {...props} InputProps={{ inputComponent: PhoneInput as any }} InputLabelProps={{ shrink: true }} />
  );
};
