import { mainApi } from 'redux/api/main-api';
import { API_ROUTES } from 'redux/api/api-routes';
import { CollectionResponse, DocumentsResponse, Paginated, VerifyIdentityApiParams } from 'redux/api/types';
import { DEFAULT_PAGE_SIZE, FIRST_PAGE, GET_PERSONAL_IDENTITY_TAG } from 'redux/api/constants';
import { PersonalIdentityDetailsResponseModel } from 'models/response/identities/personal/personal-identity-details-response-model';
import { PersonalIdentityResponseModel } from 'models/response/identities/personal/personal-identity-response-model';
import { FilterResult } from 'filters/interfaces/filter-result';
import { getRouteWithParams } from 'utils/get-route-with-params';

export interface IdentityApiParams extends Paginated {
  filterResult?: FilterResult;
}

const personalIdentitiesApi = mainApi.injectEndpoints({
  endpoints: build => ({
    getPersonalIdentities: build.query<CollectionResponse<PersonalIdentityResponseModel>, IdentityApiParams>({
      query: ({ page = FIRST_PAGE, pageSize = DEFAULT_PAGE_SIZE, filterResult }) => {
        if (filterResult) {
          const { property, operator, value } = filterResult as FilterResult;

          return {
            url: API_ROUTES.private.PERSONAL_IDENTITIES,
            params: { page, pageSize, [`filter[${property} ${operator}]`]: value.trim() },
          };
        }
        return {
          url: API_ROUTES.private.PERSONAL_IDENTITIES,
          params: { page, pageSize },
        };
      },
    }),
    getPersonalIdentityDetailsById: build.query<PersonalIdentityDetailsResponseModel, string>({
      query: (identityId: string) => ({
        url: getRouteWithParams(API_ROUTES.private.PERSONAL_IDENTITY, { identityId }),
      }),
      providesTags: [GET_PERSONAL_IDENTITY_TAG],
    }),
    getPersonalIdentityDocuments: build.query<DocumentsResponse, string>({
      query: (identityId: string) => ({
        url: getRouteWithParams(API_ROUTES.private.PERSONAL_IDENTITY_DOCUMENTS, { identityId }),
      }),
    }),
    verifyPersonalIdentity: build.mutation<undefined, VerifyIdentityApiParams>({
      query: ({ identityId, identityVerificationModel }) => ({
        url: getRouteWithParams(API_ROUTES.private.PERSONAL_IDENTITY_VERIFY, { identityId }),
        method: 'POST',
        body: identityVerificationModel,
      }),
      invalidatesTags: [GET_PERSONAL_IDENTITY_TAG],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetPersonalIdentitiesQuery,
  useGetPersonalIdentityDetailsByIdQuery,
  useLazyGetPersonalIdentityDetailsByIdQuery,
  useLazyGetPersonalIdentityDocumentsQuery,
  useVerifyPersonalIdentityMutation,
} = personalIdentitiesApi;
