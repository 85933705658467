import i18n from 'localizations';
import { UserLimitModel } from 'models/response/users/user-limit-model';
import { RangeType } from 'models/enums/user/range-type';

export const performLimitsValidation = (limits: UserLimitModel[]): string | undefined => {
  // check for duplicates - start
  if (limits.filter(i => i.rangeType === RangeType.transaction).length > 1) {
    return i18n.t('validation.multipleTransactionLimits');
  }

  if (limits.filter(i => i.rangeType === RangeType.day).length > 1) {
    return i18n.t('validation.multipleDailyLimits');
  }

  if (limits.filter(i => i.rangeType === RangeType.week).length > 1) {
    return i18n.t('validation.multipleWeeklyLimits');
  }

  if (limits.filter(i => i.rangeType === RangeType.month).length > 1) {
    return i18n.t('validation.multipleMonthlyLimits');
  }
  // check for duplicates - end

  const sortedByMaxValueArray = limits.slice().sort((a, b) => a.maxValue - b.maxValue);

  const hashMap: Record<string, number> = {};

  sortedByMaxValueArray.forEach(el => {
    // remember first value of each existing rangeType (which is the smallest)
    if (!(el.rangeType in hashMap)) {
      hashMap[el.rangeType] = el.maxValue;
    }
  });

  // check transactionLimitExceedsDailyWeeklyMonthly - start
  if (RangeType.transaction in hashMap && RangeType.day in hashMap) {
    if (hashMap[RangeType.transaction] > hashMap[RangeType.day]) {
      return i18n.t('validation.transactionLimitExceedsDailyWeeklyMonthly');
    }
  }

  if (RangeType.transaction in hashMap && RangeType.week in hashMap) {
    if (hashMap[RangeType.transaction] > hashMap[RangeType.week]) {
      return i18n.t('validation.transactionLimitExceedsDailyWeeklyMonthly');
    }
  }

  if (RangeType.transaction in hashMap && RangeType.month in hashMap) {
    if (hashMap[RangeType.transaction] > hashMap[RangeType.month]) {
      return i18n.t('validation.transactionLimitExceedsDailyWeeklyMonthly');
    }
  }
  // check transactionLimitExceedsDailyWeeklyMonthly - end

  // check dailyLimitExceedsWeeklyMonthly - start
  if (RangeType.day in hashMap && RangeType.week in hashMap) {
    if (hashMap[RangeType.day] > hashMap[RangeType.week]) {
      return i18n.t('validation.dailyLimitExceedsWeeklyMonthly');
    }
  }

  // check dailyLimitExceedsWeeklyMonthly
  if (RangeType.day in hashMap && RangeType.month in hashMap) {
    if (hashMap[RangeType.day] > hashMap[RangeType.month]) {
      return i18n.t('validation.dailyLimitExceedsWeeklyMonthly');
    }
  }
  // check dailyLimitExceedsWeeklyMonthly - end

  // check weeklyLimitExceedsMonthly - start
  if (RangeType.week in hashMap && RangeType.month in hashMap) {
    if (hashMap[RangeType.week] > hashMap[RangeType.month]) {
      return i18n.t('validation.weeklyLimitExceedsMonthly');
    }
  }
  // check weeklyLimitExceedsMonthly - end

  return undefined;
};
