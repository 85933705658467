import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { SideMenu } from 'ui-interfaces/side-menu/side-menu';
import { SideMenuIdentitiesTapiWapiViewModel } from 'view-models/side-menu/side-menu-identities-tapi-wapi-vm';
import { SideMenuIdentitiesTapiViewModel } from 'view-models/side-menu/side-menu-identities-tapi-vm';
import { SideMenuIdentitiesWapiViewModel } from 'view-models/side-menu/side-menu-identities-wapi-vm';
import { SideMenuWapiViewModel } from 'view-models/side-menu/side-menu-wapi-vm';
import { SideMenuNotImplementedViewModel } from 'view-models/side-menu/side-menu-not-implemented-vm';
import { checkIfArraysEqual } from 'utils/check-if-arrays-equal';
import { SubApiCases } from 'navigation/sub-api-cases';
import { featureFlags } from 'utils/feature-flags';

export const createSideMenuViewModel = (user?: ShortUserResponseModel): SideMenu => {
  if (user) {
    if (!featureFlags.isWalletEnabled) {
      return new SideMenuIdentitiesTapiViewModel(user);
    }

    const allowedSubApis = user.organization?.allowedSubApis ?? [];

    switch (true) {
      case checkIfArraysEqual(SubApiCases.trustWalletCompliance, allowedSubApis):
      case checkIfArraysEqual(SubApiCases.default, allowedSubApis):
        return new SideMenuIdentitiesTapiWapiViewModel(user);

      case checkIfArraysEqual(SubApiCases.trustCompliance, allowedSubApis):
        return new SideMenuIdentitiesTapiViewModel(user);

      case checkIfArraysEqual(SubApiCases.walletCompliance, allowedSubApis):
        return new SideMenuIdentitiesWapiViewModel(user);

      case checkIfArraysEqual(SubApiCases.wallet, allowedSubApis):
        return new SideMenuWapiViewModel(user);

      default:
        return new SideMenuNotImplementedViewModel(user);
    }
  }

  return new SideMenuNotImplementedViewModel();
};
