import React from 'react';
import { Modal, Stack } from 'elements/components';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import FooterButtons from '../FooterButtons';
import { AccountVerificationProps } from '../types';
import { StyledContainer, StyledLayoutModal } from './styled';

type Props = {
  children: React.ReactElement;
  pageTitle: string;
} & Omit<AccountVerificationProps, 'title' | 'subtitle'>;

const ResponsiveVerificationLayout: React.FC<Props> = ({
  pageTitle,
  children,
  onBack,
  onLetsGo,
  contactUsHref,
  isTertiaryButtonShown,
}) => {
  const { isMobile } = useIsMobile();

  return (
    <div>
      {isMobile ? (
        <Modal open onClose={onBack} alignBottom>
          <StyledContainer>
            {children}
            <Stack fullWidth gap="16px">
              <FooterButtons
                onBack={onBack}
                onLetsGo={onLetsGo}
                contactUsHref={contactUsHref}
                isTertiaryButtonShown={isTertiaryButtonShown}
              />
            </Stack>
          </StyledContainer>
        </Modal>
      ) : (
        <StyledLayoutModal
          withHeader
          withFooter
          isAbove
          title={pageTitle}
          onBack={onBack}
          isContentCentered={!isMobile}
          subFooter={
            <Stack fullWidth gap="16px">
              <FooterButtons
                onBack={onBack}
                onLetsGo={onLetsGo}
                contactUsHref={contactUsHref}
                isTertiaryButtonShown={isTertiaryButtonShown}
              />
            </Stack>
          }
        >
          {children}
        </StyledLayoutModal>
      )}
    </div>
  );
};

export default ResponsiveVerificationLayout;
