import { Popover } from '@mui/material';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledPopover = elementsStyled(Popover)(
  ({ theme }) => css`
    top: -20px;
    left: 0;

    & .MuiPaper-root {
      border: 1px solid ${theme.palette.tertiary.main};
      box-shadow: 0 4px 16px ${theme.palette.tertiary.main};
      border-radius: ${theme.borderRadius};
    }
  `,
);
