import { UserRoleTemplateResponseModel } from 'models/response/users/user-role-template-response-model';

export const getSelectedPermissionsIndexes = (
  userRoleTemplateResponseModel: UserRoleTemplateResponseModel,
): Set<number> => {
  const set = new Set<number>();

  userRoleTemplateResponseModel.permissions.forEach((p, index) => {
    if (p.value) {
      set.add(index);
    }
  });

  return set;
};
