import i18n from 'localizations';
import { IdentityDetailsNode } from 'ui-interfaces/identity-details/identity-details-node';
import { IdentityDetailRowType } from 'ui-enums/identities/identity-detail-row-type';

export const loadingNodes: IdentityDetailsNode[] = [
  {
    id: '01',
    title: i18n.t('identityDetailsPage.identityDetailsTitle'),
    items: [
      {
        id: '1',
        rowType: IdentityDetailRowType.loading,
      },
      {
        id: '2',
        rowType: IdentityDetailRowType.loading,
      },
      {
        id: '3',
        rowType: IdentityDetailRowType.loading,
      },
      {
        id: '4',
        rowType: IdentityDetailRowType.loading,
      },
      {
        id: '5',
        rowType: IdentityDetailRowType.loading,
      },
      {
        id: '6',
        rowType: IdentityDetailRowType.loading,
      },
      {
        id: '7',
        rowType: IdentityDetailRowType.loading,
      },
      {
        id: '8',
        rowType: IdentityDetailRowType.loading,
      },
      {
        id: '9',
        rowType: IdentityDetailRowType.loading,
      },
    ],
  },
];
