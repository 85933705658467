import i18n from 'localizations';
import { CustomAssetBalanceResponseModel } from 'models/response/custodial-accounts/custom-asset-balance-response-model';
import { Currency } from 'ui-enums/response/transactions/currency';
import { formatCurrency } from 'utils/format-currency';

export const formatAssetPrice = (balance: CustomAssetBalanceResponseModel): string => {
  if (balance.assetPrice === '') {
    return i18n.t('custodialAccountDetailsPage.customAssetBalance.emptyFields.price');
  }

  if (balance?.assetPriceCurrency?.toLowerCase() === Currency.usd.toLowerCase()) {
    return `${formatCurrency(+balance.assetPrice)} ${i18n.t(`currency.short.${balance.assetPriceCurrency}`)}`;
  }

  return `${balance.assetPrice} ${i18n.t(`currency.short.${balance.assetPriceCurrency}`)}`;
};
