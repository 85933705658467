import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { CompoundLabel } from 'ui-interfaces/row-interfaces/transactions';
import { formatCurrency } from 'utils/format-currency';
import { LabelLength } from 'ui-enums/label-length';
import { getSignOfTransaction } from 'utils/transactions/get-sign-of-transaction';
import { getDynamicAssetLabel } from 'utils/labels-mapping/get-dynamic-asset-label';

export const getAmountLabelCryptoBuy = (transaction: TransactionResponseModel): CompoundLabel | string => {
  if (transaction.cryptoAmount) {
    return `${getSignOfTransaction(transaction)}${transaction.cryptoAmount} ${getDynamicAssetLabel(
      LabelLength.short,
      transaction.assetTicker,
      transaction.assetName,
    )}`;
  }

  return {
    start: `-- ${getDynamicAssetLabel(LabelLength.short, transaction.assetTicker, transaction.assetName)}`,
    end: `| $${formatCurrency(transaction.amount ?? 0)}`,
  } as CompoundLabel;
};
