import React from 'react';
import { styled } from '@mui/material/styles';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import { pxToRem } from 'utils/styling-utils';

export const StyledTabs = styled((props: TabsProps) => (
  <Tabs textColor="secondary" indicatorColor="secondary" {...props} />
))(({ theme }) => ({
  paddingInline: pxToRem(theme.gaps._24px),
}));

export const StyledTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  ...theme.typography.subtitleMedium,
  marginRight: pxToRem(theme.gaps._20px),
  color: theme.palette.grey['500'],
  padding: 0,
  paddingBottom: pxToRem(theme.gaps._16px),
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));
