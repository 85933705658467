import i18n from 'localizations';
import { formatCurrency } from 'utils/format-currency';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';
import { formatDate, formatDateAndTimeInUTC } from 'utils/format-date';
import { TransactionDetailResponseModel } from 'models/response/transactions/transaction-detail-response-model';
import { StatusCellProps } from 'ui-interfaces/statuses/status-cell-props';
import { mapTransactionStatusToStatusCellProps } from 'utils/multi-mapping/statuses/map-transaction-status-to-status-cell-props';
import { TransactionDetails } from 'ui-interfaces/transaction-details/transaction-details';
import { SectionIdentifiable } from 'ui-interfaces/transaction-details/sections/section-identifiable';
import { PdfNode } from 'ui-interfaces/transaction-details/pdf/pdf-node';
import { getStandardAmountFormatting } from 'utils/transactions/get-standard-amount-formatting';
import { getOverviewSectionBasicProps } from 'utils/transactions/detail/get-overview-section-basic-props';
import { RowType } from 'ui-enums/transaction-detail/row-type';
import { ITextRow } from 'ui-interfaces/transaction-details/rows/i-text-row';
import { IPaymentTypeRow } from 'ui-interfaces/transaction-details/rows/i-payment-type-row';
import { IPlainSection } from 'ui-interfaces/transaction-details/sections/i-plain-section';
import { PdfRowType } from 'ui-enums/pdf/pdf-row-type';
import { IPlainRow } from 'ui-interfaces/transaction-details/pdf/rows/i-plain-row';
import { getTransactionStatusLabel } from 'utils/labels-mapping/get-transaction-status-label';
import { IPdfNoLabelRow } from 'ui-interfaces/transaction-details/pdf/rows/i-pdf-no-label-row';
import { capitalizeFirstLetter } from 'utils/capitalize-first-letter';
import { Valued } from 'ui-interfaces/shared/valued';
import { NetworkChain } from 'ui-enums/response/network-chain';
import { mapAssetByNetworkIconSizeToNumber } from 'utils/multi-mapping/asset-by-network-icon-size/map-asset-by-network-icon-size-to-number';
import { AssetByNetworkIconSize } from 'ui-enums/asset-by-network-icon-size';
import { getStaticDefaultIconForTransaction } from 'utils/icons-mapping/transactions/get-static-default-icon-for-transaction';

class TransactionDetailsBaseViewModel implements TransactionDetails {
  // header - start
  headerLabel = i18n.t('transactionDetailsPage.transactionHeaderLabel');

  amountForActionDialog: string;

  sourceOrganizationName?: string;

  tooltipMessage?: string;

  statusCellProps: StatusCellProps = mapTransactionStatusToStatusCellProps(TransactionStatus.completed);

  createdAt: string;

  paymentId: string;

  isTransactionInitiatedByTheCurrentUser = false;

  areActionsDisplayed = false;
  // header - end

  sections: SectionIdentifiable[] = [];

  pdfNodes: PdfNode[] = [];

  protected readonly networkChain: NetworkChain | undefined;

  protected readonly assetIconSize = AssetByNetworkIconSize['36px'];

  constructor(transaction: TransactionDetailResponseModel) {
    const transactionStatus = transaction.status as TransactionStatus;
    this.networkChain = transaction.network ? (transaction.network as NetworkChain) : undefined;
    // header - start
    this.amountForActionDialog = `$${formatCurrency(transaction.amount ?? 0)}`;
    this.sourceOrganizationName = transaction.sourceOrganizationName;
    this.statusCellProps = mapTransactionStatusToStatusCellProps(transactionStatus);
    this.createdAt = formatDate(transaction.date);
    this.paymentId = transaction.paymentId;
    // header - end
    this.sections = [
      {
        ...getOverviewSectionBasicProps(),
        currencyRow: {
          imageUrl: transaction.assetIconXs,
          size: mapAssetByNetworkIconSizeToNumber(this.assetIconSize),
          defaultAssetIcon: getStaticDefaultIconForTransaction(transaction.assetTicker, this.assetIconSize),
          label: getStandardAmountFormatting(transaction),
        },
        nodes: [
          {
            id: 'infoSection',
            blockItems: [
              {
                id: 'transactionId',
                label: i18n.t('transactionDetailsPage.labels.transactionId'),
                uiElementType: RowType.text,
                value: transaction.externalId || i18n.t('transactionDetailsPage.labels.noData'),
              } as ITextRow,
              {
                id: 'paymentId',
                label: i18n.t('transactionDetailsPage.labels.paymentId'),
                uiElementType: RowType.text,
                value: transaction.paymentId,
              } as ITextRow,
              {
                id: 'date',
                label: i18n.t('transactionDetailsPage.labels.date'),
                uiElementType: RowType.text,
                value: `${formatDateAndTimeInUTC(transaction.date)} UTC`,
              } as ITextRow,
              {
                id: 'type',
                label: i18n.t('transactionDetailsPage.labels.type'),
                uiElementType: RowType.paymentType,
                value: transaction.paymentType
                  ? capitalizeFirstLetter(transaction.paymentType)
                  : i18n.t('transactionDetailsPage.labels.noData'),
                paymentType: transaction.paymentType,
              } as IPaymentTypeRow,
              {
                id: 'comment',
                label: i18n.t('transactionDetailsPage.labels.comment'),
                uiElementType: RowType.textMultiLine,
                value: transaction?.comment || '',
              } as ITextRow,
            ].filter(i => {
              if ('value' in i) {
                return !!(i as Valued).value;
              }

              return true;
            }),
          },
          {
            id: 'sourceSection',
            blockHeaderLabel: i18n.t('transactionDetailsPage.source'),
            blockItems: [
              {
                id: 'source',
                label: '',
                uiElementType: RowType.valueWithoutLabel,
                value: transaction.sourceOwnerIdentityDisplayName ?? i18n.t('transactionDetailsPage.labels.na'),
              } as ITextRow,
            ],
          },
          {
            id: 'destinationSection',
            blockHeaderLabel: i18n.t('transactionDetailsPage.destination'),
            blockItems: [
              {
                id: 'destination',
                label: '',
                uiElementType: RowType.valueWithoutLabel,
                value: transaction.destinationOwnerIdentityDisplayName ?? i18n.t('transactionDetailsPage.labels.na'),
              } as ITextRow,
            ].filter(i => !!i.value),
          },
        ],
      } as IPlainSection,
    ];

    this.pdfNodes = [
      {
        id: 'infoSection',
        title: i18n.t('transactionDetailsPage.labels.general'),
        items: [
          {
            id: 'amount',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.amountBlockHeaderLabel'),
            value: getStandardAmountFormatting(transaction),
          } as IPlainRow,
          {
            id: 'status',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.status'),
            value: getTransactionStatusLabel(transactionStatus),
          } as IPlainRow,
          {
            id: 'transactionId',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.transactionId'),
            value: transaction.externalId || i18n.t('transactionDetailsPage.labels.noData'),
          } as IPlainRow,
          {
            id: 'paymentId',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.paymentId'),
            value: transaction.paymentId,
          } as IPlainRow,
          {
            id: 'date',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.date'),
            value: `${formatDateAndTimeInUTC(transaction.date)} UTC`,
          } as IPlainRow,
          {
            id: 'type',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.type'),
            value: transaction.paymentType
              ? capitalizeFirstLetter(transaction.paymentType)
              : i18n.t('transactionDetailsPage.labels.noData'),
          } as IPlainRow,
          {
            id: 'comment',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.comment'),
            value: transaction?.comment || '',
          } as IPlainRow,
        ].filter(i => {
          if ('value' in i) {
            return !!(i as Valued).value;
          }

          return true;
        }),
      },
      {
        id: 'sourceSection',
        title: i18n.t('transactionDetailsPage.source'),
        items: [
          {
            id: 'source',
            rowType: PdfRowType.noLabelRow,
            value: transaction.sourceOwnerIdentityDisplayName ?? i18n.t('transactionDetailsPage.labels.na'),
          } as IPdfNoLabelRow,
        ],
      },
      {
        id: 'destinationSection',
        title: i18n.t('transactionDetailsPage.destination'),
        items: [
          {
            id: 'destination',
            rowType: PdfRowType.noLabelRow,
            label: i18n.t('transactionDetailsPage.labels.accountNumber'),
            value: transaction.destinationOwnerIdentityDisplayName ?? i18n.t('transactionDetailsPage.labels.na'),
          } as IPdfNoLabelRow,
        ].filter(i => {
          if ('value' in i) {
            return !!(i as Valued).value;
          }

          return true;
        }),
      },
    ];
  }
}

export { TransactionDetailsBaseViewModel };
