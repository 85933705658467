import React, { useState, useRef, useLayoutEffect } from 'react';
import { TypographyProps } from '@mui/material';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import { isOverflown } from 'utils/helpers';
import { pxToRem } from 'utils/styling-utils';
import { StyledTitleTypography } from './styled';

interface Props extends TypographyProps {
  title: string;
  maxWidth: number;
}

export const CroppedLabel: React.FC<Props> = ({ title, maxWidth, variant, color, ...restProps }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const titleRef = useRef(null);

  useLayoutEffect(() => {
    const isTitleCropped = isOverflown(titleRef.current!);
    setShowTooltip(isTitleCropped);
  }, []);

  return (
    <CommonTooltip
      title={title}
      placement="top"
      disableHoverListener={!showTooltip}
      disableFocusListener={!showTooltip}
      disableTouchListener={!showTooltip}
    >
      <StyledTitleTypography
        {...restProps}
        sx={{
          maxWidth: pxToRem(maxWidth),
        }}
        ref={titleRef}
        variant={variant ?? 'bodyDefaultBook'}
        color={color ?? 'grey.700'}
      >
        {title}
      </StyledTitleTypography>
    </CommonTooltip>
  );
};
