import React from 'react';
import Typography from '@mui/material/Typography';
import { ITokenDetailsPage } from 'ui-interfaces/token-details/i-token-details-page';
import { CommonLayoutContainer, CommonLayoutContent, CommonLayoutHeader } from 'components/shared/layouts/CommonLayout';
import { Gap } from 'components/shared/Gap';
import Breadcrumbs, { BreadCrumbsItem } from 'components/shared/Breadcrumbs';
import { ShadowBox } from 'components/shared/detail-page/rows/shared/ShadowBox';
import { HeaderBox } from 'components/shared/detail-page/HeaderBox';
import { TokenDetailsPageHeader } from './TokenDetailsPageHeader';
import { DetailNode } from '../DetailNode';

type Props = {
  breadCrumbs: BreadCrumbsItem[];
  tokenDetailsPage?: ITokenDetailsPage;
};

export const Content: React.FC<Props> = ({ breadCrumbs, tokenDetailsPage }) => {
  if (!tokenDetailsPage) {
    return null;
  }

  return (
    <CommonLayoutContainer>
      <CommonLayoutHeader>
        <HeaderBox>
          <Breadcrumbs items={breadCrumbs} />
          <Gap size="_20px" />
          <TokenDetailsPageHeader data={tokenDetailsPage.pageHeader} />
        </HeaderBox>
        <Gap size="_20px" />
      </CommonLayoutHeader>
      <CommonLayoutContent>
        <Gap size="_24px" />
        <ShadowBox>
          <Typography variant="h6">{tokenDetailsPage.sectionHeader}</Typography>
          <Gap size="_16px" />
          {tokenDetailsPage.nodes.map((n, index, originalArray) => (
            <DetailNode key={n.id} node={n} hasBottomBorder={!n.noBottomBorder && index !== originalArray.length - 1} />
          ))}
        </ShadowBox>
        <Gap size="_24px" />
      </CommonLayoutContent>
    </CommonLayoutContainer>
  );
};
