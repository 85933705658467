import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';

export const StyledImage = styled('img')(({ theme }) => ({
  borderRadius: theme.gaps._8px,
}));

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  borderRadius: theme.gaps._8px,
  overflow: 'hidden',
  minWidth: pxToRem(144),
  minHeight: pxToRem(144),
}));
