import React, { MouseEvent, useState } from 'react';
import { TypeBackground } from '@mui/material';
import { PopoverModal } from 'elements/components/PopoverModal';
import { PopoverModalContentProps } from 'elements/components/PopoverModal/PopoverModalContent';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { LayoutHeader } from './LayoutHeader';
import {
  StyledFooter,
  StyledScrollableContainer,
  StyledScrollableInnerContainer,
  StyledSignature,
  StyledUpperContainer,
} from './styled';

export type LayoutModalProps = {
  title?: string;
  subtitle?: string;
  withFooter?: boolean;
  withHeader?: boolean;
  isContentCentered?: boolean;
  fullContentHeight?: boolean;
  overHeader?: React.ReactNode;
  subHeader?: React.ReactNode;
  subFooter?: React.ReactNode;
  className?: string;
  onClose?: () => void;
  onBack?: () => void;
  defaultBackgroundColor?: keyof TypeBackground;
  isAbove?: boolean;
  popoverModalSettings?: Omit<PopoverModalContentProps, 'onClosePopoverModal'>;
};

const LayoutModal: React.FC<LayoutModalProps> = ({
  title,
  subtitle,
  children,
  className,
  withFooter,
  withHeader = true,
  isContentCentered = false,
  fullContentHeight = false,
  overHeader,
  subHeader,
  subFooter,
  onClose,
  onBack,
  defaultBackgroundColor,
  isAbove = false,
  popoverModalSettings,
}) => {
  const { isMobile } = useIsMobile();

  const [isPopoverModalOpened, setIsPopoverModalOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  const onHelperIconClick = (event: MouseEvent<SVGSVGElement>) => {
    setIsPopoverModalOpened(true);
    setAnchorEl(event?.currentTarget);
  };

  return (
    <StyledUpperContainer className={className} defaultBackgroundColor={defaultBackgroundColor} isAbove={isAbove}>
      {withHeader && (
        <LayoutHeader
          onHelperIconClick={popoverModalSettings?.href ? onHelperIconClick : null}
          onClose={onClose}
          overHeader={overHeader}
          subHeader={subHeader}
          title={title}
          subtitle={subtitle}
          onBack={onBack}
        />
      )}

      <StyledScrollableContainer withHeader={withHeader} isMobile={isMobile}>
        <StyledScrollableInnerContainer isContentCentered={isContentCentered} fullContentHeight={fullContentHeight}>
          {children}
          {popoverModalSettings?.href && (
            <PopoverModal
              onClosePopoverModal={() => setIsPopoverModalOpened(false)}
              open={isPopoverModalOpened}
              anchorEl={anchorEl}
              {...popoverModalSettings}
            />
          )}
        </StyledScrollableInnerContainer>
      </StyledScrollableContainer>
      {(withFooter || subFooter) && (
        <StyledFooter defaultBackgroundColor={defaultBackgroundColor} isMobile={isMobile}>
          {subFooter}
          {withFooter && <StyledSignature />}
        </StyledFooter>
      )}
    </StyledUpperContainer>
  );
};

export { LayoutModal };
