import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledSearchInput = elementsStyled(TextField)(({ theme }) => {
  const borderRadius = `border-radius: ${theme.borderRadius}`;
  const background = theme.palette.background.custom || theme.palette.background.default;

  return css`
    background: ${background};
    box-sizing: border-box;
    ${borderRadius};

    & .MuiOutlinedInput-root {
      color: ${theme.palette.primary.font};
      background-color: ${background};
      ${borderRadius};
      overflow: hidden;
      height: 52px;
      font-family: ${theme.fontFamily};
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.02em;
      box-sizing: border-box;

      & .MuiOutlinedInput-input {
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      & fieldset {
        border: 1px solid ${theme.palette.tertiary.main};
      }

      &.Mui-focused fieldset,
      &:hover fieldset {
        border: 1px solid ${theme.palette.primary.main};
      }

      @media (hover: hover) {
        &:hover {
          background-color: ${background};
        }
      }

      &.Mui-focused {
        background-color: ${background};
      }
    }

    & .MuiOutlinedInput-input {
      ${css`
        padding: 14px 12px;
      `}

      height: unset;

      //noinspection CssInvalidPseudoSelector
      &:-webkit-autofill,
      &:autofill {
        ${borderRadius};
      }

      &::placeholder {
        color: ${theme.palette.secondary.font};
        opacity: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:focus {
        background: ${background};
      }
    }

    & .MuiInputLabel-root {
      transform: translate(12px, 14px) scale(1);
      font-family: 'MalloryMP', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.02em;

      &.MuiInputLabel-shrink {
        transform: translate(13px, 7.5px) scale(1);
        color: ${theme.palette.primary.font};
        font-family: 'MalloryMP', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 100%;
        letter-spacing: 0;
        padding-bottom: 2px;
      }
    }
  `;
});

export const StyledCancelIcon = elementsStyled(CancelIcon)`
  height: 21px;
  width: 21px;
  color: ${({ theme }) => theme.palette.secondary.font};

  &:hover {
    cursor: pointer;
  }
`;

export const StyledSearchIcon = elementsStyled(SearchIcon)`
  color: ${({ theme }) => theme.palette.secondary.font};
`;
