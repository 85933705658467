export const rawCountriesObjectByCountryCode = {
  AL: { code: 'AL', label: 'Albania', phone: '355' },
  DZ: { code: 'DZ', label: 'Algeria', phone: '213' },
  AX: { code: 'AX', label: 'Alland Islands', phone: '358' },
  AS: { code: 'AS', label: 'American Samoa', phone: '1-684' },
  AD: { code: 'AD', label: 'Andorra', phone: '376' },
  AO: { code: 'AO', label: 'Angola', phone: '244' },
  AI: { code: 'AI', label: 'Anguilla', phone: '1-264' },
  AG: { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268' },
  AR: { code: 'AR', label: 'Argentina', phone: '54' },
  AM: { code: 'AM', label: 'Armenia', phone: '374' },
  AW: { code: 'AW', label: 'Aruba', phone: '297' },
  AU: { code: 'AU', label: 'Australia', phone: '61' },
  AT: { code: 'AT', label: 'Austria', phone: '43' },
  AZ: { code: 'AZ', label: 'Azerbaijan', phone: '994' },
  BS: { code: 'BS', label: 'Bahamas', phone: '1-242' },
  BH: { code: 'BH', label: 'Bahrain', phone: '973' },
  BD: { code: 'BD', label: 'Bangladesh', phone: '880' },
  BB: { code: 'BB', label: 'Barbados', phone: '1-246' },
  BE: { code: 'BE', label: 'Belgium', phone: '32' },
  BZ: { code: 'BZ', label: 'Belize', phone: '501' },
  BJ: { code: 'BJ', label: 'Benin', phone: '229' },
  BM: { code: 'BM', label: 'Bermuda', phone: '1-441' },
  BO: { code: 'BO', label: 'Bolivia', phone: '591' },
  BW: { code: 'BW', label: 'Botswana', phone: '267' },
  BR: { code: 'BR', label: 'Brazil', phone: '55' },
  IO: { code: 'IO', label: 'British Indian Ocean Territory', phone: '246' },
  BN: { code: 'BN', label: 'Brunei', phone: '673' },
  BG: { code: 'BG', label: 'Bulgaria', phone: '359' },
  BF: { code: 'BF', label: 'Burkina Faso', phone: '226' },
  CM: { code: 'CM', label: 'Cameroon', phone: '237' },
  CA: { code: 'CA', label: 'Canada', phone: '1' },
  CV: { code: 'CV', label: 'Cape Verde', phone: '238' },
  KY: { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
  TD: { code: 'TD', label: 'Chad', phone: '235' },
  CL: { code: 'CL', label: 'Chile', phone: '56' },
  CN: { code: 'CN', label: 'China', phone: '86' },
  KM: { code: 'KM', label: 'Comoros', phone: '269' },
  CG: { code: 'CG', label: 'Congo', phone: '242' },
  CK: { code: 'CK', label: 'Cook Islands', phone: '682' },
  CR: { code: 'CR', label: 'Costa Rica', phone: '506' },
  HR: { code: 'HR', label: 'Croatia', phone: '385' },
  CW: { code: 'CW', label: 'Curaçao', phone: '599' },
  CY: { code: 'CY', label: 'Cyprus', phone: '357' },
  CZ: { code: 'CZ', label: 'Czech Republic', phone: '420' },
  DK: { code: 'DK', label: 'Denmark', phone: '45' },
  DJ: { code: 'DJ', label: 'Djibouti', phone: '253' },
  DM: { code: 'DM', label: 'Dominica', phone: '1-767' },
  DO: { code: 'DO', label: 'Dominican Republic', phone: '1-809' },
  EC: { code: 'EC', label: 'Ecuador', phone: '593' },
  EG: { code: 'EG', label: 'Egypt', phone: '20' },
  SV: { code: 'SV', label: 'El Salvador', phone: '503' },
  GQ: { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  EE: { code: 'EE', label: 'Estonia', phone: '372' },
  FK: { code: 'FK', label: 'Falkland Islands', phone: '500' },
  FO: { code: 'FO', label: 'Faroe Islands', phone: '298' },
  FJ: { code: 'FJ', label: 'Fiji', phone: '679' },
  FI: { code: 'FI', label: 'Finland', phone: '358' },
  FR: { code: 'FR', label: 'France', phone: '33' },
  GF: { code: 'GF', label: 'French Guiana', phone: '594' },
  PF: { code: 'PF', label: 'French Polynesia', phone: '689' },
  GA: { code: 'GA', label: 'Gabon', phone: '241' },
  GM: { code: 'GM', label: 'Gambia', phone: '220' },
  GE: { code: 'GE', label: 'Georgia', phone: '995' },
  DE: { code: 'DE', label: 'Germany', phone: '49' },
  GH: { code: 'GH', label: 'Ghana', phone: '233' },
  GI: { code: 'GI', label: 'Gibraltar', phone: '350' },
  GR: { code: 'GR', label: 'Greece', phone: '30' },
  GL: { code: 'GL', label: 'Greenland', phone: '299' },
  GD: { code: 'GD', label: 'Grenada', phone: '1-473' },
  GP: { code: 'GP', label: 'Guadeloupe', phone: '590' },
  GU: { code: 'GU', label: 'Guam', phone: '1-671' },
  GT: { code: 'GT', label: 'Guatemala', phone: '502' },
  GY: { code: 'GY', label: 'Guyana', phone: '592' },
  HN: { code: 'HN', label: 'Honduras', phone: '504' },
  HK: { code: 'HK', label: 'Hong Kong', phone: '852' },
  HU: { code: 'HU', label: 'Hungary', phone: '36' },
  IS: { code: 'IS', label: 'Iceland', phone: '354' },
  IN: { code: 'IN', label: 'India', phone: '91' },
  ID: { code: 'ID', label: 'Indonesia', phone: '62' },
  IE: { code: 'IE', label: 'Ireland', phone: '353' },
  IM: { code: 'IM', label: 'Isle of Man', phone: '44' },
  IL: { code: 'IL', label: 'Israel', phone: '972' },
  IT: { code: 'IT', label: 'Italy', phone: '39' },
  JM: { code: 'JM', label: 'Jamaica', phone: '1-876' },
  JP: { code: 'JP', label: 'Japan', phone: '81' },
  JO: { code: 'JO', label: 'Jordan', phone: '962' },
  KE: { code: 'KE', label: 'Kenya', phone: '254' },
  KI: { code: 'KI', label: 'Kiribati', phone: '686' },
  KW: { code: 'KW', label: 'Kuwait', phone: '965' },
  LV: { code: 'LV', label: 'Latvia', phone: '371' },
  LS: { code: 'LS', label: 'Lesotho', phone: '266' },
  LI: { code: 'LI', label: 'Liechtenstein', phone: '423' },
  LT: { code: 'LT', label: 'Lithuania', phone: '370' },
  LU: { code: 'LU', label: 'Luxembourg', phone: '352' },
  MK: { code: 'MK', label: 'Macedonia', phone: '389' },
  MG: { code: 'MG', label: 'Madagascar', phone: '261' },
  MW: { code: 'MW', label: 'Malawi', phone: '265' },
  MY: { code: 'MY', label: 'Malaysia', phone: '60' },
  MT: { code: 'MT', label: 'Malta', phone: '356' },
  MH: { code: 'MH', label: 'Marshall Islands', phone: '692' },
  MQ: { code: 'MQ', label: 'Martinique', phone: '596' },
  MU: { code: 'MU', label: 'Mauritius', phone: '230' },
  YT: { code: 'YT', label: 'Mayotte', phone: '262' },
  MX: { code: 'MX', label: 'Mexico', phone: '52' },
  FM: { code: 'FM', label: 'Micronesia', phone: '691' },
  MC: { code: 'MC', label: 'Monaco', phone: '377' },
  MN: { code: 'MN', label: 'Mongolia', phone: '976' },
  MS: { code: 'MS', label: 'Montserrat', phone: '1-664' },
  MA: { code: 'MA', label: 'Morocco', phone: '212' },
  MZ: { code: 'MZ', label: 'Mozambique', phone: '258' },
  NA: { code: 'NA', label: 'Namibia', phone: '264' },
  NL: { code: 'NL', label: 'Netherlands', phone: '31' },
  NC: { code: 'NC', label: 'New Caledonia', phone: '687' },
  NZ: { code: 'NZ', label: 'New Zealand', phone: '64' },
  NE: { code: 'NE', label: 'Niger', phone: '227' },
  NG: { code: 'NG', label: 'Nigeria', phone: '234' },
  NU: { code: 'NU', label: 'Niue', phone: '683' },
  NF: { code: 'NF', label: 'Norfolk Island', phone: '672' },
  MP: { code: 'MP', label: 'Northern Mariana Islands', phone: '1-670' },
  NO: { code: 'NO', label: 'Norway', phone: '47' },
  OM: { code: 'OM', label: 'Oman', phone: '968' },
  PW: { code: 'PW', label: 'Palau', phone: '680' },
  PA: { code: 'PA', label: 'Panama', phone: '507' },
  PG: { code: 'PG', label: 'Papua New Guinea', phone: '675' },
  PY: { code: 'PY', label: 'Paraguay', phone: '595' },
  PE: { code: 'PE', label: 'Peru', phone: '51' },
  PH: { code: 'PH', label: 'Philippines', phone: '63' },
  PL: { code: 'PL', label: 'Poland', phone: '48' },
  PT: { code: 'PT', label: 'Portugal', phone: '351' },
  PR: { code: 'PR', label: 'Puerto Rico', phone: '1' },
  QA: { code: 'QA', label: 'Qatar', phone: '974' },
  RE: { code: 'RE', label: 'Reunion', phone: '262' },
  RO: { code: 'RO', label: 'Romania', phone: '40' },
  KN: { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869' },
  LC: { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
  PM: { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508' },
  VC: { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784' },
  WS: { code: 'WS', label: 'Samoa', phone: '685' },
  SM: { code: 'SM', label: 'San Marino', phone: '378' },
  ST: { code: 'ST', label: 'Sao Tome and Principe', phone: '239' },
  SA: { code: 'SA', label: 'Saudi Arabia', phone: '966' },
  SN: { code: 'SN', label: 'Senegal', phone: '221' },
  RS: { code: 'RS', label: 'Serbia', phone: '381' },
  SC: { code: 'SC', label: 'Seychelles', phone: '248' },
  SL: { code: 'SL', label: 'Sierra Leone', phone: '232' },
  SG: { code: 'SG', label: 'Singapore', phone: '65' },
  SX: { code: 'SX', label: 'Sint Maarten', phone: '1-721' },
  SK: { code: 'SK', label: 'Slovakia', phone: '421' },
  SI: { code: 'SI', label: 'Slovenia', phone: '386' },
  SB: { code: 'SB', label: 'Solomon Islands', phone: '677' },
  ZA: { code: 'ZA', label: 'South Africa', phone: '27' },
  KR: { code: 'KR', label: 'South Korea', phone: '82' },
  ES: { code: 'ES', label: 'Spain', phone: '34' },
  LK: { code: 'LK', label: 'Sri Lanka', phone: '94' },
  SR: { code: 'SR', label: 'Suriname', phone: '597' },
  SZ: { code: 'SZ', label: 'Swaziland', phone: '268' },
  SE: { code: 'SE', label: 'Sweden', phone: '46' },
  CH: { code: 'CH', label: 'Switzerland', phone: '41' },
  TW: { code: 'TW', label: 'Taiwan', phone: '886' },
  TJ: { code: 'TJ', label: 'Tajikistan', phone: '992' },
  TZ: { code: 'TZ', label: 'Tanzania', phone: '255' },
  TH: { code: 'TH', label: 'Thailand', phone: '66' },
  TG: { code: 'TG', label: 'Togo', phone: '228' },
  TO: { code: 'TO', label: 'Tonga', phone: '676' },
  TT: { code: 'TT', label: 'Trinidad and Tobago', phone: '1-868' },
  TN: { code: 'TN', label: 'Tunisia', phone: '216' },
  TR: { code: 'TR', label: 'Turkey', phone: '90' },
  TM: { code: 'TM', label: 'Turkmenistan', phone: '993' },
  TC: { code: 'TC', label: 'Turks and Caicos Islands', phone: '1-649' },
  TV: { code: 'TV', label: 'Tuvalu', phone: '688' },
  UG: { code: 'UG', label: 'Uganda', phone: '256' },
  AE: { code: 'AE', label: 'United Arab Emirates', phone: '971' },
  GB: { code: 'GB', label: 'United Kingdom', phone: '44' },
  US: { code: 'US', label: 'United States', phone: '1' },
  UY: { code: 'UY', label: 'Uruguay', phone: '598' },
  UZ: { code: 'UZ', label: 'Uzbekistan', phone: '998' },
  VU: { code: 'VU', label: 'Vanuatu', phone: '678' },
  VN: { code: 'VN', label: 'Vietnam', phone: '84' },
  VG: { code: 'VG', label: 'Virgin Islands', phone: '1-284' },
  VI: { code: 'VI', label: 'Virgin Islands U.S.', phone: '1-340' },
  EH: { code: 'EH', label: 'Western Sahara', phone: '212' },
  ZM: { code: 'ZM', label: 'Zambia', phone: '260' },
};

export const countryCodeList = Object.keys(
  rawCountriesObjectByCountryCode,
) as (keyof typeof rawCountriesObjectByCountryCode)[];

export const COUNTRY_CODE_US = 'US';
