import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLazyGetUserCustodialAccountsQuery } from 'redux/api/api-custodial-accounts';
import { useExport } from 'hooks/use-export';
import { CustodialAccountsRow } from 'ui-interfaces/row-interfaces/custodial-accounts';
import { Filterable } from 'ui-interfaces/shared/filters/filterable';
import { CustodialAccountsListFiltersViewModel } from 'view-models/custodial-accounts/filters/custodial-accounts-list-filters-vm';
import { FilterResult } from 'filters/interfaces/filter-result';
import { CustomizedGrid } from 'components/shared/grids/CustomizedGrid';
import { onCustodialAccountRowClick, onCustodialAccountTransform } from '../../handlers';
import { columnsCustodialAccounts } from '../../columns-definitions';
import { onUserCustodialAccountsExportClick } from './handlers';

export const UserCustodialAccountsTab: React.FC = () => {
  const navigate = useNavigate();
  const { downloadCsv, isLoading: isExportLoading, error: exportError } = useExport();
  const [getUserCustodialAccountsTrigger, userCustodialAccountsResult] = useLazyGetUserCustodialAccountsQuery();

  const fetchUserCustodialAccounts = useCallback(
    (page?: number, filterResult?: FilterResult) => getUserCustodialAccountsTrigger({ page, filterResult }, false),
    [getUserCustodialAccountsTrigger],
  );

  const [rows, setRows] = useState<CustodialAccountsRow[]>([]);
  const [resourceCount, setResourceCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(userCustodialAccountsResult.isUninitialized);
  const [filterable] = useState<Filterable>(() => new CustodialAccountsListFiltersViewModel());

  useEffect(() => {
    fetchUserCustodialAccounts();
  }, [fetchUserCustodialAccounts]);

  useEffect(() => {
    if (userCustodialAccountsResult.isSuccess && userCustodialAccountsResult.data) {
      const transformedItems = userCustodialAccountsResult.data.data.map(onCustodialAccountTransform);
      setRows(transformedItems);
      setResourceCount(userCustodialAccountsResult.data.meta?.resourceCount ?? 0);
      setLoading(userCustodialAccountsResult.isFetching);
    }
  }, [userCustodialAccountsResult.data, userCustodialAccountsResult.isFetching, userCustodialAccountsResult.isSuccess]);

  const { t } = useTranslation();

  return (
    <CustomizedGrid
      columns={columnsCustodialAccounts}
      headerName={t('custodialAccountsPage.userCustodialAccountsGridTitle')}
      filters={filterable.filters}
      selectionMacroCommand={filterable.selectionMacroCommand}
      noResultsTitle={t('custodialAccountsPage.noResultsTitle')}
      noResultsSubtitle={t('custodialAccountsPage.noResultsSubtitle')}
      containerHeight="100%"
      rows={rows}
      rowCount={resourceCount}
      isLoading={loading}
      error={userCustodialAccountsResult.error || exportError}
      fetchOtherItems={fetchUserCustodialAccounts}
      onGridRowClick={onCustodialAccountRowClick(navigate)}
      onExportClick={onUserCustodialAccountsExportClick(downloadCsv)}
      isExportLoading={isExportLoading}
    />
  );
};
