import React from 'react';
import { Typography } from '@mui/material';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import { AssetImage } from 'components/shared/Cells/AssetImage';
import { StyledSpan } from './styled';

interface Props {
  imageUrl?: string;
  tooltipMessage: string;
  size: number;
  defaultAssetIcon: JSX.Element;
}

export const WalletMultiAssetIcon: React.FC<Props> = ({ imageUrl, tooltipMessage, size, defaultAssetIcon }) => {
  return (
    <CommonTooltip
      placement="top"
      title={
        <Typography color="background.paper" variant="bodySmallBook">
          {tooltipMessage}
        </Typography>
      }
    >
      <StyledSpan>
        <AssetImage url={imageUrl} size={size} defaultAssetIcon={defaultAssetIcon} />
      </StyledSpan>
    </CommonTooltip>
  );
};
