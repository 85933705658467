import { TrustAssetType } from 'ui-enums/response/custodial-accounts/trust-asset-type';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import { Balance } from 'ui-interfaces/custodial-accounts-details/custodial-account-balance';
import { AccountBalanceCryptoCurrencyViewModel } from 'view-models/account-balances/account-balances-crypto-currency-vm';
import { AccountBalanceFiatCurrencyViewModel } from 'view-models/account-balances/account-balances-fiat-currency-vm';

export const createCustodialAccountBalanceViewModel = (balance: CustodialAccountBalanceModel): Balance => {
  switch (balance.assetType.toLowerCase()) {
    case TrustAssetType.fiatCurrency.toLowerCase(): {
      return new AccountBalanceFiatCurrencyViewModel(balance);
    }
    case TrustAssetType.cryptoCurrency.toLowerCase(): {
      return new AccountBalanceCryptoCurrencyViewModel(balance);
    }
    // @TODO add nft asset type case
    default: {
      return new AccountBalanceCryptoCurrencyViewModel(balance);
    }
  }
};
