import React from 'react';
import MuiStack, { StackProps as MuiStackProps } from '@mui/material/Stack';
import { css, styled } from '@mui/material/styles';
import { preventForwardProps } from 'elements/utils';

type StackProps = {
  fullWidth?: boolean;
  alignCenter?: boolean;
  justifyCenter?: boolean;
} & MuiStackProps;

/**
 * Stack component is used to arrange elements in flexbox component using all its properties and all system mui properties
 * for more details explore https://mui.com/material-ui/react-stack/  and https://mui.com/system/properties/
 * * */
export const Stack = styled<React.FC<StackProps>>(
  MuiStack,
  preventForwardProps(['fullWidth', 'alignCenter', 'justifyCenter']),
)(
  ({ fullWidth, alignCenter, justifyCenter }) => css`
    width: ${fullWidth && '100%'};
    align-items: ${alignCenter && 'center'};
    justify-content: ${justifyCenter && 'center'};
  `,
);
