import React from 'react';
import ListItemText from '@mui/material/ListItemText';
import { StyledTooltip } from 'components/shared/StyledTooltip';
import { SideMenuListItem } from 'ui-interfaces/side-menu/side-menu-list-item';
import { getSideMenuFadedIcon } from 'utils/icons-mapping/side-menu/get-side-menu-faded-icon';
import { getSideMenuIcon } from 'utils/icons-mapping/side-menu/get-side-menu-icon';
import { StyledListItemButton, StyledListItemIcon, StyledNavLink } from './styled';

type Props = {
  el: SideMenuListItem;
  open: boolean;
};

export const SideMenuItem: React.FC<Props> = ({ el, open }) => {
  return (
    <StyledNavLink end to={el.to}>
      {({ isActive }) => {
        if (!open) {
          return (
            <StyledTooltip title={el.label} placement="right">
              <StyledListItemButton selected={isActive} key={el.label}>
                <StyledListItemIcon>
                  {isActive ? getSideMenuIcon(el.icon) : getSideMenuFadedIcon(el.icon)}
                </StyledListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={el.label} />
              </StyledListItemButton>
            </StyledTooltip>
          );
        }
        return (
          <StyledListItemButton selected={isActive} key={el.label}>
            <StyledListItemIcon>
              {isActive ? getSideMenuIcon(el.icon) : getSideMenuFadedIcon(el.icon)}
            </StyledListItemIcon>
            <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={el.label} />
          </StyledListItemButton>
        );
      }}
    </StyledNavLink>
  );
};
