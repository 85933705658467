import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { formatPhoneNumber } from 'utils/format-phone-number';
import { DashboardRoles } from 'models/enums/user/dashboard-roles';

export const formatIdentityPhone = (user: ShortUserResponseModel, phone: string) => {
  if (!user.permissions.includes(DashboardRoles.pii)) {
    return phone;
  }

  return formatPhoneNumber(phone);
};
