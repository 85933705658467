import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const MMM_D_YYYY = 'MMM D, YYYY'; // Apr 1, 2022
export const MMM_D_YYYY_HH_MM = 'MMM D, YYYY HH:mm'; // Mar 12, 2022 06:20
export const DD_MM_YYYY = 'DD MM YYYY'; // 01 01 2000

// time
export const HH_MM = 'HH:mm'; // 12:09

export const formatDate = (date: string, format: string = MMM_D_YYYY) => {
  return dayjs.utc(date).format(format);
};

export const formatDateAndTimeInUTC = (date: string, format: string = MMM_D_YYYY_HH_MM) => formatDate(date, format);

export const getTime = (date: string, format: string = HH_MM) => formatDate(date, format);
