import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Divider, NoteReview, Spacer } from 'elements/components';
import { CustodialAccount } from 'elements/types';
import { formatUsdBalance } from 'elements/utils';
import { ReactComponent as RoundedCheckIcon } from 'elements/element-transfer/assets/icons/roundedCheck.svg';
import Layout from 'elements/element-transfer/components/Layout';
import TransactionSummary from 'elements/element-transfer/components/TransactionSummary';
import WireSource from 'elements/element-transfer/components/Withdrawal/WireSource';
import WireTo from 'elements/element-transfer/components/Withdrawal/WireTo';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { StyledButton } from './styled';
import PurposeOfPayment from '../PurposeOfPayment';

type Props = {
  amount: string;
  recipientName: string;
  bankName: string;
  bankCountry: string;
  accountNumber: string;
  custodialAccount: CustodialAccount | null;
  note: string;
  purposeOfPayment: string;
  isDomestic: boolean;
  memo: string;
  onDone: () => void;
};

const WirePlacedPage: React.FC<Props> = ({
  amount,
  recipientName,
  bankName,
  bankCountry,
  accountNumber,
  custodialAccount,
  note,
  purposeOfPayment,
  isDomestic,
  memo,
  onDone,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  const formattedAmount = formatUsdBalance(Number(amount), isMobile);

  return (
    <Layout
      title={t('elements.transfer.customizableLabels.withdrawalWireSuccessPageTitle')}
      onClose={onDone}
      withCloseButton={false}
      footerButtons={
        <StyledButton onClick={onDone}>
          <Typography variant="buttonLarge">{t('elements.common.done')}</Typography>
        </StyledButton>
      }
    >
      <TransactionSummary
        icon={RoundedCheckIcon}
        amount={formattedAmount}
        amountLabel={t('elements.transfer.withdrawalWirePlacedPage.amountLabel')}
        totalAmount={formattedAmount}
        totalLabel={t('elements.transfer.withdrawalWirePlacedPage.totalLabel')}
      />
      <Spacer size={40} />
      <WireTo
        recipientName={recipientName}
        bankName={bankName}
        bankCountry={bankCountry}
        accountNumber={accountNumber}
      />
      <Spacer size={isMobile ? 32 : 24} />
      {!!custodialAccount && (
        <>
          <WireSource custodialAccount={custodialAccount} />
          <Spacer size={isMobile ? 32 : 24} />
        </>
      )}
      {!!purposeOfPayment && !isDomestic && (
        <>
          <PurposeOfPayment text={purposeOfPayment} />
          <Divider />
          <Spacer size={isMobile ? 32 : 24} />
        </>
      )}
      {!!memo && (
        <>
          <NoteReview note={memo} title={t('elements.transfer.withdrawalWirePlacedPage.memoTitle')} withMessageIcon />
          <Divider />
          <Spacer size={8} />
        </>
      )}

      {!!note && (
        <>
          <NoteReview note={note} title={t('elements.transfer.withdrawalWirePlacedPage.internalNoteTitle')} />
          <Divider />
          <Spacer size={32} />
        </>
      )}
    </Layout>
  );
};

export default WirePlacedPage;
