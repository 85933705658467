import React from 'react';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import i18n from 'localizations';

type Props = {
  onClick: () => void;
};

export const CancelButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <Typography color="primary.main" variant="buttonMedium">
        {i18n.t('filters.cancelButtonLabel')}
      </Typography>
    </Button>
  );
};
