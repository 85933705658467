import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Button } from 'elements/components';
import { CustodialAccount, PaymentAccount } from 'elements/types';
import { ReactComponent as RoundedCheckIcon } from 'elements/element-transfer/assets/icons/roundedCheck.svg';
import DepositInfo from 'elements/element-transfer/components/Deposit/DepositInfo';
import Layout from 'elements/element-transfer/components/Layout';
import { useIsMobile } from 'elements/hooks/useIsMobile';

type Props = {
  depositAmount: number;
  paymentAccount: PaymentAccount | null;
  custodialAccount: CustodialAccount | null;
  onDone: () => void;
};

const AchPlacedPage: React.FC<Props> = ({ depositAmount, paymentAccount, custodialAccount, onDone }) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  return (
    <Layout
      title={t('elements.transfer.customizableLabels.depositAchSuccessPageTitle')}
      withCloseButton={!isMobile}
      onClose={onDone}
      footerButtons={
        <Button onClick={onDone}>
          <Typography variant="buttonLarge">{t('elements.common.done')}</Typography>
        </Button>
      }
    >
      <DepositInfo
        depositAmount={depositAmount}
        icon={RoundedCheckIcon}
        paymentAccount={paymentAccount}
        custodialAccount={custodialAccount}
      />
    </Layout>
  );
};

export default AchPlacedPage;
