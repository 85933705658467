import i18n from 'localizations';
import { getUserPermissionLabel } from 'utils/labels-mapping/get-user-permission-label';
import { getEnabledDisabledLabel } from 'utils/labels-mapping/get-enabled-disabled-label';
import { UserPermissionModel } from 'models/response/users/user-permission-model';
import { UserDetailsNode } from 'ui-interfaces/user-details/user-details-node';
import { UserDetailsRowType } from 'ui-enums/user-details/user-details-row-type';
import { IDefaultRow } from 'ui-interfaces/user-details/rows/i-default-row';

export const generatePermissions = (permissionDefinitions: UserPermissionModel[]): UserDetailsNode[] => {
  if (permissionDefinitions.length === 0) {
    return [];
  }

  return [
    {
      id: 'permissions',
      title: i18n.t('userDetailsPage.permissions'),
      items: permissionDefinitions.map(p => {
        return {
          id: p.key,
          rowType: UserDetailsRowType.default,
          label: getUserPermissionLabel(p.key),
          value: getEnabledDisabledLabel(p.value),
        } as IDefaultRow;
      }),
    },
  ];
};
