import React from 'react';
import { IUiTextRow } from 'ui-interfaces/shared/rows/i-ui-text-row';
import { RowBox } from './RowBox';
import { LeftCell } from './LeftCell';
import { RightCell } from './RightCell';

type Props = {
  data: IUiTextRow;
  hasBottomBorder?: boolean;
};

export const TextMultiLineRow: React.FC<Props> = ({ data, hasBottomBorder = true }) => {
  return (
    <RowBox hasBottomBorder={hasBottomBorder}>
      <LeftCell value={data.label} large={data.largeLeftCell} />
      <RightCell value={data.value} large={!data.largeLeftCell} />
    </RowBox>
  );
};
