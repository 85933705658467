import { Button, css } from '@mui/material';
import { BaseInput } from 'elements/components';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledBaseInput = elementsStyled(BaseInput)`
  .MuiInputBase-root {
    padding-right: 4px;
    padding-left: 4px;
  }

  .MuiInputBase-input {
    overflow-y: scroll !important;
    padding-right: 2px;
    padding-left: 8px;
  }
`;

export const StyledTextButton = elementsStyled(Button)(css`
  align-self: flex-start;
  padding: 0;
`);
