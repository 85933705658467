import { Typography } from '@mui/material';
import { css } from '@mui/material/styles';
import { BaseSelect, BaseSelectItem } from 'elements/components';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { preventForwardProps } from 'elements/utils';

export const StyledBaseSelectItem = elementsStyled(BaseSelectItem)`
  padding: 12px 6px;
`;

export const StyledBaseSelect = elementsStyled(BaseSelect)`
  & .MuiFilledInput-root {
    height: 60px;
  }
`;

export const StyledSubtitle = elementsStyled(Typography)`
  display: block;
  margin-bottom: 16px;
  overflow-wrap: break-word;
`;

export const StyledContainer = elementsStyled(
  'div',
  preventForwardProps(['isMobile']),
)<{ isMobile: boolean }>(
  ({ isMobile }) => css`
    padding: 42px 0 ${isMobile ? 65 : 220}px;
    text-align: center;
  `,
);
