import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { pxToRem } from 'utils/styling-utils';
import { Row } from 'components/shared/Flex/Row';
import { KycLevel } from 'models/enums/identities/kyc-level';

export const StyledKysLevelRow = styled(Row, {
  shouldForwardProp: prop => prop !== 'kycLevel',
})<{ kycLevel: KycLevel }>(({ theme, kycLevel = KycLevel.L0 }) => {
  let backgroundColor = '';

  switch (true) {
    case kycLevel === KycLevel.L1: {
      backgroundColor = theme.palette.warning.light2 || '';

      break;
    }
    case kycLevel === KycLevel.L2: {
      backgroundColor = theme.palette.info.light2 || '';

      break;
    }
    default: {
      backgroundColor = theme.palette.grey['200'] || '';
    }
  }

  return {
    justifyContent: 'center',
    alignItems: 'center',
    height: pxToRem(28),
    paddingInline: pxToRem(theme.gaps._12px),
    borderRadius: pxToRem(14),
    backgroundColor,
  };
});

export const StyledKycLevelLabel = styled(Typography, {
  shouldForwardProp: prop => prop !== 'kycLevel',
})<{ kycLevel: KycLevel }>(({ theme, kycLevel = KycLevel.L0 }) => {
  let color = '';

  switch (true) {
    case kycLevel === KycLevel.L1: {
      color = theme.palette.warning.dark || '';
      break;
    }
    case kycLevel === KycLevel.L2: {
      color = theme.palette.info.main || '';
      break;
    }
    default: {
      color = theme.palette.grey['700'] || '';
    }
  }

  return {
    color,
  };
});
