import React from 'react';
import { Box, Typography } from '@mui/material';
import { UserDetailsNode } from 'ui-interfaces/user-details/user-details-node';
import { createRow } from 'components/settings/users/UserDetailsPageContent/create-row';
import { GrayGap, StyledBox } from './styled';

type Props = {
  hasBottomBorder: boolean;
  userDetailsNode: UserDetailsNode;
};

export const DetailNode: React.FC<Props> = ({ userDetailsNode, hasBottomBorder }) => {
  return (
    <Box>
      {userDetailsNode.title && (
        <StyledBox>
          <Typography variant="h7">{userDetailsNode.title}</Typography>
        </StyledBox>
      )}
      {userDetailsNode.items.map((i, index, originalArray) => {
        return createRow(i, index === originalArray.length - 1);
      })}
      {hasBottomBorder && <GrayGap size="_8px" />}
    </Box>
  );
};
