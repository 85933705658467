import i18n from 'localizations';
import { TransactionDetails } from 'ui-interfaces/transaction-details/transaction-details';
import { TransactionDetailResponseModel } from 'models/response/transactions/transaction-detail-response-model';
import { formatDateAndTimeInUTC } from 'utils/format-date';
import { TransactionDetailsBaseViewModel } from 'view-models/transactions/details/transaction-details-base-vm';
import { addSpaceAfterEvery4Chars } from 'utils/add-space-after-every-4-chars';
import { getNetworkChainLabel } from 'utils/labels-mapping/get-network-chain-label';
import { LabelLength } from 'ui-enums/label-length';
import { getSignOfTransaction } from 'utils/transactions/get-sign-of-transaction';
import { RowType } from 'ui-enums/transaction-detail/row-type';
import { generateApprovalSection } from 'utils/transactions/generate-approval-section';
import { getCryptoAmountForActionDialog } from 'utils/transactions/get-crypto-amount-for-action-dialog';
import { ITextRow } from 'ui-interfaces/transaction-details/rows/i-text-row';
import { ILinkRow } from 'ui-interfaces/transaction-details/rows/i-link-row';
import { IPaymentTypeRow } from 'ui-interfaces/transaction-details/rows/i-payment-type-row';
import { formatCurrency } from 'utils/format-currency';
import { getOverviewSectionBasicProps } from 'utils/transactions/detail/get-overview-section-basic-props';
import { IPlainSection } from 'ui-interfaces/transaction-details/sections/i-plain-section';
import { PdfRowType } from 'ui-enums/pdf/pdf-row-type';
import { getPdfAmountForDepositWithdrawalInternalTransferCrypto } from 'utils/transactions/detail/pdf/get-pdf-amount-for-deposit-withdrawal-internal-transfer-crypto';
import { IPlainRow } from 'ui-interfaces/transaction-details/pdf/rows/i-plain-row';
import { getTransactionStatusLabel } from 'utils/labels-mapping/get-transaction-status-label';
import { IPdfLinkRow } from 'ui-interfaces/transaction-details/pdf/rows/i-pdf-link-row';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';
import { mapAssetByNetworkIconSizeToNumber } from 'utils/multi-mapping/asset-by-network-icon-size/map-asset-by-network-icon-size-to-number';
import { getDynamicAssetLabel } from 'utils/labels-mapping/get-dynamic-asset-label';
import { getStaticDefaultIconForTransaction } from 'utils/icons-mapping/transactions/get-static-default-icon-for-transaction';

export class TransactionDetailsWithdrawalCryptoViewModel
  extends TransactionDetailsBaseViewModel
  implements TransactionDetails
{
  constructor(transaction: TransactionDetailResponseModel) {
    super(transaction);

    const transactionStatus = transaction.status as TransactionStatus;

    this.amountForActionDialog = getCryptoAmountForActionDialog(transaction);

    this.sections = [
      {
        ...getOverviewSectionBasicProps(),
        currencyRow: {
          imageUrl: transaction.assetIconXs,
          size: mapAssetByNetworkIconSizeToNumber(this.assetIconSize),
          defaultAssetIcon: getStaticDefaultIconForTransaction(transaction.assetTicker, this.assetIconSize),

          label: `${getSignOfTransaction(transaction)}${transaction.cryptoAmount ?? 0} ${getDynamicAssetLabel(
            LabelLength.short,
            transaction.assetTicker,
            transaction.assetName,
          )}`,
          subLabel: transaction.amount
            ? `${getSignOfTransaction(transaction)}${formatCurrency(transaction.amount ?? 0)} ${getSignOfTransaction(
                transaction,
              )}${transaction.cryptoAmount ?? 0} ${getDynamicAssetLabel(
                LabelLength.short,
                transaction.assetTicker,
                transaction.assetName,
              )}`
            : undefined,
        },
        nodes: [
          {
            id: 'infoSection',
            blockItems: [
              {
                id: 'transactionId',
                label: i18n.t('transactionDetailsPage.labels.transactionId'),
                uiElementType: RowType.text,
                value: transaction.externalId || i18n.t('transactionDetailsPage.labels.noData'),
              } as ITextRow,
              {
                id: 'paymentId',
                label: i18n.t('transactionDetailsPage.labels.paymentId'),
                uiElementType: RowType.text,
                value: transaction.paymentId,
              } as ITextRow,
              {
                id: 'transactionHash',
                label: i18n.t('transactionDetailsPage.labels.transactionHash'),
                uiElementType: RowType.link,
                value: transaction?.txHash || '',
                url: transaction?.txHashUrl || '',
              } as ILinkRow,
              {
                id: 'date',
                label: i18n.t('transactionDetailsPage.labels.dateLabel'),
                uiElementType: RowType.text,
                value: `${formatDateAndTimeInUTC(transaction.date)} UTC`,
              } as ITextRow,
              {
                id: 'type',
                label: i18n.t('transactionDetailsPage.labels.type'),
                uiElementType: RowType.paymentType,
                value: i18n.t('transactionDetailsPage.labels.withdrawal'),
                paymentType: transaction.paymentType,
              } as IPaymentTypeRow,
              {
                id: 'blockChain',
                label: i18n.t('transactionDetailsPage.labels.blockchain'),
                uiElementType: RowType.text,
                value: transaction.network ? getNetworkChainLabel(this.networkChain!) : '',
              } as ITextRow,
              {
                id: 'comment',
                label: i18n.t('transactionDetailsPage.labels.comment'),
                uiElementType: RowType.textMultiLine,
                value: (transaction.initiatedBy ? '' : transaction.comment) || '',
              } as ITextRow,
            ].filter(i => {
              if (
                i.uiElementType === RowType.text ||
                i.uiElementType === RowType.textMultiLine ||
                i.uiElementType === RowType.link
              ) {
                return !!i.value;
              }

              return true;
            }),
          },
          {
            id: 'sourceSection',
            blockHeaderLabel: i18n.t('transactionDetailsPage.source'),
            blockItems: [
              {
                id: 'custodialAccountNumber',
                label: i18n.t('transactionDetailsPage.labels.accountNumber'),
                uiElementType: RowType.text,
                value: addSpaceAfterEvery4Chars(transaction.sourceAccountNumber || ''),
              } as ITextRow,
              {
                id: 'accountOwner',
                label: i18n.t('transactionDetailsPage.labels.accountOwner'),
                uiElementType: RowType.text,
                value: transaction.sourceOwnerIdentityDisplayName,
              } as ITextRow,
              {
                id: 'walletAddress',
                label: i18n.t('transactionDetailsPage.labels.walletAddress'),
                uiElementType: RowType.text,
                value: transaction?.sourceCryptoAddress || '',
              } as ITextRow,
            ].filter(i => !!i.value),
          },
          {
            id: 'destinationSection',
            blockHeaderLabel: i18n.t('transactionDetailsPage.destination'),
            blockItems: [
              {
                id: 'walletAddress',
                label: i18n.t('transactionDetailsPage.labels.walletAddress'),
                uiElementType: RowType.text,
                value: transaction?.destinationCryptoAddress || '',
              } as ITextRow,
              {
                id: 'destinationTag',
                label: i18n.t('transactionDetailsPage.labels.destinationTag'),
                uiElementType: RowType.textMultiLine,
                value: transaction.destinationTag || '',
              } as ITextRow,
            ].filter(i => !!i.value),
          },
          {
            id: 'initiatedBySection',
            blockHeaderLabel: i18n.t('transactionDetailsPage.initiationSectionLabel'),
            blockItems: [
              {
                id: 'initiatedBy',
                label: i18n.t('transactionDetailsPage.labels.initiatedBy'),
                uiElementType: RowType.text,
                value: transaction?.initiatedByDisplayValue || '',
              } as ITextRow,
              {
                id: 'dateInitiated',
                label: i18n.t('transactionDetailsPage.labels.dateInitiated'),
                uiElementType: RowType.text,
                value: transaction.initiatedAt ? `${formatDateAndTimeInUTC(transaction.initiatedAt)} UTC` : '',
              } as ITextRow,
              {
                id: 'comment',
                label: i18n.t('transactionDetailsPage.labels.comment'),
                uiElementType: RowType.textMultiLine,
                value: (transaction.initiatedBy ? transaction.comment : '') || '',
              } as ITextRow,
            ].filter(i => !!i.value),
          },
          ...generateApprovalSection(transaction.approvals, transactionStatus),
          // {
          //   id: 'feeSection',
          //   blockHeaderLabel: i18n.t('transactionDetailsPage.labels.feeBlockHeaderLabel'),
          //   blockHeaderSubLabel: `${i18n.t(
          //     'transactionDetailsPage.labels.amountBlockHeaderSubLabelForCryptoWithdrawalFirstPart',
          //   )} ${transaction?.sourceOrganizationName}${i18n.t(
          //     'transactionDetailsPage.labels.amountBlockHeaderSubLabelForCryptoWithdrawalSecondPart',
          //   )}`,
          //   blockItems: [
          //     {
          //       id: 'feeRow',
          //       rowType: RowType.fee,
          //       frontItem: {
          //         id: 'blockChainFee',
          //         label: i18n.t('transactionDetailsPage.labels.blockChainFeeLabel'),
          //         value: transaction?.gasFee ? `${transaction?.gasFee} ${transaction?.feeAsset?.toUpperCase() || ''}` : '',
          //         toolTipMessage: i18n.t('transactionDetailsPage.labels.tapiBlockchainFeeTooltip'),
          //       },
          //       collapsedItems: [
          //         {
          //           id: 'gasPrice',
          //           label: i18n.t('transactionDetailsPage.labels.gasPrice'),
          //           toolTipMessage: i18n.t('transactionDetailsPage.labels.gasPriceTooltip'),
          //           value: transaction?.gasPrice
          //             ? `${transaction?.gasPrice} ${i18n.t('transactionDetailsPage.labels.gwei')}`
          //             : '',
          //         },
          //         {
          //           key: 'gasLimit',
          //           label: i18n.t('transactionDetailsPage.labels.gasLimit'),
          //           rowType: RowType.labelWithTooltip,
          //           toolTipMessage: i18n.t('transactionDetailsPage.labels.gasLimitTooltip'),
          //           value: transaction?.gasLimit
          //             ? `${transaction?.gasLimit} ${i18n.t('transactionDetailsPage.labels.units')}`
          //             : '',
          //         },
          //       ],
          //     } as IFeeRow,
          //   ].filter(i => {
          //     if (i.rowType === RowType.fee) {
          //       return !!(i as IFeeRow).frontItem.value;
          //     }
          //
          //     return true;
          //   }),
          // },
        ].filter(node => {
          if (node.blockHeaderLabel === i18n.t('transactionDetailsPage.initiationSectionLabel')) {
            return !!transaction.initiatedBy;
          }
          return true;
        }),
      } as IPlainSection,
    ];

    this.pdfNodes = [
      {
        id: 'infoSection',
        title: i18n.t('transactionDetailsPage.labels.general'),
        items: [
          {
            id: 'amount',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.amountBlockHeaderLabel'),
            value: getPdfAmountForDepositWithdrawalInternalTransferCrypto(transaction),
          } as IPlainRow,
          {
            id: 'status',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.status'),
            value: getTransactionStatusLabel(transactionStatus),
          } as IPlainRow,
          {
            id: 'transactionId',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.transactionId'),
            value: transaction.externalId || i18n.t('transactionDetailsPage.labels.noData'),
          } as IPlainRow,
          {
            id: 'paymentId',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.paymentId'),
            value: transaction.paymentId,
          } as IPlainRow,
          {
            id: 'transactionHash',
            rowType: PdfRowType.link,
            label: i18n.t('transactionDetailsPage.labels.transactionHash'),
            value: transaction?.txHash || '',
            url: transaction?.txHashUrl || '',
          } as IPdfLinkRow,
          {
            id: 'date',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.dateLabel'),
            value: `${formatDateAndTimeInUTC(transaction.date)} UTC`,
          } as IPlainRow,
          {
            id: 'type',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.type'),
            value: i18n.t('transactionDetailsPage.labels.withdrawal'),
          } as IPlainRow,
          {
            id: 'blockChain',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.blockchain'),
            value: transaction?.network ? getNetworkChainLabel(this.networkChain!) : '',
          } as IPlainRow,
          {
            id: 'comment',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.comment'),
            value: (transaction.initiatedBy ? '' : transaction.comment) || '',
          } as IPlainRow,
        ].filter(i => !!i.value),
      },
      {
        id: 'sourceSection',
        title: i18n.t('transactionDetailsPage.source'),
        items: [
          {
            id: 'custodialAccountNumber',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.accountNumber'),
            value: addSpaceAfterEvery4Chars(transaction.sourceAccountNumber || ''),
          } as IPlainRow,
          {
            id: 'accountOwner',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.accountOwner'),
            value: transaction.sourceOwnerIdentityDisplayName,
          } as IPlainRow,
          {
            id: 'walletAddress',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.walletAddress'),
            value: transaction?.sourceCryptoAddress || '',
          } as IPlainRow,
        ].filter(i => !!i.value),
      },
      {
        id: 'destinationSection',
        title: i18n.t('transactionDetailsPage.destination'),
        items: [
          {
            id: 'walletAddress',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.walletAddress'),
            value: transaction?.destinationCryptoAddress || '',
          } as IPlainRow,
          {
            id: 'destinationTag',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.destinationTag'),
            value: transaction.destinationTag || '',
          } as IPlainRow,
        ].filter(i => !!i.value),
      },
    ];
  }
}
