import { AvatarCellProps } from 'ui-interfaces/users/avatar-cell-props';
import { getAvatarColor } from 'utils/settings/get-avatar-color';

export const getAvatarProps = (name: string): AvatarCellProps => {
  return {
    avatarAbbreviation: `${name.split(' ')[0][0].toLocaleUpperCase()}${name.split(' ')[1][0].toLocaleUpperCase()}`,
    value: name,
    bgColor: getAvatarColor(),
  };
};
