import { styled } from '@mui/material/styles';
import { Skeleton } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  borderRadius: theme.gaps._8px,
  width: pxToRem(144),
  height: pxToRem(144),
  marginRight: pxToRem(16),
  backgroundColor: theme.palette.grey['100'],
}));
