import i18n from 'localizations';
import { CompoundLabel, TransactionsRow } from 'ui-interfaces/row-interfaces/transactions';
import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { get4LastChars } from 'utils/get-4-last-chars';
import { getStandardAmountFormatting } from 'utils/transactions/get-standard-amount-formatting';
import { TransactionBaseViewModel } from '../transaction-base-vm';

export class TransactionManualViewModel extends TransactionBaseViewModel implements TransactionsRow {
  readonly source: CompoundLabel;

  readonly destination: CompoundLabel;

  readonly statusTooltipMessage?: string;

  constructor(transaction: TransactionResponseModel) {
    super(transaction);
    this.source = {
      start: transaction.sourceDisplayValue
        ? `*${get4LastChars(transaction.sourceDisplayValue)}`
        : i18n.t('transactionsPage.noData'),
      end: transaction.sourceDisplayValue ? i18n.t('transactionsPage.account') : '',
    } as CompoundLabel;
    this.destination = {
      start: transaction.destinationDisplayValue
        ? `*${get4LastChars(transaction.destinationDisplayValue)}`
        : i18n.t('transactionsPage.noData'),
      end: transaction.destinationDisplayValue ? i18n.t('transactionsPage.account') : '',
    } as CompoundLabel;
    this.amount = getStandardAmountFormatting(transaction);
  }
}
