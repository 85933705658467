import { TransferOptionsEnum } from 'elements/element-transfer/types';

const getTransferOptionsList = (param?: string) => {
  const fullTransferOptionsArray = Object.values(TransferOptionsEnum);

  if (!param || param === 'all') {
    return fullTransferOptionsArray;
  }

  const transferOptionsArrayFromParam = param.toLowerCase().split('+');

  const filteredTransferOptionsArray = fullTransferOptionsArray.filter(option =>
    transferOptionsArrayFromParam.includes(option),
  );

  return filteredTransferOptionsArray.length ? filteredTransferOptionsArray : fullTransferOptionsArray;
};

export { getTransferOptionsList };
