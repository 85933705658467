import React from 'react';
import { mapAssetByNetworkIconSizeToNumber } from 'utils/multi-mapping/asset-by-network-icon-size/map-asset-by-network-icon-size-to-number';
import { AssetByNetworkIconSize } from 'ui-enums/asset-by-network-icon-size';
import { SvgStaticImage } from 'components/shared/SvgStaticImage';
import { getStaticDefaultIconComponentForTransaction } from './get-static-default-icon-component-for-transaction';

export const getStaticDefaultIconForTransaction = (
  assetTicker: string,
  size: AssetByNetworkIconSize,
  isPrivateSecurity = false,
) => {
  const defaultIconComponent = getStaticDefaultIconComponentForTransaction(assetTicker, isPrivateSecurity);

  return <SvgStaticImage icon={defaultIconComponent} size={mapAssetByNetworkIconSizeToNumber(size)} />;
};
