import React from 'react';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import { pxToRem } from 'utils/styling-utils';
import { GridCellExpand } from 'components/shared/Cells/GridCellExpand';
import { StyledTypography } from './styled';

type Props = {
  value: string;
  tooltipMessage: string;
  width: number;
};

export const OwnerCell: React.FC<Props> = ({ value, tooltipMessage, width }) => {
  if (!tooltipMessage) {
    return <GridCellExpand value={value} width={width} />;
  }

  return (
    <CommonTooltip title={tooltipMessage} placement="top">
      <StyledTypography
        sx={{
          maxWidth: pxToRem(width),
        }}
      >
        {value}
      </StyledTypography>
    </CommonTooltip>
  );
};
