import React from 'react';
import { ReactComponent as ImageIcon } from 'assets/icons/tokens/small/image-type.svg';
import { ReactComponent as GifIcon } from 'assets/icons/tokens/small/gif-type.svg';
import { ReactComponent as AudioIcon } from 'assets/icons/tokens/small/audio-type.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/tokens/small/video-type.svg';
import { ReactComponent as FileIcon } from 'assets/icons/tokens/small/pdf-type.svg';
import { ReactComponent as OtherIcon } from 'assets/icons/tokens/small/other-type.svg';
import { ReactComponent as Graphics3DIcon } from 'assets/icons/tokens/small/3D-type.svg';
import { FileType } from 'ui-enums/file-type';

export const getFileTypeIcon = (fileType: FileType): React.ReactNode => {
  switch (fileType) {
    case FileType['image/jpeg']:
    case FileType['image/tiff']:
    case FileType['image/webp']:
    case FileType['image/svg+xml']:
    case FileType['image/png']:
      return <ImageIcon />;
    case FileType['image/gif']:
      return <GifIcon />;
    case FileType['audio/aac']:
    case FileType['audio/aiff']:
    case FileType['audio/flac']:
    case FileType['audio/mpeg']:
    case FileType['audio/ogg']:
    case FileType['audio/wav']:
    case FileType['audio/x-aiff']:
    case FileType['audio/x-aac']:
    case FileType['audio/x-flac']:
      return <AudioIcon />;
    case FileType['video/mp4']:
    case FileType['video/quicktime']:
    case FileType['video/webm']:
    case FileType['video/x-matroska']:
    case FileType['video/x-msvideo']:
      return <VideoIcon />;
    case FileType['application/pdf']:
    case FileType['application/msword']:
    case FileType['text/plain']:
      return <FileIcon />;
    case FileType['model/obj']:
    case FileType['application/x-3ds']:
    case FileType['image/x-3ds']:
    case FileType['application/x-blender']:
    case FileType['model/stl']:
    case FileType['model/x.stl-binary']:
    case FileType['model/x.stl-ascii']:
    case FileType['model/gltf+json']:
    case FileType['model/gltf-binary']:
      return <Graphics3DIcon />;
    default:
      return <OtherIcon />;
  }
};
