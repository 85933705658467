import React, { useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { rawCountriesObjectByCountryCode } from 'elements/constants';
import useDebounce from 'elements/hooks/useDebounce';
import { BaseInputProps } from 'elements/components/BaseInput';
import { BaseSelect } from 'elements/components/BaseSelect';
import { BaseSelectItem, BaseSelectSubheader } from 'elements/components/BaseSelect/styled';
import { CountryImage } from 'elements/components/CountryImage';
import { SearchInput } from 'elements/components/SearchInput';

type Props = BaseInputProps & {
  searchPlaceholder?: string;
  excludedCountries?: (keyof typeof rawCountriesObjectByCountryCode)[];
};

export const CountryDropdown: React.FC<Props> = React.memo(
  ({ searchPlaceholder, excludedCountries = [], ...otherProps }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const doesTextContainSearchQuery = (searchedText: string, searchedQuery: string): boolean =>
      searchedText.toLowerCase().indexOf(searchedQuery.toLowerCase()) > -1;

    const debouncedSearchInputHandler = useDebounce(searchQuery, 200);

    const filteredCountriesBySearchQuery = useMemo(
      () =>
        Object.values(rawCountriesObjectByCountryCode).filter(
          option =>
            !excludedCountries?.includes(option.code as keyof typeof rawCountriesObjectByCountryCode) &&
            doesTextContainSearchQuery(option.label, debouncedSearchInputHandler),
        ),
      [debouncedSearchInputHandler, excludedCountries],
    );

    const handleCloseSelect = () => {
      setSearchQuery('');
    };

    const preventFocusOnFirstFoundItem = (event: React.KeyboardEvent<HTMLInputElement>) => event.stopPropagation();

    return (
      <BaseSelect
        onClose={handleCloseSelect}
        shouldExcludeTopPadding
        renderValueHandler={(countryCode: keyof typeof rawCountriesObjectByCountryCode) => {
          const country = rawCountriesObjectByCountryCode[countryCode];

          return (
            <Box display="flex" alignItems="center">
              <CountryImage code={country.code} label={country.label} />
              {country.label}
            </Box>
          );
        }}
        {...otherProps}
      >
        <BaseSelectSubheader>
          <SearchInput
            searchQuery={searchQuery}
            onSearch={setSearchQuery}
            placeholder={searchPlaceholder}
            onKeyDown={preventFocusOnFirstFoundItem}
          />
        </BaseSelectSubheader>
        {filteredCountriesBySearchQuery.map(option => (
          <BaseSelectItem key={option.code} value={option.code} sx={{ marginBottom: 1 }}>
            <Box display="flex" alignItems="center">
              <CountryImage code={option.code} label={option.label} />
              <Typography variant="bodyDefaultBook" color="primary.font">
                {option.label}
              </Typography>
            </Box>
          </BaseSelectItem>
        ))}
      </BaseSelect>
    );
  },
);
