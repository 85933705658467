import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { mainApi } from 'redux/api/main-api';
import { API_ROUTES } from 'redux/api/api-routes';
import { CollectionResponse, Paginated } from 'redux/api/types';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { getRouteWithParams } from 'utils/get-route-with-params';
import { UserDetailResponseModel } from 'models/response/users/user-detail-response-model';
import { UserBaseResponseModel } from 'models/response/users/user-base-response-model';
import {
  FIRST_PAGE,
  DEFAULT_PAGE_SIZE,
  CREATE_USER_TAG,
  DELETE_USER_BY_ID_TAG,
  GET_USER_BY_ID_TAG,
  GET_USERS_TAG,
} from 'redux/api/constants';
import { FilterResult } from 'filters/interfaces/filter-result';
import { UserRoleTemplateResponseModel } from 'models/response/users/user-role-template-response-model';
import { CreateUserRequestModel } from 'models/request/users/create-user-request-model';
import { UpdateUserDetailRequestModel } from 'models/request/users/update-user-detail-request-model';

export interface UserByIdApiParams {
  userId: string;
}

export interface UsersApiParams extends Paginated {
  filterResult?: FilterResult;
}

type EditUserArgument = {
  userId: string;
  data: UpdateUserDetailRequestModel;
  skipInvalidateUserByIdTag?: boolean;
};

const usersApi = mainApi.injectEndpoints({
  endpoints: build => ({
    getUser: build.query<ShortUserResponseModel, undefined>({
      query: () => API_ROUTES.private.USERS_ME,
    }),
    createUser: build.mutation<undefined, CreateUserRequestModel>({
      query: (data: CreateUserRequestModel) => ({
        url: API_ROUTES.private.USERS,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [CREATE_USER_TAG],
    }),
    editUser: build.mutation<undefined, EditUserArgument>({
      query: ({ userId, data }) => ({
        url: getRouteWithParams(API_ROUTES.private.USER_DETAILS, { userId }),
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (res: undefined, err: FetchBaseQueryError | undefined, arg): any => {
        if (err) {
          return undefined;
        }
        return arg.skipInvalidateUserByIdTag ? [GET_USERS_TAG] : [GET_USER_BY_ID_TAG, GET_USERS_TAG];
      },
    }),
    getUserById: build.query<UserDetailResponseModel, UserByIdApiParams>({
      query: ({ userId }) => getRouteWithParams(API_ROUTES.private.USER_DETAILS, { userId }),
      providesTags: [GET_USER_BY_ID_TAG, DELETE_USER_BY_ID_TAG],
    }),
    deleteUserByIdUsersList: build.mutation<undefined, UserByIdApiParams>({
      query: ({ userId }) => ({
        url: getRouteWithParams(API_ROUTES.private.USER_DETAILS, { userId }),
        method: 'DELETE',
      }),
      invalidatesTags: [DELETE_USER_BY_ID_TAG],
    }),
    deleteUserByIdUserDetails: build.mutation<undefined, UserByIdApiParams>({
      query: ({ userId }) => ({
        url: getRouteWithParams(API_ROUTES.private.USER_DETAILS, { userId }),
        method: 'DELETE',
      }),
    }),
    getUsers: build.query<CollectionResponse<UserBaseResponseModel>, UsersApiParams>({
      query: ({ page = FIRST_PAGE, pageSize = DEFAULT_PAGE_SIZE, filterResult }) => {
        if (filterResult) {
          const { property, operator, value } = filterResult as FilterResult;

          return {
            url: API_ROUTES.private.USERS,
            params: { page, pageSize, [`filter[${property} ${operator}]`]: value.trim() },
          };
        }
        return {
          url: API_ROUTES.private.USERS,
          params: { page, pageSize },
        };
      },
      providesTags: [GET_USERS_TAG, CREATE_USER_TAG, DELETE_USER_BY_ID_TAG],
    }),
    resetPassword: build.mutation<undefined, UserByIdApiParams>({
      query: ({ userId }) => {
        return { url: getRouteWithParams(API_ROUTES.private.RESET_PASSWORD, { userId }), method: 'POST' };
      },
    }),
    closeJWT: build.query<undefined, undefined>({
      query: () => {
        return { url: API_ROUTES.private.CLOSE_JWT, method: 'PATCH' };
      },
    }),
    getRoleTemplates: build.query<CollectionResponse<UserRoleTemplateResponseModel>, undefined>({
      query: () => {
        return { url: API_ROUTES.private.USER_ROLE_TEMPLATES };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetUserQuery,
  useLazyGetUserByIdQuery,
  useLazyGetUsersQuery,
  endpoints: { getUser, getUserById, getRoleTemplates },
  useResetPasswordMutation,
  useCreateUserMutation,
  useEditUserMutation,
  useDeleteUserByIdUsersListMutation,
  useDeleteUserByIdUserDetailsMutation,
  useLazyCloseJWTQuery,
  useLazyGetRoleTemplatesQuery,
} = usersApi;
