import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';
import {
  CODE_CELL_WIDTH,
  COMMON_CELL_HEIGHT,
  LARGE_LEFT_CELL_WIDTH,
} from 'components/shared/detail-page/rows/shared/constants';

export const StyledBox = styled(Box)(() => {
  return {
    height: pxToRem(COMMON_CELL_HEIGHT),
    width: pxToRem(LARGE_LEFT_CELL_WIDTH),
    display: 'flex',
    alignItems: 'center',
  };
});

export const CodeBox = styled(Box)(() => {
  return {
    height: pxToRem(COMMON_CELL_HEIGHT),
    width: pxToRem(CODE_CELL_WIDTH),
    display: 'flex',
    alignItems: 'center',
  };
});

export const LabelBox = styled(Box)(() => {
  return {
    height: pxToRem(COMMON_CELL_HEIGHT),
    width: pxToRem(LARGE_LEFT_CELL_WIDTH - CODE_CELL_WIDTH),
    display: 'flex',
    alignItems: 'center',
  };
});
