import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Button, Stack } from 'elements/components';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { LayoutModalProps } from 'elements/components/LayoutModal';
import { StyledLayoutModal } from './styled';

export type LayoutProps = {
  footerButtons?: React.ReactNode;
  withCloseButton?: boolean;
} & LayoutModalProps;

const Layout: React.FC<LayoutProps> = ({
  children,
  withFooter = true,
  footerButtons,
  withCloseButton = true,
  onClose,
  ...props
}) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  const onCloseButtonClick = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <StyledLayoutModal
      withFooter={withFooter}
      onClose={!isMobile ? onCloseButtonClick : undefined}
      subFooter={
        (footerButtons || (isMobile && withCloseButton)) && (
          <Stack fullWidth gap="16px">
            {footerButtons}
            {isMobile && withCloseButton && (
              <Button buttonType="tertiary" onClick={onCloseButtonClick}>
                <Typography variant="buttonLarge">{t('elements.common.cancel')}</Typography>
              </Button>
            )}
          </Stack>
        )
      }
      {...props}
    >
      {children}
    </StyledLayoutModal>
  );
};
export default Layout;
