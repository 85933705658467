import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { ResetPasswordButton } from 'components/settings/users/UserDetailsPageContent/layout/Actions/buttons/ResetPasswordButton';
import { useResetPasswordMutation } from 'redux/api/api-users';
import { setBannerData, MessageType } from 'redux/banner';

type Props = {
  userId: string;
};
export const ResetPasswordButtonContainer: React.FC<Props> = ({ userId }) => {
  const dispatch = useDispatch();
  const [resetPasswordTrigger, resetPasswordResult] = useResetPasswordMutation();

  useEffect(() => {
    if (resetPasswordResult.isLoading) {
      return;
    }
    if (resetPasswordResult.isSuccess) {
      dispatch(
        setBannerData({
          type: MessageType.success,
          message: t('settingsPage.banner.resetPasswordSuccessMessage'),
        }),
      );
    }
  }, [dispatch, resetPasswordResult]);

  const onResetClick = () => {
    resetPasswordTrigger({ userId });
  };
  return <ResetPasswordButton isLoading={resetPasswordResult.isLoading} onClick={onResetClick} />;
};
