import dayjs from 'dayjs';
import i18n from 'localizations';
import { Filter } from 'filters/interfaces/filter';
import { SingleValueFilter } from 'filters/single-value-filter';
import { Operators } from 'filters/operators';
import { TransactionsFilterProperty } from 'ui-enums/transactions/transactions-filter-property';
import { DateFilter } from 'filters/date-filter';
import { MultiValueFilter } from 'filters/multi-value-filter';
import { TransactionsValueSets } from 'filters/common-value-sets/transactions-value-sets';

export const createRejectedTransactionsFilters: () => Filter[] = () => [
  // new SingleValueFilter({
  //   property: TransactionsFilterProperty.id,
  //   displayProperty: i18n.t('transactionsPage.transactionId'),
  //   operators: [Operators.equals],
  //   operatorsDisplayValues: [i18n.t('filters.operators.equals')],
  //   initialOperator: Operators.equals,
  //   initialValue: '',
  // }),
  new SingleValueFilter({
    property: TransactionsFilterProperty.paymentId,
    displayProperty: i18n.t('transactionsPage.paymentId'),
    operators: [Operators.equals],
    operatorsDisplayValues: [i18n.t('filters.operators.equals')],
    initialOperator: Operators.equals,
    initialValue: '',
  }),
  new DateFilter({
    property: TransactionsFilterProperty.externalCreatedAt,
    displayProperty: i18n.t('transactionsPage.date'),
    operators: [Operators.equals, Operators.greaterThan, Operators.lessThan],
    operatorsDisplayValues: [
      i18n.t('filters.operators.is'),
      i18n.t('filters.operators.isAfter'),
      i18n.t('filters.operators.isBefore'),
    ],
    initialOperator: Operators.equals,
    initialValue: dayjs().format(), // current date
  }),
  new MultiValueFilter({
    property: TransactionsFilterProperty.paymentType,
    displayProperty: i18n.t('transactionsPage.type'),
    operators: [Operators.in],
    operatorsDisplayValues: [i18n.t('filters.operators.equals')],
    initialOperator: Operators.in,
    values: TransactionsValueSets.paymentTypeValueSet.map(el => el.value),
    displayValues: TransactionsValueSets.paymentTypeValueSet.map(el => el.displayValue),
    initialValue: '',
  }),
  // new MultiValueFilter({
  //   property: TransactionsFilterProperty.currency,
  //   displayProperty: i18n.t('transactionsPage.asset'),
  //   operators: [Operators.equals],
  //   operatorsDisplayValues: [i18n.t('filters.operators.equals')],
  //   initialOperator: Operators.equals,
  //   values: TransactionsValueSets.currencyValueSet.map(el => el.value),
  //   displayValues: TransactionsValueSets.currencyValueSet.map(el => el.displayValue),
  //   initialValue: '',
  // }),
  // new SingleValueFilter({
  //   property: TransactionsFilterProperty.amountDecimal,
  //   displayProperty: i18n.t('transactionsPage.amount'),
  //   operators: [Operators.contains],
  //   operatorsDisplayValues: [i18n.t('filters.operators.contains')],
  //   initialOperator: Operators.contains,
  //   initialValue: '',
  // }),
  // new SingleValueFilter({
  //   property: TransactionsFilterProperty.sourceDisplayValue,
  //   displayProperty: i18n.t('transactionsPage.source'),
  //   operators: [Operators.contains, Operators.like],
  //   operatorsDisplayValues: [i18n.t('filters.operators.contains'), i18n.t('filters.operators.equals')],
  //   initialOperator: Operators.contains,
  //   initialValue: '',
  //   valueFormatter: removeAllSpaces,
  // }),
  // new SingleValueFilter({
  //   property: TransactionsFilterProperty.destinationDisplayValue,
  //   displayProperty: i18n.t('transactionsPage.destination'),
  //   operators: [Operators.contains, Operators.like],
  //   operatorsDisplayValues: [i18n.t('filters.operators.contains'), i18n.t('filters.operators.equals')],
  //   initialOperator: Operators.contains,
  //   initialValue: '',
  //   valueFormatter: removeAllSpaces,
  // }),
];
