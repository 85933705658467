import i18n from 'localizations';
import { TransactionDetails } from 'ui-interfaces/transaction-details/transaction-details';
import { TransactionDetailResponseModel } from 'models/response/transactions/transaction-detail-response-model';
import { formatDateAndTimeInUTC } from 'utils/format-date';
import { TransactionDetailsBaseViewModel } from 'view-models/transactions/details/transaction-details-base-vm';
import { addSpaceAfterEvery4Chars } from 'utils/add-space-after-every-4-chars';
import { formatAddress } from 'containers/identities/utils/format-address';
import { RowType } from 'ui-enums/transaction-detail/row-type';
import { ITextRow } from 'ui-interfaces/transaction-details/rows/i-text-row';
import { IPaymentTypeRow } from 'ui-interfaces/transaction-details/rows/i-payment-type-row';
import { getOverviewSectionBasicProps } from 'utils/transactions/detail/get-overview-section-basic-props';
import { IPlainSection } from 'ui-interfaces/transaction-details/sections/i-plain-section';
import { PdfRowType } from 'ui-enums/pdf/pdf-row-type';
import { IPlainRow } from 'ui-interfaces/transaction-details/pdf/rows/i-plain-row';
import { getCurrencyRowPropsForFiat } from 'utils/transactions/detail/get-currency-row-props-for-fiat';
import { getTransactionStatusLabel } from 'utils/labels-mapping/get-transaction-status-label';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';

export class TransactionDetailsWithdrawalWireReturnViewModel
  extends TransactionDetailsBaseViewModel
  implements TransactionDetails
{
  constructor(transaction: TransactionDetailResponseModel) {
    super(transaction);

    this.sections = [
      {
        ...getOverviewSectionBasicProps(),
        currencyRow: getCurrencyRowPropsForFiat(transaction, this.assetIconSize),
        nodes: [
          {
            id: 'infoSection',
            blockItems: [
              {
                id: 'transactionId',
                label: i18n.t('transactionDetailsPage.labels.transactionId'),
                uiElementType: RowType.text,
                value: transaction.externalId || i18n.t('transactionDetailsPage.labels.noData'),
              } as ITextRow,
              {
                id: 'paymentId',
                label: i18n.t('transactionDetailsPage.labels.paymentId'),
                uiElementType: RowType.text,
                value: transaction.paymentId,
              } as ITextRow,
              {
                id: 'date',
                label: i18n.t('transactionDetailsPage.labels.date'),
                uiElementType: RowType.text,
                value: `${formatDateAndTimeInUTC(transaction.date)} UTC`,
              } as ITextRow,
              {
                id: 'type',
                label: i18n.t('transactionDetailsPage.labels.type'),
                uiElementType: RowType.paymentType,
                value: i18n.t('transactionDetailsPage.labels.withdrawalReturn'),
                paymentType: transaction.paymentType,
              } as IPaymentTypeRow,
              {
                id: 'paymentMethod',
                label: i18n.t('transactionDetailsPage.labels.paymentMethod'),
                uiElementType: RowType.text,
                value: i18n.t('transactionDetailsPage.labels.domesticWire'),
              } as ITextRow,
              {
                id: 'comment',
                label: i18n.t('transactionDetailsPage.labels.comment'),
                uiElementType: RowType.textMultiLine,
                value: transaction?.comment || '',
              } as ITextRow,
            ].filter(i => !!i.value),
          },
          {
            id: 'sourceSection',
            blockHeaderLabel: i18n.t('transactionDetailsPage.source'),
            blockItems: [
              {
                id: 'accountNumber',
                label: i18n.t('transactionDetailsPage.labels.externalAccountNumber'),
                uiElementType: RowType.text,
                value: `*${transaction.sourceAccountNumber || ''}`,
              } as ITextRow,
              {
                id: 'accountOwner',
                label: i18n.t('transactionDetailsPage.labels.accountOwner'),
                uiElementType: RowType.text,
                value: transaction.sourceOwnerIdentityDisplayName,
              } as ITextRow,
              {
                id: 'routingNumber',
                label: i18n.t('transactionDetailsPage.labels.routingNumber'),
                uiElementType: RowType.text,
                value: transaction.sourceWireDetails?.routingNumber || '',
              } as ITextRow,
              {
                id: 'swift',
                label: i18n.t('transactionDetailsPage.labels.swift'),
                uiElementType: RowType.text,
                value: transaction.sourceWireDetails?.swift || '',
              } as ITextRow,
              {
                id: 'wireDetailsAddress',
                label: i18n.t('transactionDetailsPage.labels.address'),
                uiElementType: RowType.text,
                value: formatAddress(transaction?.sourceWireDetails?.address),
              } as ITextRow,
              {
                id: 'bankName',
                label: i18n.t('transactionDetailsPage.labels.bankName'),
                uiElementType: RowType.text,
                value: transaction.sourceWireDetails?.bankName || '',
              } as ITextRow,
              {
                id: 'bankAddress',
                label: i18n.t('transactionDetailsPage.labels.bankAddress'),
                uiElementType: RowType.text,
                value: formatAddress(transaction.sourceWireDetails?.bankAddress),
              } as ITextRow,
            ].filter(i => !!i.value),
          },
          {
            id: 'destinationSection',
            blockHeaderLabel: i18n.t('transactionDetailsPage.destination'),
            blockItems: [
              {
                id: 'custodialAccountNumber',
                label: i18n.t('transactionDetailsPage.labels.accountNumber'),
                uiElementType: RowType.text,
                value: addSpaceAfterEvery4Chars(transaction.destinationAccountNumber || ''),
              } as ITextRow,
              {
                id: 'accountOwner',
                label: i18n.t('transactionDetailsPage.labels.accountOwner'),
                uiElementType: RowType.text,
                value: transaction.destinationOwnerIdentityDisplayName,
              } as ITextRow,
            ].filter(i => !!i.value),
          },
        ],
      } as IPlainSection,
    ];

    this.pdfNodes = [
      {
        id: 'infoSection',
        title: i18n.t('transactionDetailsPage.labels.general'),
        items: [
          {
            id: 'amount',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.amountBlockHeaderLabel'),
            value: getCurrencyRowPropsForFiat(transaction, this.assetIconSize).label,
          } as IPlainRow,
          {
            id: 'status',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.status'),
            value: getTransactionStatusLabel(transaction.status as TransactionStatus),
          } as IPlainRow,
          {
            id: 'transactionId',
            label: i18n.t('transactionDetailsPage.labels.transactionId'),
            rowType: PdfRowType.plain,
            value: transaction.externalId || i18n.t('transactionDetailsPage.labels.noData'),
          } as IPlainRow,
          {
            id: 'paymentId',
            label: i18n.t('transactionDetailsPage.labels.paymentId'),
            rowType: PdfRowType.plain,
            value: transaction.paymentId,
          } as IPlainRow,
          {
            id: 'date',
            label: i18n.t('transactionDetailsPage.labels.date'),
            rowType: PdfRowType.plain,
            value: `${formatDateAndTimeInUTC(transaction.date)} UTC`,
          } as IPlainRow,
          {
            id: 'type',
            label: i18n.t('transactionDetailsPage.labels.type'),
            rowType: PdfRowType.plain,
            value: i18n.t('transactionDetailsPage.labels.withdrawalReturn'),
            paymentType: transaction.paymentType,
          } as IPlainRow,
          {
            id: 'paymentMethod',
            label: i18n.t('transactionDetailsPage.labels.paymentMethod'),
            rowType: PdfRowType.plain,
            value: i18n.t('transactionDetailsPage.labels.domesticWire'),
          } as IPlainRow,
          {
            id: 'comment',
            label: i18n.t('transactionDetailsPage.labels.comment'),
            rowType: PdfRowType.plain,
            value: transaction.comment || '',
          } as IPlainRow,
        ].filter(i => !!i.value),
      },
      {
        id: 'sourceSection',
        title: i18n.t('transactionDetailsPage.source'),
        items: [
          {
            id: 'accountNumber',
            label: i18n.t('transactionDetailsPage.labels.externalAccountNumber'),
            rowType: PdfRowType.plain,
            value: `*${transaction.sourceAccountNumber || ''}`,
          } as IPlainRow,
          {
            id: 'accountOwner',
            label: i18n.t('transactionDetailsPage.labels.accountOwner'),
            rowType: PdfRowType.plain,
            value: transaction.sourceOwnerIdentityDisplayName,
          } as IPlainRow,
          {
            id: 'routingNumber',
            label: i18n.t('transactionDetailsPage.labels.routingNumber'),
            rowType: PdfRowType.plain,
            value: transaction.sourceWireDetails?.routingNumber || '',
          } as IPlainRow,
          {
            id: 'swift',
            label: i18n.t('transactionDetailsPage.labels.swift'),
            rowType: PdfRowType.plain,
            value: transaction?.sourceWireDetails?.swift || '',
          } as IPlainRow,
          {
            id: 'wireDetailsAddress',
            label: i18n.t('transactionDetailsPage.labels.address'),
            rowType: PdfRowType.plain,
            value: formatAddress(transaction.sourceWireDetails?.address),
          } as IPlainRow,
          {
            id: 'bankName',
            label: i18n.t('transactionDetailsPage.labels.bankName'),
            rowType: PdfRowType.plain,
            value: transaction.sourceWireDetails?.bankName ?? '',
          } as IPlainRow,
          {
            id: 'bankAddress',
            label: i18n.t('transactionDetailsPage.labels.bankAddress'),
            rowType: PdfRowType.plain,
            value: formatAddress(transaction.sourceWireDetails?.bankAddress),
          } as IPlainRow,
        ].filter(i => !!i.value),
      },
      {
        id: 'destinationSection',
        title: i18n.t('transactionDetailsPage.destination'),
        items: [
          {
            id: 'custodialAccountNumber',
            label: i18n.t('transactionDetailsPage.labels.accountNumber'),
            rowType: PdfRowType.plain,
            value: addSpaceAfterEvery4Chars(transaction.destinationAccountNumber || ''),
          } as IPlainRow,
          {
            id: 'accountOwner',
            label: i18n.t('transactionDetailsPage.labels.accountOwner'),
            rowType: PdfRowType.plain,
            value: transaction.destinationOwnerIdentityDisplayName,
          } as IPlainRow,
        ].filter(i => !!i.value),
      },
    ];
  }
}
