import { LayoutModal } from 'elements/components';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledLayoutModal = elementsStyled(LayoutModal)`
  & h4 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
