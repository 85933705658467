import { css } from '@mui/material';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledFieldset = elementsStyled('fieldset')(css`
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-inline: 0;
  padding-block: 0;
  margin-inline: 0;

  legend {
    margin-bottom: 16px;
  }
`);
