import i18n from 'localizations';
import { TransactionDetailNode } from 'ui-interfaces/transaction-details/transaction-detail-node';
import { Mt103ReferenceBaseModel } from 'models/response/transactions/mt103/mt-103-reference-base-model';
import { IMt103Row } from 'ui-interfaces/transaction-details/rows/mt103/i-mt103-row';
import { RowType } from 'ui-enums/transaction-detail/row-type';
import { IMt103NoValueRow } from 'ui-interfaces/transaction-details/rows/mt103/i-mt103-no-value-row';
import { IMt103Indent1Row } from 'ui-interfaces/transaction-details/rows/mt103/i-mt103-indent-1-row';
import { IMt103Indent1NoValueRow } from 'ui-interfaces/transaction-details/rows/mt103/i-mt103-indent-1-no-value-row';
import { IMt103Indent2Row } from 'ui-interfaces/transaction-details/rows/mt103/i-mt103-indent-2-row';
import { Valued } from 'ui-interfaces/shared/valued';
import { formatDate } from 'utils/format-date';
import { formatCurrency } from 'utils/format-currency';

const VALUE_DATE_CURRENCY_INTERBANK_SETTLED_AMOUNT = 'valueDateCurrencyInterbankSettledAmount';
const VALUE_DATE_CURRENCY_INTERBANK_SETTLED_AMOUNT__DATE = `${VALUE_DATE_CURRENCY_INTERBANK_SETTLED_AMOUNT}.date`;
const VALUE_DATE_CURRENCY_INTERBANK_SETTLED_AMOUNT__CURRENCY = `${VALUE_DATE_CURRENCY_INTERBANK_SETTLED_AMOUNT}.currency`;
const VALUE_DATE_CURRENCY_INTERBANK_SETTLED_AMOUNT__AMOUNT = `${VALUE_DATE_CURRENCY_INTERBANK_SETTLED_AMOUNT}.amount`;

const CURRENCY_INSTRUCTED_AMOUNT = 'currencyInstructedAmount';
const CURRENCY_INSTRUCTED_AMOUNT__CURRENCY = `${CURRENCY_INSTRUCTED_AMOUNT}.currency`;
const CURRENCY_INSTRUCTED_AMOUNT__AMOUNT = `${CURRENCY_INSTRUCTED_AMOUNT}.amount`;

const ORDERING_CUSTOMER = 'orderingCustomer';
const ORDERING_CUSTOMER__ACCOUNT_IDENTIFIER_CODE = `${ORDERING_CUSTOMER}.accountIdentifierCode`;
const ORDERING_CUSTOMER__ACCOUNT_IDENTIFIER_CODE__ACCOUNT = `${ORDERING_CUSTOMER__ACCOUNT_IDENTIFIER_CODE}.account`;
const ORDERING_CUSTOMER__ACCOUNT_IDENTIFIER_CODE__IDENTIFIER_CODE = `${ORDERING_CUSTOMER__ACCOUNT_IDENTIFIER_CODE}.identifierCode`;
const ORDERING_CUSTOMER__PARTY_IDENTIFIER_NAME_ADDRESS = `${ORDERING_CUSTOMER}.partyIdentifierNameAddress`;
const ORDERING_CUSTOMER__PARTY_IDENTIFIER_NAME_ADDRESS__PARTY_IDENTIFIER = `${ORDERING_CUSTOMER__PARTY_IDENTIFIER_NAME_ADDRESS}.partyIdentifier`;
const ORDERING_CUSTOMER__PARTY_IDENTIFIER_NAME_ADDRESS__NAME_ADDRESS = `${ORDERING_CUSTOMER__PARTY_IDENTIFIER_NAME_ADDRESS}.nameAddress`;
const ORDERING_CUSTOMER__ACCOUNT_NAME_ADDRESS = `${ORDERING_CUSTOMER}.accountNameAddress`;
const ORDERING_CUSTOMER__ACCOUNT_NAME_ADDRESS__ACCOUNT = `${ORDERING_CUSTOMER__ACCOUNT_NAME_ADDRESS}.account`;
const ORDERING_CUSTOMER__ACCOUNT_NAME_ADDRESS__NAME_ADDRESS = `${ORDERING_CUSTOMER__ACCOUNT_NAME_ADDRESS}.nameAddress`;

const BENEFICIARY_CUSTOMER = 'beneficiaryCustomer';
const BENEFICIARY_CUSTOMER__ACCOUNT_IDENTIFIER_CODE = `${BENEFICIARY_CUSTOMER}.accountIdentifierCode`;
const BENEFICIARY_CUSTOMER__ACCOUNT_IDENTIFIER_CODE__ACCOUNT = `${BENEFICIARY_CUSTOMER__ACCOUNT_IDENTIFIER_CODE}.account`;
const BENEFICIARY_CUSTOMER__ACCOUNT_IDENTIFIER_CODE__IDENTIFIER_CODE = `${BENEFICIARY_CUSTOMER__ACCOUNT_IDENTIFIER_CODE}.identifierCode`;
const BENEFICIARY_CUSTOMER__ACCOUNT_NUMBER_NAME_ADDRESS = `${BENEFICIARY_CUSTOMER}.accountNumberNameAddress`;
const BENEFICIARY_CUSTOMER__ACCOUNT_NUMBER_NAME_ADDRESS__ACCOUNT = `${BENEFICIARY_CUSTOMER__ACCOUNT_NUMBER_NAME_ADDRESS}.account`;
const BENEFICIARY_CUSTOMER__ACCOUNT_NUMBER_NAME_ADDRESS__NUMBER = `${BENEFICIARY_CUSTOMER__ACCOUNT_NUMBER_NAME_ADDRESS}.number`;
const BENEFICIARY_CUSTOMER__ACCOUNT_NUMBER_NAME_ADDRESS__NAME_ADDRESS = `${BENEFICIARY_CUSTOMER__ACCOUNT_NUMBER_NAME_ADDRESS}.nameAddress`;

export const generateMt103Nodes = (mt103Reference?: Mt103ReferenceBaseModel): TransactionDetailNode[] => {
  if (!mt103Reference) {
    return [];
  }

  let shouldShowValueDateCurrencyInterbankSettledAmount = false;
  let shouldShowCurrencyInstructedAmount = false;

  let shouldShowOrderingCustomer = false;
  let shouldShowOrderingCustomerAccountIdentifierCode = false;
  let shouldShowOrderingCustomerPartyIdentifierNameAddress = false;
  let shouldShowOrderingCustomerAccountNameAddress = false;

  let shouldShowBeneficiaryCustomer = false;
  let shouldShowBeneficiaryCustomerAccountIdentifierCode = false;
  let shouldShowBeneficiaryCustomerAccountNumberNameAddress = false;

  return [
    {
      id: 'mt103',
      blockItems: [
        {
          id: 'sendersReference',
          uiElementType: RowType.mt103,
          code: '20',
          label: i18n.t('transactionDetailsPage.mt103.sendersReference'),
          value: mt103Reference.sendersReference,
        } as IMt103Row,
        {
          id: 'timeIndication',
          uiElementType: RowType.mt103,
          code: '13C',
          label: i18n.t('transactionDetailsPage.mt103.timeIndication'),
          value: mt103Reference.timeIndication ?? '',
        } as IMt103Row,
        {
          id: 'bankOperationCode',
          uiElementType: RowType.mt103,
          code: '23B',
          label: i18n.t('transactionDetailsPage.mt103.bankOperationCode'),
          value: mt103Reference.bankOperationCode,
        } as IMt103Row,
        {
          id: 'instructionCode',
          uiElementType: RowType.mt103,
          code: '23E',
          label: i18n.t('transactionDetailsPage.mt103.instructionCode'),
          value: mt103Reference.instructionCode ?? '',
        } as IMt103Row,
        {
          id: 'transactionTypeCode',
          uiElementType: RowType.mt103,
          code: '26T',
          label: i18n.t('transactionDetailsPage.mt103.transactionTypeCode'),
          value: mt103Reference.transactionTypeCode ?? '',
        } as IMt103Row,
        // valueDateCurrencyInterbankSettledAmount - start
        {
          id: VALUE_DATE_CURRENCY_INTERBANK_SETTLED_AMOUNT,
          uiElementType: RowType.mt103NoValue,
          code: '32A',
          label: i18n.t('transactionDetailsPage.mt103.valueDateCurrencyInterbankSettledAmount'),
        } as IMt103NoValueRow,
        {
          id: VALUE_DATE_CURRENCY_INTERBANK_SETTLED_AMOUNT__DATE,
          uiElementType: RowType.mt103Indent1,
          label: i18n.t('transactionDetailsPage.mt103.date'),
          value: mt103Reference.valueDate_currency_interbankSettledAmount.date
            ? formatDate(mt103Reference.valueDate_currency_interbankSettledAmount.date)
            : '',
        } as IMt103Indent1Row,
        {
          id: VALUE_DATE_CURRENCY_INTERBANK_SETTLED_AMOUNT__CURRENCY,
          uiElementType: RowType.mt103Indent1,
          label: i18n.t('transactionDetailsPage.mt103.currency'),
          value: mt103Reference.valueDate_currency_interbankSettledAmount.currency ?? '',
        } as IMt103Indent1Row,
        {
          id: VALUE_DATE_CURRENCY_INTERBANK_SETTLED_AMOUNT__AMOUNT,
          uiElementType: RowType.mt103Indent1,
          label: i18n.t('transactionDetailsPage.mt103.amount'),
          value: mt103Reference.valueDate_currency_interbankSettledAmount.amount
            ? formatCurrency(parseFloat(mt103Reference.valueDate_currency_interbankSettledAmount.amount))
            : '',
        } as IMt103Indent1Row,
        // valueDateCurrencyInterbankSettledAmount - end
        // currencyInstructedAmount - start
        {
          id: CURRENCY_INSTRUCTED_AMOUNT,
          uiElementType: RowType.mt103NoValue,
          code: '33B',
          label: i18n.t('transactionDetailsPage.mt103.currencyInstructedAmount'),
        } as IMt103NoValueRow,
        {
          id: CURRENCY_INSTRUCTED_AMOUNT__CURRENCY,
          uiElementType: RowType.mt103Indent1,
          label: i18n.t('transactionDetailsPage.mt103.currency'),
          value: mt103Reference.currency_instructedAmount?.currency ?? '',
        } as IMt103Indent1Row,
        {
          id: CURRENCY_INSTRUCTED_AMOUNT__AMOUNT,
          uiElementType: RowType.mt103Indent1,
          label: i18n.t('transactionDetailsPage.mt103.amount'),
          value: mt103Reference.currency_instructedAmount?.amount
            ? formatCurrency(parseFloat(mt103Reference.currency_instructedAmount?.amount))
            : '',
        } as IMt103Indent1Row,
        // currencyInstructedAmount - end
        {
          id: 'exchangeRate',
          uiElementType: RowType.mt103,
          code: '36',
          label: i18n.t('transactionDetailsPage.mt103.exchangeRate'),
          value: mt103Reference.exchangeRate ?? '',
        } as IMt103Row,
        // orderingCustomer - start
        {
          id: ORDERING_CUSTOMER,
          uiElementType: RowType.mt103NoValue,
          code: '50a',
          label: i18n.t('transactionDetailsPage.mt103.orderingCustomer'),
        } as IMt103NoValueRow,
        {
          id: ORDERING_CUSTOMER__ACCOUNT_IDENTIFIER_CODE,
          uiElementType: RowType.mt103Indent1NoValue,
          label: i18n.t('transactionDetailsPage.mt103.accountIdentifierCode'),
        } as IMt103Indent1NoValueRow,
        {
          id: ORDERING_CUSTOMER__ACCOUNT_IDENTIFIER_CODE__ACCOUNT,
          uiElementType: RowType.mt103Indent2,
          label: i18n.t('transactionDetailsPage.mt103.account'),
          value: mt103Reference.orderingCustomer.account_identifierCode?.account ?? '',
        } as IMt103Indent2Row,
        {
          id: ORDERING_CUSTOMER__ACCOUNT_IDENTIFIER_CODE__IDENTIFIER_CODE,
          uiElementType: RowType.mt103Indent2,
          label: i18n.t('transactionDetailsPage.mt103.identifierCode'),
          value: mt103Reference.orderingCustomer.account_identifierCode?.identifierCode ?? '',
        } as IMt103Indent2Row,
        {
          id: ORDERING_CUSTOMER__PARTY_IDENTIFIER_NAME_ADDRESS,
          uiElementType: RowType.mt103Indent1NoValue,
          label: i18n.t('transactionDetailsPage.mt103.partyIdentifierNameAddress'),
        } as IMt103Indent1NoValueRow,
        {
          id: ORDERING_CUSTOMER__PARTY_IDENTIFIER_NAME_ADDRESS__PARTY_IDENTIFIER,
          uiElementType: RowType.mt103Indent2,
          label: i18n.t('transactionDetailsPage.mt103.partyIdentifier'),
          value: mt103Reference.orderingCustomer.partyIdentifier_nameAddress?.partyIdentifier ?? '',
        } as IMt103Indent2Row,
        {
          id: ORDERING_CUSTOMER__PARTY_IDENTIFIER_NAME_ADDRESS__NAME_ADDRESS,
          uiElementType: RowType.mt103Indent2,
          label: i18n.t('transactionDetailsPage.mt103.nameAddress'),
          value: mt103Reference.orderingCustomer.partyIdentifier_nameAddress?.nameAddress ?? '',
        } as IMt103Indent2Row,
        {
          id: ORDERING_CUSTOMER__ACCOUNT_NAME_ADDRESS,
          uiElementType: RowType.mt103Indent1NoValue,
          label: i18n.t('transactionDetailsPage.mt103.accountNameAddress'),
        } as IMt103Indent1NoValueRow,
        {
          id: ORDERING_CUSTOMER__ACCOUNT_NAME_ADDRESS__ACCOUNT,
          uiElementType: RowType.mt103Indent2,
          label: i18n.t('transactionDetailsPage.mt103.account'),
          value: mt103Reference.orderingCustomer.account_nameAddress?.account ?? '',
        } as IMt103Indent2Row,
        {
          id: ORDERING_CUSTOMER__ACCOUNT_NAME_ADDRESS__NAME_ADDRESS,
          uiElementType: RowType.mt103Indent2,
          label: i18n.t('transactionDetailsPage.mt103.nameAddress'),
          value: mt103Reference.orderingCustomer.account_nameAddress?.nameAddress ?? '',
        } as IMt103Indent2Row,
        // orderingCustomer - end
        {
          id: 'sendingInstitution',
          uiElementType: RowType.mt103,
          code: '51A',
          label: i18n.t('transactionDetailsPage.mt103.sendingInstitution'),
          value: mt103Reference.sendingInstitution ?? '',
        } as IMt103Row,
        {
          id: 'orderingInstitution',
          uiElementType: RowType.mt103,
          code: '52a',
          label: i18n.t('transactionDetailsPage.mt103.orderingInstitution'),
          value: mt103Reference.orderingInstitution ?? '',
        } as IMt103Row,
        {
          id: 'sendersCorrespondent',
          uiElementType: RowType.mt103,
          code: '53a',
          label: i18n.t('transactionDetailsPage.mt103.sendersCorrespondent'),
          value: mt103Reference.sendersCorrespondent ?? '',
        } as IMt103Row,
        {
          id: 'receiversCorrespondent',
          uiElementType: RowType.mt103,
          code: '54a',
          label: i18n.t('transactionDetailsPage.mt103.receiversCorrespondent'),
          value: mt103Reference.receiversCorrespondent ?? '',
        } as IMt103Row,
        {
          id: 'thirdReimbursementInstitution',
          uiElementType: RowType.mt103,
          code: '55a',
          label: i18n.t('transactionDetailsPage.mt103.thirdReimbursementInstitution'),
          value: mt103Reference.thirdReimbursementInstitution ?? '',
        } as IMt103Row,
        {
          id: 'intermediaryInstitution',
          uiElementType: RowType.mt103,
          code: '56a',
          label: i18n.t('transactionDetailsPage.mt103.intermediaryInstitution'),
          value: mt103Reference.intermediaryInstitution ?? '',
        } as IMt103Row,
        {
          id: 'accountWithInstitution',
          uiElementType: RowType.mt103,
          code: '57a',
          label: i18n.t('transactionDetailsPage.mt103.accountWithInstitution'),
          value: mt103Reference.accountWithInstitution ?? '',
        } as IMt103Row,
        // beneficiaryCustomer - start
        {
          id: BENEFICIARY_CUSTOMER,
          uiElementType: RowType.mt103NoValue,
          code: '59a',
          label: i18n.t('transactionDetailsPage.mt103.beneficiaryCustomer'),
        } as IMt103NoValueRow,
        {
          id: BENEFICIARY_CUSTOMER__ACCOUNT_IDENTIFIER_CODE,
          uiElementType: RowType.mt103Indent1NoValue,
          label: i18n.t('transactionDetailsPage.mt103.accountIdentifierCode'),
        } as IMt103Indent1NoValueRow,
        {
          id: BENEFICIARY_CUSTOMER__ACCOUNT_IDENTIFIER_CODE__ACCOUNT,
          uiElementType: RowType.mt103Indent2,
          label: i18n.t('transactionDetailsPage.mt103.account'),
          value: mt103Reference.beneficiaryCustomer.account_identifierCode?.account ?? '',
        } as IMt103Indent2Row,
        {
          id: BENEFICIARY_CUSTOMER__ACCOUNT_IDENTIFIER_CODE__IDENTIFIER_CODE,
          uiElementType: RowType.mt103Indent2,
          label: i18n.t('transactionDetailsPage.mt103.identifierCode'),
          value: mt103Reference.beneficiaryCustomer.account_identifierCode?.identifierCode ?? '',
        } as IMt103Indent2Row,
        {
          id: BENEFICIARY_CUSTOMER__ACCOUNT_NUMBER_NAME_ADDRESS,
          uiElementType: RowType.mt103Indent1NoValue,
          label: i18n.t('transactionDetailsPage.mt103.accountNumberNameAddress'),
        } as IMt103Indent1NoValueRow,
        {
          id: BENEFICIARY_CUSTOMER__ACCOUNT_NUMBER_NAME_ADDRESS__ACCOUNT,
          uiElementType: RowType.mt103Indent2,
          label: i18n.t('transactionDetailsPage.mt103.account'),
          value: mt103Reference.beneficiaryCustomer.account_number_nameAddress?.account ?? '',
        } as IMt103Indent2Row,
        {
          id: BENEFICIARY_CUSTOMER__ACCOUNT_NUMBER_NAME_ADDRESS__NUMBER,
          uiElementType: RowType.mt103Indent2,
          label: i18n.t('transactionDetailsPage.mt103.number'),
          value: mt103Reference.beneficiaryCustomer.account_number_nameAddress?.number ?? '',
        } as IMt103Indent2Row,
        {
          id: BENEFICIARY_CUSTOMER__ACCOUNT_NUMBER_NAME_ADDRESS__NAME_ADDRESS,
          uiElementType: RowType.mt103Indent2,
          label: i18n.t('transactionDetailsPage.mt103.nameAddress'),
          value: mt103Reference.beneficiaryCustomer.account_number_nameAddress?.nameAddress ?? '',
        } as IMt103Indent2Row,
        // beneficiaryCustomer - end
        {
          id: 'remittanceInformation',
          uiElementType: RowType.mt103,
          code: '70',
          label: i18n.t('transactionDetailsPage.mt103.remittanceInformation'),
          value: mt103Reference.remittanceInformation ?? '',
        } as IMt103Row,
        {
          id: 'detailsOfCharges',
          uiElementType: RowType.mt103,
          code: '71A',
          label: i18n.t('transactionDetailsPage.mt103.detailsOfCharges'),
          value: mt103Reference.detailsOfCharges,
        } as IMt103Row,
        {
          id: 'sendersCharges',
          uiElementType: RowType.mt103,
          code: '71F',
          label: i18n.t('transactionDetailsPage.mt103.sendersCharges'),
          value: mt103Reference.sendersCharges ?? '',
        } as IMt103Row,
        {
          id: 'receiversCharges',
          uiElementType: RowType.mt103,
          code: '71G',
          label: i18n.t('transactionDetailsPage.mt103.receiversCharges'),
          value: mt103Reference.receiversCharges ?? '',
        } as IMt103Row,
        {
          id: 'senderToReceiverInformation',
          uiElementType: RowType.mt103,
          code: '72',
          label: i18n.t('transactionDetailsPage.mt103.senderToReceiverInformation'),
          value: mt103Reference.senderToReceiverInformation ?? '',
        } as IMt103Row,
        {
          id: 'regulatoryReporting',
          uiElementType: RowType.mt103,
          code: '77B',
          label: i18n.t('transactionDetailsPage.mt103.regulatoryReporting'),
          value: mt103Reference.regulatoryReporting ?? '',
        } as IMt103Row,
      ]
        .filter(i => {
          if ('value' in i) {
            return !!(i as Valued).value;
          }

          return true;
        })
        .map(i => {
          if (
            [
              VALUE_DATE_CURRENCY_INTERBANK_SETTLED_AMOUNT__DATE,
              VALUE_DATE_CURRENCY_INTERBANK_SETTLED_AMOUNT__CURRENCY,
              VALUE_DATE_CURRENCY_INTERBANK_SETTLED_AMOUNT__AMOUNT,
            ].includes(i.id)
          ) {
            shouldShowValueDateCurrencyInterbankSettledAmount = true;
          }

          if ([CURRENCY_INSTRUCTED_AMOUNT__CURRENCY, CURRENCY_INSTRUCTED_AMOUNT__AMOUNT].includes(i.id)) {
            shouldShowCurrencyInstructedAmount = true;
          }

          if (
            [
              ORDERING_CUSTOMER__ACCOUNT_IDENTIFIER_CODE__ACCOUNT,
              ORDERING_CUSTOMER__ACCOUNT_IDENTIFIER_CODE__IDENTIFIER_CODE,
            ].includes(i.id)
          ) {
            shouldShowOrderingCustomerAccountIdentifierCode = true;
          }

          if (
            [
              ORDERING_CUSTOMER__PARTY_IDENTIFIER_NAME_ADDRESS__PARTY_IDENTIFIER,
              ORDERING_CUSTOMER__PARTY_IDENTIFIER_NAME_ADDRESS__NAME_ADDRESS,
            ].includes(i.id)
          ) {
            shouldShowOrderingCustomerPartyIdentifierNameAddress = true;
          }

          if (
            [
              ORDERING_CUSTOMER__ACCOUNT_NAME_ADDRESS__ACCOUNT,
              ORDERING_CUSTOMER__ACCOUNT_NAME_ADDRESS__NAME_ADDRESS,
            ].includes(i.id)
          ) {
            shouldShowOrderingCustomerAccountNameAddress = true;
          }

          if (
            [
              shouldShowOrderingCustomerAccountIdentifierCode,
              shouldShowOrderingCustomerPartyIdentifierNameAddress,
              shouldShowOrderingCustomerAccountNameAddress,
            ].some(e => e)
          ) {
            shouldShowOrderingCustomer = true;
          }

          if (
            [
              BENEFICIARY_CUSTOMER__ACCOUNT_IDENTIFIER_CODE__ACCOUNT,
              BENEFICIARY_CUSTOMER__ACCOUNT_IDENTIFIER_CODE__IDENTIFIER_CODE,
            ].includes(i.id)
          ) {
            shouldShowBeneficiaryCustomerAccountIdentifierCode = true;
          }

          if (
            [
              BENEFICIARY_CUSTOMER__ACCOUNT_NUMBER_NAME_ADDRESS__ACCOUNT,
              BENEFICIARY_CUSTOMER__ACCOUNT_NUMBER_NAME_ADDRESS__NUMBER,
              BENEFICIARY_CUSTOMER__ACCOUNT_NUMBER_NAME_ADDRESS__NAME_ADDRESS,
            ].includes(i.id)
          ) {
            shouldShowBeneficiaryCustomerAccountNumberNameAddress = true;
          }

          if (
            [
              shouldShowBeneficiaryCustomerAccountIdentifierCode,
              shouldShowBeneficiaryCustomerAccountNumberNameAddress,
            ].some(e => e)
          ) {
            shouldShowBeneficiaryCustomer = true;
          }

          return i;
        })
        .filter(i => {
          if (i.id === VALUE_DATE_CURRENCY_INTERBANK_SETTLED_AMOUNT) {
            return shouldShowValueDateCurrencyInterbankSettledAmount;
          }
          return true;
        })
        .filter(i => {
          if (i.id === CURRENCY_INSTRUCTED_AMOUNT) {
            return shouldShowCurrencyInstructedAmount;
          }
          return true;
        })
        .filter(i => {
          if (i.id === ORDERING_CUSTOMER__ACCOUNT_IDENTIFIER_CODE) {
            return shouldShowOrderingCustomerAccountIdentifierCode;
          }
          return true;
        })
        .filter(i => {
          if (i.id === ORDERING_CUSTOMER__PARTY_IDENTIFIER_NAME_ADDRESS) {
            return shouldShowOrderingCustomerPartyIdentifierNameAddress;
          }
          return true;
        })
        .filter(i => {
          if (i.id === ORDERING_CUSTOMER__ACCOUNT_NAME_ADDRESS) {
            return shouldShowOrderingCustomerPartyIdentifierNameAddress;
          }
          return true;
        })
        .filter(i => {
          if (i.id === ORDERING_CUSTOMER) {
            return shouldShowOrderingCustomer;
          }
          return true;
        })
        .filter(i => {
          if (i.id === BENEFICIARY_CUSTOMER__ACCOUNT_IDENTIFIER_CODE) {
            return shouldShowBeneficiaryCustomerAccountIdentifierCode;
          }
          return true;
        })
        .filter(i => {
          if (i.id === BENEFICIARY_CUSTOMER__ACCOUNT_NUMBER_NAME_ADDRESS) {
            return shouldShowBeneficiaryCustomerAccountNumberNameAddress;
          }
          return true;
        })
        .filter(i => {
          if (i.id === BENEFICIARY_CUSTOMER) {
            return shouldShowBeneficiaryCustomer;
          }
          return true;
        }),
    },
  ];
};
