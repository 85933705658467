import { Divider } from '@mui/material';
import { css } from '@mui/material/styles';
import { InlineBanner, Link } from 'elements/components';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledDivider = elementsStyled(Divider)(
  ({ theme }) =>
    css`
      margin: 24px 0;
      border-color: ${theme.palette.primary.font};
    `,
);

export const StyledInlineBanner = elementsStyled(InlineBanner)`
  margin-top: 8px;
  background-color: transparent;
  padding: 0;

  & svg {
    width: 20px;
    height: 20px;
  }
`;

export const StyledLinkButton = elementsStyled(Link)(
  ({ theme }) => css`
    color: ${theme.palette.error.main};
    letter-spacing: -0.02em;
    vertical-align: baseline;
  `,
);
