import React from 'react';
import { createTheme, ThemeOptions, Theme, SimplePaletteColorOptions } from '@mui/material/styles';
import {
  formHelperTextClasses,
  inputBaseClasses,
  inputClasses,
  inputLabelClasses,
  outlinedInputClasses,
  buttonClasses,
  alpha,
  backdropClasses,
  radioClasses,
  checkboxClasses,
} from '@mui/material';
import { pxToRem } from 'utils/styling-utils';

export interface GapDimensions {
  _2px: number;
  _4px: number;
  _8px: number;
  _10px: number;
  _12px: number;
  _16px: number;
  _20px: number;
  _24px: number;
  _32px: number;
  _40px: number;
  _48px: number;
  _56px: number;
  _64px: number;
}

interface CustomShadowDimensions {
  _2dp: string;
  _4dp: string;
  _8dp: string;
  _16dp: string;
  _24dp: string;
  _32dp: string;
}

declare module '@mui/material/styles' {
  /**
   * extend palette with new type
   * by adding new field to
   * Palette and PaletteOptions
   */
  interface Palette {
    transparent: string;
    brown: SimplePaletteColorOptions;
    magenta: SimplePaletteColorOptions;
    teal: SimplePaletteColorOptions;
  }
  interface PaletteOptions {
    transparent?: string;
    brown?: SimplePaletteColorOptions;
    magenta?: SimplePaletteColorOptions;
    teal?: SimplePaletteColorOptions;
  }

  /**
   * extend with new color type
   * by adding new field to
   * PaletteColor and SimplePaletteColorOptions
   */
  interface PaletteColor {
    light2?: string;
    light3?: string;
    dark2?: string;
  }
  interface SimplePaletteColorOptions {
    light2?: string;
    light3?: string;
    dark2?: string;
  }
  interface Theme {
    customShadows: {
      grey: CustomShadowDimensions;
      teal: CustomShadowDimensions;
      red: CustomShadowDimensions;
      orange: CustomShadowDimensions;
      green: CustomShadowDimensions;
      blue: CustomShadowDimensions;
    };
    gaps: GapDimensions;
  }
  interface ThemeOptions {
    customShadows?: {
      grey: CustomShadowDimensions;
      teal: CustomShadowDimensions;
      red: CustomShadowDimensions;
      orange: CustomShadowDimensions;
      green: CustomShadowDimensions;
      blue: CustomShadowDimensions;
    };
    gaps?: GapDimensions;
  }
  interface TypographyVariants {
    h7: React.CSSProperties;
    bodyDefaultBook: React.CSSProperties;
    bodyDefaultItalic: React.CSSProperties;
    bodySmallItalic: React.CSSProperties;
    bodyDefaultMedium: React.CSSProperties;
    bodySmallBook: React.CSSProperties;
    bodySmallMedium: React.CSSProperties;
    bodySmallBold: React.CSSProperties;
    bodyDefaultBookLink: React.CSSProperties;
    bodyDefaultBold: React.CSSProperties;
    subtitleBook: React.CSSProperties;
    subtitleMedium: React.CSSProperties;
    captionMedium: React.CSSProperties;
    overlineDefaultBold: React.CSSProperties;
    overlineDefaultMedium: React.CSSProperties;
    overlineSmallBold: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    buttonMedium: React.CSSProperties;
    buttonLarge: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h7?: React.CSSProperties;
    bodyDefaultBook?: React.CSSProperties;
    bodyDefaultItalic?: React.CSSProperties;
    bodySmallItalic?: React.CSSProperties;
    bodyDefaultMedium?: React.CSSProperties;
    bodySmallBook?: React.CSSProperties;
    bodySmallMedium?: React.CSSProperties;
    bodySmallBold?: React.CSSProperties;
    bodyDefaultBookLink?: React.CSSProperties;
    bodyDefaultBold?: React.CSSProperties;
    subtitleBook?: React.CSSProperties;
    subtitleMedium?: React.CSSProperties;
    captionMedium?: React.CSSProperties;
    overlineDefaultBold?: React.CSSProperties;
    overlineDefaultMedium?: React.CSSProperties;
    overlineSmallBold?: React.CSSProperties;
    overlineSmallMedium?: React.CSSProperties;
    buttonLarge?: React.CSSProperties;
    buttonMedium?: React.CSSProperties;
    buttonSmall?: React.CSSProperties;
  }
}
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body1: false;
    body2: false;
    button: false;
    caption: false;
    overline: false;
    subtitle1: false;
    subtitle2: false;
    h7: true;
    bodyDefaultBook: true;
    bodyDefaultItalic: true;
    bodySmallItalic: true;
    bodyDefaultMedium: true;
    bodyDefaultBookLink: true;
    bodyDefaultBold: true;
    bodySmallBook: true;
    bodySmallMedium: true;
    bodySmallBold: true;
    subtitleBook: true;
    subtitleMedium: true;
    captionMedium: true;
    overlineDefaultBold: true;
    overlineDefaultMedium: true;
    overlineSmallBold: true;
    buttonLarge: true;
    buttonMedium: true;
    buttonSmall: true;
  }
}

const createBasicTheme = (options: ThemeOptions): Theme => {
  const secondaryColor = options.palette?.secondary as SimplePaletteColorOptions | undefined;
  const errorColor = options.palette?.error as SimplePaletteColorOptions | undefined;

  return createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: options.palette?.grey?.[300],
            },
            ':hover': {
              [`& .${outlinedInputClasses.notchedOutline}`]: {
                borderWidth: 1,
                ...(secondaryColor && { borderColor: `${secondaryColor.main}` }),
              },
            },
            [`&.${outlinedInputClasses.focused}`]: {
              [`& .${outlinedInputClasses.notchedOutline}`]: {
                borderWidth: 1,
                ...(secondaryColor && { borderColor: `${secondaryColor.main}` }),
              },
            },
            [`&.${outlinedInputClasses.disabled}`]: {
              [`& .${outlinedInputClasses.input}`]: {
                ...(options.gaps && { borderRadius: pxToRem(options.gaps._8px) }),
                backgroundColor: options.palette?.grey?.[50],
                color: options.palette?.grey?.[600],
              },
              [`& .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: options.palette?.grey?.[300],
              },
            },
            [`&.${inputClasses.error}`]: {
              ':hover': {
                [`& .${outlinedInputClasses.notchedOutline}`]: {
                  ...(errorColor && { borderColor: `${errorColor.main}` }),
                },
              },
              [`&.${outlinedInputClasses.focused}`]: {
                [`& .${outlinedInputClasses.notchedOutline}`]: {
                  ...(errorColor && { borderColor: `${errorColor.main}` }),
                },
              },
            },
            ...(options.gaps && { borderRadius: pxToRem(options.gaps._8px) }),
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            [`& .${inputBaseClasses.root}`]: {
              height: pxToRem(52),
              ...(typeof options.typography === 'object' && { ...options.typography.bodyDefaultBook }),
            },
            [`& .${inputBaseClasses.input}::placeholder`]: {
              opacity: 1,
              color: options.palette?.grey?.[600],
            },
            [`& .${inputBaseClasses.input}:-webkit-autofill`]: {
              boxShadow: `0 0 0px 1000px ${options.palette?.background?.paper} inset`,
            },
            [`& .${inputLabelClasses.root}`]: {
              [`&.${inputLabelClasses.shrink}`]: {
                ...(typeof options.typography === 'object' && { ...options.typography.bodyDefaultMedium }),
                [`&.${inputClasses.focused}`]: {
                  ...(typeof options.typography === 'object' && { ...options.typography.bodyDefaultMedium }),
                  ...(secondaryColor && { color: secondaryColor.main }),
                },
                [`&.${inputClasses.disabled}`]: {
                  color: options.palette?.grey?.[600],
                },
              },
            },
            [`& .${inputLabelClasses.root}.${inputClasses.error}`]: {
              color: options.palette?.grey?.[600],
              [`&.${inputLabelClasses.shrink}`]: {
                ...(errorColor && { color: errorColor.main }),
                [`&.${inputClasses.focused}`]: {
                  ...(errorColor && { color: errorColor.main }),
                },
              },
            },
            [`& .${formHelperTextClasses.root}`]: {
              color: options.palette?.grey?.[600],
              display: 'flex',
              alignItems: 'center',
              margin: `${pxToRem(8)} 0px 0px 0px`,
              ...(typeof options.typography === 'object' && { ...options.typography.bodySmallMedium }),
              [`&.${inputClasses.error}`]: {
                ...(errorColor && { color: errorColor.main }),
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            ...(options.gaps && { borderRadius: pxToRem(options.gaps._8px) }),
            boxShadow: 'none',
            ':hover': {
              boxShadow: 'none',
            },
            [`&.${buttonClasses.focusVisible}`]: {
              boxShadow: 'none',
            },
          },
          outlined: {
            borderColor: options.palette?.grey?.[300],
            ':hover': {
              borderColor: options.palette?.grey?.[300],
            },
          },
          sizeMedium: {
            height: pxToRem(40),
          },
          sizeLarge: {
            height: pxToRem(44),
            padding: `${pxToRem(10)} ${pxToRem(20)}`,
          },
          startIcon: {
            marginLeft: 0,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            ...(options.gaps && { borderRadius: pxToRem(options.gaps._8px) }),
            boxShadow: options.customShadows?.grey._16dp,
          },
          root: {
            [`& .${backdropClasses.root}`]: {
              ...(options.palette?.grey && { backgroundColor: alpha(options.palette?.grey?.[700]!, 0.7) }),
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            ...(options.gaps && { padding: pxToRem(options.gaps._4px) }),
            color: options.palette?.grey?.[500],
            ':hover': {
              backgroundColor: secondaryColor?.light2,
              color: secondaryColor?.main,
            },
            [`&.${radioClasses.checked}`]: {
              color: secondaryColor?.main,
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            ...(options.gaps && { padding: pxToRem(options.gaps._4px) }),
            color: options.palette?.grey?.[500],
            ':hover': {
              backgroundColor: secondaryColor?.light2,
              color: secondaryColor?.main,
            },
            [`&.${checkboxClasses.checked}`]: {
              color: secondaryColor?.main,
            },
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
          *:focus {
            outline: none;
          }
        `,
      },
    },
    ...options,
  });
};

export { createBasicTheme };
