import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { ReactComponent as OtherIcon } from 'assets/icons/tokens/small/other-type.svg';
import { Gap } from 'components/shared/Gap';
import { FileType } from 'ui-enums/file-type';
import { getFileTypeIcon } from './get-file-type-icon';
import { getFileTypeLabel } from './getFileTypeLabel';

type Props = {
  fileType?: string;
};

export const FileTypeCell: React.FC<Props> = ({ fileType }) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {fileType ? getFileTypeIcon(fileType as FileType) : <OtherIcon />}
      <Gap size="_8px" isHorizontal />
      <Typography variant="bodyDefaultBook">
        {fileType ? getFileTypeLabel(fileType as FileType) : t('fileType.other')}
      </Typography>
    </Box>
  );
};
