import React from 'react';
import { StyledDiv, StyledRow, StyledTextArea, StyledTypography, StylesHelperErrorAttentionIcon } from './styled';

type Props = {
  name: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLTextAreaElement, Element>) => void;
  error?: boolean;
  errorText?: string;
  height: string;
  width: string;
};

export const TextArea: React.FC<Props> = ({
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  errorText,
  height,
  width,
}) => {
  return (
    <div>
      <StyledDiv
        sx={{
          height,
          width,
        }}
        isError={error ?? false}
      >
        <StyledTextArea name={name} value={value} onChange={onChange} onBlur={onBlur} placeholder={placeholder} />
      </StyledDiv>
      {error && (
        <StyledRow>
          <StylesHelperErrorAttentionIcon />
          <StyledTypography variant="bodyDefaultMedium" color="error">
            {errorText}
          </StyledTypography>
        </StyledRow>
      )}
    </div>
  );
};
