export enum IdentityLevel {
  L0 = 'L0',
  L1 = 'L1',
  L2 = 'L2',
}

export enum IdentityType {
  Personal = 'personal',
  Business = 'business',
}

export enum AccountStatusType {
  Active = 'active',
  Inactive = 'inactive',
  InactivationStarted = 'inactivationStarted',
}

export type IdentityData = {
  type: IdentityType;
  kycLevel: IdentityLevel;
  status: AccountStatusType;
  canUseCustodialAccounts: boolean;
};
