import { formatCurrency } from 'utils/format-currency';
import { TransactionDetailResponseModel } from 'models/response/transactions/transaction-detail-response-model';

export const getSublabelForPreciousMetalSellTransactionDetailsPage = (
  transaction: TransactionDetailResponseModel,
): string => {
  if (transaction.amount) {
    return `$${formatCurrency(transaction.amount)}`;
  }

  return '';
};
