import { Divider } from '@mui/material';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledDivider = elementsStyled(Divider)(
  ({ theme }) =>
    css`
      border-color: ${theme.palette.tertiary.main};
    `,
);

export const PaddingContainer = elementsStyled('div')`
  padding: 0 12px;
`;
