import React from 'react';
import { useTranslation } from 'react-i18next';
import { Gap } from 'components/shared/Gap';
import { CroppedLabel } from 'components/shared/CroppedLabel';
import { StyledRow } from './styled';

type Props = {
  icon: React.ReactNode;
  maxTextWidth: number;
};

export const NotImplementedCellContent: React.FC<Props> = ({ icon, maxTextWidth }) => {
  const { t } = useTranslation();

  return (
    <StyledRow>
      {icon}
      <Gap size="_8px" isHorizontal />
      <CroppedLabel title={t('notImplemented')} maxWidth={maxTextWidth} />
    </StyledRow>
  );
};
