import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { PaymentType } from 'ui-enums/response/transactions/payment-type';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';
import { formatCurrency } from 'utils/format-currency';

export const getFiatAmountForCryptoTransactionDetailsPage = (transaction: TransactionResponseModel): string => {
  const defaultCase = '';
  const fullFilledCase = `$${formatCurrency(transaction.amount ?? 0)}`;

  if (transaction.paymentType.toLowerCase() === PaymentType.sellCrypto.toLowerCase()) {
    switch (transaction.status.toLowerCase()) {
      case TransactionStatus.processing.toLowerCase(): {
        return fullFilledCase;
      }
      case TransactionStatus.completed.toLowerCase(): {
        return fullFilledCase;
      }
      default: {
        return defaultCase;
      }
    }
  }

  if (transaction.paymentType.toLowerCase() === PaymentType.buyCrypto.toLowerCase()) {
    return fullFilledCase;
  }

  return defaultCase;
};
