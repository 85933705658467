import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';

export const StyledContainer = styled('div')(({ theme }) => ({
  paddingBlock: theme.gaps._24px,
  paddingInline: theme.gaps._24px,
  borderRadius: theme.gaps._8px,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.grey[200],
  display: 'flex',
  flexDirection: 'row',
}));

export const StyledAuthorTypography = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: pxToRem(260),
  color: theme.palette.grey[600],
}));
