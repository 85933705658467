import { FormikValues } from 'formik';
import { GeneralFormikPopsType } from './types';

export const getFormikPropsWithoutOnBlur = <Values extends FormikValues>(
  formikProps: GeneralFormikPopsType<Values>,
): Omit<GeneralFormikPopsType<Values>, 'onBlur'> => {
  // eslint-disable-next-line   @typescript-eslint/no-unused-vars
  const { onBlur, ...props } = formikProps;
  return props;
};
