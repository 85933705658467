import React from 'react';
import { Typography } from '@mui/material';
import { CommonActionModal } from 'components/shared/modals/CommonActionModal';
import { ReactComponent as RejectIcon } from 'assets/icons/white-close-icon.svg';
import { LoadingButtonWithSkeleton } from 'components/shared/buttons/LoadingButtonWithSkeleton';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  captionText: string;
  mainText: string;
  destroyButtonTitle: string;
  destroyButtonLoading: boolean;
  onDestroyButtonClick: () => void;
};

export const DestroyActionModal: React.FC<Props> = ({
  isOpen,
  onClose,
  title,
  captionText,
  mainText,
  destroyButtonTitle,
  destroyButtonLoading,
  onDestroyButtonClick,
}) => {
  return (
    <CommonActionModal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      captionText={captionText}
      mainText={mainText}
      actionButton={
        <LoadingButtonWithSkeleton
          color="error"
          variant="contained"
          size="large"
          icon={<RejectIcon />}
          text={
            <Typography variant="buttonMedium" color="background.paper">
              {destroyButtonTitle}
            </Typography>
          }
          loading={destroyButtonLoading}
          onClick={onDestroyButtonClick}
        />
      }
    />
  );
};
