import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useExport } from 'hooks/use-export';
import { useLazyGetPersonalIdentitiesQuery } from 'redux/api/api-personal-identities';
import { FilterResult } from 'filters/interfaces/filter-result';
import { CommonGridBox } from 'components/shared/layouts/CommonLayout';
import { CustomizedGrid } from 'components/shared/grids/CustomizedGrid';
import { PersonalIdentityRow } from 'ui-interfaces/row-interfaces/identities/personal-identity-row';
import { Filterable } from 'ui-interfaces/shared/filters/filterable';
import { getUser } from 'redux/api/api-users';
import { PersonalIdentitiesListFiltersViewModel } from 'view-models/identities/list/filters/personal/personal-identities-list-filters-vm';
import { onPersonalIdentitiesTransform, onPersonalIdentityRowClick, onPersonalIdentitiesExportClick } from './handlers';
import { columnsPersonal } from './columns-personal';

export const PersonalIdentitiesTab: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: user } = useSelector(getUser.select(undefined));

  const { downloadCsv, isLoading: isExportLoading, error: exportError } = useExport();

  const [personalIdentityTrigger, personalIdentityResult] = useLazyGetPersonalIdentitiesQuery();

  const fetchPersonalIdentities = useCallback(
    (page?: number, filterResult?: FilterResult) => personalIdentityTrigger({ page, filterResult }, false),
    [personalIdentityTrigger],
  );

  const [rows, setRows] = useState<PersonalIdentityRow[]>([]);
  const [resourceCount, setResourceCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(personalIdentityResult.isUninitialized);

  useEffect(() => {
    fetchPersonalIdentities();
  }, [fetchPersonalIdentities]);

  useEffect(() => {
    if (personalIdentityResult.isSuccess && personalIdentityResult.data && user) {
      const transformedItems = personalIdentityResult.data.data.map(onPersonalIdentitiesTransform(user));
      setRows(transformedItems);
      setResourceCount(personalIdentityResult.data.meta?.resourceCount ?? 0);
      setLoading(personalIdentityResult.isFetching);
    }
  }, [personalIdentityResult.data, personalIdentityResult.isFetching, personalIdentityResult.isSuccess, user]);

  // filters - start
  const [filterable, setFilterable] = useState<Filterable>(() => new PersonalIdentitiesListFiltersViewModel());

  useEffect(() => {
    if (user) {
      setFilterable(new PersonalIdentitiesListFiltersViewModel(user));
    }
  }, [user]);
  // filters - end

  return (
    <CommonGridBox>
      <CustomizedGrid
        columns={columnsPersonal}
        headerName={t('identitiesPage.gridTitle.personal')}
        filters={filterable.filters}
        selectionMacroCommand={filterable.selectionMacroCommand}
        noResultsTitle={t('identitiesPage.noResultsTitle.personal')}
        noResultsSubtitle={t('identitiesPage.noResultsSubtitle.personal')}
        containerHeight="100%"
        rows={rows}
        rowCount={resourceCount}
        isLoading={loading}
        error={personalIdentityResult.error || exportError}
        fetchOtherItems={fetchPersonalIdentities}
        onGridRowClick={onPersonalIdentityRowClick(navigate)}
        onExportClick={onPersonalIdentitiesExportClick(downloadCsv)}
        isExportLoading={isExportLoading}
      />
    </CommonGridBox>
  );
};
