import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { LoadingButtonWithSkeleton } from 'components/shared/buttons/LoadingButtonWithSkeleton';
import { ReactComponent as EditIcon } from 'assets/icons/settings/user-action/edit-white.svg';

type Props = {
  isSkeletonDisplayed: boolean;
  onClick: () => void;
};

export const EditButton: React.FC<Props> = ({ isSkeletonDisplayed, onClick }) => {
  const { t } = useTranslation();

  return (
    <LoadingButtonWithSkeleton
      color="primary"
      variant="contained"
      size="large"
      icon={<EditIcon />}
      text={
        <Typography variant="buttonMedium" color="background.paper">
          {t('userDetailsPage.buttons.edit')}
        </Typography>
      }
      isSkeletonDisplayed={isSkeletonDisplayed}
      loading={false}
      onClick={onClick}
    />
  );
};
