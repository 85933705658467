import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Typography } from '@mui/material';
import { TransformWrapper, TransformComponent } from '@kokarn/react-zoom-pan-pinch';
import { PDFDocumentProxy } from 'pdfjs-dist';

import { ReactComponent as ZoomInIcon } from 'assets/icons/doc-viewer/zoom-in-icon.svg';
import { ReactComponent as ZoomOutIcon } from 'assets/icons/doc-viewer/zoom-out-icon.svg';
import { ReactComponent as ImageIcon } from 'assets/icons/doc-viewer/doc-type-image-white.svg';
import { ReactComponent as FileIcon } from 'assets/icons/doc-viewer/doc-type-file-white.svg';

import useResize from 'hooks/use-resize';

import { Gap } from 'components/shared/Gap';
import { Row } from 'components/shared/Flex/Row';
import { DocTypes } from 'utils/parse-format-document';

import DocumentViewerModalError from './DocumentViewerModalError';
import PDFSlider from '../PDFViewer/PDFSlider';
import PDFViewer from '../PDFViewer';
import {
  Overlay,
  Tools,
  CloseBtn,
  ButtonContainer,
  ModalContent,
  StyledLoader,
  TOOLS_HEIGHT,
  transformContentStyles,
} from './styled';

type Props = {
  doc: string;
  onClose: () => void;
  docName: string;
  loading?: boolean;
  error?: boolean;
  docType: string;
};

export const DocumentViewerModal: React.FC<Props> = ({ doc, onClose, docName, loading, error, docType }) => {
  const { t } = useTranslation();
  const { height, width } = useResize();
  const imageHeight = height - TOOLS_HEIGHT;

  const [pageAmount, setPageAmount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [currentPage, setCurrentPage] = useState(pageNumber);

  const isShowSlider = pageAmount > 1;
  const isPdf = docType === DocTypes.pdf;
  const handlePrevClick = () => {
    setCurrentPage(prevPage => (prevPage !== 1 ? prevPage - 1 : prevPage));
  };
  const handleNextClick = () => {
    setCurrentPage(prevPage => (prevPage < pageAmount ? prevPage + 1 : prevPage));
  };
  const handleDocumentLoadSuccess = (pdf: PDFDocumentProxy) => {
    setPageAmount(pdf.numPages);
    if (pdf.numPages === 1) {
      setPageNumber(1);
    }
  };
  const content = useMemo(() => {
    if (error) {
      return <DocumentViewerModalError />;
    }
    if (loading) {
      return <StyledLoader size={36} />;
    }
    if (doc && isPdf) {
      return <PDFViewer currentPage={currentPage} doc={doc} handleDocumentLoadSuccess={handleDocumentLoadSuccess} />;
    }
    if (doc && !isPdf) {
      return (
        <img
          src={doc}
          alt={docName}
          style={{
            maxHeight: imageHeight,
            maxWidth: width,
          }}
        />
      );
    }
    return null;
  }, [error, loading, doc, isPdf, currentPage, docName, imageHeight, width]);

  const icon = isPdf ? <FileIcon /> : <ImageIcon />;
  return (
    <TransformWrapper
      initialScale={1}
      initialPositionX={0}
      initialPositionY={0}
      centerZoomedOut
      centerOnInit
      wheel={{ disabled: true }}
      doubleClick={{ disabled: true }}
      disabled={loading || error}
    >
      {({ zoomIn, zoomOut }: any) => {
        return (
          <>
            <Tools>
              <Row>
                <>
                  {icon}
                  <Gap size="_8px" isHorizontal />
                  <Typography variant="h5" color="background.paper">
                    {docName}
                  </Typography>
                </>
              </Row>
              <ButtonContainer>
                <IconButton onClick={() => zoomOut()} disabled={loading}>
                  <ZoomOutIcon />
                </IconButton>
                <IconButton onClick={() => zoomIn()} disabled={loading}>
                  <ZoomInIcon />
                </IconButton>
                <Gap size="_40px" isHorizontal />
                <CloseBtn variant="outlined" onClick={() => onClose()}>
                  <Typography variant="buttonMedium" color="grey.300">
                    {t('documentsList.closeModalBtn')}
                  </Typography>
                </CloseBtn>
              </ButtonContainer>
            </Tools>
            <Overlay>
              <ModalContent>
                <TransformComponent
                  contentStyle={{
                    width,
                    height: imageHeight,
                    ...transformContentStyles,
                  }}
                >
                  {content}
                </TransformComponent>
                {isShowSlider && (
                  <PDFSlider
                    currentPage={currentPage}
                    pageAmount={pageAmount}
                    onPrevClick={handlePrevClick}
                    onNextClick={handleNextClick}
                  />
                )}
              </ModalContent>
            </Overlay>
          </>
        );
      }}
    </TransformWrapper>
  );
};
