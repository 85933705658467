import React from 'react';
import { Box } from '@mui/material';
import { InfoColumn } from './InfoColumn';
import { FooterIcon } from './FooterIcon';
import { StyledButtonRow, StyledUserInfoRow } from './styled';
import LogOutButton from './LogOutButton';

type Props = {
  open: boolean;
  title: string;
  subtitle: string;
  onLogOut: () => void;
};

export const Footer: React.FC<Props> = ({ open, title, subtitle, onLogOut }) => {
  return (
    <Box>
      <StyledUserInfoRow>
        <div>
          <FooterIcon isTooltipEnabled={!open} tooltipTitle={title} toolTipSubtitle={subtitle} />
        </div>
        {open && <InfoColumn title={title} subtitle={subtitle} />}
      </StyledUserInfoRow>
      <StyledButtonRow isSideBarExpanded={open}>
        <LogOutButton onClick={onLogOut} isSideBarExpanded={open} />
      </StyledButtonRow>
    </Box>
  );
};
