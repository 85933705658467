import { UserDetailResponseModel } from 'models/response/users/user-detail-response-model';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { UserDetails } from 'ui-interfaces/user-details/user-details';
// view models - start
import { UserDetailsViewModelWithCurrentUser } from 'view-models/users/details/decorators/user-details-vm-with-current-user';
import { UserDetailsBaseViewModel } from 'view-models/users/details/base/user-details-base-vm';
import { AdminUserDetailsViewModel } from 'view-models/users/details/admin-user-details-vm';
import { EditorUserDetailsViewModel } from 'view-models/users/details/editor-user-details-vm';
import { ViewerUserDetailsViewModel } from 'view-models/users/details/viewer-user-details-vm';
import { RoleTemplate } from 'models/enums/user/role-template';
import { UserRoleTemplateResponseModel } from 'models/response/users/user-role-template-response-model';
import { OwnerUserDetailsViewModel } from 'view-models/users/details/owner-user-details-vm';
// view models - end

export const createUserDetailsViewModel = (
  user: UserDetailResponseModel,
  currentUser: ShortUserResponseModel,
  userRoleTemplates?: UserRoleTemplateResponseModel[],
): UserDetails => {
  switch (user.roleTemplate) {
    case RoleTemplate.admin:
      return new UserDetailsViewModelWithCurrentUser(
        new AdminUserDetailsViewModel(user),
        user,
        currentUser,
        userRoleTemplates,
      );
    case RoleTemplate.editor:
      return new UserDetailsViewModelWithCurrentUser(
        new EditorUserDetailsViewModel(user),
        user,
        currentUser,
        userRoleTemplates,
      );
    case RoleTemplate.viewer:
      return new UserDetailsViewModelWithCurrentUser(
        new ViewerUserDetailsViewModel(user),
        user,
        currentUser,
        userRoleTemplates,
      );
    case RoleTemplate.owner:
      return new UserDetailsViewModelWithCurrentUser(
        new OwnerUserDetailsViewModel(user),
        user,
        currentUser,
        userRoleTemplates,
      );
    default:
      return new UserDetailsBaseViewModel(user);
  }
};
