import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const BOX_MARGIN_RIGHT = 12;
export const CELL_INLINE_PADDING = 20;

export const StyledTitleTypography = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
