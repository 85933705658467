import { UserRoleTemplateResponseModel } from 'models/response/users/user-role-template-response-model';
import { IRoleTemplatesInputs } from 'ui-interfaces/user-form/i-role-templates-inputs';
import { RoleTemplatesViewModel } from 'view-models/user-form/role-templates-vm';
import { CreateUserRequestModel } from 'models/request/users/create-user-request-model';

export const createRoleTemplatesViewModel = (
  userRoleTemplateResponseModels: UserRoleTemplateResponseModel[],
): IRoleTemplatesInputs<Pick<CreateUserRequestModel, 'roleTemplate' | 'permissions' | 'limits'>> => {
  return new RoleTemplatesViewModel(userRoleTemplateResponseModels);
};
