import React from 'react';

import { UserBaseResponseModel } from 'models/response/users/user-base-response-model';
import { ActionCellAction } from 'ui-interfaces/users/action-cell-action';
import { Action } from 'ui-enums/users/action-cell-type';
import { ReactComponent as RemoveIcon } from 'assets/icons/settings/user-action/remove.svg';
import i18n from 'localizations';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { UserRoleTemplateResponseModel } from 'models/response/users/user-role-template-response-model';
import { RoleTemplate } from 'models/enums/user/role-template';

export class RemoveUserActionViewModel implements ActionCellAction {
  type = Action.remove;

  icon = (<RemoveIcon />);

  label = i18n.t('settingsPage.grid.actions.remove');

  isDisplayed = false;

  constructor(
    user: UserBaseResponseModel,
    currentUser: ShortUserResponseModel,
    userRoleTemplates?: UserRoleTemplateResponseModel[],
  ) {
    this.isDisplayed = RemoveUserActionViewModel.calculateIsDisplayed(user, currentUser, userRoleTemplates);
  }

  static calculateIsDisplayed(
    user: UserBaseResponseModel,
    currentUser: ShortUserResponseModel,
    userRoleTemplates?: UserRoleTemplateResponseModel[],
  ) {
    const isCurrentUser = user.id === currentUser.id;
    const roleTemplatesOfEditableUsers = userRoleTemplates
      ? userRoleTemplates.map(({ roleTemplate }) => roleTemplate)
      : [];
    const isRemovalAllowed = roleTemplatesOfEditableUsers.includes(user.roleTemplate);
    switch (true) {
      case isCurrentUser: {
        return false;
      }

      case user.roleTemplate === RoleTemplate.owner: {
        return false;
      }

      default:
        return isRemovalAllowed;
    }
  }
}
