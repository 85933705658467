import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  height: pxToRem(64),
}));
