import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { Backdrop, Typography, useTheme } from '@mui/material';
import { Button } from 'elements/components';
import { CustodialAccount } from 'elements/types';
import { formatCryptoBalance } from 'elements/utils';
import Layout from 'elements/element-transfer/components/Layout';
import { CryptoSendState } from 'elements/element-transfer/contexts/Element';
import { ElementsCustomTheme } from 'elements/theme/createCustomTheme';
import { StyledInlineBanner } from './styled';
import { TransactionDetailsDynamicAsset } from '../TransactionDetailsDynamicAsset';

type Props = {
  sendCryptoState: CryptoSendState;
  custodialAccount: CustodialAccount | null;
  isSendCoinsLoading: boolean;
  isSendCoinsError?: boolean;
  errorMessage?: string;
  errorTraceId: string;
  onBack: () => void;
  onClose: () => void;
  onSend: () => void;
};

const CryptoReviewPage: React.FC<Props> = ({
  sendCryptoState,
  custodialAccount,
  isSendCoinsLoading,
  isSendCoinsError,
  errorMessage,
  errorTraceId,
  onBack,
  onClose,
  onSend,
}) => {
  const { t } = useTranslation();
  const theme = useTheme<ElementsCustomTheme>();
  const { amount, selectedCoin } = sendCryptoState;

  const formattedAmount = amount
    ? formatCryptoBalance(amount, { withCommas: true, coinAbbreviation: selectedCoin?.assetTicker })
    : '';

  return (
    <Layout
      title={t('elements.transfer.customizableLabels.withdrawalCryptoReviewPageTitle')}
      onBack={onBack}
      onClose={onClose}
      footerButtons={
        <Button isLoading={isSendCoinsLoading} onClick={onSend} endIcon={<CheckIcon />}>
          <Typography variant="buttonLarge">
            {t('elements.transfer.withdrawalCryptoReviewPage.sendButton', { formattedAmount })}
          </Typography>
        </Button>
      }
    >
      {(errorMessage || isSendCoinsError) && (
        <StyledInlineBanner
          text={errorMessage || t('elements.transfer.withdrawalCryptoReviewPage.processingError')}
          errorTraceId={errorTraceId}
        />
      )}
      <Backdrop
        open={isSendCoinsLoading}
        sx={{ backgroundColor: `${theme.palette.background.whiteTransparent50}`, zIndex: 5 }}
      />

      <TransactionDetailsDynamicAsset sendCryptoState={sendCryptoState} custodialAccount={custodialAccount} />
    </Layout>
  );
};

export default CryptoReviewPage;
