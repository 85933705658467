import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import * as api from 'elements/element-transfer/api';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { TransferFlow } from 'elements/element-transfer/types';
import { CustomError, isReachedLimits } from 'elements/api';
import { setErrorBanner } from 'elements/utils';
import ContentBlock from 'elements/element-transfer/components/Withdrawal/CryptoReviewPage';
import {
  setCryptoWithdrawalPaymentId,
  setIsOrderPlaced,
  setLockId,
  setPage,
  useElement,
} from 'elements/element-transfer/contexts/Element';
import convertErrorMessage from 'elements/element-transfer/utils/convertErrorMessage';
import { ConfirmModal } from 'elements/components/ConfirmModal';
import { OnCloseElement } from 'elements/models/types/element-result';
import { getParsedTraceId } from 'elements/element-transfer/utils/getParsedTraceId';

const CryptoReviewPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const { dispatch, state } = useElement();
  const sendCryptoState = state[TransferFlow.Withdrawal].CRYPTO;
  const { walletAddress, amount, selectedCoin, destinationTag } = sendCryptoState;

  const [isSendCoinsLoading, setIsSendCoinsLoading] = useState(false);
  const [isSendCoinsError, setIsSendCoinsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorTraceId, setErrorTraceId] = useState('');

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const onConfirmDecline = () => setIsConfirmModalOpen(false);

  const onCustomClose = () => setIsConfirmModalOpen(true);

  const sendCoins = async () => {
    try {
      setIsSendCoinsLoading(true);
      setIsSendCoinsError(false);
      setErrorMessage('');
      setErrorTraceId('');

      if (!selectedCoin || !state.custodialAccount) return;

      const result = await api.transferElement.initiateCoinsWithdrawal({
        sourceCustodialAccountId: state.custodialAccount.id,
        destinationAddress: walletAddress,
        assetType: selectedCoin?.assetTicker,
        network: selectedCoin?.network,
        amount: +amount,
        lockId: state.lockId,
        destinationTag,
      });
      if (result?.id) {
        dispatch(setCryptoWithdrawalPaymentId(result?.id));
      }

      dispatch(setLockId(''));
      dispatch(setIsOrderPlaced(true));
      dispatch(setPage(Paths.WithdrawalCryptoSent));
    } catch (err) {
      const coinAbbreviation = selectedCoin?.assetType;

      const errorResponse = err as CustomError;
      const errorMessageResponse = errorResponse?.responseData?.errors?.['']?.[0];

      if (errorMessageResponse && coinAbbreviation && !isReachedLimits(err)) {
        const errorMsg = convertErrorMessage(errorMessageResponse, coinAbbreviation);
        setErrorMessage(errorMsg);
      }

      if (isReachedLimits(err)) {
        setErrorMessage(t('serverError.transactionlimitsError'));
      }

      setErrorBanner(err, enqueueSnackbar);

      setIsSendCoinsError(true);
      if (errorResponse?.responseData?.traceId) {
        const tId = getParsedTraceId(errorResponse?.responseData?.traceId);
        if (tId) {
          setErrorTraceId(tId);
        }
      }
    } finally {
      setIsSendCoinsLoading(false);
    }
  };

  const onSend = () => {
    // eslint-disable-next-line no-return-await
    (async () => await sendCoins())();
  };

  const onBack = (): void => {
    dispatch(setPage(Paths.WithdrawalCryptoAmount));
  };

  useEffect(() => {
    setErrorMessage('');

    if (state.isOrderPlaced) {
      dispatch(setPage(Paths.WithdrawalCryptoSent));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!sendCryptoState) return null;

  return (
    <>
      <ContentBlock
        sendCryptoState={sendCryptoState}
        onClose={onCustomClose}
        onBack={onBack}
        onSend={onSend}
        isSendCoinsLoading={isSendCoinsLoading}
        isSendCoinsError={isSendCoinsError}
        custodialAccount={state.custodialAccount}
        errorMessage={errorMessage}
        errorTraceId={errorTraceId}
      />
      {isConfirmModalOpen && <ConfirmModal onConfirm={onClose} onDecline={onConfirmDecline} />}
    </>
  );
};

export default CryptoReviewPage;
