import i18n from 'localizations';
import { RejectedReason } from 'models/enums/transactions/reject-reason';

export const getTransactionRejectedReason = (rejectedReason: RejectedReason): string => {
  switch (rejectedReason) {
    case RejectedReason.approvalTimeExpired:
      return i18n.t('transactionDetailsPage.labels.approvalRowLabels.rejectedReasons.approvalTimeExpired');

    case RejectedReason.rejectedByAdmin:
      return i18n.t('transactionDetailsPage.labels.approvalRowLabels.rejectedReasons.rejectedByAdmin');

    default:
      return '';
  }
};
