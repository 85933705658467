import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePermissionBasedAccess } from 'hooks/use-permission-based-access';
import ContentBlock from 'components/tapi/custodialAccounts/CustodialAccountsDetailsPage';
import { useLazyGetCustodialAccountDetailsByIdQuery } from 'redux/api/api-custodial-accounts';
import { CustodialAccountsDetailsUrlParams } from 'navigation/url-params-type';
import { CustodialAccountDetailsPageTabs } from 'ui-enums/tabs/accounts/custodial-account-details-page-tabs';
import { useBcCustodialAccountDetailsPage } from 'hooks/breadcrumbs/use-bc-custodial-account-details-page';
import { ElementsJwtTypeModel } from 'models/enums/identities/elements-jwt-type-model';
import { elementConfigTrade } from 'elements/configs/element-config-trade';
import { ElementName } from 'elements/models/enums/element-name';
import {
  elementConfigTransferForDeposit,
  elementConfigTransferForDepositWithdrawal,
} from 'elements/configs/element-config-transfer';
import { useLazyCloseJWTQuery } from 'redux/api/api-users';
import { CustodialAccountsDetailsHeader } from 'ui-interfaces/custodial-accounts-details/custodial-accounts-details-header';
import { DashboardRoles } from 'models/enums/user/dashboard-roles';
import { createCustodialAccountDetailsHeaderViewModel } from 'factories/custodial-accounts/create-custodial-account-details-header-view-model';
import { createCaNamingModalViewModel } from 'factories/custodial-accounts/create-ca-naming-modal-view-model';
import { Element } from 'ui-interfaces/elements/element';
import { ICaNamingModal } from 'ui-interfaces/custodial-accounts-details/i-ca-naming-modal';
import { onTradeElementDone, onTransferElementDone } from './handlers';

const IS_NATIVE_TRADE_ELEMENT = process.env.REACT_APP_IS_NATIVE_TRADE_ELEMENT?.normalize() === 'true';
const IS_NATIVE_TRANSFER_ELEMENT = process.env.REACT_APP_IS_NATIVE_TRANSFER_ELEMENT?.normalize() === 'true';

export const CustodialAccountsDetailsPage: React.FC = () => {
  const { custodialAccountId } = useParams<CustodialAccountsDetailsUrlParams>();

  const [isDetailsPageError, setIsDetailsPageError] = useState(false);
  const [tab, setTab] = useState(CustodialAccountDetailsPageTabs.fiatCrypto);

  // getCustodialAccountDetailsById - start
  const [getCustodialAccountDetailsByIdTrigger, custodialAccountDetailsByIdResult] =
    useLazyGetCustodialAccountDetailsByIdQuery();

  const fetchCustodialAccountDetailsById = useCallback(
    () => getCustodialAccountDetailsByIdTrigger(custodialAccountId!),
    [custodialAccountId, getCustodialAccountDetailsByIdTrigger],
  );

  const { breadCrumbs, pathMatch } = useBcCustodialAccountDetailsPage();

  const [formattedHeaderData, setFormattedHeaderData] = useState<CustodialAccountsDetailsHeader | undefined>();

  useEffect(() => {
    fetchCustodialAccountDetailsById();
  }, [fetchCustodialAccountDetailsById]);

  // TODO: Move to CustodialAccountsDetailsHeader when elements' button initiation logic is fixed
  const [caNamingModalData, setCaNamingModalData] = useState<ICaNamingModal | undefined>();

  useEffect(() => {
    if (custodialAccountDetailsByIdResult.isSuccess && custodialAccountDetailsByIdResult.data) {
      setFormattedHeaderData(
        createCustodialAccountDetailsHeaderViewModel(pathMatch, custodialAccountDetailsByIdResult.data),
      );
      // TODO: Move to CustodialAccountsDetailsHeader when elements' button initiation logic is fixed
      setCaNamingModalData(createCaNamingModalViewModel(custodialAccountDetailsByIdResult.data));
    }
  }, [custodialAccountDetailsByIdResult, pathMatch]);
  // getCustodialAccountDetailsById - end

  // closeJWT - start
  const [closeJWTTrigger] = useLazyCloseJWTQuery();
  const closeJWTInitiator = useCallback(() => closeJWTTrigger(undefined), [closeJWTTrigger]);
  // closeJWT - end

  // handle all page error - start
  useEffect(() => {
    setIsDetailsPageError(custodialAccountDetailsByIdResult.isError);
  }, [custodialAccountDetailsByIdResult.isError]);
  // handle all page error - end

  // permissions - start
  const isPaymentInitiationAllowed = usePermissionBasedAccess([DashboardRoles.paymentInitiator]);
  // permissions - end

  // elements - start
  const transferElementConfig = isPaymentInitiationAllowed
    ? elementConfigTransferForDepositWithdrawal
    : elementConfigTransferForDeposit;
  const elements: Array<Element> = formattedHeaderData
    ? [
        {
          isNativeElement: IS_NATIVE_TRANSFER_ELEMENT,
          name: ElementName.TRANSFER,
          custodialAccountId: custodialAccountId!,
          identityId: formattedHeaderData.identityId,
          elementType: ElementsJwtTypeModel.transfer,
          elementConfig: transferElementConfig,
          onDoneCb: onTransferElementDone(IS_NATIVE_TRANSFER_ELEMENT ? closeJWTInitiator : undefined),
        },
        ...(isPaymentInitiationAllowed
          ? ([
              {
                isNativeElement: IS_NATIVE_TRADE_ELEMENT,
                name: ElementName.TRADE,
                custodialAccountId: custodialAccountId!,
                identityId: formattedHeaderData.identityId,
                elementType: ElementsJwtTypeModel.trade,
                elementConfig: elementConfigTrade,
                onDoneCb: onTradeElementDone(IS_NATIVE_TRADE_ELEMENT ? closeJWTInitiator : undefined),
              },
            ] as Array<Element>)
          : []),
      ]
    : [];
  // elements - end

  return (
    <ContentBlock
      setIsDetailsPageError={setIsDetailsPageError}
      custodialAccountId={custodialAccountId!}
      pathMatch={pathMatch}
      breadCrumbs={breadCrumbs}
      headerProps={formattedHeaderData}
      error={isDetailsPageError}
      loading={custodialAccountDetailsByIdResult.isFetching || custodialAccountDetailsByIdResult.isUninitialized}
      tab={tab}
      setTab={setTab}
      elements={elements}
      caNamingModalData={caNamingModalData}
    />
  );
};
