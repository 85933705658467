import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';

export const StyledBox = styled(Box)(() => ({
  'span:not(:first-of-type)': {
    marginLeft: `-7px`,
  },
}));

export const MoreAssetsElement = styled('div')(({ theme }) => ({
  marginLeft: `-7px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  border: `3px solid ${theme.palette.background.paper}`,
  backgroundColor: theme.palette.grey[100],
  width: pxToRem(34),
}));
