const colors = {
  navy: '#263698',
  azure: '#226BD0',

  white: '#FFFFFF',
  backgroundLight: '#FBFBFD',
  backgroundBlack: '#000000',
  cameraBackground: '#4B4B4B',

  errorDefault: '#D43636',
  errorDark: '#72000B',
  errorLight: '#FBEBEB',
  errorLight2: '#F99E9E',

  warningDefault: '#EA7B14',
  warningDark: '#761F00',
  warningLight: '#FEF3E8',
  warningLight2: '#F6CA92',

  successDefault: '#00875A',
  successDark: '#12441F',
  successLight: '#E5F3EE',
  successLight2: '#ABF5D1',

  infoDefault: '#0F56B3',
  infoDark: '#002E9F',
  infoLight: '#E7EEF7',
  infoLight2: '#8AB9F6',

  grey700: '#1C1C1C',
  grey600: '#707070',
  grey500: '#8D8D8D',
  grey400: '#BABABA',
  grey300: '#DEDEDE',
  grey200: '#E8E8E8',
  grey100: '#F4F4F4',
  grey50: '#F7F7F7',
};

export { colors };
