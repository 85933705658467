import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Typography } from '@mui/material';
import { BannerType, Button, CircularProgress, InlineBanner, Spacer, Stack, Tabs } from 'elements/components';
import { ErrorState } from 'elements/features/LoadingErrorState';
import { TradeDirectionEnum, TradeFlow } from 'elements/element-trade/types';
import buySellCoinPageTitleMapping from 'elements/element-trade/utils/buySellCoinPageTitleMapping';
import isCoinDisabled from 'elements/element-trade/utils/isCoinDisabled';
import Layout from 'elements/element-trade/components/Layout';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import { AssetResponseModel } from 'models/response/assets/asset-response-model';
import PageContent from './PageContent';
import { StyledLoader } from './styled';

type Props = {
  selectedCoin: AssetResponseModel | CustodialAccountBalanceModel | null;
  tradeDirection: TradeDirectionEnum;
  flowType: TradeFlow;
  coinsList: Array<AssetResponseModel | CustodialAccountBalanceModel>;
  coinPrice: number;
  amount: string;
  amountError: boolean;
  lockAmountError: boolean;
  unlockAmountError: boolean;
  coinPriceError: boolean;
  coinListError: boolean;
  coinPriceLoading: boolean;
  continueButtonDisabled: boolean;
  coinListLoading: boolean;
  contactUsUrl: string | null;
  serviceUnavailable: boolean;
  onAmountInputChange: (
    event: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } },
  ) => void;
  onCoinDropdownChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAmountInputBlur: () => void;
  onAmountInputFocus: () => void;
  onReloadCoinPrice: () => void;
  onReload: (flowType: TradeFlow) => void;
  onContinue: () => void;
  onClose: () => void;
  onTabChange: (value: TradeFlow) => void;
};

const BuySellCoinPage: React.FC<Props> = ({
  selectedCoin,
  tradeDirection,
  flowType,
  coinsList,
  coinPrice,
  amount,
  coinListLoading,
  coinPriceLoading,
  continueButtonDisabled,
  amountError,
  lockAmountError,
  unlockAmountError,
  coinListError,
  coinPriceError,
  contactUsUrl,
  serviceUnavailable,
  onAmountInputChange,
  onAmountInputBlur,
  onAmountInputFocus,
  onCoinDropdownChange,
  onReload,
  onReloadCoinPrice,
  onTabChange,
  onContinue,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Layout
      title={buySellCoinPageTitleMapping[tradeDirection]}
      isContentCentered={coinListError || unlockAmountError}
      onClose={onClose}
      footerButtons={
        !coinListError &&
        !serviceUnavailable &&
        !unlockAmountError && (
          <Button type="submit" disabled={continueButtonDisabled} onClick={onContinue}>
            <Typography variant="buttonLarge">{t('elements.common.continue')}</Typography>
            <ArrowForwardIcon />
          </Button>
        )
      }
      subHeader={
        tradeDirection === TradeDirectionEnum.BuySell && (
          <Tabs
            items={[
              { value: TradeFlow.Buy, title: t('elements.trade.customizableLabels.buy') },
              { value: TradeFlow.Sell, title: t('elements.trade.customizableLabels.sell') },
            ]}
            value={flowType}
            onTabChange={onTabChange}
          />
        )
      }
    >
      {flowType === TradeFlow.Sell && isCoinDisabled(selectedCoin?.assetTicker) ? (
        <div>
          <InlineBanner type={BannerType.Info} text={t('elements.trade.buySellPage.unavailableCoinBanner')} />
          <Spacer size={24} />
        </div>
      ) : (
        !coinListError && !unlockAmountError && <Spacer size={16} />
      )}

      {coinListLoading ? (
        <StyledLoader>
          <CircularProgress />
        </StyledLoader>
      ) : (
        <Stack gap="32px">
          {coinListError || unlockAmountError ? (
            <ErrorState onReload={() => onReload(flowType)} />
          ) : (
            <PageContent
              selectedCoin={selectedCoin}
              flowType={flowType}
              coinsList={coinsList}
              amount={amount}
              amountError={amountError}
              lockAmountError={lockAmountError}
              coinPrice={coinPrice}
              coinPriceError={coinPriceError}
              coinPriceLoading={coinPriceLoading}
              serviceUnavailable={serviceUnavailable}
              contactUsUrl={contactUsUrl}
              onAmountInputChange={onAmountInputChange}
              onAmountInputBlur={onAmountInputBlur}
              onAmountInputFocus={onAmountInputFocus}
              onCoinDropdownChange={onCoinDropdownChange}
              onReloadCoinPrice={onReloadCoinPrice}
            />
          )}
        </Stack>
      )}
    </Layout>
  );
};

export default BuySellCoinPage;
