import React, { useEffect, useMemo } from 'react';
import { OnCloseElement } from 'elements/models/types/element-result';
import { ElementDialog } from 'elements/components';
import { transferElement } from 'elements/element-transfer/api';
import { createAppContent } from './utils/createAppContent';
import {
  setFlow,
  setPaymentMethodTypesMap,
  setTransferDirection,
  setTransferOptionsList,
  useElement,
} from './contexts/Element';
import { TransferDirectionEnum, TransferFlow } from './types';
import { Paths } from './navigation/routes';
import { sanitizeTransferDirection } from './utils/sanitizeTransferDirection';

const CLOSE_CONFIRMATION_REQUIRED_PAGES = [
  Paths.DepositAchReview,
  Paths.WithdrawalCryptoReview,
  Paths.WithdrawalWireAccountNumbers,
  Paths.WithdrawalWireAmount,
  Paths.WithdrawalWireBankInfo,
  Paths.WithdrawalWireReview,
];

type Props = {
  paymentMethodTypes?: string;
  transferDirection?: string;
  onClose: OnCloseElement;
};
export const App: React.FC<Props> = ({ paymentMethodTypes, transferDirection, onClose }) => {
  const { state, dispatch } = useElement();

  const isWireWithdrawalRecipientPageCloseConfirmRequired =
    state[TransferFlow.Withdrawal].WIRE.isCloseConfirmationRequired;

  const isCloseConfirmationRequired = useMemo(
    () =>
      [
        ...CLOSE_CONFIRMATION_REQUIRED_PAGES,
        ...(isWireWithdrawalRecipientPageCloseConfirmRequired ? [Paths.WithdrawalWireRecipientInfo] : []),
      ].includes(state.page.location),
    [state.page.location, isWireWithdrawalRecipientPageCloseConfirmRequired],
  );

  const onTransferClose = useMemo(
    () => async () => {
      onClose();
      if (state.lockId) {
        await transferElement.unlockPaymentAmount(state.lockId);
      }
    },
    [onClose, state.lockId],
  );

  const content = useMemo(
    () => createAppContent(state.page.location, onTransferClose),
    [state.page.location, onTransferClose],
  );

  useEffect(() => {
    if (paymentMethodTypes) {
      dispatch(setPaymentMethodTypesMap(paymentMethodTypes));
      dispatch(setTransferOptionsList(paymentMethodTypes));
    }
    if (transferDirection) {
      const sanitizedTransferDirection = sanitizeTransferDirection(transferDirection);
      dispatch(setTransferDirection(sanitizedTransferDirection));
      dispatch(
        setFlow(
          sanitizedTransferDirection === TransferDirectionEnum.Withdrawal
            ? TransferFlow.Withdrawal
            : TransferFlow.Deposit,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ElementDialog onClose={onClose} isCloseConfirmationRequired={isCloseConfirmationRequired}>
      {content}
    </ElementDialog>
  );
};
