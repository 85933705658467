import React, { useState, useEffect, useCallback } from 'react';
import { GridRowsProp, GridColDef, GridRowParams } from '@mui/x-data-grid';
import Skeleton from '@mui/material/Skeleton';
import { pxToRem } from 'utils/styling-utils';
import { DEFAULT_PAGE_SIZE } from 'redux/api/constants';
import { SelectionMacroCommand } from 'filters/commands/selection-macro-command';
import { Filter } from 'filters/interfaces/filter';
import { FilterResult } from 'filters/interfaces/filter-result';
import { PaginatedRequestParams } from 'redux/api/types';
import { CustomHeader } from './CustomHeader';
import { CustomNoResultsOverlay } from './CustomNoResultsOverlay';
import { CustomErrorOverlay } from './CustomErrorOverlay';
import { CustomLoadingOverlay } from './CustomLoadingOverlay';
import { StyledContainer, StyledDataGrid } from './styled';

interface FetchCallBack {
  (page?: number, filterResult?: FilterResult): void;
}

type Props = {
  rows: GridRowsProp;
  columns: GridColDef[];
  headerName: string;
  selectionMacroCommand: SelectionMacroCommand;
  filters: Filter[];
  noResultsTitle: string;
  noResultsSubtitle: string;
  rowCount?: number;
  pageSize?: number;
  isLoading: boolean;
  error: any;
  containerHeight: string;
  fetchOtherItems: FetchCallBack;
  onExportClick: (pagination: PaginatedRequestParams, filterResult?: FilterResult) => void;
  onGridRowClick?: (params: GridRowParams) => void;
  isExportLoading?: boolean;
  alwaysShowToolbar?: boolean;
};

const drawSkeleton = () => (
  <Skeleton sx={{ bgcolor: 'grey.200' }} animation="pulse" height={pxToRem(16)} width="100%" />
);

const loadingRows = Array(DEFAULT_PAGE_SIZE)
  .fill(0)
  .map((_, idx) => ({
    id: idx,
  }));

export const PaginatedExportGrid: React.FC<Props> = ({
  rows,
  columns,
  headerName,
  selectionMacroCommand,
  filters,
  noResultsTitle,
  noResultsSubtitle,
  pageSize = DEFAULT_PAGE_SIZE,
  rowCount,
  isLoading,
  error,
  containerHeight,
  fetchOtherItems,
  onGridRowClick,
  onExportClick,
  isExportLoading,
  alwaysShowToolbar,
}) => {
  const [page, setPage] = useState(0);
  const [, setIsGridError] = useState();
  const [loadingCols, setLoadingCols] = useState(columns);
  const [noSortingCols, setNoSortingCols] = useState(columns);

  const fetchCb: FetchCallBack = useCallback(
    (p?: number, fR?: FilterResult) => {
      fetchOtherItems(p, fR);
      setPage(0);
    },
    [fetchOtherItems],
  );

  useEffect(() => {
    setLoadingCols(columns.map(c => ({ ...c, renderCell: drawSkeleton })));
    setNoSortingCols(columns.map(c => ({ ...c, sortable: false })));
  }, [columns]);

  /**
   * DataGrid does not react instantly to error change
   * and there's mismatch between error value
   * and the actual DataGrid state:
   * https://github.com/mui/mui-x/issues/3922
   */
  useEffect(() => {
    // needed to force grid rerender
    const timeoutID = setTimeout(() => {
      setIsGridError(error);
    }, 100);
    return () => {
      clearTimeout(timeoutID);
    };
  }, [error]);

  return (
    <StyledContainer
      sx={{
        height: containerHeight,
      }}
    >
      <StyledDataGrid
        disableVirtualization
        rowHeight={64}
        error={error}
        loading={isLoading}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnFilter
        page={page}
        columns={noSortingCols}
        rows={rows}
        rowCount={rowCount}
        pageSize={pageSize}
        pagination
        paginationMode="server"
        components={{
          Toolbar: CustomHeader,
          NoRowsOverlay: CustomNoResultsOverlay,
          ErrorOverlay: CustomErrorOverlay,
          LoadingOverlay: CustomLoadingOverlay,
        }}
        componentsProps={{
          toolbar: {
            headerName,
            selectionMacroCommand,
            filters,
            fetchOtherItems: fetchCb,
            noPureData: rows.length === 0 && !selectionMacroCommand.hasSavedCommandSlot() && !alwaysShowToolbar,
            isExportLoading,
            onExportClick,
          },
          noRowsOverlay: {
            title: noResultsTitle,
            subtitle: noResultsSubtitle,
            noFilteredData: rows.length === 0 && selectionMacroCommand.hasSavedCommandSlot(),
          },
          loadingOverlay: {
            rows: loadingRows,
            columns: loadingCols,
          },
        }}
        onPageChange={p => {
          setPage(p);
          const filterResult = selectionMacroCommand.getState();
          if (filterResult.value !== '') {
            fetchOtherItems(p + 1, filterResult);
          } else {
            fetchOtherItems(p + 1);
          }
        }}
        onRowClick={onGridRowClick}
        rowsPerPageOptions={[pageSize]}
      />
    </StyledContainer>
  );
};
