import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  titleText: { fontSize: 10, fontFamily: 'Helvetica-Bold', lineHeight: '140%', letterSpacing: 0.3, marginBottom: 12 },
  divider: {
    backgroundColor: '#F1F1F1',
    width: '100%',
    height: 1,
    marginTop: 4,
    marginBottom: 12,
  },
});
