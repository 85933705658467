import { Box, IconButton, Typography } from '@mui/material';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { preventForwardProps } from 'elements/utils';

export const StyledContainer = elementsStyled(
  Box,
  preventForwardProps(['isMobile']),
)<{ isMobile: boolean }>(
  ({ isMobile }) => css`
    display: flex;
    flex-direction: column;
    padding: 24px 20px ${isMobile && '60px'};
    width: ${!isMobile && '414px'};
  `,
);

export const StyledHeadline = elementsStyled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledFooterContainer = elementsStyled(Box)`
  margin-top: 24px;
`;

export const StyledCloseButton = elementsStyled(IconButton)(
  ({ theme }) => css`
    background: ${theme.palette.tertiary.mainTransparent40};
    color: ${theme.palette.secondary.font};

    &:hover {
      background: ${theme.palette.tertiary.mainTransparent40};
    }
  `,
);

export const StyledTypography = elementsStyled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
