import { CardTypes } from 'elements/types';
import i18n from 'localizations';

export const getCardType = (type: CardTypes) => {
  const types = {
    [CardTypes.AmericanExpress]: i18n.t('elements.features.paymentMethods.americanExpress'),
    [CardTypes.Diners]: i18n.t('elements.features.paymentMethods.dinners'),
    [CardTypes.Discover]: i18n.t('elements.features.paymentMethods.discover'),
    [CardTypes.JCB]: i18n.t('elements.features.paymentMethods.jcb'),
    [CardTypes.Maestro]: i18n.t('elements.features.paymentMethods.maestro'),
    [CardTypes.Mastercard]: i18n.t('elements.features.paymentMethods.mastercard'),
    [CardTypes.Visa]: i18n.t('elements.features.paymentMethods.visa'),
    [CardTypes.Other]: i18n.t('elements.features.paymentMethods.other'),
  };
  return types[type];
};
