import { useEffect, useState } from 'react';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { Auth0ClientSingleton } from 'auth0/auth0-client-singleton';

const ACCESS_DENIED = 'access_denied';
const LOGIN_REQUIRED = 'login_required';
const MFA_ERROR_DESCRIPTION = 'Multifactor authentication required';

export const useAuth = () => {
  const [isProcessing, setIsProcessing] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    async function fetchIsAuthenticated() {
      setIsLoading(true);
      let auth0: Auth0Client | undefined;
      try {
        auth0 = await Auth0ClientSingleton.getInstance();
        const token = await auth0.getTokenSilently();
        setIsAuthenticated(!!token);
      } catch (error) {
        setIsAuthenticated(false);
        if (
          error.error === ACCESS_DENIED ||
          (error.error === LOGIN_REQUIRED && error.error_description === MFA_ERROR_DESCRIPTION)
        ) {
          await auth0?.logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          });
        }
      } finally {
        setIsLoading(false);
        setIsProcessing(false);
      }
    }

    fetchIsAuthenticated();
  }, []);

  return { isProcessing, isLoading, isAuthenticated };
};
