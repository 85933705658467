import React from 'react';
import { LeftCell } from 'components/shared/detail-page/rows/shared/LeftCell';
import { RightCell } from 'components/shared/detail-page/rows/shared/RightCell';
import { RowBox } from 'components/shared/detail-page/rows/shared/RowBox';
import { IDefaultRow } from 'ui-interfaces/user-details/rows/i-default-row';

type Props = {
  data: IDefaultRow;
  hasBottomBorder?: boolean;
};

export const DefaultRow: React.FC<Props> = ({ data, hasBottomBorder = true }) => {
  return (
    <RowBox hasBottomBorder={hasBottomBorder}>
      <LeftCell value={data.label} />
      <RightCell value={data.value} />
    </RowBox>
  );
};
