import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledForwardIcon = elementsStyled(KeyboardBackspaceIcon)(
  ({ theme }) => css`
    transform: rotate(180deg);
    color: ${theme.palette.info.main};
    cursor: pointer;
  `,
);
