import { Input } from 'ui-interfaces/shared/inputs/input';
import { UpdateCustodialAccountDetailRequestModel } from 'models/request/custodial-accounts/update-custodial-account-detail-request-model';
import { CustodialAccountLabel } from 'models/enums/custodial-accounts/custodial-account-label';
import { CaNamingInput } from 'ui-enums/custodial-accounts/inputs/ca-naming-input';
import { InputStateCollector } from 'ui-interfaces/shared/inputs/input-state-collector';

export class CaInputsStateCollectorViewModel implements InputStateCollector<UpdateCustodialAccountDetailRequestModel> {
  private inputs: Input<string | CustodialAccountLabel, CaNamingInput>[];

  constructor(inputs: Input<string | CustodialAccountLabel, CaNamingInput>[]) {
    this.inputs = inputs;
  }

  isStateChanged(): boolean {
    return this.inputs.some(el => el.hasValueChanged());
  }

  isStateTouched(): boolean {
    return this.inputs.some(el => el.isTouched);
  }

  isStateValid(): boolean {
    return this.inputs.every(el => el.isValid);
  }

  getState(): UpdateCustodialAccountDetailRequestModel {
    return this.inputs.reduce((acc, cur) => {
      if (cur.uiElementType === CaNamingInput.name) {
        acc.name = (cur as Input<string, CaNamingInput>).value.trim();
      }

      if (cur.uiElementType === CaNamingInput.label) {
        acc.label = (cur as Input<CustodialAccountLabel, CaNamingInput>).value;
      }

      return acc;
    }, {} as UpdateCustodialAccountDetailRequestModel);
  }

  reset(): void {
    this.inputs.forEach(el => el.reset());
  }
}
