import { Filterable } from 'ui-interfaces/shared/filters/filterable';
import { Filter } from 'filters/interfaces/filter';
import { SelectionMacroCommand } from 'filters/commands/selection-macro-command';
import { getCustodialAccountsFilters } from 'utils/custodial-accounts/get-custodial-accounts-filters';
import { createCustodialAccountsFilters } from './create-custodial-accounts-filters';

export class CustodialAccountsListFiltersViewModel implements Filterable {
  readonly filters: Filter[];

  readonly selectionMacroCommand: SelectionMacroCommand;

  constructor() {
    const filterable = getCustodialAccountsFilters(createCustodialAccountsFilters());
    this.filters = filterable.filters;
    this.selectionMacroCommand = filterable.selectionMacroCommand;
  }
}
