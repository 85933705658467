import React from 'react';
import { BaseInputProps } from 'elements/components/BaseInput';
import NumericFormatInput, { NumericFormatInputProps } from './NumericFormatInput';
import { StyledBaseInput } from './styled';

export const NumberInput: React.FC<BaseInputProps & NumericFormatInputProps> = ({
  prefix = '',
  decimalScale,
  ...rest
}) => (
  <StyledBaseInput
    {...rest}
    InputProps={{
      inputComponent: NumericFormatInput,
    }}
    InputLabelProps={{ shrink: true }}
    // eslint-disable-next-line react/jsx-no-duplicate-props
    inputProps={{ prefix, decimalScale }}
  />
);
