import { css } from '@mui/material/styles';
import { ReactComponent as QuestionMarkIcon } from 'elements/assets/icons/questionMarkIcon.svg';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { preventForwardProps } from 'elements/utils';

export const StyledQuestionMarkIcon = elementsStyled(
  QuestionMarkIcon,
  preventForwardProps(['isMobile']),
)<{ isMobile: boolean }>(
  ({ theme: { palette }, isMobile }) => css`
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    padding: 8px;
    margin: 4px;
    color: ${palette.primary.main};
    background-color: ${palette.primary.mainTransparent10};
    border-radius: 50%;

    ${!isMobile &&
    css`
      padding: 12px;
      width: 44px;
      height: 44px;
      position: absolute;
      z-index: 20;
      bottom: 130px;
      right: 40px;

      &:hover {
        cursor: pointer;
      }
    `}
  `,
);
