import React from 'react';
import { LeftCell } from 'components/shared/detail-page/rows/shared/LeftCell';
import { RowBox } from 'components/shared/detail-page/rows/shared/RowBox';
import { IBlockchainRow } from 'ui-interfaces/token-details/rows/i-blockchain-row';
import { RightCell } from './RightCell';

type Props = {
  data: IBlockchainRow;
  hasBottomBorder?: boolean;
};

export const BlockchainRow: React.FC<Props> = ({ data, hasBottomBorder = true }) => {
  return (
    <RowBox hasBottomBorder={hasBottomBorder}>
      <LeftCell value={data.label} />
      <RightCell network={data.network} />
    </RowBox>
  );
};
