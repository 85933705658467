import { TransferDirectionEnum } from 'elements/element-transfer/types';
import i18n from 'localizations';

const transferPageTitleMapping: { [key in TransferDirectionEnum]: string } = {
  [TransferDirectionEnum.Deposit]: i18n.t('elements.transfer.customizableLabels.depositTitle'),
  [TransferDirectionEnum.Withdrawal]: i18n.t('elements.transfer.customizableLabels.withdrawalAloneTitle'),
  [TransferDirectionEnum.DepositWithdrawal]: i18n.t('elements.transfer.customizableLabels.title'),
};

export default transferPageTitleMapping;
