import i18n from 'localizations';
import { Environment } from 'ui-enums/environment/environment';

export const getEnvLabel = (baseUrl: string): string | undefined => {
  const env = baseUrl.split('.')[1];
  switch (env) {
    case Environment.dev:
      return i18n.t('environment.label.dev');

    case Environment.int:
      return i18n.t('environment.label.int');

    case Environment.stage:
      return i18n.t('environment.label.stage');

    case Environment.sandbox:
      return i18n.t('environment.label.sandbox');

    default:
      return undefined;
  }
};
