import React from 'react';
import { PaddedRowBox } from 'components/shared/detail-page/rows/shared/PaddedRowBox';
import { IMt103Indent1NoValueRow } from 'ui-interfaces/transaction-details/rows/mt103/i-mt103-indent-1-no-value-row';
import { LeftCell } from './LeftCell';

type Props = {
  data: IMt103Indent1NoValueRow;
  hasBottomBorder?: boolean;
};

export const MT103Indent1NoValueRow: React.FC<Props> = ({ data, hasBottomBorder }) => {
  return (
    <PaddedRowBox hasBottomBorder={hasBottomBorder}>
      <LeftCell label={data.label} />
    </PaddedRowBox>
  );
};
