import React from 'react';
import { StatusCellProps } from 'ui-interfaces/statuses/status-cell-props';
import { RoleTemplate } from 'models/enums/user/role-template';

// labels - start
import { ReactComponent as AdminLabel } from 'assets/icons/settings/roles/labels/admin.svg';
import { ReactComponent as EditorLabel } from 'assets/icons/settings/roles/labels/editor.svg';
import { ReactComponent as ViewerLabel } from 'assets/icons/settings/roles/labels/viewer.svg';
import { ReactComponent as OwnerLabel } from 'assets/icons/settings/roles/labels/owner.svg';
// labels - end

export const mapRoleToRoleCellProps = (roleTemplate: RoleTemplate): StatusCellProps => {
  switch (roleTemplate) {
    case RoleTemplate.editor:
      return {
        labelIcon: <EditorLabel />,
        pillowProps: {
          color: 'warning',
          colorType: 'light3',
        },
      };

    case RoleTemplate.viewer:
      return {
        labelIcon: <ViewerLabel />,
        pillowProps: {
          color: 'info',
          colorType: 'light3',
        },
      };

    case RoleTemplate.admin:
      return {
        labelIcon: <AdminLabel />,
        pillowProps: {
          color: 'magenta',
          colorType: 'light',
        },
      };

    case RoleTemplate.owner:
      return {
        labelIcon: <OwnerLabel />,
        pillowProps: {
          color: 'error',
          colorType: 'light2',
        },
      };

    default:
      return {
        labelIcon: <ViewerLabel />,
        pillowProps: {
          color: 'info',
          colorType: 'light3',
        },
      };
  }
};
