import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';
import { UserDetailsRowHeight } from 'ui-enums/user-details/user-details-row-height';

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  alignItems: 'flex-start',
  height: pxToRem(UserDetailsRowHeight['45px']),
}));
