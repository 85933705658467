import { styled, css, Box } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';

export const StyledBox = styled(Box)(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-template-rows: repeat(2, max-content);
    grid-auto-columns: max-content;
    grid-auto-rows: max-content;
    grid-column-gap: ${pxToRem(theme.gaps._32px)};
    grid-row-gap: ${pxToRem(theme.gaps._16px)};
    grid-auto-flow: column;
  `,
);
