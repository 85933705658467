import React from 'react';
import { Typography } from '@mui/material';
import { RightCellBox } from './RightCellBox';

type Props = {
  value: string;
};

export const RightCell: React.FC<Props> = ({ value }) => {
  return (
    <RightCellBox>
      <Typography color="grey.600" variant="bodyDefaultBook">
        {value}
      </Typography>
    </RightCellBox>
  );
};
