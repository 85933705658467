import React from 'react';
import { Typography } from '@mui/material';
import { CustodialAccountTypeEnum } from 'elements/types';
import { ReactComponent as AccountBalanceWalletIcon } from 'elements/element-transfer/assets/icons/accountBalanceWallet.svg';
import { getAccountData } from 'elements/element-transfer/utils/getAccountData';
import { StyledContainer, StyledIconBackground, StyledTextContainer, StyledTypography } from './styled';

type Props = {
  label: string;
  address: string;
  accountType?: CustodialAccountTypeEnum;
  className?: string;
};

const SourceDestinationComponent: React.FC<Props> = ({ label, address, accountType, className }) => {
  const postfix = `*${address.slice(-4)}`;

  const custodialAccountText = accountType && getAccountData(accountType).title.concat(' ', postfix);

  const CustodialAccountIcon = accountType && getAccountData(accountType).Icon;

  return (
    <StyledContainer isCustodialAccountIcon={!!accountType} className={className}>
      <StyledIconBackground isCustodialAccountIcon={!!accountType}>
        {accountType && CustodialAccountIcon ? <CustodialAccountIcon /> : <AccountBalanceWalletIcon />}
      </StyledIconBackground>
      <StyledTextContainer>
        <Typography variant="captionMedium" color="secondary.font">
          {label}
        </Typography>
        <StyledTypography variant="bodyDefaultMedium" color="primary.font">
          {accountType ? custodialAccountText : address}
        </StyledTypography>
      </StyledTextContainer>
    </StyledContainer>
  );
};

export default SourceDestinationComponent;
