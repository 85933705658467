export const checkIfArraysEqual = (a: string[], b: string[]): boolean => {
  if (a.length !== b.length) return false;
  const copyA = [...a];
  const copyB = [...b];

  copyA.sort();
  copyB.sort();

  for (let i = 0; i < copyA.length; i += 1) {
    if (copyA[i] !== copyB[i]) return false;
  }
  return true;
};
