import React from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';

import innerRoutes from 'navigation/routes/inner-routes';
import authRoutes from 'navigation/routes/auth-routes';

export const MainNavigation: React.FC = () => (
  <BrowserRouter>
    <Routes>
      {authRoutes()}
      {innerRoutes()}
    </Routes>
  </BrowserRouter>
);
