import { UserBaseResponseModel } from 'models/response/users/user-base-response-model';
import { UsersListRow } from 'ui-interfaces/row-interfaces/users/users-list-row';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
// view models - start
import { UserViewModelWithCurrentUser } from 'view-models/users/list/decorators/user-vm-with-current-user';
import { UserBaseViewModel } from 'view-models/users/list/base/user-base-vm';
import { AdminUserViewModel } from 'view-models/users/list/admin-user-vm';
import { EditorUserViewModel } from 'view-models/users/list/editor-user-vm';
import { ViewerUserViewModel } from 'view-models/users/list/viewer-user-vm';
import { RoleTemplate } from 'models/enums/user/role-template';
import { UserRoleTemplateResponseModel } from 'models/response/users/user-role-template-response-model';
import { OwnerUserViewModel } from 'view-models/users/list/owner-user-vm';
// view models - end

export const createUserViewModel = (
  user: UserBaseResponseModel,
  currentUser: ShortUserResponseModel,
  roleTemplates?: UserRoleTemplateResponseModel[],
): UsersListRow => {
  switch (user.roleTemplate) {
    case RoleTemplate.admin:
      return new UserViewModelWithCurrentUser(new AdminUserViewModel(user), user, currentUser, roleTemplates);
    case RoleTemplate.editor:
      return new UserViewModelWithCurrentUser(new EditorUserViewModel(user), user, currentUser, roleTemplates);
    case RoleTemplate.viewer:
      return new UserViewModelWithCurrentUser(new ViewerUserViewModel(user), user, currentUser, roleTemplates);
    case RoleTemplate.owner:
      return new UserViewModelWithCurrentUser(new OwnerUserViewModel(user), user, currentUser, roleTemplates);
    default:
      return new UserBaseViewModel(user);
  }
};
