import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';

export const PropertyContainer = styled<React.FC<{ isLast?: boolean; index: number }>>(Box)(
  ({ theme, isLast, index }) => ({
    width: pxToRem(155),
    height: pxToRem(64),
    border: `1px solid ${theme.palette.grey['200']}`,
    borderRadius: pxToRem(theme.gaps._8px),
    paddingInline: pxToRem(theme.gaps._16px),
    paddingTop: pxToRem(theme.gaps._16px),
    paddingBottom: pxToRem(theme.gaps._8px),
    display: 'flex',
    flexDirection: 'column',
    marginRight: isLast && index % 6 !== 0 ? 0 : theme.gaps._12px,
    marginBottom: index % 6 === 0 ? 0 : theme.gaps._12px,
  }),
);
