import { UserDetailResponseModel } from 'models/response/users/user-detail-response-model';

export const getSelectedPermissionsIndexesFromUserDetailResponseModel = (
  userDetailResponseModel: UserDetailResponseModel,
): Set<number> => {
  const set = new Set<number>();

  userDetailResponseModel.permissions.forEach((p, index) => {
    if (p.value) {
      set.add(index);
    }
  });

  return set;
};
