import React from 'react';
import { Typography } from '@mui/material';
import { Gap } from 'components/shared/Gap';
import { ISubHeaderRow } from 'ui-interfaces/user-details/rows/i-sub-header-row';
import { RowBox } from './RowBox';

type Props = {
  data: ISubHeaderRow;
};

export const SubHeaderRow: React.FC<Props> = ({ data }) => {
  return (
    <RowBox hasBottomBorder>
      <Gap size="_16px" />
      <Typography variant="overlineDefaultBold" color="grey.500">
        {data.title}
      </Typography>
      <Gap size="_16px" />
    </RowBox>
  );
};
