export enum CryptoAssetType {
  ether = 'eth',
  matic = 'matic',
  florins = 'flrns',
  bitcoin = 'btc',
  usdCoin = 'usdc',
  solana = 'sol',
  wrappedETH = 'weth',
  ada = 'ada',
  avax = 'avax',
  dot = 'dot',
  shib = 'shib',
  bat = 'bat',
  bnb = 'bnb',
  busd = 'busd',
}

export enum AdditionalCryptoAsset {
  algo = 'algo',
  link = 'link',
  xlm = 'xlm',
  ltc = 'ltc',
  uni = 'uni',

  doge = 'doge',
  crv = 'crv',
  aave = 'aave',
  bch = 'bch',
  xtz = 'xtz',
  comp = 'comp',

  grt = 'grt',
  atom = 'atom',
  usdt = 'usdt',
}
export const CombinedCryptoAsset = { ...CryptoAssetType, ...AdditionalCryptoAsset };

export type TCombinedCryptoAssetKeys = keyof typeof CombinedCryptoAsset;
export type TCombinedCryptoAsset = typeof CombinedCryptoAsset[TCombinedCryptoAssetKeys];

export enum CryptoAssetNetwork {
  // Ethereum
  eth_mainnet = 'eth_mainnet',
  eth_goerli = 'eth_goerli',
  eth_sepolia = 'eth_sepolia',
  // Polygon
  matic_polygonMainnet = 'matic_polygonMainnet',
  matic_polygonMumbai = 'matic_polygonMumbai',
  // TODO add support for trading later
  // Polygon on Ethereum
  // matic_mainnet = 'matic_mainnet',
  // matic_goerli = 'matic_goerli',
  // Florins
  flrns_mainnet = 'flrns_mainnet',
  flrns_goerli = 'flrns_goerli',
  // Bitcoin
  btc_bitcoinMainnet = 'btc_bitcoinMainnet',
  btc_bitcoinTestnet = 'btc_bitcoinTestnet',
  // USD Coin
  usdc_mainnet = 'usdc_mainnet',
  usdc_goerli = 'usdc_goerli',
  usdc_sepolia = 'usdc_sepolia',
  // USD Coin on Polygon
  usdc_polygonMainnet = 'usdc_polygonMainnet',
  usdc_polygonMumbai = 'usdc_polygonMumbai',
  // Solana
  sol_solanaMainnet = 'sol_solanaMainnet',
  sol_solanaDevnet = 'sol_solanaDevnet',
  // Wrapped ETH
  weth_mainnet = 'weth_mainnet',
  weth_goerli = 'weth_goerli',
  // Wrapped ETH on Polygon
  weth_polygonMainnet = 'weth_polygonMainnet',
  weth_polygonMumbai = 'weth_polygonMumbai',
  // Cardano ADA
  ada_cardanoMainnet = 'ada_cardanoMainnet',
  ada_cardanoTestnet = 'ada_cardanoTestnet',
  // Avalanche AVAX
  avax_avalancheMainnet = 'avax_avalancheMainnet',
  avax_avalancheFuji = 'avax_avalancheFuji',
  // Polkadot DOT
  dot_polkadotMainnet = 'dot_polkadotMainnet',
  dot_polkadotWestend = 'dot_polkadotWestend',
  // Shiba Inu
  shib_mainnet = 'shib_mainnet',
  shib_goerli = 'shib_goerli',
  // Basic Attention Token BAT
  bat_mainnet = 'bat_mainnet',
  bat_goerli = 'bat_goerli',
  // BNB
  bnb_bnbSmartChainMainnet = 'bnb_bnbSmartChainMainnet',
  bnb_bnbSmartChainTestnet = 'bnb_bnbSmartChainTestnet',
  // Binance USD
  busd_bnbSmartChainMainnet = 'busd_bnbSmartChainMainnet',
  busd_bnbSmartChainTestnet = 'busd_bnbSmartChainTestnet',
  // Binance USD on Ethereum
  busd_mainnet = 'busd_mainnet',
  busd_goerli = 'busd_goerli',
}

export enum AdditionalCryptoAssetNetwork {
  // Stellar
  xlm_stellarMainnet = 'xlm_stellarMainnet',
  xlm_stellarTestnet = 'xlm_stellarTestnet',
  // Chainlink
  link_mainnet = 'link_mainnet',
  link_goerli = 'link_goerli',
  // Uniswap
  uni_mainnet = 'uni_mainnet',
  uni_goerli = 'uni_goerli',
  // Algorand
  algo_algorandMainnet = 'algo_algorandMainnet',
  algo_algorandTestnet = 'algo_algorandTestnet',
  // Litecoin
  ltc_litecoinMainnet = 'ltc_litecoinMainnet',
  ltc_litecoinTestnet = 'ltc_litecoinTestnet',

  // Dogecoin
  doge_dogeMainnet = 'doge_dogeMainnet',
  doge_dogeTestnet = 'doge_dogeTestnet',
  // Curve DAO Token
  crv_mainnet = 'crv_mainnet',
  crv_goerli = 'crv_goerli',
  // AAVE
  aave_mainnet = 'aave_mainnet',
  aave_goerli = 'aave_goerli',
  // Bitcoin Cash
  bch_bchMainnet = 'bch_bchMainnet',
  bch_bchTestnet = 'bch_bchTestnet',
  // Tezos
  xtz_tezosMainnet = 'xtz_tezosMainnet',
  xtz_tezosTestnet = 'xtz_tezosTestnet',
  // Compound
  comp_mainnet = 'comp_mainnet',
  comp_goerli = 'comp_goerli',

  // The Graph
  grt_mainnet = 'grt_mainnet',
  grt_goerli = 'grt_goerli',
  // Cosmos
  atom_cosmosHub = 'atom_cosmosHub',
  atom_cosmosHubTestnet = 'atom_cosmosHubTestnet',
  // USDT
  usdt_mainnet = 'usdt_mainnet',
  usdt_goerli = 'usdt_goerli',
}

export const CombinedCryptoAssetNetwork = {
  ...CryptoAssetNetwork,
  ...AdditionalCryptoAssetNetwork,
};

type TCombinedCryptoAssetNetworkKeys = keyof typeof CombinedCryptoAssetNetwork;
export type TCombinedCryptoAssetNetwork = typeof CombinedCryptoAssetNetwork[TCombinedCryptoAssetNetworkKeys];

export enum CryptoNetwork {
  mainnet = 'mainnet',
  ropsten = 'ropsten',
  rinkeby = 'rinkeby',
  goerli = 'goerli',
  sepolia = 'sepolia',
  kovan = 'kovan',
  polygonMainnet = 'polygonMainnet',
  polygonMumbai = 'polygonMumbai',
  bitcoinMainnet = 'bitcoinMainnet',
  bitcoinTestnet = 'bitcoinTestnet',
  solanaMainnet = 'solanaMainnet',
  solanaDevnet = 'solanaDevnet',
  solanaTestnet = 'solanaTestnet',
  cardanoMainnet = 'cardanoMainnet',
  cardanoTestnet = 'cardanoTestnet',
  avalancheMainnet = 'avalancheMainnet',
  avalancheFuji = 'avalancheFuji',
  polkadotMainnet = 'polkadotMainnet',
  polkadotWestend = 'polkadotWestend',
  bnbSmartChainMainnet = 'bnbSmartChainMainnet',
  bnbSmartChainTestnet = 'bnbSmartChainTestnet',
}

export enum AdditionalCryptoNetwork {
  stellarMainnet = 'stellarMainnet',
  stellarTestnet = 'stellarTestnet',
  algorandMainnet = 'algorandMainnet',
  algorandTestnet = 'algorandTestnet',
  litecoinMainnet = 'litecoinMainnet',
  litecoinTestnet = 'litecoinTestnet',

  dogeMainnet = 'dogeMainnet',
  dogeTestnet = 'dogeTestnet',
  bchMainnet = 'bchMainnet',
  bchTestnet = 'bchTestnet',
  tezosMainnet = 'tezosMainnet',
  tezosTestnet = 'tezosTestnet',

  cosmosHub = 'cosmosHub',
  cosmosHubTestnet = 'cosmosHubTestnet',
}

export const CombinedCryptoNetwork = { ...CryptoNetwork, ...AdditionalCryptoNetwork };

type TCombinedCryptoNetworkKeys = keyof typeof CombinedCryptoNetwork;
export type TCombinedCryptoNetwork = typeof CombinedCryptoNetwork[TCombinedCryptoNetworkKeys];

export enum CryptoAssetSymbols {
  ETH = 'ETH',
  MAT = 'MATIC',
  FLRNS = 'FLRNS',
  BTC = 'BTC',
  USDC = 'USDC',
  SOL = 'SOL',
  WETH = 'WETH',
  ADA = 'ADA',
  AVAX = 'AVAX',
  DOT = 'DOT',
  SHIB = 'SHIB',
  BAT = 'BAT',
  BNB = 'BNB',
  BUSD = 'BUSD',
}

export enum AdditionalCryptoAssetSymbols {
  ALGO = 'ALGO',
  LINK = 'LINK',
  XLM = 'XLM',
  LTC = 'LTC',
  UNI = 'UNI',

  DOGE = 'DOGE',
  CRV = 'CRV',
  AAVE = 'AAVE',
  BCH = 'BCH',
  XTZ = 'XTZ',
  COMP = 'COMP',

  GRT = 'GRT',
  ATOM = 'ATOM',
  USDT = 'USDT',
}

export const CombinedCryptoAssetSymbols = {
  ...CryptoAssetSymbols,
  ...AdditionalCryptoAssetSymbols,
};

// TODO consider having CommonCoin types for combined and not combined types of assets and networks, to avoid passing extra types for receive element
export type CommonCoin = {
  assetType: TCombinedCryptoAsset;
  networkList: [TCombinedCryptoNetwork, string][];
};
