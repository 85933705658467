import { Box, Typography } from '@mui/material';
import { css } from '@mui/material/styles';
import { Button } from 'elements/components';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledContainer = elementsStyled(Box)(css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`);

export const StyleErrorStateSubtext = elementsStyled(Typography)(css`
  margin-top: 16px;
  margin-bottom: 38px;
  text-align: center;
`);

export const StyledButton = elementsStyled(Button)`
  max-width: 335px;
`;
