import React from 'react';
import { SideMenuIcon } from 'ui-enums/side-menu/side-menu-icon';
// icons - start
import { ReactComponent as IdentitiesFadedIcon } from 'assets/icons/side-menu/identities.svg';
import { ReactComponent as CustodialAccountsFadedIcon } from 'assets/icons/side-menu/custodial-accounts-faded.svg';
import { ReactComponent as TransactionsFadedIcon } from 'assets/icons/side-menu/transactions.svg';
import { ReactComponent as WalletFadedIcon } from 'assets/icons/side-menu/wallet.svg';
import { ReactComponent as SettingsFadedIcon } from 'assets/icons/side-menu/settings-faded.svg';
// icons - end

export const getSideMenuFadedIcon = (sideMenuIcon: SideMenuIcon) => {
  switch (sideMenuIcon) {
    case SideMenuIcon.identities: {
      return <IdentitiesFadedIcon />;
    }
    case SideMenuIcon.custodialAccounts: {
      return <CustodialAccountsFadedIcon />;
    }
    case SideMenuIcon.transactions: {
      return <TransactionsFadedIcon />;
    }
    case SideMenuIcon.wallets: {
      return <WalletFadedIcon />;
    }
    case SideMenuIcon.settings: {
      return <SettingsFadedIcon />;
    }
    default: {
      return <TransactionsFadedIcon />;
    }
  }
};
