import { EditOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledEditButton = elementsStyled(IconButton)(
  css`
    padding: 0px;
  `,
);

export const StyledIcon = elementsStyled(EditOutlined)(
  ({ theme }) =>
    css`
      color: ${theme.palette.primary.main};
    `,
);
