import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { TradeFlow } from 'elements/element-trade/types';
import { BannerType, EstimatedValue, InlineBanner, Spacer, Stack } from 'elements/components';
import { formatCryptoBalance, formatUsdBalance } from 'elements/utils';
import { calculateCoinAmount, calculateDollarAmount } from 'elements/element-trade/utils/calculateTransactionAmount';
import { orderDetailsLabels } from 'elements/element-trade/utils/labelsMapping';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { AssetImage } from 'components/shared/Cells/AssetImage';
import { ICoinListItem } from 'ui-interfaces/elements/i-coin-list-item';
import { StyledDivider } from './styled';

type Props = {
  amount: string;
  coinPrice: number;
  assetData: ICoinListItem;
  isRemark?: boolean;
  isProcessingError?: boolean;
  processingErrorMessage?: string;
  processingErrorTraceId?: string;
  flowType: TradeFlow;
  isInfoBanner?: boolean;
};

export const OrderDetailsNew: React.FC<Props> = ({
  amount,
  coinPrice,
  assetData,
  isRemark = true,
  isProcessingError = false,
  processingErrorMessage,
  processingErrorTraceId,
  flowType,
  isInfoBanner,
  // TODO add balance and linking errors
}) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  const coinAmount = calculateCoinAmount(amount, coinPrice);

  const dollarAmount = calculateDollarAmount(amount, coinPrice);

  const defaultErrorMessage =
    flowType === TradeFlow.Buy
      ? t('elements.trade.orderDetails.processingBuyErrorLabel')
      : t('elements.trade.orderDetails.processingSellErrorLabel');

  return (
    <>
      {isProcessingError && (
        <>
          <InlineBanner text={processingErrorMessage || defaultErrorMessage} errorTraceId={processingErrorTraceId} />
          <Spacer size={32} />
        </>
      )}
      <Stack alignCenter>
        {!isMobile && <Spacer size={8} />}
        <AssetImage
          url={assetData.asset.imageUrl}
          size={assetData.size}
          defaultAssetIcon={assetData.asset.defaultAssetIcon}
        />

        <Spacer size={16} />
        <Typography variant="h3" color="primary.font">
          {flowType === TradeFlow.Buy ? formatUsdBalance(+amount, isMobile) : amount}
        </Typography>
        <Spacer size={4} />

        <Typography variant="bodyDefaultBook" color="secondary.font">
          {assetData.asset.longName} ({assetData.asset.shortName})
        </Typography>
        <Spacer size={32} />
      </Stack>

      {flowType === TradeFlow.Sell && isInfoBanner && (
        <>
          <InlineBanner type={BannerType.Info} text={t('elements.trade.orderDetails.infoBanner')} />
          <Spacer size={32} />
        </>
      )}

      <EstimatedValue
        label={
          isRemark && flowType === TradeFlow.Sell
            ? orderDetailsLabels[flowType].estimatedPrice(assetData.asset.shortName).concat('*')
            : orderDetailsLabels[flowType].estimatedPrice(assetData.asset.shortName)
        }
        value={formatUsdBalance(coinPrice, isMobile)}
      />

      {flowType === TradeFlow.Buy && (
        <>
          <Spacer size={12} />
          <EstimatedValue
            label={
              <>
                {t('elements.trade.orderDetails.estimatedAmount', { coinAbbreviation: assetData.asset.shortName })}
                {isRemark && '*'}
              </>
            }
            value={formatCryptoBalance(coinAmount, { withCommas: true })}
          />
        </>
      )}

      <Spacer size={20} />
      <StyledDivider />
      <Spacer size={16} />

      <EstimatedValue
        label={orderDetailsLabels[flowType].totalValue}
        value={
          flowType === TradeFlow.Buy
            ? formatUsdBalance(coinAmount * coinPrice, isMobile)
            : formatUsdBalance(dollarAmount, isMobile)
        }
        isTotal
        fontVariant="subtitleMedium"
      />

      <Spacer size={flowType === TradeFlow.Buy ? 28 : 32} />

      {isRemark && (
        <Typography variant="bodySmallBook" color="secondary.font" align="center">
          *{orderDetailsLabels[flowType].remark}
        </Typography>
      )}
    </>
  );
};
