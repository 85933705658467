import React from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as InfoIcon } from 'assets/icons/info-icon.svg';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import { Gap } from 'components/shared/Gap';

interface Props {
  tooltipMessage: string;
  columnName: string;
}

export const AccountBalanceHeaderCell: React.FC<Props> = ({ tooltipMessage, columnName }) => {
  return (
    <>
      <CommonTooltip placement="top" title={tooltipMessage}>
        <InfoIcon cursor="pointer" />
      </CommonTooltip>
      <Gap isHorizontal size="_8px" />
      <Typography variant="bodySmallBold" color="grey.600">
        {columnName}
      </Typography>
    </>
  );
};
