import { useState, useEffect } from 'react';
import { PathMatch, useMatch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BreadCrumbsItem } from 'components/shared/Breadcrumbs';
import { NESTED_PATHS, PATHS } from 'navigation/constants';

export const useBcUserDetailsPage = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const MATCHES_SETTINGS__USER_DETAILS = useMatch({
    path: NESTED_PATHS.SETTINGS__USER_DETAILS,
    end: true,
  });

  const [breadCrumbs, setBreadCrumbs] = useState<BreadCrumbsItem[]>([]);
  const [pathMatch, setPathMatch] = useState<PathMatch | null>(null);

  useEffect(() => {
    if (MATCHES_SETTINGS__USER_DETAILS) {
      setBreadCrumbs([
        {
          label: t('userDetailsPage.breadcrumbs.settings'),
          path: PATHS.SETTINGS,
        },
        {
          label: t('userDetailsPage.breadcrumbs.userDetails'),
        },
      ]);
      setPathMatch(MATCHES_SETTINGS__USER_DETAILS);
    }
    // eslint-disable-next-line
  }, [location]);

  return { breadCrumbs, pathMatch };
};
