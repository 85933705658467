import { DownloadCSV } from 'hooks/use-export/interfaces';
import { FilterResult } from 'filters/interfaces/filter-result';
import { API_ROUTES } from 'redux/api/api-routes';

export const onCustodialAccountsExportClick = (downloadCsv: DownloadCSV) => (filterResult?: FilterResult) => {
  downloadCsv({
    urlSegment: API_ROUTES.private.CUSTODIAL_ACCOUNTS,
    filterResult,
  });
};
