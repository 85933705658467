import { useState } from 'react';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { IdentityType } from 'models/enums/identities/identity-type';
import { API_ROUTES } from 'redux/api/api-routes';
import { MessageType, setBannerData } from 'redux/banner';
import { prepareHeader } from './utils/prepare-header';
import { parseDocumentBuffer } from './utils/parse-document-buffer';
import { fetchDocumentData } from './utils/fetch-document-data';

export type FetchDocProps = {
  identityId: string;
  sideId: string;
  documentId: string;
  type: IdentityType;
};

export const useFetchDocument = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const fetchImageContent = async ({ identityId, sideId, documentId, type }: FetchDocProps) => {
    const identityType =
      type === IdentityType.personal ? API_ROUTES.private.PERSONAL_IDENTITIES : API_ROUTES.private.BUSINESS_IDENTITIES;
    try {
      const header = await prepareHeader();
      setIsError(false);
      setIsLoading(true);

      if (!navigator.onLine) {
        dispatch(setBannerData({ type: MessageType.error, message: i18next.t('offlineNetworkMessage') }));
        setIsLoading(false);
        setIsError(true);
        return;
      }

      const res = await fetchDocumentData({
        header,
        type: identityType as IdentityType,
        identityId,
        documentId,
        sideId,
      });

      if ((res && res.status === 404) || res.status === 503 || res.status === 400) {
        setIsError(true);
        throw new Error();
      }

      const document = await parseDocumentBuffer(res);

      setIsLoading(false);
      setData(document);
    } catch (error) {
      if (error) {
        setIsLoading(false);
        if (!navigator.onLine) {
          dispatch(setBannerData({ type: MessageType.error, message: i18next.t('offlineNetworkMessage') }));
          setIsError(true);
        } else {
          dispatch(setBannerData({ type: MessageType.error, message: i18next.t('serverErrorBanner') }));
          setIsError(true);
        }
      }
    }
  };

  return { isLoading, isError, data, fetchImageContent };
};
