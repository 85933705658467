import i18n from 'localizations';
import { FileType } from 'ui-enums/file-type';

export const getFileTypeLabel = (ft: FileType) => {
  switch (ft) {
    case FileType['image/tiff']:
    case FileType['image/webp']:
    case FileType['image/jpeg']:
    case FileType['image/svg+xml']:
    case FileType['image/png']:
      return i18n.t('fileType.image');
    case FileType['image/gif']:
      return i18n.t('fileType.gif');
    case FileType['audio/aac']:
    case FileType['audio/aiff']:
    case FileType['audio/flac']:
    case FileType['audio/mpeg']:
    case FileType['audio/ogg']:
    case FileType['audio/wav']:
    case FileType['audio/x-aac']:
    case FileType['audio/x-aiff']:
    case FileType['audio/x-flac']:
      return i18n.t('fileType.audio');
    case FileType['video/mp4']:
    case FileType['video/quicktime']:
    case FileType['video/webm']:
    case FileType['video/x-matroska']:
    case FileType['video/x-msvideo']:
      return i18n.t('fileType.video');
    case FileType['application/pdf']:
    case FileType['application/msword']:
    case FileType['text/plain']:
      return i18n.t('fileType.doc');
    case FileType['model/obj']:
    case FileType['application/x-3ds']:
    case FileType['image/x-3ds']:
    case FileType['application/x-blender']:
    case FileType['model/stl']:
    case FileType['model/x.stl-binary']:
    case FileType['model/x.stl-ascii']:
    case FileType['model/gltf+json']:
    case FileType['model/gltf-binary']:
      return i18n.t('fileType.threeD');
    default:
      return i18n.t('fileType.other');
  }
};
