import { mainApi } from 'redux/api/main-api';
import { API_ROUTES } from 'redux/api/api-routes';
import { CollectionResponse } from 'redux/api/types';
import { AssetResponseModel } from 'models/response/assets/asset-response-model';
import { OperationTypeModel } from 'models/enums/assets/operation-type-model';

export interface AssetsApiParams {
  operation: OperationTypeModel;
}

const assetsApi = mainApi.injectEndpoints({
  endpoints: build => ({
    getAssets: build.query<CollectionResponse<AssetResponseModel>, AssetsApiParams>({
      query: ({ operation }) => {
        return {
          url: API_ROUTES.private.ASSETS,

          params: {
            operation,
          },
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const { useLazyGetAssetsQuery } = assetsApi;
