import dayjs from 'dayjs';
import i18n from 'localizations';
import { Filter } from 'filters/interfaces/filter';
import { SingleValueFilter } from 'filters/single-value-filter';
import { Operators } from 'filters/operators';
import { CustodialAccountsFilterProperty } from 'ui-enums/custodial-accounts/custodial-accounts-filter-property';
import { custodialAccountNumberFormatter } from 'filters/value-formatters/custodial-account-number-formatter';
import { MultiValueFilter } from 'filters/multi-value-filter';
import { AccountsValueSets } from 'filters/common-value-sets/accounts-value-sets';
import { DateFilter } from 'filters/date-filter';

export const createCustodialAccountsFilters: () => Filter[] = () => [
  new SingleValueFilter({
    property: CustodialAccountsFilterProperty.name,
    displayProperty: i18n.t('custodialAccountsPage.name'),
    operators: [Operators.contains, Operators.equals],
    operatorsDisplayValues: [i18n.t('filters.operators.contains'), i18n.t('filters.operators.equals')],
    initialOperator: Operators.contains,
    initialValue: '',
  }),
  new SingleValueFilter({
    property: CustodialAccountsFilterProperty.id,
    displayProperty: i18n.t('custodialAccountsPage.accountId'),
    operators: [Operators.equals],
    operatorsDisplayValues: [i18n.t('filters.operators.equals')],
    initialOperator: Operators.equals,
    initialValue: '',
  }),
  new SingleValueFilter({
    property: CustodialAccountsFilterProperty.ownerIdentityDisplayName,
    displayProperty: i18n.t('custodialAccountsPage.owner'),
    operators: [Operators.contains, Operators.equals],
    operatorsDisplayValues: [i18n.t('filters.operators.contains'), i18n.t('filters.operators.equals')],
    initialOperator: Operators.contains,
    initialValue: '',
  }),
  new SingleValueFilter({
    property: CustodialAccountsFilterProperty.number,
    displayProperty: i18n.t('custodialAccountsPage.accountNumber'),
    operators: [Operators.contains, Operators.equals],
    operatorsDisplayValues: [i18n.t('filters.operators.contains'), i18n.t('filters.operators.equals')],
    initialOperator: Operators.contains,
    initialValue: '',
    valueFormatter: custodialAccountNumberFormatter,
  }),
  new MultiValueFilter({
    property: CustodialAccountsFilterProperty.type,
    displayProperty: i18n.t('custodialAccountsPage.accountType'),
    operators: [Operators.equals],
    operatorsDisplayValues: [i18n.t('filters.operators.equals')],
    initialOperator: Operators.equals,
    values: AccountsValueSets.custodialAccountTypeValueSet.map(el => el.value),
    displayValues: AccountsValueSets.custodialAccountTypeValueSet.map(el => el.displayValue),
    initialValue: '',
  }),
  new DateFilter({
    property: CustodialAccountsFilterProperty.lastActivityUtc,
    displayProperty: i18n.t('custodialAccountsPage.lastActivity'),
    operators: [Operators.equals, Operators.greaterThan, Operators.lessThan],
    operatorsDisplayValues: [
      i18n.t('filters.operators.is'),
      i18n.t('filters.operators.isAfter'),
      i18n.t('filters.operators.isBefore'),
    ],
    initialOperator: Operators.equals,
    initialValue: dayjs().format(), // current date
  }),
  new MultiValueFilter({
    property: CustodialAccountsFilterProperty.status,
    displayProperty: i18n.t('custodialAccountsPage.status'),
    operators: [Operators.in],
    operatorsDisplayValues: [i18n.t('filters.operators.equals')],
    initialOperator: Operators.in,
    values: AccountsValueSets.statusValueSet.map(el => el.value),
    displayValues: AccountsValueSets.statusValueSet.map(el => el.displayValue),
    initialValue: '',
  }),
  // TODO: reveal when backend sends up-to-date usd balance
  // new SingleValueFilter({
  //   property: CustodialAccountsFilterProperty.totalUsdBalance,
  //   displayProperty: i18n.t('custodialAccountsPage.usdBalance'),
  //   operators: [Operators.equals, Operators.greaterThan, Operators.lessThan],
  //   operatorsDisplayValues: [
  //     i18n.t('filters.operators.equals'),
  //     i18n.t('filters.operators.greaterThan'),
  //     i18n.t('filters.operators.lessThan'),
  //   ],
  //   initialOperator: Operators.equals,
  //   initialValue: '',
  // })
];
