import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as ImageIcon } from 'assets/icons/doc-viewer/doc-type-image.svg';
import { ReactComponent as FileIcon } from 'assets/icons/doc-viewer/doc-type-file.svg';
import { Row } from 'components/shared/Flex/Row';
import { StatusCell } from 'components/shared/Cells/StatusCell';
import { Document } from 'ui-interfaces/identity-details/document-list/document';
import { isOverflown } from 'utils/helpers';
import { DocTypes } from 'utils/parse-format-document';
import { StyledTooltip } from 'components/shared/StyledTooltip';
import { DocumentItem, DateItem, PillItem, FileName, StyledDivider, DocumentRow } from './styled';

interface Props extends Document {
  isLastItem: boolean;
  loading?: boolean;
  onSelect: (sideId: string, documentId: string, fileName: string, docType: string) => void;
}

export const DocumentsListRow: React.FC<Props> = ({
  sideId,
  documentId,
  fileName,
  submitted,
  isLastItem,
  loading,
  onSelect,
  docType,
  statusCellProps,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const titleRef = React.useRef<HTMLSpanElement | null>(null);
  useEffect(() => {
    const isTitleCurrentlyOverflown = isOverflown(titleRef.current!);
    setShowTooltip(isTitleCurrentlyOverflown);
  }, []);

  if (!document && !loading) {
    return null;
  }

  const icon = docType === DocTypes.pdf ? <FileIcon /> : <ImageIcon />;
  const onRowClick = () => {
    onSelect(sideId, documentId, fileName, docType);
  };
  return (
    <>
      <Row>
        <DocumentItem>
          <StyledTooltip title={fileName} disableHoverListener={!showTooltip} disableFocusListener={!showTooltip}>
            <DocumentRow onClick={onRowClick}>
              {icon}
              <FileName ref={titleRef} color="primary" variant="bodyDefaultBookLink">
                {fileName}
              </FileName>
            </DocumentRow>
          </StyledTooltip>
        </DocumentItem>
        <DateItem>
          <Typography color="grey.600" variant="bodyDefaultBook">
            {submitted}
          </Typography>
        </DateItem>
        <PillItem>
          <StatusCell {...statusCellProps} />
        </PillItem>
      </Row>
      {!isLastItem && <StyledDivider />}
    </>
  );
};
