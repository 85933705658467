import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TokenDetailsUrlParams } from 'navigation/url-params-type';
import { TokenDetailByIdApiParams, useLazyGetWalletTokenDetailsByIdQuery } from 'redux/api/api-wallets';
import { useBcTokenDetailsPage } from 'hooks/breadcrumbs/use-bc-token-details-page';
import { createTokenDetailsPageViewModel } from 'factories/tokens/create-token-details-page-view-model';
import { ITokenDetailsPage } from 'ui-interfaces/token-details/i-token-details-page';
import { TokenDetailsPageContent } from 'components/wapi/tokens/TokenDetailsPageContent';

const TokenDetailsPage: React.FC = () => {
  const { walletId, tokenId } = useParams<TokenDetailsUrlParams>();

  const { breadCrumbs } = useBcTokenDetailsPage();

  const [getWalletTokenDetailsByIdTrigger, tokenDetailsByIdResult] = useLazyGetWalletTokenDetailsByIdQuery();

  const fetchWalletTokenDetailsById = useCallback(
    () => getWalletTokenDetailsByIdTrigger({ walletId: walletId!, tokenId: tokenId! } as TokenDetailByIdApiParams),
    [getWalletTokenDetailsByIdTrigger, tokenId, walletId],
  );

  const [tokenDetailsPage, setTokenDetailsPage] = useState<ITokenDetailsPage>();

  useEffect(() => {
    fetchWalletTokenDetailsById();
  }, [fetchWalletTokenDetailsById]);

  useEffect(() => {
    if (tokenDetailsByIdResult.isSuccess) {
      setTokenDetailsPage(createTokenDetailsPageViewModel(tokenDetailsByIdResult.data));
    }
  }, [tokenDetailsByIdResult.data, tokenDetailsByIdResult.isSuccess]);

  return (
    <TokenDetailsPageContent
      breadCrumbs={breadCrumbs}
      isLoading={tokenDetailsByIdResult.isLoading}
      isError={tokenDetailsByIdResult.isError}
      tokenDetailsPage={tokenDetailsPage}
    />
  );
};

export default TokenDetailsPage;
