import { StyleSheet } from '@react-pdf/renderer';
import { colors } from 'elements/theme';

// There's no possibility of using theme here
export const PDFTemplateStyles = StyleSheet.create({
  body: {
    paddingTop: 38,
    paddingHorizontal: 40,
  },
  title: {
    fontsize: 22,
    textAlign: 'center',
    marginBottom: 35,
  },
  sectionTitle: {
    fontSize: 12,
    fontWeight: 500,
    fontStyle: 'normal',
    color: `${colors.grey600}`,
    marginBottom: 4,
  },
  sectionText: {
    fontSize: 14,
    fontWeight: 500,
    fontStyle: 'normal',
    color: `${colors.grey700}`,
    marginBottom: 26,
  },
  participantInfoSectionHeader: {
    fontSize: 12,
    fontWeight: 500,
    fontStyle: 'normal',
    color: `${colors.grey600}`,
    marginBottom: 16,
  },
  participantInfoSectionTitle: {
    fontSize: 14,
    fontWeight: 500,
    fontStyle: 'normal',
    color: `${colors.grey700}`,
  },
  participantInfoSectionText: {
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'normal',
    color: `${colors.grey700}`,
  },
  participantDivider: {
    height: 42,
  },
});
