import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderDetailsItem } from 'components/shared/HeaderDetailsItem';
import { Column } from 'components/shared/Flex/Column';
import { Row } from 'components/shared/Flex/Row';
import { Gap } from 'components/shared/Gap';
import { ReactComponent as AccountIdIcon } from 'assets/icons/custodial-accounts/account-id.svg';
import { ReactComponent as OwnerIcon } from 'assets/icons/custodial-accounts/owner.svg';
import { ReactComponent as DateIcon } from 'assets/icons/identity-details/date.svg';
import { WalletsDetailsHeader } from 'ui-interfaces/wallets/wallet-details/wallets-details-header';

interface Props {
  headerProps: WalletsDetailsHeader;
  loading?: boolean;
  isOwnerDetailsItemShown: boolean;
}

const WalletDetailsHeader: React.FC<Props> = ({ headerProps, loading, isOwnerDetailsItemShown }) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Column>
        <HeaderDetailsItem
          value={headerProps.walletId}
          label={t('walletDetailsPage.walletId')}
          icon={<AccountIdIcon />}
          loading={loading}
          large
        />
        <Gap size="_16px" />
        {isOwnerDetailsItemShown && (
          <HeaderDetailsItem
            value={headerProps.owner}
            label={t('walletDetailsPage.walletOwner')}
            icon={<OwnerIcon />}
            loading={loading}
            tooltip={headerProps.ownerTooltipMessage}
          />
        )}
      </Column>
      <Gap size="_32px" isHorizontal />
      <Column>
        <HeaderDetailsItem
          value={headerProps.dateCreated}
          label={t('walletDetailsPage.walletDateCreated')}
          icon={<DateIcon />}
          loading={loading}
        />
        <Gap size="_16px" />
      </Column>
    </Row>
  );
};

export default WalletDetailsHeader;
