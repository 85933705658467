import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { CoinListItemNew } from 'elements/features/CoinsListNew/CoinListItemNew';
import useDebounce from 'elements/hooks/useDebounce';
import { BaseSelectSubheader, SearchInput } from 'elements/components';
import { BaseInputProps } from 'elements/components/BaseInput';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { mapCustodialAccountBalanceModelToISellCoinListItem } from 'utils/elements/map-custodial-account-balance-model-to-i-sell-coin-list-item';
import { mapAssetResponseModelToICoinListItem } from 'utils/elements/map-asset-response-model-to-i-coin-list-item';
import { BuyCoinElement } from 'elements/element-trade/pages/BuySellCoinPageNew/Tabs/BuyTab/BuyDropDown/BuyCoinElement';
import { AssetResponseModel } from 'models/response/assets/asset-response-model';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import { SellCoinElement } from 'elements/element-trade/pages/BuySellCoinPageNew/Tabs/SellTab/SellDropDown/SellCoinElement';
import { getUniqueIdentifierForAssetResponseModel } from 'utils/assets/get-unique-identifier-for-asset-response-model';
import { TradeFlow } from 'elements/element-trade/types';
import { StyledBaseSelect, StyledBaseSelectItem, StyledContainer, StyledSubtitle } from './styled';
import NoCoins from './NoCoins';
import { SellCoinListItem } from './SellCoinListItem';

type Props = BaseInputProps & {
  flowType: TradeFlow;
  searchPlaceholder?: string;
  coinsList: AssetResponseModel[] | CustodialAccountBalanceModel[];
  renderValueHandler: (id: string) => React.ReactElement;
  withSearch?: boolean;
};

export const renderSelectedValue =
  (coinsList: AssetResponseModel[] | CustodialAccountBalanceModel[], withBalance?: boolean) => (id: string) => {
    if (withBalance) {
      const selectedCab = (coinsList as CustodialAccountBalanceModel[]).find(cab => cab.id === id);

      return <SellCoinElement data={mapCustodialAccountBalanceModelToISellCoinListItem(selectedCab!)} />;
    }

    const selectedAsset = (coinsList as AssetResponseModel[]).find(
      a => getUniqueIdentifierForAssetResponseModel(a) === id,
    );
    return <BuyCoinElement data={mapAssetResponseModelToICoinListItem(selectedAsset!)} />;
  };

const MOBILE_DROPDOWN_HEIGHT = 300;
const DESKTOP_DROPDOWN_HEIGHT = 430;

const checkAssetFullAndShortNameContainSearchQuery = (
  assetTicker: string,
  assetName: string,
  searchedQuery: string,
): boolean => {
  return (
    assetTicker?.toLowerCase().indexOf(searchedQuery.toLowerCase()) > -1 ||
    assetName?.toLowerCase().indexOf(searchedQuery.toLowerCase()) > -1
  );
};

export const CoinsBuySellDropdown: React.FC<Props> = React.memo(
  ({ flowType, searchPlaceholder, coinsList, renderValueHandler, withSearch = true, ...otherProps }) => {
    const { t } = useTranslation();
    const { isMobile } = useIsMobile();
    const [searchQuery, setSearchQuery] = useState('');

    const debouncedSearchInputHandler = useDebounce(searchQuery, 200);

    const filteredCoinsBySearchQuery = useMemo(
      () =>
        coinsList.filter(coin => {
          const c = coin as Pick<AssetResponseModel, 'assetTicker' | 'assetName'>;
          return checkAssetFullAndShortNameContainSearchQuery(c.assetTicker, c.assetName, debouncedSearchInputHandler);
        }),

      [debouncedSearchInputHandler, coinsList],
    );

    const handleCloseSelect = () => {
      setSearchQuery('');
    };

    const preventFocusOnFirstFoundItem = (event: React.KeyboardEvent<HTMLInputElement>) => event.stopPropagation();

    if (!coinsList.length) {
      return <NoCoins />;
    }

    return (
      <StyledBaseSelect
        onClose={handleCloseSelect}
        shouldExcludeTopPadding={coinsList.length > 1}
        dropdownHeight={isMobile ? MOBILE_DROPDOWN_HEIGHT : DESKTOP_DROPDOWN_HEIGHT}
        renderValueHandler={renderValueHandler}
        {...otherProps}
      >
        {withSearch && (
          <BaseSelectSubheader>
            <SearchInput
              searchQuery={searchQuery}
              onSearch={setSearchQuery}
              placeholder={searchPlaceholder || t('elements.features.coinsDropdown.searchPlaceholder')}
              onKeyDown={preventFocusOnFirstFoundItem}
              withDiscardIcon
            />
          </BaseSelectSubheader>
        )}
        {filteredCoinsBySearchQuery?.length > 0 ? (
          filteredCoinsBySearchQuery.map(el => {
            if (flowType === TradeFlow.Sell) {
              const cab = el as CustodialAccountBalanceModel;

              return (
                <StyledBaseSelectItem key={cab.id} value={cab.id}>
                  <SellCoinListItem
                    data={mapCustodialAccountBalanceModelToISellCoinListItem(cab)}
                    withArrow={false}
                    withDivider={false}
                  />
                </StyledBaseSelectItem>
              );
            }

            return (
              <StyledBaseSelectItem
                key={getUniqueIdentifierForAssetResponseModel(el)}
                value={getUniqueIdentifierForAssetResponseModel(el)}
              >
                <CoinListItemNew
                  data={mapAssetResponseModelToICoinListItem(el)}
                  withArrow={false}
                  withDivider={false}
                />
              </StyledBaseSelectItem>
            );
          })
        ) : (
          <StyledContainer isMobile={isMobile}>
            <StyledSubtitle variant="subtitleMedium" color="primary.font">
              {t('elements.features.coinsDropdown.noResultsTitle')} “{searchQuery}”
            </StyledSubtitle>
            <Typography variant="bodyDefaultMedium" color="secondary.font">
              {t('elements.features.coinsDropdown.noResultsSubtitle')}
            </Typography>
          </StyledContainer>
        )}
      </StyledBaseSelect>
    );
  },
);
