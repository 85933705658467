import React from 'react';
import { Select } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material/Select';
import { UserLimitType } from 'models/enums/user/user-limit-type';
import { Gap } from 'components/shared/Gap';
import { LimitInputType } from 'ui-enums/create-user-form/limit-input-type';
import { RangeType } from 'models/enums/user/range-type';
import { LimitsFormSection } from './LimitFormSection';
import { StyledFormControl, StyledMenuItem, StyledNumberField, TypeFormControl } from './styled';

type Props = {
  id: string;
  onDelete: () => void;
  isDeletable: boolean;
  possibleTypeValues: UserLimitType[];
  possibleTypeValueWordings: string[];
  typeValue: UserLimitType;
  onTypeValueChange: (val: SelectChangeEvent<UserLimitType>) => void;
  maxValue: string;
  onMaxValueChange: (val: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  rangeValue: RangeType;
  onRangeValueChange: (val: SelectChangeEvent<RangeType>) => void;
  limitInputType: LimitInputType;
  isLastLimit: boolean;
};

export const Limit: React.FC<Props> = ({
  id,
  typeValue,
  possibleTypeValues,
  possibleTypeValueWordings,
  onTypeValueChange,
  maxValue,
  onMaxValueChange,
  rangeValue,
  onRangeValueChange,
  isLastLimit,
  isDeletable,
  onDelete,
  limitInputType,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <LimitsFormSection isDeletable={isDeletable} onDelete={onDelete}>
        <TypeFormControl>
          <Select IconComponent={ExpandMoreIcon} value={typeValue} onChange={onTypeValueChange}>
            {possibleTypeValues.map((possibleTypeValue, index) => {
              return (
                <StyledMenuItem key={possibleTypeValue} value={possibleTypeValue}>
                  {possibleTypeValueWordings[index]}
                </StyledMenuItem>
              );
            })}
          </Select>
        </TypeFormControl>
        <Gap size="_10px" isHorizontal />
        {limitInputType === LimitInputType.amount && (
          <StyledNumberField
            inputProps={{
              prefix: '$',
            }}
            name={id}
            value={maxValue}
            onChange={onMaxValueChange}
            placeholder={t('createUserDialog.maxValueOfAmountFieldPlaceholder')}
          />
        )}
        {limitInputType === LimitInputType.count && (
          <StyledNumberField
            inputProps={{
              decimalScale: 0,
            }}
            name={id}
            value={maxValue}
            onChange={onMaxValueChange}
            placeholder={t('createUserDialog.maxValueOfTransactionsNumberFieldPlaceholder')}
          />
        )}
        <Gap size="_10px" isHorizontal />
        <StyledFormControl>
          <Select IconComponent={ExpandMoreIcon} value={rangeValue} onChange={onRangeValueChange}>
            <StyledMenuItem value={RangeType.day}>{t('limitRangeType.day')}</StyledMenuItem>
            <StyledMenuItem value={RangeType.week}>{t('limitRangeType.week')}</StyledMenuItem>
            <StyledMenuItem value={RangeType.month}>{t('limitRangeType.month')}</StyledMenuItem>
            {limitInputType === LimitInputType.amount && (
              <StyledMenuItem value={RangeType.transaction}>{t('limitRangeType.transaction')}</StyledMenuItem>
            )}
          </Select>
        </StyledFormControl>
      </LimitsFormSection>
      {!isLastLimit && <Gap size="_16px" />}
    </>
  );
};
