import { NavigateFunction } from 'react-router-dom';
import { GridRowParams } from '@mui/x-data-grid';
import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { createTransactionViewModel } from 'factories/transactions/create-transaction-view-model';
import { getRouteWithParams } from 'utils/get-route-with-params';
import { TransactionDetailsUrlParams } from 'navigation/url-params-type';
import { PATHS } from 'navigation/constants';
import { SearchParamsConstants } from 'navigation/utils/search-params-constants';
import { FilterResult } from 'filters/interfaces/filter-result';
import { API_ROUTES } from 'redux/api/api-routes';
import { DownloadPaginatedCSV } from 'hooks/use-paginated-export/interfaces';
import { PaginatedRequestParams } from 'redux/api/types';

export const onTransactionTransform = (transaction: TransactionResponseModel) => {
  return createTransactionViewModel(transaction);
};

export const onApprovedTransactionRowClick = (navigate: NavigateFunction) => (params: GridRowParams) => {
  const { row } = params;
  navigate(
    getRouteWithParams(`${PATHS.TAPI.TRANSACTION_DETAILS}?${SearchParamsConstants.approved}=true`, {
      transactionId: row.id,
    } as TransactionDetailsUrlParams),
  );
};
export const onNonApprovedTransactionRowClick = (navigate: NavigateFunction) => (params: GridRowParams) => {
  const { row } = params;
  navigate(
    getRouteWithParams(`${PATHS.TAPI.TRANSACTION_DETAILS}?${SearchParamsConstants.approved}=false`, {
      transactionId: row.id,
    } as TransactionDetailsUrlParams),
  );
};

export const onTransactionsExportClick =
  (downloadPaginatedCsv: DownloadPaginatedCSV) => (pagination: PaginatedRequestParams, filterResult?: FilterResult) => {
    downloadPaginatedCsv({
      urlSegment: API_ROUTES.private.TRANSACTIONS,
      pagination,
      preFilterParams: {
        approved: 'true',
      },
      filterResult,
    });
  };
