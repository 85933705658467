import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { pxToRem } from 'utils/styling-utils';

export const StyledTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  // eslint-disable-next-line react/react-in-jsx-scope
  <Tooltip {...props} arrow classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.grey['700'],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    ...theme.typography.bodySmallBook,
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.grey['700'],
    padding: pxToRem(theme.gaps._16px),
    borderRadius: pxToRem(theme.gaps._8px),
  },
  [`& .${tooltipClasses.tooltipPlacementRight}`]: {
    marginLeft: `${pxToRem(theme.gaps._24px)} !important`,
  },
}));
