import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ModalProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { Modal } from 'elements/components/Modal';
import { Spacer } from 'elements/components/Spacer';
import { StyledButton, StyledCloseButton, StyledContainer, StyledHeadline } from './styled';

type Props = {
  onConfirm: () => void;
  onDecline: () => void;
  componentsProps?: ModalProps['componentsProps'];
};

export const ConfirmModal: React.FC<Props> = ({ onConfirm, onDecline, componentsProps }) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  return (
    <Modal
      open
      onClose={onDecline}
      disablePortal
      componentsProps={{
        ...componentsProps,
        root: {
          style: {
            maxWidth: '335px',
            margin: 'auto',
          },
        },
      }}
    >
      <StyledContainer>
        <StyledHeadline>
          <Typography variant="h6" color="primary.font">
            {t('elements.common.closeConfirmModal.title')}
          </Typography>
          <StyledCloseButton onClick={onDecline} isMobile={isMobile}>
            <CloseIcon />
          </StyledCloseButton>
        </StyledHeadline>

        <Typography variant="bodyDefaultBook" color="secondary.font">
          {t('elements.common.closeConfirmModal.subtitle')}
        </Typography>

        <Spacer size={36} />

        <StyledButton type="button" buttonType="secondary" onClick={onConfirm}>
          <Typography variant="buttonLarge">{t('elements.common.closeConfirmModal.buttonLabel')}</Typography>
        </StyledButton>
      </StyledContainer>
    </Modal>
  );
};
