/**
 * Default browser root font size
 */
const ROOT_FONT_SIZE = 16;

/**
 *  Default device margins
 */
export const ROOT_CONTAINER_MARGIN_MOBILE = {
  HORIZONTAL: 20,
  VERTICAL: 32,
};

export const ROOT_CONTAINER_MARGIN_DESKTOP = {
  HORIZONTAL: 40,
  VERTICAL: 40,
};

/**
 *  Default device margins
 */
export const ICON_BUTTON_PADDING = 10;

/**
 * Design screen dimensions
 */
export const DESIGN_MOBILE_DEVICE_DIMENSIONS = {
  WIDTH: 375,
  HEIGHT: 812,
};

export const DESIGN_DESKTOP_DEVICE_DIMENSIONS = {
  WIDTH: 552,
  HEIGHT: 856,
};

/**
 *  Default breakpoint for mobile-desktop transition
 */
export const CUSTOM_THEME_SM_BREAKPOINT = 450;

/**
 * Utils to convert pixels to rems
 *
 * Recommended using with: width, height of elements (e.g. buttons), fontSize, lineHeight, paddings, margins, text properties
 */
export const pxToRem = (px: number): string => `${+(px / ROOT_FONT_SIZE).toFixed(4)}rem`;

/**
 * Utils to convert design pixels to vw or vh
 *
 * Recommended using with: margin, padding, width of root container, basic containers sizes, spacer sizes
 */
export const pxToVw = (px: number): string => `${+((px * 100) / DESIGN_MOBILE_DEVICE_DIMENSIONS.WIDTH).toFixed(4)}vw`;

export const pxToVh = (px: number): string => `${+((px * 100) / DESIGN_MOBILE_DEVICE_DIMENSIONS.HEIGHT).toFixed(4)}vh`;

export const pxToVwDesktop = (px: number): string =>
  `${+((px * 100) / DESIGN_DESKTOP_DEVICE_DIMENSIONS.WIDTH).toFixed(4)}vw`;

export const pxToVhDesktop = (px: number): string =>
  `${+((px * 100) / DESIGN_DESKTOP_DEVICE_DIMENSIONS.HEIGHT).toFixed(4)}vh`;
