import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import { pxToRem } from 'utils/styling-utils';
import { StyledTooltip } from 'components/shared/StyledTooltip';

export const CommonTooltip = styled(StyledTooltip)(({ theme }) => ({
  [`& .${tooltipClasses.tooltipPlacementRight}`]: {
    paddingBlock: pxToRem(theme.gaps._12px),
    marginLeft: `${pxToRem(theme.gaps._24px + theme.gaps._12px)} !important`,
  },
}));
