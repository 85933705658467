import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import i18n from 'localizations';
import { CustodialAccountCustomAssetBalanceRow } from 'ui-interfaces/row-interfaces/custodial-accounts/custodial-account-custom-asset-balance-row';
import { NameCell } from './NameCell';
import { DescriptionCell } from './DescriptionCell';

export const columnsCustodialAccountCustomAssetBalance: GridColDef[] = [
  {
    field: 'name',
    renderCell: (params: GridRenderCellParams) => {
      const { name, symbol } = params.row as CustodialAccountCustomAssetBalanceRow;
      return <NameCell name={name} symbol={symbol} width={params.colDef.computedWidth} />;
    },
    headerName: i18n.t('custodialAccountDetailsPage.customAssetBalance.cols.name'),
    flex: 1,
  },
  {
    field: 'description',
    renderCell: (params: GridRenderCellParams) => {
      const { description } = params.row as CustodialAccountCustomAssetBalanceRow;
      return <DescriptionCell value={description} width={params.colDef.computedWidth} />;
    },
    headerName: i18n.t('custodialAccountDetailsPage.customAssetBalance.cols.description'),
    flex: 2,
  },
  {
    field: 'price',
    headerName: i18n.t('custodialAccountDetailsPage.customAssetBalance.cols.price'),
    flex: 0.5,
    headerAlign: 'right',
    align: 'right',
  },
  {
    field: 'balance',
    headerName: i18n.t('custodialAccountDetailsPage.customAssetBalance.cols.balance'),
    flex: 0.5,
    headerAlign: 'right',
    align: 'right',
  },
];
