import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  code: {
    fontSize: 8,
    fontFamily: 'Helvetica-Bold',
    lineHeight: '160%',
    letterSpacing: 0.24,
    color: '#226BD0',
    width: 24,
    marginRight: 8,
  },
  text: { fontSize: 8, fontFamily: 'Helvetica-Bold', lineHeight: '160%', letterSpacing: 0.24 },
});
