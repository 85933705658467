import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { InnerLayout } from 'components/shared/layouts/InnerLayout';
import { useLazyGetUserQuery } from 'redux/api/api-users';
import { useTransformCollectionItemResponse } from 'hooks/use-transform-collection-item-response';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { createSideMenuViewModel } from 'factories/side-menu/create-side-menu-view-model';
import { SideMenu } from 'ui-interfaces/side-menu/side-menu';
import { Auth0ClientSingleton } from 'auth0/auth0-client-singleton';
import { PATHS } from 'navigation/constants';

export const InnerLayoutContainer = () => {
  const [getUserTrigger, { data: userData, error }] = useLazyGetUserQuery();

  const [sideMenuData] = useTransformCollectionItemResponse<ShortUserResponseModel, SideMenu>(
    userData,
    createSideMenuViewModel,
  );

  const onLogOut = async () => {
    const auth0 = await Auth0ClientSingleton.getInstance();
    await auth0.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  useEffect(() => {
    getUserTrigger(undefined);
  }, [getUserTrigger]);

  if (error) {
    return <Navigate to={PATHS.GLOBAL.ERROR} replace />;
  }

  // TODO: in the future handle loading case

  return <InnerLayout sideMenuProps={sideMenuData} onLogOut={onLogOut} />;
};
