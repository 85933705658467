import { Box, IconButton, Stack } from '@mui/material';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import {
  ICON_BUTTON_PADDING,
  ROOT_CONTAINER_MARGIN_DESKTOP,
  ROOT_CONTAINER_MARGIN_MOBILE,
  preventForwardProps,
} from 'elements/utils';

export const StyledHeaderContainer = elementsStyled(
  Stack,
  preventForwardProps(['withTitle', 'isMobile']),
)<{ withTitle: boolean; isMobile: boolean }>(({ isMobile, withTitle }) => {
  const bottomPadding = withTitle ? '24px' : '0';

  return css`
    padding: ${isMobile
      ? `${ROOT_CONTAINER_MARGIN_MOBILE.VERTICAL}px ${ROOT_CONTAINER_MARGIN_MOBILE.HORIZONTAL}px ${bottomPadding}`
      : `32px ${ROOT_CONTAINER_MARGIN_DESKTOP.HORIZONTAL}px ${bottomPadding}`};
  `;
});

export const StyledIconButton = elementsStyled(IconButton)(
  ({ theme: { palette, borderRadius } }) =>
    css`
      color: ${palette.primary.font};
      padding: ${ICON_BUTTON_PADDING}px;
      border-radius: ${borderRadius};

      & .MuiTouchRipple-root .MuiTouchRipple-child {
        border-radius: ${borderRadius};
      }
    `,
);

export const StyledTopContainer = elementsStyled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

export const EmptyIconBox = elementsStyled(Box)(() => {
  const size = `${ICON_BUTTON_PADDING * 2 + 24}px`;
  return css`
    width: ${size};
    height: ${size};
  `;
});
