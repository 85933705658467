import i18n from 'localizations';
import { CompoundLabel, TransactionsRow } from 'ui-interfaces/row-interfaces/transactions';
import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { get4LastChars } from 'utils/get-4-last-chars';
import { formatCurrency } from 'utils/format-currency';
import { getSignOfTransaction } from 'utils/transactions/get-sign-of-transaction';
import { LabelLength } from 'ui-enums/label-length';
import { getDynamicAssetLabel } from 'utils/labels-mapping/get-dynamic-asset-label';
import { TransactionBaseViewModel } from '../transaction-base-vm';

export class TransactionInitialDepositViewModel extends TransactionBaseViewModel implements TransactionsRow {
  readonly source: CompoundLabel | string;

  readonly destination: CompoundLabel;

  constructor(transaction: TransactionResponseModel) {
    super(transaction);
    this.amount = `${getSignOfTransaction(transaction)}${
      transaction.amount ? formatCurrency(transaction.amount) : transaction.cryptoAmount
    } ${getDynamicAssetLabel(LabelLength.short, transaction.assetTicker, transaction.assetName)}`;

    this.source = transaction.sourceDisplayValue
      ? ({
          start: `*${get4LastChars(transaction.sourceDisplayValue ?? '')}`,
          end: i18n.t('transactionsPage.account'),
        } as CompoundLabel)
      : i18n.t('transactionsPage.noData');

    this.destination = {
      start: `*${get4LastChars(transaction.destinationDisplayValue ?? '')}`,
      end: i18n.t('transactionsPage.account'),
    } as CompoundLabel;
  }
}
