import { Filterable } from 'ui-interfaces/shared/filters/filterable';
import { Filter } from 'filters/interfaces/filter';
import { SelectionMacroCommand } from 'filters/commands/selection-macro-command';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { getIdentitiesFilters } from 'utils/identities/get-identities-filters';
import { createBusinessIdentitiesFilters } from './create-business-identities-filters';

export class BusinessIdentitiesListFiltersViewModel implements Filterable {
  readonly filters: Filter[];

  readonly selectionMacroCommand: SelectionMacroCommand;

  constructor(currentUser?: ShortUserResponseModel) {
    const filterable = getIdentitiesFilters(createBusinessIdentitiesFilters(), currentUser);
    this.filters = filterable.filters;
    this.selectionMacroCommand = filterable.selectionMacroCommand;
  }
}
