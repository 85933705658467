import React, { useCallback, useEffect } from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UsersGrid } from 'components/shared/grids/UsersGrid';
import { UserBaseResponseModel } from 'models/response/users/user-base-response-model';
import { UsersListRow } from 'ui-interfaces/row-interfaces/users/users-list-row';
import { getRoleTemplates, getUser, useLazyGetUsersQuery } from 'redux/api/api-users';
import { FilterResult } from 'filters/interfaces/filter-result';
import { useTransformCollectionResponse } from 'hooks/use-transform-collection-response';
import { createUserViewModel } from 'factories/users/create-user-vm';
import { usePermissionBasedAccess } from 'hooks/use-permission-based-access';
import { DashboardRoles } from 'models/enums/user/dashboard-roles';
import { columnsSettings } from './columns-definitions';
import { onUserCellClick } from './handlers';

type Props = {
  onCreateUserClick: () => void;
};
export const UsersGridContainer: React.FC<Props> = ({ onCreateUserClick }) => {
  const navigate = useNavigate();
  const { data: currentUserData } = useSelector(getUser.select(undefined));
  const [getUsersTrigger, usersResult] = useLazyGetUsersQuery();
  const {
    isSuccess: areRoleTemplatesLoaded,
    data: userRoleTemplatesData,
    isLoading: areUserRoleTemplatesLoading,
  } = useSelector(getRoleTemplates.select(undefined));

  const fetchUsers = useCallback(
    (page?: number, filterResult?: FilterResult) => getUsersTrigger({ page, filterResult }, false),
    [getUsersTrigger],
  );

  const onUsersTransform = useCallback(
    (user: UserBaseResponseModel) => {
      return createUserViewModel(user, currentUserData!, userRoleTemplatesData?.data);
    },
    [currentUserData, userRoleTemplatesData?.data],
  );

  const [usersRows, usersResourceCount, isUsersLoading] = useTransformCollectionResponse<
    UserBaseResponseModel,
    UsersListRow
  >(usersResult.data, onUsersTransform, usersResult.isFetching);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const isAddUserButtonDisplayed = usePermissionBasedAccess([DashboardRoles.userManager]) && areRoleTemplatesLoaded;

  const isUsersGridLoading = isUsersLoading || areUserRoleTemplatesLoading;

  return (
    <UsersGrid
      columns={columnsSettings}
      headerName={t('settingsPage.grid.title')}
      noResultsTitle={t('settingsPage.grid.noResultsTitle')}
      noResultsSubtitle={t('settingsPage.grid.noResultsSubtitle')}
      containerHeight="100%"
      rows={usersRows}
      rowCount={usersResourceCount}
      isLoading={isUsersGridLoading}
      error={usersResult.error}
      fetchOtherItems={fetchUsers}
      onCellClick={onUserCellClick(navigate)}
      onCreateUserClick={onCreateUserClick}
      isAddUserButtonDisplayed={isAddUserButtonDisplayed}
    />
  );
};
