import { useEffect, useState } from 'react';
import { FormikConfig, FormikErrors, FormikValues, useFormik } from 'formik';
import { AnySchema, object } from 'yup';
import Lazy from 'yup/lib/Lazy';
import Reference from 'yup/lib/Reference';
import { getFormikHelpers } from 'elements/utils';

type TypedObjectShape<T extends object> = {
  [key in keyof T]: AnySchema | Reference | Lazy<never, never>;
};

type Props<T extends FormikValues> = FormikConfig<T> & {
  yupShape?: TypedObjectShape<T>;
  customErrors?: FormikErrors<Record<keyof T, string>>;
};

const sanitizeCustomErrors = <T extends Record<string, string>>(errors: T): T =>
  Object.keys(errors).reduce<T>((acc, key: keyof T) => {
    if (errors[key]) {
      acc[key] = errors[key];
    }
    return acc;
  }, {} as T);

const useForm = <Values extends FormikValues>({
  validationSchema,
  validateOnMount = true,
  yupShape,
  customErrors,
  ...props
}: Props<Values>) => {
  const yupSchema = yupShape ? object().shape(yupShape) : undefined;
  const schema = validationSchema ?? yupSchema;
  const formik = useFormik({
    validationSchema: schema,
    validateOnMount,
    ...props,
  });

  // This logic is necessary to work around the issue of the form being valid on initialization
  // https://github.com/jaredpalmer/formik/issues/1950
  const [isAlreadyValidated, setIsAlreadyValidated] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setIsAlreadyValidated(true);

    const sanitizedCustomErrors = sanitizeCustomErrors(customErrors || {});

    formik.setErrors(sanitizedCustomErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customErrors]);

  return { formik, helpers: getFormikHelpers(formik, isAlreadyValidated) };
};

export default useForm;
