import { CustomAssetBalanceResponseModel } from 'models/response/custodial-accounts/custom-asset-balance-response-model';
import { CustodialAccountCustomAssetBalanceRow } from 'ui-interfaces/row-interfaces/custodial-accounts/custodial-account-custom-asset-balance-row';
import { formatAssetPrice } from 'utils/custom-asset-balance/format-asset-price';

export class CustodialAccountCustomAssetBalanceViewModel implements CustodialAccountCustomAssetBalanceRow {
  readonly id: string;

  readonly name: string;

  readonly symbol?: string;

  readonly description?: string;

  readonly price: string;

  readonly balance: string;

  constructor(balance: CustomAssetBalanceResponseModel) {
    this.id = balance.id;
    this.name = balance.assetName;
    this.symbol = balance.assetSymbol;
    this.description = balance.assetDescription;
    this.price = formatAssetPrice(balance);
    this.balance = balance.total;
  }
}
