import React from 'react';
import { Typography } from '@mui/material';
import { Gap } from 'components/shared/Gap';
import { AssetImage } from 'components/shared/Cells/AssetImage';
import { IAccountBalanceNameCell } from 'ui-interfaces/custodial-accounts-details/i-account-balance-name-cell';
import { AssetRow } from './styled';

interface Props {
  data: IAccountBalanceNameCell;
}

export const AccountBalanceNameCell: React.FC<Props> = ({ data }) => {
  return (
    <AssetRow>
      <AssetImage url={data.asset.imageUrl} size={data.size} defaultAssetIcon={data.asset.defaultAssetIcon} />
      <Gap size="_12px" isHorizontal />
      <Typography variant="bodyDefaultMedium" color="grey.700">
        {data.asset.longName}
      </Typography>
      <Gap size="_12px" isHorizontal />
      <Typography variant="bodyDefaultMedium" color="grey.400">
        {data.asset.shortName}
      </Typography>
    </AssetRow>
  );
};
