import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { ProtectedOutlet } from 'navigation/utils/ProtectedOutlet';
import { DEFAULT_ROUTE, PATHS } from 'navigation/constants';
import { useLazyGetUserQuery } from 'redux/api/api-users';
import { UserAllowedSubApi } from 'models/enums/user/user-allowed-sub-api';
import { getInitialRoute } from 'utils/navigation/get-initial-route';
import { checkIfAccessAllowed } from 'utils/sub-apis/check-if-access-allowed';

type Props = {
  allowedSubApis: UserAllowedSubApi[][];
};

export const PermissionRoutesRestriction: React.FC<Props> = ({ allowedSubApis }) => {
  const [getUserTrigger, { data, isSuccess, isError }] = useLazyGetUserQuery();

  const [isAllowedToAccessRoute, setIsAllowedToAccessRoute] = useState(true);
  const [routeToNavigateAway, setRouteToNavigateAway] = useState(DEFAULT_ROUTE);

  useEffect(() => {
    getUserTrigger(undefined);
  }, [getUserTrigger]);

  useEffect(() => {
    if (isSuccess && data) {
      const isAccessAllowed = checkIfAccessAllowed(allowedSubApis, data.organization.allowedSubApis!);
      setIsAllowedToAccessRoute(isAccessAllowed);

      if (!isAccessAllowed) {
        const fallbackRoute = getInitialRoute(data.organization.allowedSubApis!);
        setRouteToNavigateAway(fallbackRoute);
      }
    }
  }, [allowedSubApis, data, isSuccess]);

  if (isError) {
    return <Navigate to={PATHS.GLOBAL.ERROR} replace />;
  }

  return <ProtectedOutlet fallbackRoute={routeToNavigateAway} fallbackCondition={!isAllowedToAccessRoute} />;
};
