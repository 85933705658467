import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Spacer from 'components/shared/Spacer';
import { EnvCell } from 'components/shared/Cells/EnvCell';

import { ReactComponent as LoginIcon } from 'assets/icons/log-in-icon.svg';
import { ReactComponent as ContactUsIcon } from 'assets/icons/contact-us-icon.svg';
import {
  StyledPageContainer,
  StyledContent,
  StyledLogo,
  StyledTitle,
  StyledDescription,
  StyledDescriptionContent,
  StyledLoginButton,
  StyledLink,
  StyledButtonsContent,
  StyledLightenBox,
  StyledRow,
} from './styled';

const CONTACT_US_URL = 'https://fortresstrust.com/contact';

type Props = {
  onLoginClick: () => void;
};

const LoginPageContent: React.FC<Props> = ({ onLoginClick }) => {
  const { t } = useTranslation();

  return (
    <StyledPageContainer>
      <StyledLightenBox />
      <StyledRow>
        <StyledLogo />
        <EnvCell />
      </StyledRow>

      <StyledContent>
        <StyledTitle variant="bodyDefaultMedium">
          {t('loginPageTitle1')} <br />
          <em>{t('loginPageTitle2')}</em> {t('loginPageTitle3')}
        </StyledTitle>

        <Spacer size={24} />

        <StyledDescriptionContent>
          <StyledDescription variant="bodyDefaultMedium">{t('loginPageDescription')}</StyledDescription>
        </StyledDescriptionContent>

        <Spacer size={24} />

        <StyledButtonsContent>
          <StyledLoginButton variant="contained" onClick={onLoginClick}>
            <Typography variant="buttonLarge">{t('loginPageButtonLabel')}</Typography>
            <LoginIcon />
          </StyledLoginButton>
          <StyledLink href={CONTACT_US_URL} target="_blank" rel="noopener noreferrer">
            <Typography variant="buttonLarge">{t('loginPageContactUsLabel')}</Typography>
            <ContactUsIcon />
          </StyledLink>
        </StyledButtonsContent>
      </StyledContent>
    </StyledPageContainer>
  );
};

export { LoginPageContent };
