import { TradeDirection } from 'elements/models/enums/trade-direction';
import i18n from 'localizations';
import { TradeDirectionEnum } from 'elements/element-trade/types';

const buySellCoinPageTitleMapping: { [key in TradeDirection]: string } = {
  [TradeDirectionEnum.Buy]: i18n.t('elements.trade.customizableLabels.buy'),
  [TradeDirectionEnum.Sell]: i18n.t('elements.trade.customizableLabels.sell'),
  [TradeDirectionEnum.BuySell]: i18n.t('elements.trade.customizableLabels.title'),
};

export default buySellCoinPageTitleMapping;
