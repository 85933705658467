import React from 'react';
import { Typography } from '@mui/material';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { AccountVerificationProps } from '../types';
import { StyledTypography } from './styled';

type Props = Pick<AccountVerificationProps, 'title' | 'subtitle'>;
const VerificationContent: React.FC<Props> = ({ title, subtitle }) => {
  const { isMobile } = useIsMobile();

  return (
    <>
      <Typography variant="h6" color="primary.font" textAlign={isMobile ? 'start' : 'center'}>
        {title}
      </Typography>
      <StyledTypography variant="bodyDefaultBook" color="secondary.font" textAlign={isMobile ? 'start' : 'center'}>
        {subtitle}
      </StyledTypography>
    </>
  );
};

export default VerificationContent;
