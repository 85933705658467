import React, { useCallback } from 'react';
import { NumericFormat } from 'react-number-format';
import { InputBaseComponentProps } from '@mui/material';

export type NumericFormatInputProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  defaultValue?: string;
  prefix?: string;
  decimalScale?: number;
};

const DOT_SIGN = '.';
const СOMMA_SIGN = ',';

const NumericFormatInput = React.forwardRef<HTMLInputElement, NumericFormatInputProps & InputBaseComponentProps>(
  (props, ref) => {
    const { prefix, decimalScale = 2, name, onChange, ...other } = props;

    const onValueChange = useCallback(
      values => {
        if (onChange) {
          onChange({
            target: {
              name,
              value: values.value,
            },
          });
        }
      },
      [onChange, name],
    );

    // this function is used to change comma sign entered by user on IOS devices into dot, because IOS numeric keyboard contains either only comma, or only dot, depending on user's device locale
    const onCustomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const indexOfCursor = e.target.selectionStart;
      if (!indexOfCursor) {
        return;
      }

      // @ts-ignore
      if (e.nativeEvent?.data === СOMMA_SIGN && !e.target.value.includes(DOT_SIGN)) {
        const newValueWithDot = `${value.slice(0, indexOfCursor)}${DOT_SIGN}${value.slice(indexOfCursor)}`;
        const valueWithoutCommas = newValueWithDot.replaceAll(СOMMA_SIGN, '');

        e.target.value = valueWithoutCommas;

        if (onChange) {
          onChange({
            target: {
              name,
              value: `${e.target.value}.`,
            },
          });
        }
      }
    };

    return (
      <NumericFormat
        {...other}
        inputMode="decimal"
        name={name}
        getInputRef={ref}
        allowNegative={false}
        decimalScale={decimalScale}
        thousandsGroupStyle="thousand"
        thousandSeparator=","
        prefix={prefix}
        valueIsNumericString
        onValueChange={onValueChange}
        onChange={onCustomChange}
      />
    );
  },
);

export default NumericFormatInput;
