export enum PaymentType {
  internal = 'internal',
  cardDeposit = 'cardDeposit',
  cardWithdrawal = 'cardWithdrawal',
  achDeposit = 'achDeposit',
  achWithdrawal = 'achWithdrawal',
  wireDeposit = 'wireDeposit',
  wireWithdrawal = 'wireWithdrawal',
  buyCrypto = 'buyCrypto',
  sellCrypto = 'sellCrypto',
  depositCrypto = 'depositCrypto',
  withdrawalCrypto = 'withdrawalCrypto',
  internalCrypto = 'internalCrypto',
  privateSecurityDeposit = 'privateSecurityDeposit',
  privateSecurityWithdrawal = 'privateSecurityWithdrawal',
  privateSecurityInternal = 'privateSecurityInternal',
  incomingAchDeposit = 'incomingAchDeposit',
  incomingAchWithdrawal = 'incomingAchWithdrawal',
  checkDeposit = 'checkDeposit',
  internationalWireWithdrawal = 'internationalWireWithdrawal',
  initialDeposit = 'initialDeposit',
  returnAchDeposit = 'ACHDepositReturn',
  returnAchWithdrawal = 'ACHWithdrawalReturn',
  achDepositReverse = 'achDepositReverse',
  achWithdrawalReverse = 'achWithdrawalReverse',
  wireDepositReturn = 'wireDepositReturn',
  wireWithdrawalReturn = 'wireWithdrawalReturn',
  reversalWireDeposit = 'WireDepositReverse',
  reversalWireWithdrawal = 'WireWithdrawalReverse',
  buyPreciousMetal = 'buyPreciousMetal',
  sellPreciousMetal = 'sellPreciousMetal',
  manuallyProcessed = 'manuallyProcessed',
}
