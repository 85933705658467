import { styled } from '@mui/material/styles';
import { IconButton, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { pxToRem } from 'utils/styling-utils';

export const StyledBackButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.grey['100'],
  borderRadius: pxToRem(theme.gaps._8px / 2),
  width: pxToRem(theme.gaps._32px),
  height: pxToRem(theme.gaps._32px),
  '&:hover': {
    borderRadius: pxToRem(theme.gaps._8px / 2),
    backgroundColor: theme.palette.grey[200],
  },
  '&:active': {
    borderRadius: pxToRem(theme.gaps._8px / 2),
    backgroundColor: theme.palette.grey[300],
  },
  '&:focus': {
    borderRadius: pxToRem(theme.gaps._8px / 2),
    backgroundColor: theme.palette.grey[200],
  },
}));

export const StyledFirstLabel = styled(NavLink)(({ theme }) => ({
  ...theme.typography.bodyDefaultBook,
  color: theme.palette.grey['600'],
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));
export const StyledSecondLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.bodyDefaultBook,
}));
