import { TransferDirectionEnum, TransferFlow } from 'elements/element-transfer/types';
import { getPaymentMethodTypesMap } from 'elements/features/PaymentMethods';
import { withdrawalWireInitialState } from 'elements/element-transfer/contexts/Element/constants';
import { getTransferOptionsList } from 'elements/element-transfer/utils/getTransferOptionsList';
import { sanitizeTransferDirection } from 'elements/element-transfer/utils/sanitizeTransferDirection';
import { Paths } from 'elements/element-transfer/navigation/routes';

const sanitizedTransferDirection = sanitizeTransferDirection();

const depositAchInitialState = {
  amount: '',
  externalAccountList: [],
  paymentAccount: null,
};

const cryptoInitialState = {
  selectedCoin: null,
  selectedBuySellCoin: null,
  selectedCab: null,
  selectedAsset: null,
  coinList: [],
  walletAddress: '',
  destinationTag: '',
};

export const initialState = {
  [TransferFlow.Deposit]: {
    ACH: depositAchInitialState,
    WIRE: {
      instructions: null,
    },
    CRYPTO: cryptoInitialState,
  },
  [TransferFlow.Withdrawal]: {
    ACH: null,
    WIRE: withdrawalWireInitialState,
    CRYPTO: { ...cryptoInitialState, amount: '' },
  },
  transferDirection: sanitizedTransferDirection,
  lockId: '',
  paymentType: null,
  flowType:
    sanitizedTransferDirection === TransferDirectionEnum.Withdrawal ? TransferFlow.Withdrawal : TransferFlow.Deposit,
  contactUsUrl: '',
  paymentMethodTypesMap: getPaymentMethodTypesMap(),
  transferOptionsList: getTransferOptionsList(),
  identityData: null,
  custodialAccount: null,
  isOrderPlaced: false,
  achDepositPaymentId: null,
  cryptoWithdrawalPaymentId: null,
  wireDomesticWithdrawalPaymentId: null,
  wireInternationalWithdrawalPaymentId: null,
  custodialAccountId: '',
  identityId: '',
  page: { location: Paths.Root, state: null },
};
