export enum NetworkChain {
  mainnet = 'mainnet',
  rinkeby = 'rinkeby',
  goerli = 'goerli',
  sepolia = 'sepolia',
  kovan = 'kovan',
  bnbSmartChainMainnet = 'bnbSmartChainMainnet',
  bnbSmartChainTestnet = 'bnbSmartChainTestnet',
  polygonMainnet = 'polygonMainnet',
  bitcoinMainnet = 'bitcoinMainnet',
  bitcoinTestnet = 'bitcoinTestnet',
  solanaMainnet = 'solanaMainnet',
  solanaDevnet = 'solanaDevnet',
  solanaTestnet = 'solanaTestnet',
  polkadotMainnet = 'polkadotMainnet',
  polkadotWestend = 'polkadotWestend',
  cardanoMainnet = 'cardanoMainnet',
  cardanoTestnet = 'cardanoTestnet',
  avalancheFuji = 'avalancheFuji',
  avalancheMainnet = 'avalancheMainnet',
  polygonMumbai = 'polygonMumbai',
  polygonAmoy = 'polygonAmoy',
  algorandMainnet = 'algorandMainnet',
  algorandTestnet = 'algorandTestnet',
  stellarMainnet = 'stellarMainnet',
  stellarTestnet = 'stellarTestnet',
  litecoinMainnet = 'litecoinMainnet',
  litecoinTestnet = 'litecoinTestnet',
  dogeTestnet = 'dogeTestnet',
  dogeMainnet = 'dogeMainnet',
  cosmosHub = 'cosmosHub',
  cosmosHubTestnet = 'cosmosHubTestnet',
  eosMainnet = 'eosMainnet',
  eosTestnet = 'eosTestnet',
  tezosMainnet = 'tezosMainnet',
  tezosTestnet = 'tezosTestnet',
  nearMainnet = 'nearMainnet',
  nearTestnet = 'nearTestnet',
  tronMainnet = 'tronMainnet',
  tronTestnet = 'tronTestnet',
  arbitrumGoerli = 'arbitrumGoerli',
  arbitrumSepolia = 'arbitrumSepolia',
  arbitrumMainnet = 'arbitrumMainnet',
  bchMainnet = 'bchMainnet',
  bchTestnet = 'bchTestnet',
  rippleMainnet = 'rippleMainnet',
  rippleTestnet = 'rippleTestnet',
  flowMainnet = 'flowMainnet',
  flowTestnet = 'flowTestnet',
  hederaMainnet = 'hederaMainnet',
  hederaTestnet = 'hederaTestnet',
}
