import { TransactionsFilterProperty } from 'ui-enums/transactions/transactions-filter-property';
import { Filterable } from 'ui-interfaces/shared/filters/filterable';
import { SelectionMacroCommand } from 'filters/commands/selection-macro-command';
import { Filter } from 'filters/interfaces/filter';
import { FilterCommand } from 'filters/commands/filter-command';

export const getTransactionsFilters = (defaultFilters: Filter[]): Filterable => {
  return {
    filters: defaultFilters,
    selectionMacroCommand: new SelectionMacroCommand(
      defaultFilters.map(f => new FilterCommand(f)),
      defaultFilters.findIndex(f => f.getProperty() === TransactionsFilterProperty.id),
    ),
  };
};
