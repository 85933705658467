import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { LoadingButtonWithSkeleton } from 'components/shared/buttons/LoadingButtonWithSkeleton';
import { Gap } from 'components/shared/Gap';
import { Row } from 'components/shared/Flex/Row';
import { ReactComponent as ApproveIcon } from 'assets/icons/white-checkmark.svg';
import { ReactComponent as RejectIcon } from 'assets/icons/white-close-icon.svg';
import { ActionDialog } from './ActionDialog';

type Props = {
  onApprove: () => void;
  onReject: () => void;
  isApproveLoading: boolean;
  isRejectLoading: boolean;
  amount: string;
  sourceOrganizationName: string;
};

export const Actions: React.FC<Props> = ({
  onApprove,
  onReject,
  isApproveLoading,
  isRejectLoading,
  amount,
  sourceOrganizationName,
}) => {
  const { t } = useTranslation();
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
  const onOpenApproveDialogButtonClick = () => {
    setIsApproveDialogOpen(true);
  };
  const onApproveDialogClose = () => {
    setIsApproveDialogOpen(false);
  };
  const onApproveButtonClick = () => {
    onApprove();
    onApproveDialogClose();
  };
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const onOpenRejectDialogButtonClick = () => {
    setIsRejectDialogOpen(true);
  };
  const onRejectDialogClose = () => {
    setIsRejectDialogOpen(false);
  };
  const onRejectButtonClick = () => {
    onReject();
    onRejectDialogClose();
  };

  return (
    <Row>
      <LoadingButtonWithSkeleton
        color="success"
        variant="contained"
        size="large"
        icon={<ApproveIcon />}
        text={
          <Typography variant="buttonMedium" color="background.paper">
            {t('transactionDetailsPage.approveButtonLabel')}
          </Typography>
        }
        loading={isApproveLoading}
        onClick={onOpenApproveDialogButtonClick}
      />
      <ActionDialog
        isOpen={isApproveDialogOpen}
        onClose={onApproveDialogClose}
        sourceOrganizationName={sourceOrganizationName}
        amount={amount}
        question={t('transactionDetailsPage.actionDialog.approveQuestion')}
        actionButton={
          <Button color="success" variant="contained" size="large" onClick={onApproveButtonClick}>
            <ApproveIcon />
            <Gap isHorizontal size="_8px" />
            <Typography variant="buttonMedium" color="background.paper">
              {t('transactionDetailsPage.actionDialog.approveButtonLabel')}
            </Typography>
          </Button>
        }
      />
      <Gap isHorizontal size="_20px" />
      <LoadingButtonWithSkeleton
        color="error"
        variant="contained"
        size="large"
        icon={<RejectIcon />}
        text={
          <Typography variant="buttonMedium" color="background.paper">
            {t('transactionDetailsPage.rejectButtonLabel')}
          </Typography>
        }
        loading={isRejectLoading}
        onClick={onOpenRejectDialogButtonClick}
      />
      <ActionDialog
        isOpen={isRejectDialogOpen}
        onClose={onRejectDialogClose}
        sourceOrganizationName={sourceOrganizationName}
        amount={amount}
        question={t('transactionDetailsPage.actionDialog.rejectQuestion')}
        actionButton={
          <Button color="error" variant="contained" size="large" onClick={onRejectButtonClick}>
            <RejectIcon />
            <Gap isHorizontal size="_8px" />
            <Typography variant="buttonMedium" color="background.paper">
              {t('transactionDetailsPage.actionDialog.rejectButtonLabel')}
            </Typography>
          </Button>
        }
      />
    </Row>
  );
};
