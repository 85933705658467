import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { pxToRem } from 'utils/styling-utils';

export const StyledBox = styled(Box)(({ theme }) => ({
  height: '100%',
  boxShadow: theme.customShadows.grey._2dp,
  backgroundColor: theme.palette.background.paper,
  marginTop: pxToRem(theme.gaps._24px),
  marginInline: pxToRem(theme.gaps._32px),
  marginBottom: pxToRem(theme.gaps._48px),
  padding: pxToRem(theme.gaps._12px),
  borderRadius: pxToRem(theme.gaps._8px),
}));
