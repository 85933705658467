import React from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumbs, { BreadCrumbsItems } from 'components/shared/Breadcrumbs';
import { CommonLayoutContainer, CommonLayoutContent, CommonLayoutHeader } from 'components/shared/layouts/CommonLayout';
import { CommonErrorLayout } from 'components/shared/layouts/CommonErrorLayout';
import { HeaderBox } from 'components/shared/detail-page/HeaderBox';
import { StyledTab, StyledTabs } from 'components/shared/Tabs';
import { CustodialAccountDetailsPageTabs } from 'ui-enums/tabs/accounts/custodial-account-details-page-tabs';
import { StyledTabBox } from './styled';

type Props = {
  breadCrumbs: BreadCrumbsItems;
  tab: CustodialAccountDetailsPageTabs;
  handleChange: (event: React.SyntheticEvent, newTab: CustodialAccountDetailsPageTabs) => void;
  isPrivateSecurityTabShown?: boolean;
  isBeneficiariesTabShown?: boolean;
};

export const Error: React.FC<Props> = ({
  breadCrumbs,
  tab,
  handleChange,
  isPrivateSecurityTabShown,
  isBeneficiariesTabShown,
}) => {
  const { t } = useTranslation();
  return (
    <CommonLayoutContainer>
      <CommonLayoutHeader>
        <HeaderBox>
          <Breadcrumbs items={breadCrumbs} />
        </HeaderBox>
        <StyledTabBox>
          <StyledTabs value={tab} onChange={handleChange}>
            <StyledTab
              label={t('custodialAccountDetailsPage.tabs.fiatCrypto')}
              value={CustodialAccountDetailsPageTabs.fiatCrypto}
            />
            {isPrivateSecurityTabShown && (
              <StyledTab
                label={t('custodialAccountDetailsPage.tabs.privateSecurities')}
                value={CustodialAccountDetailsPageTabs.privateSecurities}
              />
            )}
            {isBeneficiariesTabShown && (
              <StyledTab
                label={t('custodialAccountDetailsPage.tabs.beneficiaries')}
                value={CustodialAccountDetailsPageTabs.beneficiaries}
              />
            )}
          </StyledTabs>
        </StyledTabBox>
      </CommonLayoutHeader>
      <CommonLayoutContent>
        <CommonErrorLayout />
      </CommonLayoutContent>
    </CommonLayoutContainer>
  );
};
