import React from 'react';
import { Pillow } from 'components/shared/Pillow';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import { StatusCellProps } from 'ui-interfaces/statuses/status-cell-props';
import { StatusCellRow } from './StatusCellRow';

export const StatusCell: React.FC<StatusCellProps> = ({ labelIcon, pillowProps, tooltipMessage }) => {
  if (tooltipMessage) {
    return (
      <Pillow color={pillowProps.color} colorType={pillowProps.colorType}>
        <CommonTooltip placement="top" title={tooltipMessage}>
          <StatusCellRow labelIcon={labelIcon} />
        </CommonTooltip>
      </Pillow>
    );
  }
  return (
    <Pillow color={pillowProps.color} colorType={pillowProps.colorType}>
      <StatusCellRow labelIcon={labelIcon} />
    </Pillow>
  );
};
