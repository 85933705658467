import i18n from 'localizations';
import { UserDetails } from 'ui-interfaces/user-details/user-details';
import { UserDetailsNode } from 'ui-interfaces/user-details/user-details-node';
import { HeaderDetails } from 'ui-interfaces/user-details/header-details';
import { UserDetailResponseModel } from 'models/response/users/user-detail-response-model';
import { formatDate, formatDateAndTimeInUTC } from 'utils/format-date';
import { getUserRoleLabel } from 'utils/labels-mapping/get-user-role-label';
import { UserDetailsRowType } from 'ui-enums/user-details/user-details-row-type';
import { formatPhoneNumber } from 'utils/format-phone-number';
import { mapRoleToRoleCellProps } from 'utils/multi-mapping/role/map-role-to-role-cell-props';
import { generatePermissions } from 'utils/user/details-page/generate-permissions';
import { generateLimits } from 'utils/user/details-page/generate-limits';
import { DashboardRoles } from 'models/enums/user/dashboard-roles';
import { havePermissionDefinitionsEnabledPermissions } from 'utils/user/have-permission-definitions-enabled-permissions';
// row interfaces - start
import { IDefaultRow } from 'ui-interfaces/user-details/rows/i-default-row';
import { IRoleRow } from 'ui-interfaces/user-details/rows/i-role-row';
// row interfaces - end

export class UserDetailsBaseViewModel implements UserDetails {
  headerDetails: HeaderDetails = {
    date: '',
    title: '',
    isPending: false,
    pendingTooltipMessage: '',
    actions: [],
    destroyModalMainText: '',
  };

  nodes: UserDetailsNode[] = [];

  isCurrentUser = false;

  constructor(user: UserDetailResponseModel) {
    this.headerDetails.title = `${user.firstName} ${user.lastName}`;
    this.headerDetails.date = user.createdAt ? formatDate(user.createdAt) : i18n.t('userDetailsPage.na');
    this.headerDetails.isPending = !user.isActive;
    this.headerDetails.pendingTooltipMessage = i18n.t('userDetailsPage.pendingTooltipMessage');
    this.headerDetails.destroyModalMainText = `${this.headerDetails.title} (${getUserRoleLabel(user.roleTemplate)})`;

    this.nodes = [
      {
        id: 'general',
        items: [
          {
            id: 'fullName',
            rowType: UserDetailsRowType.default,
            label: i18n.t('userDetailsPage.fullName'),
            value: `${user.firstName} ${user.lastName}`,
          } as IDefaultRow,
          {
            id: 'userID',
            rowType: UserDetailsRowType.default,
            label: i18n.t('userDetailsPage.userID'),
            value: user.id,
          } as IDefaultRow,
          {
            id: 'email',
            rowType: UserDetailsRowType.default,
            label: i18n.t('userDetailsPage.email'),
            value: user.email,
          } as IDefaultRow,
          {
            id: 'phoneNumber',
            rowType: UserDetailsRowType.default,
            label: i18n.t('userDetailsPage.phoneNumber'),
            value: formatPhoneNumber(user.phone),
          } as IDefaultRow,
          {
            id: 'role',
            rowType: UserDetailsRowType.role,
            label: i18n.t('userDetailsPage.role'),
            statusCellProps: mapRoleToRoleCellProps(user.roleTemplate),
          } as IRoleRow,
          {
            id: 'dateAdded',
            rowType: UserDetailsRowType.default,
            label: i18n.t('userDetailsPage.dateAdded'),
            value: user.createdAt ? `${formatDateAndTimeInUTC(user.createdAt)} UTC` : i18n.t('userDetailsPage.na'),
          } as IDefaultRow,
        ],
      },
      ...generatePermissions(user.permissions),
      ...generateLimits(
        user.limits,
        havePermissionDefinitionsEnabledPermissions([DashboardRoles.paymentInitiator], user.permissions),
        havePermissionDefinitionsEnabledPermissions([DashboardRoles.paymentApprover], user.permissions),
      ),
    ];
  }
}
