import i18n from 'localizations';
import { PersonalIdentityDetailsResponseModel } from 'models/response/identities/personal/personal-identity-details-response-model';
import { formatIdentityPhone } from 'utils/identities/format-identity-phone';
import { mapIdentityStatusModelToStatusCellProps } from 'utils/multi-mapping/statuses/map-identity-status-model-to-status-cell-props';
import { formatIdentityDate } from 'utils/identities/format-identity-date';
import { formatIdentityAddress } from 'utils/identities/format-identity-address';
import { IIdentityDetailsPage } from 'ui-interfaces/identity-details/i-identity-details-page';
import { IPlainRow } from 'ui-interfaces/identity-details/rows/i-plain-row';
import { IdentityDetailRowType } from 'ui-enums/identities/identity-detail-row-type';
import { Valued } from 'ui-interfaces/shared/valued';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { IdentityDetailsBaseViewModel } from './base/identity-details-base-vm';

export class IdentityDetailsPersonalViewModel extends IdentityDetailsBaseViewModel implements IIdentityDetailsPage {
  constructor(identity: PersonalIdentityDetailsResponseModel, user: ShortUserResponseModel) {
    super(identity, user);

    this.pageTitle = {
      title: `${identity.personalInfo.firstName} ${identity.personalInfo.lastName}`,
      kycLevel: identity.kycLevel,
      statusCellProps: mapIdentityStatusModelToStatusCellProps(identity.status, !identity.isSuspendedByIntegrator),
    };

    this.nodes = [
      {
        id: 'identityInfoSection',
        items: [
          {
            id: 'firstName',
            rowType: IdentityDetailRowType.plain,
            label: i18n.t('identityDetailsPage.detailsLabels.firstName'),
            value: identity.personalInfo.firstName,
          } as IPlainRow,
          {
            id: 'lastName',
            rowType: IdentityDetailRowType.plain,
            label: i18n.t('identityDetailsPage.detailsLabels.lastName'),
            value: identity.personalInfo.lastName,
          } as IPlainRow,
          {
            id: 'middleName',
            rowType: IdentityDetailRowType.plain,
            label: i18n.t('identityDetailsPage.detailsLabels.middleName'),
            value: identity.personalInfo.middleName ?? '',
          } as IPlainRow,
          {
            id: 'email',
            rowType: IdentityDetailRowType.plain,
            label: i18n.t('identityDetailsPage.detailsLabels.email'),
            value: identity.personalInfo.email,
          } as IPlainRow,
          {
            id: 'phoneNumber',
            rowType: IdentityDetailRowType.plain,
            label: i18n.t('identityDetailsPage.detailsLabels.phoneNumber'),
            value: formatIdentityPhone(user, identity.personalInfo.phone),
          } as IPlainRow,
          {
            id: 'dateOfBirth',
            rowType: IdentityDetailRowType.plain,
            label: i18n.t('identityDetailsPage.detailsLabels.dateOfBirth'),
            value: identity.personalInfo.dateOfBirth ? formatIdentityDate(user, identity.personalInfo.dateOfBirth) : '',
          } as IPlainRow,
          {
            id: 'ssn',
            rowType: IdentityDetailRowType.plain,
            label: i18n.t('identityDetailsPage.detailsLabels.ssn'),
            value: identity.personalInfo.ssn ?? '',
          } as IPlainRow,
          {
            id: 'address',
            rowType: IdentityDetailRowType.textMultiLine,
            label: i18n.t('identityDetailsPage.detailsLabels.address'),
            value: formatIdentityAddress(user, identity.address),
          } as IPlainRow,
        ].filter(i => {
          if ('value' in i) {
            return !!(i as Valued).value;
          }

          return true;
        }),
      },
    ];
  }
}
