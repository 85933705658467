import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import i18n from 'localizations';
import { CommonTooltip } from 'components/shared/CommonTooltip';

export const DateHeaderCell: React.FC = () => {
  return (
    <Box display="flex" alignItems="center">
      <CommonTooltip
        placement="top"
        title={
          <Typography color="background.paper" variant="bodySmallBook">
            {i18n.t('transactionsPage.utcTimeZone')}
          </Typography>
        }
      >
        <Typography variant="bodySmallBold" color="grey.600">
          {i18n.t('transactionsPage.date')}
        </Typography>
      </CommonTooltip>
    </Box>
  );
};
