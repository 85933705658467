import React, { useEffect } from 'react';
import { OnCloseElement } from 'elements/models/types/element-result';
import useRequestCoinsList, { RequestCoinListStatus } from 'elements/element-trade/hooks/useRequestCoinList';
import { Paths } from 'elements/element-trade/navigation/routes';
import { LoadingErrorPage } from 'elements/features/LoadingErrorState';
import { setElementStarted, setPage, setShouldPriceReload, useElement } from 'elements/element-trade/contexts/Element';
import buySellCoinPageTitleMapping from 'elements/element-trade/utils/buySellCoinPageTitleMapping';

const EntryPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const {
    dispatch,
    state: { isElementStarted, flowType, tradeDirection },
  } = useElement();

  const { requestCoinList, requestCoinListStatus } = useRequestCoinsList();

  const onLoadPage = async () => {
    await requestCoinList(flowType);
  };

  useEffect(() => {
    if (requestCoinListStatus === RequestCoinListStatus.Success) {
      dispatch(setElementStarted());
      dispatch(setPage(Paths.BuySell));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestCoinListStatus]);

  useEffect(() => {
    (async () => {
      if (isElementStarted) {
        dispatch(setShouldPriceReload(false));
        dispatch(setPage(Paths.BuySell));
        return;
      }

      await onLoadPage();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isError = requestCoinListStatus === RequestCoinListStatus.Failed;

  return (
    <LoadingErrorPage
      title={isError ? buySellCoinPageTitleMapping[tradeDirection] : undefined}
      isLoading={!isError}
      isError={isError}
      onReload={onLoadPage}
      onClose={onClose}
    />
  );
};

export default EntryPage;
