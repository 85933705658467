import { useMediaQuery, useTheme } from '@mui/material';
import { ElementsCustomTheme } from 'elements/theme/createCustomTheme';
import { CUSTOM_THEME_SM_BREAKPOINT } from 'elements/utils';

const useIsMobile = () => {
  const theme = useTheme<ElementsCustomTheme>();
  const isMobile = useMediaQuery(theme.breakpoints.down(CUSTOM_THEME_SM_BREAKPOINT));

  return { isMobile };
};

export { useIsMobile };
