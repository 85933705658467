import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { LabelLength } from 'ui-enums/label-length';
import { getSignOfTransaction } from 'utils/transactions/get-sign-of-transaction';
import { getDynamicAssetLabel } from 'utils/labels-mapping/get-dynamic-asset-label';

export const getLabelForPreciousMetalSellTransactionDetailsPage = (transaction: TransactionResponseModel): string => {
  if (transaction.quantity) {
    return `${getSignOfTransaction(transaction)}${transaction.quantity} ${getDynamicAssetLabel(
      LabelLength.short,
      transaction.assetTicker,
      transaction.assetName,
    )}`;
  }

  return '';
};
