import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Typography } from '@mui/material';
import { BannerType, Button, InlineBanner, QrCode, Spacer, TextCopiedSnackbar } from 'elements/components';
import { copyToClipboard, shareText } from 'elements/utils';
import { ErrorState } from 'elements/features/LoadingErrorState';
import Layout from 'elements/element-transfer/components/Layout';
import getSanitizedWalletAddress from 'elements/element-transfer/utils/getSanitizedWalletAddress';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { OnCloseElement } from 'elements/models/types/element-result';
import { CoinListItemNew } from 'elements/features/CoinsListNew/CoinListItemNew';
import { AssetResponseModel } from 'models/response/assets/asset-response-model';
import { Currency } from 'ui-enums/response/transactions/currency';
import { mapAssetResponseModelToICoinListItem } from 'utils/elements/map-asset-response-model-to-i-coin-list-item';
import {
  StyledButtonsContainer,
  StyledCenteredContainer,
  StyledContainer,
  StyledDivider,
  StyledNetworkButton,
  StyledReplyIcon,
  StyledStrong,
} from './styled';

type Props = {
  asset: AssetResponseModel;
  networkAddress: string;
  onBack: () => void;
  onClose: OnCloseElement;
  isRequestError: boolean;
  onReload: () => void;
};

const CryptoQrCodePage: React.FC<Props> = ({ asset, networkAddress, onBack, onClose, isRequestError, onReload }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useIsMobile();

  const sanitizedWalletAddress = getSanitizedWalletAddress(networkAddress, asset.assetType);

  const onCopyButtonClick = async (): Promise<void> => {
    await copyToClipboard(sanitizedWalletAddress);
    enqueueSnackbar('message', {
      content: <TextCopiedSnackbar message={t('elements.transfer.cryptoQrCodePage.snackbarMsg')} />,
    });
  };
  const onShareButtonClick = async (): Promise<void> => {
    await shareText(sanitizedWalletAddress);
  };

  const content = (
    <>
      {asset.assetType === Currency.usdc && (
        <>
          <InlineBanner type={BannerType.Info} text={t('elements.transfer.cryptoQrCodePage.multipleNetworksMsg')} />
          <Spacer size={isMobile ? 24 : 42} />
        </>
      )}
      <StyledContainer>
        <CoinListItemNew data={mapAssetResponseModelToICoinListItem(asset)} withArrow={false} withDivider={false} />
        <StyledDivider />
        <StyledCenteredContainer isMobile={isMobile}>
          <QrCode value={sanitizedWalletAddress} />
          <StyledNetworkButton onClick={onCopyButtonClick} disableRipple>
            <StyledStrong variant="bodyDefaultBook" textAlign="center" color="primary.font">
              {sanitizedWalletAddress}
            </StyledStrong>
          </StyledNetworkButton>
          <StyledButtonsContainer>
            <Button onClick={onCopyButtonClick} buttonType="secondary">
              <ContentCopyIcon />
              <Typography variant="buttonMedium">{t('elements.common.copy')}</Typography>
            </Button>
            {isMobile && (
              <Button onClick={onShareButtonClick} buttonType="secondary">
                <StyledReplyIcon />
                <Typography variant="buttonMedium">{t('elements.common.share')}</Typography>
              </Button>
            )}
          </StyledButtonsContainer>
        </StyledCenteredContainer>
      </StyledContainer>
    </>
  );

  return (
    <Layout
      withFooter
      onBack={onBack}
      isContentCentered={isRequestError}
      onClose={onClose}
      defaultBackgroundColor="paper"
      title={t('elements.transfer.customizableLabels.depositCryptoQrCodePageTitle')}
    >
      {isRequestError ? <ErrorState onReload={onReload} /> : content}
    </Layout>
  );
};

export default CryptoQrCodePage;
