import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { ProtectedOutlet } from 'navigation/utils/ProtectedOutlet';
import { DEFAULT_ROUTE, PATHS } from 'navigation/constants';
import { useLazyGetUserQuery } from 'redux/api/api-users';
import { DashboardRoles } from 'models/enums/user/dashboard-roles';
import { arraysHaveMatchedPermissions } from 'utils/user/arrays-have-matched-permissions';

type Props = {
  permissionsAllowingAccess: DashboardRoles[];
};

export const PermissionBasedAccessRestriction: React.FC<Props> = ({ permissionsAllowingAccess }) => {
  const [getUserTrigger, { data, isSuccess, isError }] = useLazyGetUserQuery();

  const [isAllowed, setIsAllowed] = useState(false);
  const [hasBeenProcessed, setHasBeenProcessed] = useState(false);

  useEffect(() => {
    if (isSuccess && data) {
      const allowed = arraysHaveMatchedPermissions(permissionsAllowingAccess, data.permissions);
      setIsAllowed(allowed);
      setHasBeenProcessed(true);
    }
  }, [data, isSuccess, permissionsAllowingAccess]);

  useEffect(() => {
    getUserTrigger(undefined);
  }, [getUserTrigger]);

  if (isError) {
    return <Navigate to={PATHS.GLOBAL.ERROR} replace />;
  }

  if (hasBeenProcessed) {
    return <ProtectedOutlet fallbackRoute={DEFAULT_ROUTE} fallbackCondition={!isAllowed} />;
  }

  return null;
};
