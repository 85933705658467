import { mainApi } from 'redux/api/main-api';
import { API_ROUTES } from 'redux/api/api-routes';
import { CollectionResponse, Paginated } from 'redux/api/types';
import { APPROVE_PAYMENT_TAG, DEFAULT_PAGE_SIZE, FIRST_PAGE, REJECT_PAYMENT_TAG } from 'redux/api/constants';
import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { TransactionDetailResponseModel } from 'models/response/transactions/transaction-detail-response-model';
import { FilterResult } from 'filters/interfaces/filter-result';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';
import { getRouteWithParams } from 'utils/get-route-with-params';

export interface TransactionsApiParams extends Paginated {
  custodialAccountId?: string;
  filterResult?: FilterResult;
}

const transactionsApi = mainApi.injectEndpoints({
  endpoints: build => ({
    getTransactions: build.query<CollectionResponse<TransactionResponseModel>, TransactionsApiParams>({
      query: ({ page = FIRST_PAGE, pageSize = DEFAULT_PAGE_SIZE, custodialAccountId, filterResult }) => {
        if (filterResult) {
          const { property, operator, value } = filterResult as FilterResult;

          return {
            url: API_ROUTES.private.TRANSACTIONS,
            params: {
              approved: true,
              page,
              pageSize,
              custodialAccountId,
              [`filter[${property} ${operator}]`]: value.trim(),
            },
          };
        }
        return {
          url: API_ROUTES.private.TRANSACTIONS,
          params: { approved: true, page, pageSize, custodialAccountId },
        };
      },
    }),
    getTransactionDetailsByIdForApproved: build.query<TransactionDetailResponseModel, string>({
      query: (transactionId: string) => ({
        url: getRouteWithParams(API_ROUTES.private.TRANSACTION, { transactionId }),
        params: {
          approved: true,
        },
      }),
      providesTags: [APPROVE_PAYMENT_TAG, REJECT_PAYMENT_TAG],
    }),
    getTransactionDetailsByIdForNonApproved: build.query<TransactionDetailResponseModel, string>({
      query: (transactionId: string) => ({
        url: getRouteWithParams(API_ROUTES.private.TRANSACTION, { transactionId }),
        params: {
          approved: false,
        },
      }),
      providesTags: [APPROVE_PAYMENT_TAG, REJECT_PAYMENT_TAG],
    }),
    getPrivateSecurityTransactions: build.query<CollectionResponse<TransactionResponseModel>, TransactionsApiParams>({
      query: ({ page = FIRST_PAGE, pageSize = DEFAULT_PAGE_SIZE, custodialAccountId, filterResult }) => {
        if (filterResult) {
          const { property, operator, value } = filterResult as FilterResult;

          return {
            url: API_ROUTES.private.TRANSACTIONS,
            params: {
              approved: true,
              isPrivateSecurities: true,
              page,
              pageSize,
              custodialAccountId,
              [`filter[${property} ${operator}]`]: value.trim(),
            },
          };
        }
        return {
          url: API_ROUTES.private.TRANSACTIONS,
          params: {
            approved: true,
            isPrivateSecurities: true,
            page,
            pageSize,
            custodialAccountId,
          },
        };
      },
    }),
    getFiatAndCryptoTransactions: build.query<CollectionResponse<TransactionResponseModel>, TransactionsApiParams>({
      query: ({ page = FIRST_PAGE, pageSize = DEFAULT_PAGE_SIZE, custodialAccountId, filterResult }) => {
        if (filterResult) {
          const { property, operator, value } = filterResult as FilterResult;

          return {
            url: API_ROUTES.private.TRANSACTIONS,
            params: {
              approved: true,
              isPrivateSecurities: false,
              page,
              pageSize,
              custodialAccountId,
              [`filter[${property} ${operator}]`]: value.trim(),
            },
          };
        }
        return {
          url: API_ROUTES.private.TRANSACTIONS,
          params: {
            approved: true,
            isPrivateSecurities: false,
            page,
            pageSize,
            custodialAccountId,
          },
        };
      },
    }),
    getApprovalNeededTransactions: build.query<CollectionResponse<TransactionResponseModel>, TransactionsApiParams>({
      query: ({ page = FIRST_PAGE, pageSize = DEFAULT_PAGE_SIZE, custodialAccountId, filterResult }) => {
        if (filterResult) {
          const { property, operator, value } = filterResult as FilterResult;

          return {
            url: API_ROUTES.private.TRANSACTIONS,
            params: {
              approved: false,
              page,
              pageSize,
              custodialAccountId,
              [`filter[status in]`]: `${TransactionStatus.approvalNeeded}`,
              [`filter[${property} ${operator}]`]: value.trim(),
            },
          };
        }
        return {
          url: API_ROUTES.private.TRANSACTIONS,
          params: {
            approved: false,
            page,
            pageSize,
            custodialAccountId,
            [`filter[status in]`]: `${TransactionStatus.approvalNeeded}`,
          },
        };
      },
    }),
    getRejectedTransactions: build.query<CollectionResponse<TransactionResponseModel>, TransactionsApiParams>({
      query: ({ page = FIRST_PAGE, pageSize = DEFAULT_PAGE_SIZE, custodialAccountId, filterResult }) => {
        if (filterResult) {
          const { property, operator, value } = filterResult as FilterResult;

          return {
            url: API_ROUTES.private.TRANSACTIONS,
            params: {
              approved: false,
              page,
              pageSize,
              custodialAccountId,
              [`filter[status in]`]: `${TransactionStatus.rejected}`,
              [`filter[${property} ${operator}]`]: value.trim(),
            },
          };
        }
        return {
          url: API_ROUTES.private.TRANSACTIONS,
          params: {
            approved: false,
            page,
            pageSize,
            custodialAccountId,
            [`filter[status in]`]: `${TransactionStatus.rejected}`,
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetTransactionsQuery,
  useLazyGetTransactionDetailsByIdForApprovedQuery,
  useLazyGetTransactionDetailsByIdForNonApprovedQuery,
  useLazyGetFiatAndCryptoTransactionsQuery,
  useLazyGetPrivateSecurityTransactionsQuery,
  useLazyGetApprovalNeededTransactionsQuery,
  useLazyGetRejectedTransactionsQuery,
} = transactionsApi;
