import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Gap } from 'components/shared/Gap';
// icons - start
import { ReactComponent as Error } from 'assets/icons/global/global-error.svg';
// icons - end
import { StyledPageContainer, StyledContent, StyledHeader, StyledTypography, StyledLogo } from './styled';

export const ErrorPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledPageContainer>
      <StyledHeader>
        <StyledLogo />
      </StyledHeader>
      <StyledContent>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Error />
          <Gap size="_24px" />
          <Typography variant="h4" color="grey.600">
            {t('error.title')}
          </Typography>
          <Gap size="_12px" />
          <Typography variant="subtitleBook" color="grey.600">
            {t('error.subtitleOr')}
            <Link target="_blank" rel="noopener noreferrer" href="https://fortress.xyz/contact/">
              <StyledTypography variant="bodyDefaultBookLink">{t('error.contactUs')}</StyledTypography>
            </Link>
          </Typography>
        </Box>
      </StyledContent>
    </StyledPageContainer>
  );
};
