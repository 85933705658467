import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const StyledFooterTitleTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'isVisible',
})<{ isVisible: boolean }>(({ theme, isVisible }) => ({
  ...theme.typography.bodySmallBold,
  color: theme.palette.background.paper,
  opacity: isVisible ? 1 : 0,
}));

export const StyledFooterSubtitleTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'isVisible',
})<{ isVisible: boolean }>(({ theme, isVisible }) => ({
  ...theme.typography.bodySmallBook,
  color: theme.palette.background.paper,
  opacity: isVisible ? 1 : 0,
}));
