import React from 'react';
import { QRCode } from 'react-qrcode-logo';
import { StyledQrCodeContainer } from './styled';

type Props = {
  value: string;
};

export const QrCode: React.FC<Props> = ({ value }) => {
  return (
    <StyledQrCodeContainer>
      <QRCode value={value} ecLevel="Q" size={195} logoWidth={40} logoPadding={10} removeQrCodeBehindLogo />
    </StyledQrCodeContainer>
  );
};
