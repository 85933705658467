import React from 'react';
import { StandardTextFieldProps } from '@mui/material';
import { HelperText } from 'elements/components/HelperText';
import EditEndAdorment from './EditEndAdorment';
import { StyledBaseInput } from './styled';

export type BaseInputProps = Omit<StandardTextFieldProps, 'variant' | 'hidden'> & {
  name: string;
  onEditButtonClick?: () => void;
  maxLength?: number;
};

export const BaseInput: React.FC<BaseInputProps> = React.forwardRef<HTMLDivElement, BaseInputProps>(
  (
    {
      name,
      InputProps: MUIInputProps,
      inputProps: DOMInputProps,
      error,
      maxLength,
      helperText,
      onEditButtonClick,
      fullWidth = true,
      autoComplete = 'off',
      value,
      ...props
    },
    ref,
  ) => {
    const handleOnKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (maxLength && value !== undefined && (value as string).length >= maxLength) {
        event.preventDefault();
      }
    };

    return (
      <StyledBaseInput
        ref={ref}
        hidden
        variant="filled"
        autoCorrect="off"
        name={name}
        value={value}
        error={error}
        fullWidth={fullWidth}
        autoComplete={autoComplete}
        onKeyPress={handleOnKeyPress}
        helperText={helperText ? <HelperText helperText={helperText} error={error} /> : null}
        InputProps={{
          disableUnderline: true,
          ...MUIInputProps,
          ...(onEditButtonClick && {
            endAdornment: <EditEndAdorment onClick={onEditButtonClick} />,
          }),
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          maxLength,
          autoCapitalize: 'none',
          'data-testid': 'base-input',
          ...DOMInputProps,
        }}
        {...props}
      />
    );
  },
);
