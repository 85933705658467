import React from 'react';
import Breadcrumbs, { BreadCrumbsItems } from 'components/shared/Breadcrumbs';
import { IIdentityDetailsPage } from 'ui-interfaces/identity-details/i-identity-details-page';
import { usePermissionBasedAccess } from 'hooks/use-permission-based-access';
import { DashboardRoles } from 'models/enums/user/dashboard-roles';
import { SuspensionAction } from '../SuspensionAction';
import { LoadingTopRow } from '../LoadingHeader/LoadingTopRow';
import { StyledTopRow } from './styled';

type Props = {
  breadCrumbs: BreadCrumbsItems;
  showSuspensionModal: () => void;
  data?: IIdentityDetailsPage;
  loading: boolean;
};

export const IdentityDetailsPageTopRow: React.FC<Props> = ({ breadCrumbs, showSuspensionModal, data, loading }) => {
  const hasIdentitySuspenderPermission = usePermissionBasedAccess([DashboardRoles.identitySuspender]);

  if (loading || !data) {
    return <LoadingTopRow breadCrumbs={breadCrumbs} />;
  }

  return (
    <StyledTopRow>
      <Breadcrumbs items={breadCrumbs} />
      {hasIdentitySuspenderPermission && data?.isSuspensionActionShown && (
        <SuspensionAction isSuspendMode={data?.isSuspendMode ?? false} onClick={showSuspensionModal} />
      )}
    </StyledTopRow>
  );
};
