export enum RowType {
  text = 'text',
  textMultiLine = 'textMultiLine',
  status = 'status',
  time = 'time',
  paymentType = 'paymentType',
  cardType = 'cardType',
  link = 'link',
  valueWithoutLabel = 'valueWithoutLabel',
  approvalStatus = 'approvalStatus',
  loading = 'loading',
  mt103 = 'mt103',
  mt103NoValue = 'mt103NoValue',
  mt103Indent1 = 'mt103Indent1',
  mt103Indent1NoValue = 'mt103Indent1NoValue',
  mt103Indent2 = 'mt103Indent2',
}
