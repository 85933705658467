import React from 'react';
import { Typography } from '@mui/material';
import { RightCellBox } from 'components/shared/detail-page/rows/shared/RightCellBox';
import { Gap } from 'components/shared/Gap';
import { PaymentType } from 'ui-enums/response/transactions/payment-type';
import { getPaymentTypeIcon } from 'utils/icons-mapping/get-payment-type-icon';

type Props = {
  value: string;
  paymentType: PaymentType;
};

export const RightCell: React.FC<Props> = ({ value, paymentType }) => {
  return (
    <RightCellBox>
      {getPaymentTypeIcon(paymentType)}
      <Gap size="_8px" isHorizontal />
      <Typography color="grey.600" variant="bodyDefaultBook">
        {value}
      </Typography>
    </RightCellBox>
  );
};
