import React, { AnchorHTMLAttributes } from 'react';
import { LinkProps } from 'react-router-dom';
import { ButtonProps } from '@mui/material/Button';
import { StyledAnchor, StyledButton, StyledLink } from './styled';

type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>;

type Props = ButtonProps | LinkProps | AnchorProps;

const isLinkProps = (props: Props): props is LinkProps => !!(props as LinkProps).to;
const isAnchorProps = (props: Props): props is AnchorProps => !!(props as AnchorProps).href;

// TODO revise the approach in order to be able to use it with HOCs
function Link(props: ButtonProps): JSX.Element;
// eslint-disable-next-line no-redeclare
function Link(props: LinkProps): JSX.Element;
// eslint-disable-next-line no-redeclare
function Link(props: AnchorProps): JSX.Element;
// eslint-disable-next-line no-redeclare
function Link(props: Props): JSX.Element {
  const { children } = props;

  if (isLinkProps(props)) {
    return <StyledLink {...props}>{children}</StyledLink>;
  }

  if (isAnchorProps(props)) {
    return <StyledAnchor {...props}>{children}</StyledAnchor>;
  }

  return (
    // eslint-disable-next-line react/destructuring-assignment
    <StyledButton onClick={props.onClick} disableRipple {...props}>
      {children}
    </StyledButton>
  );
}

export { Link };
