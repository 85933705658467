import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ContentBlock from 'components/tapi/transactions/TapiTransactionDetailsPage';
import { TransactionDetailsUrlParams } from 'navigation/url-params-type';
import { SearchParamsConstants } from 'navigation/utils/search-params-constants';
import {
  useLazyGetTransactionDetailsByIdForApprovedQuery,
  useLazyGetTransactionDetailsByIdForNonApprovedQuery,
} from 'redux/api/api-transactions';
import { createTransactionDetailsViewModel } from 'factories/transactions/create-transaction-details-view-model';
import { TransactionDetails } from 'ui-interfaces/transaction-details/transaction-details';
import { useBcTapiTransactionDetailsPage } from 'hooks/breadcrumbs/use-bc-tapi-transaction-details-page';
import { useApprovePaymentMutation, useRejectPaymentMutation } from 'redux/api/api-payments';
import { getUser } from 'redux/api/api-users';
import { MessageType, setBannerData } from 'redux/banner';

export const TapiTransactionDetailsPage: React.FC = () => {
  const { breadCrumbs } = useBcTapiTransactionDetailsPage();
  const { transactionId } = useParams<TransactionDetailsUrlParams>();
  const [searchParams] = useSearchParams();

  // get data for transaction details page - start
  const { data: user } = useSelector(getUser.select(undefined));

  const lazeLazyGetTransactionDetailsByIdQuery = useMemo(() => {
    if (searchParams.get(SearchParamsConstants.approved) === 'true') {
      return useLazyGetTransactionDetailsByIdForApprovedQuery;
    }

    return useLazyGetTransactionDetailsByIdForNonApprovedQuery;
  }, [searchParams]);

  const [getTransactionDetailsByIdTrigger, transactionDetailsByIdResult] = lazeLazyGetTransactionDetailsByIdQuery();

  const fetchTransactionDetailsById = useCallback(
    () => getTransactionDetailsByIdTrigger(transactionId!),
    [getTransactionDetailsByIdTrigger, transactionId],
  );

  const [transactionDetails, setTransactionDetails] = useState<TransactionDetails>();

  useEffect(() => {
    fetchTransactionDetailsById();
  }, [fetchTransactionDetailsById]);

  useEffect(() => {
    if (transactionDetailsByIdResult.isSuccess && user) {
      setTransactionDetails(createTransactionDetailsViewModel(transactionDetailsByIdResult.data, user));
    }
  }, [transactionDetailsByIdResult, user]);
  // get data for transaction details page - end

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [approvePaymentTrigger, approvePaymentResult] = useApprovePaymentMutation();
  const approvalIsForbiddenForSelfCreatedTransactionMessage = t(
    'transactionDetailsPage.approvalIsForbiddenForSelfCreatedTransactionMessage',
  );
  const onApprove: () => void = useCallback(() => {
    if (!transactionDetails) {
      return;
    }
    if (transactionDetails.isTransactionInitiatedByTheCurrentUser) {
      dispatch(
        setBannerData({ type: MessageType.error, message: approvalIsForbiddenForSelfCreatedTransactionMessage }),
      );
      return;
    }
    approvePaymentTrigger(transactionDetails.paymentId);
  }, [transactionDetails, approvePaymentTrigger, dispatch, approvalIsForbiddenForSelfCreatedTransactionMessage]);

  const [rejectPaymentTrigger, rejectPaymentResult] = useRejectPaymentMutation();
  const onReject: () => void = useCallback(() => {
    if (!transactionDetails) {
      return;
    }

    if (transactionDetails.isTransactionInitiatedByTheCurrentUser) {
      dispatch(
        setBannerData({ type: MessageType.error, message: approvalIsForbiddenForSelfCreatedTransactionMessage }),
      );
      return;
    }
    rejectPaymentTrigger(transactionDetails.paymentId);
  }, [transactionDetails, rejectPaymentTrigger, dispatch, approvalIsForbiddenForSelfCreatedTransactionMessage]);

  return (
    <ContentBlock
      breadCrumbs={breadCrumbs}
      transactionProps={transactionDetails}
      loading={transactionDetailsByIdResult.isFetching}
      error={transactionDetailsByIdResult.isError}
      onApprove={onApprove}
      onReject={onReject}
      isApproveLoading={approvePaymentResult.isLoading}
      isRejectLoading={rejectPaymentResult.isLoading}
    />
  );
};
