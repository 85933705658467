import { CustodialAccountExtendedResponseModel } from 'models/response/custodial-accounts/custodial-account-extended-response-model';
import { ICaNamingModal } from 'ui-interfaces/custodial-accounts-details/i-ca-naming-modal';
import { CaLabelInputViewModel } from 'view-models/custodial-accounts/details/ca-naming/ca-label-input-vm';
import { CaNamingInput } from 'ui-enums/custodial-accounts/inputs/ca-naming-input';
import { CaNameInputViewModel } from 'view-models/custodial-accounts/details/ca-naming/ca-name-input-vm';
import { CaInputsStateCollectorViewModel } from 'view-models/custodial-accounts/details/ca-naming/ca-inputs-state-collector-vm';
import { Input } from 'ui-interfaces/shared/inputs/input';
import { CustodialAccountLabel } from 'models/enums/custodial-accounts/custodial-account-label';

export const createCaNamingModalViewModel = (
  custodialAccountDetails: CustodialAccountExtendedResponseModel,
): ICaNamingModal => {
  // TODO: Move to CustodialAccountsDetailsHeader when elements' button initiation logic is fixed
  const inputs: Input<string | CustodialAccountLabel, CaNamingInput>[] = [
    new CaNameInputViewModel({
      id: 'caName',
      uiElementType: CaNamingInput.name,
      value: custodialAccountDetails.name ?? '',
    }),
    new CaLabelInputViewModel({
      id: 'caLabel',
      uiElementType: CaNamingInput.label,
      value: custodialAccountDetails.label,
    }),
  ];

  return {
    formable: {
      inputs,
      inputStateCollector: new CaInputsStateCollectorViewModel(inputs),
    },
  };
};
