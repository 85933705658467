import i18n from 'localizations';
import { Identifiable } from 'ui-interfaces/user-details/identifiable';
import { UserDetailsRowType } from 'ui-enums/user-details/user-details-row-type';
import { ISubHeaderRow } from 'ui-interfaces/user-details/rows/i-sub-header-row';
import { IDefaultRow } from 'ui-interfaces/user-details/rows/i-default-row';

export const initLimitsEmptyState: Identifiable[] = [
  {
    id: '5',
    rowType: UserDetailsRowType.subHeader,
    title: i18n.t('userDetailsPage.initiateTransactions'),
  } as ISubHeaderRow,
  {
    id: '6',
    rowType: UserDetailsRowType.defaultNoBorder,
    label: i18n.t('userDetailsPage.dollarAmount'),
    value: i18n.t('userDetailsPage.emptyValue'),
  } as IDefaultRow,
  {
    id: '7',
    rowType: UserDetailsRowType.short,
    label: i18n.t('userDetailsPage.per'),
    value: i18n.t('userDetailsPage.emptyValue'),
  } as IDefaultRow,
  {
    id: '8',
    rowType: UserDetailsRowType.defaultNoBorder,
    label: i18n.t('userDetailsPage.numberOfTransactions'),
    value: i18n.t('userDetailsPage.emptyValue'),
  } as IDefaultRow,
  {
    id: '9',
    rowType: UserDetailsRowType.short,
    label: i18n.t('userDetailsPage.per'),
    value: i18n.t('userDetailsPage.emptyValue'),
  } as IDefaultRow,
];
