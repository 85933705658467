import { NavigateFunction, PathMatch } from 'react-router-dom';
import { GridRowParams } from '@mui/x-data-grid';
import { GridEventListener } from '@mui/x-data-grid/models/events';
import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { createTransactionViewModel } from 'factories/transactions/create-transaction-view-model';
import { getRouteWithParams } from 'utils/get-route-with-params';
import {
  BeneficiaryDetailsUnderCustodialAccountDetailsUnderIdentityDetailsUrlParams,
  CustodialAccountDetailsUnderIdentityDetailsUrlParams,
  CustodialAccountsDetailsUrlParams,
  IdentityDetailsUnderCustodialAccountDetails,
  TransactionDetailsUnderCustodialAccountDetailsUnderIdentityDetailsUrlParams,
  TransactionDetailsUnderCustodialAccountDetailsUrlParams,
} from 'navigation/url-params-type';
import { NESTED_PATHS, PATHS } from 'navigation/constants';
import { SearchParamsConstants } from 'navigation/utils/search-params-constants';
import { DownloadCSV } from 'hooks/use-export/interfaces';
import { FilterResult } from 'filters/interfaces/filter-result';
import { API_ROUTES } from 'redux/api/api-routes';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import { createCustodialAccountBalanceViewModel } from 'factories/balances/create-custodial-account-balance-view-model';
import { createCustodialAccountCustomAssetBalanceViewModel } from 'factories/custodial-accounts/create-custodial-account-custom-asset-balance-view-model';
import { CustomAssetBalanceResponseModel } from 'models/response/custodial-accounts/custom-asset-balance-response-model';
import { AccountBeneficiaryResponseModel } from 'models/response/custodial-accounts/account-beneficiary-response-model';
import { createBeneficiaryViewModel } from 'factories/custodial-accounts/create-beneficiary-view-model';
import { BeneficiaryRow } from 'ui-interfaces/row-interfaces/custodial-accounts/beneficiary-row';
import { DownloadPaginatedCSV } from 'hooks/use-paginated-export/interfaces';
import { PaginatedRequestParams } from 'redux/api/types';

export const onTransactionTransform = (transaction: TransactionResponseModel) => {
  return createTransactionViewModel(transaction);
};

export const onApprovedTransactionRowClick =
  (navigate: NavigateFunction, custodialAccountId: string, pathMatch: PathMatch | null) => (params: GridRowParams) => {
    const { row } = params;

    if (pathMatch?.pattern.path === NESTED_PATHS.IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS) {
      const { identityType, identityId } = pathMatch.params as CustodialAccountDetailsUnderIdentityDetailsUrlParams;
      navigate(
        getRouteWithParams(
          `${NESTED_PATHS.IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__TRANSACTION_DETAILS}?${SearchParamsConstants.approved}=true`,
          {
            transactionId: row.id,
            custodialAccountId,
            identityType,
            identityId,
          } as TransactionDetailsUnderCustodialAccountDetailsUnderIdentityDetailsUrlParams,
        ),
      );
    }

    if (pathMatch?.pattern.path === PATHS.TAPI.CUSTODIAL_ACCOUNTS_DETAILS) {
      navigate(
        getRouteWithParams(
          `${NESTED_PATHS.CUSTODIAL_ACCOUNTS_DETAILS__TRANSACTION_DETAILS}?${SearchParamsConstants.approved}=true`,
          {
            transactionId: row.id,
            custodialAccountId,
          } as TransactionDetailsUnderCustodialAccountDetailsUrlParams,
        ),
      );
    }
  };

export const onApprovedTransactionsExportClick =
  (downloadPaginatedCsv: DownloadPaginatedCSV, custodialAccountId: string) =>
  (pagination: PaginatedRequestParams, filterResult?: FilterResult) => {
    downloadPaginatedCsv({
      urlSegment: API_ROUTES.private.TRANSACTIONS,
      pagination,
      preFilterParams: {
        approved: 'true',
        isPrivateSecurities: 'false',
        custodialAccountId,
      },
      filterResult,
    });
  };

export const onAccountBalancesTransform = (balance: CustodialAccountBalanceModel) => {
  return createCustodialAccountBalanceViewModel(balance);
};

export const onCustodialAccountCustomAssetBalanceTransform = (balance: CustomAssetBalanceResponseModel) => {
  return createCustodialAccountCustomAssetBalanceViewModel(balance);
};

export const onAccountBalancesExportClick =
  (downloadCsv: DownloadCSV, custodialAccountId: string) => (filterResult?: FilterResult) => {
    downloadCsv({
      urlSegment: `${API_ROUTES.private.CUSTODIAL_ACCOUNTS}/${custodialAccountId}/balances`,
      filterResult,
    });
  };

export const onCustodialAccountCustomAssetBalanceExportClick =
  (downloadCsv: DownloadCSV, custodialAccountId: string) => (filterResult?: FilterResult) => {
    downloadCsv({
      urlSegment: getRouteWithParams(API_ROUTES.private.CUSTODIAL_ACCOUNT_CUSTOM_ASSET_BALANCES, {
        custodialAccountId,
      }),
      filterResult,
    });
  };

export const onPrivateSecurityTransactionsExportClick =
  (downloadPaginatedCsv: DownloadPaginatedCSV, custodialAccountId: string) =>
  (pagination: PaginatedRequestParams, filterResult?: FilterResult) => {
    downloadPaginatedCsv({
      urlSegment: API_ROUTES.private.TRANSACTIONS,
      pagination,
      preFilterParams: {
        approved: 'true',
        isPrivateSecurities: 'true',
        custodialAccountId,
      },
      filterResult,
    });
  };

// beneficiaries - start
export const onBeneficiaryTransform = (ab: AccountBeneficiaryResponseModel) => {
  return createBeneficiaryViewModel(ab);
};

export const onBeneficiaryCellClick =
  (navigate: NavigateFunction, pathMatch: PathMatch | null): GridEventListener<'cellClick'> =>
  props => {
    const { id: rowId, identityType: rowIdentityType } = props.row as BeneficiaryRow;

    if (pathMatch?.pattern.path === PATHS.TAPI.CUSTODIAL_ACCOUNTS_DETAILS) {
      const { custodialAccountId } = pathMatch.params as CustodialAccountsDetailsUrlParams;
      if (props.field === 'name') {
        navigate(
          getRouteWithParams(NESTED_PATHS.CUSTODIAL_ACCOUNTS_DETAILS__IDENTITY_DETAILS, {
            custodialAccountId,
            identityId: rowId,
            identityType: rowIdentityType,
          } as IdentityDetailsUnderCustodialAccountDetails),
        );
      }
    }

    if (pathMatch?.pattern.path === NESTED_PATHS.IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS) {
      const { identityId, identityType, custodialAccountId } =
        pathMatch.params as CustodialAccountDetailsUnderIdentityDetailsUrlParams;

      if (props.field === 'name') {
        navigate(
          getRouteWithParams(NESTED_PATHS.IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__BENEFICIARY_DETAILS, {
            identityId: rowId,
            identityType: rowIdentityType,
            custodialAccountId,
            parentIdentityId: identityId,
            parentIdentityType: identityType,
          } as BeneficiaryDetailsUnderCustodialAccountDetailsUnderIdentityDetailsUrlParams),
        );
      }
    }
  };
// beneficiaries - end
