import React from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { CircularProgress } from 'elements/components';
import { StyledStack } from './styled';

type Props = {
  label: React.ReactNode;
  value: string;
  isLoading?: boolean;
  isError?: boolean;
  isTotal?: boolean;
  fontVariant?: TypographyProps['variant'];
};

export const EstimatedValue: React.FC<Props> = ({
  label,
  value,
  isLoading,
  isError,
  isTotal,
  fontVariant = 'bodyDefaultBook',
}: Props) => (
  <StyledStack direction="row" justifyContent="space-between" isLoading={isLoading}>
    <Typography variant={fontVariant} color={isTotal ? 'primary.font' : 'secondary.font'} align="left">
      {label}
    </Typography>

    {isLoading ? (
      <CircularProgress size={17} />
    ) : (
      <Typography variant={fontVariant} color={isError && !isTotal ? 'secondary.font' : 'primary.font'} align="right">
        {value}
      </Typography>
    )}
  </StyledStack>
);
