import React from 'react';
import { ThemeProvider as MuiThemeProvider, darken, lighten } from '@mui/material';
import { alpha, createTheme } from '@mui/material/styles';
import { ElementsCustomTheme } from '../createCustomTheme';

const mockedColors = {
  navy: '#263698',
  azure: '#226BD0',

  white: '#FFFFFF',
  backgroundLight: '#FBFBFD',
  backgroundBlack: '#000000',
  cameraBackground: '#4B4B4B',

  errorDefault: '#D43636',
  errorDark: '#72000B',
  errorLight: '#FBEBEB',
  errorLight2: '#F99E9E',

  warningDefault: '#F6851D',
  warningDark: '#761F00',
  warningLight: '#FEF3E8',
  warningLight2: '#F6CA92',

  successDefault: '#00875A',
  successDark: '#12441F',
  successLight: '#E5F3EE',
  successLight2: '#ABF5D1',

  infoDefault: '#0F56B3',
  infoDark: '#002E9F',
  infoLight: '#E7EEF7',
  infoLight2: '#8AB9F6',

  grey700: '#1C1C1C',
  grey600: '#707070',
  grey500: '#8D8D8D',
  grey400: '#BABABA',
  grey300: '#DEDEDE',
  grey200: '#E8E8E8',
  grey100: '#F4F4F4',
  grey50: '#F7F7F7',
};

const fontFamily = 'MalloryMP';

const mockedTheme = {
  components: {
    MuiCssBaseline: {},
  },
  borderRadius: '10px',
  fontFamily,
  palette: {
    primary: {
      main: mockedColors.navy,
      mainLighten40: lighten(mockedColors.navy, 0.4),
      mainDarken25: darken(mockedColors.navy, 0.25),
      mainTransparent50: alpha(mockedColors.navy, 0.5),
      font: mockedColors.grey700,
      buttonFont: mockedColors.white,
    },
    secondary: {
      main: mockedColors.azure,
      font: mockedColors.grey600,
    },
    tertiary: {
      main: mockedColors.grey200,
      mainLighten40: lighten(mockedColors.grey200, 0.4),
      mainDarken20: darken(mockedColors.grey200, 0.2),
      mainTransparent20: alpha(mockedColors.grey200, 0.2),
      mainTransparent40: alpha(mockedColors.grey200, 0.4),
      mainTransparent50: alpha(mockedColors.grey200, 0.5),
      mainTransparent60: alpha(mockedColors.grey200, 0.6),
    },
    error: {
      main: mockedColors.errorDefault,
      dark: mockedColors.errorDark,
      light: mockedColors.errorLight,
      light2: mockedColors.errorLight2,
    },

    warning: {
      main: mockedColors.warningDefault,
      dark: mockedColors.warningDark,
      light: mockedColors.warningLight,
      light2: mockedColors.warningLight2,
    },
    success: {
      main: mockedColors.successDefault,
      dark: mockedColors.successDark,
      light: mockedColors.successLight,
      light2: mockedColors.successLight2,
    },
    info: {
      main: mockedColors.infoDefault,
      dark: mockedColors.infoDark,
      light: mockedColors.infoLight,
      light2: mockedColors.infoLight2,
    },
    background: {
      default: mockedColors.white,
      paper: mockedColors.backgroundLight,
      dark: mockedColors.backgroundBlack,
      whiteTransparent50: alpha(mockedColors.white, 0.5),
      custom: '',
    },
    grey: {
      700: mockedColors.grey700,
      600: mockedColors.grey600,
      500: mockedColors.grey500,
      400: mockedColors.grey400,
      300: mockedColors.grey300,
      200: mockedColors.grey200,
      100: mockedColors.grey100,
      50: mockedColors.grey50,
    },
  },
  typography: {
    h1: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '38px',
      lineHeight: '135%',
      letterSpacing: '-0.04em',
    },
    h2: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '32px',
      lineHeight: '138%',
      letterSpacing: '-0.03em',
    },
    h3: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '26px',
      lineHeight: '138%',
      letterSpacing: '-0.02em',
    },
    h4: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '22px',
      lineHeight: '140%',
      letterSpacing: '-0.02em',
    },
    h5: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '148%',
      letterSpacing: '-0.02em',
    },
    h6: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '18px',
      lineHeight: '148%',
      letterSpacing: '-0.02em',
    },
    signatureDefault: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '10px',
      lineHeight: '170%',
    },
    bodyDefaultBook: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '170%',
      letterSpacing: '-0.02em',
    },
    bodyDefaultMedium: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '170%',
    },
    bodySmallBook: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '170%',
    },
    bodySmallBold: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '170%',
    },
    bodySmallMedium: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '170%',
    },
    subtitleBook: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: '148%',
    },
    subtitleMedium: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '15px',
      lineHeight: '148%',
    },
    captionMedium: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '11px',
      lineHeight: '100%',
    },
    overlineDefaultMedium: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '133%',
    },
    overlineSmallMedium: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '11px',
      lineHeight: '133%',
    },
    buttonSmall: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '13px',
      lineHeight: '150%',
    },
    buttonMedium: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '150%',
    },
    buttonLarge: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '15px',
      lineHeight: '150%',
    },
    bodyDefaultMediumUnderline: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '170%',
      textDecoration: 'underline',
    },
    bodySmallMediumUnderline: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '170%',
      textDecoration: 'underline',
    },
    button: {
      textTransform: 'none',
    },
  },
} as ElementsCustomTheme;

const MockedThemeProvider = ({ children }: { children: React.ReactNode }) => (
  <MuiThemeProvider theme={createTheme(mockedTheme)}>{children}</MuiThemeProvider>
);

export default MockedThemeProvider;
