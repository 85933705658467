import React from 'react';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/side-menu/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/side-menu/arrow-right.svg';
import { StyledIconButton, StyledIconButtonCollapsed } from './styled';

type Props = {
  isShown: boolean;
  isDrawerOpen: boolean;
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
};
export const FloatingButton: React.FC<Props> = ({ isShown, isDrawerOpen, handleDrawerClose, handleDrawerOpen }) => {
  if (isDrawerOpen) {
    return (
      <StyledIconButton isShown={isShown} onClick={handleDrawerClose}>
        <ArrowLeftIcon />
      </StyledIconButton>
    );
  }
  return (
    <StyledIconButtonCollapsed isShown={isShown} onClick={handleDrawerOpen}>
      <ArrowRightIcon />
    </StyledIconButtonCollapsed>
  );
};
