export const shareText = async (text = ''): Promise<void | boolean> => {
  // eslint-disable-next-line no-undef
  const data: ShareData = {
    text,
  };
  if (navigator.share && text) {
    try {
      await navigator.share(data);
      return;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error: ${error}`);
    }
  }
  // eslint-disable-next-line no-console
  console.log('Web Share API not supported!');
};
