import React from 'react';
import { Text as _Text, View as _View } from '@react-pdf/renderer';

import { RightCellBox } from 'components/tapi/transactions/TapiTransactionDetailsPage/pdf/rows/shared/RightCellBox';
import { styles } from './styles';

const Text: any = _Text;
const View: any = _View;

type Props = {
  code: string;
  value: string;
};

export const RightCell: React.FC<Props> = ({ code, value }) => {
  return (
    <RightCellBox>
      <View style={styles.row}>
        <Text style={styles.code}>{code}</Text>
        <Text style={styles.text}>{value}</Text>
      </View>
    </RightCellBox>
  );
};
