import React from 'react';
import { Typography } from '@mui/material';
import { EstimatedValue, Spacer, Stack } from 'elements/components';
import { AssetImage } from 'components/shared/Cells/AssetImage';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { ICoinListItem } from 'ui-interfaces/elements/i-coin-list-item';
import { StyledPrimaryDivider } from './styled';

type Props = {
  assetData: ICoinListItem;
  totalLabel: string;
  amount?: string;
  totalAmount?: string;
};

export const TransactionSummaryDynamicAsset: React.FC<Props> = ({ assetData, amount, totalAmount, totalLabel }) => {
  const { isMobile } = useIsMobile();

  return (
    <>
      <Stack alignCenter>
        {!isMobile && <Spacer size={8} />}
        <AssetImage
          url={assetData.asset.imageUrl}
          size={assetData.size}
          defaultAssetIcon={assetData.asset.defaultAssetIcon}
        />

        <Spacer size={16} />
        <Typography variant="h3" color="primary.font">
          {amount}
        </Typography>
        <Spacer size={4} />
        <Typography variant="bodyDefaultBook" color="secondary.font">
          {assetData.asset.longName} ({assetData.asset.shortName})
        </Typography>
        <Spacer size={isMobile ? 32 : 40} />
      </Stack>
      {totalAmount && <EstimatedValue label={assetData.asset.longName} value={totalAmount} />}

      <StyledPrimaryDivider />
      {totalAmount && <EstimatedValue label={totalLabel} value={totalAmount} isTotal fontVariant="subtitleMedium" />}
    </>
  );
};
