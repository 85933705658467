import { CardTypes } from './cards';

export enum CustodialAccountTypeEnum {
  Personal = 'personal',
  Business = 'business',
  RothIra = 'rothIra',
  TraditionalIra = 'traditionalIra',
  SepIra = 'sepIra',
  SimpleIra = 'simpleIra',
}

export enum ExternalAccountTypeEnum {
  AchExternalAccount = 'achExternalAccount',
  WireExternalAccount = 'wireExternalAccount',
  CardExternalAccount = 'cardExternalAccount',
  InternationalWireExternalAccount = 'internationalWireExternalAccount',
}
export type CustodialAccount = {
  id: string;
  balance: number;
  type: CustodialAccountTypeEnum;
  accountNumber: string;
  createdAtUtc: string;
};

export type ExternalAccount = {
  id: string;
  last4: string;
  type: ExternalAccountTypeEnum;
  createdAtUtc: string;
  network?: CardTypes;
  name?: string;
  bankName?: string;
  country?: string;
};

export type PaymentAccount = CustodialAccount | ExternalAccount | undefined;
