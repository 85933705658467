import React from 'react';
import { Checkbox } from '@mui/material';
import { CheckboxFormControlLabel, CheckboxLabel } from 'components/shared/Checkbox';
import { IPermissionsCheckbox } from 'ui-interfaces/user-form/i-permissions-checkbox';

type Props = {
  data: IPermissionsCheckbox;
  isChecked: boolean;
  onChange: (val: string) => void;
};

export const PermissionsCheckbox: React.FC<Props> = ({ data, isChecked = false, onChange }) => {
  return (
    <CheckboxFormControlLabel
      control={<Checkbox value={data.value} onChange={e => onChange(e.target.value)} checked={isChecked} />}
      label={<CheckboxLabel title={data.title} description={data.description} />}
    />
  );
};
