import React from 'react';
import { StyledButton, StyledCircularProgress } from './styled';
import { ButtonProps } from './types';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
  }
}

export const Button: React.FC<ButtonProps> = ({ children, isLoading, ...props }) => (
  <StyledButton
    {...props}
    startIcon={!isLoading && props.startIcon}
    endIcon={!isLoading && props.endIcon}
    onClick={!isLoading ? props.onClick : undefined}
  >
    {isLoading ? <StyledCircularProgress size={18} /> : children}
  </StyledButton>
);
