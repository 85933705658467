import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AssetResponseModel } from 'models/response/assets/asset-response-model';
import { ICoinListItem } from 'ui-interfaces/elements/i-coin-list-item';
import {
  setDepositCryptoSelectedCoin,
  setDepositSelectedAsset,
  setPage,
  useElement,
} from 'elements/element-transfer/contexts/Element';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { mapAssetResponseModelToICoinListItem } from 'utils/elements/map-asset-response-model-to-i-coin-list-item';
import { DepositCoin } from 'elements/element-transfer/types';
import { CoinListItemNew } from './CoinListItemNew';
import { StyledContainer, StyledMessage, StyledSubtitle } from './styled';

type Props = {
  assets: AssetResponseModel[];
  searchQuery: string;
};

const isAssetNameContainSearchQuery = (assetResponse: AssetResponseModel, searchedQuery: string): boolean => {
  return (
    assetResponse.network?.toLowerCase().indexOf(searchedQuery.toLowerCase()) > -1 ||
    assetResponse.assetTicker?.toLowerCase().indexOf(searchedQuery.toLowerCase()) > -1
  );
};

export const CoinsListNew: React.FC<Props> = ({ assets, searchQuery }) => {
  const { t } = useTranslation();
  const { dispatch } = useElement();

  const onCoinClick = async (assetResponse: AssetResponseModel) => {
    dispatch(
      setDepositCryptoSelectedCoin({
        assetType: assetResponse.assetTicker,
        network: assetResponse.network,
      } as DepositCoin),
    );
    dispatch(setDepositSelectedAsset(assetResponse));
    dispatch(setPage(Paths.DepositCryptoQrCode));
  };

  const filteredAssets = useMemo(
    () => assets.filter(assetResponse => isAssetNameContainSearchQuery(assetResponse, searchQuery)),
    [assets, searchQuery],
  );

  return (
    <>
      {assets.length >= 8 && filteredAssets.length === 0 && (
        <StyledContainer data-testid="CoinList-emptySearch">
          <StyledSubtitle variant="subtitleMedium">
            {t('elements.features.coinsListPage.noResultsTitle')} “{searchQuery}”
          </StyledSubtitle>
          <StyledMessage variant="bodyDefaultBook">
            {t('elements.features.coinsListPage.noResultsSubtitle')}
          </StyledMessage>
        </StyledContainer>
      )}
      {filteredAssets.length > 0 &&
        filteredAssets.map((assetResponse, idx) => {
          const data: ICoinListItem = mapAssetResponseModelToICoinListItem(assetResponse);

          return (
            <CoinListItemNew
              key={`${assetResponse.assetTicker}${idx.toString()}`}
              data={data}
              onClick={() => onCoinClick(assetResponse)}
            />
          );
        })}
    </>
  );
};
