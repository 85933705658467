import { NETWORK_ERROR_MESSAGE, UNAVAILABLE_SERVICE_MESSAGE } from 'elements/constants/index';
import { CustomError } from 'elements/api/errors/customError';
import { GenericError } from 'elements/api/types';
import {
  CANNOT_LOCK_TRANSACTION_AMOUNT,
  EXCEED_TRANSACTION_LIMIT,
  MAX_LENGTH_OF_BANK_NAME,
  NO_BANK_INF0_FOUND_WITH_ROUTING_NUMBER,
  ROUTING_NUMBER_DOES_NOT_PROCESS_FEDWIRE_PAYMENTS,
  OTHER_EXCEPTIONS,
} from 'redux/api/error-codes';

const getStatusCode = (e: unknown): number | undefined => {
  const error = e as GenericError;
  return error?.status;
};

type CheckHTTPStatus = (e: unknown) => boolean;

export const isBadRequest: CheckHTTPStatus = e => getStatusCode(e) === 400;

export const isNotFound: CheckHTTPStatus = e => getStatusCode(e) === 404;

export const isAlreadyExists: CheckHTTPStatus = e => getStatusCode(e) === 409;

export const isNetworkError: CheckHTTPStatus = e => (e as GenericError)?.message === NETWORK_ERROR_MESSAGE;

export const isUnavailableServiceError = (e: unknown): boolean =>
  (e as CustomError)?.responseData?.errors?.[''][0] === UNAVAILABLE_SERVICE_MESSAGE;

export const isReachedLimits = (e: unknown): boolean =>
  Boolean((e as CustomError)?.responseData?.errors?.[EXCEED_TRANSACTION_LIMIT]);

export const isCannotLockTxAmount = (e: unknown): boolean =>
  Boolean((e as CustomError)?.responseData?.errors?.[CANNOT_LOCK_TRANSACTION_AMOUNT]);

export const isRoutingNumberDoesNotProcessFedwirePayments = (e: unknown): boolean =>
  Boolean((e as CustomError)?.responseData?.errors?.[ROUTING_NUMBER_DOES_NOT_PROCESS_FEDWIRE_PAYMENTS]);

export const isMaxLengthOfBankName = (e: unknown): boolean =>
  Boolean((e as CustomError)?.responseData?.errors?.[MAX_LENGTH_OF_BANK_NAME]);

export const isNoBankInfoFoundWithRoutingNumber = (e: unknown): boolean =>
  Boolean((e as CustomError)?.responseData?.errors?.[NO_BANK_INF0_FOUND_WITH_ROUTING_NUMBER]);

export const isOtherExceptions = (e: unknown): boolean =>
  Boolean((e as CustomError)?.responseData?.errors?.[OTHER_EXCEPTIONS]);
