import { Divider, css } from '@mui/material';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { ROOT_CONTAINER_MARGIN_DESKTOP, ROOT_CONTAINER_MARGIN_MOBILE, preventForwardProps } from 'elements/utils';

export const StyledWideDivider = elementsStyled(
  Divider,
  preventForwardProps(['isMobile']),
)<{ isMobile: boolean }>(({ theme: { palette }, isMobile }) => {
  const margin = isMobile ? ROOT_CONTAINER_MARGIN_MOBILE.HORIZONTAL : ROOT_CONTAINER_MARGIN_DESKTOP.HORIZONTAL;

  return css`
    width: calc(100% + ${margin * 2}px);
    margin: 0 -${margin}px;
    border-bottom: 8px solid ${palette.tertiary.mainTransparent40};
  `;
});
