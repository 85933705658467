import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'hasBottomBorder',
})<{ hasBottomBorder: boolean }>(({ theme, hasBottomBorder }) => ({
  display: 'flex',
  flexDirection: 'row',
  borderBottom: hasBottomBorder ? `1px solid ${theme.palette.grey['200']}` : 'none',
}));
