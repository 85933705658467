import React from 'react';
import { Typography } from '@mui/material';

type Props = {
  label?: string;
};

const SubHeaderRow: React.FC<Props> = ({ label }) => {
  if (!label) {
    return null;
  }

  return (
    <Typography color="grey.600" variant="bodyDefaultBook">
      {label}
    </Typography>
  );
};

export default SubHeaderRow;
