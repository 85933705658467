import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';
import { LeftCellWidth } from 'components/shared/detail-page/rows/shared/types';
import { DEFAULT_LEFT_CELL_WIDTH, LARGE_LEFT_CELL_WIDTH } from 'components/shared/detail-page/rows/shared/constants';

export const StyledBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'large',
})<BoxProps & { large?: boolean }>(({ theme, large }) => {
  const boxWidth: LeftCellWidth = large ? LARGE_LEFT_CELL_WIDTH : DEFAULT_LEFT_CELL_WIDTH;

  return {
    width: pxToRem(boxWidth),
    display: 'flex',
    alignItems: 'center',
    paddingRight: pxToRem(theme.gaps._24px),
  };
});
