import React from 'react';
import { Gap } from 'components/shared/Gap';
import { StyledTitleTypography, StyledSubitleTypography, StyledRootColumn, StyledContentColumn } from './styled';

type Props = {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
};
export const IconWithLabels: React.FC<Props> = ({ icon, title, subtitle }) => {
  return (
    <StyledRootColumn>
      <StyledContentColumn>
        {icon}
        <Gap size="_32px" />
        <StyledTitleTypography variant="h5">{title}</StyledTitleTypography>
        <Gap size="_12px" />
        <StyledSubitleTypography>{subtitle}</StyledSubitleTypography>
      </StyledContentColumn>
    </StyledRootColumn>
  );
};
