import React from 'react';
import { StyledContainer, StyledHeaderLabel, StyledMainRow } from './styled';

type Props = {
  headerName: string;
};

export const CustomHeader: React.FC<Props> = ({ headerName }) => {
  return (
    <StyledContainer>
      <StyledMainRow>
        <StyledHeaderLabel>{headerName}</StyledHeaderLabel>
      </StyledMainRow>
    </StyledContainer>
  );
};
