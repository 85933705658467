import dayjs from 'dayjs';
import i18n from 'localizations';
import { SingleValueFilter } from 'filters/single-value-filter';
import { MultiValueFilter } from 'filters/multi-value-filter';
import { DateFilter } from 'filters/date-filter';
import { Filter } from 'filters/interfaces/filter';
import { Operators } from 'filters/operators';
import { phoneValueFormatter } from 'filters/value-formatters/phone-formatter';
import { IdentityValueSets } from 'filters/common-value-sets/identity-value-sets';
import { IdentityFilterProperty } from 'ui-enums/identities/identity-filter-property';

export const createBusinessIdentitiesFilters: () => Filter[] = () => [
  new SingleValueFilter({
    property: IdentityFilterProperty.companyName,
    displayProperty: i18n.t('identitiesPage.companyName'),
    operators: [Operators.contains, Operators.equals],
    operatorsDisplayValues: [i18n.t('filters.operators.contains'), i18n.t('filters.operators.equals')],
    initialOperator: Operators.contains,
    initialValue: '',
  }),
  new SingleValueFilter({
    property: IdentityFilterProperty.id,
    displayProperty: i18n.t('identitiesPage.id'),
    operators: [Operators.equals],
    operatorsDisplayValues: [i18n.t('filters.operators.equals')],
    initialOperator: Operators.equals,
    initialValue: '',
  }),
  new SingleValueFilter({
    property: IdentityFilterProperty.email,
    displayProperty: i18n.t('identitiesPage.email'),
    operators: [Operators.contains, Operators.equals],
    operatorsDisplayValues: [i18n.t('filters.operators.contains'), i18n.t('filters.operators.equals')],
    initialOperator: Operators.contains,
    initialValue: '',
  }),
  new SingleValueFilter({
    property: IdentityFilterProperty.phone,
    displayProperty: i18n.t('identitiesPage.phone'),
    operators: [Operators.contains, Operators.equals],
    operatorsDisplayValues: [i18n.t('filters.operators.contains'), i18n.t('filters.operators.equals')],
    initialOperator: Operators.contains,
    initialValue: '',
    valueFormatter: phoneValueFormatter,
  }),
  new MultiValueFilter({
    property: IdentityFilterProperty.kycLevel,
    displayProperty: i18n.t('identitiesPage.kybLevel'),
    operators: [Operators.equals],
    operatorsDisplayValues: [i18n.t('filters.operators.equals')],
    initialOperator: Operators.equals,
    values: ['l0', 'l1', 'l2'],
    displayValues: ['L0', 'L1', 'L2'],
    initialValue: '',
  }),
  new MultiValueFilter({
    property: IdentityFilterProperty.status,
    displayProperty: i18n.t('identitiesPage.status'),
    operators: [Operators.equals],
    operatorsDisplayValues: [i18n.t('filters.operators.equals')],
    initialOperator: Operators.equals,
    values: IdentityValueSets.identityStatusModelValueSet.map(el => el.value),
    displayValues: IdentityValueSets.identityStatusModelValueSet.map(el => el.displayValue),
    initialValue: '',
  }),
  new DateFilter({
    property: IdentityFilterProperty.externalCreatedAt,
    displayProperty: i18n.t('identitiesPage.dateCreated'),
    operators: [Operators.equals, Operators.greaterThan, Operators.lessThan],
    operatorsDisplayValues: [
      i18n.t('filters.operators.is'),
      i18n.t('filters.operators.isAfter'),
      i18n.t('filters.operators.isBefore'),
    ],
    initialOperator: Operators.equals,
    initialValue: dayjs().format(), // current date
  }),
];
