import { Filterable } from 'ui-interfaces/shared/filters/filterable';
import { Filter } from 'filters/interfaces/filter';
import { SelectionMacroCommand } from 'filters/commands/selection-macro-command';
import { getCustodialAccountCustomAssetBalanceFilters } from 'utils/custodial-accounts/get-custodial-account-custom-asset-balance-filters';
import { createCustodialAccountCustomAssetBalanceFilters } from './create-custodial-account-custom-asset-balance-filters';

export class CustodialAccountCustomAssetBalanceListFiltersViewModel implements Filterable {
  readonly filters: Filter[];

  readonly selectionMacroCommand: SelectionMacroCommand;

  constructor() {
    const filterable = getCustodialAccountCustomAssetBalanceFilters(createCustodialAccountCustomAssetBalanceFilters());
    this.filters = filterable.filters;
    this.selectionMacroCommand = filterable.selectionMacroCommand;
  }
}
