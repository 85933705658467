import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import * as api from 'elements/element-trade/api';
import { TradeFlow } from 'elements/element-trade/types';
import { CustomError, isNetworkError, isReachedLimits } from 'elements/api';
import { LoadingPage } from 'elements/features/LoadingErrorState';
import {
  setBuyDepositPaymentId,
  setBuyTradePaymentId,
  setCustodialAccountList,
  setOrderFailed,
  setIsOrderSucceed,
  setPage,
  setSellPaymentId,
  useElement,
  setLockId,
} from 'elements/element-trade/contexts/Element';
import { Paths } from 'elements/element-trade/navigation/routes';
import { setErrorBanner } from 'elements/utils';
import { getParsedTraceId } from 'elements/element-transfer/utils/getParsedTraceId';

const ProcessingOrderPage: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch, state } = useElement();

  const { selectedBuySellCoin, amount, note } = state[state.flowType];
  const { paymentAccount, custodialAccountList, flowType, lockId } = state;

  const processOrder = async () => {
    try {
      const isCustodialSelected = custodialAccountList.some(el => el.id === paymentAccount?.id);
      const firstCustodialAccountId = custodialAccountList[0]?.id;
      let custodialAccountId = isCustodialSelected ? paymentAccount?.id : firstCustodialAccountId;

      // TODO delete redundant getCustodialAccounts request
      if (flowType === TradeFlow.Buy && !custodialAccountId) {
        const custodialAccounts = await api.tradeElement.getCustodialAccounts(state.custodialAccountId);
        dispatch(setCustodialAccountList(custodialAccounts.data));
        custodialAccountId = custodialAccounts.data?.[0]?.id;
      }

      if (custodialAccountId && selectedBuySellCoin?.assetTicker) {
        dispatch(setOrderFailed(false));

        if (flowType === TradeFlow.Buy) {
          const buyResponse = await api.tradeElement.processBuyOrder({
            source: {
              custodialAccountId,
            },
            assetType: selectedBuySellCoin?.assetTicker,
            network: selectedBuySellCoin?.network,
            amount: +amount,
            lockId,
            ...(note ? { comment: note } : {}),
          });
          if (buyResponse.depositPaymentId) {
            dispatch(setBuyDepositPaymentId(buyResponse.depositPaymentId));
          }
          if (buyResponse.tradePaymentId) {
            dispatch(setBuyTradePaymentId(buyResponse.tradePaymentId));
          }
        } else {
          if (!state.sellFlow.custodialAccountId) {
            return;
          }

          const sellResponse = await api.tradeElement.processSellOrder({
            custodialAccountId: state.sellFlow.custodialAccountId,
            assetType: selectedBuySellCoin?.assetTicker,
            network: selectedBuySellCoin?.network,
            amount: +amount,
            lockId,
            ...(note ? { comment: note } : {}),
          });
          dispatch(setSellPaymentId(sellResponse.paymentId));
        }

        dispatch(setLockId(''));
        dispatch(setIsOrderSucceed(true));
        dispatch(setPage(Paths.Order));
      } else {
        throw new Error('No custodialAccount provided');
      }
    } catch (e) {
      const error = e as CustomError;
      if (isNetworkError(error)) {
        dispatch(setPage(Paths.Review));
        return;
      }
      if (isReachedLimits(error)) {
        dispatch(setOrderFailed(true, t('serverError.transactionlimitsError')));
        // eslint-disable-next-line no-console
        console.error('Limits error', error);
      } else if (error?.responseData?.traceId && flowType === TradeFlow.Sell) {
        const traceId = getParsedTraceId(error?.responseData?.traceId);
        dispatch(setOrderFailed(true, '', traceId));
        // eslint-disable-next-line no-console
        console.error('Processing order error', error);
      }

      setErrorBanner(e, enqueueSnackbar, true);

      dispatch(setPage(Paths.Review));
    }
  };

  useEffect(() => {
    (async () => {
      if (state.isOrderSucceed) {
        dispatch(setPage(Paths.Order));
        return;
      }

      await processOrder();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingPage />;
};

export default ProcessingOrderPage;
