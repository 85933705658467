import { Box, IconButton, darken } from '@mui/material';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { preventForwardProps } from 'elements/utils';
import { Button } from 'elements/components/Button';

export const StyledContainer = elementsStyled(Box)`
  display: flex;
  flex-direction: column;
  padding: 24px 20px 40px;
`;

export const StyledHeadline = elementsStyled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const StyledCloseButton = elementsStyled(
  IconButton,
  preventForwardProps(['isMobile']),
)<{ isMobile: boolean }>(
  ({ theme, isMobile }) => css`
    background: ${isMobile ? theme.palette.tertiary.mainTransparent40 : 'none'};
    color: ${theme.palette.secondary.font};

    &:hover {
      background: ${theme.palette.tertiary.mainTransparent40};
    }
  `,
);

export const StyledButton = elementsStyled(Button)(
  ({ theme }) => css`
    border-color: ${theme.palette.error.main};
    color: ${theme.palette.error.main};

    @media (hover: hover) {
      &:hover {
        color: ${darken(theme.palette.error.main, 0.25)};
        border-color: ${darken(theme.palette.error.main, 0.25)};
      }
    }

    @media (hover: none) {
      &:hover {
        border-color: ${theme.palette.error.main};
        color: ${theme.palette.error.main};
      }
    }
  `,
);
