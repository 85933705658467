import React from 'react';
import { Typography } from '@mui/material';
import { Gap } from 'components/shared/Gap';
import { StatusCell } from 'components/shared/Cells/StatusCell';
import { StatusCellProps } from 'ui-interfaces/statuses/status-cell-props';
import { StyledTitleRowSkeleton, StyledRow } from './styled';

type Props = {
  title: string;
  loading: boolean;
  tooltipMessage?: string;
  statusCellProps: StatusCellProps;
};

const TitleRow: React.FC<Props> = ({ title, loading, tooltipMessage, statusCellProps }) => {
  if (loading) {
    return (
      <StyledRow>
        <StyledTitleRowSkeleton animation="pulse" variant="circular" />
      </StyledRow>
    );
  }

  return (
    <StyledRow>
      <Typography variant="h3">{title}</Typography>
      <Gap size="_12px" isHorizontal />
      <StatusCell {...statusCellProps} tooltipMessage={tooltipMessage} />
    </StyledRow>
  );
};

export default TitleRow;
