import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import {
  CommonGridBox,
  CommonLayoutContainer,
  CommonLayoutContent,
  CommonLayoutHeader,
  CommonPageTitle,
} from 'components/shared/layouts/CommonLayout';
import { Gap } from 'components/shared/Gap';
import { useLazyGetRoleTemplatesQuery } from 'redux/api/api-users';
import { CreateUserDialogContainer } from './CreateUserDialogContainer';
import { UsersGridContainer } from './UsersGridContainer';

export const SettingsPageContent: React.FC = () => {
  const [fetchRoleTemplates] = useLazyGetRoleTemplatesQuery();
  const [canCreateUserModalBeOpened, setCanCreateUserModalBeOpened] = useState(false);
  const onOpenCreateUserModal = () => {
    setCanCreateUserModalBeOpened(true);
  };
  const onCloseCreateUserModal = () => setCanCreateUserModalBeOpened(false);

  useEffect(() => {
    fetchRoleTemplates(undefined);
  }, [fetchRoleTemplates]);

  return (
    <CommonLayoutContainer>
      <CommonLayoutHeader>
        <Gap size="_40px" />
        <CommonPageTitle>{t('settingsPage.title')}</CommonPageTitle>
        <Gap size="_32px" />
      </CommonLayoutHeader>
      <CommonLayoutContent>
        <CommonGridBox>
          <UsersGridContainer onCreateUserClick={onOpenCreateUserModal} />
        </CommonGridBox>
      </CommonLayoutContent>
      <CreateUserDialogContainer onClose={onCloseCreateUserModal} isOpen={canCreateUserModalBeOpened} />
    </CommonLayoutContainer>
  );
};
