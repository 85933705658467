import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormGroup, RadioGroup, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { ReactComponent as CloseIcon } from 'assets/icons/grey-close-icon.svg';
import { Gap } from 'components/shared/Gap';
import useForm from 'hooks/use-form';
import { PhoneField } from 'components/shared/inputs/PhoneField';
import { BaseTextField } from 'components/shared/inputs/BaseTextField';
import { Row } from 'components/shared/Flex/Row';
import { useYupRules } from 'hooks/use-yup-rules';
import { UpdateUserDetailRequestModel } from 'models/request/users/update-user-detail-request-model';
import { LoadingButtonWithSkeleton } from 'components/shared/buttons/LoadingButtonWithSkeleton';
import { RadioGroupLabel } from 'components/shared/Radio';
import { IEditUserFormViewModel } from 'ui-interfaces/user-form/edit-user-form/i-edit-user-form-vm';
import { RangeType } from 'models/enums/user/range-type';
import { CheckboxGroupLabel } from 'components/shared/Checkbox';
import { UserLimitType } from 'models/enums/user/user-limit-type';
import { IPermissionsCheckbox } from 'ui-interfaces/user-form/i-permissions-checkbox';
import { ILimitInput } from 'ui-interfaces/user-form/i-limit-input';
import { RoleRadio } from 'components/shared/user-form/RoleRadio';
import { PermissionsCheckbox } from 'components/shared/user-form/PermissionsCheckbox';
import { Limit } from 'components/shared/user-form/Limit';
import ErrorAlert from 'components/shared/user-form/ErrorAlert';
import {
  StyledCheckboxFormControl,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledDivider,
  StyledIconButton,
  StyledLink,
  StyledRadioFormControl,
  StyledRow,
  StyledTypography,
} from './styled';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onUserEdit: (values: UpdateUserDetailRequestModel) => void;
  isLoading: boolean;
  formViewModel: IEditUserFormViewModel;
};

type UiRoleTemplateInputsState = {
  roleIndex: number;
  permissions: IPermissionsCheckbox[];
  approveLimits: ILimitInput[];
  areApproveLimitsShown: boolean;
  initLimits: ILimitInput[];
  areInitLimitsShown: boolean;
  approveLimitsErrorMessage: string | undefined;
  initLimitsErrorMessage: string | undefined;
};

const getUpdatedUiRoleTemplateInputsState = (formViewModel: IEditUserFormViewModel): UiRoleTemplateInputsState => {
  return {
    roleIndex: formViewModel.roleTemplatesInputs.selectedUserRoleTemplateIndex,
    permissions: formViewModel.roleTemplatesInputs.permissions,
    approveLimits: formViewModel.roleTemplatesInputs.approveLimitsCollection.items,
    areApproveLimitsShown: formViewModel.roleTemplatesInputs.approveLimitsCollection.items.length > 0,
    initLimits: formViewModel.roleTemplatesInputs.initLimitsCollection.items,
    areInitLimitsShown: formViewModel.roleTemplatesInputs.initLimitsCollection.items.length > 0,
    approveLimitsErrorMessage: formViewModel.roleTemplatesInputs.approveLimitsCollection.errorMessage,
    initLimitsErrorMessage: formViewModel.roleTemplatesInputs.initLimitsCollection.errorMessage,
  };
};

export const EditUserDialog: React.FC<Props> = ({ isOpen, onClose, onUserEdit, isLoading, formViewModel }) => {
  const { t } = useTranslation();
  const { firstNameRule, lastNameRule, phoneRule } = useYupRules();
  const {
    handleSubmit,
    formikHelpers: { getFormikProps, withOnChangeStringParameter },
    submitForm,
    formik: { values: formValues, resetForm, isValid: isFormValid, validateForm },
  } = useForm({
    initialValues: {
      firstName: formViewModel.firstName,
      lastName: formViewModel.lastName,
      phone: formViewModel.phone,
    },
    validateOnChange: true,
    yupSchema: {
      firstName: firstNameRule,
      lastName: lastNameRule,
      phone: phoneRule,
    },
    onSubmit: (_, { setSubmitting }) => {
      const editUserFormState: UpdateUserDetailRequestModel = formViewModel.getState();
      onUserEdit(editUserFormState);
      setSubmitting(false);
    },
  });
  const phoneFormikProps = withOnChangeStringParameter(getFormikProps('phone'));
  const firstNameFormikProps = getFormikProps('firstName');
  const lastNameFormikProps = getFormikProps('lastName');

  useEffect(() => {
    formViewModel.firstName = formValues.firstName;
    formViewModel.lastName = formValues.lastName;
    formViewModel.phone = formValues.phone;
  }, [formValues.firstName, formValues.lastName, formValues.phone, formViewModel]);

  const [uiRoleTemplateInputsState, setUiRoleTemplateInputsState] = useState<UiRoleTemplateInputsState>(() =>
    getUpdatedUiRoleTemplateInputsState(formViewModel),
  );

  const onRoleIndexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formViewModel.roleTemplatesInputs.selectedUserRoleTemplateIndex = parseInt(e.target.value, 10);
    setUiRoleTemplateInputsState(getUpdatedUiRoleTemplateInputsState(formViewModel));
  };

  const onPermissionsCheckboxChange = (val: string) => {
    formViewModel.roleTemplatesInputs.selectPermission(parseInt(val, 10));
    setUiRoleTemplateInputsState(getUpdatedUiRoleTemplateInputsState(formViewModel));
  };

  // approve limits - start
  const onApproveLimitDelete = (id: string) => {
    formViewModel.roleTemplatesInputs.approveLimitsCollection.remove(id);
    formViewModel.roleTemplatesInputs.approveLimitsCollection.validate();
    setUiRoleTemplateInputsState(getUpdatedUiRoleTemplateInputsState(formViewModel));
  };

  const onApproveLimitTypeValueChange = (limit: ILimitInput, val: SelectChangeEvent<UserLimitType>) => {
    limit.typeValue = val.target.value as UserLimitType;
    formViewModel.roleTemplatesInputs.approveLimitsCollection.validate();
    setUiRoleTemplateInputsState(getUpdatedUiRoleTemplateInputsState(formViewModel));
  };

  const onApproveLimitMaxValueChange = (
    limit: ILimitInput,
    val: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    limit.maxValue = val.target.value;
    formViewModel.roleTemplatesInputs.approveLimitsCollection.validate();
    setUiRoleTemplateInputsState(getUpdatedUiRoleTemplateInputsState(formViewModel));
  };

  const onApproveLimitRangeValueChange = (limit: ILimitInput, val: SelectChangeEvent<RangeType>) => {
    limit.rangeValue = val.target.value as RangeType;
    formViewModel.roleTemplatesInputs.approveLimitsCollection.validate();
    setUiRoleTemplateInputsState(getUpdatedUiRoleTemplateInputsState(formViewModel));
  };

  const onApproveLimitAdd = () => {
    formViewModel.roleTemplatesInputs.approveLimitsCollection.add();
    setUiRoleTemplateInputsState(getUpdatedUiRoleTemplateInputsState(formViewModel));
  };
  // approve limits - end

  // init limits - start
  const onInitLimitDelete = (id: string) => {
    formViewModel.roleTemplatesInputs.initLimitsCollection.remove(id);
    formViewModel.roleTemplatesInputs.initLimitsCollection.validate();
    setUiRoleTemplateInputsState(getUpdatedUiRoleTemplateInputsState(formViewModel));
  };

  const onInitLimitTypeValueChange = (limit: ILimitInput, val: SelectChangeEvent<UserLimitType>) => {
    limit.typeValue = val.target.value as UserLimitType;
    formViewModel.roleTemplatesInputs.initLimitsCollection.validate();
    setUiRoleTemplateInputsState(getUpdatedUiRoleTemplateInputsState(formViewModel));
  };

  const onInitLimitMaxValueChange = (
    limit: ILimitInput,
    val: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    limit.maxValue = val.target.value;
    formViewModel.roleTemplatesInputs.initLimitsCollection.validate();
    setUiRoleTemplateInputsState(getUpdatedUiRoleTemplateInputsState(formViewModel));
  };

  const onInitLimitRangeValueChange = (limit: ILimitInput, val: SelectChangeEvent<RangeType>) => {
    limit.rangeValue = val.target.value as RangeType;
    formViewModel.roleTemplatesInputs.initLimitsCollection.validate();
    setUiRoleTemplateInputsState(getUpdatedUiRoleTemplateInputsState(formViewModel));
  };

  const onInitLimitAdd = () => {
    formViewModel.roleTemplatesInputs.initLimitsCollection.add();
    setUiRoleTemplateInputsState(getUpdatedUiRoleTemplateInputsState(formViewModel));
  };
  // init limits - end
  // roleTemplatesInputs handlers - end

  useEffect(() => {
    if (!isOpen) {
      resetForm({
        values: {
          firstName: formViewModel.firstName,
          lastName: formViewModel.lastName,
          phone: formViewModel.phone,
        },
      });
      validateForm();
      formViewModel.reset();
      // sync ui state with view model
      setUiRoleTemplateInputsState(getUpdatedUiRoleTemplateInputsState(formViewModel));
    }
  }, [isOpen, resetForm, validateForm, formViewModel]);
  const isSaveButtonDisabled =
    !isFormValid ||
    !!uiRoleTemplateInputsState.approveLimitsErrorMessage ||
    !!uiRoleTemplateInputsState.initLimitsErrorMessage ||
    !formViewModel.isStateChanged();

  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <StyledDialogTitle variant="h5">
        {t('editUserDialog.title')}
        <StyledIconButton onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </StyledDialogTitle>
      {(uiRoleTemplateInputsState.approveLimitsErrorMessage || uiRoleTemplateInputsState.initLimitsErrorMessage) && (
        <>
          <Gap size="_24px" />
          <ErrorAlert>
            {uiRoleTemplateInputsState.approveLimitsErrorMessage || uiRoleTemplateInputsState.initLimitsErrorMessage}
          </ErrorAlert>
        </>
      )}
      <StyledDialogContent>
        <form onSubmit={handleSubmit}>
          <Gap size="_24px" />
          <Row>
            <BaseTextField {...firstNameFormikProps} label={t('editUserDialog.firstNameFieldLabel')} />
            <Gap isHorizontal size="_16px" />
            <BaseTextField {...lastNameFormikProps} label={t('editUserDialog.lastNameFieldLabel')} />
          </Row>
          <Gap size="_20px" />
          <BaseTextField
            name={formViewModel.email}
            value={formViewModel.email}
            label={t('editUserDialog.emailFieldLabel')}
            disabled
          />
          <Gap size="_20px" />
          <PhoneField {...phoneFormikProps} label={t('editUserDialog.phoneNumberFieldLabel')} />
          {!formViewModel.isCurrentUserBeingEdited && (
            <>
              <Gap size="_32px" />
              <StyledRadioFormControl>
                <RadioGroupLabel>{t('editUserDialog.roleTemplateFieldLabel')}</RadioGroupLabel>
                <Gap size="_8px" />
                <RadioGroup value={uiRoleTemplateInputsState.roleIndex} onChange={onRoleIndexChange}>
                  {formViewModel.roleTemplatesInputs.roles.map(r => (
                    <RoleRadio key={r.id} data={r} />
                  ))}
                </RadioGroup>
              </StyledRadioFormControl>
              <Gap size="_24px" />
              <StyledCheckboxFormControl>
                <CheckboxGroupLabel>{t('editUserDialog.permissionsFieldLabel')}</CheckboxGroupLabel>
                <Gap size="_8px" />
                <FormGroup>
                  {uiRoleTemplateInputsState.permissions.map(p => (
                    <PermissionsCheckbox
                      key={p.id}
                      data={p}
                      isChecked={formViewModel.roleTemplatesInputs.checkedPermissions.has(p.value)}
                      onChange={onPermissionsCheckboxChange}
                    />
                  ))}
                </FormGroup>
              </StyledCheckboxFormControl>

              {(uiRoleTemplateInputsState.areApproveLimitsShown || uiRoleTemplateInputsState.areInitLimitsShown) && (
                <>
                  <Gap size="_24px" />
                  <Typography variant="h6">{t('editUserDialog.transactionLimitsSectionLabel')}</Typography>
                  <Gap size="_24px" />
                </>
              )}
              {uiRoleTemplateInputsState.areApproveLimitsShown && (
                <>
                  <StyledTypography>{t('editUserDialog.approveLimitSectionLabel')}</StyledTypography>
                  <Gap size="_16px" />
                </>
              )}
              {uiRoleTemplateInputsState.approveLimits.map((l, index, arr) => (
                <Limit
                  key={l.id}
                  id={l.id}
                  onDelete={() => {
                    onApproveLimitDelete(l.id);
                  }}
                  isDeletable={uiRoleTemplateInputsState.approveLimits.length > 1}
                  possibleTypeValues={[UserLimitType.approveAmount, UserLimitType.approveCount]}
                  possibleTypeValueWordings={[
                    t('editUserDialog.amountLimitTypeOption'),
                    t('editUserDialog.countLimitTypeOption'),
                  ]}
                  typeValue={l.typeValue}
                  onTypeValueChange={val => {
                    onApproveLimitTypeValueChange(l, val);
                  }}
                  maxValue={l.maxValue}
                  onMaxValueChange={val => {
                    onApproveLimitMaxValueChange(l, val);
                  }}
                  rangeValue={l.rangeValue}
                  onRangeValueChange={val => {
                    onApproveLimitRangeValueChange(l, val);
                  }}
                  limitInputType={l.limitInputType}
                  isLastLimit={index === arr.length - 1}
                />
              ))}
              {uiRoleTemplateInputsState.areApproveLimitsShown &&
                !formViewModel.roleTemplatesInputs.approveLimitsCollection.isFull() && (
                  <>
                    <Gap size="_32px" />
                    <StyledRow>
                      <StyledLink href="#" onClick={onApproveLimitAdd}>
                        {t('editUserDialog.addApproveLimitSectionButtonLabel')}
                      </StyledLink>
                    </StyledRow>
                  </>
                )}
              {uiRoleTemplateInputsState.areApproveLimitsShown && <Gap size="_32px" />}
              {uiRoleTemplateInputsState.areInitLimitsShown && (
                <>
                  <StyledTypography>{t('editUserDialog.initCountLimitSectionLabel')}</StyledTypography>
                  <Gap size="_16px" />
                </>
              )}
              {uiRoleTemplateInputsState.initLimits.map((l, index, arr) => (
                <Limit
                  key={l.id}
                  id={l.id}
                  onDelete={() => {
                    onInitLimitDelete(l.id);
                  }}
                  isDeletable={uiRoleTemplateInputsState.initLimits.length > 1}
                  possibleTypeValues={[UserLimitType.initAmount, UserLimitType.initCount]}
                  possibleTypeValueWordings={[
                    t('editUserDialog.amountLimitTypeOption'),
                    t('editUserDialog.countLimitTypeOption'),
                  ]}
                  typeValue={l.typeValue}
                  onTypeValueChange={val => {
                    onInitLimitTypeValueChange(l, val);
                  }}
                  maxValue={l.maxValue}
                  onMaxValueChange={val => {
                    onInitLimitMaxValueChange(l, val);
                  }}
                  rangeValue={l.rangeValue}
                  onRangeValueChange={val => {
                    onInitLimitRangeValueChange(l, val);
                  }}
                  limitInputType={l.limitInputType}
                  isLastLimit={index === arr.length - 1}
                />
              ))}
              {uiRoleTemplateInputsState.areInitLimitsShown &&
                !formViewModel.roleTemplatesInputs.initLimitsCollection.isFull() && (
                  <>
                    <Gap size="_32px" />
                    <StyledRow>
                      <StyledLink onClick={onInitLimitAdd}>
                        {t('editUserDialog.addInitCountLimitSectionButtonLabel')}
                      </StyledLink>
                    </StyledRow>
                  </>
                )}
              {uiRoleTemplateInputsState.areInitLimitsShown && <Gap size="_32px" />}
              {uiRoleTemplateInputsState.areApproveLimitsShown && uiRoleTemplateInputsState.areInitLimitsShown && (
                <StyledDivider />
              )}
            </>
          )}
        </form>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" size="large" onClick={onClose}>
          <Typography variant="buttonMedium">{t('editUserDialog.cancelButtonLabel')}</Typography>
        </Button>
        <Gap isHorizontal size="_16px" />
        <LoadingButtonWithSkeleton
          variant="contained"
          size="large"
          text={
            <Typography variant="buttonMedium" color="background.paper">
              {t('editUserDialog.saveButtonLabel')}
            </Typography>
          }
          loading={isLoading}
          disabled={isSaveButtonDisabled}
          onClick={submitForm}
        />
      </StyledDialogActions>
    </StyledDialog>
  );
};
