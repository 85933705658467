import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Typography } from '@mui/material';
import { ButtonContainer, Footer, BeforeIcon, NextIcon } from './styled';

interface PDFSliderProps {
  onPrevClick: () => void;
  onNextClick: () => void;
  currentPage: number;
  pageAmount: number;
}

const PDFSlider: React.FC<PDFSliderProps> = ({ onPrevClick, onNextClick, currentPage, pageAmount }) => {
  const { t } = useTranslation();
  return (
    <Footer>
      <ButtonContainer>
        <IconButton aria-label="before" size="small" onClick={onPrevClick} disabled={currentPage === 1}>
          <BeforeIcon disabled={currentPage === 1} />
        </IconButton>
        <Typography variant="bodyDefaultMedium" color="background.paper">
          {currentPage} {t('documentsList.sliderPagesOf')} {pageAmount}
        </Typography>
        <IconButton aria-label="next" size="small" onClick={onNextClick} disabled={currentPage === pageAmount}>
          <NextIcon disabled={currentPage === pageAmount} />
        </IconButton>
      </ButtonContainer>
    </Footer>
  );
};

export default PDFSlider;
