import React from 'react';
import { Box } from '@mui/material';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { StyledTypography } from './styled';

const Signature: React.FC<{ className?: string }> = ({ className }) => {
  const { isMobile } = useIsMobile();
  return (
    <Box className={className} display="flex" justifyContent="center" alignItems="center">
      <StyledTypography color="secondary.font" isMobile={isMobile}>
        Powered by&nbsp;
        <strong>Fortress</strong>
        &nbsp;Trust
      </StyledTypography>
    </Box>
  );
};

export { Signature };
