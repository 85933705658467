import React from 'react';
import { AddUserButton } from './AddUserButton';
import { StyledContainer, StyledHeaderLabel, StyledMainRow } from './styled';

type Props = {
  headerName: string;
  onCreateUserClick: () => void;
  isAddUserButtonDisplayed: boolean;
};

export const CustomHeader: React.FC<Props> = ({ headerName, onCreateUserClick, isAddUserButtonDisplayed }) => {
  return (
    <StyledContainer>
      <StyledMainRow>
        <StyledHeaderLabel>{headerName}</StyledHeaderLabel>
        {isAddUserButtonDisplayed && <AddUserButton onClick={onCreateUserClick} />}
      </StyledMainRow>
    </StyledContainer>
  );
};
