import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Column } from 'components/shared/Flex/Column';

export const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey['500'],
}));

export const StyledSubitleTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.bodyDefaultBook,
  color: theme.palette.grey['600'],
}));

export const StyledRootColumn = styled(Column)(() => ({
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledContentColumn = styled(Column)(() => ({
  alignItems: 'center',
}));
