import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import { Balance } from 'ui-interfaces/custodial-accounts-details/custodial-account-balance';
import { AssetByNetworkIconSize } from 'ui-enums/asset-by-network-icon-size';
import { LabelLength } from 'ui-enums/label-length';
import { IAccountBalanceNameCell } from 'ui-interfaces/custodial-accounts-details/i-account-balance-name-cell';
import { getDynamicAssetLabel } from 'utils/labels-mapping/get-dynamic-asset-label';
import { getStaticDefaultIcon } from 'utils/icons-mapping/get-static-default-icon';
import { mapAssetByNetworkIconSizeToNumber } from 'utils/multi-mapping/asset-by-network-icon-size/map-asset-by-network-icon-size-to-number';
import { getUniqueIdentifierForCustodialAccountBalanceModel } from 'utils/account-balances/get-unique-identifier-for-custodial-account-balance-model';

export class AccountBalanceCryptoCurrencyViewModel implements Balance {
  readonly id: string;

  readonly accountBalanceNameCellProps: IAccountBalanceNameCell;

  readonly availableBalance: string;

  readonly lockedBalance: string;

  readonly pendingBalance: string;

  readonly totalBalance: string;

  protected readonly assetIconSize = AssetByNetworkIconSize['36px'];

  constructor(balance: CustodialAccountBalanceModel) {
    this.id = getUniqueIdentifierForCustodialAccountBalanceModel(balance);
    this.accountBalanceNameCellProps = {
      asset: {
        imageUrl: balance.assetIconXs,
        shortName: getDynamicAssetLabel(LabelLength.short, balance.assetTicker, balance.assetName),
        longName: getDynamicAssetLabel(LabelLength.long, balance.assetTicker, balance.assetName),
        defaultAssetIcon: getStaticDefaultIcon(balance.assetTicker, this.assetIconSize),
      },
      size: mapAssetByNetworkIconSizeToNumber(this.assetIconSize),
    };
    this.availableBalance = `${balance.disbursable} ${getDynamicAssetLabel(
      LabelLength.short,
      balance.assetTicker,
      balance.assetName,
    )}`;
    this.lockedBalance =
      +balance.locked === 0
        ? '--'
        : `${balance.locked} ${getDynamicAssetLabel(LabelLength.short, balance.assetTicker, balance.assetName)}`;
    this.pendingBalance =
      +balance.pending === 0
        ? '--'
        : `${balance.pending} ${getDynamicAssetLabel(LabelLength.short, balance.assetTicker, balance.assetName)}`;
    this.totalBalance = `${balance.total} ${getDynamicAssetLabel(
      LabelLength.short,
      balance.assetTicker,
      balance.assetName,
    )}`;
  }
}
