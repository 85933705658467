export enum Currency {
  usd = 'usd',
  btc = 'btc',
  eth = 'eth',
  matic = 'matic',
  usdc = 'usdc',
  sol = 'sol',
  privateSecurity = 'privateSecurity',
  ada = 'ada',
  xlm = 'xlm',
  dot = 'dot',
  link = 'link',
  algo = 'algo',
  bnb = 'bnb',
  busd = 'busd',
  avax = 'avax',
  ltc = 'ltc',
  uni = 'uni',
  shib = 'shib',
  doge = 'doge',
  crv = 'crv',
  aave = 'aave',
  xtz = 'xtz',
  comp = 'comp',
  bch = 'bch',
  eos = 'eos',
  grt = 'grt',
  bat = 'bat',
  atom = 'atom',
  sushi = 'sushi',
  sand = 'sand',
  enj = 'enj',
  axs = 'axs',
  mana = 'mana',
  yfi = 'yfi',
  mkr = 'mkr',
  ape = 'ape',
  dai = 'dai',
  usdt = 'usdt',
  near = 'near',
  xrp = 'xrp',
  flrns = 'flrns',
  ftm = 'ftm',
  flow = 'flow',
  tron = 'tron',
  gala = 'gala',
  chz = 'chz',
  trx = 'trx',
  smtf = 'smtf',
  qnt = 'qnt',
  hbar = 'hbar',
  gold = 'gold',
  silver = 'silver',
}
