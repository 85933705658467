import React, { ForwardedRef, forwardRef } from 'react';
import { StyledButton } from './styled';

type Props = {
  className?: string;
};

const TransparentButton: React.FC<Props> = forwardRef(
  ({ children, className, ...restProps }, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <StyledButton {...restProps} variant="outlined" className={className} ref={ref}>
        {children}
      </StyledButton>
    );
  },
);

export default TransparentButton;
