import { useContext } from 'react';
import { ElementContext, ElementProvider } from './ElementProvider';

function useElement() {
  const context = useContext(ElementContext);

  if (context === undefined) {
    throw new Error(`${useElement.name} must be used within a ${ElementProvider.name}`);
  }

  return context;
}

export default useElement;
