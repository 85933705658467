import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RemoveButton } from 'components/settings/users/UserDetailsPageContent/layout/Actions/buttons/RemoveButton';
import { useDeleteUserByIdUserDetailsMutation, UserByIdApiParams } from 'redux/api/api-users';
import { DestroyActionModal } from 'components/shared/modals/DestroyActionModal';
import { PATHS } from 'navigation/constants';

type Props = {
  userId: string;
  destroyModalMainText: string;
};

export const RemoveButtonContainer: React.FC<Props> = ({ userId, destroyModalMainText }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isDestroyDialogOpen, setIsDestroyDialogOpen] = useState(false);

  const [deleteUserByIdTrigger, deleteUserByIdResult] = useDeleteUserByIdUserDetailsMutation();

  const removeUser = useCallback(
    (id: string) => {
      deleteUserByIdTrigger({ userId: id } as UserByIdApiParams);
    },
    [deleteUserByIdTrigger],
  );

  const onClickRemoveUser = useCallback(() => {
    removeUser(userId);
  }, [removeUser, userId]);

  useEffect(() => {
    if (deleteUserByIdResult.isSuccess) {
      navigate(PATHS.SETTINGS);
    }
  }, [deleteUserByIdResult.isSuccess, navigate]);

  const onClick = () => {
    setIsDestroyDialogOpen(true);
  };

  const onClose = () => {
    setIsDestroyDialogOpen(false);
  };

  return (
    <>
      <RemoveButton isLoading={false} onClick={onClick} />
      <DestroyActionModal
        isOpen={isDestroyDialogOpen}
        onClose={onClose}
        onDestroyButtonClick={onClickRemoveUser}
        title={t('actionDialog.title')}
        captionText={t('actionDialog.users.destroyCaptionText')}
        mainText={destroyModalMainText}
        destroyButtonTitle={t('actionDialog.users.destroyButtonTitle')}
        destroyButtonLoading={deleteUserByIdResult.isLoading}
      />
    </>
  );
};
