import React from 'react';
import { StyledAlert, StyledErrorIcon } from './styled';

type Props = {
  className?: string;
};

const ErrorAlert: React.FC<Props> = ({ children, className }) => {
  return (
    <StyledAlert severity="error" className={className} icon={<StyledErrorIcon />}>
      {children}
    </StyledAlert>
  );
};

export default ErrorAlert;
