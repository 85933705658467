import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderDetailsItem } from 'components/shared/HeaderDetailsItem';
import { HeaderDetailsLinkItem } from 'components/shared/HeaderDetailsLinkItem';
import { CustodialAccountsDetailsHeader } from 'ui-interfaces/custodial-accounts-details/custodial-accounts-details-header';
// icons - start
import { ReactComponent as AccountIdIcon } from 'assets/icons/custodial-accounts/account-id.svg';
import { ReactComponent as AccountNumberIcon } from 'assets/icons/custodial-accounts/account-number.svg';
import { ReactComponent as OwnerIcon } from 'assets/icons/custodial-accounts/owner.svg';
import { ReactComponent as DateIcon } from 'assets/icons/identity-details/date.svg';
import { ReactComponent as LastActivityIcon } from 'assets/icons/custodial-accounts/last-activity-icon.svg';
import { ReactComponent as AccountTypeIcon } from 'assets/icons/custodial-accounts/account-type.svg';
// icons - end
import { StyledBox } from './styled';

interface Props {
  headerProps: CustodialAccountsDetailsHeader;
}

export const HeaderItemsGrid: React.FC<Props> = ({ headerProps }) => {
  const { t } = useTranslation();
  return (
    <StyledBox>
      <HeaderDetailsItem
        value={headerProps.custodialAccountId}
        label={t('custodialAccountsPage.accountId')}
        icon={<AccountIdIcon />}
        large
      />

      <HeaderDetailsItem
        value={headerProps.custodialAccountNumber}
        label={t('custodialAccountsPage.accountNumber')}
        icon={<AccountNumberIcon />}
      />

      <HeaderDetailsLinkItem
        url={headerProps.ownerDetailsUrl}
        urlDisplayText={headerProps.owner}
        label={t('custodialAccountsPage.owner')}
        icon={<OwnerIcon />}
      />

      <HeaderDetailsItem
        value={headerProps.dateOpened}
        label={t('custodialAccountsPage.dateOpened')}
        icon={<DateIcon />}
      />

      <HeaderDetailsItem
        value={headerProps.modifiedAt}
        label={t('custodialAccountDetailsPage.detailsLabels.modifiedAt')}
        tooltip={t('custodialAccountDetailsPage.detailsLabels.modifiedAtTooltip')}
        icon={<LastActivityIcon />}
      />

      <HeaderDetailsItem
        value={headerProps.accountTypeLabel}
        label={t('custodialAccountsPage.accountType')}
        icon={<AccountTypeIcon />}
      />
    </StyledBox>
  );
};
