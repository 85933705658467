import React from 'react';
import { RightCell } from 'components/shared/detail-page/rows/shared/RightCell';
import { RowBox } from 'components/shared/detail-page/rows/shared/RowBox';
import { ICanApproveRow } from 'ui-interfaces/user-details/rows/i-can-approve-row';
import { LeftCell } from './LeftCell';

type Props = {
  data: ICanApproveRow;
  hasBottomBorder: boolean;
};

export const CanApproveRow: React.FC<Props> = ({ data, hasBottomBorder }) => {
  return (
    <RowBox hasBottomBorder={hasBottomBorder}>
      <LeftCell value={data.label} tooltipMessage={data.tooltipMessage} />
      <RightCell value={data.value} />
    </RowBox>
  );
};
