import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoHeightGrid } from 'components/shared/grids/AutoHeightGrid';
import { CommonAutoHeightGridBox } from 'components/shared/layouts/CommonLayout';
import { CustodialAccountCustomAssetBalanceRow } from 'ui-interfaces/row-interfaces/custodial-accounts/custodial-account-custom-asset-balance-row';
import { useLazyGetCustodialAccountCustomAssetBalanceByIdQuery } from 'redux/api/api-custodial-accounts';
import { CustodialAccountCustomAssetBalanceListFiltersViewModel } from 'view-models/custodial-accounts/filters/custom-asset-balance/custodial-account-custom-asset-balance-list-filters-vm';
import { Filterable } from 'ui-interfaces/shared/filters/filterable';
import { FilterResult } from 'filters/interfaces/filter-result';
import { useExport } from 'hooks/use-export';
import {
  onCustodialAccountCustomAssetBalanceExportClick,
  onCustodialAccountCustomAssetBalanceTransform,
} from 'handlers/tapi/custodial-accounts/handlers';
import { columnsCustodialAccountCustomAssetBalance } from './columns-definition';

type Props = {
  custodialAccountId: string;
  setIsDetailsPageError: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CustomAssetBalanceGrid: React.FC<Props> = ({ custodialAccountId, setIsDetailsPageError }) => {
  const { t } = useTranslation();
  const { downloadCsv, isLoading, error } = useExport();

  // request boilerplate - start
  const [getCustodialAccountCustomAssetBalanceTrigger, custodialAccountCustomAssetBalanceResult] =
    useLazyGetCustodialAccountCustomAssetBalanceByIdQuery();

  const fetchCustodialAccountCustomAssetBalance = useCallback(
    (page?: number, filterResult?: FilterResult) =>
      getCustodialAccountCustomAssetBalanceTrigger({ custodialAccountId, page, filterResult }, false),
    [getCustodialAccountCustomAssetBalanceTrigger, custodialAccountId],
  );

  const [rows, setRows] = useState<CustodialAccountCustomAssetBalanceRow[]>([]);
  const [resourceCount, setResourceCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(custodialAccountCustomAssetBalanceResult.isUninitialized);
  const [filterable] = useState<Filterable>(() => new CustodialAccountCustomAssetBalanceListFiltersViewModel());

  useEffect(() => {
    fetchCustodialAccountCustomAssetBalance();
  }, [fetchCustodialAccountCustomAssetBalance]);
  // request boilerplate - end

  useEffect(() => {
    if (custodialAccountCustomAssetBalanceResult.isSuccess && custodialAccountCustomAssetBalanceResult.data) {
      const transformedItems = custodialAccountCustomAssetBalanceResult.data.data.map(
        onCustodialAccountCustomAssetBalanceTransform,
      );
      setRows(transformedItems);
      setResourceCount(custodialAccountCustomAssetBalanceResult.data.meta?.resourceCount ?? 0);
      setLoading(custodialAccountCustomAssetBalanceResult.isFetching);
    }
  }, [
    custodialAccountCustomAssetBalanceResult.data,
    custodialAccountCustomAssetBalanceResult.isFetching,
    custodialAccountCustomAssetBalanceResult.isSuccess,
  ]);

  // handle all page error - start
  useEffect(() => {
    setIsDetailsPageError(Boolean(custodialAccountCustomAssetBalanceResult.error || error));
  }, [error, custodialAccountCustomAssetBalanceResult.error, setIsDetailsPageError]);
  // handle all page error - end

  return (
    <CommonAutoHeightGridBox>
      <AutoHeightGrid
        headerName={t('custodialAccountDetailsPage.customAssetBalance.title')}
        columns={columnsCustodialAccountCustomAssetBalance}
        filters={filterable.filters}
        selectionMacroCommand={filterable.selectionMacroCommand}
        noResultsTitle={t('custodialAccountDetailsPage.customAssetBalance.noResultsTitle')}
        noResultsSubtitle={t('custodialAccountDetailsPage.customAssetBalance.noResultsSubtitle')}
        containerHeightWhenNonAHMode="515px"
        rows={rows}
        rowCount={resourceCount}
        isLoading={loading}
        error={custodialAccountCustomAssetBalanceResult.error || error}
        fetchOtherItems={fetchCustodialAccountCustomAssetBalance}
        onExportClick={onCustodialAccountCustomAssetBalanceExportClick(downloadCsv, custodialAccountId)}
        isExportLoading={isLoading}
      />
    </CommonAutoHeightGridBox>
  );
};
