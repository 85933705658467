import { GridRowParams } from '@mui/x-data-grid';
import { NavigateFunction } from 'react-router-dom';
import { CustodialAccountResponseModel } from 'models/response/custodial-accounts/custodial-account-response-model';
import { CustodialAccountViewModel } from 'view-models/custodial-accounts/custodial-account-vm';
import { CustodialAccountsDetailsUrlParams } from 'navigation/url-params-type';
import { getRouteWithParams } from 'utils/get-route-with-params';
import { PATHS } from 'navigation/constants';

export const onCustodialAccountRowClick = (navigate: NavigateFunction) => (params: GridRowParams) => {
  const { row } = params;
  navigate(
    getRouteWithParams(PATHS.TAPI.CUSTODIAL_ACCOUNTS_DETAILS, {
      custodialAccountId: row.id,
    } as CustodialAccountsDetailsUrlParams),
  );
};

export const onCustodialAccountTransform = (custodialAccount: CustodialAccountResponseModel) => {
  return new CustodialAccountViewModel(custodialAccount);
};
