import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as CloseIcon } from 'assets/icons/grey-close-icon.svg';
import { Gap } from 'components/shared/Gap';
import { Column } from 'components/shared/Flex/Column';
import { useYupRules } from 'hooks/use-yup-rules';
import { TextArea } from 'components/shared/TextArea';
import { useVerifyBusinessIdentityMutation } from 'redux/api/api-business-identities';
import { useVerifyPersonalIdentityMutation } from 'redux/api/api-personal-identities';
import { VerifyIdentityApiParams } from 'redux/api/types';
import { IdentityVerificationDecision } from 'models/enums/identities/identity-verification-decision';
import { IdentityDetailsUrlParams } from 'navigation/url-params-type';
import { IdentityType } from 'models/enums/identities/identity-type';
import {
  StyledButton,
  StyledCenteredTextTypography,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledForm,
  StyledIconButton,
  StyledLoadingButtonWithSkeleton,
  StyledTypographyCaption,
} from './styled';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isSuspendMode: boolean;
  name: string;
  urlParams: Readonly<Partial<IdentityDetailsUrlParams>>;
};

export const SuspensionIdentityModal: React.FC<Props> = ({ isOpen, onClose, isSuspendMode, name, urlParams }) => {
  const { t } = useTranslation();
  const { suspensionReasonRule, unSuspensionReasonRule } = useYupRules();

  const [verifyBusinessIdentityTrigger, verifyBusinessIdentityResult] = useVerifyBusinessIdentityMutation();

  const [verifyPersonalIdentityTrigger, verifyPersonalIdentityResult] = useVerifyPersonalIdentityMutation();

  const isBusinessIdentity = urlParams.identityType === IdentityType.business;

  const isLoading = isBusinessIdentity
    ? verifyBusinessIdentityResult.isLoading
    : verifyPersonalIdentityResult.isLoading;

  const triggerFunction = isBusinessIdentity ? verifyBusinessIdentityTrigger : verifyPersonalIdentityTrigger;

  const onSubmit = (suspensionReason: string) => {
    triggerFunction({
      identityId: urlParams.identityId,
      identityVerificationModel: {
        decision: isSuspendMode ? IdentityVerificationDecision.suspend : IdentityVerificationDecision.approve,
        comment: suspensionReason,
      },
    } as VerifyIdentityApiParams);
  };

  const { handleChange, handleBlur, handleSubmit, values, errors, touched, submitForm, validateForm, resetForm } =
    useFormik({
      initialValues: {
        suspensionReason: '',
      },
      validateOnChange: true,
      validationSchema: Yup.object({
        suspensionReason: isSuspendMode ? suspensionReasonRule : unSuspensionReasonRule,
      }),
      onSubmit: (onSubmitValues, { setSubmitting }) => {
        onSubmit(onSubmitValues.suspensionReason);
        setSubmitting(false);
      },
    });

  useEffect(() => {
    if (!isOpen) {
      resetForm({
        values: {
          suspensionReason: '',
        },
      });
      validateForm();
    }
  }, [isOpen, resetForm, validateForm]);

  useEffect(() => {
    if (
      verifyBusinessIdentityResult.isSuccess ||
      verifyPersonalIdentityResult.isSuccess ||
      verifyBusinessIdentityResult.isError ||
      verifyPersonalIdentityResult.isError
    ) {
      onClose();
    }
  }, [
    verifyBusinessIdentityResult.isSuccess,
    verifyPersonalIdentityResult.isSuccess,
    onClose,
    verifyBusinessIdentityResult.isError,
    verifyPersonalIdentityResult.isError,
  ]);

  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <StyledDialogTitle>
        <Column>
          <Gap size="_24px" />
          {isSuspendMode && (
            <Typography variant="h4">{t('identityDetailsPage.suspendIdentityModal.suspendIdentity')}</Typography>
          )}
          {!isSuspendMode && (
            <Typography variant="h4">{t('identityDetailsPage.suspendIdentityModal.unSuspendIdentity')}</Typography>
          )}
        </Column>
        <StyledIconButton onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Column>
          <Gap size="_12px" />
          {isSuspendMode && (
            <StyledTypographyCaption variant="bodyDefaultBook" color="text.secondary">
              {t('identityDetailsPage.suspendIdentityModal.suspendCaption')}
            </StyledTypographyCaption>
          )}
          {!isSuspendMode && (
            <StyledTypographyCaption variant="bodyDefaultBook" color="text.secondary">
              {t('identityDetailsPage.suspendIdentityModal.unSuspendCaption')}
            </StyledTypographyCaption>
          )}
          <Gap size="_24px" />
          <StyledCenteredTextTypography variant="h5">{name}</StyledCenteredTextTypography>
          <StyledCenteredTextTypography variant="buttonMedium" color="text.secondary">
            {t('identityDetailsPage.suspendIdentityModal.identityId')}
          </StyledCenteredTextTypography>
          <StyledCenteredTextTypography variant="bodyDefaultBook" color="text.secondary">
            {urlParams.identityId}
          </StyledCenteredTextTypography>
          <Gap size="_20px" />
          {isSuspendMode && (
            <Typography variant="buttonMedium">
              {t('identityDetailsPage.suspendIdentityModal.suspensionReason')}
            </Typography>
          )}
          {!isSuspendMode && (
            <Typography variant="buttonMedium">
              {t('identityDetailsPage.suspendIdentityModal.unSuspensionReason')}
            </Typography>
          )}
          <Gap size="_8px" />
          <StyledForm onSubmit={handleSubmit}>
            <TextArea
              name="suspensionReason"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.suspensionReason}
              placeholder={
                isSuspendMode
                  ? t('identityDetailsPage.suspendIdentityModal.suspendTextAreaPlaceholder')
                  : t('identityDetailsPage.suspendIdentityModal.unSuspendTextAreaPlaceholder')
              }
              error={!!errors.suspensionReason && touched.suspensionReason}
              errorText={errors.suspensionReason}
              height="100px"
              width="374px"
            />
          </StyledForm>
        </Column>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton variant="outlined" size="large" onClick={onClose}>
          <Typography variant="buttonMedium">{t('identityDetailsPage.suspendIdentityModal.cancel')}</Typography>
        </StyledButton>
        <Gap isHorizontal size="_4px" />
        {isSuspendMode && (
          <StyledLoadingButtonWithSkeleton
            variant="contained"
            size="large"
            text={
              <Typography variant="buttonMedium" color="background.paper">
                {t('identityDetailsPage.suspendIdentityModal.suspend')}
              </Typography>
            }
            color="error"
            loading={isLoading}
            onClick={submitForm}
          />
        )}
        {!isSuspendMode && (
          <StyledLoadingButtonWithSkeleton
            variant="contained"
            size="large"
            text={
              <Typography variant="buttonMedium" color="background.paper">
                {t('identityDetailsPage.suspendIdentityModal.unSuspend')}
              </Typography>
            }
            loading={isLoading}
            onClick={submitForm}
          />
        )}
      </StyledDialogActions>
    </StyledDialog>
  );
};
