import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SerializedStyles } from '@emotion/serialize';
import { Button, CircularProgress } from '@mui/material';
import { alpha, css } from '@mui/material/styles';
import { preventForwardProps } from 'elements/utils';
import { elementsStyled, ElementsCustomTheme } from 'elements/theme/createCustomTheme';
import { ButtonProps } from './types';

// TODO revise the button types
//       @media (hover: hover) {} is used to apply hover styles on devices that have primary input mechanism that can hover over elements (desktops, laptops)
//       @media (hover: none) {} is used to override hover styles on touch devices (mobiles, IPads) where sticky hover effect appears at button touch
const applyButtonColorStyle = (props: ButtonProps, theme: ElementsCustomTheme): SerializedStyles => {
  const colorsMap = {
    primary: css`
      background: ${theme.palette.primary.main};
      color: ${theme.palette.primary.buttonFont};

      @media (hover: hover) {
        &:hover {
          background: ${theme.palette.primary.mainDarken25};
          box-shadow: unset;
          cursor: pointer;
        }
      }

      @media (hover: none) {
        &:hover {
          background: ${theme.palette.primary.main};
          color: ${theme.palette.primary.buttonFont};
        }
      }

      &.Mui-disabled {
        color: ${alpha(theme.palette.primary.buttonFont || '', 0.5)};
        background: ${theme.palette.primary.mainTransparent50};
      }
    `,
    secondary: css`
      background: transparent;
      color: ${theme.palette.primary.main};
      border: 2px solid ${theme.palette.primary.main};

      @media (hover: hover) {
        &:hover {
          color: ${theme.palette.primary.mainDarken25};
          border-color: ${theme.palette.primary.mainDarken25};
          background: transparent;
          box-shadow: unset;
          cursor: pointer;
        }
      }

      @media (hover: none) {
        &:hover {
          background: transparent;
          color: ${theme.palette.primary.main};
          border: 2px solid ${theme.palette.primary.main};
        }
      }

      &.Mui-disabled {
        background: transparent;
        color: ${theme.palette.primary.mainTransparent50};
        border-color: ${theme.palette.primary.mainTransparent50};
      }
    `,
    tertiary: css`
      background: ${theme.palette.tertiary.main};
      color: ${theme.palette.primary.font};

      @media (hover: hover) {
        &:hover {
          background: linear-gradient(
              0deg,
              ${alpha(theme.palette.primary.main, 0.05)},
              ${alpha(theme.palette.primary.main, 0.05)}
            ),
            ${theme.palette.tertiary.main};
        }
      }

      @media (hover: none) {
        &:hover {
          background: ${theme.palette.tertiary.main};
          color: ${theme.palette.primary.font};
        }
      }

      &.Mui-disabled {
        background: ${theme.palette.tertiary.main};
        color: ${alpha(theme.palette.primary.font || '', 0.5)};
      }
    `,
  };
  return colorsMap[props.buttonType || 'primary'];
};

export const StyledButton = elementsStyled<React.FC<ButtonProps>>(
  Button,
  preventForwardProps(['buttonType']),
)(
  ({ theme, ...props }) => css`
    display: flex;
    gap: 16px;
    width: 100%;
    box-shadow: unset;
    height: 52px;
    border-radius: ${theme.borderRadius};
    ${applyButtonColorStyle(props, theme)}
  `,
);

export const StyledCircularProgress = elementsStyled(CircularProgress)`
  color: inherit;
`;
