import React from 'react';
import { alpha, styled } from '@mui/material';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Page } from 'react-pdf';
import { pxToRem } from 'utils/styling-utils';

type StyledIconProps = { disabled: boolean };

export const StyledPage = styled(Page)(({ theme }) => ({
  opacity: 1,
  position: 'relative',
  zIndex: theme.zIndex.drawer + 5,
}));

export const Footer = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  position: 'fixed',
  zIndex: theme.zIndex.drawer + 3,
  left: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  paddingBottom: pxToRem(20),
}));

export const ButtonContainer = styled('div')(({ theme }) => ({
  width: pxToRem(theme.gaps._8px * 19),
  padding: pxToRem(theme.gaps._8px),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: alpha(theme.palette.grey[700], 0.8),
  borderRadius: pxToRem(8),
}));

export const BeforeIcon = styled<React.FC<StyledIconProps>>(NavigateBefore)(({ theme, disabled }) => ({
  color: disabled ? theme.palette.grey[500] : theme.palette.background.paper,
}));

export const NextIcon = styled<React.FC<StyledIconProps>>(NavigateNext)(({ theme, disabled }) => ({
  color: disabled ? theme.palette.grey[500] : theme.palette.background.paper,
}));
