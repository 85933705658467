import { CustodialAccountStatus } from 'models/enums/custodial-accounts/custodial-account-status';

export const calculateIsSuspensionActionShown = (
  status: CustodialAccountStatus,
  isSuspendedByIntegrator: boolean,
  isOwnerSuspended: boolean,
): boolean => {
  if (isOwnerSuspended) {
    return false;
  }

  if (status === CustodialAccountStatus.closed) {
    return false;
  }

  return !(status === CustodialAccountStatus.suspended && !isSuspendedByIntegrator);
};
