import dayjs from 'dayjs';
import { TransactionsFilterProperty } from 'ui-enums/transactions/transactions-filter-property';
import { Operators } from 'filters/operators';
import { FilterResult } from '../../filters/interfaces/filter-result';
import { DEFAULT_CREATED_AT_LAST_DAYS_FILTER } from './constants';

export const defaultTransactionFilterResult = (): FilterResult | undefined => {
  if (DEFAULT_CREATED_AT_LAST_DAYS_FILTER) {
    return {
      property: TransactionsFilterProperty.externalCreatedAt,
      operator: Operators.greaterThan,
      value: dayjs().subtract(DEFAULT_CREATED_AT_LAST_DAYS_FILTER, 'day').format(),
    };
  }

  return undefined;
};
