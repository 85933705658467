import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import i18n from 'localizations';
import { TokenRow } from 'ui-interfaces/row-interfaces/wallets/token-row';
import { NameCell } from './NameCell';
import { ContractAddressCell } from './ContractAddressCell';
import { IdCell } from './IdCell';
import { FileTypeCell } from './FileTypeCell';

export const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: i18n.t('walletDetailsPage.tokens.cols.name'),
    flex: 1.5,
    renderCell: (params: GridRenderCellParams) => {
      const { imageUrl, tokenName, fileType } = params.row as TokenRow;
      return <NameCell imageUrl={imageUrl} title={tokenName} fileType={fileType} width={params.colDef.computedWidth} />;
    },
  },
  {
    field: 'formattedContractAddress',
    headerName: i18n.t('walletDetailsPage.tokens.cols.contractAddress'),
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const { formattedContractAddress, fullContractAddress, contractAddressUrl } = params.row as TokenRow;
      return (
        <ContractAddressCell
          formattedAddress={formattedContractAddress}
          fullAddress={fullContractAddress}
          addressUrl={contractAddressUrl}
        />
      );
    },
  },
  {
    field: 'formattedTokenId',
    headerName: i18n.t('walletDetailsPage.tokens.cols.tokenId'),
    flex: 1.3,
    renderCell: (params: GridRenderCellParams) => {
      const { blockchainTokenId } = params.row as TokenRow;
      return <IdCell id={blockchainTokenId} />;
    },
  },
  {
    field: 'blockchainLabel',
    headerName: i18n.t('walletDetailsPage.tokens.cols.blockchain'),
    flex: 0.8,
  },
  {
    field: 'tokenStandard',
    headerName: i18n.t('walletDetailsPage.tokens.cols.tokenStandard'),
    flex: 0.8,
  },
  {
    field: 'fileType',
    headerName: i18n.t('walletDetailsPage.tokens.cols.fileType'),
    flex: 0.6,
    renderCell: (params: GridRenderCellParams) => {
      const { fileType } = params.row as TokenRow;
      return <FileTypeCell fileType={fileType} />;
    },
  },
  {
    field: 'amount',
    headerName: i18n.t('walletDetailsPage.tokens.cols.amount'),
    flex: 0.5,
  },
];
