import React from 'react';
import { Typography } from '@mui/material';
import { StyledBox, CodeBox, LabelBox, StyledLi, StyledUl } from './styled';

type Props = {
  label: string;
};

export const LeftCell: React.FC<Props> = ({ label }) => {
  return (
    <StyledBox>
      <CodeBox />
      <LabelBox>
        <StyledUl>
          <StyledLi>
            <Typography variant="bodyDefaultMedium">{label}</Typography>
          </StyledLi>
        </StyledUl>
      </LabelBox>
    </StyledBox>
  );
};
