import React from 'react';
import { ReactComponent as EthereumIcon } from 'assets/icons/crypto-asset-by-network-chain/ethereum-icon.svg';
import { ReactComponent as PolygonIcon } from 'assets/icons/crypto-asset-by-network-chain/polygon-icon.svg';
import { ReactComponent as SolanaIcon } from 'assets/icons/crypto-asset-by-network-chain/solana-icon.svg';
import { NetworkChain } from 'ui-enums/response/network-chain';

export const getBlockchainAssetIcon = (network: NetworkChain): React.ReactNode => {
  switch (network.toLowerCase()) {
    case NetworkChain.mainnet.toLowerCase():
      return <EthereumIcon />;
    case NetworkChain.polygonMainnet.toLowerCase():
    case NetworkChain.polygonMumbai.toLowerCase():
      return <PolygonIcon />;
    case NetworkChain.solanaDevnet.toLowerCase():
    case NetworkChain.solanaTestnet.toLowerCase():
    case NetworkChain.solanaMainnet.toLowerCase():
      return <SolanaIcon />;

    default:
      return <EthereumIcon />;
  }
};
