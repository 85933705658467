import i18n from 'localizations';
import { formatDate } from 'utils/format-date';
import { WalletsDetailsHeader } from 'ui-interfaces/wallets/wallet-details/wallets-details-header';
import { WalletDetailsResponseModel } from 'models/response/wallets/wallet-details-response-model';

export class WalletDetailsHeaderViewModel implements WalletsDetailsHeader {
  readonly walletId: string = '';

  readonly owner: string = '';

  readonly ownerTooltipMessage: string = '';

  readonly dateCreated: string = '';

  readonly title: string = '';

  constructor(response?: WalletDetailsResponseModel) {
    if (response) {
      this.walletId = response.id;
      this.owner = response.ownerIdentityDisplayName ?? i18n.t('walletDetailsPage.noData');
      this.ownerTooltipMessage = response.ownerIdentityDisplayName
        ? ''
        : i18n.t('walletDetailsPage.noOwnerTooltipMessage');
      this.dateCreated = formatDate(response.createdAt);
      this.title = `${response.name || ''}`;
    }
  }
}
