import React from 'react';
import { Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Gap } from 'components/shared/Gap';
import { StyledAccordion, StyledAccordionSummary } from './styled';

interface IExpendSection {
  sectionTitle: string;
}

export const ExpendSection: React.FC<IExpendSection> = ({ children, sectionTitle }) => {
  return (
    <StyledAccordion disableGutters>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="h5">{sectionTitle}</Typography>
      </StyledAccordionSummary>
      <Gap size="_8px" />
      {children}
    </StyledAccordion>
  );
};
