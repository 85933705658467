import { GridRowParams } from '@mui/x-data-grid';
import { NavigateFunction } from 'react-router-dom';
import { getRouteWithParams } from 'utils/get-route-with-params';
import { PATHS } from 'navigation/constants';
import { PersonalIdentityResponseModel } from 'models/response/identities/personal/personal-identity-response-model';
import { PersonalIdentityRow } from 'ui-interfaces/row-interfaces/identities/personal-identity-row';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { IdentityType } from 'models/enums/identities/identity-type';
import { IdentityDetailsUrlParams } from 'navigation/url-params-type';
import { FilterResult } from 'filters/interfaces/filter-result';
import { DownloadCSV } from 'hooks/use-export/interfaces';
import { API_ROUTES } from 'redux/api/api-routes';
import { createPersonalIdentityViewModel } from 'factories/identities/create-personal-identity-view-model';

export const onPersonalIdentitiesTransform =
  (user: ShortUserResponseModel) =>
  (personalIdentity: PersonalIdentityResponseModel): PersonalIdentityRow =>
    createPersonalIdentityViewModel(personalIdentity, user);

export const onPersonalIdentityRowClick =
  (navigate: NavigateFunction) =>
  (params: GridRowParams<PersonalIdentityRow>): void => {
    const { row } = params;

    navigate(
      getRouteWithParams(PATHS.IDENTITY_DETAILS, {
        identityId: row.id,
        identityType: IdentityType.personal,
      } as IdentityDetailsUrlParams),
    );
  };

export const onPersonalIdentitiesExportClick = (downloadCsv: DownloadCSV) => (filterResult?: FilterResult) => {
  downloadCsv({ urlSegment: API_ROUTES.private.PERSONAL_IDENTITIES, filterResult });
};
