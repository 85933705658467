import React, { useEffect, useRef } from 'react';
import { StyledTextField } from '../styled';

type Props = {
  label: string;
  value: string;
  onChange: (value: string) => void;
};

export const TextComponent: React.FC<Props> = ({ label, value, onChange }) => {
  // Workaround - start
  // Workaround for using 'autofocus' prop on 'TextField'
  // which makes page scroll up on safari
  // https://github.com/mui/material-ui/issues/16740
  const inputRef = useRef<HTMLInputElement | undefined>();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  // Workaround - end

  return (
    <StyledTextField
      inputRef={inputRef}
      label={label}
      value={value}
      variant="outlined"
      onChange={event => {
        onChange(event.target.value);
      }}
    />
  );
};
