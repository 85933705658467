import { addCommas, trimZerosRight } from './stringUtils';

function formatStringAsCryptoBalance(balance: string, withCommas: boolean, decimals: number) {
  const splitBalance = balance.split('.');

  const beforeDot = withCommas ? addCommas(splitBalance[0]) : splitBalance[0];

  const afterDot = trimZerosRight((splitBalance[1] ?? '').substring(0, decimals));

  return Number(afterDot) === 0 ? `${beforeDot}` : `${beforeDot}.${afterDot}`;
}

function formatPartsWithFractions(parts: string[], numberOfDecimals: number): string[] {
  /**
   * there are always 2 fraction related parts: '.' and fraction 'XXXXXXXX'
   */
  const numberOfFractionRelatedParts = 2;
  const formattedIntegerParts = parts.slice(0, -numberOfFractionRelatedParts);

  const fractionPart = parts[parts.length - 1];
  const formattedFractionPart = trimZerosRight(fractionPart.substring(0, numberOfDecimals));
  const isFractionPartValuable = Number(formattedFractionPart) !== 0;
  const fractionPartSeparator = '.';

  return isFractionPartValuable
    ? [...formattedIntegerParts, fractionPartSeparator, formattedFractionPart]
    : [...formattedIntegerParts];
}

function formatNumberAsCryptoBalance(balance: number, withCommas: boolean, decimals: number) {
  const preFormatter = new Intl.NumberFormat('en', {
    style: 'decimal',
    minimumIntegerDigits: 1,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    useGrouping: withCommas,
  });
  const preFormattedParts = preFormatter.formatToParts(balance).map(part => part.value);
  const isFractionFormattingNeeded = decimals > 0;

  const parts = isFractionFormattingNeeded ? formatPartsWithFractions(preFormattedParts, decimals) : preFormattedParts;

  return parts.join('');
}

const formatCryptoBalance = (
  balance: string | number,
  formatSettings: {
    withCommas?: boolean;
    decimals?: number;
    coinAbbreviation?: string;
  },
) => {
  const withCommas = formatSettings.withCommas || false;
  const decimals = formatSettings.decimals || 8;
  const coinAbbreviation = formatSettings.coinAbbreviation || null;

  const formattedValue =
    typeof balance === 'number'
      ? formatNumberAsCryptoBalance(balance, withCommas, decimals)
      : formatStringAsCryptoBalance(balance, withCommas, decimals);

  return coinAbbreviation ? formattedValue.concat(' ', coinAbbreviation) : formattedValue;
};
export { formatCryptoBalance };
