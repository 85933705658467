import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoHeightGrid } from 'components/shared/grids/AutoHeightGrid';
import { CommonAutoHeightGridBox } from 'components/shared/layouts/CommonLayout';
import { useLazyGetWalletAssetsByIdQuery, WalletAssetsByIdApiParams } from 'redux/api/api-wallets';
import { useExport } from 'hooks/use-export';
import { WalletAssetRow } from 'ui-interfaces/row-interfaces/wallets/wallet-asset-row';
import { FilterResult } from 'filters/interfaces/filter-result';

import { filters, selectionMacroCommand } from './crypto-assets-filter-config';
import { onResponseModelTransform, onExportClick } from './handlers';
import { columns } from './columns-definitions';

type Props = {
  walletId: string;
  setIsDetailsPageError: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CryptoAssetsGrid: React.FC<Props> = ({ walletId, setIsDetailsPageError }) => {
  const { t } = useTranslation();
  const { downloadCsv, isLoading, error } = useExport();

  const [getWalletAssetsByIdTrigger, walletAssetsByIdResult] = useLazyGetWalletAssetsByIdQuery();

  const fetchWalletAssetsById = useCallback(
    (page?: number, filterResult?: FilterResult) =>
      getWalletAssetsByIdTrigger({ walletId, page, filterResult } as WalletAssetsByIdApiParams, false),
    [getWalletAssetsByIdTrigger, walletId],
  );

  const [rows, setRows] = useState<WalletAssetRow[]>([]);
  const [resourceCount, setResourceCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(walletAssetsByIdResult.isUninitialized);

  useEffect(() => {
    fetchWalletAssetsById();
  }, [fetchWalletAssetsById]);

  useEffect(() => {
    if (walletAssetsByIdResult.isSuccess && walletAssetsByIdResult.data) {
      const transformedItems = walletAssetsByIdResult.data.data.map(onResponseModelTransform);
      setRows(transformedItems);
      setResourceCount(walletAssetsByIdResult.data.meta?.resourceCount ?? 0);
      setLoading(walletAssetsByIdResult.isFetching);
    }
  }, [walletAssetsByIdResult.data, walletAssetsByIdResult.isFetching, walletAssetsByIdResult.isSuccess]);

  useEffect(() => {
    setIsDetailsPageError(Boolean(walletAssetsByIdResult.error || error));
  }, [error, setIsDetailsPageError, walletAssetsByIdResult.error]);

  return (
    <CommonAutoHeightGridBox>
      <AutoHeightGrid
        columns={columns}
        headerName={t('walletDetailsPage.assets.title')}
        filters={filters}
        selectionMacroCommand={selectionMacroCommand}
        noResultsTitle={t('walletDetailsPage.assets.noResultsTitle')}
        noResultsSubtitle={t('walletDetailsPage.assets.noResultsSubtitle')}
        containerHeightWhenNonAHMode="515px"
        rows={rows}
        rowCount={resourceCount}
        isLoading={loading}
        error={walletAssetsByIdResult.error || error}
        fetchOtherItems={fetchWalletAssetsById}
        onExportClick={onExportClick(downloadCsv, walletId!)}
        isExportLoading={isLoading}
      />
    </CommonAutoHeightGridBox>
  );
};
