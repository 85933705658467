import { AssetByNetwork } from 'ui-enums/response/asset-by-network';
import { AssetByNetworkIconSize } from 'ui-enums/asset-by-network-icon-size';
import { getAssetByNetworkIcon28px } from './helpers/get-asset-by-network-icon-28px';
import { getAssetByNetworkIcon34px } from './helpers/get-asset-by-network-icon-34px';
import { getAssetByNetworkIcon36px } from './helpers/get-asset-by-network-icon-36px';

export const getAssetByNetworkIcon = (
  asset: AssetByNetwork,
  size: AssetByNetworkIconSize = AssetByNetworkIconSize['36px'],
) => {
  switch (size) {
    case AssetByNetworkIconSize['28px']:
      return getAssetByNetworkIcon28px(asset);
    case AssetByNetworkIconSize['34px']:
      return getAssetByNetworkIcon34px(asset);
    case AssetByNetworkIconSize['36px']:
      return getAssetByNetworkIcon36px(asset);
    default:
      return getAssetByNetworkIcon28px(asset);
  }
};
