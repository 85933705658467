export const EXCEED_USER_LIMIT = '2ba023c8-9452-49c4-9605-3ef18c8c4167';
export const EXCEED_TRANSACTION_LIMIT = '4378703d-30d8-41c8-b9d9-c1c2faa0770f';
export const CANNOT_LOCK_TRANSACTION_AMOUNT = '1b7d86f5-dd79-4b4d-86a1-35c503cad88c';
export const PAYMENT_ALREADY_REJECTED = 'e04ca093-2912-4497-bab3-dc5079a25e49';
export const CANNOT_APPROVE_PAYMENT_IN_STATUS_REJECTED = '77771705-6611-40d2-a191-06656934d84b';
export const JWT_TOKEN_IS_ALREADY_IN_USE = 'a2b1490e-3d35-4930-9b4b-1e3ce106b825';
export const CA_OR_IDENTITY_SUSPENDED = '7e359230-2468-41e2-ac51-a9d4d83d4a7a';
export const NO_BANK_INF0_FOUND_WITH_ROUTING_NUMBER = 'a17623e5-fdbd-4ad0-80f0-82f08d348590';
export const MAX_LENGTH_OF_BANK_NAME = '88bd5600-7996-45c1-a90b-5d3b54efd81e';
export const ROUTING_NUMBER_DOES_NOT_PROCESS_FEDWIRE_PAYMENTS = '9849f69c-1cab-44d3-8454-f545cc863450';
export const OTHER_EXCEPTIONS = '0454adfd-6bad-4cb0-aba6-a5fc7d81101c';
export const IDENTITY_IS_NOT_VALID = '220107a4-c5e6-4fb2-881a-0c5d3617025a';
export const ACTIVATE_ALREADY_ACTIVATED_CA_OR_IDENTITY_IS_NOT_VALID = '1eadb2e9-9c0e-4d88-aad1-abca2eeede7a';
export const SUSPEND_ALREADY_SUSPENDED_CA_OR_IDENTITY_IS_NOT_VALID = '591b4fc4-d9d2-44a1-8b32-e68c13c45573';
export const TRANSFER_IS_FORBIDDEN_FOR_ASSET_PAIRS = '432d24cf-af75-4bda-bad3-9eabdc1852aa';
export const WITHDRAWAL_AMOUNT_IS_LOWER_REQUIRED_MIN_ACCOUNT = 'ecce468b-8c20-41cf-8e47-d2feaae06fa8';
export const WITHDRAWAL_AMOUNT_IS_LOWER_REQUIRED_MIN_TRANSACTION = '0b0d0897-2165-4414-bc06-9b48b4431792';
export const DEFAULT_ERROR_CODE_ELEMENTS = 'bb68b7cb-1f30-4f60-a549-26393eddfcb9';
export const BALANCE_OF_WALLET_FOR_NATIVE_COIN_IS_NOT_SUFFICIENT = '49820364-a8e9-4f2e-a6ca-cd5faeb76337';
export const PAGE_SIZE_MUST_BE_LESS_OR_EQUAL_THAN_5000 = 'e087cd8b-281e-4c59-8710-3ed654e8c2cd';
export const FUNCTIONALITY_IS_NOT_AVAILABLE_FOR_ORGANIZATION = '5303afc3-c79a-43b8-9a24-a691f6304349';
