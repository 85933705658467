import i18n from 'localizations';
import { CompoundLabel, TransactionsRow } from 'ui-interfaces/row-interfaces/transactions';
import { TransactionWireBaseViewModel } from 'view-models/transactions/list/fiat/base/transaction-wire-base-vm';
import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { get4LastChars } from 'utils/get-4-last-chars';

export class TransactionDepositAchReturnViewModel extends TransactionWireBaseViewModel implements TransactionsRow {
  readonly color: string = 'error.main';

  constructor(transaction: TransactionResponseModel) {
    super(transaction);
    this.destination = {
      start: `*${get4LastChars(transaction.destinationDisplayValue ?? '')}`,
      end: transaction.destinationCustodialAccountId
        ? i18n.t('transactionsPage.account')
        : i18n.t('transactionsPage.externalAccount'),
    } as CompoundLabel;
  }
}
