import React, { useState } from 'react';
import ContentBlock from 'elements/element-transfer/components/Withdrawal/WireRecipientInfoPage';
import useWireWithdrawalRecipientInfoPage from 'elements/element-transfer/hooks/useWireWithdrawalRecipientInfoPage';
import { OnCloseElement } from 'elements/models/types/element-result';
import { ConfirmModal } from 'elements/components/ConfirmModal';
import { setWithdrawalWireCloseConfirmationRequired, useElement } from 'elements/element-transfer/contexts/Element';

type Props = {
  onClose: OnCloseElement;
};

const WireRecipientInfoPage: React.FC<Props> = ({ onClose }) => {
  const { dispatch } = useElement();

  const { initialFormData, continueHandler, backHandler } = useWireWithdrawalRecipientInfoPage();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const onConfirmDecline = () => setIsConfirmModalOpen(false);

  const onCustomClose = () => setIsConfirmModalOpen(true);

  const setIsConfirmCloseRequired = (val: boolean) => {
    dispatch(setWithdrawalWireCloseConfirmationRequired(val));
  };

  return (
    <>
      <ContentBlock
        initialFormData={initialFormData}
        onContinue={continueHandler}
        onCustomClose={onCustomClose}
        onClose={onClose}
        onBack={backHandler}
        setIsConfirmCloseRequired={setIsConfirmCloseRequired}
      />
      {isConfirmModalOpen && <ConfirmModal onConfirm={onClose} onDecline={onConfirmDecline} />}
    </>
  );
};

export default WireRecipientInfoPage;
