import { styled } from '@mui/material/styles';

export const StyledContainer = styled('div')(({ theme }) => ({
  paddingBlock: theme.gaps._24px,
  paddingInline: theme.gaps._24px,
  borderRadius: theme.gaps._8px,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.grey[200],
  display: 'flex',
  flexDirection: 'row',
}));
