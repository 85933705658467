export enum AssetByNetwork {
  nft = 'nft',
  ethMainnet = 'ethMainnet',
  ethRopsten = 'ethRopsten',
  usdcMainnet = 'usdcMainnet',
  usdcRopsten = 'usdcRopsten',
  flrnsMainnet = 'flrnsMainnet',
  flrnsRopsten = 'flrnsRopsten',
  wethMainnet = 'wethMainnet',
  wethRopsten = 'wethRopsten',
  wethPolygonMainnet = 'wethPolygonMainnet',
  wethPolygonMumbai = 'wethPolygonMumbai',
  maticPolygonMainnet = 'maticPolygonMainnet',
  maticPolygonMumbai = 'maticPolygonMumbai',
  btcBitcoinTestnet = 'btcBitcoinTestnet',
  btcBitcoinMainnet = 'btcBitcoinMainnet',
  solSolanaMainnet = 'solSolanaMainnet',
  solSolanaDevnet = 'solSolanaDevnet',
  solSolanaTestnet = 'solSolanaTestnet',
  adaCardanoMainnet = 'adaCardanoMainnet',
  adaCardanoTestnet = 'adaCardanoTestnet',
  dotPolkadotMainnet = 'dotPolkadotMainnet',
  dotPolkadotWestend = 'dotPolkadotWestend',
  avaxAvalancheMainnet = 'avaxAvalancheMainnet',
  avaxAvalancheFuji = 'avaxAvalancheFuji',
  batMainnet = 'batMainnet',
  batRopsten = 'batRopsten',
  shibMainnet = 'shibMainnet',
  shibRopsten = 'shibRopsten',
  usdcPolygonMainnet = 'usdcPolygonMainnet',
  usdcPolygonMumbai = 'usdcPolygonMumbai',
  maticMainnet = 'maticMainnet',
  maticRopsten = 'maticRopsten',
  usd = 'usd',
  ethGoerli = 'ethGoerli',
  ethSepolia = 'ethSepolia',
  usdcGoerli = 'usdcGoerli',
  usdcSepolia = 'usdcSepolia',
  flrnsGoerli = 'flrnsGoerli',
  flrnsSepolia = 'flrnsSepolia',
  wethGoerli = 'wethGoerli',
  wethSepolia = 'wethSepolia',
  maticGoerli = 'maticGoerli',
  maticSepolia = 'maticSepolia',
  batGoerli = 'batGoerli',
  batSepolia = 'batSepolia',
  shibGoerli = 'shibGoerli',
  shibSepolia = 'shibSepolia',
  bnbBnbSmartChainMainnet = 'bnbBnbSmartChainMainnet',
  bnbBnbSmartChainTestnet = 'bnbBnbSmartChainTestnet',
  privateSecurity = 'privateSecurity',
  xlmStellarMainnet = 'xlmStellarMainnet',
  xlmStellarTestnet = 'xlmStellarTestnet',
  linkMainnet = 'linkMainnet',
  linkGoerli = 'linkGoerli',
  linkSepolia = 'linkSepolia',
  algoAlgorandMainnet = 'algoAlgorandMainnet',
  algoAlgorandTestnet = 'algoAlgorandTestnet',
  busdBnbSmartChainMainnet = 'busdBnbSmartChainMainnet',
  busdBnbSmartChainTestnet = 'busdBnbSmartChainTestnet',
  ltcLitecoinMainnet = 'ltcLitecoinMainnet',
  ltcLitecoinTestnet = 'ltcLitecoinTestnet',
  uniMainnet = 'uniMainnet',
  uniGoerli = 'uniGoerli',
  uniSepolia = 'uniSepolia',
  busdMainnet = 'busdMainnet',
  busdGoerli = 'busdGoerli',
  busdSepolia = 'busdSepolia',
  crvMainnet = 'crvMainnet',
  crvGoerli = 'crvGoerli',
  crvSepolia = 'crvSepolia',
  dogeDogeMainnet = 'dogeDogeMainnet',
  dogeDogeTestnet = 'dogeDogeTestnet',
  aaveMainnet = 'aaveMainnet',
  aaveGoerli = 'aaveGoerli',
  aaveSepolia = 'aaveSepolia',
  compMainnet = 'compMainnet',
  compGoerli = 'compGoerli',
  compSepolia = 'compSepolia',
  xtzTezosMainnet = 'xtzTezosMainnet',
  xtzTezosTestnet = 'xtzTezosTestnet',
  bchBchMainnet = 'bchBchMainnet',
  bchBchTestnet = 'bchBchTestnet',
  eosEosTestnet = 'eosEosTestnet',
  eosEosMainnet = 'eosEosMainnet',
  atomCosmosHub = 'atomCosmosHub',
  atomCosmosHubTestnet = 'atomCosmosHubTestnet',
  grtMainnet = 'grtMainnet',
  grtGoerli = 'grtGoerli',
  grtSepolia = 'grtSepolia',
  sushiMainnet = 'sushiMainnet',
  sushiGoerli = 'sushiGoerli',
  sushiSepolia = 'sushiSepolia',
  sandMainnet = 'sandMainnet',
  sandGoerli = 'sandGoerli',
  sandSepolia = 'sandSepolia',
  enjMainnet = 'enjMainnet',
  enjGoerli = 'enjGoerli',
  enjSepolia = 'enjSepolia',
  axsMainnet = 'axsMainnet',
  axsGoerli = 'axsGoerli',
  axsSepolia = 'axsSepolia',
  manaMainnet = 'manaMainnet',
  manaGoerli = 'manaGoerli',
  manaSepolia = 'manaSepolia',
  mkrMainnet = 'mkrMainnet',
  mkrGoerli = 'mkrGoerli',
  mkrSepolia = 'mkrSepolia',
  yfiMainnet = 'yfiMainnet',
  yfiGoerli = 'yfiGoerli',
  yfiSepolia = 'yfiSepolia',
  apeMainnet = 'apeMainnet',
  apeGoerli = 'apeGoerli',
  apeSepolia = 'apeSepolia',
  nearNearMainnet = 'nearNearMainnet',
  nearNearTestnet = 'nearNearTestnet',
  daiMainnet = 'daiMainnet',
  daiGoerli = 'daiGoerli',
  daiSepolia = 'daiSepolia',
  usdtMainnet = 'usdtMainnet',
  usdtGoerli = 'usdtGoerli',
  usdtSepolia = 'usdtSepolia',
  xrpRippleMainnet = 'xrpRippleMainnet',
  xrpRippleTestnet = 'xrpRippleTestnet',
  usdcArbitrumMainnet = 'usdcArbitrumMainnet',
  usdcArbitrumGoerli = 'usdcArbitrumGoerli',
  usdcArbitrumSepolia = 'usdcArbitrumSepolia',
  usdcAlgorandMainnet = 'usdcAlgorandMainnet',
  usdcAlgorandTestnet = 'usdcAlgorandTestnet',
  usdcSolanaMainnet = 'usdcSolanaMainnet',
  usdcSolanaDevnet = 'usdcSolanaDevnet',
  usdcSolanaTestnet = 'usdcSolanaTestnet',
  usdcAvalancheMainnet = 'usdcAvalancheMainnet',
  usdcAvalancheFuji = 'usdcAvalancheFuji',
  usdcStellarMainnet = 'usdcStellarMainnet',
  usdcStellarTestnet = 'usdcStellarTestnet',
  usdcFantomMainnet = 'usdcFantomMainnet',
  usdcTronMainnet = 'usdcTronMainnet',
  usdcTronTestnet = 'usdcTronTestnet',
  usdcBnbSmartChainMainnet = 'usdcBnbSmartChainMainnet',
  usdcBnbSmartChainTestnet = 'usdcBnbSmartChainTestnet',
  flrnsPolygonMumbai = 'flrnsPolygonMumbai',
  ftm = 'ftm',
  flowFlowMainnet = 'flowFlowMainnet',
  flowFlowTestnet = 'flowFlowTestnet',
  trxTronMainnet = 'trxTronMainnet',
  trxTronTestnet = 'trxTronTestnet',
  usdcFlowMainnet = 'usdcFlowMainnet',
  usdcFlowTestnet = 'usdcFlowTestnet',
  galaMainnet = 'galaMainnet',
  galaGoerli = 'galaGoerli',
  galaSepolia = 'galaSepolia',
  chzMainnet = 'chzMainnet',
  chzGoerli = 'chzGoerli',
  chzSepolia = 'chzSepolia',
  smtfBnbSmartChainMainnet = 'smtfBnbSmartChainMainnet',
  smtfBnbSmartChainTestnet = 'smtfBnbSmartChainTestnet',
  qntMainnet = 'qntMainnet',
  qntGoerli = 'qntGoerli',
  qntSepolia = 'qntSepolia',
  hbarHederaMainnet = 'hbarHederaMainnet',
  hbarHederaTestnet = 'hbarHederaTestnet',
  gold = 'XAU',
  silver = 'XAG',
}
