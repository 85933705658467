import React from 'react';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { IdentityCheck } from 'elements/features/IdentityCheck';
import { ErrorState, LoadingPage } from 'elements/features/LoadingErrorState';
import { IdentityLevel } from 'elements/types';
import { ReactComponent as CryptoIcon } from 'elements/element-transfer/assets/icons/crypto.svg';
import FlowFirstPageLayout from 'elements/element-transfer/components/FlowFirstPageLayout';
import useIdentityCheck from 'elements/element-transfer/hooks/useIdentityCheck';
import i18n from 'localizations';
import { setPage, useElement } from 'elements/element-transfer/contexts/Element';
import { OnCloseElement } from 'elements/models/types/element-result';

const cryptoIcon = <CryptoIcon width={32} height={32} />;

type EntryRouteType =
  | Paths.DepositAchAmount
  | Paths.DepositWire
  | Paths.DepositCryptoAddresses
  | Paths.WithdrawalCryptoAmount
  | Paths.WithdrawalWireRecipientSearch;

type EntryPageDataType = {
  pageTitle: string;
  title?: string;
  description?: string;
  icon?: React.ReactNode;
};

const getEmptyPageData = (route: EntryRouteType): EntryPageDataType => {
  const pagesMap = {
    [Paths.DepositAchAmount]: {
      pageTitle: i18n.t('elements.transfer.customizableLabels.depositAchAmountPageTitle'),
    },
    [Paths.DepositWire]: {
      pageTitle: i18n.t('elements.transfer.customizableLabels.depositWireInstructionsPageTitle'),
    },
    [Paths.DepositCryptoAddresses]: {
      pageTitle: i18n.t('elements.transfer.customizableLabels.depositCryptoListPageTitle'),
      title: i18n.t('elements.transfer.restrictions.cryptoDepositEmptyStateTitle'),
      description: i18n.t('elements.transfer.restrictions.cryptoDepositEmptyStateDescription'),
      icon: cryptoIcon,
    },
    [Paths.WithdrawalCryptoAmount]: {
      pageTitle: i18n.t('elements.transfer.customizableLabels.withdrawalCryptoAmountPageTitle'),
      title: i18n.t('elements.transfer.restrictions.cryptoWithdrawalEmptyStateTitle'),
      description: i18n.t('elements.transfer.restrictions.cryptoWithdrawalEmptyStateDescription'),
      icon: cryptoIcon,
    },
    [Paths.WithdrawalWireRecipientSearch]: {
      pageTitle: i18n.t('elements.transfer.customizableLabels.withdrawalWireRecipientInfoPageTitle'),
    },
  };

  return pagesMap[route];
};

type Props = {
  requiredLevel?: IdentityLevel;
  onClose: OnCloseElement;
  path: string;
};

const IdentityRestriction: React.FC<Props> = ({ children, requiredLevel, onClose, path }) => {
  const { state, dispatch } = useElement();

  const { identityData, custodialAccount, isLoading, isError, onReload, onVerify, contactUsUrl, isServiceUnavailable } =
    useIdentityCheck(requiredLevel);

  const pageData = getEmptyPageData(state.page.location as EntryRouteType);

  const onBack = () => dispatch(setPage(Paths.Transfer));

  return isLoading ? (
    <LoadingPage onClose={onClose} />
  ) : (
    <FlowFirstPageLayout title={pageData?.pageTitle} fullContentHeight onBack={onBack} onClose={onClose}>
      {!identityData || isError ? (
        <ErrorState onReload={onReload} />
      ) : (
        <IdentityCheck
          identityData={identityData}
          custodialAccount={custodialAccount}
          requiredLevel={requiredLevel}
          title={pageData?.title}
          icon={pageData?.icon}
          description={pageData?.description}
          contactUsHref={contactUsUrl || ''}
          onVerify={onVerify}
          isServiceUnavailable={isServiceUnavailable}
          path={path}
        >
          {children}
        </IdentityCheck>
      )}
    </FlowFirstPageLayout>
  );
};

export default IdentityRestriction;
