import { mainApi } from 'redux/api/main-api';
import { API_ROUTES } from 'redux/api/api-routes';
import { CollectionResponse, Paginated } from 'redux/api/types';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_FOR_AUTO_HEIGHT, FIRST_PAGE } from 'redux/api/constants';
import { FilterResult } from 'filters/interfaces/filter-result';
import { WalletAssetResponseModel } from 'models/response/wallets/wallet-asset-response-model';
import { WalletResponseModel } from 'models/response/wallets/wallet-response-model';
import { TokenResponseModel } from 'models/response/tokens/token-response-model';
import { WalletDetailsResponseModel } from 'models/response/wallets/wallet-details-response-model';
import { TokenDetailResponseModel } from 'models/response/tokens/token-detail-response-model';
import { getRouteWithParams } from 'utils/get-route-with-params';

export interface WalletsApiParams extends Paginated {
  ownerIdentityId?: string;
  filterResult?: FilterResult;
}

export interface WalletAssetsByIdApiParams extends Paginated {
  walletId: string;
  filterResult?: FilterResult;
}

export interface TokensByIdApiParams extends Paginated {
  walletId: string;
  filterResult?: FilterResult;
}

export interface TokenDetailByIdApiParams {
  walletId: string;
  tokenId: string;
}

const walletsApi = mainApi.injectEndpoints({
  endpoints: build => ({
    getWallets: build.query<CollectionResponse<WalletResponseModel>, WalletsApiParams>({
      query: ({ page = FIRST_PAGE, pageSize = DEFAULT_PAGE_SIZE, ownerIdentityId, filterResult }) => {
        if (filterResult) {
          const { property, operator, value } = filterResult as FilterResult;

          return {
            url: API_ROUTES.private.WALLETS,

            params: {
              page,
              pageSize,
              [`filter[ownerIdentityId eq]`]: ownerIdentityId,
              [`filter[${property} ${operator}]`]: value.trim(),
            },
          };
        }
        return {
          url: API_ROUTES.private.WALLETS,
          params: { page, pageSize, [`filter[ownerIdentityId eq]`]: ownerIdentityId },
        };
      },
    }),
    getWalletAssetsById: build.query<CollectionResponse<WalletAssetResponseModel>, WalletAssetsByIdApiParams>({
      query: ({ page = FIRST_PAGE, pageSize = DEFAULT_PAGE_SIZE, walletId, filterResult }) => {
        if (filterResult) {
          const { property, operator, value } = filterResult as FilterResult;

          return {
            url: getRouteWithParams(API_ROUTES.private.WALLET_ASSETS, { walletId }),
            params: { page, pageSize, [`filter[${property} ${operator}]`]: value.trim() },
          };
        }
        return {
          url: getRouteWithParams(API_ROUTES.private.WALLET_ASSETS, { walletId }),
          params: { page, pageSize },
        };
      },
    }),
    getWalletTokensById: build.query<CollectionResponse<TokenResponseModel>, TokensByIdApiParams>({
      query: ({ page = FIRST_PAGE, pageSize = DEFAULT_PAGE_SIZE_FOR_AUTO_HEIGHT, walletId, filterResult }) => {
        if (filterResult) {
          const { property, operator, value } = filterResult as FilterResult;

          return {
            url: getRouteWithParams(API_ROUTES.private.WALLET_TOKENS, { walletId }),
            params: { page, pageSize, [`filter[${property} ${operator}]`]: value.trim() },
          };
        }
        return {
          url: getRouteWithParams(API_ROUTES.private.WALLET_TOKENS, { walletId }),
          params: { page, pageSize },
        };
      },
    }),
    getWalletDetailsById: build.query<WalletDetailsResponseModel, string>({
      query: (walletId: string) => ({
        url: getRouteWithParams(API_ROUTES.private.WALLET, { walletId }),
      }),
    }),
    getWalletTokenDetailsById: build.query<TokenDetailResponseModel, TokenDetailByIdApiParams>({
      query: ({ walletId, tokenId }) => {
        return {
          url: getRouteWithParams(API_ROUTES.private.WALLET_TOKEN, { walletId, tokenId }),
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useLazyGetWalletAssetsByIdQuery,
  useLazyGetWalletsQuery,
  useLazyGetWalletTokensByIdQuery,
  useLazyGetWalletTokenDetailsByIdQuery,
  useGetWalletDetailsByIdQuery,
} = walletsApi;
