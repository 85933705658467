import { styled, Alert, alertClasses, IconButton, css } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';
import { ReactComponent as ErrorIcon } from 'assets/icons/error-alert-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon_24x24.svg';

export const StyledAlert = styled(Alert)(({ theme }) => ({
  width: pxToRem(462),
  alignItems: 'center',
  padding: `${pxToRem(theme.gaps._8px)} ${pxToRem(theme.gaps._20px)} `,
  backgroundColor: theme.palette.error.main,
  border: `1px solid ${theme.palette.secondary.light}`,
  borderRadius: pxToRem(theme.gaps._8px),
  [`& .${alertClasses.icon}`]: {
    marginRight: pxToRem(theme.gaps._8px),
    padding: 0,
  },
  [`& .${alertClasses.message}`]: {
    ...theme.typography.bodyDefaultMedium,
    color: theme.palette.secondary.main,
    padding: 0,
  },
  [`& .${alertClasses.action}`]: {
    padding: 0,
  },
}));

export const StyledIconButton = styled(IconButton)(() => ({
  height: pxToRem(36),
  width: pxToRem(36),
  padding: 0,
}));

export const StyledErrorIcon = styled(ErrorIcon)(
  ({ theme }) => css`
    color: ${theme.palette.background.paper};
  `,
);

export const StyledCloseIcon = styled(CloseIcon)(
  ({ theme }) => css`
    color: ${theme.palette.background.paper};
  `,
);
