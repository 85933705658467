import React from 'react';
import { FieldInputProps, FormikProps, FormikValues } from 'formik';
import { KeyOf } from './types';

export const getBasicFormikProps =
  <Values extends FormikValues>(formik: FormikProps<Values>) =>
  (
    name: KeyOf<Values>,
  ): Omit<FieldInputProps<Values[typeof name]>, 'onChange'> & { onChange: (e: React.ChangeEvent<any>) => void } => ({
    ...formik.getFieldProps(name),
  });
