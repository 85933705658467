import React from 'react';
import { Gap } from 'components/shared/Gap';
import { IdentityDetailsUrlParams } from 'navigation/url-params-type';
import { CommonLayoutContent } from 'components/shared/layouts/CommonLayout';
import { IIdentityDetailsPage } from 'ui-interfaces/identity-details/i-identity-details-page';
import { IdentityDetailsSection } from './IdentityDetailsSection';
import { DocumentsList } from './documents/DocumentsList';
import { BeneficialOwnersSection } from './beneficial-owners/BeneficialOwnersSection';

type Props = {
  urlParams: Readonly<Partial<IdentityDetailsUrlParams>>;
  identityDetailsPage?: IIdentityDetailsPage;
  setIsDetailsPageError: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
};

export const IdentityDetailsTab: React.FC<Props> = ({
  urlParams,
  identityDetailsPage,
  loading,
  setIsDetailsPageError,
}) => {
  return (
    <CommonLayoutContent>
      <IdentityDetailsSection data={identityDetailsPage} isLoading={loading} />
      <DocumentsList urlParams={urlParams} setIsDetailsPageError={setIsDetailsPageError} />
      <BeneficialOwnersSection urlParams={urlParams} />
      <Gap size="_48px" />
    </CommonLayoutContent>
  );
};
