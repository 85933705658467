import React, { useState, useEffect } from 'react';
import { ReactComponent as NoResultsIcon } from 'assets/icons/grid/no-results.svg';
import { IconWithLabels } from 'components/shared/IconWithLabels';

type Props = {
  title: string;
  subtitle: string;
};
export const CustomNoResultsOverlay: React.FC<Props> = ({ title, subtitle }) => {
  // https://github.com/mui/mui-x/issues/4309
  // Workaround
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 300);
    return () => clearTimeout(timeout);
  }, []);

  if (!show) return null;

  return <IconWithLabels icon={<NoResultsIcon />} title={title} subtitle={subtitle} />;
};
