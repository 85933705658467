import { LabelLength } from 'ui-enums/label-length';
import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { formatCurrency } from 'utils/format-currency';
import { getSignOfTransaction } from 'utils/transactions/get-sign-of-transaction';
import { getDynamicAssetLabel } from 'utils/labels-mapping/get-dynamic-asset-label';

export const getStandardAmountFormatting = (transaction: TransactionResponseModel): string => {
  return `${getSignOfTransaction(transaction)}${
    transaction.amount ? formatCurrency(transaction.amount) : transaction.cryptoAmount
  } ${getDynamicAssetLabel(LabelLength.short, transaction.assetTicker, transaction.assetName)}`;
};
