import React from 'react';
import Typography from '@mui/material/Typography';
import { ReactComponent as ExportIcon } from 'assets/icons/export-icon.svg';
import i18n from 'localizations';
import { Row } from 'components/shared/Flex/Row';
import { Gap } from 'components/shared/Gap';
import { StyledButton, StyledLoader } from './styled';

type Props = {
  isDisabled: boolean;
  isLoading?: boolean;
  onClick: () => void;
};

export const ExportButton: React.FC<Props> = ({ isDisabled, isLoading = false, onClick }) => {
  return (
    <StyledButton
      sx={{
        opacity: isDisabled ? 0 : 1,
      }}
      disabled={isDisabled}
      variant="outlined"
      onClick={onClick}
    >
      {isLoading ? (
        <StyledLoader size="small" />
      ) : (
        <Row>
          <ExportIcon />
          <Gap size="_12px" isHorizontal />
          <Typography variant="buttonMedium">{i18n.t('filters.exportButtonLabel')}</Typography>
        </Row>
      )}
    </StyledButton>
  );
};
