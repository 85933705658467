import { useEffect, useState } from 'react';
import { PathMatch, useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BreadCrumbsItem } from 'components/shared/Breadcrumbs';
import { NESTED_PATHS, PATHS } from 'navigation/constants';
import {
  IdentityDetailsTokenDetailsUrlParams,
  IdentityDetailsUrlParams,
  TokenDetailsUrlParams,
  WalletDetailsUrlParams,
} from 'navigation/url-params-type';
import { getRouteWithParams } from 'utils/get-route-with-params';

export const useBcTokenDetailsPage = () => {
  const { t } = useTranslation();
  const [breadCrumbs, setBreadCrumbs] = useState<BreadCrumbsItem[]>([]);
  const [pathMatch, setPathMatch] = useState<PathMatch | null>(null);

  const MATCHES_WALLETS__TOKEN_DETAILS = useMatch({
    path: PATHS.WAPI.TOKEN_DETAILS,
    end: true,
  });

  const MATCHES_WALLET_DETAILS_UNDER_IDENTITIES = useMatch({
    path: NESTED_PATHS.IDENTITY_DETAILS__TOKEN_DETAILS,
    end: true,
  });

  useEffect(() => {
    if (MATCHES_WALLET_DETAILS_UNDER_IDENTITIES) {
      const { identityId, identityType, walletId } =
        MATCHES_WALLET_DETAILS_UNDER_IDENTITIES.params as IdentityDetailsTokenDetailsUrlParams;
      setBreadCrumbs([
        {
          label: t('identityDetailsPage.breadcrumbs.identities'),
          path: PATHS.IDENTITIES,
        },
        {
          label: t('identityDetailsPage.breadcrumbs.identityDetails'),
          path: getRouteWithParams(PATHS.IDENTITY_DETAILS, {
            identityId,
            identityType,
          } as IdentityDetailsUrlParams),
        },
        {
          label: t('walletDetailsPage.breadcrumbs.wallet'),
          path: getRouteWithParams(PATHS.WAPI.WALLET_DETAILS, { walletId } as WalletDetailsUrlParams),
        },
        {
          label: t('nft.nftDetails.breadcrumbs.nftDetails'),
        },
      ]);
      setPathMatch(MATCHES_WALLET_DETAILS_UNDER_IDENTITIES);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (MATCHES_WALLETS__TOKEN_DETAILS) {
      const { walletId } = MATCHES_WALLETS__TOKEN_DETAILS.params as TokenDetailsUrlParams;

      setBreadCrumbs([
        {
          label: t('walletDetailsPage.breadcrumbs.wallets'),
          path: PATHS.WAPI.WALLETS,
        },
        {
          label: t('walletDetailsPage.breadcrumbs.wallet'),
          path: getRouteWithParams(PATHS.WAPI.WALLET_DETAILS, { walletId } as WalletDetailsUrlParams),
        },
        {
          label: t('nft.nftDetails.breadcrumbs.nftDetails'),
        },
      ]);
      setPathMatch(MATCHES_WALLETS__TOKEN_DETAILS);
    }
    // eslint-disable-next-line
  }, []);

  return { breadCrumbs, pathMatch };
};
