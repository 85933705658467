import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Row } from 'components/shared/Flex/Row';
import { Gap } from 'components/shared/Gap';
import { ExpendSection } from 'components/shared/ExpandSection';
import { ExpendSectionRow } from 'components/shared/ExpandSection/ExpendSectionRow';
import { IdentityDetailsUrlParams } from 'navigation/url-params-type';
import { useLazyGetBusinessIdentityBeneficialOwnersQuery } from 'redux/api/api-business-identities';
import { useTransformCollectionResponse } from 'hooks/use-transform-collection-response';
import { BeneficialOwnerResponseModel } from 'models/response/identities/business/beneficial-owner-response-model';
import { BeneficialOwnerRow } from 'ui-interfaces/row-interfaces/identities/beneficial-owner-row';
import { createBeneficialOwnersViewModel } from 'factories/beneficial-owners/create-beneficial-owners-view-model';
import { IdentityType } from 'models/enums/identities/identity-type';
import { BeneficialOwnersRow } from '../BeneficialOwnersRow';
import { onBeneficialOwnerRowClick } from './handlers';
import { NameColumnLabel, StyledDivider, IdColumnLabel, PillColumnLabel } from './styled';

type Props = {
  urlParams: Readonly<Partial<IdentityDetailsUrlParams>>;
};

export const BeneficialOwnersSection: React.FC<Props> = ({ urlParams }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [fetchBeneficialOwnersTrigger, beneficialOwnersResult] = useLazyGetBusinessIdentityBeneficialOwnersQuery();

  const [formattedBeneficialOwners] = useTransformCollectionResponse<BeneficialOwnerResponseModel, BeneficialOwnerRow>(
    beneficialOwnersResult.data,
    createBeneficialOwnersViewModel,
  );

  const isBusinessIdentity = urlParams.identityType === IdentityType.business;
  useEffect(() => {
    if (isBusinessIdentity) {
      fetchBeneficialOwnersTrigger(urlParams.identityId!);
    }
  }, [fetchBeneficialOwnersTrigger, urlParams.identityId, isBusinessIdentity]);

  const hasBeneficialOwners = formattedBeneficialOwners.length > 0;
  if (
    beneficialOwnersResult.isLoading ||
    beneficialOwnersResult.isError ||
    !isBusinessIdentity ||
    !hasBeneficialOwners
  ) {
    return null;
  }

  return (
    <>
      <Gap size="_24px" />
      <ExpendSection sectionTitle={t('beneficialOwnersList.sectionTitle')}>
        <Row>
          <NameColumnLabel>
            <Typography color="grey.600" variant="bodySmallBold">
              {t('beneficialOwnersList.columnTitles.ownerName')}
            </Typography>
          </NameColumnLabel>
          <IdColumnLabel>
            <Typography color="grey.600" variant="bodySmallBold">
              {t('beneficialOwnersList.columnTitles.id')}
            </Typography>
          </IdColumnLabel>
          <PillColumnLabel>
            <Typography color="grey.600" variant="bodySmallBold">
              {t('beneficialOwnersList.columnTitles.kycLevel')}
            </Typography>
          </PillColumnLabel>
          <PillColumnLabel>
            <Typography color="grey.600" variant="bodySmallBold">
              {t('beneficialOwnersList.columnTitles.ownerStatus')}
            </Typography>
          </PillColumnLabel>
        </Row>
        <StyledDivider />
        {formattedBeneficialOwners?.map((owner, index) => (
          <ExpendSectionRow key={owner.id} isLastItem={index === formattedBeneficialOwners.length - 1}>
            <BeneficialOwnersRow
              {...owner}
              isLastItem={index === formattedBeneficialOwners.length - 1}
              onRowClick={onBeneficialOwnerRowClick(navigate)}
            />
          </ExpendSectionRow>
        ))}
      </ExpendSection>
    </>
  );
};
