import React from 'react';
import { CustodialAccountTypeEnum } from 'elements/types';
import { ReactComponent as BusinessAccountIcon } from 'elements/element-transfer/assets/icons/businessAccount.svg';
import { ReactComponent as PersonalAccountIcon } from 'elements/element-transfer/assets/icons/personalAccount.svg';
import i18n from 'localizations';

type AccountData = {
  Icon: React.FC;
  title: string;
};

export const getAccountData = (type: CustodialAccountTypeEnum): AccountData => {
  const mapAccountTypeToIcon = {
    [CustodialAccountTypeEnum.Business]: BusinessAccountIcon,
    [CustodialAccountTypeEnum.Personal]: PersonalAccountIcon,
    [CustodialAccountTypeEnum.RothIra]: PersonalAccountIcon,
    [CustodialAccountTypeEnum.SepIra]: PersonalAccountIcon,
    [CustodialAccountTypeEnum.SimpleIra]: PersonalAccountIcon,
    [CustodialAccountTypeEnum.TraditionalIra]: PersonalAccountIcon,
  };

  const mapAccountTypeToTitle = {
    [CustodialAccountTypeEnum.Business]: i18n.t('elements.common.businessAccount'),
    [CustodialAccountTypeEnum.Personal]: i18n.t('elements.common.personalAccount'),
    [CustodialAccountTypeEnum.RothIra]: i18n.t('elements.common.account'),
    [CustodialAccountTypeEnum.SepIra]: i18n.t('elements.common.account'),
    [CustodialAccountTypeEnum.SimpleIra]: i18n.t('elements.common.account'),
    [CustodialAccountTypeEnum.TraditionalIra]: i18n.t('elements.common.account'),
  };

  const Icon = mapAccountTypeToIcon[type];
  const title = mapAccountTypeToTitle[type];

  return { Icon, title };
};
