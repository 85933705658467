import React, { useEffect } from 'react';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { TransferOptionsEnum, TransferDirectionEnum } from 'elements/element-transfer/types';
import { setPage, useElement } from 'elements/element-transfer/contexts/Element';

const getPageUrl = (transferOptionsList: Array<TransferOptionsEnum>, transferDirection: TransferDirectionEnum) => {
  if (transferOptionsList.length === 1) {
    if (transferOptionsList[0] === TransferOptionsEnum.Ach) {
      return transferDirection === TransferDirectionEnum.Withdrawal
        ? Paths.WithdrawalAchAmount
        : Paths.DepositAchAmount;
    }

    if (transferOptionsList[0] === TransferOptionsEnum.Crypto) {
      return transferDirection === TransferDirectionEnum.Withdrawal
        ? Paths.WithdrawalCryptoAmount
        : Paths.DepositCryptoAddresses;
    }

    if (transferOptionsList[0] === TransferOptionsEnum.Wire) {
      return transferDirection === TransferDirectionEnum.Withdrawal
        ? Paths.WithdrawalWireRecipientSearch
        : Paths.DepositWire;
    }
  }

  return Paths.Transfer;
};

const EntryPage: React.FC = () => {
  const {
    state: { transferDirection, transferOptionsList },
    dispatch,
  } = useElement();

  useEffect(() => {
    dispatch(setPage(getPageUrl(transferOptionsList, transferDirection)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default EntryPage;
