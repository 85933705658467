import { darken, lighten } from '@mui/material';
import { alpha, createTheme, css } from '@mui/material/styles';
import { createStyled } from '@mui/system';
import { Theme } from '@mui/material/styles/createTheme';
import { CUSTOM_THEME_SM_BREAKPOINT, pxToRem } from 'elements/utils';
import { colors } from './constants';

const fontFamily = 'MalloryMP';
const borderRadius = '8px';
const primaryColor = colors.navy;
const primaryFontColor = colors.grey700;
const secondaryColor = colors.azure;
const secondaryFontColor = colors.grey600;
const tertiaryColor = colors.grey200;
const primaryButtonFontColor = colors.white;

const theme = {
  components: {
    MuiCssBaseline: {
      styleOverrides: css`
        *:focus {
          outline: none;
        }

        @media only screen and (min-width: ${CUSTOM_THEME_SM_BREAKPOINT}px) {
          * {
            scrollbar-width: thin;
            scrollbar-color: ${tertiaryColor} transparent;

            &::-webkit-scrollbar {
              width: 10px;
              height: 10px;
              background-color: transparent;
            }

          ,
          &:: -webkit-scrollbar-track {
            background-color: transparent;
          },
          &:: -webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: ${tertiaryColor};
            background-clip: content-box;
            border: 2px solid transparent;
          },
          &:: -webkit-scrollbar-thumb: focus {
            background-color: ${darken(tertiaryColor, 0.2)};
          },
          &:: -webkit-scrollbar-thumb: active {
            background-color: ${darken(tertiaryColor, 0.2)};
          },
          &:: -webkit-scrollbar-thumb: hover {
            background-color: ${darken(tertiaryColor, 0.2)};
          },
          &:: -webkit-scrollbar-corner {
            background-color: transparent;
          },
          }
        }
      `,
    },
  },
  borderRadius,
  fontFamily,
  palette: {
    primary: {
      main: primaryColor,
      mainDarken25: darken(primaryColor, 0.25),
      mainLighten40: lighten(primaryColor, 0.4),
      mainTransparent10: alpha(primaryColor, 0.1),
      mainTransparent50: alpha(primaryColor, 0.5),
      font: primaryFontColor,
      fontTransparent50: alpha(primaryFontColor, 0.5),
      buttonFont: primaryButtonFontColor,
    },
    secondary: {
      main: secondaryColor,
      font: secondaryFontColor,
      mainTransparent10: alpha(secondaryColor, 0.1),
      fontTransparent10: alpha(secondaryFontColor, 0.1),
    },
    tertiary: {
      main: tertiaryColor,
      mainLighten40: lighten(tertiaryColor, 0.4),
      mainDarken20: darken(tertiaryColor, 0.2),
      mainTransparent20: alpha(tertiaryColor, 0.2),
      mainTransparent40: alpha(tertiaryColor, 0.4),
      mainTransparent50: alpha(tertiaryColor, 0.5),
      mainTransparent60: alpha(tertiaryColor, 0.6),
    },
    error: {
      main: colors.errorDefault,
      dark: colors.errorDark,
      light: colors.errorLight,
      light2: colors.errorLight2,
    },
    warning: {
      main: colors.warningDefault,
      dark: colors.warningDark,
      light: colors.warningLight,
      light2: colors.warningLight2,
    },
    success: {
      main: colors.successDefault,
      dark: colors.successDark,
      light: colors.successLight,
      light2: colors.successLight2,
    },
    info: {
      main: colors.infoDefault,
      dark: colors.infoDark,
      light: colors.infoLight,
      light2: colors.infoLight2,
    },
    background: {
      default: colors.white,
      paper: colors.backgroundLight,
      dark: colors.backgroundBlack,
      whiteTransparent50: alpha(colors.white, 0.5),
      // TODO consider to get rid of
      custom: '',
    },
    grey: {
      700: colors.grey700,
      600: colors.grey600,
      500: colors.grey500,
      400: colors.grey400,
      300: colors.grey300,
      200: colors.grey200,
      100: colors.grey100,
      50: colors.grey50,
    },
  },
  typography: {
    h1: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: pxToRem(38),
      lineHeight: '135%',
      letterSpacing: '-0.04em',
    },
    h2: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: pxToRem(32),
      lineHeight: '138%',
      letterSpacing: '-0.03em',
    },
    h3: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: pxToRem(26),
      lineHeight: '138%',
      letterSpacing: '-0.02em',
    },
    h4: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: pxToRem(22),
      lineHeight: '140%',
      letterSpacing: '-0.02em',
    },
    h5: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: pxToRem(20),
      lineHeight: '148%',
      letterSpacing: '-0.02em',
    },
    h6: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: pxToRem(18),
      lineHeight: '148%',
      letterSpacing: '-0.02em',
    },
    signatureDefault: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: pxToRem(10),
      lineHeight: '170%',
    },
    bodyDefaultBook: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: pxToRem(14),
      lineHeight: '170%',
      letterSpacing: '-0.02em',
    },
    bodyDefaultMedium: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: pxToRem(14),
      lineHeight: '170%',
    },
    bodySmallBook: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: pxToRem(12),
      lineHeight: '170%',
    },
    bodySmallBold: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: pxToRem(12),
      lineHeight: '170%',
    },
    bodySmallMedium: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: pxToRem(12),
      lineHeight: '170%',
    },
    subtitleBook: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: pxToRem(15),
      lineHeight: '148%',
    },
    subtitleMedium: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: pxToRem(15),
      lineHeight: '148%',
    },
    captionMedium: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: pxToRem(11),
      lineHeight: '100%',
    },
    overlineDefaultMedium: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: pxToRem(12),
      lineHeight: '133%',
    },
    overlineSmallMedium: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: pxToRem(11),
      lineHeight: '133%',
    },
    buttonSmall: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: pxToRem(13),
      lineHeight: '150%',
    },
    buttonMedium: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: pxToRem(14),
      lineHeight: '150%',
    },
    buttonLarge: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: pxToRem(15),
      lineHeight: '150%',
    },
    bodyDefaultMediumUnderline: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: pxToRem(14),
      lineHeight: '170%',
      textDecoration: 'underline',
    },
    bodySmallMediumUnderline: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: pxToRem(12),
      lineHeight: '170%',
      textDecoration: 'underline',
    },
    button: {
      textTransform: 'none',
    },
  },
};

export type ElementsCustomTheme = Theme & typeof theme;

// @ts-ignore
const defaultTheme = createTheme(theme) as ElementsCustomTheme;

const elementsStyled = createStyled({ defaultTheme });

export { defaultTheme as elementsCustomTheme, elementsStyled };
