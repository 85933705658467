import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Spacer } from 'elements/components';
import { StyledBaseInput, StyledTextButton } from './styled';

type Props = {
  name: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  multiline?: boolean;
  label: string;
  error?: boolean;
  helperText?: string;
  addButtonName?: string;
  maxLength?: number;
};

const MAX_LENGTH = 1000;

const NoteField: React.FC<Props> = ({
  name,
  value,
  onChange,
  onBlur,
  multiline = false,
  error,
  helperText,
  label,
  addButtonName,
  maxLength = MAX_LENGTH,
}) => {
  const [isButtonShown, setIsButtonShown] = useState(!value && !!addButtonName);
  const [currentValue, setCurrentValue] = useState(value);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setCurrentValue(e.target.value.slice(0, maxLength));
  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setCurrentValue(currentValue.trim() && currentValue.slice(0, maxLength));
    if (onBlur) {
      onBlur(e);
    }
  };

  useEffect(() => {
    onChange(currentValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  return isButtonShown ? (
    <StyledTextButton variant="text" onClick={() => setIsButtonShown(false)}>
      <Typography variant="buttonSmall" align="left">
        {addButtonName}
      </Typography>
    </StyledTextButton>
  ) : (
    <>
      {!isButtonShown && addButtonName && <Spacer size={16} />}
      <StyledBaseInput
        name={name}
        value={currentValue}
        label={label}
        error={error}
        helperText={error ? helperText : undefined}
        multiline={multiline}
        rows={multiline ? 3 : 1}
        onChange={onInputChange}
        onBlur={onInputBlur}
      />
      {!error && helperText && (
        <>
          <Spacer size={8} />
          <Typography variant="bodySmallBook" color="secondary.font">
            {helperText}
          </Typography>
        </>
      )}
    </>
  );
};

export default NoteField;
