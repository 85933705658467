import React, { useEffect, useRef, useState } from 'react';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Typography, useTheme } from '@mui/material';
import { ROOT_CONTAINER_MARGIN_MOBILE, isSafari, pxToRem, pxToVw } from 'elements/utils';
import { ElementsCustomTheme } from 'elements/theme/createCustomTheme';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { BaseInputProps } from 'elements/components/BaseInput';
import { StyledSelect } from './styled';

export type BaseSelectProps = BaseInputProps & {
  dropdownMarginHorizontal?: number;
  dropdownHeight?: number;
  dropdownPaddingLeft?: number;
  dropdownPaddingVertical?: number;
  dropdownPaddingRight?: number;
  isDropdownTopRounded?: boolean;
  shouldExcludeTopPadding?: boolean;
  onOpen?: () => void;
  renderValueHandler?: (value: any) => React.ReactElement;
  onClose?: () => void;
  open?: boolean;
  topDropdown?: boolean;
  displayEmpty?: boolean;
};

const DROPDOWN_DEFAULT_STYLES = {
  height: 258,
  paddingLeft: 8,
  paddingVertical: 9,
  paddingRight: 12,
};

const LIST_RIGHT_PADDING = isSafari ? '4px' : '16px';
const CONTENT_BORDER_RADIUS = 8;

const isButton = (el: Element | null): el is HTMLButtonElement =>
  !!el && typeof (el as HTMLButtonElement).blur === 'function';

export const BaseSelect: React.FC<BaseSelectProps> = ({
  children,
  dropdownMarginHorizontal = ROOT_CONTAINER_MARGIN_MOBILE.HORIZONTAL,
  dropdownHeight = DROPDOWN_DEFAULT_STYLES.height,
  dropdownPaddingLeft = DROPDOWN_DEFAULT_STYLES.paddingLeft,
  dropdownPaddingVertical = DROPDOWN_DEFAULT_STYLES.paddingVertical,
  dropdownPaddingRight = DROPDOWN_DEFAULT_STYLES.paddingRight,
  isDropdownTopRounded = true,
  shouldExcludeTopPadding = false,
  value,
  renderValueHandler = original => (
    <Typography variant="bodyDefaultBook" color="primary.font">
      {original}
    </Typography>
  ),
  onOpen,
  onClose,
  open,
  topDropdown,
  displayEmpty,
  ...otherProps
}) => {
  const { palette } = useTheme<ElementsCustomTheme>();
  const { isMobile } = useIsMobile();
  const [isOpen, setIsOpen] = useState(false);

  const menuListRef = useRef<HTMLUListElement | null>(null);
  const baseInputRef = useRef<HTMLDivElement>(null);

  const menuListPaddingStylingSettings = shouldExcludeTopPadding ? 0 : pxToRem(dropdownPaddingVertical);
  const dropdownMenuPropsBorderStylingSettings = isDropdownTopRounded
    ? pxToRem(CONTENT_BORDER_RADIUS)
    : `0 0 ${pxToRem(CONTENT_BORDER_RADIUS)} ${pxToRem(CONTENT_BORDER_RADIUS)}`;

  useEffect(() => {
    if (!isOpen) {
      if (baseInputRef.current) {
        const button = baseInputRef.current.querySelector('[role="button"]');
        if (isButton(button)) {
          button.blur();
        }
      }
    }
  }, [isOpen]);

  const onOpenHandler = () => {
    setIsOpen(true);

    if (onOpen) {
      onOpen();
    }

    setTimeout(() => {
      const selectedItem = menuListRef.current?.querySelector('[aria-selected="true"]');
      selectedItem?.scrollIntoView({ block: 'center' });
    }, 0);
  };

  const onCloseHandler = () => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <StyledSelect
      ref={baseInputRef}
      isSelectBottomRounded={isDropdownTopRounded || !(open ?? isOpen)}
      SelectProps={{
        open: open ?? isOpen,
        onOpen: onOpenHandler,
        onClose: onCloseHandler,
        renderValue: renderValueHandler,
        displayEmpty,
        // eslint-disable-next-line react/no-unstable-nested-components
        IconComponent: props => <ExpandMoreOutlinedIcon {...props} />,
        MenuProps: {
          disableAutoFocus: true,
          disableAutoFocusItem: true,
          keepMounted: true,
          sx: { maxHeight: pxToRem(dropdownHeight), width: 'max-content' },
          ...(topDropdown && {
            anchorOrigin: {
              vertical: 'center',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: dropdownHeight - 5,
              horizontal: 'left',
            },
          }),
          PaperProps: {
            sx: {
              height: pxToRem(dropdownHeight),
              backgroundColor: palette.background.custom || palette.background.default,
              marginTop: '1px',
              borderRadius: dropdownMenuPropsBorderStylingSettings,
              boxShadow: `0px 2px 8px ${palette.tertiary.main}, 0px 6px 16px ${palette.tertiary.main}`,
              maxWidth: `calc(100% - ${pxToVw(2 * dropdownMarginHorizontal)})`,
              paddingRight: isMobile ? `${dropdownPaddingRight}px` : LIST_RIGHT_PADDING,
              overflowY: isSafari ? 'scroll' : 'overlay',
              scrollbarWidth: 'thin',
              scrollbarColor: `${palette.tertiary.main} transparent`,

              '&::-webkit-scrollbar': {
                width: '12px',
                height: '20px',
                backgroundColor: 'transparent',
              },

              '&:: -webkit-scrollbar-track': {
                backgroundColor: 'transparent',
              },
              '&:: -webkit-scrollbar-thumb': {
                minHeight: '30px',
                borderRadius: '6px',
                backgroundColor: palette.tertiary.main,
                backgroundClip: 'content-box',
                border: '4px solid transparent',
              },
              '&:: -webkit-scrollbar-thumb: focus': {
                backgroundColor: palette.tertiary.mainDarken20,
              },
              '&:: -webkit-scrollbar-thumb: active': {
                backgroundColor: palette.tertiary.mainDarken20,
              },
              '&:: -webkit-scrollbar-thumb: hover': {
                backgroundColor: palette.tertiary.mainDarken20,
              },
              '&:: -webkit-scrollbar-corner': {
                backgroundColor: 'transparent',
              },
            },
          },
          MenuListProps: {
            sx: {
              padding: `${menuListPaddingStylingSettings} ${pxToRem(dropdownPaddingLeft)}`,
              paddingRight: 0,
            },
            ref: menuListRef,
          },
        },
      }}
      value={value}
      InputLabelProps={{ shrink: !!value }}
      select
      {...otherProps}
    >
      {children}
    </StyledSelect>
  );
};
