import { styled } from '@mui/material/styles';
import { Row } from 'components/shared/Flex/Row';
import { pxToRem } from 'utils/styling-utils';

export const StyledRow = styled(Row)(({ theme }) => ({
  alignItems: 'center',
  padding: `${pxToRem(theme.gaps._4px)} ${pxToRem(theme.gaps._10px)}`,
  borderRadius: pxToRem(6),
  backgroundColor: theme.palette.secondary.main,
}));
