import React from 'react';
import { Typography } from '@mui/material';
import { Gap } from 'components/shared/Gap';
import { IdentityKycLevel } from 'components/identities/IdentityDetailsPageContent/IdentityKycLevel';
import { StatusCell } from 'components/shared/Cells/StatusCell';
import { IIdentityDetailsPageTitleRow } from 'ui-interfaces/identity-details/i-identity-details-page-title-row';
import { StyledIdentityDetailsTitleRowSkeleton, StyledRow } from './styled';

type Props = {
  data?: IIdentityDetailsPageTitleRow;
  loading: boolean;
};

export const IdentityDetailsPageTitleRow: React.FC<Props> = ({ data, loading }) => {
  if (loading) {
    return <StyledIdentityDetailsTitleRowSkeleton animation="pulse" variant="circular" />;
  }

  if (!data) {
    return null;
  }

  return (
    <StyledRow>
      <Typography variant="h3">{data.title}</Typography>
      <Gap size="_12px" isHorizontal />
      <IdentityKycLevel kycLevel={data.kycLevel} />
      <Gap size="_12px" isHorizontal />
      <StatusCell {...data.statusCellProps} />
    </StyledRow>
  );
};
