import React from 'react';
import { LeftCell } from 'components/shared/detail-page/rows/shared/LeftCell';
import { RowBox } from 'components/shared/detail-page/rows/shared/RowBox';
import { IUiApprovalStatusRow } from 'ui-interfaces/shared/rows/i-ui-approval-status-row';
import { RightCell } from './RightCell';

type Props = {
  data: IUiApprovalStatusRow;
  hasBottomBorder?: boolean;
};

export const ApprovalStatusRow: React.FC<Props> = ({ data, hasBottomBorder = true }) => {
  return (
    <RowBox hasBottomBorder={hasBottomBorder}>
      <LeftCell value={data.label} large={data.largeLeftCell} />
      <RightCell statusCellProps={data.statusCellProps} />
    </RowBox>
  );
};
