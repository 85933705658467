import { useState } from 'react';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { API_BASE_URL } from 'redux/api/api-routes';
import { MessageType, setBannerData } from 'redux/banner';
import { FilterResult } from 'filters/interfaces/filter-result';
import { Auth0ClientSingleton } from 'auth0/auth0-client-singleton';
import { PAGE_SIZE_MUST_BE_LESS_OR_EQUAL_THAN_5000 } from 'redux/api/error-codes';
import { UsePaginatedExportReturnValue, FetchPaginatedCSV } from './interfaces';

export const usePaginatedExport = (): UsePaginatedExportReturnValue => {
  const dispatch = useDispatch();
  const [error, setError] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const downloadPaginatedCsv = async ({ urlSegment, filterResult, preFilterParams, pagination }: FetchPaginatedCSV) => {
    try {
      const auth0 = await Auth0ClientSingleton.getInstance();
      const token = await auth0.getTokenSilently();
      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: 'text/csv',
        },
      };

      setIsLoading(true);

      if (!navigator.onLine) {
        dispatch(setBannerData({ type: MessageType.error, message: i18next.t('offlineNetworkMessage') }));
        setIsLoading(false);
        setError(new Error());
        return;
      }

      let url: string;
      if (filterResult) {
        const { property, operator, value } = filterResult as FilterResult;
        const params: Record<string, string> = {
          ...(preFilterParams ?? {}),
          [`filter[${property} ${operator}]`]: value.trim(),
        };

        if (pagination.page) {
          params.page = pagination.page;
        }

        if (pagination.pageSize) {
          params.pageSize = pagination.pageSize;
        }

        url = `${API_BASE_URL}${urlSegment}?${new URLSearchParams(params)}`;
      } else if (preFilterParams) {
        const params: Record<string, string> = { ...preFilterParams };

        if (pagination.page) {
          params.page = pagination.page;
        }

        if (pagination.pageSize) {
          params.pageSize = pagination.pageSize;
        }

        url = `${API_BASE_URL}${urlSegment}?${new URLSearchParams(params)}`;
      } else {
        const params: Record<string, string> = {};

        if (pagination.page) {
          params.page = pagination.page;
        }

        if (pagination.pageSize) {
          params.pageSize = pagination.pageSize;
        }

        if (Object.keys(params).length > 0) {
          url = `${API_BASE_URL}${urlSegment}?${new URLSearchParams(params)}`;
        } else {
          url = `${API_BASE_URL}${urlSegment}`;
        }
      }

      const res = await fetch(url, {
        ...header,
      });

      if ((res && res.status === 404) || res.status === 503) {
        throw new Error();
      }

      if (res && res.status === 422) {
        const parsedError = await res.json();
        if (parsedError?.errors?.[PAGE_SIZE_MUST_BE_LESS_OR_EQUAL_THAN_5000]) {
          dispatch(
            setBannerData({
              type: MessageType.error,
              message: i18next.t('serverError.pageSizeMustBeLessOrEqualThan500'),
            }),
          );
          return;
        }
      }

      const contentType = res.headers.get('Content-Disposition');
      const fileName = contentType?.split('; ')?.[1]?.split('filename=')?.[1];
      const blob = await res.blob();
      // Create blob link to download
      const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName ?? 'sample.csv');
      // Append to html page
      document.body.appendChild(link);
      // Force download
      link.click();
      // Clean up and remove the link
      link.parentNode?.removeChild(link);
      setIsLoading(false);
    } catch (err) {
      setError(err);
      dispatch(setBannerData({ type: MessageType.error, message: i18next.t('serverErrorBanner') }));
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, error, downloadPaginatedCsv };
};
