import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { transferElement } from 'elements/element-transfer/api';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { TransferFlow } from 'elements/element-transfer/types';
import ContentBlock from 'elements/element-transfer/components/Deposit/AchReviewPage';
import {
  setAchDepositPaymentId,
  setIsOrderPlaced,
  setPage,
  useElement,
} from 'elements/element-transfer/contexts/Element';
import { ConfirmModal } from 'elements/components/ConfirmModal';
import { OnCloseElement } from 'elements/models/types/element-result';
import { isReachedLimits } from 'elements/api';
import { setErrorBanner } from 'elements/utils';

const AchReviewPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isProcessingLoading, setIsProcessingLoading] = useState(false);
  const [isProcessingError, setIsProcessingError] = useState(false);
  const [processingErrorMessage, setProcessingErrorMessage] = useState('');

  const { state, dispatch } = useElement();
  const achDepositState = state[TransferFlow.Deposit].ACH;

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const onConfirmDecline = () => setIsConfirmModalOpen(false);

  const onCustomClose = () => setIsConfirmModalOpen(true);

  const onBack = (): void => {
    dispatch(setPage(Paths.DepositAchAmount));
  };

  const onDeposit = () => {
    if (!achDepositState?.paymentAccount || !state.custodialAccount) {
      return;
    }

    setIsProcessingLoading(true);

    if (!achDepositState) {
      return;
    }

    transferElement
      .processDeposit({
        sourceExternalAccountId: achDepositState.paymentAccount.id,
        destinationCustodialAccountId: state.custodialAccount.id,
        amount: +achDepositState.amount,
      })
      .then(result => {
        setIsProcessingLoading(false);
        setIsProcessingError(false);
        setProcessingErrorMessage('');

        if (result?.id) {
          dispatch(setAchDepositPaymentId(result?.id));
        }
        dispatch(setIsOrderPlaced(true));
        dispatch(setPage(Paths.DepositAchPlaced));
      })
      .catch(e => {
        setIsProcessingLoading(false);
        setIsProcessingError(true);

        if (isReachedLimits(e)) {
          setProcessingErrorMessage(t('serverError.transactionlimitsError'));
        }

        setErrorBanner(e, enqueueSnackbar, false);
      });
  };

  useEffect(() => {
    if (state.isOrderPlaced) {
      dispatch(setPage(Paths.DepositAchPlaced));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!achDepositState) return null;

  return (
    <>
      <ContentBlock
        depositAmount={+achDepositState.amount}
        onClose={onCustomClose}
        onBack={onBack}
        onDeposit={onDeposit}
        isProcessingLoading={isProcessingLoading}
        isProcessingError={isProcessingError}
        processingErrorMessage={processingErrorMessage}
        paymentAccount={achDepositState.paymentAccount}
        custodialAccount={state.custodialAccount}
      />
      {isConfirmModalOpen && <ConfirmModal onConfirm={onClose} onDecline={onConfirmDecline} />}
    </>
  );
};

export default AchReviewPage;
