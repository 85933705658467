import React, { ForwardedRef, forwardRef } from 'react';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import Content from './Content';

type Props = {
  loading?: boolean;
  label: string;
  value: string;
  icon: React.ReactNode;
  large?: boolean;
  tooltip?: string;
};

export const HeaderDetailsItem = forwardRef(
  (
    { label, loading = false, value, icon, large = false, tooltip, ...restProps }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    if (!value && !loading) {
      return null;
    }
    const content = (
      <Content {...restProps} ref={ref} loading={loading} label={label} value={value} icon={icon} large={large} />
    );

    if (!tooltip) {
      return content;
    }

    return (
      <CommonTooltip placement="top" title={tooltip}>
        {content}
      </CommonTooltip>
    );
  },
);
