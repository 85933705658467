import i18n from 'localizations';
import { BusinessIdentityDetailsResponseModel } from 'models/response/identities/business/business-identity-details-response-model';
import { formatDate } from 'utils/format-date';
import { formatIdentityPhone } from 'utils/identities/format-identity-phone';
import { addHTTPStoUrlIfNeeded } from 'utils/add-HTTPS-to-url-if-needed';
import { mapIdentityStatusModelToStatusCellProps } from 'utils/multi-mapping/statuses/map-identity-status-model-to-status-cell-props';
import { formatIdentityAddress } from 'utils/identities/format-identity-address';
import { IIdentityDetailsPage } from 'ui-interfaces/identity-details/i-identity-details-page';
import { IdentityDetailRowType } from 'ui-enums/identities/identity-detail-row-type';
import { IPlainRow } from 'ui-interfaces/identity-details/rows/i-plain-row';
import { ILinkRow } from 'ui-interfaces/identity-details/rows/i-link-row';
import { Valued } from 'ui-interfaces/shared/valued';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { formatIdentityIndustry } from 'utils/identities/format-identity-industry';
import { IdentityDetailsBaseViewModel } from './base/identity-details-base-vm';

export class IdentityDetailsBusinessViewModel extends IdentityDetailsBaseViewModel implements IIdentityDetailsPage {
  constructor(identity: BusinessIdentityDetailsResponseModel, user: ShortUserResponseModel) {
    super(identity, user);

    this.pageTitle = {
      title: `${identity.businessInfo.companyName}`,
      kycLevel: identity.kycLevel,
      statusCellProps: mapIdentityStatusModelToStatusCellProps(identity.status, !identity.isSuspendedByIntegrator),
    };

    this.nodes = [
      {
        id: 'identityInfoSection',
        items: [
          {
            id: 'companyName',
            rowType: IdentityDetailRowType.plain,
            label: i18n.t('identityDetailsPage.detailsLabels.companyName'),
            value: identity.businessInfo.companyName,
          } as IPlainRow,
          {
            id: 'phoneNumber',
            rowType: IdentityDetailRowType.plain,
            label: i18n.t('identityDetailsPage.detailsLabels.phoneNumber'),
            value: formatIdentityPhone(user, identity.businessInfo.phone),
          } as IPlainRow,
          {
            id: 'email',
            rowType: IdentityDetailRowType.plain,
            label: i18n.t('identityDetailsPage.detailsLabels.email'),
            value: identity.businessInfo.email,
          } as IPlainRow,
          {
            id: 'webSite',
            rowType: IdentityDetailRowType.link,
            label: i18n.t('identityDetailsPage.detailsLabels.webSite'),
            value: identity.businessInfo.website ? addHTTPStoUrlIfNeeded(identity.businessInfo.website) : '',
            url: identity.businessInfo.website ? addHTTPStoUrlIfNeeded(identity.businessInfo.website) : '',
          } as ILinkRow,
          {
            id: 'ein',
            rowType: IdentityDetailRowType.plain,
            label: i18n.t('identityDetailsPage.detailsLabels.ein'),
            value: identity.businessInfo.ein,
          } as IPlainRow,
          {
            id: 'address',
            rowType: IdentityDetailRowType.textMultiLine,
            label: i18n.t('identityDetailsPage.detailsLabels.address'),
            value: formatIdentityAddress(user, identity.address),
          } as IPlainRow,
          {
            id: 'regionOfFormation',
            rowType: IdentityDetailRowType.plain,
            label: i18n.t('identityDetailsPage.detailsLabels.regionOfFormation'),
            value: identity.businessInfo.regionOfFormation,
          } as IPlainRow,
          {
            id: 'description',
            rowType: IdentityDetailRowType.plain,
            label: i18n.t('identityDetailsPage.detailsLabels.description'),
            value: identity.businessInfo.description,
          } as IPlainRow,
          {
            id: 'establishedOn',
            rowType: IdentityDetailRowType.plain,
            label: i18n.t('identityDetailsPage.detailsLabels.establishedOn'),
            value: identity.businessInfo.establishedOn ? formatDate(identity.businessInfo.establishedOn) : '',
          } as IPlainRow,
          {
            id: 'legalStructure',
            rowType: IdentityDetailRowType.plain,
            label: i18n.t('identityDetailsPage.detailsLabels.legalStructure'),
            value: i18n.t(`identityDetailsPage.legalStructure.${identity.businessInfo.legalStructure}`),
          } as IPlainRow,
          {
            id: 'mailingAddress',
            rowType: IdentityDetailRowType.textMultiLine,
            label: i18n.t('identityDetailsPage.detailsLabels.mailingAddress'),
            value: formatIdentityAddress(user, identity.mailingAddress),
          } as IPlainRow,
          {
            id: 'naics',
            rowType: IdentityDetailRowType.plain,
            label: i18n.t('identityDetailsPage.detailsLabels.naics'),
            value: identity.businessInfo.naics,
          } as IPlainRow,
          {
            id: 'naicsDescription',
            rowType: IdentityDetailRowType.plain,
            label: i18n.t('identityDetailsPage.detailsLabels.naicsDescription'),
            value: identity.businessInfo.naicsDescription,
          } as IPlainRow,
          {
            id: 'industry',
            rowType: IdentityDetailRowType.textMultiLine,
            label: i18n.t('identityDetailsPage.detailsLabels.industry'),
            value: formatIdentityIndustry(identity),
          } as IPlainRow,
        ].filter(i => {
          if ('value' in i) {
            return !!(i as Valued).value;
          }

          return true;
        }),
      },
    ];
  }
}
