import React, { CSSProperties } from 'react';

type CountryImageProps = {
  code: string;
  label: string;
  width?: number;
  height?: number;
  borderRadius?: number;
  margin?: CSSProperties['margin'];
};

// extend list if you need flags with higher resolution, pattern: newHigherValue = prevHigher * 2
const allowedWidthsList = [20, 40, 80];

export const CountryImage: React.FC<CountryImageProps> = ({
  code,
  label,
  width = 20,
  height,
  borderRadius = 1,
  margin = '0 10px 0 0',
}) => {
  // the closest allowed width according to the input width
  const closestAllowedWidth = allowedWidthsList.reduce((prev, curr) =>
    Math.abs(curr - width) < Math.abs(prev - width) ? curr : prev,
  );
  return (
    <img
      width={`${width}px`}
      height={height ? `${height}px` : 'auto'}
      style={{ borderRadius, margin }}
      src={`https://flagcdn.com/w${closestAllowedWidth}/${code.toLowerCase()}.png`}
      srcSet={`https://flagcdn.com/w${2 * closestAllowedWidth}/${code.toLowerCase()}.png 2x`}
      alt={label}
    />
  );
};
