import { useState } from 'react';
import { useSnackbar } from 'notistack';
import * as api from 'elements/element-trade/api';
import { setErrorBanner } from 'elements/utils';
import {
  setBuySellCoinsList,
  setBuySellSelectedCoin,
  setCustodialAccountId,
  useElement,
} from 'elements/element-trade/contexts/Element';
import { TradeDirectionEnum, TradeFlow } from 'elements/element-trade/types';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import { AssetResponseModel } from 'models/response/assets/asset-response-model';

export const enum RequestCoinListStatus {
  Init = 'Init',
  Loading = 'Loading',
  Success = 'Success',
  Failed = 'Failed',
}

const useRequestCoinsList = (): {
  requestCoinList: (flowType: TradeFlow) => Promise<void>;
  requestCoinListStatus: RequestCoinListStatus;
} => {
  const { enqueueSnackbar } = useSnackbar();
  const [requestCoinListStatus, setRequestCoinListStatus] = useState(RequestCoinListStatus.Init);
  const { state, dispatch } = useElement();

  const requestCoinList = async (flowType: TradeFlow) => {
    try {
      setRequestCoinListStatus(RequestCoinListStatus.Loading);

      let coins: AssetResponseModel[] | CustodialAccountBalanceModel[];
      if (flowType === TradeFlow.Buy) {
        coins = await api.tradeElement.getBuyCoinsData();
      } else {
        coins = await api.tradeElement.getSellCoinsData(state.custodialAccountId);
        dispatch(setCustodialAccountId(state.custodialAccountId));
      }

      dispatch(setBuySellCoinsList(coins, flowType));
      if (coins.length) {
        const defaultCoin = coins[0];
        dispatch(setBuySellSelectedCoin(defaultCoin, flowType));
      }
      setRequestCoinListStatus(RequestCoinListStatus.Success);
    } catch (e) {
      const isBannerShown =
        state.tradeDirection === TradeDirectionEnum.BuySell &&
        flowType === TradeFlow.Sell &&
        setErrorBanner(e, enqueueSnackbar);
      setRequestCoinListStatus(isBannerShown ? RequestCoinListStatus.Init : RequestCoinListStatus.Failed);
    }
  };

  return { requestCoinList, requestCoinListStatus };
};

export default useRequestCoinsList;
