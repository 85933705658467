import { TransactionBaseViewModel } from 'view-models/transactions/list/transaction-base-vm';
import { TransactionsRow } from 'ui-interfaces/row-interfaces/transactions';
import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { getSignOfTransaction } from 'utils/transactions/get-sign-of-transaction';

export class TransactionBasePrivateSecurityViewModel extends TransactionBaseViewModel implements TransactionsRow {
  readonly amount: string;

  constructor(transaction: TransactionResponseModel) {
    super(transaction);
    this.amount = `${getSignOfTransaction(transaction)}${transaction.amount ?? 0}`;
  }
}
