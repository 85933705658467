import { UserRoleTemplateResponseModel } from 'models/response/users/user-role-template-response-model';
import { IRoleRadio } from 'ui-interfaces/user-form/i-role-radio';
import { UserFormInput } from 'ui-enums/create-user-form/user-form-input';
import { getRoleTemplateRadioLabels } from './get-role-template-radio-labels';

export const generateRoles = (userRoleTemplateResponseModels: UserRoleTemplateResponseModel[]): IRoleRadio[] => {
  return userRoleTemplateResponseModels.map((el, index) => {
    const { title, description } = getRoleTemplateRadioLabels(el.roleTemplate);

    return {
      id: el.roleTemplate,
      uiElementType: UserFormInput.radio,
      value: index,
      title,
      description,
    };
  });
};
