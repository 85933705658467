import { NavigateFunction } from 'react-router-dom';
import { getRouteWithParams } from 'utils/get-route-with-params';
import { NESTED_PATHS } from 'navigation/constants';
import { BeneficialOwnerDetailsUnderIdentityDetailsUrlParams } from 'navigation/url-params-type';
import { IdentityType } from 'models/enums/identities/identity-type';
import { BeneficialOwnerRow } from 'ui-interfaces/row-interfaces/identities/beneficial-owner-row';

export const onBeneficialOwnerRowClick =
  (navigate: NavigateFunction) =>
  ({ id, ownerType, businessIdentityId }: Pick<BeneficialOwnerRow, 'id' | 'businessIdentityId' | 'ownerType'>) =>
    navigate(
      getRouteWithParams(NESTED_PATHS.IDENTITY_DETAILS__BENEFICIAL_OWNER_DETAILS, {
        identityId: businessIdentityId,
        identityType: IdentityType.business,
        parentIdentityId: id,
        parentIdentityType: ownerType,
      } as BeneficialOwnerDetailsUnderIdentityDetailsUrlParams),
    );
