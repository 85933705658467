import { styled } from '@mui/material/styles';
import { ClassAttributes } from 'react';
import { pxToRem } from 'utils/styling-utils';

export const StyledImage = styled('img', {
  shouldForwardProp: prop => prop !== 'size',
})<ClassAttributes<HTMLImageElement> & { size: number }>(({ size }) => ({
  width: pxToRem(size),
  height: pxToRem(size),
}));
