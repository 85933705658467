export enum TransactionsFilterProperty {
  id = 'id',
  paymentId = 'paymentId',
  externalCreatedAt = 'externalCreatedAt',
  paymentType = 'paymentType',
  sourceDisplayValue = 'sourceDisplayValue',
  destinationDisplayValue = 'destinationDisplayValue',
  customAssetDisplayValue = 'customAsset.displayName',
  status = 'status',
  currency = 'currency',
  amountDecimal = 'amountDecimal',
}
