import React from 'react';
import { Link as _Link } from '@react-pdf/renderer';
import { RightCellBox } from 'components/tapi/transactions/TapiTransactionDetailsPage/pdf/rows/shared/RightCellBox';
import { styles } from './styles';

const PDFLink: any = _Link;

type Props = {
  value: string;
  url?: string;
};

export const RightCell: React.FC<Props> = ({ value, url }) => {
  return (
    <RightCellBox>
      <PDFLink src={url} style={styles.link} target="_blank" rel="noopener noreferrer">
        {value}
      </PDFLink>
    </RightCellBox>
  );
};
