import React, { ForwardedRef, forwardRef } from 'react';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import Content from './Content';

type Props = {
  loading?: boolean;
  label: string;
  url: string;
  urlDisplayText?: string;
  icon: React.ReactNode;
  large?: boolean;
  tooltip?: string;
};

export const HeaderDetailsLinkItem = forwardRef(
  (
    { label, loading = false, url, urlDisplayText, icon, large = false, tooltip, ...restProps }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    if (!url && !loading) {
      return null;
    }
    const content = (
      <Content
        {...restProps}
        ref={ref}
        loading={loading}
        label={label}
        urlDisplayText={urlDisplayText}
        url={url}
        icon={icon}
        large={large}
      />
    );

    if (!tooltip) {
      return content;
    }

    return (
      <CommonTooltip placement="top" title={tooltip}>
        {content}
      </CommonTooltip>
    );
  },
);
