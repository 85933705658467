import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { Backdrop, Typography, useTheme } from '@mui/material';
import { Button, Divider, InlineBanner, NoteReview, Spacer } from 'elements/components';
import { CustodialAccount } from 'elements/types';
import { formatUsdBalance } from 'elements/utils';
import { ReactComponent as RoundedArrowIcon } from 'elements/element-transfer/assets/icons/roundedArrow.svg';
import Layout from 'elements/element-transfer/components/Layout';
import TransactionSummary from 'elements/element-transfer/components/TransactionSummary';
import WireSource from 'elements/element-transfer/components/Withdrawal/WireSource';
import WireTo from 'elements/element-transfer/components/Withdrawal/WireTo';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { ElementsCustomTheme } from 'elements/theme/createCustomTheme';
import PurposeOfPayment from '../PurposeOfPayment';

type Props = {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  errorTraceId: string;
  amount: string;
  recipientName: string;
  bankName: string;
  bankCountry: string;
  accountNumber: string;
  custodialAccount: CustodialAccount | null;
  note: string;
  purposeOfPayment: string;
  isDomestic: boolean;
  memo: string;
  onBack: () => void;
  onClose: () => void;
  onWire: () => void;
};

const WireReviewPage: React.FC<Props> = ({
  loading,
  error,
  errorMessage,
  errorTraceId,
  amount,
  recipientName,
  bankName,
  bankCountry,
  accountNumber,
  custodialAccount,
  note,
  purposeOfPayment,
  isDomestic,
  memo,
  onBack,
  onClose,
  onWire,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const theme = useTheme<ElementsCustomTheme>();

  const formattedAmount = formatUsdBalance(Number(amount), isMobile);

  return (
    <Layout
      title={t('elements.transfer.customizableLabels.withdrawalWireReviewPageTitle')}
      onBack={onBack}
      onClose={onClose}
      footerButtons={
        <Button isLoading={loading} onClick={onWire}>
          <Typography variant="buttonLarge">
            {t('elements.transfer.withdrawalWireReviewPage.wireButton', {
              amount: formattedAmount,
            })}
          </Typography>
          <CheckIcon />
        </Button>
      }
    >
      {error && (
        <>
          <InlineBanner
            text={errorMessage || t('elements.transfer.withdrawalWireReviewPage.processingError')}
            errorTraceId={errorTraceId}
          />
          <Spacer size={isMobile ? 24 : 16} />
        </>
      )}
      <Backdrop open={loading} sx={{ backgroundColor: theme.palette.background.whiteTransparent50, zIndex: 5 }} />
      <TransactionSummary
        icon={RoundedArrowIcon}
        amount={formattedAmount}
        amountLabel={t('elements.transfer.withdrawalWireReviewPage.withdrawalAmount')}
        totalAmount={formattedAmount}
        totalLabel={t('elements.common.total')}
      />
      <Spacer size={40} />
      <WireTo
        recipientName={recipientName}
        bankName={bankName}
        bankCountry={bankCountry}
        accountNumber={accountNumber}
      />
      <Spacer size={isMobile ? 32 : 24} />
      {!!custodialAccount && (
        <>
          <WireSource custodialAccount={custodialAccount} />
          <Spacer size={isMobile ? 32 : 24} />
        </>
      )}
      {!!purposeOfPayment && !isDomestic && (
        <>
          <PurposeOfPayment text={purposeOfPayment} />
          <Divider />
          <Spacer size={isMobile ? 32 : 24} />
        </>
      )}
      {!!memo && (
        <>
          <NoteReview note={memo} title={t('elements.transfer.withdrawalWireReviewPage.memoTitle')} withMessageIcon />
          <Divider />
          <Spacer size={8} />
        </>
      )}
      {!!note && (
        <>
          <NoteReview note={note} title={t('elements.transfer.withdrawalWireReviewPage.internalNoteTitle')} />
          <Divider />
          <Spacer size={32} />
        </>
      )}
    </Layout>
  );
};

export default WireReviewPage;
