import { TransactionBaseViewModel } from 'view-models/transactions/list/transaction-base-vm';
import { CompoundLabel, TransactionsRow } from 'ui-interfaces/row-interfaces/transactions';
import { LabelLength } from 'ui-enums/label-length';
import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { getSignOfTransaction } from 'utils/transactions/get-sign-of-transaction';
import { getDynamicAssetLabel } from 'utils/labels-mapping/get-dynamic-asset-label';

export class TransactionBaseCryptoViewModel extends TransactionBaseViewModel implements TransactionsRow {
  amount: CompoundLabel | string;

  constructor(transaction: TransactionResponseModel) {
    super(transaction);
    this.amount = `${getSignOfTransaction(transaction)}${transaction.cryptoAmount ?? 0} ${getDynamicAssetLabel(
      LabelLength.short,
      transaction.assetTicker,
      transaction.assetName,
    )}`;
  }
}
