import { AssetByNetworkIconSize } from 'ui-enums/asset-by-network-icon-size';

export const mapAssetByNetworkIconSizeToNumber = (a: AssetByNetworkIconSize): number => {
  switch (a) {
    case AssetByNetworkIconSize['56px']:
      return 56;
    case AssetByNetworkIconSize['40px']:
      return 40;
    case AssetByNetworkIconSize['36px']:
      return 36;
    case AssetByNetworkIconSize['34px']:
      return 34;
    case AssetByNetworkIconSize['28px']:
      return 28;
    default:
      return 0;
  }
};
