import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { LayoutModal, Modal, Spacer, Stack } from 'elements/components';
import { LoadingPage } from 'elements/features/LoadingErrorState';
import { CustodialAccount, ExternalAccount, PaymentAccount } from 'elements/types';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import AddPayment from './AddPayment';
import FooterButtons from './FooterButtons';
import PaymentReview from './PaymentReview';
import {
  StyledCloseButton,
  StyledContainer,
  StyledFooterContainer,
  StyledHeadline,
  StyledScrollableContainer,
} from './styled';

type Props = {
  isLoading: boolean;
  withCustodialAccounts?: boolean;
  withCardAccounts?: boolean;
  withBankAccounts?: boolean;
  currentPaymentAccount: PaymentAccount | null;
  custodialAccountList?: CustodialAccount[];
  externalAccountList: ExternalAccount[];
  onPaymentAccountSelect: (paymentAccountId: string) => void;
  onOpenAddBankAccount: () => void;
  onClose: () => void;
  outletOnClose: () => void;
};

const PaymentMethods: React.FC<Props> = ({
  isLoading,
  withCustodialAccounts = true,
  withCardAccounts = true,
  withBankAccounts = true,
  currentPaymentAccount,
  custodialAccountList = [],
  externalAccountList,
  onPaymentAccountSelect,
  onOpenAddBankAccount,
  onClose: onCloseProp,
  outletOnClose,
}) => {
  const [currentPaymentAccountId, setCurrentPaymentAccount] = useState<string>(currentPaymentAccount?.id || '');
  const [isReview, setIsReview] = useState(true);
  const { t } = useTranslation();

  const { isMobile } = useIsMobile();

  const onAddPaymentMethod = () => {
    setIsReview(false);
  };

  const onClose = () => {
    onPaymentAccountSelect(currentPaymentAccountId);
    setIsReview(true);
    onCloseProp();
  };

  const onBack = () => {
    if (isReview) {
      onClose();
    } else {
      setIsReview(true);
    }
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div>
      {isMobile ? (
        <Modal open onClose={onClose} alignBottom>
          <StyledContainer>
            <StyledHeadline>
              <Typography variant="h6" color="primary.font">
                {t('elements.features.paymentMethods.title')}
              </Typography>
              <StyledCloseButton onClick={onClose}>
                <CloseIcon />
              </StyledCloseButton>
            </StyledHeadline>
            <StyledScrollableContainer>
              {isReview ? (
                <PaymentReview
                  withCustodialAccounts={withCustodialAccounts}
                  withCardAccounts={withCardAccounts}
                  withBankAccounts={withBankAccounts}
                  currentPaymentAccountId={currentPaymentAccountId}
                  custodialAccountList={custodialAccountList}
                  externalAccountList={externalAccountList}
                  onCurrentPaymentAccountIdChange={setCurrentPaymentAccount}
                />
              ) : (
                <>
                  <AddPayment onOpenAddBankAccount={onOpenAddBankAccount} withBankAccounts={withBankAccounts} />
                  <Spacer size={44} />
                </>
              )}
              <Spacer size={16} />
            </StyledScrollableContainer>
            {isReview && (
              <StyledFooterContainer>
                <FooterButtons
                  spaceBetween
                  withAddPaymentMethod={withBankAccounts}
                  onAddPaymentMethod={onAddPaymentMethod}
                  onDoneClick={onClose}
                />
              </StyledFooterContainer>
            )}
          </StyledContainer>
        </Modal>
      ) : (
        <LayoutModal
          title={t('elements.features.paymentMethods.title')}
          withHeader
          isAbove
          onBack={onBack}
          onClose={outletOnClose}
          withFooter
          subFooter={
            isReview && (
              <Stack fullWidth gap="16px">
                <FooterButtons
                  withAddPaymentMethod={withBankAccounts}
                  onAddPaymentMethod={onAddPaymentMethod}
                  onDoneClick={onClose}
                />
              </Stack>
            )
          }
        >
          {isReview ? (
            <PaymentReview
              withCustodialAccounts={withCustodialAccounts}
              withCardAccounts={withCardAccounts}
              withBankAccounts={withBankAccounts}
              currentPaymentAccountId={currentPaymentAccountId}
              custodialAccountList={custodialAccountList}
              externalAccountList={externalAccountList}
              onCurrentPaymentAccountIdChange={setCurrentPaymentAccount}
            />
          ) : (
            <AddPayment onOpenAddBankAccount={onOpenAddBankAccount} withBankAccounts={withBankAccounts} />
          )}
        </LayoutModal>
      )}
    </div>
  );
};

export { PaymentMethods };
