import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Typography } from '@mui/material';
import { StyledLink, StyledStack, StyledTypography } from './styled';
import { BannerType, InlineBannerProps } from './types';

const mapBannerTypeToIcon = {
  [BannerType.Error]: <ErrorOutlineIcon data-testid="error-outlined-icon-id" />,
  [BannerType.Info]: <InfoOutlinedIcon data-testid="info-outlined-icon-id" />,
  [BannerType.Warning]: <WarningAmberOutlinedIcon data-testid="warning-outlined-icon-id" />,
};

// to display a link to a new tab buttonTitle and linkHref should be provided
// to display a button buttonTitle and onClick should be provided
// if only buttonTitle is provided then it will be displayed as regular text (is used in case when integrator has not provided a link)
const InlineBanner: React.FC<InlineBannerProps> = ({
  children,
  icon,
  withIcon = true,
  type = BannerType.Error,
  text,
  text2,
  linkHref,
  buttonTitle,
  onClick,
  className,
  errorTraceId,
}) => {
  const { t } = useTranslation();
  const linkProps = linkHref
    ? {
        href: linkHref,
        target: '_blank',
        rel: 'noreferrer',
      }
    : { onClick };

  return (
    <StyledStack flexDirection="row" alignCenter gap="8px" className={className} type={type}>
      {withIcon && (icon || mapBannerTypeToIcon[type])}

      {buttonTitle ? (
        <span>
          {text && <Typography variant="bodySmallBook">{text}</Typography>}
          {linkHref || onClick ? (
            <StyledLink {...linkProps} bannerType={type}>
              <StyledTypography variant="bodySmallMedium">{buttonTitle}</StyledTypography>
            </StyledLink>
          ) : (
            <StyledTypography variant="bodySmallBook">{buttonTitle}</StyledTypography>
          )}
          {text2 && <Typography variant="bodySmallBook">{text2}</Typography>}
        </span>
      ) : (
        <span>
          <Typography variant="bodySmallBook">{text}</Typography>
          {errorTraceId && (
            <Typography variant="bodySmallBold">
              {` ${t('elements.common.traceId')}: `}
              <u>{errorTraceId}</u>
            </Typography>
          )}
        </span>
      )}

      {children}
    </StyledStack>
  );
};

export { InlineBanner, BannerType };
