export enum BusinessIdentityIndustry {
  b2B = 'b2B',
  b2C = 'b2C',
  accounting = 'accounting',
  aerospace = 'aerospace',
  agriculture = 'agriculture',
  aquaticsMarine = 'aquaticsMarine',
  automotive = 'automotive',
  businessServices = 'businessServices',
  charity = 'charity',
  computerSoftware = 'computerSoftware',
  construction = 'construction',
  cryptoBlockchain = 'cryptoBlockchain',
  education = 'education',
  energy = 'energy',
  engineering = 'engineering',
  entertainment = 'entertainment',
  environmentGreen = 'environmentGreen',
  farming = 'farming',
  fashion = 'fashion',
  financialServices = 'financialServices',
  franchise = 'franchise',
  gaming = 'gaming',
  gigEconomy = 'gigEconomy',
  government = 'government',
  hospitality = 'hospitality',
  informationTechnology = 'informationTechnology',
  insurance = 'insurance',
  internet = 'internet',
  international = 'international',
  lawEnforcementMilitary = 'lawEnforcementMilitary',
  lifestyle = 'lifestyle',
  manufacturing = 'manufacturing',
  marketingServices = 'marketingServices',
  mediaPublishing = 'mediaPublishing',
  medical = 'medical',
  mobileApps = 'mobileApps',
  music = 'music',
  naturalResources = 'naturalResources',
  networkMarketing = 'networkMarketing',
  performingArts = 'performingArts',
  personalServices = 'personalServices',
  professionalServices = 'professionalServices',
  realEstate = 'realEstate',
  recreation = 'recreation',
  retail = 'retail',
  royalties = 'royalties',
  science = 'science',
  securities = 'securities',
  shippingTransportation = 'shippingTransportation',
  software = 'software',
  storageWarehousing = 'storageWarehousing',
  supplier = 'supplier',
  technology = 'technology',
  transportation = 'transportation',
  wholesale = 'wholesale',
  other = 'other',
  art = 'art',
}
