import { styled, css } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Column } from 'components/shared/Flex/Column';
import { pxToRem } from 'utils/styling-utils';

// ui containers
export const CommonLayoutContainer = styled(Column)(
  () => css`
    height: 100%;
  `,
);

export const CommonLayoutHeader = styled(Column)(
  ({ theme }) => css`
    background-color: ${theme.palette.background.paper};
    border-bottom: 1px solid ${theme.palette.grey['200']};
  `,
);

export const CommonLayoutContent = styled(Column)(
  ({ theme }) => css`
    height: 100%;
    background-color: ${theme.palette.background.default};
  `,
);

// elements
export const CommonGridBox = styled(Box)(({ theme }) => ({
  height: '100%',
  paddingTop: pxToRem(theme.gaps._24px),
  paddingBottom: pxToRem(theme.gaps._48px),
  paddingInline: pxToRem(theme.gaps._32px),
}));

export const CommonPageTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  paddingInline: pxToRem(theme.gaps._32px),
}));

export const CommonAutoHeightGridBox = styled(Box)(({ theme }) => ({
  paddingTop: pxToRem(theme.gaps._24px),
  paddingInline: pxToRem(theme.gaps._32px),
}));
