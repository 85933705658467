import { Auth0Client } from '@auth0/auth0-spa-js';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Auth0ClientSingleton } from 'auth0/auth0-client-singleton';
import { API_BASE_URL } from 'redux/api/api-routes';
import { CustomError } from './errors/customError';
import { GenericError } from './types';

const LOGIN_REQUIRED = 'login_required';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});
const requestParser = <T>(result: AxiosResponse<T>): T => result.data;

const errorHandler = <T>(error: AxiosError<T>): Promise<never> =>
  // @ts-ignore TODO resolve an error
  Promise.reject(new CustomError(error as GenericError));

axiosInstance.interceptors.request.use(async request => {
  let auth0: Auth0Client | undefined;
  try {
    auth0 = await Auth0ClientSingleton.getInstance();
    const accessToken = await auth0.getTokenSilently();
    if (request.headers && accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    }
  } catch (error) {
    // @ts-ignore
    if (error.error === LOGIN_REQUIRED) {
      await auth0?.logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    }
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return request;
  }
}, errorHandler);

axiosInstance.interceptors.response.use(requestParser, errorHandler);

export { axiosInstance };
