import { TransferDirectionEnum } from 'elements/element-transfer/types';

const sanitizeTransferDirection = (transferFlow?: string): TransferDirectionEnum => {
  if (!transferFlow) {
    return TransferDirectionEnum.DepositWithdrawal;
  }

  if (transferFlow.toLocaleLowerCase() === TransferDirectionEnum.Deposit.toLowerCase()) {
    return TransferDirectionEnum.Deposit;
  }

  if (transferFlow.toLocaleLowerCase() === TransferDirectionEnum.Withdrawal.toLowerCase()) {
    return TransferDirectionEnum.Withdrawal;
  }

  return TransferDirectionEnum.DepositWithdrawal;
};

export { sanitizeTransferDirection };
