import React, { useState } from 'react';
import { Dialog, DialogContent, useTheme } from '@mui/material';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { ConfirmModal } from 'elements/components/ConfirmModal';
import { ElementsCustomTheme } from 'elements/theme/createCustomTheme';
import { OnCloseElement } from 'elements/models/types/element-result';

type Props = {
  onClose: OnCloseElement;
  isCloseConfirmationRequired: boolean;
};

export const ElementDialog: React.FC<Props> = ({ children, onClose, isCloseConfirmationRequired }) => {
  const theme = useTheme<ElementsCustomTheme>();
  const { isMobile } = useIsMobile();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const onConfirmDecline = () => setIsConfirmModalOpen(false);

  return (
    <Dialog
      open
      onClose={() => {
        if (isCloseConfirmationRequired) {
          setIsConfirmModalOpen(true);
        } else {
          onClose();
        }
      }}
      fullScreen={isMobile}
      PaperProps={
        !isMobile
          ? {
              style: {
                height: '856px',
                width: '552px',
                margin: 0,
                maxHeight: '100%',
              },
            }
          : {}
      }
    >
      <DialogContent
        sx={{
          height: '100%',
          display: 'flex',
          padding: 0,
          overflow: 'hidden',
          backgroundColor: theme.palette.background.default,
          position: 'relative',
        }}
      >
        {children}
      </DialogContent>
      {isConfirmModalOpen && <ConfirmModal onConfirm={onClose} onDecline={onConfirmDecline} />}
    </Dialog>
  );
};
