import { TCombinedCryptoAsset, TCombinedCryptoNetwork } from 'elements/types';

export enum TradeDirectionEnum {
  Sell = 'Sell',
  Buy = 'Buy',
  BuySell = 'Buy+Sell',
}

export enum TradeFlow {
  Buy = 'buyFlow',
  Sell = 'sellFlow',
}

export type SellCoin = {
  assetType: TCombinedCryptoAsset;
  network: TCombinedCryptoNetwork;
  balance: number;
};

export type BuyCoin = Omit<SellCoin, 'balance'>;

export type BuyPaymentId = {
  tradePaymentId?: string;
  depositPaymentId?: string;
};

export type SellPaymentId = {
  paymentId: string;
};

export type CoinPrice = {
  price: {
    buy: number;
    sell: number;
  };
};
