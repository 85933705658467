import i18n from 'localizations';
import { UserDetailsNode } from 'ui-interfaces/user-details/user-details-node';
import { UserDetailsRowType } from 'ui-enums/user-details/user-details-row-type';

export const loadingNodes: UserDetailsNode[] = [
  {
    id: '01',
    items: [
      {
        id: '1',
        rowType: UserDetailsRowType.loading,
      },
      {
        id: '2',
        rowType: UserDetailsRowType.loading,
      },
      {
        id: '3',
        rowType: UserDetailsRowType.loading,
      },
      {
        id: '4',
        rowType: UserDetailsRowType.loading,
      },
      {
        id: '5',
        rowType: UserDetailsRowType.loading,
      },
      {
        id: '6',
        rowType: UserDetailsRowType.loading,
      },
    ],
  },
  {
    id: '02',
    title: i18n.t('userDetailsPage.permissions'),
    items: [
      {
        id: '1',
        rowType: UserDetailsRowType.loading,
      },
    ],
  },
  {
    id: '03',
    title: i18n.t('userDetailsPage.transactionLimits'),
    items: [
      {
        id: '1',
        rowType: UserDetailsRowType.loading,
      },
    ],
  },
];
