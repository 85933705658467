import React from 'react';
import { Row } from 'components/tapi/transactions/TapiTransactionDetailsPage/pdf/rows/shared/Row';
import { IPdfMt103Indent1NoValueRow } from 'ui-interfaces/transaction-details/pdf/rows/mt-103/i-pdf-mt103-indent-1-no-value-row';
import { RightCell } from './RightCell';

type Props = {
  data: IPdfMt103Indent1NoValueRow;
};

export const PdfMT103Indent1NoValueRow: React.FC<Props> = ({ data }) => {
  return (
    <Row>
      <RightCell value={data.label} />
    </Row>
  );
};
