import React from 'react';
import ContentBlock from 'elements/element-trade/components/OrderPage';
import { useElement } from 'elements/element-trade/contexts/Element';
import { OnCloseElement } from 'elements/models/types/element-result';
import { sendPaymentIdMessage } from 'elements/element-trade/utils/sendPaymentIdMessage';

const OrderPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { state } = useElement();

  const {
    flowType,
    [flowType]: { amount, selectedBuySellCoin, coinPrice, note },
    paymentAccount,
    sellPaymentId,
    buyTradePaymentId,
    buyDepositPaymentId,
  } = state;

  const onDone = (): void => {
    onClose(sendPaymentIdMessage({ sellPaymentId, buyTradePaymentId, buyDepositPaymentId }));
    // @TODO remove when element will use RTK query with common re-fetch approach
    window.location.reload();
  };
  return (
    <ContentBlock
      coinPrice={coinPrice}
      amount={amount}
      coin={selectedBuySellCoin}
      paymentAccount={paymentAccount}
      note={note}
      onDone={onDone}
      flowType={flowType}
    />
  );
};

export default OrderPage;
