import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useExport } from 'hooks/use-export';
import { useLazyGetBusinessIdentitiesQuery } from 'redux/api/api-business-identities';
import { FilterResult } from 'filters/interfaces/filter-result';
import { CommonGridBox } from 'components/shared/layouts/CommonLayout';
import { CustomizedGrid } from 'components/shared/grids/CustomizedGrid';
import { BusinessIdentityRow } from 'ui-interfaces/row-interfaces/identities/business-identity-row';
import { Filterable } from 'ui-interfaces/shared/filters/filterable';
import { getUser } from 'redux/api/api-users';
import { BusinessIdentitiesListFiltersViewModel } from 'view-models/identities/list/filters/business/business-identities-list-filters-vm';
import { onBusinessIdentitiesTransform, onBusinessIdentityRowClick, onBusinessIdentitiesExportClick } from './handlers';
import { columnsBusiness } from './columns-business';

export const BusinessIdentitiesTab: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: user } = useSelector(getUser.select(undefined));

  const { downloadCsv, isLoading: isExportLoading, error: exportError } = useExport();

  const [businessIdentityTrigger, businessIdentityResult] = useLazyGetBusinessIdentitiesQuery();

  const fetchBusinessIdentities = useCallback(
    (page?: number, filterResult?: FilterResult) => businessIdentityTrigger({ page, filterResult }, false),
    [businessIdentityTrigger],
  );

  const [rows, setRows] = useState<BusinessIdentityRow[]>([]);
  const [resourceCount, setResourceCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(businessIdentityResult.isUninitialized);

  useEffect(() => {
    fetchBusinessIdentities();
  }, [fetchBusinessIdentities]);

  useEffect(() => {
    if (businessIdentityResult.isSuccess && businessIdentityResult.data && user) {
      const transformedItems = businessIdentityResult.data.data.map(onBusinessIdentitiesTransform(user));
      setRows(transformedItems);
      setResourceCount(businessIdentityResult.data.meta?.resourceCount ?? 0);
      setLoading(businessIdentityResult.isFetching);
    }
  }, [businessIdentityResult.data, businessIdentityResult.isFetching, businessIdentityResult.isSuccess, user]);

  // filters - start
  const [filterable, setFilterable] = useState<Filterable>(() => new BusinessIdentitiesListFiltersViewModel());

  useEffect(() => {
    if (user) {
      setFilterable(new BusinessIdentitiesListFiltersViewModel(user));
    }
  }, [user]);
  // filters - end

  return (
    <CommonGridBox>
      <CustomizedGrid
        columns={columnsBusiness}
        headerName={t('identitiesPage.gridTitle.business')}
        filters={filterable.filters}
        selectionMacroCommand={filterable.selectionMacroCommand}
        noResultsTitle={t('identitiesPage.noResultsTitle.business')}
        noResultsSubtitle={t('identitiesPage.noResultsSubtitle.business')}
        containerHeight="100%"
        rows={rows}
        rowCount={resourceCount}
        isLoading={loading}
        error={businessIdentityResult.error || exportError}
        fetchOtherItems={fetchBusinessIdentities}
        onGridRowClick={onBusinessIdentityRowClick(navigate)}
        onExportClick={onBusinessIdentitiesExportClick(downloadCsv)}
        isExportLoading={isExportLoading}
      />
    </CommonGridBox>
  );
};
