import i18n from 'localizations';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';

export const getApprovalSectionRowLabel = (transactionStatus: TransactionStatus): { by: string; at: string } => {
  switch (transactionStatus.toLowerCase()) {
    case TransactionStatus.approvalNeeded.toLowerCase():
      return {
        by: i18n.t('transactionDetailsPage.labels.approvalRowLabels.approvalNeeded.by'),
        at: i18n.t('transactionDetailsPage.labels.approvalRowLabels.approvalNeeded.at'),
      };

    case TransactionStatus.rejected.toLowerCase():
      return {
        by: i18n.t('transactionDetailsPage.labels.approvalRowLabels.rejected.by'),
        at: i18n.t('transactionDetailsPage.labels.approvalRowLabels.rejected.at'),
      };

    default:
      return {
        by: i18n.t('transactionDetailsPage.labels.approvalRowLabels.approved.by'),
        at: i18n.t('transactionDetailsPage.labels.approvalRowLabels.approved.at'),
      };
  }
};
