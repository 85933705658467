import { KycLevel } from 'models/enums/identities/kyc-level';
import { LevelCellProps } from 'ui-interfaces/kyc-levels/level-cell-props';

export const mapKycLevelToLevelCellProps = (level: KycLevel): LevelCellProps => {
  switch (level) {
    case KycLevel.L0:
      return {
        levelLabel: 'L0',
        text: { color: 'grey.700' },
        background: { color: 'grey', colorType: 200 },
      };
    case KycLevel.L1:
      return {
        levelLabel: 'L1',
        text: { color: 'warning.dark' },
        background: { color: 'warning', colorType: 'light2' },
      };
    case KycLevel.L2:
      return {
        levelLabel: 'L2',
        text: { color: 'info.main' },
        background: { color: 'info', colorType: 'light2' },
      };

    default:
      return {
        levelLabel: 'L3',
        text: { color: 'grey.700' },
        background: { color: 'grey', colorType: 200 },
      };
  }
};
