import React, { useState, useEffect } from 'react';
import { GridRowsProp, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { GridEventListener } from '@mui/x-data-grid/models/events';
import { DEFAULT_PAGE_SIZE } from 'redux/api/constants';
import { CustomHeader } from './CustomHeader';
import { CustomNoResultsOverlay } from './CustomNoResultsOverlay';
import { CustomErrorOverlay } from './CustomErrorOverlay';
import { CustomLoadingOverlay } from './CustomLoadingOverlay';
import { StyledContainer, StyledDataGrid } from './styled';
import { LoadingCell } from './LoadingCell';

interface FetchCallBack {
  (page?: number): void;
}

type Props = {
  rows: GridRowsProp;
  columns: GridColDef[];
  headerName: string;
  noResultsTitle: string;
  noResultsSubtitle: string;
  isLoading: boolean;
  error: any;
  containerHeight: string;
  onGridRowClick?: (params: GridRowParams) => void;
  onCellClick?: GridEventListener<'cellClick'>;
  pageSize?: number;
  rowCount?: number;
  fetchOtherItems: FetchCallBack;
  onCreateUserClick: () => void;
  isAddUserButtonDisplayed: boolean;
};

const loadingRows = Array(DEFAULT_PAGE_SIZE)
  .fill(0)
  .map((_, idx) => ({
    id: idx,
  }));

export const UsersGrid: React.FC<Props> = ({
  rows,
  columns,
  headerName,
  noResultsTitle,
  noResultsSubtitle,
  pageSize = DEFAULT_PAGE_SIZE,
  rowCount,
  isLoading,
  error,
  containerHeight,
  fetchOtherItems,
  onGridRowClick,
  onCellClick,
  onCreateUserClick,
  isAddUserButtonDisplayed,
}) => {
  const [page, setPage] = useState(0);
  const [, setIsGridError] = useState();
  const [loadingCols, setLoadingCols] = useState(columns);
  const [noSortingCols, setNoSortingCols] = useState(columns);

  useEffect(() => {
    setLoadingCols(columns.map(c => ({ ...c, renderCell: () => <LoadingCell /> })));
    setNoSortingCols(columns.map(c => ({ ...c, sortable: false })));
  }, [columns]);

  /**
   * DataGrid does not react instantly to error change
   * and there's mismatch between error value
   * and the actual DataGrid state:
   * https://github.com/mui/mui-x/issues/3922
   */
  useEffect(() => {
    // needed to force grid rerender
    const timeoutID = setTimeout(() => {
      setIsGridError(error);
    }, 100);
    return () => {
      clearTimeout(timeoutID);
    };
  }, [error]);

  return (
    <StyledContainer
      sx={{
        height: containerHeight,
      }}
    >
      <StyledDataGrid
        disableVirtualization
        rowHeight={64}
        error={error}
        loading={isLoading}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnFilter
        page={page}
        columns={noSortingCols}
        rows={rows}
        rowCount={rowCount}
        pageSize={pageSize}
        pagination
        paginationMode="server"
        components={{
          Toolbar: CustomHeader,
          NoRowsOverlay: CustomNoResultsOverlay,
          ErrorOverlay: CustomErrorOverlay,
          LoadingOverlay: CustomLoadingOverlay,
        }}
        componentsProps={{
          toolbar: {
            headerName,
            onCreateUserClick,
            isAddUserButtonDisplayed,
          },
          noRowsOverlay: {
            title: noResultsTitle,
            subtitle: noResultsSubtitle,
          },
          loadingOverlay: {
            rows: loadingRows,
            columns: loadingCols,
          },
        }}
        onCellClick={onCellClick}
        onPageChange={p => {
          setPage(p);
          fetchOtherItems(p + 1);
        }}
        onRowClick={onGridRowClick}
        rowsPerPageOptions={[pageSize]}
      />
    </StyledContainer>
  );
};
