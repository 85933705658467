import React from 'react';
import { StyledRow } from 'components/shared/detail-page/header/styled';
import { Gap } from 'components/shared/Gap';
import { StyledTypography } from './styled';

type Props = {
  title: string;
};

export const LabelRow: React.FC<Props> = ({ title }) => {
  return (
    <StyledRow>
      <div>
        <StyledTypography>{title}</StyledTypography>
        <Gap size="_12px" />
      </div>
    </StyledRow>
  );
};
