import { SnackbarProvider } from 'notistack';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledSnackbarProvider = elementsStyled(SnackbarProvider)(
  ({ theme }) => css`
    &.notistack-MuiContent-success {
      background-color: ${theme.palette.success.main};
    }

    &.notistack-MuiContent-error {
      background-color: ${theme.palette.error.main};
    }
  `,
);
