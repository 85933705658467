import React from 'react';
import { BreakLineTypography } from './styled';

type Props = {
  value: string;
  large?: boolean;
};

export const DefaultValue: React.FC<Props> = ({ value, large }) => {
  return (
    <BreakLineTypography large={large} color="grey.600" variant="bodyDefaultBook">
      {value}
    </BreakLineTypography>
  );
};
