import React from 'react';
import { Alert, AlertProps, Snackbar, alertClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { pxToRem, pxToVw } from 'utils/styling-utils';
import { MessageType } from 'redux/banner';

export const StyledSnackbar = styled(Snackbar)({
  left: pxToVw(20),
  right: pxToVw(20),
});

export const StyledAlert = styled<React.FC<AlertProps & { type: MessageType }>>(Alert)(({ type, theme }) => ({
  width: pxToVw(600),
  background: theme.palette[type].main,
  color: theme.palette.background.paper,
  borderRadius: '8px',
  padding: `${pxToRem(theme.gaps._16px)} ${pxToRem(theme.gaps._20px)} ${pxToRem(theme.gaps._16px)} ${pxToRem(
    theme.gaps._16px,
  )}`,
  alignItems: 'center',
  [`.${alertClasses.message}`]: {
    flexGrow: 1,
    padding: 0,
  },
  [`.${alertClasses.action}`]: {
    flexGrow: 0,
    margin: 0,
  },
}));
