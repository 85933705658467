import React from 'react';
import { LeftCell } from 'components/shared/detail-page/rows/shared/LeftCell';
import { RowBox } from 'components/shared/detail-page/rows/shared/RowBox';
import { IUiTextRow } from 'ui-interfaces/shared/rows/i-ui-text-row';
import { RightCell } from './RightCell';

type Props = {
  data: IUiTextRow;
  hasBottomBorder?: boolean;
};

export const TimeRow: React.FC<Props> = ({ data, hasBottomBorder = true }) => {
  return (
    <RowBox hasBottomBorder={hasBottomBorder}>
      <LeftCell value={data.label} />
      <RightCell value={data.value} />
    </RowBox>
  );
};
