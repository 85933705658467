import React, { ForwardedRef, forwardRef } from 'react';
import { Link, Typography } from '@mui/material';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Gap } from 'components/shared/Gap';
import { pxToRem } from 'utils/styling-utils';
import { StyledRow, StyledSkeleton } from './styled';

type Props = {
  loading?: boolean;
  label: string;
  url: string;
  urlDisplayText?: string;
  icon: React.ReactNode;
  large?: boolean;
};

const Content = forwardRef(
  (
    { label, loading = false, url, urlDisplayText, icon, large = false, ...restProps }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const skeletonWidth = large ? 280 : 120;

    return (
      <StyledRow {...restProps} ref={ref}>
        {icon}
        <Gap size="_12px" isHorizontal />
        <Typography color="grey.600" variant="bodyDefaultMedium">
          {label}:
        </Typography>
        <Gap size="_8px" isHorizontal />
        {loading ? (
          <StyledSkeleton animation="pulse" variant="circular" width={pxToRem(skeletonWidth)} />
        ) : (
          <Link component={ReactRouterLink} to={url} sx={{ cursor: 'pointer' }}>
            <Typography variant="bodyDefaultBookLink">{urlDisplayText ?? url}</Typography>
          </Link>
        )}
      </StyledRow>
    );
  },
);

export default Content;
