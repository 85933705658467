import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import * as api from 'elements/element-transfer/api';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { TransferFlow } from 'elements/element-transfer/types';
import { setErrorBanner } from 'elements/utils';
import { setDepositWireInstructions, setPage, useElement } from 'elements/element-transfer/contexts/Element';
import convertWireInstructionsResponse from 'elements/element-transfer/utils/convertWireInstructionsResponse';

const fetchWireInstructionsContent = async (custodialAccountId: string) => {
  const response = await api.transferElement.getWireDepositInstructions(custodialAccountId);
  return convertWireInstructionsResponse(response);
};

const useWireInstructionsContent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch, state } = useElement();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const wire = state[TransferFlow.Deposit].WIRE;
  const withBackButton = state.transferOptionsList.length > 1;

  const handleBack = () => {
    dispatch(setPage(Paths.Transfer));
  };

  const requestWireInstructionsContent = useCallback(async () => {
    try {
      setLoading(true);
      setError(false);

      const wireInstructions = await fetchWireInstructionsContent(state.custodialAccountId);
      dispatch(setDepositWireInstructions(wireInstructions));
    } catch (e) {
      const isBannerShown = setErrorBanner(e, enqueueSnackbar);
      dispatch(setDepositWireInstructions(null));

      if (!isBannerShown || !withBackButton) {
        setError(true);
      }
      if (withBackButton) {
        handleBack();
      }
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, enqueueSnackbar]);

  useEffect(() => {
    requestWireInstructionsContent();
  }, [requestWireInstructionsContent]);

  // eslint-disable-next-line no-return-await
  const handleReload = async () => await requestWireInstructionsContent();

  return {
    loading,
    error,
    wireInstructions: wire.instructions,
    handleReload,
    handleBack: withBackButton ? handleBack : undefined,
  };
};

export default useWireInstructionsContent;
