import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogOutIcon } from 'assets/icons/side-menu/log-out.svg';
import { Gap } from 'components/shared/Gap';
import { StyledCommonTooltip, StyledTransparentButton } from './styled';

type Props = {
  className?: string;
  onClick: () => void;
  isSideBarExpanded: boolean;
};

const LogOutButton: React.FC<Props> = ({ className, isSideBarExpanded, ...restProps }) => {
  const { t } = useTranslation();
  const text = t('sideMenu.labels.logOut');
  const content = (
    <StyledTransparentButton {...restProps} className={className}>
      <LogOutIcon />
      {isSideBarExpanded && (
        <>
          <Gap isHorizontal size="_8px" />
          {text}
        </>
      )}
    </StyledTransparentButton>
  );

  if (isSideBarExpanded) {
    return content;
  }

  return (
    <StyledCommonTooltip placement="right" title={text}>
      {content}
    </StyledCommonTooltip>
  );
};

export default LogOutButton;
