import React, { useState, useEffect } from 'react';
import { GridRowsProp, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { GridEventListener } from '@mui/x-data-grid/models/events';
import { CustomHeader } from './CustomHeader';
import { CustomNoResultsOverlay } from './CustomNoResultsOverlay';
import { CustomErrorOverlay } from './CustomErrorOverlay';
import { CustomLoadingOverlay } from './CustomLoadingOverlay';
import { StyledContainer, StyledDataGrid } from './styled';
import { LoadingCell } from './LoadingCell';
import { NoFooter } from './NoFooter';

const MAX_NUM_OF_ROWS = 10;

type Props = {
  rows: GridRowsProp;
  columns: GridColDef[];
  headerName: string;
  noResultsTitle: string;
  noResultsSubtitle: string;
  isLoading: boolean;
  error: any;
  containerHeight: string;
  onGridRowClick?: (params: GridRowParams) => void;
  onCellClick?: GridEventListener<'cellClick'>;
};

const loadingRows = Array(MAX_NUM_OF_ROWS)
  .fill(0)
  .map((_, idx) => ({
    id: idx,
  }));

export const SimplifiedGrid: React.FC<Props> = ({
  rows,
  columns,
  headerName,
  noResultsTitle,
  noResultsSubtitle,
  isLoading,
  error,
  containerHeight,
  onGridRowClick,
  onCellClick,
}) => {
  const [, setIsGridError] = useState();
  const [loadingCols, setLoadingCols] = useState(columns);
  const [noSortingCols, setNoSortingCols] = useState(columns);

  useEffect(() => {
    setLoadingCols(columns.map(c => ({ ...c, renderCell: () => <LoadingCell /> })));
    setNoSortingCols(columns.map(c => ({ ...c, sortable: false })));
  }, [columns]);

  /**
   * DataGrid does not react instantly to error change
   * and there's mismatch between error value
   * and the actual DataGrid state:
   * https://github.com/mui/mui-x/issues/3922
   */
  useEffect(() => {
    // needed to force grid rerender
    const timeoutID = setTimeout(() => {
      setIsGridError(error);
    }, 100);
    return () => {
      clearTimeout(timeoutID);
    };
  }, [error]);

  return (
    <StyledContainer
      sx={{
        height: containerHeight,
      }}
    >
      <StyledDataGrid
        disableVirtualization
        rowHeight={64}
        error={error}
        loading={isLoading}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnFilter
        columns={noSortingCols}
        rows={rows}
        components={{
          Toolbar: CustomHeader,
          NoRowsOverlay: CustomNoResultsOverlay,
          ErrorOverlay: CustomErrorOverlay,
          LoadingOverlay: CustomLoadingOverlay,
          Footer: NoFooter,
        }}
        componentsProps={{
          toolbar: {
            headerName,
          },
          noRowsOverlay: {
            title: noResultsTitle,
            subtitle: noResultsSubtitle,
          },
          loadingOverlay: {
            rows: loadingRows,
            columns: loadingCols,
          },
        }}
        onRowClick={onGridRowClick}
        onCellClick={onCellClick}
      />
    </StyledContainer>
  );
};
