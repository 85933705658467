import i18n from 'localizations';
import { TransactionDetails } from 'ui-interfaces/transaction-details/transaction-details';
import { TransactionDetailResponseModel } from 'models/response/transactions/transaction-detail-response-model';
import { formatDateAndTimeInUTC } from 'utils/format-date';
import { TransactionDetailsBaseViewModel } from 'view-models/transactions/details/transaction-details-base-vm';
import { addSpaceAfterEvery4Chars } from 'utils/add-space-after-every-4-chars';
import { getNetworkChainLabel } from 'utils/labels-mapping/get-network-chain-label';
import { LabelLength } from 'ui-enums/label-length';
import { getSignOfTransaction } from 'utils/transactions/get-sign-of-transaction';
import { RowType } from 'ui-enums/transaction-detail/row-type';
import { getCryptoAmountForActionDialog } from 'utils/transactions/get-crypto-amount-for-action-dialog';
import { ITextRow } from 'ui-interfaces/transaction-details/rows/i-text-row';
import { IPaymentTypeRow } from 'ui-interfaces/transaction-details/rows/i-payment-type-row';
import { ILinkRow } from 'ui-interfaces/transaction-details/rows/i-link-row';
import { formatCurrency } from 'utils/format-currency';
import { getOverviewSectionBasicProps } from 'utils/transactions/detail/get-overview-section-basic-props';
import { IPlainSection } from 'ui-interfaces/transaction-details/sections/i-plain-section';
import { IPlainRow } from 'ui-interfaces/transaction-details/pdf/rows/i-plain-row';
import { getTransactionStatusLabel } from 'utils/labels-mapping/get-transaction-status-label';
import { PdfRowType } from 'ui-enums/pdf/pdf-row-type';
import { getPdfAmountForDepositWithdrawalInternalTransferCrypto } from 'utils/transactions/detail/pdf/get-pdf-amount-for-deposit-withdrawal-internal-transfer-crypto';
import { IPdfLinkRow } from 'ui-interfaces/transaction-details/pdf/rows/i-pdf-link-row';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';
import { mapAssetByNetworkIconSizeToNumber } from 'utils/multi-mapping/asset-by-network-icon-size/map-asset-by-network-icon-size-to-number';
import { getDynamicAssetLabel } from 'utils/labels-mapping/get-dynamic-asset-label';
import { getStaticDefaultIconForTransaction } from 'utils/icons-mapping/transactions/get-static-default-icon-for-transaction';

export class TransactionDetailsDepositCryptoViewModel
  extends TransactionDetailsBaseViewModel
  implements TransactionDetails
{
  constructor(transaction: TransactionDetailResponseModel) {
    super(transaction);

    this.amountForActionDialog = getCryptoAmountForActionDialog(transaction);

    this.sections = [
      {
        ...getOverviewSectionBasicProps(),
        currencyRow: {
          imageUrl: transaction.assetIconXs,
          size: mapAssetByNetworkIconSizeToNumber(this.assetIconSize),
          defaultAssetIcon: getStaticDefaultIconForTransaction(transaction.assetTicker, this.assetIconSize),

          label: `${getSignOfTransaction(transaction)}${transaction.cryptoAmount ?? 0} ${getDynamicAssetLabel(
            LabelLength.short,
            transaction.assetTicker,
            transaction.assetName,
          )}`,
          subLabel: transaction.amount
            ? `${getSignOfTransaction(transaction)}${formatCurrency(transaction.amount ?? 0)} ${getDynamicAssetLabel(
                LabelLength.short,
                transaction.assetTicker,
                transaction.assetName,
              )}`
            : undefined,
        },
        nodes: [
          {
            id: 'infoSection',
            blockItems: [
              {
                id: 'transactionId',
                label: i18n.t('transactionDetailsPage.labels.transactionId'),
                uiElementType: RowType.text,
                value: transaction.externalId || i18n.t('transactionDetailsPage.labels.noData'),
              } as ITextRow,
              {
                id: 'paymentId',
                label: i18n.t('transactionDetailsPage.labels.paymentId'),
                uiElementType: RowType.text,
                value: transaction.paymentId,
              } as ITextRow,
              {
                id: 'transactionHash',
                label: i18n.t('transactionDetailsPage.labels.transactionHash'),
                uiElementType: RowType.link,
                value: transaction?.txHash || '',
                url: transaction?.txHashUrl || '',
              } as ILinkRow,
              {
                id: 'date',
                label: i18n.t('transactionDetailsPage.labels.date'),
                uiElementType: RowType.text,
                value: `${formatDateAndTimeInUTC(transaction.date)} UTC`,
              } as ITextRow,
              {
                id: 'type',
                label: i18n.t('transactionDetailsPage.labels.type'),
                uiElementType: RowType.paymentType,
                value: i18n.t('transactionDetailsPage.labels.deposit'),
                paymentType: transaction.paymentType,
              } as IPaymentTypeRow,
              {
                id: 'blockChain',
                label: i18n.t('transactionDetailsPage.labels.blockchain'),
                uiElementType: RowType.text,
                value: transaction.network ? getNetworkChainLabel(this.networkChain!) : '',
              } as ITextRow,
              {
                id: 'comment',
                label: i18n.t('transactionDetailsPage.labels.comment'),
                uiElementType: RowType.textMultiLine,
                value: transaction?.comment || '',
              } as ITextRow,
            ].filter(i => {
              if (
                i.uiElementType === RowType.text ||
                i.uiElementType === RowType.textMultiLine ||
                i.uiElementType === RowType.link
              ) {
                return !!i.value;
              }

              return true;
            }),
          },
          {
            id: 'sourceSection',
            blockHeaderLabel: i18n.t('transactionDetailsPage.source'),
            blockItems: [
              {
                id: 'walletAddress',
                label: i18n.t('transactionDetailsPage.labels.walletAddress'),
                uiElementType: RowType.text,
                value: transaction?.sourceCryptoAddress || '',
              } as ITextRow,
            ],
          },
          {
            id: 'destinationSection',
            blockHeaderLabel: i18n.t('transactionDetailsPage.destination'),
            blockItems: [
              {
                id: 'custodialAccountNumber',
                label: i18n.t('transactionDetailsPage.labels.accountNumber'),
                uiElementType: RowType.text,
                value: addSpaceAfterEvery4Chars(transaction.destinationAccountNumber || ''),
              } as ITextRow,
              {
                id: 'accountOwner',
                label: i18n.t('transactionDetailsPage.labels.accountOwner'),
                uiElementType: RowType.text,
                value: transaction.destinationOwnerIdentityDisplayName,
              } as ITextRow,
              {
                id: 'walletAddress',
                label: i18n.t('transactionDetailsPage.labels.walletAddress'),
                uiElementType: RowType.text,
                value: transaction?.destinationCryptoAddress || '',
              } as ITextRow,
            ].filter(i => !!i.value),
          },
          // {
          //   id: 'feeSection',
          //   blockHeaderLabel: i18n.t('transactionDetailsPage.labels.feeBlockHeaderLabel'),
          //   blockItems: [
          //     {
          //       id: 'feeRow',
          //       rowType: RowType.fee,
          //       frontItem: {
          //         id: 'BlockchainFee',
          //         label: i18n.t('transactionDetailsPage.labels.blockchainFee'),
          //         value: i18n.t('transactionDetailsPage.labels.tapiDepositCryptoBlockChainFee'),
          //         toolTipMessage: i18n.t('transactionDetailsPage.labels.wapiBlockchainFeeToolTip'),
          //       },
          //     } as IFeeRow,
          //   ],
          // },
        ],
      } as IPlainSection,
    ];

    this.pdfNodes = [
      {
        id: 'infoSection',
        title: i18n.t('transactionDetailsPage.labels.general'),
        items: [
          {
            id: 'amount',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.amountBlockHeaderLabel'),
            value: getPdfAmountForDepositWithdrawalInternalTransferCrypto(transaction),
          } as IPlainRow,
          {
            id: 'status',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.status'),
            value: getTransactionStatusLabel(transaction.status as TransactionStatus),
          } as IPlainRow,
          {
            id: 'transactionId',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.transactionId'),
            value: transaction.externalId || i18n.t('transactionDetailsPage.labels.noData'),
          } as IPlainRow,
          {
            id: 'paymentId',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.paymentId'),
            value: transaction.paymentId,
          } as IPlainRow,
          {
            id: 'transactionHash',
            rowType: PdfRowType.link,
            label: i18n.t('transactionDetailsPage.labels.transactionHash'),
            value: transaction?.txHash || '',
            url: transaction?.txHashUrl || '',
          } as IPdfLinkRow,
          {
            id: 'date',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.date'),
            value: `${formatDateAndTimeInUTC(transaction.date)} UTC`,
          } as IPlainRow,
          {
            id: 'type',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.type'),
            value: i18n.t('transactionDetailsPage.labels.deposit'),
          } as IPlainRow,
          {
            id: 'blockChain',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.blockchain'),
            value: transaction?.network ? getNetworkChainLabel(this.networkChain!) : '',
          } as IPlainRow,
          {
            id: 'comment',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.comment'),
            value: transaction?.comment || '',
          } as IPlainRow,
        ].filter(i => !!i.value),
      },
      {
        id: 'sourceSection',
        title: i18n.t('transactionDetailsPage.source'),
        items: [
          {
            id: 'walletAddress',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.walletAddress'),
            value: transaction?.sourceCryptoAddress || '',
          } as IPlainRow,
        ],
      },
      {
        id: 'destinationSection',
        title: i18n.t('transactionDetailsPage.destination'),
        items: [
          {
            id: 'custodialAccountNumber',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.accountNumber'),
            value: addSpaceAfterEvery4Chars(transaction.destinationAccountNumber || ''),
          } as IPlainRow,
          {
            id: 'accountOwner',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.accountOwner'),
            value: transaction.destinationOwnerIdentityDisplayName,
          } as IPlainRow,
          {
            id: 'walletAddress',
            rowType: PdfRowType.plain,
            label: i18n.t('transactionDetailsPage.labels.walletAddress'),
            value: transaction?.destinationCryptoAddress || '',
          } as IPlainRow,
        ].filter(i => !!i.value),
      },
    ];
  }
}
