import React from 'react';
import { PaymentType } from 'ui-enums/response/transactions/payment-type';
import { getPaymentTypeIcon } from 'utils/icons-mapping/get-payment-type-icon';
import { getCellLabelsByType } from './utils/getCellLabelsByType';
import { CellContent } from './CellContent';
import { NotImplementedCellContent } from './NotImplementedCellContent';
import { CellContentWithoutMethod } from './CellContentWithoutMethod';

type Props = {
  type: PaymentType;
  width: number;
};

const CELL_HORIZONTAL_PADDING = 20;
const ICON_WIDTH = 20;
const SPACER_WIDTH = 8;
const TOTAL_WIDTH_OF_STATIC_ELEMENTS = 2 * CELL_HORIZONTAL_PADDING + ICON_WIDTH + SPACER_WIDTH;

export const TypeCell: React.FC<Props> = ({ type, width }) => {
  const icon = getPaymentTypeIcon(type);
  const cellLabels = getCellLabelsByType(type);
  const maxTextWidth = width - TOTAL_WIDTH_OF_STATIC_ELEMENTS;
  if (!cellLabels) {
    return <NotImplementedCellContent icon={icon} maxTextWidth={maxTextWidth} />;
  }
  // @TODO TBD check with designers
  if (!cellLabels.method) {
    return <CellContentWithoutMethod icon={icon} operation={cellLabels.operation} maxTextWidth={maxTextWidth} />;
  }

  return (
    <CellContent icon={icon} operation={cellLabels.operation} method={cellLabels.method} maxTextWidth={maxTextWidth} />
  );
};
