import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { pdf } from '@react-pdf/renderer';
import { Typography } from '@mui/material';
import { LoadingButtonWithSkeleton } from 'components/shared/buttons/LoadingButtonWithSkeleton';
import { PdfNode } from 'ui-interfaces/transaction-details/pdf/pdf-node';
import { PdfDoc } from '../PdfDoc';

import { StyledExportIcon } from './styled';

type Props = {
  pdfNodes: PdfNode[];
  showAsMainDesign: boolean;
};

const FILE_NAME = 'Fortress_Receipt.pdf';

export const PDfExportButton: React.FC<Props> = ({ pdfNodes, showAsMainDesign }) => {
  const { t } = useTranslation();

  const generatePdf = useCallback(async () => {
    const blob = await pdf(<PdfDoc nodes={pdfNodes} />).toBlob();
    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', FILE_NAME);
    // Append to html page
    document.body.appendChild(link);
    // Force download
    link.click();
    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  }, [pdfNodes]);

  return (
    <LoadingButtonWithSkeleton
      color={showAsMainDesign ? 'primary' : 'inherit'}
      loading={false}
      variant={showAsMainDesign ? 'contained' : 'outlined'}
      size="large"
      icon={<StyledExportIcon showAsMainDesign={showAsMainDesign} />}
      text={
        <Typography color={showAsMainDesign ? 'background.paper' : 'grey.700'} variant="buttonMedium">
          {t('transactionDetailsPage.labels.exportButtonLabel')}
        </Typography>
      }
      onClick={generatePdf}
    />
  );
};
