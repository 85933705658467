import React from 'react';
import { mapAssetByNetworkIconSizeToNumber } from 'utils/multi-mapping/asset-by-network-icon-size/map-asset-by-network-icon-size-to-number';
import { AssetByNetworkIconSize } from 'ui-enums/asset-by-network-icon-size';
import { SvgStaticImage } from 'components/shared/SvgStaticImage';
import { getStaticDefaultIconComponent } from './get-static-default-icon-component';

export const getStaticDefaultIcon = (assetTicker: string, size: AssetByNetworkIconSize) => {
  const defaultIconComponent = getStaticDefaultIconComponent(assetTicker);

  return <SvgStaticImage icon={defaultIconComponent} size={mapAssetByNetworkIconSizeToNumber(size)} />;
};
