import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { columnsTransactions } from 'components/tapi/transactions/TapiTransactionsPage/columns-definitions';
import { CommonGridBox } from 'components/shared/layouts/CommonLayout';
import { RejectedTransactionsListFiltersViewModel } from 'view-models/transactions/list/filters/rejected/rejected-transactions-list-filters-vm';
import { TransactionsRow } from 'ui-interfaces/row-interfaces/transactions';
import { Filterable } from 'ui-interfaces/shared/filters/filterable';
import { useLazyGetRejectedTransactionsQuery } from 'redux/api/api-transactions';
import { FilterResult } from 'filters/interfaces/filter-result';
import { onNonApprovedTransactionRowClick, onTransactionTransform } from 'handlers/tapi/transactions/list/handlers';
import { usePaginatedExport } from 'hooks/use-paginated-export';
import { PaginatedExportGrid } from 'components/shared/grids/PaginatedExportGrid';
import InlineInfoBanner from 'components/shared/InlineInfoBanner';
import { defaultTransactionFilterResult } from 'redux/api/default-filters';
import { onTransactionsExportClick } from './handlers';

export const RejectedTab: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { downloadPaginatedCsv, isLoading, error } = usePaginatedExport();
  const [getRejectedTabTransactionsTrigger, rejectedTabTransactionsResult] = useLazyGetRejectedTransactionsQuery();

  const fetchRejectedTabTransactions = useCallback(
    (page?: number, filterResult?: FilterResult) => getRejectedTabTransactionsTrigger({ page, filterResult }, false),
    [getRejectedTabTransactionsTrigger],
  );

  const [rows, setRows] = useState<TransactionsRow[]>([]);
  const [resourceCount, setResourceCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(rejectedTabTransactionsResult.isUninitialized);
  const [filterable] = useState<Filterable>(() => new RejectedTransactionsListFiltersViewModel());

  useEffect(() => {
    fetchRejectedTabTransactions(undefined, defaultTransactionFilterResult());
  }, [fetchRejectedTabTransactions]);

  useEffect(() => {
    if (rejectedTabTransactionsResult.isSuccess && rejectedTabTransactionsResult.data) {
      const transformedItems = rejectedTabTransactionsResult.data.data.map(onTransactionTransform);
      setRows(transformedItems);
      setResourceCount(rejectedTabTransactionsResult.data.meta?.resourceCount ?? 0);
      setLoading(rejectedTabTransactionsResult.isFetching);
    }
  }, [
    rejectedTabTransactionsResult.data,
    rejectedTabTransactionsResult.isFetching,
    rejectedTabTransactionsResult.isSuccess,
  ]);

  return (
    <>
      <InlineInfoBanner>{t('transactionsPage.defaultCreatedAtFilter')}</InlineInfoBanner>
      <CommonGridBox>
        <PaginatedExportGrid
          columns={columnsTransactions}
          headerName={t('transactionsPage.gridTitle')}
          filters={filterable.filters}
          selectionMacroCommand={filterable.selectionMacroCommand}
          noResultsTitle={t('transactionsPage.noResultsTitle')}
          noResultsSubtitle={t('transactionsPage.noResultsSubtitle')}
          containerHeight="100%"
          rows={rows}
          rowCount={resourceCount}
          isLoading={loading}
          error={rejectedTabTransactionsResult.error || error}
          fetchOtherItems={fetchRejectedTabTransactions}
          onGridRowClick={onNonApprovedTransactionRowClick(navigate)}
          onExportClick={onTransactionsExportClick(downloadPaginatedCsv)}
          isExportLoading={isLoading}
          alwaysShowToolbar
        />
      </CommonGridBox>
    </>
  );
};
