import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PathMatch, useNavigate } from 'react-router-dom';
import { CommonGridBox } from 'components/shared/layouts/CommonLayout';
import { PrivateSecurityTransactionsListFiltersViewModel } from 'view-models/transactions/list/filters/private-security/private-security-transactions-list-filters-vm';
import { TransactionsApiParams, useLazyGetPrivateSecurityTransactionsQuery } from 'redux/api/api-transactions';
import { Filterable } from 'ui-interfaces/shared/filters/filterable';
import { FilterResult } from 'filters/interfaces/filter-result';
import { TransactionsRow } from 'ui-interfaces/row-interfaces/transactions';
import {
  onPrivateSecurityTransactionsExportClick,
  onApprovedTransactionRowClick,
  onTransactionTransform,
} from 'handlers/tapi/custodial-accounts/handlers';
import { columnsTransactions } from 'components/tapi/transactions/TapiTransactionsPage/columns-definitions';
import { usePaginatedExport } from 'hooks/use-paginated-export';
import { PaginatedExportGrid } from 'components/shared/grids/PaginatedExportGrid';
import InlineInfoBanner from 'components/shared/InlineInfoBanner';
import { defaultTransactionFilterResult } from 'redux/api/default-filters';
import { CustomAssetBalanceGrid } from './CustomAssetBalanceGrid';

type Props = {
  custodialAccountId: string;
  pathMatch: PathMatch | null;
  setIsDetailsPageError: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PrivateSecuritiesTab: React.FC<Props> = ({ custodialAccountId, pathMatch, setIsDetailsPageError }) => {
  const { t } = useTranslation();
  const { downloadPaginatedCsv, isLoading, error } = usePaginatedExport();
  const navigate = useNavigate();

  // request boilerplate - start
  const [getPrivateSecurityTransactionsTrigger, privateSecurityTransactionsResult] =
    useLazyGetPrivateSecurityTransactionsQuery();

  const fetchPrivateSecurityTransactions = useCallback(
    (page?: number, filterResult?: FilterResult) =>
      getPrivateSecurityTransactionsTrigger({ page, custodialAccountId, filterResult } as TransactionsApiParams, false),
    [custodialAccountId, getPrivateSecurityTransactionsTrigger],
  );

  const [rows, setRows] = useState<TransactionsRow[]>([]);
  const [resourceCount, setResourceCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(privateSecurityTransactionsResult.isUninitialized);
  const [filterable] = useState<Filterable>(() => new PrivateSecurityTransactionsListFiltersViewModel());

  useEffect(() => {
    fetchPrivateSecurityTransactions(undefined, defaultTransactionFilterResult());
  }, [fetchPrivateSecurityTransactions]);
  // request boilerplate - end

  useEffect(() => {
    if (privateSecurityTransactionsResult.isSuccess && privateSecurityTransactionsResult.data) {
      const transformedItems = privateSecurityTransactionsResult.data.data.map(onTransactionTransform);
      setRows(transformedItems);
      setResourceCount(privateSecurityTransactionsResult.data.meta?.resourceCount ?? 0);
      setLoading(privateSecurityTransactionsResult.isFetching);
    }
  }, [
    privateSecurityTransactionsResult.data,
    privateSecurityTransactionsResult.isFetching,
    privateSecurityTransactionsResult.isSuccess,
  ]);

  // handle all page error - start
  useEffect(() => {
    setIsDetailsPageError(Boolean(privateSecurityTransactionsResult.error || error));
  }, [error, privateSecurityTransactionsResult.error, setIsDetailsPageError]);
  // handle all page error - end

  return (
    <>
      <CustomAssetBalanceGrid custodialAccountId={custodialAccountId} setIsDetailsPageError={setIsDetailsPageError} />
      <InlineInfoBanner>{t('transactionsPage.defaultCreatedAtFilter')}</InlineInfoBanner>
      <CommonGridBox>
        <PaginatedExportGrid
          columns={columnsTransactions}
          headerName={t('transactionsPage.gridTitle')}
          filters={filterable.filters}
          selectionMacroCommand={filterable.selectionMacroCommand}
          noResultsTitle={t('transactionsPage.noResultsTitle')}
          noResultsSubtitle={t('transactionsPage.noResultsSubtitle')}
          containerHeight="816px"
          rows={rows}
          rowCount={resourceCount}
          isLoading={loading}
          error={privateSecurityTransactionsResult.error || error}
          fetchOtherItems={fetchPrivateSecurityTransactions}
          onGridRowClick={onApprovedTransactionRowClick(navigate, custodialAccountId!, pathMatch)}
          onExportClick={onPrivateSecurityTransactionsExportClick(downloadPaginatedCsv, custodialAccountId!)}
          isExportLoading={isLoading}
          alwaysShowToolbar
        />
      </CommonGridBox>
    </>
  );
};
