import React from 'react';
import { AssetByNetwork } from 'ui-enums/response/asset-by-network';
import { ReactComponent as UsdIcon } from 'assets/icons/asset-by-network-icons/size-36x36/usd.svg';
import { ReactComponent as NFTIcon } from 'assets/icons/asset-by-network-icons/size-36x36/nft.svg';
import { ReactComponent as CustomAsset } from 'assets/icons/asset-by-network-icons/size-36x36/custom-asset.svg';
import { ReactComponent as GoldIcon } from 'assets/icons/asset-by-network-icons/size-36x36/gold.svg';
import { ReactComponent as SilverIcon } from 'assets/icons/asset-by-network-icons/size-36x36/silver.svg';
import { ReactComponent as DefaultAssetIcon } from 'assets/icons/default-asset-icon/default-asset-icon-36x36.svg';

export const mapAssetByNetworkToIconComponent = (
  asset: AssetByNetwork,
): React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null => {
  switch (asset.toLowerCase()) {
    case AssetByNetwork.nft.toLowerCase():
      return NFTIcon;
    case AssetByNetwork.usd.toLowerCase():
      return UsdIcon;
    case AssetByNetwork.privateSecurity.toLowerCase():
      return CustomAsset;
    case AssetByNetwork.gold.toLowerCase():
      return GoldIcon;
    case AssetByNetwork.silver.toLowerCase():
      return SilverIcon;
    default:
      return DefaultAssetIcon;
  }
};
