import { GridRowParams } from '@mui/x-data-grid';
import { NavigateFunction } from 'react-router-dom';
import { WalletResponseModel } from 'models/response/wallets/wallet-response-model';
import { getRouteWithParams } from 'utils/get-route-with-params';
import { NESTED_PATHS } from 'navigation/constants';
import { IdentityDetailsUrlParams, WalletDetailsUnderIdentityDetailsUrlParams } from 'navigation/url-params-type';
import { FilterResult } from 'filters/interfaces/filter-result';
import { API_ROUTES } from 'redux/api/api-routes';
import { DownloadCSV } from 'hooks/use-export/interfaces';
import { createWalletViewModel } from 'factories/wallets/create-wallet-view-model';

export const onWalletTransform = (wallet: WalletResponseModel) => createWalletViewModel(wallet);

export const onWalletRowClick =
  (navigate: NavigateFunction, urlParams: Readonly<Partial<IdentityDetailsUrlParams>>) =>
  ({ row }: GridRowParams) => {
    navigate(
      getRouteWithParams(NESTED_PATHS.IDENTITY_DETAILS__WALLET_DETAILS, {
        walletId: row.id,
        identityId: urlParams.identityId,
        identityType: urlParams.identityType,
      } as WalletDetailsUnderIdentityDetailsUrlParams),
    );
  };

export const onWalletsExportClick = (downloadCsv: DownloadCSV, identityId: string) => (filterResult?: FilterResult) => {
  downloadCsv({
    urlSegment: API_ROUTES.private.WALLETS,
    preFilterParams: {
      [`filter[ownerIdentityId eq]`]: identityId,
    },
    filterResult,
  });
};
