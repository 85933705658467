import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLazyGetCorporateCustodialAccountsQuery } from 'redux/api/api-custodial-accounts';
import { useExport } from 'hooks/use-export';
import { CustodialAccountsRow } from 'ui-interfaces/row-interfaces/custodial-accounts';
import { Filterable } from 'ui-interfaces/shared/filters/filterable';
import { CustodialAccountsListFiltersViewModel } from 'view-models/custodial-accounts/filters/custodial-accounts-list-filters-vm';
import { FilterResult } from 'filters/interfaces/filter-result';
import { CustomizedGrid } from 'components/shared/grids/CustomizedGrid';
import { onCustodialAccountRowClick, onCustodialAccountTransform } from '../../handlers';
import { columnsCustodialAccounts } from '../../columns-definitions';
import { onCorporateCustodialAccountsExportClick } from './handlers';

export const CorporateCustodialAccountsTab: React.FC = () => {
  const navigate = useNavigate();
  const { downloadCsv, isLoading: isExportLoading, error: exportError } = useExport();
  const [getCorporateCustodialAccountsTrigger, corporateCustodialAccountsResult] =
    useLazyGetCorporateCustodialAccountsQuery();

  const fetchCorporateCustodialAccounts = useCallback(
    (page?: number, filterResult?: FilterResult) => getCorporateCustodialAccountsTrigger({ page, filterResult }, false),
    [getCorporateCustodialAccountsTrigger],
  );

  const [rows, setRows] = useState<CustodialAccountsRow[]>([]);
  const [resourceCount, setResourceCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(corporateCustodialAccountsResult.isUninitialized);
  const [filterable] = useState<Filterable>(() => new CustodialAccountsListFiltersViewModel());

  useEffect(() => {
    fetchCorporateCustodialAccounts();
  }, [fetchCorporateCustodialAccounts]);

  useEffect(() => {
    if (corporateCustodialAccountsResult.isSuccess && corporateCustodialAccountsResult.data) {
      const transformedItems = corporateCustodialAccountsResult.data.data.map(onCustodialAccountTransform);
      setRows(transformedItems);
      setResourceCount(corporateCustodialAccountsResult.data.meta?.resourceCount ?? 0);
      setLoading(corporateCustodialAccountsResult.isFetching);
    }
  }, [
    corporateCustodialAccountsResult.data,
    corporateCustodialAccountsResult.isFetching,
    corporateCustodialAccountsResult.isSuccess,
  ]);

  const { t } = useTranslation();

  return (
    <CustomizedGrid
      columns={columnsCustodialAccounts}
      headerName={t('custodialAccountsPage.corporateCustodialAccountsGridTitle')}
      filters={filterable.filters}
      selectionMacroCommand={filterable.selectionMacroCommand}
      noResultsTitle={t('custodialAccountsPage.noResultsTitle')}
      noResultsSubtitle={t('custodialAccountsPage.noResultsSubtitle')}
      containerHeight="100%"
      rows={rows}
      rowCount={resourceCount}
      isLoading={loading}
      error={corporateCustodialAccountsResult.error || exportError}
      fetchOtherItems={fetchCorporateCustodialAccounts}
      onGridRowClick={onCustodialAccountRowClick(navigate)}
      onExportClick={onCorporateCustodialAccountsExportClick(downloadCsv)}
      isExportLoading={isExportLoading}
    />
  );
};
