import { TransactionDetailResponseModel } from 'models/response/transactions/transaction-detail-response-model';
import { LabelLength } from 'ui-enums/label-length';
import { getDynamicAssetLabel } from 'utils/labels-mapping/get-dynamic-asset-label';

export const getCryptoAmountForActionDialog = (transaction: TransactionDetailResponseModel): string => {
  return `${transaction.cryptoAmount ?? 0} ${getDynamicAssetLabel(
    LabelLength.short,
    transaction.assetTicker,
    transaction.assetName,
  )}`;
};
