import React from 'react';
import { StyledRow, StyledTitleSkeleton } from 'components/shared/detail-page/header/styled';

export const LoadingNameRow: React.FC = () => {
  return (
    <StyledRow>
      <StyledTitleSkeleton />
    </StyledRow>
  );
};
