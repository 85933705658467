import React from 'react';
import { RowType } from 'ui-enums/transaction-detail/row-type';

// rows interfaces - start
import { IdentifiableUiElementTyped } from 'ui-interfaces/shared/details-page/identifiable-ui-element-typed';
import { ITextRow } from 'ui-interfaces/transaction-details/rows/i-text-row';
import { IApprovalStatusRow } from 'ui-interfaces/transaction-details/rows/i-approval-status-row';
import { ICardRow } from 'ui-interfaces/transaction-details/rows/i-card-row';
import { IPaymentTypeRow } from 'ui-interfaces/transaction-details/rows/i-payment-type-row';
import { ILinkRow } from 'ui-interfaces/transaction-details/rows/i-link-row';
// MT103 - start
import { IMt103Row } from 'ui-interfaces/transaction-details/rows/mt103/i-mt103-row';
import { IMt103NoValueRow } from 'ui-interfaces/transaction-details/rows/mt103/i-mt103-no-value-row';
import { IMt103Indent1Row } from 'ui-interfaces/transaction-details/rows/mt103/i-mt103-indent-1-row';
import { IMt103Indent1NoValueRow } from 'ui-interfaces/transaction-details/rows/mt103/i-mt103-indent-1-no-value-row';
import { IMt103Indent2Row } from 'ui-interfaces/transaction-details/rows/mt103/i-mt103-indent-2-row';
// MT103 - end
// rows interfaces - end

// rows components - start
import { LoadingRow } from 'components/shared/detail-page/rows/LoadingRow';
import { TextRow } from 'components/shared/detail-page/rows/transactions/TextRow';
import { TextMultiLineRow } from 'components/shared/detail-page/rows/transactions/TextMultiLineRow';
import { ValueWithoutLabelRow } from 'components/shared/detail-page/rows/transactions/ValueWithoutLabelRow';
import { TimeRow } from 'components/shared/detail-page/rows/transactions/TimeRow';
import { ApprovalStatusRow } from 'components/shared/detail-page/rows/transactions/ApprovalStatusRow';
import { LinkRow } from 'components/shared/detail-page/rows/transactions/LinkRow';
import { CardRow } from 'components/shared/detail-page/rows/transactions/CardRow';
import { PaymentTypeRow } from 'components/tapi/transactions/TapiTransactionDetailsPage/rows/PaymentTypeRow';
// MT103 - start
import { MT103Row } from 'components/tapi/transactions/TapiTransactionDetailsPage/rows/mt-103/MT103Row';
import { MT103NoValueRow } from 'components/tapi/transactions/TapiTransactionDetailsPage/rows/mt-103/MT103NoValueRow';
import { MT103Indent1Row } from 'components/tapi/transactions/TapiTransactionDetailsPage/rows/mt-103/MT103Indent1Row';
import { MT103Indent1NoValueRow } from 'components/tapi/transactions/TapiTransactionDetailsPage/rows/mt-103/MT103Indent1NoValueRow';
import { MT103Indent2Row } from 'components/tapi/transactions/TapiTransactionDetailsPage/rows/mt-103/MT103Indent2Row';
// MT103 - end
// rows components - end

export const createTransactionDetailsRow = (
  itemData: IdentifiableUiElementTyped<RowType>,
  isLastItem: boolean,
): React.ReactElement => {
  switch (itemData.uiElementType) {
    case RowType.text: {
      const i = itemData as ITextRow;
      return <TextRow key={i.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }

    case RowType.textMultiLine: {
      const i = itemData as ITextRow;
      return <TextMultiLineRow key={i.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }

    case RowType.valueWithoutLabel: {
      const i = itemData as ITextRow;
      return <ValueWithoutLabelRow key={i.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }

    case RowType.time: {
      const i = itemData as ITextRow;
      return <TimeRow key={i.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }

    case RowType.approvalStatus: {
      const i = itemData as IApprovalStatusRow;
      return <ApprovalStatusRow key={i.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }

    case RowType.cardType: {
      const i = itemData as ICardRow;
      return <CardRow key={i.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }

    case RowType.paymentType: {
      const i = itemData as IPaymentTypeRow;
      return <PaymentTypeRow key={i.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }

    case RowType.link: {
      const i = itemData as ILinkRow;
      return <LinkRow key={i.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }

    case RowType.loading:
      return <LoadingRow key={itemData.id} hasBottomBorder={!isLastItem} />;

    // MT103 - start
    case RowType.mt103: {
      const i = itemData as IMt103Row;
      return <MT103Row key={itemData.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }

    case RowType.mt103NoValue: {
      const i = itemData as IMt103NoValueRow;
      return <MT103NoValueRow key={itemData.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }

    case RowType.mt103Indent1: {
      const i = itemData as IMt103Indent1Row;
      return <MT103Indent1Row key={itemData.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }

    case RowType.mt103Indent1NoValue: {
      const i = itemData as IMt103Indent1NoValueRow;
      return <MT103Indent1NoValueRow key={itemData.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }

    case RowType.mt103Indent2: {
      const i = itemData as IMt103Indent2Row;
      return <MT103Indent2Row key={itemData.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }
    // MT103 - end

    default: {
      const i = itemData as ITextRow;
      return <TextRow key={i.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }
  }
};
