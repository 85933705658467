import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Balance } from 'ui-interfaces/custodial-accounts-details/custodial-account-balance';
import { AutoHeightGrid } from 'components/shared/grids/AutoHeightGrid';
import { CommonAutoHeightGridBox } from 'components/shared/layouts/CommonLayout';
import { useLazyGetAccountBalancesByIdQuery } from 'redux/api/api-custodial-accounts';
import { AccountBalancesListFiltersViewModel } from 'view-models/account-balances/filters/account-balances-list-filters-vm';
import { Filterable } from 'ui-interfaces/shared/filters/filterable';
import { FilterResult } from 'filters/interfaces/filter-result';

import { useExport } from 'hooks/use-export';
import { onAccountBalancesExportClick, onAccountBalancesTransform } from 'handlers/tapi/custodial-accounts/handlers';
import { columnsAccountBalances } from './columns-definitions';

type Props = {
  custodialAccountId: string;
  setIsDetailsPageError: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AccountBalancesGrid: React.FC<Props> = ({ custodialAccountId, setIsDetailsPageError }) => {
  const { downloadCsv, isLoading, error } = useExport();

  // request boilerplate - start
  const [getAccountBalancesTrigger, accountsBalancesResult] = useLazyGetAccountBalancesByIdQuery();

  const fetchAccountBalances = useCallback(
    (page?: number, filterResult?: FilterResult) =>
      getAccountBalancesTrigger({ custodialAccountId, page, filterResult }, false),
    [getAccountBalancesTrigger, custodialAccountId],
  );

  const [rows, setRows] = useState<Balance[]>([]);
  const [resourceCount, setResourceCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(accountsBalancesResult.isUninitialized);
  const [filterable] = useState<Filterable>(() => new AccountBalancesListFiltersViewModel());

  useEffect(() => {
    fetchAccountBalances();
  }, [fetchAccountBalances]);
  // request boilerplate - end

  useEffect(() => {
    if (accountsBalancesResult.isSuccess && accountsBalancesResult.data) {
      const transformedItems = accountsBalancesResult.data.data.map(onAccountBalancesTransform);
      setRows(transformedItems);
      setResourceCount(accountsBalancesResult.data.meta?.resourceCount ?? 0);
      setLoading(accountsBalancesResult.isFetching);
    }
  }, [accountsBalancesResult.data, accountsBalancesResult.isFetching, accountsBalancesResult.isSuccess]);

  // handle all page error - start
  useEffect(() => {
    setIsDetailsPageError(Boolean(accountsBalancesResult.error || error));
  }, [error, accountsBalancesResult.error, setIsDetailsPageError]);
  // handle all page error - end

  const { t } = useTranslation();
  return (
    <CommonAutoHeightGridBox>
      <AutoHeightGrid
        columns={columnsAccountBalances}
        headerName={t('custodialAccountDetailsPage.accountBalances.title')}
        filters={filterable.filters}
        selectionMacroCommand={filterable.selectionMacroCommand}
        noResultsTitle=""
        noResultsSubtitle=""
        containerHeightWhenNonAHMode="515px"
        rows={rows}
        rowCount={resourceCount}
        isLoading={loading}
        error={accountsBalancesResult.error || error}
        fetchOtherItems={fetchAccountBalances}
        onExportClick={onAccountBalancesExportClick(downloadCsv, custodialAccountId!)}
        isExportLoading={isLoading}
      />
    </CommonAutoHeightGridBox>
  );
};
