import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  image: { height: 28, width: 97, marginBottom: 16 },
  divider: {
    backgroundColor: '#F1F1F1',
    width: '100%',
    height: 1,
    marginBottom: 32,
  },
});
