import React from 'react';
import { useSelector } from 'react-redux';
import Loader from 'components/global/Loader';
import { selectIsLoaderDisplayed } from 'redux/loader';

export const LoaderContainer: React.FC = () => {
  const isLoaderDisplayed = useSelector(selectIsLoaderDisplayed);

  return <Loader isDisplayed={isLoaderDisplayed} />;
};
