import React, { useCallback, useEffect, useState } from 'react';
import Menu from '@mui/material/Menu';
import { IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Action } from 'ui-enums/users/action-cell-type';
import { ResetActionItem } from 'components/shared/Cells/ActionCell/containers/ResetActionItem';
import { ResendActionItem } from 'components/shared/Cells/ActionCell/containers/ResendActionItem';
import { EditActionItem } from 'components/shared/Cells/ActionCell/containers/EditActionItem';
import { RemoveActionItem } from 'components/shared/Cells/ActionCell/containers/RemoveActionItem';
import { DestroyActionModal } from 'components/shared/modals/DestroyActionModal';
import {
  getRoleTemplates,
  getUser,
  useEditUserMutation,
  useDeleteUserByIdUsersListMutation,
  useLazyGetUserByIdQuery,
  UserByIdApiParams,
} from 'redux/api/api-users';
import { EditUserDialog } from 'components/settings/EditUserDialog';
import { IEditUserFormViewModel } from 'ui-interfaces/user-form/edit-user-form/i-edit-user-form-vm';
import { createEditUserFormViewModel } from 'factories/users/edit-user-form-vm';
import { useLoader } from 'hooks/use-loader';
import { ActionCellAction } from 'ui-interfaces/users/action-cell-action';
import { UpdateUserDetailRequestModel } from 'models/request/users/update-user-detail-request-model';

type Props = {
  actions: ActionCellAction[];
  userId: string;
  destroyModalMainText: string;
};

export const ActionCell: React.FC<Props> = ({ actions, userId, destroyModalMainText }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);

  const onOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const onCloseMenu = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
    // TODO find out if we must add setAnchorEl and setOpen to dependency list
  }, []);

  const createActionCellItem = ({ isDisplayed, ...action }: ActionCellAction) => {
    if (!isDisplayed) {
      return null;
    }

    switch (action.type) {
      case Action.edit:
        return <EditActionItem key={action.type} {...action} onClick={onUserEditActionClick} />;
      case Action.reset:
        return <ResetActionItem key={action.type} userId={userId} {...action} onCloseMenu={onCloseMenu} />;
      case Action.resend:
        return <ResendActionItem key={action.type} userId={userId} {...action} onCloseMenu={onCloseMenu} />;
      case Action.remove:
        return <RemoveActionItem key={action.type} onClick={onRemoveActionItemClick} {...action} />;

      default:
        return null;
    }
  };

  const [isDestroyDialogOpen, setIsDestroyDialogOpen] = useState(false);

  // remove action item - start
  const [deleteUserByIdTrigger, deleteUserByIdResult] = useDeleteUserByIdUsersListMutation();
  const removeUser = useCallback(
    (id: string) => {
      deleteUserByIdTrigger({ userId: id } as UserByIdApiParams);
    },
    [deleteUserByIdTrigger],
  );

  const onClickRemoveUser = useCallback(() => {
    removeUser(userId);
  }, [removeUser, userId]);

  const onRemoveActionItemClick = () => {
    onCloseMenu();
    setIsDestroyDialogOpen(true);
  };
  // remove action item - end

  const { isSuccess: areRoleTemplatesLoaded, data: roleTemplatesData } = useSelector(
    getRoleTemplates.select(undefined),
  );
  const [canEditDialogBeOpened, setCanEditDialogBeOpened] = useState(false);
  const onUserEditActionClick = () => {
    onCloseMenu();
    setCanEditDialogBeOpened(true);
    fetchUserById();
  };
  const onEditUserDialogClose = () => {
    setCanEditDialogBeOpened(false);
  };
  const [editUser, { isLoading: isEditUserLoading, isSuccess: isEditUserSuccess }] = useEditUserMutation();

  const onUserEdit = (formValues: UpdateUserDetailRequestModel) => {
    if (editUserFormViewModel) {
      editUser({ userId, data: formValues, skipInvalidateUserByIdTag: true });
    }
  };

  useEffect(() => {
    if (isEditUserSuccess) {
      setCanEditDialogBeOpened(false);
    }
  }, [isEditUserSuccess]);

  const { data: currentUserData } = useSelector(getUser.select(undefined));
  const [getUserByIdTrigger, userByIdResult] = useLazyGetUserByIdQuery();

  const fetchUserById = useCallback(
    () => getUserByIdTrigger({ userId: userId! } as UserByIdApiParams),
    [getUserByIdTrigger, userId],
  );

  const [editUserFormViewModel, setEditUserFormViewModel] = useState<IEditUserFormViewModel>();

  useEffect(() => {
    if (userByIdResult.isSuccess && currentUserData && roleTemplatesData) {
      setEditUserFormViewModel(
        createEditUserFormViewModel(userByIdResult.data, currentUserData, roleTemplatesData.data),
      );
    }
  }, [userByIdResult, currentUserData, roleTemplatesData]);

  const areRowUserDetailsLoaded = userByIdResult.data?.id === userId;
  useLoader(userByIdResult.isLoading);
  const isEditUserDialogOpen = canEditDialogBeOpened && areRowUserDetailsLoaded && areRoleTemplatesLoaded;
  const actionCellItems = actions.map(createActionCellItem);
  const hasActionCellItemsToDisplay = actionCellItems.some(actionCellItem => !!actionCellItem);

  return (
    <div>
      {hasActionCellItemsToDisplay && (
        <>
          <IconButton onClick={onOpenMenu}>
            <MoreVert />
          </IconButton>
          <Menu anchorEl={anchorEl} open={open} onClose={onCloseMenu}>
            {actionCellItems}
          </Menu>
        </>
      )}
      <DestroyActionModal
        isOpen={isDestroyDialogOpen}
        onClose={() => setIsDestroyDialogOpen(false)}
        onDestroyButtonClick={onClickRemoveUser}
        title={t('actionDialog.title')}
        captionText={t('actionDialog.users.destroyCaptionText')}
        mainText={destroyModalMainText}
        destroyButtonTitle={t('actionDialog.users.destroyButtonTitle')}
        destroyButtonLoading={deleteUserByIdResult.isLoading}
      />
      {editUserFormViewModel && (
        <EditUserDialog
          formViewModel={editUserFormViewModel}
          isOpen={isEditUserDialogOpen}
          onClose={onEditUserDialogClose}
          onUserEdit={onUserEdit}
          isLoading={isEditUserLoading}
        />
      )}
    </div>
  );
};
