import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { pxToRem } from 'utils/styling-utils';

export const StyledDataGridLoading = styled(DataGrid)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  zIndex: theme.zIndex.mobileStepper,
  border: 'none',
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-virtualScroller': {
    overflow: 'hidden',
  },
  '& .MuiDataGrid-root': {
    border: 'none',
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    paddingInline: pxToRem(theme.gaps._20px),
    paddingBlock: pxToRem(theme.gaps._20px / 2),
    ...theme.typography.bodySmallBold,
    color: theme.palette.grey['600'],
  },
  '& .MuiDataGrid-columnHeaders': {
    border: 'none',
  },
  '& .MuiDataGrid-cell': {
    paddingInline: pxToRem(theme.gaps._20px),
    paddingBlock: pxToRem(theme.gaps._20px),
    ...theme.typography.bodyDefaultBook,
  },
  '& .MuiTablePagination-root .MuiTablePagination-displayedRows ': {
    ...theme.typography.bodyDefaultBook,
    marginBlock: pxToRem(theme.gaps._20px),
  },
}));
