import React, { useMemo } from 'react';
import { OnCloseElement } from 'elements/models/types/element-result';
import { ElementDialog } from 'elements/components';
import { tradeElement } from 'elements/element-trade/api';
import { useElement } from './contexts/Element';
import { Paths } from './navigation/routes';
import { createAppContent } from './utils/createAppContent';

type Props = {
  onClose: OnCloseElement;
};

const App: React.FC<Props> = ({ onClose }) => {
  const { state } = useElement();

  const isCloseConfirmationRequired =
    state.page.location === Paths.Review || state.page.location === Paths.PaymentMethod;

  const onTradeClose = useMemo(
    () => async () => {
      onClose();
      if (state.lockId) {
        await tradeElement.unlockPaymentAmount(state.lockId);
      }
    },
    [onClose, state.lockId],
  );

  const content = useMemo(
    () => createAppContent(state.page.location, onTradeClose),
    [state.page.location, onTradeClose],
  );

  return (
    <ElementDialog onClose={onTradeClose} isCloseConfirmationRequired={isCloseConfirmationRequired}>
      {content}
    </ElementDialog>
  );
};

export default App;
