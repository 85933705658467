import React from 'react';
import Typography from '@mui/material/Typography';
import { Pillow } from 'components/shared/Pillow';
import { LevelCellProps } from 'ui-interfaces/kyc-levels/level-cell-props';

export const LevelCell: React.FC<LevelCellProps> = ({ text, background, levelLabel }) => {
  return (
    <Pillow {...background}>
      <Typography {...text} variant="overlineSmallBold">
        {levelLabel}
      </Typography>
    </Pillow>
  );
};
