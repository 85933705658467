import i18n from 'localizations';
import { OVERVIEW_SECTION } from 'view-models/transactions/details/constants';
import { SectionType } from 'ui-enums/transaction-detail/section-type';
import { LabeledSectionIdentifiable } from 'ui-interfaces/transaction-details/sections/labeled-section-identifiable';

export const getOverviewSectionBasicProps = (): LabeledSectionIdentifiable => ({
  id: OVERVIEW_SECTION,
  sectionType: SectionType.plain,
  label: i18n.t('transactionDetailsPage.transactionOverview'),
});
