import 'react-phone-input-2/lib/material.css';
import React, { ForwardedRef } from 'react';
import { PhoneInputProps } from 'react-phone-input-2';
import { StyledPhoneInput } from './styled';

type Props = Omit<PhoneInputProps, 'onChange'> & {
  name: string;
  onChange?: (value: string) => void;
};

export const PhoneInput = React.forwardRef(({ name, ...restProps }: Props, ref: ForwardedRef<HTMLInputElement>) => {
  return (
    <StyledPhoneInput
      {...restProps}
      inputProps={{ name, ref }}
      placeholder="+1 (702) 123-4567"
      enableLongNumbers
      disableDropdown
    />
  );
});
