import React from 'react';
import { MainNavigation } from 'navigation/main-navigation';
import { ThemeProvider } from 'styles/themes/context/ThemeProvider';
import { THEME_NAMES } from 'styles/themes/theme-constants';
import BannerContainer from 'containers/Banner';
import { LoaderContainer } from 'containers/Loader';

function App() {
  return (
    <ThemeProvider defaultTheme={THEME_NAMES.main}>
      <BannerContainer />
      <MainNavigation />
      <LoaderContainer />
    </ThemeProvider>
  );
}

export default App;
