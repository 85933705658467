import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { pxToRem } from 'utils/styling-utils';
import { Row } from 'components/shared/Flex/Row';

const COLUMNS_ROW_HEIGHT_BORDER_MARGIN = 1;

type StyledBoxProps = {
  columnsRowHeight: number;
};

export const StyledBox = styled(Box)<StyledBoxProps>(({ theme, columnsRowHeight }) => ({
  marginTop: `${columnsRowHeight + COLUMNS_ROW_HEIGHT_BORDER_MARGIN}px`, // needs to be in px as this value is dynamically calculated
  boxShadow: theme.customShadows.grey._2dp,
  borderRadius: pxToRem(theme.gaps._8px),
  backgroundColor: theme.palette.background.paper,
}));

export const StyledInputsRow = styled(Row)(({ theme }) => ({
  padding: pxToRem(theme.gaps._24px),
  borderBottom: `1px solid ${theme.palette.grey['100']}`,
}));

export const StyledButtonsRow = styled(Row)(({ theme }) => ({
  justifyContent: 'space-between',
  paddingInline: pxToRem(theme.gaps._24px),
  paddingBlock: pxToRem(theme.gaps._16px),
}));
