import { css } from '@mui/material';
import { CountryDropdown } from 'elements/components';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledDisabledCountryDropdown = elementsStyled(CountryDropdown)(({ theme, error, disabled }) => {
  const borderColor = error ? theme.palette.error.light2 : theme.palette.tertiary.main;

  return css`
    & .MuiFilledInput-root {
      border: 1px solid ${borderColor};

      &.Mui-disabled {
        background: ${theme.palette.tertiary.mainTransparent60};
        .MuiInputBase-input {
          padding-left: 12px;
        }
      }

      & .MuiSelect-icon {
        color: ${disabled ? theme.palette.primary.fontTransparent50 : theme.palette.secondary.font};
      }

      @media (hover: hover) {
        &:hover {
          border: 1px solid ${borderColor};
        }
      }

      &.Mui-focused {
        border: 1px solid ${borderColor};
      }
    }
    & .MuiInputLabel-root {
      &.MuiInputLabel-shrink {
        left: unset;
      }
    }
  `;
});
