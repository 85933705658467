import React from 'react';
import Typography from '@mui/material/Typography';
import i18n from 'localizations';
import { ReactComponent as FilterEmpty } from 'assets/icons/filters/filter-empty.svg';
import { ReactComponent as FilterApplied } from 'assets/icons/filters/filter-applied.svg';
import { Row } from 'components/shared/Flex/Row';
import { Gap } from 'components/shared/Gap';
import { StyledButton } from './styled';

type Props = {
  isDisabled: boolean;
  isFilterApplied: boolean;
  onClick: () => void;
};

export const FiltersButton: React.FC<Props> = ({ isDisabled, isFilterApplied, onClick }) => {
  return (
    <StyledButton
      sx={{
        opacity: isDisabled ? 0 : 1,
      }}
      disabled={isDisabled}
      variant="outlined"
      onClick={onClick}
    >
      <Row>
        {isFilterApplied ? <FilterApplied /> : <FilterEmpty />}
        <Gap size="_12px" isHorizontal />
        <Typography variant="buttonMedium">{i18n.t('filters.filterButtonLabel')}</Typography>
      </Row>
    </StyledButton>
  );
};
