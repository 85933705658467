import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import * as api from 'elements/element-transfer/api';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { TransferFlow } from 'elements/element-transfer/types';
import { PaymentMethods } from 'elements/features/PaymentMethods';
import { setErrorBanner } from 'elements/utils';
import { setDepositAchPaymentAccount, setPage, useElement } from 'elements/element-transfer/contexts/Element';
import { ElementClient } from 'elements/models/types/element-client';
import { ElementName } from 'elements/models/enums/element-name';
import { WindowWithFortressElementsJS } from 'elements/models/types/window-with-fortress-elemets-js';
import { ElementResultStatus } from 'elements/models/enums/element-result-status';
import { OnCloseElement } from 'elements/models/types/element-result';

const PaymentMethodPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useElement();
  const achDepositState = state[TransferFlow.Deposit].ACH;

  const [isLoading, setIsLoading] = useState(false);
  const [addBankAccountElement, setAddBankAccountElement] = useState<ElementClient | null>(null);

  const onPaymentAccountSelect = (paymentAccountId: string) => {
    const account = achDepositState?.externalAccountList.find(el => el.id === paymentAccountId);
    dispatch(setDepositAchPaymentAccount(account));
  };

  const onOpenAddBankAccount = async (): Promise<void> => {
    try {
      setIsLoading(true);

      const data = await api.transferElement.getAddBankAccountJwt();

      if (typeof (window as WindowWithFortressElementsJS).FortressElementsJS !== 'undefined') {
        const EJS = (window as WindowWithFortressElementsJS).FortressElementsJS;
        const el = EJS.createElementClient({
          elementName: ElementName.BANK_ACCOUNT,
        });
        setAddBankAccountElement(el);
        el.run(data.jwt);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorBanner(error, enqueueSnackbar, true);
    }
  };

  useEffect(() => {
    if (addBankAccountElement) {
      addBankAccountElement.done(result => {
        addBankAccountElement.destroy();
        setAddBankAccountElement(null);

        if (result.status === ElementResultStatus.Success) {
          // eslint-disable-next-line no-console
          console.log('Bank account successfully linkend!');
          dispatch(setPage(Paths.DepositAchAmount, { linkedAccountId: result?.bankAccount?.id }));
        } else {
          dispatch(setPage(Paths.DepositAchAmount));
        }
        setIsLoading(false);
      });
    }

    return () => addBankAccountElement?.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addBankAccountElement]);

  if (!achDepositState) return null;

  return (
    <PaymentMethods
      isLoading={isLoading}
      withCustodialAccounts={false}
      withCardAccounts={!!state.paymentMethodTypesMap.card}
      withBankAccounts={!!state.paymentMethodTypesMap.ach}
      currentPaymentAccount={achDepositState.paymentAccount}
      externalAccountList={achDepositState.externalAccountList}
      onPaymentAccountSelect={onPaymentAccountSelect}
      onOpenAddBankAccount={onOpenAddBankAccount}
      onClose={() => dispatch(setPage(Paths.DepositAchAmount))}
      outletOnClose={onClose}
    />
  );
};

export default PaymentMethodPage;
