import React from 'react';
import { ButtonProps } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';
import { Gap } from 'components/shared/Gap';
import { StyledButton, StyledCircularProgress, StyledContainer, StyledSkeleton } from './styled';

interface Props extends ButtonProps {
  text: React.ReactNode;
  icon?: React.ReactNode;
  loading: boolean;
  isSkeletonDisplayed?: boolean;
}

export const LoadingButtonWithSkeleton: React.FC<Props> = ({
  text,
  icon,
  loading,
  disabled,
  isSkeletonDisplayed = false,
  ...restProps
}) => {
  const isContentHidden = loading || isSkeletonDisplayed;
  const isButtonDisabled = loading || disabled || isSkeletonDisplayed;
  const loader = loading ? <StyledCircularProgress color="inherit" size={pxToRem(18)} /> : null;
  const skeleton = isSkeletonDisplayed ? <StyledSkeleton /> : null;
  const iconWithGap = icon ? (
    <>
      {icon}
      <Gap isHorizontal size="_8px" />
    </>
  ) : null;

  return (
    <StyledButton {...restProps} disabled={isButtonDisabled} loading={loading}>
      <StyledContainer isContentHidden={isContentHidden}>
        {iconWithGap}
        {text}
      </StyledContainer>
      {loader}
      {skeleton}
    </StyledButton>
  );
};
