import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { StyledContainer, StyledIconContainer, StyledStarIcon, StyledTextContainer } from './styled';

type Props = {
  text: string;
};

const PurposeOfPayment: React.FC<Props> = ({ text }) => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <StyledIconContainer>
        <StyledStarIcon />
      </StyledIconContainer>
      <StyledTextContainer>
        <Typography variant="captionMedium" color="secondary.font">
          {t('elements.transfer.withdrawalWireReviewPage.purposeOfPayment')}
        </Typography>
        <Typography variant="bodyDefaultBook" color="primary.font">
          {text}
        </Typography>
      </StyledTextContainer>
    </StyledContainer>
  );
};

export default PurposeOfPayment;
