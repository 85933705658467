import { TransactionDetailResponseModel } from 'models/response/transactions/transaction-detail-response-model';
import { LabelLength } from 'ui-enums/label-length';
import { getSignOfTransaction } from 'utils/transactions/get-sign-of-transaction';
import { formatCurrency } from 'utils/format-currency';
import { getDynamicAssetLabel } from 'utils/labels-mapping/get-dynamic-asset-label';

export const getPdfAmountForDepositWithdrawalInternalTransferCrypto = (
  transaction: TransactionDetailResponseModel,
): string => {
  const label = `${getSignOfTransaction(transaction)}${transaction.cryptoAmount ?? 0} ${getDynamicAssetLabel(
    LabelLength.short,
    transaction.assetTicker,
    transaction.assetName,
  )}`;

  const subLabel = transaction.amount
    ? `${getSignOfTransaction(transaction)}${formatCurrency(transaction.amount ?? 0)} ${getDynamicAssetLabel(
        LabelLength.short,
        transaction.assetTicker,
        transaction.assetName,
      )}`
    : undefined;

  if (subLabel) {
    return `${label} / ${subLabel}`;
  }

  return label;
};
