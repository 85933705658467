import React from 'react';
import { PopoverProps } from '@mui/material';
import { Modal } from 'elements/components';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import PopoverModalContent, { PopoverModalContentProps } from './PopoverModalContent';
import { StyledPopover } from './styled';

export type PopoverModalProps = PopoverModalContentProps & Pick<PopoverProps, 'open' | 'anchorEl'>;

export const PopoverModal: React.FC<PopoverModalProps> = ({ onClosePopoverModal, open, anchorEl, ...props }) => {
  const { isMobile } = useIsMobile();

  return isMobile ? (
    <Modal alignBottom={isMobile} open={open} onClose={onClosePopoverModal}>
      <PopoverModalContent onClosePopoverModal={onClosePopoverModal} {...props} />
    </Modal>
  ) : (
    <StyledPopover
      open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      anchorEl={anchorEl}
      onClose={onClosePopoverModal}
    >
      <PopoverModalContent onClosePopoverModal={onClosePopoverModal} {...props} />
    </StyledPopover>
  );
};
