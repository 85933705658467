import { Button, css } from '@mui/material';
import { Stack } from 'elements/components';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledButton = elementsStyled(Button)(
  ({ theme }) => css`
    display: flex;
    gap: 12px;
    justify-content: space-between;
    padding: 20px 0;
    height: 100%;
    width: 100%;

    &:hover {
      background: transparent;
    }

    & > svg {
      color: ${theme.palette.primary.font};
      min-width: 24px;
    }
  `,
);
export const StyledStack = elementsStyled(Stack)`
  min-width: 0;

  & > svg {
    min-width: 24px;
  }
`;
