import i18n from 'localizations';

export enum errorsTypesContainedErrorText {
  LessThanFee = 'Withdrawal amount should be bigger than network fee',
  MinimalTransactionAmount = 'Withdrawal amount is lower than required minimal transaction amount, value must be greater than',
  MinimalAccountBalance = 'Withdrawal amount is lower than required minimal account balance, value must be greater than',
}

const convertErrorMessage = (errorMessage: string, coinAbbreviation: string) => {
  const getMinimalValue = (text: string) => text.split("'")[1];

  if (
    errorMessage.includes(errorsTypesContainedErrorText.LessThanFee) ||
    errorMessage.includes(errorsTypesContainedErrorText.MinimalTransactionAmount)
  ) {
    const minimalAmount = getMinimalValue(errorMessage);

    return i18n.t('elements.transfer.withdrawalCryptoReviewPage.minimumAmountError', {
      amount: minimalAmount,
      coinAbbreviation,
    });
  }

  if (errorMessage.includes(errorsTypesContainedErrorText.MinimalAccountBalance)) {
    const minimalAmount = getMinimalValue(errorMessage);

    return i18n.t('elements.transfer.withdrawalCryptoReviewPage.minimalAccountBalanceError', {
      amount: minimalAmount,
      coinAbbreviation,
    });
  }

  return '';
};

export default convertErrorMessage;
