import { DialogActions, DialogContent, DialogTitle, IconButton, styled, Typography } from '@mui/material';
import { Row } from 'components/shared/Flex/Row';
import { pxToRem } from 'utils/styling-utils';

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  padding: `${pxToRem(theme.gaps._64px)} ${pxToRem(theme.gaps._40px)} ${pxToRem(theme.gaps._12px)}`,
  textAlign: 'center',
  wordBreak: 'break-word',
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: `0 ${pxToRem(theme.gaps._40px)}`,
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: `${pxToRem(theme.gaps._32px)} ${pxToRem(theme.gaps._40px)} ${pxToRem(theme.gaps._40px)}`,
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: pxToRem(theme.gaps._16px),
  right: pxToRem(theme.gaps._16px),
  height: pxToRem(36),
  width: pxToRem(36),
  padding: 0,
}));

export const StyledRow = styled(Row)(() => ({
  justifyContent: 'center',
}));

export const StyledTypography = styled(Typography)(() => ({
  textAlign: 'center',
  wordBreak: 'break-word',
}));
