import i18n from 'i18next';
import { RoleTemplate } from 'models/enums/user/role-template';

export const getUserRoleLabel = (roleTemplate: RoleTemplate) => {
  switch (roleTemplate) {
    case RoleTemplate.admin:
      return i18n.t('roleTemplates.admin');

    case RoleTemplate.editor:
      return i18n.t('roleTemplates.editor');

    case RoleTemplate.viewer:
      return i18n.t('roleTemplates.viewer');

    default:
      return i18n.t('notImplemented');
  }
};
