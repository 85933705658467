import { Box, Typography } from '@mui/material';
import { css } from '@mui/material/styles';
import { Stack } from 'elements/components';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledContainer = elementsStyled(Stack)(
  ({ theme }) => css`
    width: 100%;
    border: 1px solid ${theme.palette.tertiary.main};
    border-radius: 8px;
  `,
);

export const StyledAccountContainer = elementsStyled(Stack)(
  css`
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 16px;
  `,
);

export const StyledAmountContainer = elementsStyled(Stack)(
  css`
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    gap: 16px;
  `,
);

export const StyledIconContainer = elementsStyled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: ${theme.palette.secondary.mainTransparent10};

    & svg {
      flex-shrink: 0;
      color: ${theme.palette.secondary.main};
    }
  `,
);

export const StyledTruncatedTypography = elementsStyled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledTextContainer = elementsStyled(Stack)`
  overflow: hidden;
  gap: 6px;
`;
