import React from 'react';
import { Row } from 'components/tapi/transactions/TapiTransactionDetailsPage/pdf/rows/shared/Row';
import { IPdfMt103NoValueRow } from 'ui-interfaces/transaction-details/pdf/rows/mt-103/i-pdf-mt103-no-value-row';
import { RightCell } from './RightCell';

type Props = {
  data: IPdfMt103NoValueRow;
};

export const PdfMT103NoValueRow: React.FC<Props> = ({ data }) => {
  return (
    <Row>
      <RightCell code={data.code} value={data.label} />
    </Row>
  );
};
