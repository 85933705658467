import i18n from 'localizations';
import { TransactionDetailResponseModel } from 'models/response/transactions/transaction-detail-response-model';
import { PdfRowType } from 'ui-enums/pdf/pdf-row-type';
import { PdfNode } from 'ui-interfaces/transaction-details/pdf/pdf-node';
import { IPlainRow } from 'ui-interfaces/transaction-details/pdf/rows/i-plain-row';
import { formatDateAndTimeInUTC } from 'utils/format-date';

export const generateFedReferencePdfItems = (imad: TransactionDetailResponseModel['imad']): PdfNode[] => {
  if (!imad) {
    return [];
  }

  return [
    {
      id: 'fedReference',
      title: i18n.t('transactionDetailsPage.fedReference'),
      items: [
        {
          id: 'imadNumber',
          label: i18n.t('transactionDetailsPage.labels.imadNumberLabel'),
          rowType: PdfRowType.plain,
          value: imad.raw || i18n.t('transactionDetailsPage.labels.noData'),
        } as IPlainRow,
        {
          id: 'dateInitiated',
          label: i18n.t('transactionDetailsPage.labels.dateLabel'),
          rowType: PdfRowType.plain,
          value: imad.parsed?.cycleDate
            ? `${formatDateAndTimeInUTC(imad?.parsed?.cycleDate)}`
            : i18n.t('transactionDetailsPage.labels.noData'),
        } as IPlainRow,
        {
          id: 'sourceIdentifier',
          label: i18n.t('transactionDetailsPage.labels.sourceIdentifierLabel'),
          rowType: PdfRowType.plain,
          value: imad.parsed?.inputSource || i18n.t('transactionDetailsPage.labels.noData'),
        } as IPlainRow,
        {
          id: 'inputSequenceNumber',
          label: i18n.t('transactionDetailsPage.labels.sequenceNumberLabel'),
          rowType: PdfRowType.plain,
          value: imad.parsed?.inputSequenceNumber || i18n.t('transactionDetailsPage.labels.noData'),
        } as IPlainRow,
      ],
    },
  ];
};
