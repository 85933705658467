import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchInput, Spacer } from 'elements/components';

type Props = {
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
};

export const SubheaderSearcherNew: React.FC<Props> = ({ searchQuery, setSearchQuery }) => {
  const { t } = useTranslation();

  return (
    <>
      <Spacer size={24} />
      <SearchInput
        searchQuery={searchQuery}
        onSearch={setSearchQuery}
        placeholder={t('elements.features.coinsListPage.searchCoinPlaceholder')}
        withDiscardIcon
        data-testid="SearcherInput-testId"
      />
    </>
  );
};
