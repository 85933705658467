import i18n from 'localizations';
import { FilterValueSet } from 'filters/interfaces/filter-value-set';
import { IdentityStatusModel } from 'models/enums/identities/identity-status-model';

export class IdentityValueSets {
  static readonly identityStatusModelValueSet: FilterValueSet[] = [
    {
      value: IdentityStatusModel.active,
      displayValue: i18n.t('identitiesPage.statuses.active'),
    },
    {
      value: IdentityStatusModel.inactive,
      displayValue: i18n.t('identitiesPage.statuses.inactive'),
    },
  ];
}
