import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { pxToRem } from 'utils/styling-utils';

export const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: pxToRem(theme.gaps._8px),
  width: pxToRem(32),
  height: pxToRem(32),
}));

export const StyledTitleTypography = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
