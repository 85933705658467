import React, { useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useLazyGetTwoCustodialAccountsQuery } from 'redux/api/api-custodial-accounts';
import { PATHS } from 'navigation/constants';
import { getRouteWithParams } from 'utils/get-route-with-params';
import { CustodialAccountsDetailsUrlParams } from 'navigation/url-params-type';

export const CustodialAccountsPageRestriction: React.FC = () => {
  const [getTwoCustodialAccountsTrigger, { data, isSuccess, isFetching, isUninitialized }] =
    useLazyGetTwoCustodialAccountsQuery();

  useEffect(() => {
    getTwoCustodialAccountsTrigger(undefined);
  }, [getTwoCustodialAccountsTrigger]);

  if (isUninitialized || isFetching) {
    return null;
  }

  if (isSuccess && data?.data.length === 1) {
    return (
      <Navigate
        to={getRouteWithParams(PATHS.TAPI.CUSTODIAL_ACCOUNTS_DETAILS, {
          custodialAccountId: data.data[0].id,
        } as CustodialAccountsDetailsUrlParams)}
      />
    );
  }

  return <Outlet />;
};
