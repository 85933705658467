import React from 'react';
import { TransactionsPageTabs } from 'ui-enums/tabs/transactions/transactions-page-tabs';
import { AllTab } from 'components/tapi/transactions/TapiTransactionsPage/Tabs/AllTab';
import { ApprovalNeededTab } from 'components/tapi/transactions/TapiTransactionsPage/Tabs/ApprovalNeededTab';
import { RejectedTab } from 'components/tapi/transactions/TapiTransactionsPage/Tabs/RejectedTab';

type Props = {
  currentTab: TransactionsPageTabs;
};

export const TransactionTabsContent: React.FC<Props> = ({ currentTab }) => {
  return (
    <>
      {currentTab === TransactionsPageTabs.all && <AllTab />}
      {currentTab === TransactionsPageTabs.approvalNeeded && <ApprovalNeededTab />}
      {currentTab === TransactionsPageTabs.rejected && <RejectedTab />}
    </>
  );
};
