import React, { useCallback, useEffect, useState } from 'react';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { TransferFlow } from 'elements/element-transfer/types';
import ContentBlock from 'elements/element-transfer/components/Deposit/CryptoQrCodePage';
import { LoadingPage } from 'elements/features/LoadingErrorState';
import { setPage, useElement } from 'elements/element-transfer/contexts/Element';
import { OnCloseElement } from 'elements/models/types/element-result';
import {
  CustodialAccountCoinsDepositApiParams,
  useLazyGetCustodialAccountCoinsDepositInstructionsQuery,
} from 'redux/api/api-custodial-accounts';
import { AssetResponseModel } from 'models/response/assets/asset-response-model';

type Props = {
  onClose: OnCloseElement;
};

const CryptoQrCodePage: React.FC<Props> = ({ onClose }) => {
  const { state, dispatch } = useElement();
  const { selectedAsset } = state[TransferFlow.Deposit].CRYPTO;

  const onBack = () => {
    dispatch(setPage(Paths.DepositCryptoAddresses));
  };

  // coinsDepositInstructions (address) - start
  const [getCustodialAccountCoinsDepositInstructionsTrigger, getCustodialAccountCoinsDepositInstructionsResult] =
    useLazyGetCustodialAccountCoinsDepositInstructionsQuery();

  const fetchCustodialAccountCoinsDepositInstructions = useCallback(
    (assetResponseModel: AssetResponseModel) =>
      getCustodialAccountCoinsDepositInstructionsTrigger({
        custodialAccountId: state.custodialAccountId,
        network: assetResponseModel.network,
        asset: assetResponseModel.assetTicker,
      } as CustodialAccountCoinsDepositApiParams),
    [getCustodialAccountCoinsDepositInstructionsTrigger, state.custodialAccountId],
  );

  const getAddressForSelectedAsset = useCallback(() => {
    if (selectedAsset) {
      fetchCustodialAccountCoinsDepositInstructions(selectedAsset);
    }
  }, [selectedAsset, fetchCustodialAccountCoinsDepositInstructions]);

  useEffect(() => {
    getAddressForSelectedAsset();
  }, [getAddressForSelectedAsset]);

  const [address, setAddress] = useState<string>('');
  useEffect(() => {
    if (
      getCustodialAccountCoinsDepositInstructionsResult.isSuccess &&
      getCustodialAccountCoinsDepositInstructionsResult.data
    ) {
      setAddress(getCustodialAccountCoinsDepositInstructionsResult.data.address);
      dispatch(setPage(Paths.DepositCryptoQrCode));
    }
  }, [
    dispatch,
    getCustodialAccountCoinsDepositInstructionsResult.data,
    getCustodialAccountCoinsDepositInstructionsResult.isSuccess,
  ]);
  // coinsDepositInstructions (address) - end

  if (
    getCustodialAccountCoinsDepositInstructionsResult.isFetching ||
    getCustodialAccountCoinsDepositInstructionsResult.isUninitialized
  ) {
    return <LoadingPage onClose={onClose} />;
  }

  if (!selectedAsset) {
    return null;
  }

  return (
    <ContentBlock
      onBack={onBack}
      onClose={onClose}
      asset={selectedAsset}
      networkAddress={address}
      onReload={getAddressForSelectedAsset}
      isRequestError={getCustodialAccountCoinsDepositInstructionsResult.isError}
    />
  );
};

export default CryptoQrCodePage;
