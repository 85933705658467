import React from 'react';
import { ActionCellAction } from 'ui-interfaces/users/action-cell-action';
import { ActionCellItem } from '../../ActionCellItem';

interface Props extends Omit<ActionCellAction, 'isDisplayed'> {
  onClick: () => void;
}

export const RemoveActionItem: React.FC<Props> = ({ onClick, ...action }) => {
  return <ActionCellItem {...action} onClick={onClick} />;
};
