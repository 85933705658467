import { TCombinedCryptoAsset, TCombinedCryptoNetwork } from 'elements/types';

export const MIN_DOLLAR_AMOUNT = 20;

export type ProcessBuyOrderBodyType = {
  source: {
    custodialAccountId: string;
    externalAccountId?: string;
  };
  assetType: TCombinedCryptoAsset;
  network: TCombinedCryptoNetwork;
  amount: number;
  lockId: string;
  comment?: string;
};

export type ProcessSellOrderBodyType = {
  custodialAccountId?: string;
  assetType: TCombinedCryptoAsset;
  network: TCombinedCryptoNetwork;
  amount: number;
  lockId: string;
  comment?: string;
};
