import { DocumentResponseModel } from 'models/response/documents/document-response-model';
import { DocumentStatus } from 'models/enums/documents/document-status';
import { Document } from 'ui-interfaces/identity-details/document-list/document';
import { StatusCellProps } from 'ui-interfaces/statuses/status-cell-props';
import { Side } from 'models/response/documents/document-side-model';
import { formatDate } from 'utils/format-date';
import { DocTypes, parseFormatDocument } from 'utils/parse-format-document';
import { mapDocumentStatusToStatusCellProps } from 'utils/multi-mapping/statuses/map-document-status-to-status-cell-props';

export class DocumentViewModel implements Document {
  readonly sideId: string;

  readonly documentId: string;

  readonly fileName: string;

  readonly submitted: string;

  readonly documentStatus: DocumentStatus;

  readonly docType: DocTypes;

  readonly statusCellProps: StatusCellProps;

  constructor(document: DocumentResponseModel, side: Side) {
    this.sideId = side.id;
    this.documentId = document.id;
    this.fileName = side.fileName;
    this.submitted = formatDate(document.createdAt);
    this.documentStatus = document.documentCheckStatus as DocumentStatus;
    this.docType = parseFormatDocument(side.fileName);
    this.statusCellProps = mapDocumentStatusToStatusCellProps(document.documentCheckStatus);
  }
}
