import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import i18n from 'localizations';
import { TransactionsRow } from 'ui-interfaces/row-interfaces/transactions';
import { GuidCell } from 'components/shared/Cells/GuidCell';
import { StatusCell } from 'components/shared/Cells/StatusCell';
import { TypeCell } from 'components/shared/Cells/transactions/TypeCell';
import { TransactionAssetCell } from 'components/shared/Cells/transactions/TransactionAssetCell';
import { CompoundLabelCell } from './Cells/CompoundLabelCell';
import { DateCell } from './Cells/DateCell';
import { DateHeaderCell } from './DateColumnHeader';
import { AmountCell } from './Cells/AmountCell';

export const columnsTransactions: GridColDef[] = [
  {
    field: 'transactionId',
    headerName: i18n.t('transactionsPage.transactionId'),
    flex: 1,
    minWidth: 130,
    renderCell: (params: GridRenderCellParams) => {
      const { value } = params;
      return value ? <GuidCell id={params.value} numberOfDisplayedCharacters={4} /> : i18n.t('transactionsPage.noData');
    },
  },
  {
    field: 'paymentId',
    headerName: i18n.t('transactionsPage.paymentId'),
    flex: 1,
    minWidth: 130,
    renderCell: (params: GridRenderCellParams) => {
      return <GuidCell id={params.value} numberOfDisplayedCharacters={4} />;
    },
  },
  {
    field: 'date',
    renderHeader: () => <DateHeaderCell />,
    flex: 1,
    minWidth: 123,
    renderCell: (params: GridRenderCellParams) => {
      const { date, time, type } = params.row as TransactionsRow;
      return <DateCell date={date} time={time} type={type} />;
    },
  },
  {
    field: 'type',
    headerName: i18n.t('transactionsPage.type'),
    flex: 1.3,
    minWidth: 174,
    renderCell: (params: GridRenderCellParams) => <TypeCell type={params.value} width={params.colDef.computedWidth} />,
  },
  {
    field: 'status',
    headerName: i18n.t('transactionsPage.status'),
    flex: 1,
    minWidth: 175,
    renderCell: (params: GridRenderCellParams) => {
      const { statusCellProps, statusTooltipMessage } = params.row as TransactionsRow;
      if (statusTooltipMessage) {
        return <StatusCell {...statusCellProps} tooltipMessage={statusTooltipMessage} />;
      }
      return <StatusCell {...statusCellProps} />;
    },
  },
  {
    field: 'asset',
    headerName: i18n.t('transactionsPage.asset'),
    flex: 0.5,
    minWidth: 75,
    renderCell: (params: GridRenderCellParams) => {
      const { assetCellPops } = params.row as TransactionsRow;
      return <TransactionAssetCell data={assetCellPops} />;
    },
  },
  {
    field: 'amount',
    headerName: i18n.t('transactionsPage.amount'),
    flex: 1.3,
    minWidth: 142,
    headerAlign: 'left',
    align: 'left',
    renderCell: (params: GridRenderCellParams) => {
      const { amount, color } = params.row as TransactionsRow;
      return <AmountCell value={amount} width={params.colDef.computedWidth} color={color} />;
    },
  },
  {
    field: 'source',
    headerName: i18n.t('transactionsPage.source'),
    flex: 1,
    minWidth: 117,
    renderCell: (params: GridRenderCellParams) => {
      return <CompoundLabelCell value={params.value} width={params.colDef.computedWidth} />;
    },
  },
  {
    field: 'destination',
    headerName: i18n.t('transactionsPage.destination'),
    flex: 1,
    minWidth: 117,
    renderCell: (params: GridRenderCellParams) => (
      <CompoundLabelCell value={params.value} width={params.colDef.computedWidth} />
    ),
  },
];
