import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorState } from 'elements/features/LoadingErrorState';
import FlowFirstPageLayout from 'elements/element-transfer/components/FlowFirstPageLayout';
import { OnCloseElement } from 'elements/models/types/element-result';
import { AssetResponseModel } from 'models/response/assets/asset-response-model';
import { CoinsListNew } from 'elements/features/CoinsListNew';
import { SubheaderSearcherNew } from 'elements/features/CoinsListNew/SubheaderSearcherNew';

type Props = {
  assets: AssetResponseModel[];
  isRequestError: boolean;
  onClose: OnCloseElement;
  onBack: () => void;
  onReload: () => void;
};

const CryptoAddressesPage: React.FC<Props> = ({ assets, isRequestError, onClose, onBack, onReload }) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <FlowFirstPageLayout
      title={t('elements.transfer.customizableLabels.depositCryptoListPageTitle')}
      withHeader
      isContentCentered={isRequestError}
      onBack={onBack}
      onClose={onClose}
      {...(assets.length >= 8 && {
        subHeader: <SubheaderSearcherNew searchQuery={searchQuery} setSearchQuery={setSearchQuery} />,
      })}
    >
      {isRequestError ? <ErrorState onReload={onReload} /> : <CoinsListNew assets={assets} searchQuery={searchQuery} />}
    </FlowFirstPageLayout>
  );
};

export default CryptoAddressesPage;
