import React from 'react';
import { Box, Typography } from '@mui/material';
import { TransactionDetailNode } from 'ui-interfaces/transaction-details/transaction-detail-node';
import { createTransactionDetailsRow } from 'ui-factories/transaction-details/create-transaction-details-row';
import { Gap } from 'components/shared/Gap';
import { GrayGap, StyledBox } from './styled';
import SubHeaderRow from '../SubHeaderRow';

type Props = {
  node: TransactionDetailNode;
  hasBottomBorder: boolean;
};

export const DetailNode: React.FC<Props> = ({ node, hasBottomBorder }) => {
  return (
    <Box>
      {node.blockHeaderLabel && (
        <StyledBox>
          <Typography variant="h7">{node.blockHeaderLabel}</Typography>
        </StyledBox>
      )}
      {node.blockHeaderSubLabel && (
        <>
          <SubHeaderRow label={node.blockHeaderSubLabel} />
          <Gap size="_8px" />
        </>
      )}
      {node.blockItems.map((i, index) => {
        return createTransactionDetailsRow(i, index === node.blockItems.length - 1);
      })}
      {hasBottomBorder && <GrayGap size="_8px" />}
    </Box>
  );
};
