import React from 'react';
import { TransferFlow } from 'elements/element-transfer/types';
import ContentBlock from 'elements/element-transfer/components/Deposit/AchPlacedPage';
import { useElement } from 'elements/element-transfer/contexts/Element';
import { sendPaymentIdMessage } from 'elements/element-transfer/utils/sendPaymentIdMessage';
import { OnCloseElement } from 'elements/models/types/element-result';

const AchPlacedPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { state } = useElement();
  const achDepositState = state[TransferFlow.Deposit].ACH;

  const onDone = () => {
    onClose(sendPaymentIdMessage({ achDepositPaymentId: state.achDepositPaymentId }));
  };

  if (!achDepositState) return null;

  return (
    <ContentBlock
      depositAmount={+achDepositState.amount}
      paymentAccount={achDepositState.paymentAccount}
      custodialAccount={state.custodialAccount}
      onDone={onDone}
    />
  );
};

export default AchPlacedPage;
