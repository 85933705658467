import { PersonalIdentityRow } from 'ui-interfaces/row-interfaces/identities/personal-identity-row';
import { formatIdentityPhone } from 'utils/identities/format-identity-phone';
import { formatDate } from 'utils/format-date';
import { KycLevel } from 'models/enums/identities/kyc-level';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { PersonalIdentityResponseModel } from 'models/response/identities/personal/personal-identity-response-model';
import { StatusCellProps } from 'ui-interfaces/statuses/status-cell-props';
import { LevelCellProps } from 'ui-interfaces/kyc-levels/level-cell-props';
import { mapIdentityStatusModelToStatusCellProps } from 'utils/multi-mapping/statuses/map-identity-status-model-to-status-cell-props';
import { mapKycLevelToLevelCellProps } from 'utils/multi-mapping/kyc-levels/map-levels-to-level-cell-props';

export class PersonalIdentityViewModel implements PersonalIdentityRow {
  readonly id: string;

  readonly firstName: string;

  readonly lastName: string;

  readonly email: string;

  readonly phone: string;

  readonly kycLevel: KycLevel;

  readonly dateCreated: string;

  readonly statusCellProps: StatusCellProps;

  readonly kycLevelCellProps: LevelCellProps;

  constructor(identity: PersonalIdentityResponseModel, user: ShortUserResponseModel) {
    this.id = identity.id;
    this.firstName = identity.firstName;
    this.lastName = identity.lastName;
    this.email = identity.email;
    this.phone = formatIdentityPhone(user, identity.phone);
    this.kycLevel = identity.kycLevel;
    this.dateCreated = formatDate(identity.createdAt);
    this.statusCellProps = mapIdentityStatusModelToStatusCellProps(identity.status, !identity.isSuspendedByIntegrator);
    this.kycLevelCellProps = mapKycLevelToLevelCellProps(identity.kycLevel);
  }
}
