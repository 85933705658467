import { Stack, css } from '@mui/material';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledContainer = elementsStyled(Stack)(
  ({ theme }) =>
    css`
      border: 1px solid ${theme.palette.tertiary.main};
      border-radius: 8px;
      text-align: center;
    `,
);
export const StyledHeader = elementsStyled(Stack)(
  ({ theme }) =>
    css`
      padding: 18px;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid ${theme.palette.tertiary.main};
      color: ${theme.palette.secondary.font};
    `,
);
export const StyledContent = elementsStyled(Stack)(
  ({ theme }) => css`
    padding: 13px 16px 16px;
    align-items: center;
    justify-content: center;
    color: ${theme.palette.primary.font};
  `,
);
