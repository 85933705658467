import { ElementConfigTransfer } from 'elements/models/types/config/element-config-transfer';
import { ElementName } from 'elements/models/enums/element-name';
import { PaymentMethod } from 'elements/models/enums/payment-method';
import { TransferDirection } from 'elements/models/enums/transfer-direction';

export const elementConfigTransferForDepositWithdrawal: ElementConfigTransfer = {
  elementName: ElementName.TRANSFER,
  paymentMethodTypes: PaymentMethod.wireCrypto,
  transferDirection: TransferDirection.depositWithdrawal,
};

export const elementConfigTransferForDeposit: ElementConfigTransfer = {
  elementName: ElementName.TRANSFER,
  paymentMethodTypes: PaymentMethod.wireCrypto,
  transferDirection: TransferDirection.deposit,
};
