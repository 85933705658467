import { styled, css } from '@mui/material/styles';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { pxToRem } from 'utils/styling-utils';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';

export const StyledHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: pxToRem(theme.gaps._32px),
  width: '100%',
  height: pxToRem(64),
  borderBottom: `1px solid ${theme.palette.grey['200']}`,
}));

export const StyledPageContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
}));

export const StyledContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
}));

export const StyledTypography = styled(Typography)(
  () => css`
    font-size: ${pxToRem(16)};
  `,
);

export const StyledLogo = styled(Logo)(
  ({ theme }) => css`
    color: ${theme.palette.primary.main};
  `,
);
