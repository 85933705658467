import { TransactionBaseViewModel } from 'view-models/transactions/list/transaction-base-vm';
import { TransactionsRow } from 'ui-interfaces/row-interfaces/transactions';
import i18n from 'localizations';
import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { formatCurrency } from 'utils/format-currency';
import { getSignOfTransaction } from 'utils/transactions/get-sign-of-transaction';

export class TransactionFiatBaseViewModel extends TransactionBaseViewModel implements TransactionsRow {
  constructor(transaction: TransactionResponseModel) {
    super(transaction);
    this.amount = `${getSignOfTransaction(transaction)}${formatCurrency(transaction.amount ?? 0)} ${i18n.t(
      'transactionsPage.usd',
    )}`;
  }
}
