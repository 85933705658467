import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { StyleErrorStateSubtext, StyledButton, StyledContainer } from './styled';

type Props = {
  onReload: () => void;
};

export const ErrorState: React.FC<Props> = ({ onReload, ...props }) => {
  const { t } = useTranslation();

  return (
    <StyledContainer {...props}>
      <Typography variant="h5" color="primary.font" textAlign="center">
        {t('elements.features.loadingErrorState.errorStateHeader')}
      </Typography>
      <StyleErrorStateSubtext variant="bodyDefaultBook" color="secondary.font">
        {t('elements.features.loadingErrorState.errorStateSubtext')}
      </StyleErrorStateSubtext>
      <StyledButton type="submit" onClick={onReload}>
        <Typography mr={2} variant="buttonLarge">
          {t('elements.features.loadingErrorState.reloadPageButtonLabel')}
        </Typography>
      </StyledButton>
    </StyledContainer>
  );
};
