import React from 'react';
import { ICryptoAssetsNameCell } from 'ui-interfaces/wallets/i-crypto-assets-name-cell';
import { AssetCell } from './AssetCell';

type Props = {
  data: ICryptoAssetsNameCell;
};

export const CryptoAssetsNameCell: React.FC<Props> = ({ data }) => {
  return (
    <AssetCell
      url={data.asset.imageUrl}
      size={data.size}
      defaultAssetIcon={data.asset.defaultAssetIcon}
      title={data.asset.longName}
      shortTitle={data.asset.shortName}
    />
  );
};
