import React from 'react';
import { Typography } from '@mui/material';
import { Stack } from 'elements/components';
import { AssetImage } from 'components/shared/Cells/AssetImage';
import { ISellCoinListItem } from 'ui-interfaces/elements/i-sell-coin-list-item';

type Props = {
  data: ISellCoinListItem;
};

export const CryptoSendCoinElement: React.FC<Props> = ({ data }) => {
  return (
    <Stack direction="row" alignCenter gap="16px">
      <AssetImage url={data.asset.imageUrl} size={data.size} defaultAssetIcon={data.asset.defaultAssetIcon} />
      <Stack gap="4px">
        <Typography variant="bodyDefaultMedium" color="primary.font">
          {data.asset.longName}
        </Typography>
        <Typography variant="captionMedium" color="secondary.font">
          {`${data.balance} ${data.asset.shortName}`}
        </Typography>
      </Stack>
    </Stack>
  );
};
