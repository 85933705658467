import { Paths } from 'elements/element-transfer/navigation/routes';
import { TransferFlow, WireBankInfoFormData, WireLocationType } from 'elements/element-transfer/types';
import { setPage, setWithdrawalWireBankInfo, useElement } from 'elements/element-transfer/contexts/Element';
import trimAndRemoveExtraSpacesFormData from 'elements/element-transfer/utils/wireWithdrawalUtils/trimAndRemoveExtraSpacesFormData';

const useWireWithdrawalBankInfoPage = () => {
  const { state, dispatch } = useElement();

  const initialFormData = state[TransferFlow.Withdrawal].WIRE.bankInfo;
  const { locationType } = state[TransferFlow.Withdrawal].WIRE.recipientInfo;
  const isDomestic = locationType === WireLocationType.Domestic;

  const continueHandler = (data: WireBankInfoFormData) => {
    dispatch(setWithdrawalWireBankInfo(trimAndRemoveExtraSpacesFormData(data)));
    dispatch(setPage(Paths.WithdrawalWireAccountNumbers));
  };

  const backHandler = () => {
    dispatch(setPage(Paths.WithdrawalWireRecipientInfo, { withBackButton: true }));
  };

  return { initialFormData, isDomestic, continueHandler, backHandler };
};

export default useWireWithdrawalBankInfoPage;
