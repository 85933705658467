import { NavLink } from 'react-router-dom';
import { alpha, css, styled } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { pxToRem } from 'utils/styling-utils';

export const StyledNavLink = styled(NavLink)(() => ({
  textDecoration: 'none',
  color: 'inherit',
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => {
  return css`
    color: ${alpha(theme.palette.background.paper, 0.7)};
    padding-inline: ${pxToRem(theme.gaps._20px)};
    padding-block: ${pxToRem(theme.gaps._12px)};
    margin-bottom: ${pxToRem(theme.gaps._8px)};
  `;
});

export const StyledListItemIcon = styled(ListItemIcon)(
  ({ theme }) => css`
    color: ${alpha(theme.palette.background.paper, 0.7)};
    min-width: 0;
    margin-right: ${pxToRem(theme.gaps._12px)};
  `,
);
