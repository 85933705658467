import { styled } from '@mui/material/styles';
import { Skeleton } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  height: pxToRem(theme.gaps._16px),
  width: '100%',
  borderRadius: pxToRem(theme.gaps._8px),
  backgroundColor: theme.palette.grey['100'],
}));
