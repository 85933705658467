import React, { useCallback, useEffect, useState } from 'react';
import { PathMatch, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FilterResult } from 'filters/interfaces/filter-result';
import { CommonGridBox } from 'components/shared/layouts/CommonLayout';
import { FiatAndCryptoTransactionsListFiltersViewModel } from 'view-models/transactions/list/filters/fiat-and-crypto/fiat-and-crypto-transactions-list-filters-vm';
import { TransactionsApiParams, useLazyGetFiatAndCryptoTransactionsQuery } from 'redux/api/api-transactions';
import { Filterable } from 'ui-interfaces/shared/filters/filterable';
import { TransactionsRow } from 'ui-interfaces/row-interfaces/transactions';
import {
  onApprovedTransactionRowClick,
  onApprovedTransactionsExportClick,
  onTransactionTransform,
} from 'handlers/tapi/custodial-accounts/handlers';
import { columnsTransactions } from 'components/tapi/transactions/TapiTransactionsPage/columns-definitions';
import { PaginatedExportGrid } from 'components/shared/grids/PaginatedExportGrid';
import { usePaginatedExport } from 'hooks/use-paginated-export';
import InlineInfoBanner from 'components/shared/InlineInfoBanner';
import { defaultTransactionFilterResult } from 'redux/api/default-filters';
import { AccountBalancesGrid } from './AccountBalancesGrid';

type Props = {
  custodialAccountId: string;
  pathMatch: PathMatch | null;
  setIsDetailsPageError: React.Dispatch<React.SetStateAction<boolean>>;
};

export const FiatCryptoTab: React.FC<Props> = ({ custodialAccountId, pathMatch, setIsDetailsPageError }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { downloadPaginatedCsv, isLoading, error } = usePaginatedExport();

  // request boilerplate - start
  const [getFiatAndCryptoTransactionsTrigger, fiatAndCryptoTransactionsResult] =
    useLazyGetFiatAndCryptoTransactionsQuery();

  const fetchFiatAndCryptoTransactions = useCallback(
    (page?: number, filterResult?: FilterResult) =>
      getFiatAndCryptoTransactionsTrigger({ page, custodialAccountId, filterResult } as TransactionsApiParams, false),
    [custodialAccountId, getFiatAndCryptoTransactionsTrigger],
  );

  const [rows, setRows] = useState<TransactionsRow[]>([]);
  const [resourceCount, setResourceCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(fiatAndCryptoTransactionsResult.isUninitialized);
  const [filterable] = useState<Filterable>(() => new FiatAndCryptoTransactionsListFiltersViewModel());

  useEffect(() => {
    fetchFiatAndCryptoTransactions(undefined, defaultTransactionFilterResult());
  }, [fetchFiatAndCryptoTransactions]);
  // request boilerplate - end

  useEffect(() => {
    if (fiatAndCryptoTransactionsResult.isSuccess && fiatAndCryptoTransactionsResult.data) {
      const transformedItems = fiatAndCryptoTransactionsResult.data.data.map(onTransactionTransform);
      setRows(transformedItems);
      setResourceCount(fiatAndCryptoTransactionsResult.data.meta?.resourceCount ?? 0);
      setLoading(fiatAndCryptoTransactionsResult.isFetching);
    }
  }, [
    fiatAndCryptoTransactionsResult.data,
    fiatAndCryptoTransactionsResult.isFetching,
    fiatAndCryptoTransactionsResult.isSuccess,
  ]);

  // handle all page error - start
  useEffect(() => {
    setIsDetailsPageError(Boolean(fiatAndCryptoTransactionsResult.error || error));
  }, [error, fiatAndCryptoTransactionsResult.error, setIsDetailsPageError]);
  // handle all page error - end

  return (
    <>
      <AccountBalancesGrid custodialAccountId={custodialAccountId} setIsDetailsPageError={setIsDetailsPageError} />
      <InlineInfoBanner>{t('transactionsPage.defaultCreatedAtFilter')}</InlineInfoBanner>
      <CommonGridBox>
        <PaginatedExportGrid
          columns={columnsTransactions}
          headerName={t('transactionsPage.gridTitle')}
          filters={filterable.filters}
          selectionMacroCommand={filterable.selectionMacroCommand}
          noResultsTitle={t('transactionsPage.noResultsTitle')}
          noResultsSubtitle={t('transactionsPage.noResultsSubtitle')}
          containerHeight="816px"
          rows={rows}
          rowCount={resourceCount}
          isLoading={loading}
          error={fiatAndCryptoTransactionsResult.error || error}
          fetchOtherItems={fetchFiatAndCryptoTransactions}
          onGridRowClick={onApprovedTransactionRowClick(navigate, custodialAccountId!, pathMatch)}
          onExportClick={onApprovedTransactionsExportClick(downloadPaginatedCsv, custodialAccountId!)}
          isExportLoading={isLoading}
          alwaysShowToolbar
        />
      </CommonGridBox>
    </>
  );
};
