export enum IdentityFilterProperty {
  firstName = 'firstName',
  lastName = 'lastName',
  id = 'id',
  email = 'email',
  phone = 'phone',
  kycLevel = 'kycLevel',
  status = 'status',
  externalCreatedAt = 'externalCreatedAt',
  // business
  companyName = 'companyName',
}
