import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { LabelLength } from 'ui-enums/label-length';
import { getSignOfTransaction } from 'utils/transactions/get-sign-of-transaction';
import { getDynamicAssetLabel } from 'utils/labels-mapping/get-dynamic-asset-label';

export const getAmountLabelPreciousMetalSell = (transaction: TransactionResponseModel): string => {
  return `${getSignOfTransaction(transaction)}${transaction.quantity ?? 0} ${getDynamicAssetLabel(
    LabelLength.short,
    transaction.assetTicker,
    transaction.assetName,
  )}`;
};
