import React from 'react';
import { ReactComponent as UserIcon } from 'assets/icons/side-menu/user.svg';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import { FooterTooltipInfoColumn } from '../FooterTooltipInfoColumn';
import { StyledIconBox } from './styled';

type Props = {
  isTooltipEnabled: boolean;
  tooltipTitle: string;
  toolTipSubtitle: string;
};

export const FooterIcon: React.FC<Props> = ({ isTooltipEnabled, tooltipTitle, toolTipSubtitle }) => {
  if (isTooltipEnabled) {
    return (
      <CommonTooltip
        placement="right"
        title={<FooterTooltipInfoColumn title={tooltipTitle} subtitle={toolTipSubtitle} isVisible={isTooltipEnabled} />}
      >
        <StyledIconBox>
          <UserIcon />
        </StyledIconBox>
      </CommonTooltip>
    );
  }
  return (
    <StyledIconBox>
      <UserIcon />
    </StyledIconBox>
  );
};
