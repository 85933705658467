import i18n from 'localizations';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';
import { capitalizeFirstLetter } from 'utils/capitalize-first-letter';

export const getTransactionStatusLabel = (status: TransactionStatus) => {
  switch (status.toLowerCase()) {
    case TransactionStatus.completed.toLowerCase(): {
      return i18n.t('transactionStatus.success');
    }
    case TransactionStatus.processing.toLowerCase(): {
      return i18n.t('transactionStatus.processing');
    }
    case TransactionStatus.failed.toLowerCase(): {
      return i18n.t('transactionStatus.fail');
    }
    case TransactionStatus.pending.toLowerCase(): {
      return i18n.t('transactionStatus.pending');
    }
    case TransactionStatus.approvalNeeded.toLowerCase(): {
      return i18n.t('transactionStatus.approvalNeeded');
    }
    case TransactionStatus.approved.toLowerCase(): {
      return i18n.t('transactionStatus.approved');
    }
    case TransactionStatus.rejected.toLowerCase(): {
      return i18n.t('transactionStatus.rejected');
    }
    case TransactionStatus.cancelled.toLowerCase(): {
      return i18n.t('transactionStatus.canceled');
    }
    default: {
      return status ? capitalizeFirstLetter(status) : i18n.t('noData');
    }
  }
};
