import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ContentBlock from 'components/tapi/custodialAccounts/CustodialAccounstPage/index';
import { CustodialAccountsPageTabs } from 'ui-enums/tabs/accounts/custodial-accounts-page-tabs';
import { getUser } from 'redux/api/api-users';
import { DashboardRoles } from 'models/enums/user/dashboard-roles';

export const CustodialAccountsPage: React.FC = () => {
  const [tab, setTab] = useState(CustodialAccountsPageTabs.user);
  const onTabChange = (event: React.SyntheticEvent, custodialAccountsPageTab: CustodialAccountsPageTabs) => {
    setTab(custodialAccountsPageTab);
  };
  const { data } = useSelector(getUser.select(undefined));
  const isTabbedViewEnabled = !!data && data.permissions.includes(DashboardRoles.corporateAccountViewer);

  return <ContentBlock tab={tab} onTabChange={onTabChange} isTabbedViewEnabled={isTabbedViewEnabled} />;
};
