import React from 'react';
// TODO @react-pdf/renderer is a pretty large library, so it would be great to use lazy loading for it in the future
import { pdf } from '@react-pdf/renderer';
import { LoadingPage } from 'elements/features/LoadingErrorState';
import ContentBlock from 'elements/element-transfer/components/Deposit/WireInstructionsPage';
import { PDFInstructionsTemplate } from 'elements/element-transfer/components/Deposit/WireInstructionsPage/PDFTemplate';
import useWireInstructionsContent from 'elements/element-transfer/hooks/useWireInstructionsContent';
import { composeWireInstructionsShareText } from 'elements/element-transfer/utils/composeWireInstructionsShareText';
import { shareText } from 'elements/element-transfer/utils/shareText';
import { OnCloseElement } from 'elements/models/types/element-result';

const WireInstructionsPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { loading, error, wireInstructions, handleReload, handleBack } = useWireInstructionsContent();

  const handleShare = async () => {
    await shareText(wireInstructions ? composeWireInstructionsShareText(wireInstructions) : '');
  };

  const handlePDFGenerate = async () => {
    if (wireInstructions) {
      const blob = await pdf(<PDFInstructionsTemplate wireInstructions={wireInstructions} />).toBlob();
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.setAttribute('download', `Wire_instructions_${wireInstructions?.accountHolder.name.replace(/\s/, '_')}.pdf`);
      a.click();
    }
  };

  return loading ? (
    <LoadingPage onClose={onClose} />
  ) : (
    <ContentBlock
      wireInstructions={wireInstructions}
      isError={error}
      onShare={handleShare}
      onPDFGenerate={handlePDFGenerate}
      onClose={onClose}
      onReload={handleReload}
      onBack={handleBack}
    />
  );
};

export default WireInstructionsPage;
