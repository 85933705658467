import React from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import i18n from 'localizations';
import { Row } from 'components/shared/Flex/Row';
import { Gap } from 'components/shared/Gap';
import { FilterValueComponent } from 'components/shared/grids/CustomizedGrid/GridFilterPopup/FilterValueComponent';
import { DropDown } from 'components/shared/grids/CustomizedGrid/GridFilterPopup/DropDown';
import { StyledBox, StyledButtonsRow, StyledInputsRow } from './styled';
import { ClearButton } from './ClearButton';
import { CancelButton } from './CancelButton';
import { ApplyButton } from './ApplyButton';

type Props = {
  open: boolean;
  anchorEl: HTMLDivElement | null;
  columnsRowHeight: number;
  selectedColumnIndex: number;
  selectedOperatorIndex: number;
  selectedValueIndex: number;
  selectedValue: string;
  selectedDate?: string;
  columnItems: string[];
  columnItemsDisplayValues: string[];
  operatorItems: string[];
  operatorItemsDisplayValues: string[];
  onColumnFilterChange: (index: number) => void;
  onOperatorChange: (index: number) => void;
  data: string[] | string;
  dataDisplayValues?: string[];
  onValueChange: (value: number | string) => void;
  onApplyButtonClick: () => void;
  onClearButtonClick: () => void;
  onCancelButtonClick: () => void;
  isDatePickerMode?: boolean;
  isApplyButtonDisabled: boolean;
};

export const GridFilterPopup: React.FC<Props> = ({
  open,
  anchorEl,
  columnsRowHeight,
  selectedColumnIndex,
  selectedOperatorIndex,
  selectedValueIndex,
  selectedValue,
  selectedDate,
  columnItems,
  columnItemsDisplayValues,
  operatorItems,
  operatorItemsDisplayValues,
  onColumnFilterChange,
  onOperatorChange,
  data,
  dataDisplayValues,
  onValueChange,
  onApplyButtonClick,
  onClearButtonClick,
  onCancelButtonClick,
  isDatePickerMode,
  isApplyButtonDisabled,
}) => {
  const onClickAway = (event: MouseEvent | TouchEvent) => {
    // [ClickAwayListener] Triggers on Select (and more) #12034
    // https://github.com/mui/material-ui/issues/12034
    if ((event.target as any)?.localName === 'body') {
      event.preventDefault();
    } else {
      onCancelButtonClick();
    }
  };

  return open ? (
    <ClickAwayListener onClickAway={onClickAway}>
      <Popper open={open && anchorEl !== null} anchorEl={anchorEl} placement="bottom-end" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <StyledBox columnsRowHeight={columnsRowHeight}>
              <StyledInputsRow>
                <DropDown
                  label={i18n.t('filters.column')}
                  selectedValueIndex={selectedColumnIndex}
                  items={columnItems}
                  itemsDisplayValues={columnItemsDisplayValues}
                  onChange={onColumnFilterChange}
                />
                <Gap size="_16px" isHorizontal />
                <DropDown
                  label={i18n.t('filters.operator')}
                  selectedValueIndex={selectedOperatorIndex}
                  items={operatorItems}
                  itemsDisplayValues={operatorItemsDisplayValues}
                  onChange={onOperatorChange}
                />
                <Gap size="_16px" isHorizontal />
                <FilterValueComponent
                  label={i18n.t('filters.value')}
                  selectedIndex={selectedValueIndex}
                  selectedValue={selectedValue}
                  selectedDate={selectedDate}
                  data={data}
                  dataDisplayValues={dataDisplayValues}
                  onChange={onValueChange}
                  isDatePickerMode={isDatePickerMode}
                />
              </StyledInputsRow>
              <StyledButtonsRow>
                <ClearButton onClick={onClearButtonClick} />
                <Row>
                  <CancelButton onClick={onCancelButtonClick} />
                  <Gap size="_16px" isHorizontal />
                  <ApplyButton isDisabled={isApplyButtonDisabled} onClick={onApplyButtonClick} />
                </Row>
              </StyledButtonsRow>
            </StyledBox>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  ) : null;
};
