import { useMemo } from 'react';
import * as Yup from 'yup';
import { RequiredStringSchema } from 'yup/lib/string';
import { useTranslation } from 'react-i18next';

const YupString = Yup.string().trim();

type GenericSchemaType = RequiredStringSchema<string | undefined>;

interface YupRules {
  emailRule: GenericSchemaType;
  passwordLoginRule: GenericSchemaType;
  firstNameRule: GenericSchemaType;
  lastNameRule: GenericSchemaType;
  emailRuleWithPattern: GenericSchemaType;
  phoneRule: GenericSchemaType;
  suspensionReasonRule: GenericSchemaType;
  unSuspensionReasonRule: GenericSchemaType;
}

const FIRST_NAME_MAXIMUM_LENGTH = 80;
const LAST_NAME_MAXIMUM_LENGTH = 80;
const SUSPENSION_REASON_MAXIMUM_LENGTH = 50;
const SUSPENSION_REASON_MINIMUM_LENGTH = 4;

export const useYupRules = (): YupRules => {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      emailRule: YupString.required(t('validation.fieldIsRequired')),
      passwordLoginRule: YupString.required(t('validation.fieldIsRequired')),
      firstNameRule: YupString.required(t('validation.fieldIsRequired')).max(
        FIRST_NAME_MAXIMUM_LENGTH,
        t('validation.fieldIsTooLong', { max: FIRST_NAME_MAXIMUM_LENGTH }),
      ),
      lastNameRule: YupString.required(t('validation.fieldIsRequired')).max(
        LAST_NAME_MAXIMUM_LENGTH,
        t('validation.fieldIsTooLong', { max: LAST_NAME_MAXIMUM_LENGTH }),
      ),
      emailRuleWithPattern: YupString.email(t('validation.fieldIsNotEmail')).required(t('validation.fieldIsRequired')),
      phoneRule: YupString.required(t('validation.fieldIsRequired')),
      suspensionReasonRule: YupString.required(t('validation.fieldIsRequired'))
        .min(SUSPENSION_REASON_MINIMUM_LENGTH, t('validation.suspensionReason.minMaxErrorMsg'))
        .max(SUSPENSION_REASON_MAXIMUM_LENGTH, t('validation.suspensionReason.minMaxErrorMsg')),
      unSuspensionReasonRule: YupString.required(t('validation.fieldIsRequired'))
        .min(SUSPENSION_REASON_MINIMUM_LENGTH, t('validation.unSuspensionReason.minMaxErrorMsg'))
        .max(SUSPENSION_REASON_MAXIMUM_LENGTH, t('validation.unSuspensionReason.minMaxErrorMsg')),
    }),
    [t],
  );
};
