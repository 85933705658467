import React from 'react';
import { TextFieldProps } from '@mui/material';
import { BaseTextField } from 'components/shared/inputs/BaseTextField';
import { NumberInput, NumberInputProps } from './NumberInput';

type Props = Omit<TextFieldProps, 'variant' | 'hidden' | 'inputProps'> & {
  name: string;
  inputProps?: NumberInputProps;
};

export const NumberField: React.FC<Props> = ({ inputProps, ...restProps }) => {
  return <BaseTextField {...restProps} InputProps={{ inputComponent: NumberInput, inputProps }} />;
};
