import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { PathMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CommonLayoutHeader, CommonLayoutContainer, CommonLayoutContent } from 'components/shared/layouts/CommonLayout';
import { StyledTab, StyledTabs } from 'components/shared/Tabs';
import { Gap } from 'components/shared/Gap';
import { StyledHeaderBox, StyledTitleSkeleton, StyledRow } from 'components/shared/detail-page/header/styled';
import { WalletsDetailsHeader } from 'ui-interfaces/wallets/wallet-details/wallets-details-header';
import WalletDetailsHeader from 'components/wapi/wallets/WalletDetailsPage/header';
import Breadcrumbs, { BreadCrumbsItems } from 'components/shared/Breadcrumbs';
import { CommonErrorLayout } from 'components/shared/layouts/CommonErrorLayout';
import { WalletDetailsPageTabs } from 'ui-enums/tabs/wallets/wallet-details-page-tabs';
import { CryptoAssetsTab } from './Tabs/CryptoAssetsTab';
import { TokensTab } from './Tabs/TokensTab';

type Props = {
  walletId: string;
  pathMatch: PathMatch<string> | null;
  tab: WalletDetailsPageTabs;
  setIsDetailsPageError: React.Dispatch<React.SetStateAction<boolean>>;
  setTab: (tab: WalletDetailsPageTabs) => void;
  loading?: boolean;
  error?: boolean;
  isOwnerShown: boolean;
  headerProps: WalletsDetailsHeader;
  breadCrumbs: BreadCrumbsItems;
};

const WalletDetailsPage: React.FC<Props> = ({
  walletId,
  pathMatch,
  tab,
  setIsDetailsPageError,
  setTab,
  isOwnerShown,
  loading,
  error,
  headerProps,
  breadCrumbs,
}) => {
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newTab: WalletDetailsPageTabs) => {
    setTab(newTab);
  };

  return (
    <CommonLayoutContainer>
      <CommonLayoutHeader>
        <StyledHeaderBox>
          <Breadcrumbs items={breadCrumbs} />
          {!error && <Gap size="_24px" />}
          {loading ? (
            <StyledRow>
              <StyledTitleSkeleton />
            </StyledRow>
          ) : (
            !error && (
              <StyledRow>
                <Typography variant="h3">{headerProps.title}</Typography>
              </StyledRow>
            )
          )}
          <Gap size="_20px" />
          {!error && (
            <WalletDetailsHeader headerProps={headerProps} loading={loading} isOwnerDetailsItemShown={isOwnerShown} />
          )}
        </StyledHeaderBox>
        <Gap size="_20px" />
        <Box>
          <StyledTabs value={tab} onChange={handleChange}>
            <StyledTab label={t('walletDetailsPage.tabs.cryptoTab')} value={WalletDetailsPageTabs.crypto} />
            <StyledTab label={t('walletDetailsPage.tabs.nftTab')} value={WalletDetailsPageTabs.nft} />
          </StyledTabs>
        </Box>
      </CommonLayoutHeader>
      <CommonLayoutContent>
        {error && <CommonErrorLayout />}
        {!error && (
          <>
            {tab === WalletDetailsPageTabs.crypto && (
              <CryptoAssetsTab walletId={walletId} setIsDetailsPageError={setIsDetailsPageError} />
            )}
            {tab === WalletDetailsPageTabs.nft && (
              <TokensTab walletId={walletId} pathMatch={pathMatch} setIsDetailsPageError={setIsDetailsPageError} />
            )}
          </>
        )}
      </CommonLayoutContent>
    </CommonLayoutContainer>
  );
};

export default WalletDetailsPage;
