import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { useResetPasswordMutation } from 'redux/api/api-users';
import { setBannerData, MessageType } from 'redux/banner';
import { ResendInvitationButton } from 'components/settings/users/UserDetailsPageContent/layout/Actions/buttons/ResendInvitationButton';

type Props = {
  userId: string;
};

export const ResendInvitationButtonContainer: React.FC<Props> = ({ userId }) => {
  const dispatch = useDispatch();
  const [resetPasswordTrigger, resetPasswordResult] = useResetPasswordMutation();

  useEffect(() => {
    if (resetPasswordResult.isLoading) {
      return;
    }
    if (resetPasswordResult.isSuccess) {
      dispatch(
        setBannerData({
          type: MessageType.success,
          message: t('settingsPage.banner.resetPasswordSuccessMessage'),
        }),
      );
    }
  }, [dispatch, resetPasswordResult]);

  const onResendClick = () => {
    resetPasswordTrigger({ userId });
  };
  return <ResendInvitationButton isLoading={resetPasswordResult.isLoading} onClick={onResendClick} />;
};
