import i18n from 'localizations';
import { Filter } from 'filters/interfaces/filter';
import { SingleValueFilter } from 'filters/single-value-filter';
import { Operators } from 'filters/operators';
import { CustodialAccountCustomAssetBalanceProperty } from 'ui-enums/custodial-accounts/custodial-account-custom-asset-balance-property';

export const createCustodialAccountCustomAssetBalanceFilters: () => Filter[] = () => [
  new SingleValueFilter({
    property: CustodialAccountCustomAssetBalanceProperty.name,
    displayProperty: i18n.t('custodialAccountDetailsPage.customAssetBalance.cols.name'),
    operators: [Operators.contains, Operators.equals],
    operatorsDisplayValues: [i18n.t('filters.operators.contains'), i18n.t('filters.operators.equals')],
    initialOperator: Operators.contains,
    initialValue: '',
  }),
  new SingleValueFilter({
    property: CustodialAccountCustomAssetBalanceProperty.description,
    displayProperty: i18n.t('custodialAccountDetailsPage.customAssetBalance.cols.description'),
    operators: [Operators.contains, Operators.equals],
    operatorsDisplayValues: [i18n.t('filters.operators.contains'), i18n.t('filters.operators.equals')],
    initialOperator: Operators.contains,
    initialValue: '',
  }),
  new SingleValueFilter({
    property: CustodialAccountCustomAssetBalanceProperty.amount,
    displayProperty: i18n.t('custodialAccountDetailsPage.customAssetBalance.cols.price'),
    operators: [Operators.contains, Operators.equals],
    operatorsDisplayValues: [i18n.t('filters.operators.contains'), i18n.t('filters.operators.equals')],
    initialOperator: Operators.contains,
    initialValue: '',
  }),
  new SingleValueFilter({
    property: CustodialAccountCustomAssetBalanceProperty.total,
    displayProperty: i18n.t('custodialAccountDetailsPage.customAssetBalance.cols.balance'),
    operators: [Operators.equals, Operators.greaterThan, Operators.lessThan],
    operatorsDisplayValues: [
      i18n.t('filters.operators.equals'),
      i18n.t('filters.operators.greaterThan'),
      i18n.t('filters.operators.lessThan'),
    ],
    initialOperator: Operators.equals,
    initialValue: '',
  }),
];
