import React from 'react';
import { Typography } from '@mui/material';
import { EstimatedValue, Spacer, Stack } from 'elements/components';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { StyledPrimaryDivider } from './styled';

type Props = {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  amount?: string;
  amountLabel: string;
  totalAmount?: string;
  totalLabel: string;
  coinName?: string;
  coinAbbreviation?: string;
};

const TransactionSummary: React.FC<Props> = ({
  icon: Icon,
  amount,
  amountLabel,
  totalAmount,
  totalLabel,
  coinName,
  coinAbbreviation,
}) => {
  const { isMobile } = useIsMobile();

  return (
    <>
      <Stack alignCenter>
        {!isMobile && <Spacer size={8} />}
        {!!Icon && <Icon height={56} width={56} />}

        <Spacer size={16} />
        <Typography variant="h3" color="primary.font">
          {amount}
        </Typography>
        {coinName && coinAbbreviation && (
          <>
            <Spacer size={4} />
            <Typography variant="bodyDefaultBook" color="secondary.font">
              {coinName} ({coinAbbreviation})
            </Typography>
          </>
        )}
        <Spacer size={isMobile ? 32 : 40} />
      </Stack>
      {totalAmount && <EstimatedValue label={amountLabel} value={totalAmount} />}

      <StyledPrimaryDivider />
      {totalAmount && <EstimatedValue label={totalLabel} value={totalAmount} isTotal fontVariant="subtitleMedium" />}
    </>
  );
};

export default TransactionSummary;
