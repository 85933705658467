import React from 'react';
import { BreadCrumbsItems } from 'components/shared/Breadcrumbs';
import { UserDetails } from 'ui-interfaces/user-details/user-details';
import { Error } from './layout/Error';
import { Loading } from './layout/Loading';
import { Content } from './layout/Content';

type Props = {
  breadCrumbs: BreadCrumbsItems;
  isLoading: boolean;
  isError: boolean;
  userDetails?: UserDetails;
};

export const UserDetailsPageContent: React.FC<Props> = ({ breadCrumbs, isLoading, isError, userDetails }) => {
  if (isLoading) {
    return <Loading breadCrumbs={breadCrumbs} />;
  }

  if (isError) {
    return <Error breadCrumbs={breadCrumbs} />;
  }

  return <Content breadCrumbs={breadCrumbs} userDetails={userDetails} />;
};
