import React, { useState, useEffect } from 'react';
import { EditButton } from 'components/settings/users/UserDetailsPageContent/layout/Actions/buttons/EditButton';
import { useEditUserMutation } from 'redux/api/api-users';
import { EditUserDialog } from 'components/settings/EditUserDialog';
import { IEditUserFormViewModel } from 'ui-interfaces/user-form/edit-user-form/i-edit-user-form-vm';
import { UpdateUserDetailRequestModel } from 'models/request/users/update-user-detail-request-model';

type Props = {
  userId: string;
  formViewModel?: IEditUserFormViewModel;
};

export const EditButtonContainer: React.FC<Props> = ({ userId, formViewModel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [canBeOpened, setCanBeOpened] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };
  const [editUser, { isLoading, isSuccess }] = useEditUserMutation();

  const onUserEdit = async (formValues: UpdateUserDetailRequestModel) => {
    await editUser({ userId, data: formValues });
  };

  const onEditClick = () => {
    setIsOpen(true);
    setCanBeOpened(true);
  };

  useEffect(() => {
    if (isSuccess) {
      setIsOpen(false);
    }
  }, [isSuccess]);

  return (
    <>
      <EditButton isLoading={isLoading} onClick={onEditClick} />
      {canBeOpened && formViewModel && (
        <EditUserDialog
          isOpen={isOpen}
          onClose={onClose}
          onUserEdit={onUserEdit}
          isLoading={isLoading}
          formViewModel={formViewModel}
        />
      )}
    </>
  );
};
