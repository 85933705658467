import i18n from 'localizations';
import { PaymentType } from 'ui-enums/response/transactions/payment-type';
import { capitalizeFirstLetter } from 'utils/capitalize-first-letter';

type CellLabels = {
  operation: string;
  method: string;
};

export const getCellLabelsByType = (type: PaymentType): CellLabels | null => {
  switch (type.toLowerCase()) {
    case PaymentType.internal.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.internalTransfer'),
        method: i18n.t('transactionsPage.fiatType'),
      };
    case PaymentType.achDeposit.toLowerCase():
    case PaymentType.incomingAchDeposit.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.deposit'),
        method: i18n.t('transactionsPage.ach'),
      };
    case PaymentType.cardDeposit.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.deposit'),
        method: i18n.t('transactionsPage.card'),
      };
    case PaymentType.checkDeposit.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.deposit'),
        method: i18n.t('transactionsPage.check'),
      };
    case PaymentType.returnAchDeposit.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.returnDeposit'),
        method: i18n.t('transactionsPage.ach'),
      };
    case PaymentType.wireDeposit.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.deposit'),
        method: i18n.t('transactionsPage.wire'),
      };
    case PaymentType.reversalWireDeposit.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.reverseDeposit'),
        method: i18n.t('transactionsPage.wire'),
      };
    case PaymentType.wireDepositReturn.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.returnDeposit'),
        method: i18n.t('transactionsPage.wire'),
      };
    case PaymentType.returnAchWithdrawal.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.withdrawalReturn'),
        method: i18n.t('transactionsPage.ach'),
      };
    case PaymentType.achWithdrawal.toLowerCase():
    case PaymentType.incomingAchWithdrawal.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.withdrawal'),
        method: i18n.t('transactionsPage.ach'),
      };
    case PaymentType.wireWithdrawal.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.withdrawal'),
        method: i18n.t('transactionsPage.domesticWire'),
      };
    case PaymentType.internationalWireWithdrawal.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.withdrawal'),
        method: i18n.t('transactionsPage.internationalWire'),
      };
    case PaymentType.reversalWireWithdrawal.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.reverseWithdrawal'),
        method: i18n.t('transactionsPage.domesticWire'),
      };
    case PaymentType.wireWithdrawalReturn.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.withdrawalReturn'),
        method: i18n.t('transactionsPage.domesticWire'),
      };
    case PaymentType.buyCrypto.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.trading'),
        method: i18n.t('transactionsPage.buy'),
      };
    case PaymentType.buyPreciousMetal.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.preciousMetals'),
        method: i18n.t('transactionsPage.buy'),
      };
    case PaymentType.sellCrypto.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.trading'),
        method: i18n.t('transactionsPage.sell'),
      };
    case PaymentType.sellPreciousMetal.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.preciousMetals'),
        method: i18n.t('transactionsPage.sell'),
      };
    case PaymentType.depositCrypto.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.deposit'),
        method: i18n.t('transactionsPage.cryptoType'),
      };
    case PaymentType.withdrawalCrypto.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.withdrawal'),
        method: i18n.t('transactionsPage.cryptoType'),
      };
    case PaymentType.internalCrypto.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.internalTransfer'),
        method: i18n.t('transactionsPage.cryptoType'),
      };
    case PaymentType.privateSecurityInternal.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.internalTransfer'),
        method: i18n.t('transactionsPage.privateSecurities'),
      };
    case PaymentType.privateSecurityDeposit.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.deposit'),
        method: i18n.t('transactionsPage.privateSecurities'),
      };
    case PaymentType.privateSecurityWithdrawal.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.withdrawal'),
        method: i18n.t('transactionsPage.privateSecurities'),
      };
    case PaymentType.achDepositReverse.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.reverseDeposit'),
        method: i18n.t('transactionsPage.ach'),
      };
    case PaymentType.achWithdrawalReverse.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.reverseWithdrawal'),
        method: i18n.t('transactionsPage.ach'),
      };
    case PaymentType.initialDeposit.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.initialDeposit'),
        method: '',
      };
    case PaymentType.manuallyProcessed.toLowerCase():
      return {
        operation: i18n.t('transactionsPage.manuallyProcessed'),
        method: '',
      };
    default:
      return {
        operation: type ? capitalizeFirstLetter(type) : i18n.t('transactionsPage.noData'),
        method: '',
      };
  }
};
