import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SerializedStyles } from '@emotion/serialize';
import { Button } from '@mui/material';
import { css } from '@mui/material/styles';
import { ElementsCustomTheme, elementsStyled } from 'elements/theme/createCustomTheme';

const getCommonStyles = (theme: ElementsCustomTheme): SerializedStyles => css`
  color: ${theme.palette.primary.font};
  overflow-wrap: break-word;
  vertical-align: baseline;
`;

export const StyledButton = elementsStyled(Button)(
  ({ theme }) => css`
    ${getCommonStyles(theme)}
    display: inline;
    background: none;
    padding: 0;
    margin: 0;
    height: auto;
    width: auto;
    box-shadow: unset;
    cursor: pointer;
    min-width: 0;
    text-decoration: underline;

    &:hover {
      background: none;
      text-decoration: underline;
    }

    &.Mui-disabled {
      color: ${theme.palette.primary.font};
      cursor: auto;
      background: none;
    }
  `,
);

export const StyledLink = elementsStyled(Link)(
  ({ theme }) => css`
    ${getCommonStyles(theme)};

    &:visited {
      color: ${theme.palette.primary.font};
    }
  `,
);

export const StyledAnchor = elementsStyled('a')(
  ({ theme }) => css`
    ${getCommonStyles(theme)};

    &:visited {
      color: ${theme.palette.primary.font};
    }
  `,
);
