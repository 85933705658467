import { mainApi } from 'redux/api/main-api';
import { API_ROUTES } from 'redux/api/api-routes';
import { getRouteWithParams } from 'utils/get-route-with-params';
import { APPROVE_PAYMENT_TAG, REJECT_PAYMENT_TAG } from 'redux/api/constants';

const paymentsApi = mainApi.injectEndpoints({
  endpoints: build => ({
    approvePayment: build.mutation<undefined, string>({
      query: (paymentId: string) => ({
        url: getRouteWithParams(API_ROUTES.private.APPROVE_PAYMENT, { paymentId }),
        method: 'POST',
      }),
      invalidatesTags: [APPROVE_PAYMENT_TAG],
    }),
    rejectPayment: build.mutation<undefined, string>({
      query: (paymentId: string) => ({
        url: getRouteWithParams(API_ROUTES.private.REJECT_PAYMENT, { paymentId }),
        method: 'POST',
      }),
      invalidatesTags: [REJECT_PAYMENT_TAG],
    }),
  }),
  overrideExisting: false,
});

export const { useApprovePaymentMutation, useRejectPaymentMutation } = paymentsApi;
