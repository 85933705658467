import { Typography, Divider, styled } from '@mui/material';
import { Row } from 'components/shared/Flex/Row';
import { pxToRem, pxToVw } from 'utils/styling-utils';

const MAX_WIDTH = 220;

export const OwnerName = styled(Typography)(() => ({
  maxWidth: MAX_WIDTH,
  paddingLeft: pxToRem(10),
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginInline: `-${pxToRem(theme.gaps._24px)}`,
}));

export const IDItem = styled(Row)(({ theme }) => ({
  alignItems: 'center',
  paddingTop: pxToVw(theme.gaps._16px),
  paddingBottom: pxToVw(theme.gaps._16px),
  flex: 0.7,
}));

export const PillItem = styled(Row)(({ theme }) => ({
  alignItems: 'center',
  paddingTop: pxToVw(theme.gaps._16px),
  paddingBottom: pxToVw(theme.gaps._16px),
  flex: 0.7,
}));

export const NameItem = styled(Row)(({ theme }) => ({
  alignItems: 'center',
  paddingTop: pxToVw(theme.gaps._16px),
  paddingBottom: pxToVw(theme.gaps._16px),
  flex: 0.7,
}));

export const OwnerRow = styled(Row)(() => ({
  alignItems: 'center',
}));
