import React from 'react';
import { SectionIdentifiable } from 'ui-interfaces/transaction-details/sections/section-identifiable';
import { SectionType } from 'ui-enums/transaction-detail/section-type';
import { IPlainSection } from 'ui-interfaces/transaction-details/sections/i-plain-section';
import { ICollapsibleSection } from 'ui-interfaces/transaction-details/sections/i-collapsible-section';
import { PlainSection } from 'components/tapi/transactions/TapiTransactionDetailsPage/sections/PlainSection';
import { CollapsibleSection } from 'components/tapi/transactions/TapiTransactionDetailsPage/sections/CollapsibleSection';

export const createTransactionDetailsSection = (sectionData: SectionIdentifiable): React.ReactElement => {
  switch (sectionData.sectionType) {
    case SectionType.plain: {
      const s = sectionData as IPlainSection;
      return <PlainSection key={s.id} data={{ ...s }} />;
    }

    case SectionType.collapsible: {
      const s = sectionData as ICollapsibleSection;
      return <CollapsibleSection key={s.id} data={{ ...s }} />;
    }

    default: {
      const s = sectionData as IPlainSection;
      return <PlainSection key={s.id} data={{ ...s }} />;
    }
  }
};
