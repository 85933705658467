import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 40,
  },
  text: { fontSize: 8, fontFamily: 'Helvetica-Bold', lineHeight: '160%', letterSpacing: 0.24 },
  dot: { height: 3, width: 3, borderRadius: '50%', border: '0.4 solid black', marginRight: 4 },
});
