import React from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ExternalAccount } from 'elements/types';

type Props = {
  data?: ExternalAccount;
};

const RadioLabel: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  if (!data) return null;

  const { name = '', last4, id } = data;

  const formattedName = name.length > 35 ? `${name.substr(0, 35)}...` : name;

  return (
    <Stack paddingY="5px">
      <Typography variant="bodyDefaultBook">
        {formattedName} | {t('elements.transfer.withdrawalWireRecipientSearchPage.endingIn')} *{last4}
      </Typography>
      <Typography variant="bodyDefaultBook" color="secondary.font">
        {id}
      </Typography>
    </Stack>
  );
};

export default RadioLabel;
