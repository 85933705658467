import React from 'react';
import { Typography } from '@mui/material';
import { LeftCellBox } from 'components/shared/detail-page/rows/shared/LeftCellBox';
import { ReactComponent as InfoIcon } from 'assets/icons/info-icon.svg';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import { Gap } from 'components/shared/Gap';

type Props = {
  value: string;
  tooltipMessage: string;
};

export const LeftCell: React.FC<Props> = ({ value, tooltipMessage }) => (
  <LeftCellBox>
    <CommonTooltip placement="top" title={tooltipMessage}>
      <InfoIcon cursor="pointer" />
    </CommonTooltip>
    <Gap isHorizontal size="_8px" />
    <Typography variant="bodyDefaultMedium">{value}</Typography>
  </LeftCellBox>
);
