import { FilterResult } from 'filters/interfaces/filter-result';
import { API_ROUTES } from 'redux/api/api-routes';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';
import { DownloadPaginatedCSV } from 'hooks/use-paginated-export/interfaces';
import { PaginatedRequestParams } from 'redux/api/types';

export const onTransactionsExportClick =
  (downloadPaginatedCsv: DownloadPaginatedCSV) => (pagination: PaginatedRequestParams, filterResult?: FilterResult) => {
    downloadPaginatedCsv({
      urlSegment: API_ROUTES.private.TRANSACTIONS,
      pagination,
      preFilterParams: {
        approved: 'false',
        [`filter[status in]`]: `${TransactionStatus.rejected}`,
      },
      filterResult,
    });
  };
