import React from 'react';
import { IPdfNoLabelRow } from 'ui-interfaces/transaction-details/pdf/rows/i-pdf-no-label-row';
import { Row } from '../shared/Row';
import { RightCell } from '../shared/RightCell';

type Props = {
  data: IPdfNoLabelRow;
};

export const PdfNoLabelRow: React.FC<Props> = ({ data }) => {
  return (
    <Row>
      <RightCell value={data.value} />
    </Row>
  );
};
