import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Button } from 'elements/components';
import { CustodialAccount } from 'elements/types';
import { ReactComponent as RoundedCheckCircleIcon } from 'elements/element-transfer/assets/icons/roundedCheckCircle.svg';
import Layout from 'elements/element-transfer/components/Layout';
import { CryptoSendState } from 'elements/element-transfer/contexts/Element';
import TransactionDetails from '../TransactionDetails';

type Props = {
  custodialAccount: CustodialAccount | null;
  sendCryptoState: CryptoSendState;
  onDone: () => void;
};
const CryptoSentPage: React.FC<Props> = ({ onDone, sendCryptoState, custodialAccount }) => {
  const { t } = useTranslation();

  return (
    <Layout
      title={t('elements.transfer.customizableLabels.withdrawalCryptoSuccessPageTitle')}
      onClose={onDone}
      withCloseButton={false}
      footerButtons={
        <Button onClick={onDone}>
          <Typography variant="buttonLarge">{t('elements.common.done')}</Typography>
        </Button>
      }
    >
      <TransactionDetails
        icon={RoundedCheckCircleIcon}
        sendCryptoState={sendCryptoState}
        custodialAccount={custodialAccount}
      />
    </Layout>
  );
};

export default CryptoSentPage;
