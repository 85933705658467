import { UserBaseViewModel } from 'view-models/users/list/base/user-base-vm';
import { UsersListRow } from 'ui-interfaces/row-interfaces/users/users-list-row';
import { UserBaseResponseModel } from 'models/response/users/user-base-response-model';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { UserRoleTemplateResponseModel } from 'models/response/users/user-role-template-response-model';
import { createActionViewModel } from 'factories/users/create-user-action-vm';
import { Action } from 'ui-enums/users/action-cell-type';

export class UserViewModelWithCurrentUser extends UserBaseViewModel implements UsersListRow {
  constructor(
    usersListRow: UsersListRow,
    user: UserBaseResponseModel,
    currentUser: ShortUserResponseModel,
    roleTemplates: UserRoleTemplateResponseModel[] = [],
  ) {
    super(user);

    Object.assign(this, usersListRow);

    this.isCurrentUser = user.id === currentUser.id;

    this.actionsCellProps = {
      actions: [
        createActionViewModel(Action.edit, user, currentUser, roleTemplates),
        createActionViewModel(Action.reset, user, currentUser, roleTemplates),
        createActionViewModel(Action.resend, user, currentUser, roleTemplates),
        createActionViewModel(Action.remove, user, currentUser, roleTemplates),
      ],
    };
  }
}
