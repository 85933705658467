import React from 'react';
import Typography from '@mui/material/Typography';
import { PaymentType } from 'ui-enums/response/transactions/payment-type';
import { Row } from 'components/shared/Flex/Row';

type Props = {
  date: string;
  type: PaymentType;
};

export const Row1: React.FC<Props> = ({ date, type }) => {
  switch (type.toLowerCase()) {
    case PaymentType.achWithdrawal.toLowerCase():
    case PaymentType.incomingAchWithdrawal.toLowerCase(): {
      return (
        <Row
          sx={{
            alignItems: 'center',
          }}
        >
          <Typography>{date}</Typography>
        </Row>
      );
    }
    default: {
      return <Typography variant="bodyDefaultBook">{date}</Typography>;
    }
  }
};
