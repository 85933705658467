import React from 'react';
import { TextFieldProps } from '@mui/material';
import { StyledHelperErrorIcon } from './styled';

interface Props {
  helperText: TextFieldProps['helperText'];
  error?: boolean;
}

export const HelperText: React.FC<Props> = ({ helperText, error }) => (
  <>
    {error ? <StyledHelperErrorIcon /> : null}
    {helperText}
  </>
);
