import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { columnsTransactions } from 'components/tapi/transactions/TapiTransactionsPage/columns-definitions';
import { CommonGridBox } from 'components/shared/layouts/CommonLayout';
import { ApprovalNeededTransactionsListFiltersViewModel } from 'view-models/transactions/list/filters/approval-needed/approval-needed-transactions-list-filters-vm';
import { TransactionsRow } from 'ui-interfaces/row-interfaces/transactions';
import { Filterable } from 'ui-interfaces/shared/filters/filterable';
import { useLazyGetApprovalNeededTransactionsQuery } from 'redux/api/api-transactions';
import { FilterResult } from 'filters/interfaces/filter-result';
import { onNonApprovedTransactionRowClick, onTransactionTransform } from 'handlers/tapi/transactions/list/handlers';
import { usePaginatedExport } from 'hooks/use-paginated-export';
import { PaginatedExportGrid } from 'components/shared/grids/PaginatedExportGrid';
import InlineInfoBanner from 'components/shared/InlineInfoBanner';
import { defaultTransactionFilterResult } from 'redux/api/default-filters';
import { onTransactionsExportClick } from './handlers';

export const ApprovalNeededTab: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { downloadPaginatedCsv, isLoading, error } = usePaginatedExport();

  const [getApprovalNeededTabTransactionsTrigger, approvalNeededTabTransactionsResult] =
    useLazyGetApprovalNeededTransactionsQuery();

  const fetchApprovalNeededTabTransactions = useCallback(
    (page?: number, filterResult?: FilterResult) =>
      getApprovalNeededTabTransactionsTrigger({ page, filterResult }, false),
    [getApprovalNeededTabTransactionsTrigger],
  );

  const [rows, setRows] = useState<TransactionsRow[]>([]);
  const [resourceCount, setResourceCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(approvalNeededTabTransactionsResult.isUninitialized);
  const [filterable] = useState<Filterable>(() => new ApprovalNeededTransactionsListFiltersViewModel());

  useEffect(() => {
    fetchApprovalNeededTabTransactions(undefined, defaultTransactionFilterResult());
  }, [fetchApprovalNeededTabTransactions]);

  useEffect(() => {
    if (approvalNeededTabTransactionsResult.isSuccess && approvalNeededTabTransactionsResult.data) {
      const transformedItems = approvalNeededTabTransactionsResult.data.data.map(onTransactionTransform);
      setRows(transformedItems);
      setResourceCount(approvalNeededTabTransactionsResult.data.meta?.resourceCount ?? 0);
      setLoading(approvalNeededTabTransactionsResult.isFetching);
    }
  }, [
    approvalNeededTabTransactionsResult.data,
    approvalNeededTabTransactionsResult.isFetching,
    approvalNeededTabTransactionsResult.isSuccess,
  ]);

  return (
    <>
      <InlineInfoBanner>{t('transactionsPage.defaultCreatedAtFilter')}</InlineInfoBanner>
      <CommonGridBox>
        <PaginatedExportGrid
          columns={columnsTransactions}
          headerName={t('transactionsPage.gridTitle')}
          filters={filterable.filters}
          selectionMacroCommand={filterable.selectionMacroCommand}
          noResultsTitle={t('transactionsPage.noResultsTitle')}
          noResultsSubtitle={t('transactionsPage.noResultsSubtitle')}
          containerHeight="100%"
          rows={rows}
          rowCount={resourceCount}
          isLoading={loading}
          error={approvalNeededTabTransactionsResult.error || error}
          fetchOtherItems={fetchApprovalNeededTabTransactions}
          onGridRowClick={onNonApprovedTransactionRowClick(navigate)}
          onExportClick={onTransactionsExportClick(downloadPaginatedCsv)}
          isExportLoading={isLoading}
          alwaysShowToolbar
        />
      </CommonGridBox>
    </>
  );
};
