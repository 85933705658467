export enum CustodialAccountsFilterProperty {
  id = 'id',
  number = 'number',
  ownerIdentityDisplayName = 'ownerIdentityDisplayName',
  type = 'type',
  lastActivityUtc = 'lastActivityUtc',
  status = 'status',
  assets = 'assets',
  name = 'name',
  // TODO: reveal when backend sends up-to-date usd balance
  // totalUsdBalance = 'totalUsdBalance',
}
