export enum DocTypes {
  jpg = 'jpg',
  png = 'png',
  pdf = 'pdf',
}

export const parseFormatDocument = (name: string) => {
  const arr = name.split('.');
  const index = arr.length - 1;
  return arr[index] as DocTypes;
};
