import React from 'react';
import { mapAssetByNetworkIconSizeToNumber } from 'utils/multi-mapping/asset-by-network-icon-size/map-asset-by-network-icon-size-to-number';
import { AssetByNetwork } from 'ui-enums/response/asset-by-network';
import { SvgStaticImage } from 'components/shared/SvgStaticImage';
import { AssetByNetworkIconSize } from 'ui-enums/asset-by-network-icon-size';
import { mapAssetByNetworkToIconComponent } from './map-asset-by-network-to-icon-component';

export const getAssetByNetworkIcon28px = (asset: AssetByNetwork): JSX.Element | null => {
  const iconComponent = mapAssetByNetworkToIconComponent(asset);

  if (!iconComponent) {
    return null;
  }

  return (
    <SvgStaticImage icon={iconComponent} size={mapAssetByNetworkIconSizeToNumber(AssetByNetworkIconSize['28px'])} />
  );
};
