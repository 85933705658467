import React from 'react';
import { ReactComponent as BinIcon } from 'assets/icons/users/createUserDialog/bin.svg';
import { Gap } from 'components/shared/Gap';
import { StyledIconButton, StyledRow } from './styled';

type Props = {
  isDeletable?: boolean;
  onDelete?: () => void;
};

export const LimitsFormSection: React.FC<Props> = ({ children, isDeletable = false, onDelete }) => {
  const handleDelete = () => {
    onDelete?.();
  };
  return (
    <StyledRow>
      {children}
      {isDeletable && (
        <>
          <Gap isHorizontal size="_10px" />
          <StyledIconButton onClick={handleDelete}>
            <BinIcon />
          </StyledIconButton>
        </>
      )}
    </StyledRow>
  );
};
