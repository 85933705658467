import { TransactionDetailResponseModel } from 'models/response/transactions/transaction-detail-response-model';
import { getLabelForPreciousMetalBuyTransactionDetailsPage } from 'utils/transactions/detail/precious-metal/buy/get-label-for-precious-metal-buy-transaction-details-page';
import { getSublabelForPreciousMetalBuyTransactionDetailsPage } from 'utils/transactions/detail/precious-metal/buy/get-sublabel-for-precious-metal-buy-transaction-details-page';

export const getPdfAmountForPreciousMetalBuy = (transaction: TransactionDetailResponseModel): string => {
  const label = getLabelForPreciousMetalBuyTransactionDetailsPage(transaction);

  const subLabel = getSublabelForPreciousMetalBuyTransactionDetailsPage(transaction);

  return `${label} / ${subLabel}`;
};
