import { Command } from 'filters/interfaces/command';
import { FilterResult } from 'filters/interfaces/filter-result';
import { Filter } from 'filters/interfaces/filter';

export class FilterCommand implements Command {
  private filter: Filter;

  private savedFilterState: FilterResult | undefined = undefined;

  constructor(filter: Filter) {
    this.filter = filter;
  }

  getState(): FilterResult {
    const property = this.filter.getProperty();
    const operator = this.filter.getSelectedOperator();
    const value = this.filter.getSelectedValue();

    return { property, operator, value } as FilterResult;
  }

  reset(): void {
    this.filter.resetAll();
    this.savedFilterState = undefined;
  }

  save(): void {
    const operator = this.filter.getSelectedOperator();
    const value = this.filter.getSelectedValue();
    this.savedFilterState = { operator, value } as FilterResult;
  }

  resetToSavedState(): void {
    if (this.savedFilterState) {
      this.filter.setSelectedOperator(this.savedFilterState.operator);
      this.filter.setSelectedValue(this.savedFilterState.value);
    }
  }
}
