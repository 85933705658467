import React from 'react';
import { useTranslation } from 'react-i18next';
import FlowFirstPageLayout from 'elements/element-transfer/components/FlowFirstPageLayout';

const AchAmountPage = () => {
  const { t } = useTranslation();

  // TODO replace with real page
  return (
    <FlowFirstPageLayout title={t('elements.transfer.customizableLabels.title')} withHeader isAbove>
      <div />
    </FlowFirstPageLayout>
  );
};

export default AchAmountPage;
