import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CommonLayoutContainer,
  CommonLayoutHeader,
  CommonLayoutContent,
  CommonGridBox,
  CommonPageTitle,
} from 'components/shared/layouts/CommonLayout';
import { Gap } from 'components/shared/Gap';
import { StyledTab } from 'components/shared/Tabs';
import { CustodialAccountsPageTabs } from 'ui-enums/tabs/accounts/custodial-accounts-page-tabs';
import { UserCustodialAccountsTab } from './Tabs/UserCustodialAccounts';
import { StyledTabsBox } from './styled';
import { CorporateCustodialAccountsTab } from './Tabs/CorporateCustodialAccounts';
import { CustodialAccountsList } from './CustodialAccountsList';

type Props = {
  tab: CustodialAccountsPageTabs;
  onTabChange: (event: React.SyntheticEvent, custodialAccountsPageTab: CustodialAccountsPageTabs) => void;
  isTabbedViewEnabled: boolean;
};

const CustodialAccountsPage: React.FC<Props> = ({ tab, onTabChange, isTabbedViewEnabled }) => {
  const { t } = useTranslation();

  return (
    <CommonLayoutContainer>
      <CommonLayoutHeader>
        <Gap size="_40px" />
        <CommonPageTitle>{t('custodialAccountsPage.title')}</CommonPageTitle>
        <Gap size="_32px" />
        {isTabbedViewEnabled && (
          <StyledTabsBox value={tab} onChange={onTabChange}>
            <StyledTab label={t('custodialAccountsPage.tabs.user')} value={CustodialAccountsPageTabs.user} />
            <StyledTab label={t('custodialAccountsPage.tabs.corporate')} value={CustodialAccountsPageTabs.corporate} />
          </StyledTabsBox>
        )}
      </CommonLayoutHeader>
      <CommonLayoutContent>
        <CommonGridBox>
          {isTabbedViewEnabled ? (
            <>
              {tab === CustodialAccountsPageTabs.user && <UserCustodialAccountsTab />}
              {tab === CustodialAccountsPageTabs.corporate && <CorporateCustodialAccountsTab />}
            </>
          ) : (
            <CustodialAccountsList />
          )}
        </CommonGridBox>
      </CommonLayoutContent>
    </CommonLayoutContainer>
  );
};

export default CustodialAccountsPage;
