import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { columnsTransactions } from 'components/tapi/transactions/TapiTransactionsPage/columns-definitions';
import { CommonGridBox } from 'components/shared/layouts/CommonLayout';
import { TransactionsListFiltersViewModel } from 'view-models/transactions/list/filters/transactions-list-filters-vm';
import { TransactionsRow } from 'ui-interfaces/row-interfaces/transactions';
import { Filterable } from 'ui-interfaces/shared/filters/filterable';
import { useLazyGetTransactionsQuery } from 'redux/api/api-transactions';
import { FilterResult } from 'filters/interfaces/filter-result';
import {
  onApprovedTransactionRowClick,
  onTransactionsExportClick,
  onTransactionTransform,
} from 'handlers/tapi/transactions/list/handlers';
import { usePaginatedExport } from 'hooks/use-paginated-export';
import { PaginatedExportGrid } from 'components/shared/grids/PaginatedExportGrid';
import InlineInfoBanner from 'components/shared/InlineInfoBanner';
import { defaultTransactionFilterResult } from 'redux/api/default-filters';

export const TransactionList: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { downloadPaginatedCsv, isLoading, error } = usePaginatedExport();
  const [getTransactionsTrigger, transactionsResult] = useLazyGetTransactionsQuery();

  const fetchTransactions = useCallback(
    (page?: number, filterResult?: FilterResult) => getTransactionsTrigger({ page, filterResult }, false),
    [getTransactionsTrigger],
  );

  const [rows, setRows] = useState<TransactionsRow[]>([]);
  const [resourceCount, setResourceCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(transactionsResult.isUninitialized);
  const [filterable] = useState<Filterable>(() => new TransactionsListFiltersViewModel());

  useEffect(() => {
    fetchTransactions(undefined, defaultTransactionFilterResult());
  }, [fetchTransactions]);

  useEffect(() => {
    if (transactionsResult.isSuccess && transactionsResult.data) {
      const transformedItems = transactionsResult.data.data.map(onTransactionTransform);
      setRows(transformedItems);
      setResourceCount(transactionsResult.data.meta?.resourceCount ?? 0);
      setLoading(transactionsResult.isFetching);
    }
  }, [transactionsResult.data, transactionsResult.isFetching, transactionsResult.isSuccess]);

  return (
    <>
      <InlineInfoBanner>{t('transactionsPage.defaultCreatedAtFilter')}</InlineInfoBanner>
      <CommonGridBox>
        <PaginatedExportGrid
          columns={columnsTransactions}
          headerName={t('transactionsPage.gridTitle')}
          filters={filterable.filters}
          selectionMacroCommand={filterable.selectionMacroCommand}
          noResultsTitle={t('transactionsPage.noResultsTitle')}
          noResultsSubtitle={t('transactionsPage.noResultsSubtitle')}
          containerHeight="100%"
          rows={rows}
          rowCount={resourceCount}
          isLoading={loading}
          error={transactionsResult.error || error}
          fetchOtherItems={fetchTransactions}
          onGridRowClick={onApprovedTransactionRowClick(navigate)}
          onExportClick={onTransactionsExportClick(downloadPaginatedCsv)}
          isExportLoading={isLoading}
          alwaysShowToolbar
        />
      </CommonGridBox>
    </>
  );
};
