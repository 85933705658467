import { UserDetailResponseModel } from 'models/response/users/user-detail-response-model';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { UserRoleTemplateResponseModel } from 'models/response/users/user-role-template-response-model';
import { IEditUserFormViewModel } from 'ui-interfaces/user-form/edit-user-form/i-edit-user-form-vm';
// view models - start
import { EditUserFormViewModel } from 'view-models/user-form/edit-user-form/edit-user-form-vm';
// view models - end

export const createEditUserFormViewModel = (
  user: UserDetailResponseModel,
  currentUser: ShortUserResponseModel,
  userRoleTemplates: UserRoleTemplateResponseModel[],
): IEditUserFormViewModel => new EditUserFormViewModel(user, currentUser, userRoleTemplates);
