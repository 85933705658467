import { DashboardRoles } from 'models/enums/user/dashboard-roles';
import { UserPermissionModel } from 'models/response/users/user-permission-model';

export const havePermissionDefinitionsEnabledPermissions = (
  permissionsAllowingAccess: DashboardRoles[],
  permissionDefinitions: UserPermissionModel[],
): boolean =>
  permissionsAllowingAccess.some(permissionAllowingAccess =>
    permissionDefinitions.some(permissionDefinition => {
      const isMatchedPermissionDefinition = permissionDefinition.key === permissionAllowingAccess;

      return isMatchedPermissionDefinition && permissionDefinition.value;
    }),
  );
