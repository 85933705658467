import React from 'react';
import { FieldInputProps, FormikProps, FormikValues } from 'formik';
import { KeyOf } from './types';

interface HelperTextConfig {
  helperText: string;
}
export const getFormikProps =
  <Values extends FormikValues>(formik: FormikProps<Values>) =>
  (
    name: KeyOf<Values>,
    config?: Partial<HelperTextConfig>,
  ): Omit<FieldInputProps<Values[typeof name]>, 'onChange'> & {
    onChange: (e: React.ChangeEvent<any>) => void;
    error: boolean;
    helperText: string;
  } => {
    const { getFieldProps, touched, errors } = formik;
    const { helperText } = <HelperTextConfig>{
      helperText: '',
      ...config,
    };

    return {
      ...getFieldProps(name),
      error: !!(touched[name] && errors[name]),
      helperText: (touched[name] && errors[name] ? errors[name] : helperText) as string,
    };
  };
