import { TransactionDetailResponseModel } from 'models/response/transactions/transaction-detail-response-model';
import { getSignOfTransaction } from 'utils/transactions/get-sign-of-transaction';
import { formatCurrency } from 'utils/format-currency';
import { LabelLength } from 'ui-enums/label-length';
import { ICurrencyRow } from 'ui-interfaces/transaction-details/rows/i-currency-row';
import { getStaticDefaultIcon } from 'utils/icons-mapping/get-static-default-icon';
import { AssetByNetworkIconSize } from 'ui-enums/asset-by-network-icon-size';
import { mapAssetByNetworkIconSizeToNumber } from 'utils/multi-mapping/asset-by-network-icon-size/map-asset-by-network-icon-size-to-number';
import { getDynamicAssetLabel } from 'utils/labels-mapping/get-dynamic-asset-label';

export const getCurrencyRowPropsForManual = (
  transaction: TransactionDetailResponseModel,
  assetIconsSize: AssetByNetworkIconSize,
): ICurrencyRow => {
  return {
    imageUrl: transaction.assetIconXs,
    size: mapAssetByNetworkIconSizeToNumber(assetIconsSize),
    defaultAssetIcon: getStaticDefaultIcon(transaction.assetTicker, assetIconsSize),
    label: `${getSignOfTransaction(transaction)}${
      transaction.cryptoAmount ?? formatCurrency(transaction.amount ?? 0)
    } ${getDynamicAssetLabel(LabelLength.short, transaction.assetTicker, transaction.assetName)}`,
    subLabel:
      transaction.cryptoAmount && transaction.amount
        ? `${getSignOfTransaction(transaction)}${formatCurrency(transaction.amount ?? 0)} ${getDynamicAssetLabel(
            LabelLength.short,
            transaction.assetTicker,
            transaction.assetName,
          )}`
        : undefined,
  };
};
