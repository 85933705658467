import React from 'react';
import { StyledHeaderBox } from 'components/shared/detail-page/header/styled';
import Breadcrumbs, { BreadCrumbsItem } from 'components/shared/Breadcrumbs';
import { Row } from 'components/shared/Flex/Row';
import { CustodialAccountsDetailsHeader } from 'ui-interfaces/custodial-accounts-details/custodial-accounts-details-header';
import { usePermissionBasedAccess } from 'hooks/use-permission-based-access';
import { Element } from 'ui-interfaces/elements/element';
import { DashboardRoles } from 'models/enums/user/dashboard-roles';
import { Gap } from 'components/shared/Gap';
import { SuspensionBanner } from './SuspensionBanner';
import { SuspensionAction } from './SuspensionAction';
import { EditButton } from './EditButton';
import { Elements } from './Elements';
import { LabelRow } from './LabelRow';
import { NameRow } from './NameRow';
import { TitleRow } from './TitleRow';
import { HeaderItemsGrid } from './HeaderItemsGrid';
import { LoadingHeader } from './LoadingHeader';
import { StyledTopRow } from './styles';

type Props = {
  breadCrumbs: BreadCrumbsItem[];
  headerProps?: CustodialAccountsDetailsHeader;
  showSuspensionModal: () => void;
  onCaNamingModalOpen: () => void;
  isLoading: boolean;
  elements: Element[];
};

export const CADetailPageHeader: React.FC<Props> = ({
  breadCrumbs,
  headerProps,
  showSuspensionModal,
  onCaNamingModalOpen,
  isLoading,
  elements,
}) => {
  // permissions - start
  const isCaEditorAllowed = usePermissionBasedAccess([DashboardRoles.caEditor]);
  const hasAccountSuspenderPermission = usePermissionBasedAccess([DashboardRoles.accountSuspender]);
  // permissions - end

  if (isLoading || !headerProps) {
    return <LoadingHeader breadCrumbs={breadCrumbs} />;
  }

  const isEditButtonShown = isCaEditorAllowed && headerProps.areTradeAndTransferButtonsShown;

  return (
    <StyledHeaderBox>
      <StyledTopRow>
        <Breadcrumbs items={breadCrumbs} />
        <Row>
          {hasAccountSuspenderPermission && headerProps.isSuspensionBannerShown && <SuspensionBanner />}
          {hasAccountSuspenderPermission && headerProps.isSuspensionActionShown && (
            <SuspensionAction isSuspendMode={headerProps.isSuspendMode} onClick={showSuspensionModal} />
          )}
          {isEditButtonShown && (
            <>
              <EditButton isSkeletonDisplayed={isLoading} onClick={onCaNamingModalOpen} />
              <Gap isHorizontal size="_20px" />
            </>
          )}
          {headerProps.areTradeAndTransferButtonsShown && <Elements elements={elements} isPageLoading={isLoading} />}
        </Row>
      </StyledTopRow>
      <Gap size="_24px" />
      <LabelRow title={headerProps.accountLabel} />
      <NameRow title={headerProps.accountName} />
      <TitleRow
        isAccountNameExist={!!headerProps.accountName}
        title={headerProps.title}
        statusCellProps={headerProps.statusCellProps}
      />
      <Gap size="_20px" />
      <HeaderItemsGrid headerProps={headerProps} />
    </StyledHeaderBox>
  );
};
