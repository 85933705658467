import React from 'react';
import { TFuncKey, useTranslation } from 'react-i18next';
import { Row } from 'components/shared/Flex/Row';
import { KycLevel } from 'models/enums/identities/kyc-level';
import { StyledKysLevelRow, StyledKycLevelLabel } from './styled';

type Props = {
  kycLevel: KycLevel;
};

export const IdentityKycLevel: React.FC<Props> = ({ kycLevel }) => {
  const { t } = useTranslation();

  return (
    <Row sx={{ alignItems: 'center' }}>
      <StyledKysLevelRow kycLevel={kycLevel}>
        <StyledKycLevelLabel variant="overlineSmallBold" kycLevel={kycLevel}>
          {t(`identityDetailsPage.kycLevels.${kycLevel}` as TFuncKey)}
        </StyledKycLevelLabel>
      </StyledKysLevelRow>
    </Row>
  );
};
