import React from 'react';
import { Text as _Text } from '@react-pdf/renderer';
import { styles } from './styles';

const Text: any = _Text;

type Props = {
  value: string;
};

export const MainTitle: React.FC<Props> = ({ value }) => {
  return <Text style={styles.text}>{value}</Text>;
};
