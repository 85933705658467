import React from 'react';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { StyledDataGridLoading } from './styled';

type Props = {
  rows: GridRowsProp;
  columns: GridColDef[];
};

export const CustomLoadingOverlay: React.FC<Props> = ({ rows, columns }) => {
  return (
    <StyledDataGridLoading
      rowHeight={64}
      headerHeight={0}
      columns={columns}
      rows={rows}
      hideFooter
      disableSelectionOnClick
      disableColumnMenu
      disableColumnFilter
      disableVirtualization
    />
  );
};
