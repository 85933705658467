import { CreateUserRequestModel } from 'models/request/users/create-user-request-model';
import { DashboardRoles } from 'models/enums/user/dashboard-roles';
import { UserLimitModel } from 'models/response/users/user-limit-model';
import { UserRoleTemplateResponseModel } from 'models/response/users/user-role-template-response-model';
import { IRoleTemplatesInputs } from 'ui-interfaces/user-form/i-role-templates-inputs';
import { IRoleRadio } from 'ui-interfaces/user-form/i-role-radio';
import { IPermissionsCheckbox } from 'ui-interfaces/user-form/i-permissions-checkbox';
import { ILimitInputCollection } from 'ui-interfaces/user-form/i-limit-input-collection';
import { generateRoles } from 'utils/user-form/generate-roles';
import { generatePermissions } from 'utils/user-form/generate-permissions';
import { getSelectedPermissionsIndexes } from 'utils/user-form/get-selected-permissions-indexes';
import { LimitInputCollectionType } from 'ui-enums/create-user-form/limit-input-collection-type';
import { LimitInputCollection } from './limits/limit-input-collection';

export class RoleTemplatesViewModel
  implements IRoleTemplatesInputs<Pick<CreateUserRequestModel, 'roleTemplate' | 'permissions' | 'limits'>>
{
  readonly userRoleTemplates: UserRoleTemplateResponseModel[];

  private _selectedUserRoleTemplateIndex: number;

  private readonly initiallySelectedUserRoleTemplateIndex: number;

  private readonly _roles: IRoleRadio[];

  private _permissions: IPermissionsCheckbox[];

  private _checkedPermissions: Set<number>;

  private readonly _approveLimitsCollection: ILimitInputCollection;

  private readonly _initLimitsCollection: ILimitInputCollection;

  constructor(userRoleTemplateResponseModels: UserRoleTemplateResponseModel[]) {
    this.userRoleTemplates = userRoleTemplateResponseModels;
    this._selectedUserRoleTemplateIndex = 0;
    this.initiallySelectedUserRoleTemplateIndex = 0;

    this._roles = generateRoles(this.userRoleTemplates);

    this._permissions = generatePermissions(this.userRoleTemplates[this.selectedUserRoleTemplateIndex]);

    this._checkedPermissions = getSelectedPermissionsIndexes(
      this.userRoleTemplates[this.selectedUserRoleTemplateIndex],
    );

    this._approveLimitsCollection = new LimitInputCollection(LimitInputCollectionType.approve);
    this._initLimitsCollection = new LimitInputCollection(LimitInputCollectionType.init);

    this.syncLimitCollections();
  }

  private syncLimitCollections() {
    if (this.shouldDisplayApproveLimitsCollection()) {
      this._approveLimitsCollection.add();
    }

    if (this.shouldDisplayInitLimitsCollection()) {
      this._initLimitsCollection.add();
    }
  }

  private shouldDisplayApproveLimitsCollection(): boolean {
    return !!this._permissions.find(
      p => p.dataValue === DashboardRoles.paymentApprover && this._checkedPermissions.has(p.value),
    );
  }

  private shouldDisplayInitLimitsCollection(): boolean {
    return !!this._permissions.find(
      p => p.dataValue === DashboardRoles.paymentInitiator && this._checkedPermissions.has(p.value),
    );
  }

  private getLimitsState(): UserLimitModel[] {
    return this._approveLimitsCollection.getState().concat(this._initLimitsCollection.getState());
  }

  get roles(): IRoleRadio[] {
    return this._roles;
  }

  get selectedUserRoleTemplateIndex(): number {
    return this._selectedUserRoleTemplateIndex;
  }

  set selectedUserRoleTemplateIndex(value: number) {
    this._selectedUserRoleTemplateIndex = value;
    this._permissions = generatePermissions(this.userRoleTemplates[this.selectedUserRoleTemplateIndex]);

    this._checkedPermissions = getSelectedPermissionsIndexes(
      this.userRoleTemplates[this.selectedUserRoleTemplateIndex],
    );

    this._approveLimitsCollection.reset();
    this._initLimitsCollection.reset();

    this.syncLimitCollections();
  }

  get permissions(): IPermissionsCheckbox[] {
    return this._permissions.slice();
  }

  get checkedPermissions(): Set<number> {
    return this._checkedPermissions;
  }

  get approveLimitsCollection(): ILimitInputCollection {
    return this._approveLimitsCollection;
  }

  get initLimitsCollection(): ILimitInputCollection {
    return this._initLimitsCollection;
  }

  selectPermission(value: number) {
    if (this._checkedPermissions.has(value)) {
      this._checkedPermissions.delete(value);
    } else {
      this._checkedPermissions.add(value);
    }

    const permission = this._permissions.find(p => p.value === value)!;

    if (permission.dataValue === DashboardRoles.paymentApprover) {
      this._approveLimitsCollection.reset();
      if (this._checkedPermissions.has(permission.value)) {
        this._approveLimitsCollection.add();
      }
    }

    if (permission.dataValue === DashboardRoles.paymentInitiator) {
      this._initLimitsCollection.reset();

      if (this._checkedPermissions.has(permission.value)) {
        this._initLimitsCollection.add();
      }
    }
  }

  getState(): Pick<CreateUserRequestModel, 'roleTemplate' | 'permissions' | 'limits'> {
    const state: Pick<CreateUserRequestModel, 'roleTemplate' | 'permissions' | 'limits'> = {
      roleTemplate: this.userRoleTemplates[this.selectedUserRoleTemplateIndex].roleTemplate,
      permissions: this._permissions.filter(p => this._checkedPermissions.has(p.value)).map(p => p.dataValue),
    };

    const limitsState = this.getLimitsState();

    if (limitsState.length > 0) {
      state.limits = limitsState;
    }

    return state;
  }

  reset(): void {
    // reset ui relevant fields
    this._selectedUserRoleTemplateIndex = this.initiallySelectedUserRoleTemplateIndex;
    this._permissions = generatePermissions(this.userRoleTemplates[this.selectedUserRoleTemplateIndex]);
    this._checkedPermissions = getSelectedPermissionsIndexes(
      this.userRoleTemplates[this.selectedUserRoleTemplateIndex],
    );
    this._approveLimitsCollection.reset();
    this._initLimitsCollection.reset();
    this.syncLimitCollections();
  }

  // eslint-disable-next-line class-methods-use-this
  isStateChanged(): boolean {
    return false;
  }
}
