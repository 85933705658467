import { Filterable } from 'ui-interfaces/shared/filters/filterable';
import { Filter } from 'filters/interfaces/filter';
import { SelectionMacroCommand } from 'filters/commands/selection-macro-command';
import { getTransactionsFilters } from 'utils/transactions/list/get-transactions-filters';
import { createTransactionsFilters } from './create-transactions-filters';

export class TransactionsListFiltersViewModel implements Filterable {
  readonly filters: Filter[];

  readonly selectionMacroCommand: SelectionMacroCommand;

  constructor() {
    const filterable = getTransactionsFilters(createTransactionsFilters());
    this.filters = filterable.filters;
    this.selectionMacroCommand = filterable.selectionMacroCommand;
  }
}
