import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { ReactComponent as AddIcon } from 'assets/icons/settings/add-user.svg';
import { StyledButton } from './styled';

type Props = {
  onClick: () => void;
};

export const AddUserButton: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <StyledButton variant="contained" startIcon={<AddIcon />} color="primary" onClick={onClick}>
      <Typography variant="buttonMedium" color="background.paper">
        {t('settingsPage.grid.addUserButton')}
      </Typography>
    </StyledButton>
  );
};
