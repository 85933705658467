import { Button, buttonClasses, CircularProgress, Skeleton, styled } from '@mui/material';
import { omitCustomStyleProps } from 'utils/omitCustomStyleProps';

type StyledContainerProps = {
  isContentHidden: boolean;
};

export const StyledContainer = styled(
  'span',
  omitCustomStyleProps<StyledContainerProps>(['isContentHidden']),
)<StyledContainerProps>(({ isContentHidden }) => ({
  display: 'inline-flex',
  visibility: isContentHidden ? 'hidden' : 'visible',
}));

export const StyledCircularProgress = styled(CircularProgress)(() => ({
  position: 'absolute',
}));

type StyledButtonProps = {
  loading: boolean;
};
export const StyledButton = styled(
  Button,
  omitCustomStyleProps<StyledButtonProps>(['loading']),
)<StyledButtonProps>(({ loading, theme, color }) => ({
  ...(loading && {
    [`&.${buttonClasses.containedPrimary}`]: {
      [`&.${buttonClasses.disabled}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.paper,
      },
    },
    ...(color === 'success' && {
      [`&.${buttonClasses.contained}`]: {
        [`&.${buttonClasses.disabled}`]: {
          backgroundColor: theme.palette.success.main,
          color: theme.palette.background.paper,
        },
      },
    }),
    ...(color === 'error' && {
      [`&.${buttonClasses.contained}`]: {
        [`&.${buttonClasses.disabled}`]: {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.background.paper,
        },
      },
    }),
  }),
}));

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  transform: 'none',
  borderRadius: theme.gaps._8px,
  backgroundColor: theme.palette.grey['100'],
}));
