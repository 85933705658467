import { useEffect } from 'react';

const defaultObserverParams = { threshold: 0, root: null, rootMargin: '0%' };

const useInfiniteScroll = (
  observedElement: HTMLDivElement | null,
  getItemsCallback: () => void,
  observerParams = defaultObserverParams,
) => {
  // callback that will be called when node elements comes into view zone
  const actionAtIntersection = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      getItemsCallback();
    }
  };

  useEffect(() => {
    const node = observedElement; // DOM element that triggers an actionAtIntersection
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || !node) return undefined;

    // new Instance of Intersection Observer initiation
    const observer = new IntersectionObserver(actionAtIntersection, observerParams);

    // set IO to observe if the node element comes into view zone
    observer.observe(node);

    return () => observer.disconnect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observedElement, getItemsCallback]);
};

export default useInfiniteScroll;
