import { FormikProps, FormikValues } from 'formik';
import { getBasicFormikProps } from './getBasicFormikProps';
import { getFormikProps } from './getFormikProps';
import { getFormikPropsWithOnChangeStringParameter } from './getFormikPropsWithOnChangeStringParameter';
import { getFormikPropsWithoutEventHandlers } from './getFormikPropsWithoutEventHandlers';
import { getFormikPropsWithoutOnBlur } from './getFormikPropsWithoutOnBlur';
import { getFormikPropsWithoutOnChange } from './getFormikPropsWithoutOnChange';
import { isFormDisabled } from './isFormDisabled';

export const getFormikHelpers = <Values extends FormikValues>(formik: FormikProps<Values>) => ({
  getBasicFormikProps: getBasicFormikProps<Values>(formik),
  getFormikProps: getFormikProps<Values>(formik),
  withOnChangeStringParameter: getFormikPropsWithOnChangeStringParameter<Values>(formik),
  withoutBlur: getFormikPropsWithoutOnBlur,
  withoutChange: getFormikPropsWithoutOnChange,
  withoutHandlers: getFormikPropsWithoutEventHandlers,
  isFormDisabled: isFormDisabled<Values>(formik),
});

export type FormCustomErrors<T extends object> = Partial<Record<keyof T, string>>;
