import { Box, TypeBackground } from '@mui/material';
import { css } from '@mui/material/styles';
import { ElementsCustomTheme, elementsStyled } from 'elements/theme/createCustomTheme';
import { ROOT_CONTAINER_MARGIN_DESKTOP, ROOT_CONTAINER_MARGIN_MOBILE, preventForwardProps } from 'elements/utils';
import { Signature } from 'elements/components/Signature';

const getBackgroundColor = (theme: ElementsCustomTheme, defaultBackgroundColor?: keyof TypeBackground): string =>
  theme.palette.background.custom ||
  (defaultBackgroundColor && theme.palette.background[defaultBackgroundColor]) ||
  theme.palette.background.default;

// This container is used to set this block height of popper window for proper work of overflow: auto
export const StyledUpperContainer = elementsStyled(
  Box,
  preventForwardProps(['defaultBackgroundColor', 'isAbove']),
)<{ defaultBackgroundColor?: keyof TypeBackground; isAbove: boolean }>(
  ({ theme, defaultBackgroundColor, isAbove }) => css`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    margin: auto;
    display: flex;
    flex-direction: column;
    background: ${getBackgroundColor(theme, defaultBackgroundColor)};
    ${isAbove && 'z-index: 10;'}
  `,
);

export const StyledScrollableContainer = elementsStyled(
  Box,
  preventForwardProps(['withHeader', 'isMobile']),
)<{ withHeader?: boolean; isMobile: boolean }>(
  ({ isMobile, withHeader }) => css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: ${withHeader ? 0 : 32}px
      ${isMobile ? ROOT_CONTAINER_MARGIN_MOBILE.HORIZONTAL : ROOT_CONTAINER_MARGIN_DESKTOP.HORIZONTAL}px 0;
  `,
);

export const StyledScrollableInnerContainer = elementsStyled(
  Box,
  preventForwardProps(['isContentCentered', 'fullContentHeight']),
)<{ isContentCentered?: boolean; fullContentHeight?: boolean }>(
  ({ isContentCentered, fullContentHeight }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    ${isContentCentered ? 'margin: auto 0;' : ''}
    ${fullContentHeight && 'height: 100%;'}
  `,
);

export const StyledFooter = elementsStyled(
  Box,
  preventForwardProps(['defaultBackgroundColor', 'isMobile']),
)<{ defaultBackgroundColor?: keyof TypeBackground; isMobile: boolean }>(
  ({ theme, isMobile, defaultBackgroundColor }) => css`
    width: 100%;
    box-shadow: 0px -4px 16px ${getBackgroundColor(theme, defaultBackgroundColor)};
    padding: 0 ${isMobile ? ROOT_CONTAINER_MARGIN_MOBILE.HORIZONTAL : ROOT_CONTAINER_MARGIN_DESKTOP.HORIZONTAL}px;
    padding-top: 16px;
    z-index: 6;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
);

export const StyledSignature = elementsStyled(Signature)`
  margin: 16px 0;
  padding: 6px;
  width: max-content;
`;
