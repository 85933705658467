import { styled } from '@mui/material/styles';
import { Skeleton } from '@mui/material';
import { pxToRem, pxToVw } from 'utils/styling-utils';
import { Row } from 'components/shared/Flex/Row';

export const StyledTitleRowSkeleton = styled(Skeleton)(({ theme }) => ({
  height: '100%',
  width: pxToVw(320),
  backgroundColor: theme.palette.grey['200'],
  borderRadius: pxToRem(theme.gaps._12px),
}));

export const StyledRow = styled(Row)(({ theme }) => ({
  alignItems: 'center',
  height: pxToRem(theme.gaps._32px),
}));
