import React from 'react';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import i18n from 'localizations';

type Props = {
  onClick: () => void;
};

export const ClearButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Button variant="outlined" onClick={onClick}>
      <Typography variant="buttonMedium">{i18n.t('filters.clearButtonLabel')}</Typography>
    </Button>
  );
};
