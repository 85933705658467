import React from 'react';
import { ProtectedOutlet } from 'navigation/utils/ProtectedOutlet';
import { DEFAULT_ROUTE } from 'navigation/constants';
import { useAuth } from 'auth0/hooks/use-auth';

export const PublicRoutesRestriction: React.FC = () => {
  const { isProcessing, isLoading, isAuthenticated } = useAuth();

  if (isProcessing || isLoading) {
    return null;
  }

  return <ProtectedOutlet fallbackRoute={DEFAULT_ROUTE} fallbackCondition={isAuthenticated} />;
};
