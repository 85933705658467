import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { TransferFlow } from 'elements/element-transfer/types';
import * as api from 'elements/element-transfer/api';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { LoadingPage } from 'elements/features/LoadingErrorState';
import { selectDefaultExternalAccount } from 'elements/features/PaymentMethods';
import { setErrorBanner } from 'elements/utils';
import ContentBlock from 'elements/element-transfer/components/Deposit/AchAmountPage';
import {
  setDepositAchAmount,
  setDepositAchExternalAccountList,
  setDepositAchPaymentAccount,
  setPage,
  useElement,
} from 'elements/element-transfer/contexts/Element';
import getFilteredExternalAccountList from 'elements/element-transfer/utils/getFilteredExternalAccountList';
import { OnCloseElement } from 'elements/models/types/element-result';
import DepositPaymentMethodPage from './PaymentMethodPage';

const AchAmountPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch, state } = useElement();

  const achDepositState = state[TransferFlow.Deposit].ACH;

  const newLinkedAccountId = state.page.state?.linkedAccountId as string | void;

  const [isLoading, setIsLoading] = useState(true);
  const [isGetAccountsFailed, setIsGetAccountsFailed] = useState(false);
  const isContinueButtonDisabled =
    isLoading ||
    isGetAccountsFailed ||
    !achDepositState?.amount ||
    !achDepositState?.paymentAccount ||
    !state?.custodialAccount;

  const requestAccounts = async () => {
    try {
      setIsLoading(true);
      setIsGetAccountsFailed(false);

      const externalAccountList = await api.transferElement.getExternalAccounts(state.identityId);

      const filteredExternalAccountList = getFilteredExternalAccountList(
        externalAccountList.data,
        state.paymentMethodTypesMap,
      );

      dispatch(setDepositAchExternalAccountList(filteredExternalAccountList));
      dispatch(setDepositAchPaymentAccount(selectDefaultExternalAccount(filteredExternalAccountList)));

      setIsLoading(false);
    } catch (e) {
      const isBannerShown = setErrorBanner(e, enqueueSnackbar);
      const withBackNavigation = state.transferOptionsList.length > 1;

      if (!isBannerShown || !withBackNavigation) {
        setIsGetAccountsFailed(true);
      }
      if (withBackNavigation) {
        dispatch(setPage(Paths.Transfer));
      }
      setIsLoading(false);
    }
  };

  const onAmountInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } },
  ) => {
    dispatch(setDepositAchAmount(event.target.value));
  };

  const onContinue = () => {
    dispatch(setPage(Paths.DepositAchReview));
  };

  const onReload = async () => {
    await requestAccounts();
  };

  const onBack = () => {
    dispatch(setPage(Paths.Transfer));
  };

  const onPaymentMethod = () => {
    dispatch(setPage(Paths.DepositPaymentMethod));
  };

  const requestAccountsWithNewExternal = async () => {
    try {
      setIsLoading(true);

      const externalAccountList = await api.transferElement.getExternalAccounts(state.identityId);

      const filteredExternalAccountList = getFilteredExternalAccountList(
        externalAccountList.data,
        state.paymentMethodTypesMap,
      );

      dispatch(setDepositAchExternalAccountList(filteredExternalAccountList));

      const newLinkedMethod = filteredExternalAccountList.find(el => el.id === newLinkedAccountId);

      dispatch(setDepositAchPaymentAccount(newLinkedMethod));

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (newLinkedAccountId) {
      (async () => {
        await requestAccountsWithNewExternal();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLinkedAccountId]);

  useEffect(() => {
    // eslint-disable-next-line no-return-await
    (async () => await requestAccounts())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!achDepositState) return null;

  return (
    <>
      {isLoading ? (
        <LoadingPage onClose={onClose} />
      ) : (
        <ContentBlock
          amount={achDepositState.amount}
          paymentAccount={achDepositState.paymentAccount}
          custodialAccount={state.custodialAccount}
          isGetAccountsError={isGetAccountsFailed}
          isContinueButtonDisabled={isContinueButtonDisabled}
          onContinue={onContinue}
          onAmountInputChange={onAmountInputChange}
          onPaymentMethod={onPaymentMethod}
          onReload={onReload}
          onBack={onBack}
          onClose={onClose}
        />
      )}
      {state.page.location === Paths.DepositPaymentMethod ? <DepositPaymentMethodPage onClose={onClose} /> : null}
    </>
  );
};

export default AchAmountPage;
