import React from 'react';
import { Text as _Text } from '@react-pdf/renderer';
import { ParticipantInfo } from 'elements/element-transfer/types';
import { getWireAddressLine3 } from 'elements/element-transfer/utils/getWireAddressLine3';
import { PDFTemplateStyles as styles } from './styled';

const Text: any = _Text;

type Props = {
  header: string;
  participantInfo: ParticipantInfo;
};

export const ParticipantSection: React.FC<Props> = ({
  header,
  participantInfo: { name, street1, street2, city, state, postalCode },
}) => (
  <>
    <Text style={styles.participantInfoSectionHeader}>{header}</Text>
    <Text style={styles.participantInfoSectionTitle}>{name}</Text>
    <Text style={styles.participantInfoSectionText}>{street1}</Text>
    {street2 && <Text style={styles.participantInfoSectionText}>{street2}</Text>}
    <Text style={styles.participantInfoSectionText}>{getWireAddressLine3(city, state, postalCode)}</Text>
  </>
);
