import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderDetailsItem } from 'components/shared/HeaderDetailsItem';
import { IIdentityDetailsPageHeader } from 'ui-interfaces/identity-details/i-identity-details-page-header';

// icons - start
import { ReactComponent as IdentityIcon } from 'assets/icons/identity-details/identity.svg';
import { ReactComponent as DateIcon } from 'assets/icons/identity-details/date.svg';
import { ReactComponent as LastActivityIcon } from 'assets/icons/custodial-accounts/last-activity-icon.svg';
import { ReactComponent as AccountTypeIcon } from 'assets/icons/custodial-accounts/account-type.svg';
// icons - end
import { StyledBox } from './styled';

type Props = {
  data?: IIdentityDetailsPageHeader;
  loading: boolean;
};

export const IdentityDetailsPageHeader: React.FC<Props> = ({ data, loading }) => {
  const { t } = useTranslation();

  return (
    <StyledBox>
      <HeaderDetailsItem
        value={data?.typeLabel ?? ''}
        label={t('identityDetailsPage.identityType')}
        icon={<AccountTypeIcon />}
        loading={loading}
        large
      />

      <HeaderDetailsItem
        value={data?.id ?? ''}
        label={t('identityDetailsPage.identityId')}
        icon={<IdentityIcon />}
        loading={loading}
        large
      />

      <HeaderDetailsItem
        value={data?.createdAt ?? ''}
        label={t('identityDetailsPage.dateCreated')}
        icon={<DateIcon />}
        loading={loading}
      />

      <HeaderDetailsItem
        value={data?.modifiedAt ?? ''}
        label={t('identityDetailsPage.lastUpdate')}
        icon={<LastActivityIcon />}
        loading={loading}
        tooltip={t('identityDetailsPage.lastUpdateTooltip')}
      />
    </StyledBox>
  );
};
