import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Divider, Stack } from 'elements/components';
import { CustodialAccount } from 'elements/types';
import { formatUsdBalance } from 'elements/utils';
import { getAccountData } from 'elements/element-transfer/utils/getAccountData';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import {
  StyledAccountContainer,
  StyledAmountContainer,
  StyledContainer,
  StyledIconContainer,
  StyledTextContainer,
  StyledTruncatedTypography,
} from './styled';

type Props = {
  custodialAccount: CustodialAccount;
  withBalance?: boolean;
};

const WireSource: React.FC<Props> = ({ custodialAccount, withBalance }) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  const { type, accountNumber, balance } = custodialAccount;

  const { Icon, title } = getAccountData(type);
  const postfix = `*${accountNumber.slice(-4)}`;

  return (
    <StyledContainer>
      <StyledAccountContainer>
        <StyledIconContainer>
          <Icon />
        </StyledIconContainer>
        <StyledTextContainer>
          <Typography variant="captionMedium" color="secondary.font">
            {t('elements.transfer.withdrawalWireAmountPage.fromLabel')}
          </Typography>
          <Stack direction="row" fullWidth gap={0.5}>
            <StyledTruncatedTypography variant="bodyDefaultMedium" color="primary.font">
              {title}
            </StyledTruncatedTypography>
            <Typography variant="bodyDefaultMedium" color="primary.font">
              {postfix}
            </Typography>
          </Stack>
        </StyledTextContainer>
      </StyledAccountContainer>
      {withBalance && (
        <>
          <Divider />
          <StyledAmountContainer>
            <StyledTruncatedTypography variant="bodyDefaultBook" color="primary.font">
              {t('elements.transfer.withdrawalWireAmountPage.availableAmountLabel')}
            </StyledTruncatedTypography>
            <Typography variant="bodyDefaultMedium" color="primary.font">
              {formatUsdBalance(balance, isMobile)}
            </Typography>
          </StyledAmountContainer>
        </>
      )}
    </StyledContainer>
  );
};

export default WireSource;
