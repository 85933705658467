import React from 'react';
import { Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import i18n from 'localizations';
import { CustodialAccountsRow } from 'ui-interfaces/row-interfaces/custodial-accounts';
import { GuidCell } from 'components/shared/Cells/GuidCell';
import { StatusCell } from 'components/shared/Cells/StatusCell';
import { GridCellExpand } from 'components/shared/Cells/GridCellExpand';

export const columnsCustodialAccountsTab: GridColDef[] = [
  {
    field: 'name',
    headerName: i18n.t('custodialAccountsPage.name'),
    flex: 1.1,
    renderCell: (params: GridRenderCellParams) => {
      const { accountName } = params.row as CustodialAccountsRow;
      return <GridCellExpand value={accountName} width={params.colDef.computedWidth} />;
    },
  },
  {
    field: 'custodialAccountId',
    headerName: i18n.t('custodialAccountsPage.accountId'),
    flex: 1.2,
    renderCell: (params: GridRenderCellParams) => <GuidCell id={params.value} />,
  },
  {
    field: 'custodialAccountNumber',
    headerName: i18n.t('custodialAccountsPage.accountNumber'),
    flex: 1.2,
  },
  {
    field: 'typeLabel',
    headerName: i18n.t('custodialAccountsPage.accountType'),
    flex: 1.1,
  },
  {
    field: 'lastActivity',
    headerName: i18n.t('custodialAccountsPage.lastActivity'),
    flex: 1.1,
    renderCell: (params: GridRenderCellParams) => {
      const { lastActivity } = params.row as CustodialAccountsRow;
      return !lastActivity ? (
        <Typography variant="bodyDefaultBook" color="grey.500">
          {i18n.t('custodialAccountsPage.noActivity')}
        </Typography>
      ) : (
        lastActivity
      );
    },
  },
  {
    field: 'status',
    headerName: i18n.t('custodialAccountsPage.status'),
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const { statusCellProps } = params.row as CustodialAccountsRow;
      return <StatusCell {...statusCellProps} />;
    },
  },
  // TODO: reveal when backend sends up-to-date usd balance
  // {
  //   field: 'usdBalance',
  //   renderHeader: () => <BalanceHeaderCell />,
  //   flex: 1,
  //   headerAlign: 'right',
  //   align: 'right',
  // },
];
