import React from 'react';
import { TransferFlow } from 'elements/element-transfer/types';
import ContentBlock from 'elements/element-transfer/components/Withdrawal/CryptoSentPage';
import { useElement } from 'elements/element-transfer/contexts/Element';
import { sendPaymentIdMessage } from 'elements/element-transfer/utils/sendPaymentIdMessage';
import { OnCloseElement } from 'elements/models/types/element-result';

const CryptoSentPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { state } = useElement();
  const sendCryptoState = state[TransferFlow.Withdrawal].CRYPTO;
  const onDone = () => {
    onClose(sendPaymentIdMessage({ cryptoWithdrawalPaymentId: state.cryptoWithdrawalPaymentId }));
    // @TODO remove when element will use RTK query with common re-fetch approach
    window.location.reload();
  };

  return <ContentBlock onDone={onDone} sendCryptoState={sendCryptoState} custodialAccount={state.custodialAccount} />;
};

export default CryptoSentPage;
