import i18n from 'localizations';
import { FilterValueSet } from 'filters/interfaces/filter-value-set';
import { Currency } from 'ui-enums/response/transactions/currency';

export class GenericValueSets {
  static readonly currencyValueSet: FilterValueSet[] = [
    {
      value: Currency.usd,
      displayValue: i18n.t('cryptoAssets.usd.long'),
    },
    {
      value: Currency.privateSecurity,
      displayValue: i18n.t('cryptoAssets.privateSecurity.long'),
    },
    {
      value: Currency.btc,
      displayValue: i18n.t('cryptoAssets.btc.long'),
    },
    {
      value: Currency.eth,
      displayValue: i18n.t('cryptoAssets.eth.long'),
    },
    {
      value: Currency.matic,
      displayValue: i18n.t('cryptoAssets.matic.long'),
    },
    {
      value: Currency.sol,
      displayValue: i18n.t('cryptoAssets.sol.long'),
    },
    {
      value: Currency.flrns,
      displayValue: i18n.t('cryptoAssets.flrns.long'),
    },
    {
      value: Currency.usdc,
      displayValue: i18n.t('cryptoAssets.usdc.long'),
    },
    {
      value: Currency.ada,
      displayValue: i18n.t('cryptoAssets.ada.long'),
    },
    {
      value: Currency.xlm,
      displayValue: i18n.t('cryptoAssets.xlm.long'),
    },
    {
      value: Currency.dot,
      displayValue: i18n.t('cryptoAssets.dot.long'),
    },
    {
      value: Currency.doge,
      displayValue: i18n.t('cryptoAssets.doge.long'),
    },
    {
      value: Currency.shib,
      displayValue: i18n.t('cryptoAssets.shib.long'),
    },
    {
      value: Currency.link,
      displayValue: i18n.t('cryptoAssets.link.long'),
    },
    {
      value: Currency.algo,
      displayValue: i18n.t('cryptoAssets.algo.long'),
    },
    {
      value: Currency.busd,
      displayValue: i18n.t('cryptoAssets.busd.long'),
    },
    {
      value: Currency.bnb,
      displayValue: i18n.t('cryptoAssets.bnb.long'),
    },
    {
      value: Currency.avax,
      displayValue: i18n.t('cryptoAssets.avax.long'),
    },
    {
      value: Currency.ltc,
      displayValue: i18n.t('cryptoAssets.ltc.long'),
    },
    {
      value: Currency.uni,
      displayValue: i18n.t('cryptoAssets.uni.long'),
    },
    {
      value: Currency.crv,
      displayValue: i18n.t('cryptoAssets.crv.long'),
    },
    {
      value: Currency.aave,
      displayValue: i18n.t('cryptoAssets.aave.long'),
    },
    {
      value: Currency.bch,
      displayValue: i18n.t('cryptoAssets.bch.long'),
    },
    {
      value: Currency.xtz,
      displayValue: i18n.t('cryptoAssets.xtz.long'),
    },
    {
      value: Currency.comp,
      displayValue: i18n.t('cryptoAssets.comp.long'),
    },
    {
      value: Currency.eos,
      displayValue: i18n.t('cryptoAssets.eos.long'),
    },
    {
      value: Currency.bat,
      displayValue: i18n.t('cryptoAssets.bat.long'),
    },
    {
      value: Currency.atom,
      displayValue: i18n.t('cryptoAssets.atom.long'),
    },
    {
      value: Currency.grt,
      displayValue: i18n.t('cryptoAssets.grt.long'),
    },
    {
      value: Currency.sushi,
      displayValue: i18n.t('cryptoAssets.sushi.long'),
    },
    {
      value: Currency.sand,
      displayValue: i18n.t('cryptoAssets.sand.long'),
    },
    {
      value: Currency.enj,
      displayValue: i18n.t('cryptoAssets.enj.long'),
    },
    {
      value: Currency.axs,
      displayValue: i18n.t('cryptoAssets.axs.long'),
    },
    {
      value: Currency.mana,
      displayValue: i18n.t('cryptoAssets.mana.long'),
    },
    {
      value: Currency.mkr,
      displayValue: i18n.t('cryptoAssets.mkr.long'),
    },
    {
      value: Currency.yfi,
      displayValue: i18n.t('cryptoAssets.yfi.long'),
    },
    {
      value: Currency.ape,
      displayValue: i18n.t('cryptoAssets.ape.long'),
    },
    {
      value: Currency.dai,
      displayValue: i18n.t('cryptoAssets.dai.long'),
    },
    {
      value: Currency.near,
      displayValue: i18n.t('cryptoAssets.near.long'),
    },
    {
      value: Currency.usdt,
      displayValue: i18n.t('cryptoAssets.tether.long'),
    },
    {
      value: Currency.xrp,
      displayValue: i18n.t('cryptoAssets.xrp.long'),
    },
    {
      value: Currency.smtf,
      displayValue: i18n.t('cryptoAssets.smtf.long'),
    },
    {
      value: Currency.flow,
      displayValue: i18n.t('cryptoAssets.flow.long'),
    },
    {
      value: Currency.trx,
      displayValue: i18n.t('cryptoAssets.trx.long'),
    },
    {
      value: Currency.ftm,
      displayValue: i18n.t('cryptoAssets.ftm.long'),
    },
    {
      value: Currency.gala,
      displayValue: i18n.t('cryptoAssets.gala.long'),
    },
    {
      value: Currency.chz,
      displayValue: i18n.t('cryptoAssets.chz.long'),
    },
    {
      value: Currency.qnt,
      displayValue: i18n.t('cryptoAssets.qnt.long'),
    },
    {
      value: Currency.hbar,
      displayValue: i18n.t('cryptoAssets.hbar.long'),
    },
    {
      value: Currency.gold,
      displayValue: i18n.t('cryptoAssets.gold.long'),
    },
    {
      value: Currency.silver,
      displayValue: i18n.t('cryptoAssets.silver.long'),
    },
  ];

  static get currencyValueSetWithPrivateSecurityRemoved(): FilterValueSet[] {
    return GenericValueSets.currencyValueSet.filter(el => !(el.value === Currency.privateSecurity));
  }
}
