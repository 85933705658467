import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';

const domain = process.env.REACT_APP_AUTH0_DOMAIN ?? '';
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID ?? '';
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const scope = process.env.REACT_APP_AUTH0_SCOPE;

export class Auth0ClientSingleton {
  private static instance: Auth0Client | null = null;

  // eslint-disable-next-line no-useless-constructor,no-empty-function
  private constructor() {}

  static async getInstance() {
    if (!this.instance) {
      this.instance = await createAuth0Client({
        domain,
        clientId,
        authorizationParams: {
          redirect_uri: window.location.origin,
          audience,
          scope,
        },
      });
    }
    return this.instance;
  }
}
