import React from 'react';
import Typography from '@mui/material/Typography';
import { GridCellExpand } from 'components/shared/Cells/GridCellExpand';
import { CompoundLabel } from 'ui-interfaces/row-interfaces/transactions';

type Props = {
  value: CompoundLabel | string;
  width: number;
  color?: string;
};

export const AmountCell: React.FC<Props> = ({ value, width, color }) => {
  if (typeof value === 'string') {
    return <GridCellExpand value={value} width={width} color={color} />;
  }

  return (
    <div>
      <Typography variant="bodyDefaultBook">{`${value.start} `}</Typography>
      <Typography color="grey.600" variant="bodyDefaultBook">
        {value.end}
      </Typography>
    </div>
  );
};
