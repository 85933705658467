import { ElementResultStatus } from 'elements/models/enums/element-result-status';

export const sendPaymentIdMessage = ({
  sellPaymentId,
  buyTradePaymentId,
  buyDepositPaymentId,
}: {
  sellPaymentId: string | null;
  buyTradePaymentId: string | null;
  buyDepositPaymentId: string | null;
}) => {
  return {
    tradePayment: {
      sellPaymentId: sellPaymentId || null,
      buyTradePaymentId: buyTradePaymentId || null,
      buyDepositPaymentId: buyDepositPaymentId || null,
    },
    status: ElementResultStatus.Success,
  };
};
