import React from 'react';
import { View as _View, Image as _Image } from '@react-pdf/renderer';
import LogoIcon from 'assets/icons/pdf/logo_x3.png';
import { styles } from './styles';

const View: any = _View;
const Image: any = _Image;

export const Header: React.FC = () => {
  return (
    <View fixed>
      <Image src={`${LogoIcon}`} style={styles.image} />
      <View style={styles.divider} />
    </View>
  );
};
