import React from 'react';
import { PaddedRowBox } from 'components/shared/detail-page/rows/shared/PaddedRowBox';
import { IMt103NoValueRow } from 'ui-interfaces/transaction-details/rows/mt103/i-mt103-no-value-row';
import { LeftCell } from './LeftCell';

type Props = {
  data: IMt103NoValueRow;
  hasBottomBorder?: boolean;
};

export const MT103NoValueRow: React.FC<Props> = ({ data, hasBottomBorder }) => {
  return (
    <PaddedRowBox hasBottomBorder={hasBottomBorder}>
      <LeftCell code={data.code} label={data.label} />
    </PaddedRowBox>
  );
};
