import React from 'react';
import { TruncatedText } from 'elements/components';
import { ReactComponent as ArrowRightIcon } from 'elements/element-transfer/assets/icons/arrowRight.svg';
import { StyledButton, StyledStack } from './styled';

type Props = {
  startIcon: React.ReactElement;
  title: string;
  onClick: () => void;
};

const TransferOption: React.FC<Props> = ({ title, onClick, startIcon }) => {
  return (
    <StyledButton onClick={onClick} disableRipple>
      <StyledStack direction="row" alignCenter gap="12px">
        {startIcon}
        <TruncatedText variant="buttonLarge" color="primary.font">
          {title}
        </TruncatedText>
      </StyledStack>
      <ArrowRightIcon />
    </StyledButton>
  );
};

export default TransferOption;
