import React from 'react';

import { UserBaseResponseModel } from 'models/response/users/user-base-response-model';
import { ActionCellAction } from 'ui-interfaces/users/action-cell-action';
import { Action } from 'ui-enums/users/action-cell-type';
import { ReactComponent as ResendIcon } from 'assets/icons/settings/user-action/resend.svg';
import i18n from 'localizations';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { RoleTemplate } from 'models/enums/user/role-template';

export class ResendUserActionViewModel implements ActionCellAction {
  type = Action.resend;

  icon = (<ResendIcon />);

  label = i18n.t('settingsPage.grid.actions.resend');

  isDisplayed = false;

  constructor(user: UserBaseResponseModel, currentUser: ShortUserResponseModel) {
    this.isDisplayed = ResendUserActionViewModel.calculateIsDisplayed(user, currentUser);
  }

  static calculateIsDisplayed(user: UserBaseResponseModel, currentUser: ShortUserResponseModel) {
    const isCurrentUser = user.id === currentUser.id;

    switch (true) {
      case isCurrentUser: {
        return false;
      }

      case user.roleTemplate === RoleTemplate.owner: {
        return false;
      }

      case user.roleTemplate === RoleTemplate.admin:
      case user.roleTemplate === RoleTemplate.editor:
      case user.roleTemplate === RoleTemplate.viewer: {
        return !user.isActive;
      }

      default:
        return false;
    }
  }
}
