import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Spacer from 'components/shared/Spacer';
import { Units } from 'components/shared/Spacer/types';
import { PaymentType } from 'ui-enums/response/transactions/payment-type';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import { Row1 } from './Row1';
import { Row2 } from './Row2';

type Props = {
  date: string;
  time: string;
  type: PaymentType;
};

export const DateCell: React.FC<Props> = ({ date, time, type }) => {
  const { t } = useTranslation();

  return (
    <CommonTooltip
      placement="top"
      title={
        <Typography color="background.paper" variant="bodySmallBook">
          {t('transactionsPage.utcTimeZone')}
        </Typography>
      }
    >
      <Box display="flex" flexDirection="column">
        <Row1 date={date} type={type} />
        <Spacer size={4} unit={Units.px} />
        <Row2 time={time} />
      </Box>
    </CommonTooltip>
  );
};
