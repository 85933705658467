import { pxToRem } from 'utils/styling-utils';
import { createBasicTheme } from './create-basic-theme';

const mainTheme = createBasicTheme({
  palette: {
    grey: {
      700: '#1C1C1C', // grey700
      600: '#707070', // grey600
      500: '#8D8D8D', // grey500
      400: '#BABABA', // grey400
      300: '#DEDEDE', // grey300
      200: '#E8E8E8', // grey200
      100: '#F4F4F4', // grey100
      50: '#F7F7F7', // grey50
    },
    primary: {
      dark: '#223189', // Primary/Dark 1
      main: '#263698', // Primary/Default
      light: '#A8AFD6', // Primary/Light 1
      light2: '#F6F7FB', // Primary/Light 2
    },
    secondary: {
      dark: '#1F60BB', // Secondary/Dark 1
      dark2: '#184B92', // Secondary/Dark 2
      main: '#226BD0', // Secondary/Default
      light: '#D6E7FF', // Secondary/Light 1
      light2: '#F1F7FF', // Secondary/Light 2
    },
    error: {
      dark: '#A23030', // errorDark
      main: '#D43636', // errorDefault
      light: '#F99E9E', // errorLight
      light2: '#FBEBEB', // errorLight2
      light3: '#FDF5F5', // errorLight3
    },
    warning: {
      dark: '#D6530A', // warningDark
      main: '#F6851D', // warningDefault
      light: '#F6CA92', // warningLight
      light2: '#FDE7D2', // warningLight2
      light3: '#FEF3E8', // warningLight3
    },
    success: {
      dark: '#006644', // successDark
      main: '#00875A', // successDefault
      light: '#ABF5D1', // successLight
      light2: '#E5F3EE', // successLight2
    },
    info: {
      dark: '#0A3977', // infoDark
      main: '#0F56B3', // infoDefault
      light: '#8AB9F6', // infoLight
      light2: '#E7EEF7', // infoLight2
      light3: '#EEF3FA', // infoLight3
    },
    background: {
      paper: '#FFFFFF', // backgroundWhite
      default: '#FAFAFA', // backgroundGrey
    },
    text: {
      primary: '#1C1C1C', // textPrimary
      secondary: '#707070', // textSecondary
      disabled: '#8D8D8D', // textDisabled
    },
    transparent: 'transparent',
    brown: {
      main: '#945900', // Brown/Default,
      light: '#FAF4EB', // Brown/Light
    },
    magenta: {
      main: '#901E8C', // Magenta/Default
      light: '#F8F2F8', // Magenta/Light
    },
    teal: {
      main: '#E6F7F7',
    },
  },
  typography: {
    h1: {
      fontFamily: 'GothamBold',
      fontStyle: 'bold',
      fontWeight: 700,
      fontSize: pxToRem(40),
      lineHeight: '125%',
      letterSpacing: '-0.015em',
      color: '#1C1C1C', // grey700,
    },
    h2: {
      fontFamily: 'GothamBold',
      fontStyle: 'bold',
      fontWeight: 700,
      fontSize: pxToRem(33),
      lineHeight: '133%',
      letterSpacing: '-0.01em',
      color: '#1C1C1C', // grey700,
    },
    h3: {
      fontFamily: 'GothamBold',
      fontStyle: 'bold',
      fontWeight: 700,
      fontSize: pxToRem(28),
      lineHeight: '133%',
      letterSpacing: '-0.005em',
      color: '#1C1C1C', // grey700,
    },
    h4: {
      fontFamily: 'GothamMedium',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: pxToRem(26),
      lineHeight: '130%',
      color: '#1C1C1C', // grey700,
    },
    h5: {
      fontFamily: 'GothamBold',
      fontStyle: 'bold',
      fontWeight: 700,
      fontSize: pxToRem(18),
      lineHeight: '132%',
      color: '#1C1C1C', // grey700,
    },
    h6: {
      fontFamily: 'GothamBold',
      fontStyle: 'bold',
      fontWeight: 700,
      fontSize: pxToRem(18),
      lineHeight: '132%',
      letterSpacing: '0.0075em',
      color: '#1C1C1C', // grey700,
    },
    h7: {
      fontFamily: 'GothamBold',
      fontStyle: 'bold',
      fontWeight: 700,
      fontSize: pxToRem(16),
      lineHeight: '138%',
      letterSpacing: '0.0075em',
      color: '#1C1C1C', // grey700,
    },
    bodyDefaultBook: {
      fontFamily: 'GothamBook',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: pxToRem(14),
      lineHeight: '160%',
      color: '#1C1C1C', // grey700,
    },
    bodyDefaultItalic: {
      fontFamily: 'GothamBookItalic',
      fontStyle: 'italic',
      fontWeight: 400,
      fontSize: pxToRem(14),
      lineHeight: '160%',
      color: '#1C1C1C', // grey700,
    },
    bodySmallItalic: {
      fontFamily: 'GothamBookItalic',
      fontStyle: 'italic',
      fontWeight: 400,
      fontSize: pxToRem(13),
      lineHeight: '160%',
      color: '#707070', // grey600,
    },
    bodyDefaultMedium: {
      fontFamily: 'GothamMedium',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: pxToRem(14),
      lineHeight: '160%',
      color: '#1C1C1C', // grey700,
    },
    bodyDefaultBookLink: {
      fontFamily: 'GothamBook',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: pxToRem(14),
      lineHeight: '160%',
      textDecorationLine: 'underline',
      color: '#223189', // Primary/Dark 1,
      textDecorationColor: '#A8AFD6', // Primary/Light 1,
      cursor: 'pointer',
    },
    bodyDefaultBold: {
      fontFamily: 'GothamBold',
      fontStyle: 'bold',
      fontWeight: 700,
      fontSize: pxToRem(14),
      lineHeight: '160%',
      color: '#1C1C1C', // grey700,
    },
    bodySmallBook: {
      fontFamily: 'GothamBook',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: pxToRem(12),
      lineHeight: '150%',
      letterSpacing: '0.015em',
      color: '#1C1C1C', // grey700,
    },
    bodySmallMedium: {
      fontFamily: 'GothamMedium',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: pxToRem(12),
      lineHeight: '150%',
      letterSpacing: '0.015em',
      color: '#1C1C1C', // grey700,
    },
    bodySmallBold: {
      fontFamily: 'GothamBold',
      fontStyle: 'bold',
      fontWeight: 700,
      fontSize: pxToRem(12),
      lineHeight: '150%',
      letterSpacing: '0.015em',
      color: '#1C1C1C', // grey700,
    },
    subtitleBook: {
      fontFamily: 'GothamBook',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: pxToRem(16),
      lineHeight: '133%',
      letterSpacing: '0.005em',
      color: '#1C1C1C', // grey700,
    },
    subtitleMedium: {
      fontFamily: 'GothamMedium',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: pxToRem(16),
      lineHeight: '133%',
      letterSpacing: '0.005em',
      color: '#1C1C1C', // grey700,
    },
    captionMedium: {
      fontFamily: 'GothamBook',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: pxToRem(13),
      lineHeight: '140%',
      letterSpacing: '0.02em',
      color: '#1C1C1C', // grey700,
    },
    overlineDefaultBold: {
      fontFamily: 'GothamBold',
      fontStyle: 'bold',
      fontWeight: 700,
      fontSize: pxToRem(12),
      lineHeight: '133%',
      letterSpacing: '0.05em',
      color: '#1C1C1C', // grey700,
    },
    overlineSmallBold: {
      fontFamily: 'GothamBold',
      fontStyle: 'bold',
      fontWeight: 700,
      fontSize: pxToRem(10),
      lineHeight: '140%',
      letterSpacing: '0.05em',
      color: '#1C1C1C', // grey700,
    },
    buttonLarge: {
      fontFamily: 'GothamMedium',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: pxToRem(16),
      lineHeight: '150%',
      letterSpacing: '0.02em',
      color: '#1C1C1C', // grey700,
    },
    buttonMedium: {
      fontFamily: 'GothamMedium',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: pxToRem(14),
      lineHeight: '160%',
      letterSpacing: '0.02em',
      color: '#1C1C1C', // grey700,
    },
    buttonSmall: {
      fontFamily: 'GothamMedium',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: pxToRem(13),
      lineHeight: '150%',
      letterSpacing: '0.02em',
      color: '#1C1C1C', // grey700,
    },
    // disable not needed default fonts
    body1: undefined,
    body2: undefined,
    button: undefined,
    caption: undefined,
    overline: undefined,
    subtitle1: undefined,
    subtitle2: undefined,
  },
  customShadows: {
    grey: {
      _2dp: '0px 1px 2px rgba(112, 112, 112, 0.1), 0px 2px 4px rgba(112, 112, 112, 0.1)',
      _4dp: '0px 1px 4px rgba(112, 112, 112, 0.1), 0px 4px 8px rgba(112, 112, 112, 0.1)',
      _8dp: '0px 2px 8px rgba(112, 112, 112, 0.12), 0px 6px 16px rgba(112, 112, 112, 0.12)',
      _16dp: '0px 8px 16px 2px rgba(112, 112, 112, 0.1), 0px 16px 32px 2px rgba(112, 112, 112, 0.1)',
      _24dp: '0px 12px 24px 6px rgba(112, 112, 112, 0.08), 0px 24px 48px 6px rgba(112, 112, 112, 0.08)',
      _32dp: '0px 24px 48px 10px rgba(112, 112, 112, 0.06), 0px 32px 64px 10px rgba(112, 112, 112, 0.06)',
    },
    teal: {
      _2dp: '0px 1px 2px rgba(0, 119, 106, 0.2), 0px 2px 4px rgba(0, 119, 106, 0.2)',
      _4dp: '0px 2px 4px rgba(0, 119, 106, 0.18), 0px 4px 8px rgba(0, 119, 106, 0.18)',
      _8dp: '0px 4px 8px rgba(0, 119, 106, 0.14), 0px 8px 16px rgba(0, 119, 106, 0.14)',
      _16dp: '0px 8px 16px 2px rgba(0, 119, 106, 0.1), 0px 16px 32px 2px rgba(0, 119, 106, 0.1)',
      _24dp: '0px 12px 24px 6px rgba(0, 119, 106, 0.08), 0px 24px 48px 6px rgba(0, 119, 106, 0.08)',
      _32dp: '0px 24px 48px 10px rgba(0, 119, 106, 0.06), 0px 32px 64px 10px rgba(0, 119, 106, 0.06)',
    },
    red: {
      _2dp: '0px 1px 2px rgba(212, 54, 54, 0.2), 0px 2px 4px rgba(212, 54, 54, 0.2)',
      _4dp: '0px 2px 4px rgba(212, 54, 54, 0.18), 0px 4px 8px rgba(212, 54, 54, 0.18)',
      _8dp: '0px 4px 8px rgba(212, 54, 54, 0.14), 0px 8px 16px rgba(212, 54, 54, 0.14)',
      _16dp: '0px 8px 16px 2px rgba(212, 54, 54, 0.1), 0px 16px 32px 2px rgba(212, 54, 54, 0.1)',
      _24dp: '0px 12px 24px 6px rgba(212, 54, 54, 0.08), 0px 24px 48px 6px rgba(212, 54, 54, 0.08)',
      _32dp: '0px 24px 48px 10px rgba(212, 54, 54, 0.06), 0px 32px 64px 10px rgba(212, 54, 54, 0.06)',
    },
    orange: {
      _2dp: '0px 1px 2px rgba(191, 113, 15, 0.2), 0px 2px 4px rgba(191, 113, 15, 0.2)',
      _4dp: '0px 2px 4px rgba(191, 113, 15, 0.18), 0px 4px 8px rgba(191, 113, 15, 0.18)',
      _8dp: '0px 4px 8px rgba(191, 113, 15, 0.14), 0px 8px 16px rgba(191, 113, 15, 0.14)',
      _16dp: '0px 8px 16px 2px rgba(191, 113, 15, 0.1), 0px 16px 32px 2px rgba(191, 113, 15, 0.1)',
      _24dp: '0px 12px 24px 6px rgba(191, 113, 15, 0.08), 0px 24px 48px 6px rgba(191, 113, 15, 0.08)',
      _32dp: '0px 24px 48px 10px rgba(191, 113, 15, 0.06), 0px 32px 64px 10px rgba(191, 113, 15, 0.06)',
    },
    green: {
      _2dp: '0px 1px 2px rgba(0, 135, 90, 0.2), 0px 2px 4px rgba(0, 135, 90, 0.2)',
      _4dp: '0px 2px 4px rgba(0, 135, 90, 0.18), 0px 4px 8px rgba(0, 135, 90, 0.18)',
      _8dp: '0px 4px 8px rgba(0, 135, 90, 0.14), 0px 8px 16px rgba(0, 135, 90, 0.14)',
      _16dp: '0px 8px 16px 2px rgba(0, 135, 90, 0.1), 0px 16px 32px 2px rgba(0, 135, 90, 0.1)',
      _24dp: '0px 12px 24px 6px rgba(0, 135, 90, 0.08), 0px 24px 48px 6px rgba(0, 135, 90, 0.08)',
      _32dp: '0px 24px 48px 10px rgba(0, 135, 90, 0.06), 0px 32px 64px 10px rgba(0, 135, 90, 0.06)',
    },
    blue: {
      _2dp: '0px 1px 2px rgba(15, 86, 179, 0.2), 0px 2px 4px rgba(15, 86, 179, 0.2)',
      _4dp: '0px 2px 4px rgba(15, 86, 179, 0.18), 0px 4px 8px rgba(15, 86, 179, 0.18)',
      _8dp: '0px 4px 8px rgba(15, 86, 179, 0.14), 0px 8px 16px rgba(15, 86, 179, 0.14)',
      _16dp: '0px 8px 16px 2px rgba(15, 86, 179, 0.1), 0px 16px 32px 2px rgba(15, 86, 179, 0.1)',
      _24dp: '0px 12px 24px 6px rgba(15, 86, 179, 0.08), 0px 24px 48px 6px rgba(15, 86, 179, 0.08)',
      _32dp: '0px 24px 48px 10px rgba(15, 86, 179, 0.06), 0px 32px 64px 10px rgba(15, 86, 179, 0.06)',
    },
  },
  gaps: {
    _2px: 2,
    _4px: 4,
    _8px: 8,
    _10px: 10,
    _12px: 12,
    _16px: 16,
    _20px: 20,
    _24px: 24,
    _32px: 32,
    _40px: 40,
    _48px: 48,
    _56px: 56,
    _64px: 64,
  },
});

export { mainTheme };
