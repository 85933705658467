import React from 'react';
import { StyledDialog } from './styled';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const ActionModal: React.FC<Props> = ({ isOpen, onClose, children }) => {
  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      {children}
    </StyledDialog>
  );
};
