import { styled, css, lighten } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { pxToRem } from 'utils/styling-utils';

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: 'none',
  borderRadius: pxToRem(theme.gaps._8px),
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-root': {
    border: 'none',
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    paddingInline: pxToRem(theme.gaps._20px),
    paddingBlock: pxToRem(theme.gaps._20px / 2),
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    ...theme.typography.bodySmallBold,
    color: theme.palette.grey['600'],
  },
  '& .MuiDataGrid-cell': {
    paddingInline: pxToRem(theme.gaps._20px),
    paddingBlock: pxToRem(theme.gaps._20px),
    ...theme.typography.bodyDefaultBook,
  },
  '& .MuiDataGrid-cell: focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader: focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-row: hover': {
    cursor: 'pointer',
    backgroundColor: lighten(theme.palette.primary.main, 0.95),
  },
  '& .MuiTablePagination-root .MuiTablePagination-displayedRows ': {
    ...theme.typography.bodyDefaultBook,
    marginBlock: pxToRem(theme.gaps._20px),
  },
  '& .MuiDataGrid-columnHeaders, .MuiDataGrid-cell, .MuiDataGrid-footerContainer': {
    borderColor: `${theme.palette.grey[200]}`,
  },
}));

export const StyledContainer = styled('div')(
  ({ theme }) => css`
    width: 100%;
    box-shadow: ${theme.customShadows.grey._2dp};
    border-radius: ${pxToRem(theme.gaps._8px)};
  `,
);
