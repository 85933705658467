import { styled, Link, linkClasses, Box } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';

export const StyledContainer = styled(Box)(({ theme }) => ({
  padding: `${pxToRem(theme.gaps._24px)} ${pxToRem(theme.gaps._32px)} 0`,
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  borderRadius: pxToRem(theme.gaps._4px),
  color: theme.palette.secondary.main,
  textDecorationColor: theme.palette.secondary.main,
  ':hover': {
    color: theme.palette.secondary.dark,
    textDecorationColor: theme.palette.secondary.dark,
  },
  ':visited': {
    color: theme.palette.secondary.main,
    textDecorationColor: theme.palette.secondary.main,
  },
  [`&.${linkClasses.focusVisible}`]: {
    color: theme.palette.secondary.dark,
    textDecorationColor: theme.palette.secondary.dark,
    outline: `1px solid ${theme.palette.secondary.dark}`,
  },
}));
