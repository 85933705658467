import React from 'react';
import { Box } from '@mui/material';
import { InlineBanner } from 'elements/components';
import { CustodialAccount, IdentityData, IdentityLevel, IdentityType } from 'elements/types';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { EmptyState } from './EmptyState';
import { StyledForwardIcon } from './styled';
import { getVerificationBannerData, getVerificationData } from './utils/getVerificationData';

type Props = {
  identityData: IdentityData;
  custodialAccount: CustodialAccount | null;
  requiredLevel?: IdentityLevel;
  title?: string;
  icon?: React.ReactNode;
  description?: string;
  contactUsHref?: string;
  onVerify?: () => void;
  isServiceUnavailable: boolean;
  path: string;
};

const IdentityCheck: React.FC<Props> = ({
  identityData,
  custodialAccount,
  requiredLevel = IdentityLevel.L1,
  title,
  icon,
  description,
  contactUsHref,
  children,
  onVerify,
  isServiceUnavailable,
  path,
}) => {
  const { isInactiveAccount, isNotVerified, isNotL2, isNotIraL1 } = getVerificationData(
    identityData,
    custodialAccount,
    requiredLevel,
  );

  if (
    isInactiveAccount ||
    isNotVerified ||
    (isNotL2 && (isNotIraL1 || path === Paths.WithdrawalCryptoAmount)) ||
    isServiceUnavailable
  ) {
    const bannerData = getVerificationBannerData(
      identityData.type,
      isInactiveAccount,
      isNotVerified,
      isNotL2,
      isServiceUnavailable,
    );

    const withVerify = identityData.type === IdentityType.Personal && !isInactiveAccount && (isNotVerified || isNotL2);

    return (
      <Box height="100%">
        <InlineBanner
          withIcon={!bannerData.withRightIcon}
          type={bannerData.type}
          text={bannerData.text}
          text2={bannerData.text2}
          buttonTitle={bannerData.buttonTitle}
          linkHref={!withVerify ? contactUsHref : ''}
          onClick={withVerify ? onVerify : undefined}
        >
          {bannerData.withRightIcon && <StyledForwardIcon onClick={withVerify ? onVerify : undefined} />}
        </InlineBanner>
        <EmptyState title={title} description={description} icon={icon} />
      </Box>
    );
  }

  return <Box>{children}</Box>;
};

export { IdentityCheck };
