import React from 'react';

import { PdfRowIdentifiable } from 'ui-interfaces/transaction-details/pdf/pdf-row-identifiable';
import { PdfRowType } from 'ui-enums/pdf/pdf-row-type';

// rows interfaces - start
import { IPlainRow } from 'ui-interfaces/transaction-details/pdf/rows/i-plain-row';
import { IPdfLinkRow } from 'ui-interfaces/transaction-details/pdf/rows/i-pdf-link-row';
// MT103 - start
import { IPdfMt103Row } from 'ui-interfaces/transaction-details/pdf/rows/mt-103/i-pdf-mt103-row';
import { IPdfMt103NoValueRow } from 'ui-interfaces/transaction-details/pdf/rows/mt-103/i-pdf-mt103-no-value-row';
import { IPdfMt103Indent1Row } from 'ui-interfaces/transaction-details/pdf/rows/mt-103/i-pdf-mt103-indent-1-row';
import { IPdfMt103Indent1NoValueRow } from 'ui-interfaces/transaction-details/pdf/rows/mt-103/i-pdf-mt103-indent-1-no-value-row';
import { IPdfMt103Indent2Row } from 'ui-interfaces/transaction-details/pdf/rows/mt-103/i-pdf-mt103-indent-2-row';
import { IPdfNoLabelRow } from 'ui-interfaces/transaction-details/pdf/rows/i-pdf-no-label-row';
// MT103 - end
// rows interfaces - end

// rows components - start
import { PlainRow } from './rows/PlainRow';
import { PdfLinkRow } from './rows/PdfLinkRow';
// MT103 - start
import { PdfMT103Row } from './rows/mt-103/PdfMT103Row';
import { PdfMT103NoValueRow } from './rows/mt-103/PdfMT103NoValueRow';
import { PdfMT103Indent1Row } from './rows/mt-103/PdfMT103Indent1Row';
import { PdfMT103Indent1NoValueRow } from './rows/mt-103/PdfMT103Indent1NoValueRow';
import { PdfMT103Indent2Row } from './rows/mt-103/PdfMT103Indent2Row';
import { PdfNoLabelRow } from './rows/PdfNoLabelRow';
// MT103 - end
// rows components - end

export const createPdfRow = (item: PdfRowIdentifiable): React.ReactElement => {
  switch (item.rowType) {
    case PdfRowType.plain: {
      const i = item as IPlainRow;
      return <PlainRow key={i.id} data={{ ...i }} />;
    }

    case PdfRowType.link: {
      const i = item as IPdfLinkRow;
      return <PdfLinkRow key={i.id} data={{ ...i }} />;
    }

    case PdfRowType.noLabelRow: {
      const i = item as IPdfNoLabelRow;
      return <PdfNoLabelRow key={i.id} data={{ ...i }} />;
    }

    // MT103 - start
    case PdfRowType.mt103: {
      const i = item as IPdfMt103Row;
      return <PdfMT103Row key={i.id} data={{ ...i }} />;
    }

    case PdfRowType.mt103NoValue: {
      const i = item as IPdfMt103NoValueRow;
      return <PdfMT103NoValueRow key={i.id} data={{ ...i }} />;
    }

    case PdfRowType.mt103Indent1: {
      const i = item as IPdfMt103Indent1Row;
      return <PdfMT103Indent1Row key={i.id} data={{ ...i }} />;
    }

    case PdfRowType.mt103Indent1NoValue: {
      const i = item as IPdfMt103Indent1NoValueRow;
      return <PdfMT103Indent1NoValueRow key={i.id} data={{ ...i }} />;
    }

    case PdfRowType.mt103Indent2: {
      const i = item as IPdfMt103Indent2Row;
      return <PdfMT103Indent2Row key={i.id} data={{ ...i }} />;
    }
    // MT103 - end

    default: {
      const i = item as IPlainRow;
      return <PlainRow key={i.id} data={{ ...i }} />;
    }
  }
};
