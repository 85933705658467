import React from 'react';
import { useParams } from 'react-router-dom';
import { UserDetailsUrlParams } from 'navigation/url-params-type';
import { IAction } from 'ui-interfaces/user-details/actions/i-action';
import { createActionComponent } from './utils/create-action-component';
import { StyledBox } from './styled';

type Props = {
  actions: IAction[];
};

export const Actions: React.FC<Props> = ({ actions }) => {
  const { userId } = useParams<UserDetailsUrlParams>();

  return <StyledBox>{actions.map(action => createActionComponent(action, userId!))}</StyledBox>;
};
