import { FormikProps, FormikValues } from 'formik';
import { GeneralFormikPopsType } from './types';

export const getFormikPropsWithOnChangeStringParameter =
  <Values extends FormikValues>(formik: FormikProps<Values>) =>
  (
    formikProps: GeneralFormikPopsType<Values>,
  ): Omit<GeneralFormikPopsType<Values>, 'onChange'> & {
    onChange: (data: Values[typeof formikProps.name]) => void;
  } => {
    return {
      ...formikProps,
      onChange: data => formik.setFieldValue(formikProps.name, data),
    };
  };
