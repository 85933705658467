import i18n from 'localizations';
import { TransactionBaseViewModel } from 'view-models/transactions/list/transaction-base-vm';
import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { CompoundLabel, TransactionsRow } from 'ui-interfaces/row-interfaces/transactions';
import { get4LastChars } from 'utils/get-4-last-chars';
import { getAmountLabelPreciousMetalSell } from 'utils/transactions/list/precious-metal/get-amount-label-precious-metal-sell';
import { getCryptoTooltipMessage } from 'utils/transactions/get-crypto-tooltip-message';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';

export class TransactionPreciousMetalSellViewModel extends TransactionBaseViewModel implements TransactionsRow {
  readonly source: CompoundLabel;

  readonly destination: CompoundLabel;

  readonly statusTooltipMessage?: string;

  constructor(transaction: TransactionResponseModel) {
    super(transaction);
    this.source = {
      start: `*${get4LastChars(transaction.sourceDisplayValue ?? '')}`,
      end: i18n.t('transactionsPage.account'),
    } as CompoundLabel;
    this.destination = {
      start: `*${get4LastChars(transaction.destinationDisplayValue ?? '')}`,
      end: i18n.t('transactionsPage.account'),
    } as CompoundLabel;
    this.amount = getAmountLabelPreciousMetalSell(transaction);
    this.statusTooltipMessage = getCryptoTooltipMessage(transaction.status as TransactionStatus);
  }
}
