import { WireDepositInstructionsResponse } from 'elements/element-transfer/types';
import { COUNTRY_CODE_US } from 'elements/constants';
import { USStateValue } from 'elements/types';
import { getStateNameByValue } from 'elements/utils';

const getStateName = (isUS: boolean, value?: string) =>
  isUS ? getStateNameByValue(value as USStateValue | undefined) : value;

const convertWireInstructionsResponse = (response: WireDepositInstructionsResponse) => ({
  accountNumber: response.accountNumber ?? '',
  routingNumber: response.routingNumber ?? '',
  accountHolder: {
    name: response?.receiverName ?? '',
    state: getStateName(response.receiverAddress?.country === COUNTRY_CODE_US, response.receiverAddress?.state) ?? '',
    postalCode: response.receiverAddress?.postalCode ?? '',
    city: response.receiverAddress?.city ?? '',
    street1: response.receiverAddress?.street1 ?? '',
    street2: response.receiverAddress?.street2,
    country: response.receiverAddress?.country,
  },
  bank: {
    name: response?.receiverBankName ?? '',
    state:
      getStateName(response.receiverBankAddress?.country === COUNTRY_CODE_US, response.receiverBankAddress?.state) ??
      '',
    postalCode: response.receiverBankAddress?.postalCode ?? '',
    city: response.receiverBankAddress?.city ?? '',
    street1: response.receiverBankAddress?.street1 ?? '',
    street2: response.receiverBankAddress?.street2,
    country: response.receiverBankAddress?.country,
  },
  swiftCode: response.swiftCode ?? '',
});

export default convertWireInstructionsResponse;
