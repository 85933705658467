import i18n from 'localizations';
import { FilterValueSet } from 'filters/interfaces/filter-value-set';
import { PaymentType } from 'ui-enums/response/transactions/payment-type';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';
import { GenericValueSets } from 'filters/common-value-sets/base/generic-value-sets';

export class TransactionsValueSets extends GenericValueSets {
  static readonly paymentTypeValueSet: FilterValueSet[] = [
    {
      value: PaymentType.buyCrypto,
      displayValue: i18n.t('filters.buy'),
    },
    {
      value: PaymentType.sellCrypto,
      displayValue: i18n.t('filters.sell'),
    },
    {
      value: PaymentType.internalCrypto,
      displayValue: i18n.t('filters.internalTransferCrypto'),
    },
    {
      value: PaymentType.privateSecurityInternal,
      displayValue: i18n.t('filters.internalPrivateSecurity'),
    },
    {
      value: PaymentType.internal,
      displayValue: i18n.t('filters.internalTransfer'),
    },
    { value: PaymentType.depositCrypto, displayValue: i18n.t('filters.depositCrypto') },
    {
      value: PaymentType.privateSecurityDeposit,
      displayValue: i18n.t('filters.depositPrivateSecurity'),
    },
    {
      value: `${PaymentType.achDeposit},${PaymentType.incomingAchDeposit}`,
      displayValue: i18n.t('filters.depositAch'),
    },
    {
      value: PaymentType.returnAchDeposit,
      displayValue: i18n.t('filters.returnAchDeposit'),
    },
    {
      value: PaymentType.achDepositReverse,
      displayValue: i18n.t('filters.depositAchReverse'),
    },
    {
      value: PaymentType.wireDeposit,
      displayValue: i18n.t('filters.depositWire'),
    },
    {
      value: PaymentType.wireDepositReturn,
      displayValue: i18n.t('filters.returnDepositWire'),
    },
    {
      value: PaymentType.reversalWireDeposit,
      displayValue: i18n.t('filters.reverseDepositWire'),
    },
    {
      value: PaymentType.checkDeposit,
      displayValue: i18n.t('filters.depositCheck'),
    },
    {
      value: PaymentType.initialDeposit,
      displayValue: i18n.t('filters.initialDeposit'),
    },
    {
      value: PaymentType.withdrawalCrypto,
      displayValue: i18n.t('filters.withdrawalCrypto'),
    },
    {
      value: PaymentType.privateSecurityWithdrawal,
      displayValue: i18n.t('filters.withdrawalPrivateSecurity'),
    },
    {
      value: `${PaymentType.achWithdrawal},${PaymentType.incomingAchWithdrawal}`,
      displayValue: i18n.t('filters.withdrawalAch'),
    },
    {
      value: PaymentType.returnAchWithdrawal,
      displayValue: i18n.t('filters.withdrawalAchReturn'),
    },
    {
      value: PaymentType.achWithdrawalReverse,
      displayValue: i18n.t('filters.withdrawalAchReverse'),
    },
    {
      value: PaymentType.wireWithdrawal,
      displayValue: i18n.t('filters.withdrawalWire'),
    },
    {
      value: PaymentType.wireWithdrawalReturn,
      displayValue: i18n.t('filters.returnWithdrawalWire'),
    },
    {
      value: PaymentType.reversalWireWithdrawal,
      displayValue: i18n.t('filters.reverseWithdrawalDomesticWire'),
    },
    {
      value: PaymentType.internationalWireWithdrawal,
      displayValue: i18n.t('filters.internationalWithdrawalWire'),
    },
    {
      value: PaymentType.buyPreciousMetal,
      displayValue: i18n.t('filters.buyPreciousMetal'),
    },
    {
      value: PaymentType.sellPreciousMetal,
      displayValue: i18n.t('filters.sellPreciousMetal'),
    },
    {
      value: PaymentType.manuallyProcessed,
      displayValue: i18n.t('filters.manuallyProcessed'),
    },
  ];

  static get paymentTypeValueSetWithPrivateSecurityRemoved(): FilterValueSet[] {
    return TransactionsValueSets.paymentTypeValueSet.filter(
      el =>
        ![
          PaymentType.privateSecurityInternal,
          PaymentType.privateSecurityDeposit,
          PaymentType.privateSecurityWithdrawal,
        ].includes(el.value as PaymentType),
    );
  }

  static get paymentTypeValueSetWithPrivateSecurityOnly(): FilterValueSet[] {
    return TransactionsValueSets.paymentTypeValueSet.filter(el =>
      [
        PaymentType.privateSecurityInternal,
        PaymentType.privateSecurityDeposit,
        PaymentType.privateSecurityWithdrawal,
      ].includes(el.value as PaymentType),
    );
  }

  static readonly transactionStatusValueSet: FilterValueSet[] = [
    // {
    //   value: TransactionStatus.approvalNeeded,
    //   displayValue: i18n.t('transactionsPage.grid.status.approvalNeeded'),
    // },
    // {
    //   value: TransactionStatus.rejected,
    //   displayValue: i18n.t('transactionsPage.grid.status.rejected'),
    // },
    {
      value: TransactionStatus.completed,
      displayValue: i18n.t('transactionsPage.grid.status.success'),
    },
    {
      value: `${TransactionStatus.new},${TransactionStatus.pending}`,
      displayValue: i18n.t('transactionsPage.grid.status.pending'),
    },
    {
      value: `${TransactionStatus.processing},${TransactionStatus.inProgress}`,
      displayValue: i18n.t('transactionsPage.grid.status.processing'),
    },
    {
      value: TransactionStatus.cancelled,
      displayValue: i18n.t('transactionsPage.grid.status.canceled'),
    },
    {
      value: `${TransactionStatus.failed},${TransactionStatus.aborted}`,
      displayValue: i18n.t('transactionsPage.grid.status.fail'),
    },
  ];

  static get transactionStatusValueSetPrivateSecurity(): FilterValueSet[] {
    return TransactionsValueSets.transactionStatusValueSet.filter(el => !(el.value === TransactionStatus.pending));
  }
}
