import i18n from 'localizations';
import { IdentityType } from 'models/enums/identities/identity-type';

export const getIdentityTypeLabel = (type: IdentityType): string => {
  switch (type) {
    case IdentityType.personal:
      return i18n.t('identityDetailsPage.types.personal');
    case IdentityType.business:
      return i18n.t('identityDetailsPage.types.business');
    default:
      return i18n.t('identityDetailsPage.types.personal');
  }
};
