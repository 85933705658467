import { useEffect, useState } from 'react';
import { PathMatch, useMatch, useLocation } from 'react-router-dom';
import i18n from 'localizations';
import { BreadCrumbsItem } from 'components/shared/Breadcrumbs';
import { NESTED_PATHS, PATHS } from 'navigation/constants';
import {
  BeneficialOwnerDetailsUnderIdentityDetailsUrlParams,
  IdentityDetailsUnderCustodialAccountDetails,
  BeneficiaryDetailsUnderCustodialAccountDetailsUnderIdentityDetailsUrlParams,
  CustodialAccountDetailsUnderIdentityDetailsUrlParams,
  CustodialAccountsDetailsUrlParams,
  IdentityDetailsUrlParams,
  AccountOwnerDetailsUnderCustodialAccountDetailsUrlParams,
  AccountOwnerDetailsUnderCustodialAccountDetailsUnderIdentityDetailsUrlParams,
} from 'navigation/url-params-type';
import { getRouteWithParams } from 'utils/get-route-with-params';

export const useBcIdentityDetailsPage = () => {
  const location = useLocation();

  const MATCHES_IDENTITY_DETAILS = useMatch({
    path: PATHS.IDENTITY_DETAILS,
    end: true,
  });

  const MATCHES_IDENTITY_DETAILS__BENEFICIAL_OWNER_DETAILS = useMatch({
    path: NESTED_PATHS.IDENTITY_DETAILS__BENEFICIAL_OWNER_DETAILS,
    end: true,
  });

  const MATCHES_CUSTODIAL_ACCOUNTS_DETAILS__IDENTITY_DETAILS = useMatch({
    path: NESTED_PATHS.CUSTODIAL_ACCOUNTS_DETAILS__IDENTITY_DETAILS,
    end: true,
  });

  const MATCHES_IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__BENEFICIAL_OWNER_DETAILS = useMatch({
    path: NESTED_PATHS.IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__BENEFICIARY_DETAILS,
    end: true,
  });

  // account owner - start
  const MATCHES_CUSTODIAL_ACCOUNTS_DETAILS__ACCOUNT_OWNER_DETAILS = useMatch({
    path: NESTED_PATHS.CUSTODIAL_ACCOUNTS_DETAILS__ACCOUNT_OWNER_DETAILS,
    end: true,
  });

  const MATCHES_IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__ACCOUNT_OWNER_DETAILS = useMatch({
    path: NESTED_PATHS.IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__ACCOUNT_OWNER_DETAILS,
    end: true,
  });
  // account owner - end

  const [breadCrumbs, setBreadCrumbs] = useState<BreadCrumbsItem[]>([]);
  const [pathMatch, setPathMatch] = useState<PathMatch | null>(null);

  useEffect(() => {
    if (MATCHES_IDENTITY_DETAILS) {
      setBreadCrumbs([
        {
          label: i18n.t('identityDetailsPage.breadcrumbs.identities'),
          path: PATHS.IDENTITIES,
        },
        {
          label: i18n.t('identityDetailsPage.breadcrumbs.identityDetails'),
        },
      ]);
      setPathMatch(MATCHES_IDENTITY_DETAILS);
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (MATCHES_IDENTITY_DETAILS__BENEFICIAL_OWNER_DETAILS) {
      const { identityId, identityType } =
        MATCHES_IDENTITY_DETAILS__BENEFICIAL_OWNER_DETAILS.params as BeneficialOwnerDetailsUnderIdentityDetailsUrlParams;

      setBreadCrumbs([
        {
          label: i18n.t('identityDetailsPage.breadcrumbs.identities'),
          path: PATHS.IDENTITIES,
        },
        {
          label: i18n.t('identityDetailsPage.breadcrumbs.identityDetails'),
          path: getRouteWithParams(PATHS.IDENTITY_DETAILS, {
            identityId,
            identityType,
          } as IdentityDetailsUrlParams),
        },
        {
          label: i18n.t('identityDetailsPage.breadcrumbs.beneficialOwnerDetails'),
        },
      ]);
      setPathMatch(MATCHES_IDENTITY_DETAILS__BENEFICIAL_OWNER_DETAILS);
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (MATCHES_CUSTODIAL_ACCOUNTS_DETAILS__IDENTITY_DETAILS) {
      const { custodialAccountId } =
        MATCHES_CUSTODIAL_ACCOUNTS_DETAILS__IDENTITY_DETAILS.params as IdentityDetailsUnderCustodialAccountDetails;

      setBreadCrumbs([
        {
          label: i18n.t('custodialAccountsPage.breadcrumbs.accounts'),
          path: PATHS.TAPI.CUSTODIAL_ACCOUNTS,
        },
        {
          label: i18n.t('custodialAccountsPage.breadcrumbs.account'),
          path: getRouteWithParams(PATHS.TAPI.CUSTODIAL_ACCOUNTS_DETAILS, {
            custodialAccountId,
          } as CustodialAccountsDetailsUrlParams),
        },
        {
          label: i18n.t('identityDetailsPage.breadcrumbs.beneficiaryDetails'),
        },
      ]);
      setPathMatch(MATCHES_CUSTODIAL_ACCOUNTS_DETAILS__IDENTITY_DETAILS);
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (MATCHES_IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__BENEFICIAL_OWNER_DETAILS) {
      const { identityId, identityType, custodialAccountId, parentIdentityId, parentIdentityType } =
        MATCHES_IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__BENEFICIAL_OWNER_DETAILS.params as BeneficiaryDetailsUnderCustodialAccountDetailsUnderIdentityDetailsUrlParams;

      setBreadCrumbs([
        {
          label: i18n.t('identityDetailsPage.breadcrumbs.identities'),
          path: PATHS.IDENTITIES,
        },
        {
          label: i18n.t('identityDetailsPage.breadcrumbs.identityDetails'),
          path: getRouteWithParams(PATHS.IDENTITY_DETAILS, {
            identityId: parentIdentityId,
            identityType: parentIdentityType,
          } as IdentityDetailsUrlParams),
        },
        {
          label: i18n.t('custodialAccountsPage.breadcrumbs.account'),
          path: getRouteWithParams(NESTED_PATHS.IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS, {
            custodialAccountId,
            identityId,
            identityType,
          } as CustodialAccountDetailsUnderIdentityDetailsUrlParams),
        },
        {
          label: i18n.t('identityDetailsPage.breadcrumbs.beneficiaryDetails'),
        },
      ]);
      setPathMatch(MATCHES_IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__BENEFICIAL_OWNER_DETAILS);
    }
    // eslint-disable-next-line
  }, [location]);

  // account owner - start
  useEffect(() => {
    if (MATCHES_CUSTODIAL_ACCOUNTS_DETAILS__ACCOUNT_OWNER_DETAILS) {
      const { custodialAccountId } =
        MATCHES_CUSTODIAL_ACCOUNTS_DETAILS__ACCOUNT_OWNER_DETAILS.params as AccountOwnerDetailsUnderCustodialAccountDetailsUrlParams;

      setBreadCrumbs([
        {
          label: i18n.t('custodialAccountsPage.breadcrumbs.accounts'),
          path: PATHS.TAPI.CUSTODIAL_ACCOUNTS,
        },
        {
          label: i18n.t('custodialAccountsPage.breadcrumbs.account'),
          path: getRouteWithParams(PATHS.TAPI.CUSTODIAL_ACCOUNTS_DETAILS, {
            custodialAccountId,
          } as CustodialAccountsDetailsUrlParams),
        },
        {
          label: i18n.t('identityDetailsPage.breadcrumbs.accountOwnerDetails'),
        },
      ]);
      setPathMatch(MATCHES_CUSTODIAL_ACCOUNTS_DETAILS__IDENTITY_DETAILS);
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (MATCHES_IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__ACCOUNT_OWNER_DETAILS) {
      const { identityId, identityType, custodialAccountId, parentIdentityId, parentIdentityType } =
        MATCHES_IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__ACCOUNT_OWNER_DETAILS.params as AccountOwnerDetailsUnderCustodialAccountDetailsUnderIdentityDetailsUrlParams;

      setBreadCrumbs([
        {
          label: i18n.t('identityDetailsPage.breadcrumbs.identities'),
          path: PATHS.IDENTITIES,
        },
        {
          label: i18n.t('identityDetailsPage.breadcrumbs.identityDetails'),
          path: getRouteWithParams(PATHS.IDENTITY_DETAILS, {
            identityId,
            identityType,
          } as IdentityDetailsUrlParams),
        },
        {
          label: i18n.t('custodialAccountsPage.breadcrumbs.account'),
          path: getRouteWithParams(NESTED_PATHS.IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS, {
            custodialAccountId,
            identityId: parentIdentityId,
            identityType: parentIdentityType,
          } as CustodialAccountDetailsUnderIdentityDetailsUrlParams),
        },
        {
          label: i18n.t('identityDetailsPage.breadcrumbs.accountOwnerDetails'),
        },
      ]);
      setPathMatch(MATCHES_IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__BENEFICIAL_OWNER_DETAILS);
    }
    // eslint-disable-next-line
  }, [location]);
  // account owner - end

  return { breadCrumbs, pathMatch };
};
