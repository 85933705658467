type TypeFromTuple<T> = T extends readonly [infer K, ...infer R] ? K | TypeFromTuple<R> : never;

const fullPaymentMethodTypeList = ['ach', 'wire', 'card', 'crypto', 'balance'] as const;

export type PaymentMethodTypesMap = {
  [key in TypeFromTuple<typeof fullPaymentMethodTypeList>]?: boolean;
};

const getPaymentMethodTypesMap = (param?: string): PaymentMethodTypesMap => {
  if (param && param.toLowerCase() !== 'all') {
    const paymentMethodTypeListFromParam = param.toLowerCase().split('+');
    const paymentMethodTypeList = fullPaymentMethodTypeList.filter(item =>
      paymentMethodTypeListFromParam.includes(item),
    );
    if (paymentMethodTypeList.length) {
      return paymentMethodTypeList.reduce<PaymentMethodTypesMap>((acc, el) => {
        acc[el] = true;
        return acc;
      }, {});
    }
  }

  return fullPaymentMethodTypeList.reduce<PaymentMethodTypesMap>((acc, el) => {
    acc[el] = true;
    return acc;
  }, {});
};

export { getPaymentMethodTypesMap };
