import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useCreateUserMutation, useLazyGetRoleTemplatesQuery } from 'redux/api/api-users';
import { usePreviousValue } from 'hooks/usePreviousValue';
import { IRoleTemplatesInputs } from 'ui-interfaces/user-form/i-role-templates-inputs';
import { createRoleTemplatesViewModel } from 'factories/create-user-form/create-role-templates-vm';
import { CreateUserRequestModel } from 'models/request/users/create-user-request-model';
import { CreateUserDialog } from './CreateUserDialog';

type ErrorDataType = { errors?: Record<string, string[]> };
type Props = {
  onClose: () => void;
  isOpen: boolean;
};

export const CreateUserDialogContainer: React.FC<Props> = ({ onClose, isOpen }) => {
  const [fetchRoleTemplates, fetchRoleTemplatesResult] = useLazyGetRoleTemplatesQuery();

  useEffect(() => {
    fetchRoleTemplates(undefined);
  }, [fetchRoleTemplates]);

  const [roleTemplatesInputs, setRoleTemplatesInputs] = useState<
    IRoleTemplatesInputs<Pick<CreateUserRequestModel, 'roleTemplate' | 'permissions' | 'limits'>> | undefined
  >();

  useEffect(() => {
    if (fetchRoleTemplatesResult.isSuccess && fetchRoleTemplatesResult.data) {
      setRoleTemplatesInputs(createRoleTemplatesViewModel(fetchRoleTemplatesResult.data.data));
    }
  }, [fetchRoleTemplatesResult.isSuccess, fetchRoleTemplatesResult.data]);

  const [createUser, { isLoading: isCreateUserLoading, error: createUserError, isSuccess: isCreateUserSuccess }] =
    useCreateUserMutation();

  const [createUserErrorMessage, setCreateUserErrorMessage] = useState('');
  const onUserCreate = (values: CreateUserRequestModel) => {
    createUser(values);
    setCreateUserErrorMessage('');
  };

  const prevIsCreateUserSuccess = usePreviousValue(isCreateUserSuccess);
  const isUserCreationSucceeded = !prevIsCreateUserSuccess && isCreateUserSuccess;
  useEffect(() => {
    if (isUserCreationSucceeded) {
      onClose();
    }
  }, [isUserCreationSucceeded, onClose]);
  useEffect(() => {
    if (createUserError) {
      const createUserErrorHasStatus = 'status' in createUserError;
      const createUserErrorHasData = 'data' in createUserError;
      if (createUserErrorHasStatus && createUserErrorHasData) {
        const fetchCreateUserError = createUserError as FetchBaseQueryError;
        const statusCode409 = 409;
        const conflictEmailErrorCode = '610e0c29-ff12-45bb-9e86-61856904fbb6';
        const createUserErrorData = fetchCreateUserError.data as ErrorDataType;
        const isStatusCode409 = fetchCreateUserError.status === statusCode409;
        const hasConflictEmailError = !!createUserErrorData?.errors?.[conflictEmailErrorCode];
        if (isStatusCode409 && hasConflictEmailError) {
          setCreateUserErrorMessage(t('createUserDialog.conflictEmailErrorMessage'));
        }
      }
    }
  }, [createUserError]);
  useEffect(() => {
    if (isOpen) {
      setCreateUserErrorMessage('');
    }
  }, [isOpen, setCreateUserErrorMessage]);

  return roleTemplatesInputs ? (
    <CreateUserDialog
      isOpen={isOpen}
      onClose={onClose}
      onUserCreate={onUserCreate}
      isLoading={isCreateUserLoading}
      errorMessage={createUserErrorMessage}
      roleTemplatesInputs={roleTemplatesInputs}
    />
  ) : null;
};
