import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';
import { StyledTabs } from 'components/shared/Tabs';

export const StyledHeaderBox = styled(Box)(({ theme }) => ({
  paddingRight: pxToRem(theme.gaps._32px),
  paddingLeft: pxToRem(theme.gaps._32px),
  paddingTop: pxToRem(theme.gaps._40px),
}));

export const StyledTabsBox = styled(StyledTabs)(() => ({
  paddingInline: 0,
}));
