import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { Box } from '@mui/material';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledContainer = elementsStyled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 12px;
  height: auto;
  gap: 12px;

  & svg {
    flex-shrink: 0;
  }
`;

export const StyledIconContainer = elementsStyled(Box)(
  ({ theme }) =>
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      background: ${theme.palette.tertiary.mainTransparent60};
      border-radius: 8px;
    `,
);

export const StyledStarIcon = elementsStyled(StarBorderOutlinedIcon)(
  ({ theme }) =>
    css`
      height: 22px;
      width: 22px;
      color: ${theme.palette.secondary.font};
    `,
);

export const StyledTextContainer = elementsStyled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 6px;
  width: 100%;
`;
