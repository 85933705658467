import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Gap } from 'components/shared/Gap';
import { AssetImage } from 'components/shared/Cells/AssetImage';

type Props = {
  url?: string;
  size: number;
  defaultAssetIcon: JSX.Element;
  title: string;
  shortTitle: string;
};

export const AssetCell: React.FC<Props> = ({ url, size, defaultAssetIcon, title, shortTitle }) => {
  return (
    <Box display="flex" alignItems="center">
      <AssetImage url={url} size={size} defaultAssetIcon={defaultAssetIcon} />
      <Gap size="_12px" isHorizontal />
      <Typography variant="bodyDefaultMedium" color="grey.700">
        {title}
      </Typography>
      <Gap size="_8px" isHorizontal />
      <Typography variant="bodyDefaultMedium" color="grey.500">
        {shortTitle}
      </Typography>
    </Box>
  );
};
