import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { CommonLayoutContainer, CommonLayoutContent, CommonLayoutHeader } from 'components/shared/layouts/CommonLayout';
import Breadcrumbs, { BreadCrumbsItems } from 'components/shared/Breadcrumbs';
import { Gap } from 'components/shared/Gap';
import { HeaderDetailsItem } from 'components/shared/HeaderDetailsItem';
import { ReactComponent as DateIcon } from 'assets/icons/identity-details/date.svg';
import { ShadowBox } from 'components/shared/detail-page/rows/shared/ShadowBox';
import { HeaderBox } from 'components/shared/detail-page/HeaderBox';
import { DetailNode } from 'components/settings/users/UserDetailsPageContent/DetailNode';
import { UserDetails } from 'ui-interfaces/user-details/user-details';
import { Actions } from 'components/settings/users/UserDetailsPageContent/layout/Actions';
import { Title } from './Title';
import { StyledTopRow } from './styled';

type Props = {
  breadCrumbs: BreadCrumbsItems;
  userDetails?: UserDetails;
};

export const Content: React.FC<Props> = ({ breadCrumbs, userDetails }) => {
  const { t } = useTranslation();

  if (!userDetails) {
    return null;
  }

  return (
    <CommonLayoutContainer>
      <CommonLayoutHeader>
        <HeaderBox>
          <StyledTopRow>
            <Breadcrumbs items={breadCrumbs} />
            <Actions actions={userDetails.headerDetails.actions} />
          </StyledTopRow>
          <Gap size="_20px" />
          <Title
            value={userDetails.headerDetails.title}
            tooltipMessage={userDetails.headerDetails.pendingTooltipMessage}
            isPending={userDetails.headerDetails.isPending}
          />
          <Gap size="_20px" />
          <HeaderDetailsItem
            value={userDetails.headerDetails.date}
            label={t('userDetailsPage.dateAdded')}
            icon={<DateIcon />}
            loading={false}
          />
        </HeaderBox>
      </CommonLayoutHeader>
      <CommonLayoutContent>
        <Gap size="_24px" />
        <ShadowBox>
          <Typography variant="h6">{t('userDetailsPage.title')}</Typography>
          <Gap size="_16px" />
          {userDetails.nodes.map((n, index, originalArray) => (
            <DetailNode key={n.id} userDetailsNode={n} hasBottomBorder={index !== originalArray.length - 1} />
          ))}
        </ShadowBox>
        <Gap size="_24px" />
      </CommonLayoutContent>
    </CommonLayoutContainer>
  );
};
