import i18n from 'localizations';
import { CustodialAccountType } from 'models/enums/custodial-accounts/custodial-account-type';

export const getCustodialAccountTypeLabel = (type: CustodialAccountType) => {
  switch (type) {
    case CustodialAccountType.personal:
      return i18n.t('custodialAccountsPage.type.personal');
    case CustodialAccountType.business:
      return i18n.t('custodialAccountsPage.type.business');
    case CustodialAccountType.rothIra:
      return i18n.t('custodialAccountsPage.type.rothIra');
    case CustodialAccountType.traditionalIra:
      return i18n.t('custodialAccountsPage.type.traditionalIra');
    case CustodialAccountType.sepIra:
      return i18n.t('custodialAccountsPage.type.sepIra');
    case CustodialAccountType.simpleIra:
      return i18n.t('custodialAccountsPage.type.simpleIra');
    default:
      return i18n.t('notImplemented');
  }
};
