import { IdentityStatusModel } from 'models/enums/identities/identity-status-model';

export const calculateIsSuspensionActionShown = (
  status: IdentityStatusModel,
  isSuspendedByIntegrator: boolean,
): boolean => {
  if (status === IdentityStatusModel.inactive) {
    return false;
  }

  return !(status === IdentityStatusModel.suspended && !isSuspendedByIntegrator);
};
