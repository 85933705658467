import { t } from 'i18next';
import { RoleTemplate } from 'models/enums/user/role-template';

export const getRoleTemplateRadioLabels = (roleTemplate: RoleTemplate): { title: string; description: string } => {
  switch (roleTemplate) {
    case RoleTemplate.viewer:
      return {
        title: t('createUserDialog.viewerOptionTitle'),
        description: t('createUserDialog.viewerOptionDescription'),
      };
    case RoleTemplate.editor:
      return {
        title: t('createUserDialog.editorOptionTitle'),
        description: t('createUserDialog.editorOptionDescription'),
      };
    case RoleTemplate.admin:
      return {
        title: t('createUserDialog.adminOptionTitle'),
        description: t('createUserDialog.adminOptionDescription'),
      };
    default:
      return {
        title: t('common.notImplemented'),
        description: t('common.notImplemented'),
      };
  }
};
