import { Typography, Divider, styled } from '@mui/material';
import { Row } from 'components/shared/Flex/Row';
import { pxToRem, pxToVw } from 'utils/styling-utils';

export const NameColumnLabel = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  paddingTop: pxToVw(theme.gaps._16px),
  paddingBottom: pxToVw(theme.gaps._16px),
  flex: 0.7,
}));

export const IdColumnLabel = styled(Row)(({ theme }) => ({
  alignItems: 'center',
  paddingTop: pxToVw(theme.gaps._16px),
  paddingBottom: pxToVw(theme.gaps._16px),
  flex: 0.7,
}));

export const PillColumnLabel = styled(Row)(({ theme }) => ({
  alignItems: 'center',
  paddingTop: pxToVw(theme.gaps._16px),
  paddingBottom: pxToVw(theme.gaps._16px),
  flex: 0.7,
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginInline: `-${pxToRem(theme.gaps._24px)}`,
}));
