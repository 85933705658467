import { FormControlLabel, Radio } from '@mui/material';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledFormControlLabel = elementsStyled(FormControlLabel)`
  display: flex;
  justify-content: space-between;
  margin: 4px 0;
  width: 100%;

  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;

export const StyledRadio = elementsStyled(Radio)(
  ({ theme }) =>
    css`
      color: ${theme.palette.tertiary.main};

      &.Mui-checked {
        color: ${theme.palette.secondary.main};
      }
    `,
);
