import { CompoundLabel, TransactionsRow } from 'ui-interfaces/row-interfaces/transactions';
import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { get4LastChars } from 'utils/get-4-last-chars';
import i18n from 'localizations';
import { TransactionBaseCryptoViewModel } from 'view-models/transactions/list/crypto/base/transaction-base-crypto-vm';

export class TransactionCryptoInternalViewModel extends TransactionBaseCryptoViewModel implements TransactionsRow {
  readonly source: CompoundLabel;

  readonly destination: CompoundLabel;

  constructor(transaction: TransactionResponseModel) {
    super(transaction);
    this.source = {
      start: `*${get4LastChars(transaction.sourceDisplayValue ?? '')}`,
      end: i18n.t('transactionsPage.account'),
    } as CompoundLabel;
    this.destination = {
      start: `*${get4LastChars(transaction.destinationDisplayValue ?? '')}`,
      end: i18n.t('transactionsPage.account'),
    } as CompoundLabel;
  }
}
