import React from 'react';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import i18n from 'localizations';

type Props = {
  onClick: () => void;
  isDisabled: boolean;
};

export const ApplyButton: React.FC<Props> = ({ onClick, isDisabled }) => {
  return (
    <Button variant="contained" disabled={isDisabled} onClick={onClick}>
      <Typography color={isDisabled ? 'grey.500' : 'background.paper'} variant="buttonMedium">
        {i18n.t('filters.applyButtonLabel')}
      </Typography>
    </Button>
  );
};
