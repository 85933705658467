import React, { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Row } from 'components/shared/Flex/Row';
import { Gap } from 'components/shared/Gap';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import { AvatarCellProps } from 'ui-interfaces/users/avatar-cell-props';
import { TypographyVariant } from 'styles/themes/types';
import { isOverflown } from 'utils/helpers';
import { pxToRem } from 'utils/styling-utils';
import { StyledTypography, StyledColumn, StyledAvatar } from './styled';

const EXTRA_NEGATIVE_WIDTH = 12 + 36 + 20; //  36 Avatar, 12 padding from avatar, 20 right padding from next cell
interface Props extends AvatarCellProps {
  width: number;
  fullNameVariant?: TypographyVariant;
  color?: string;
  isActive: boolean;
}

export const AvatarCell: React.FC<Props> = ({
  width,
  fullNameVariant,
  color,
  value,
  avatarAbbreviation,
  isActive,
  bgColor,
}) => {
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState(false);
  const valueRef = useRef(null);

  useLayoutEffect(() => {
    const isValueCropped = isOverflown(valueRef.current!);
    setShowTooltip(isValueCropped);
  }, []);

  return (
    <Row>
      <StyledAvatar sx={{ bgcolor: bgColor }}>
        <Typography variant="bodySmallMedium" color="background.paper">
          {avatarAbbreviation}
        </Typography>
      </StyledAvatar>
      <Gap size="_12px" isHorizontal />
      <StyledColumn>
        <CommonTooltip
          title={value}
          placement="top"
          disableHoverListener={!showTooltip}
          disableFocusListener={!showTooltip}
          disableTouchListener={!showTooltip}
        >
          <StyledTypography
            sx={{
              maxWidth: pxToRem(width - EXTRA_NEGATIVE_WIDTH),
            }}
            variant={fullNameVariant}
            color={color}
            ref={valueRef}
          >
            {value}
          </StyledTypography>
        </CommonTooltip>
        {!isActive && (
          <Typography variant="bodySmallItalic" color="grey.600">
            {t('settingsPage.grid.pendingInvitation')}
          </Typography>
        )}
      </StyledColumn>
    </Row>
  );
};
