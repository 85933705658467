import React from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { InputBaseComponentProps } from '@mui/material';

export type NumberInputProps = InputBaseComponentProps & {
  prefix?: string;
  decimalScale?: number;
  onChange?: (event: { target: { name: string; value: string } }) => void;
  allowNegative?: boolean;
  defaultValue?: string;
};

export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  ({ prefix, decimalScale = 2, onChange, allowNegative = false, name, ...restProps }, ref) => {
    const onValueChange = (values: NumberFormatValues) => {
      if (onChange) {
        onChange({ target: { value: values.value, name } });
      }
    };

    return (
      <NumericFormat
        {...restProps}
        id={name}
        inputMode="decimal"
        getInputRef={ref}
        allowNegative={allowNegative}
        decimalScale={decimalScale}
        thousandsGroupStyle="thousand"
        thousandSeparator=","
        prefix={prefix}
        onValueChange={onValueChange}
        valueIsNumericString
      />
    );
  },
);
