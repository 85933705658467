import React from 'react';
import { Box, Typography } from '@mui/material';
import { Row } from 'components/shared/Flex/Row';
import { Gap } from 'components/shared/Gap';

type Props = {
  title: string;
  description: string;
};

export const RadioLabel: React.FC<Props> = ({ title, description }) => {
  return (
    <Box>
      <Row>
        <Typography variant="bodyDefaultMedium">{title}</Typography>
      </Row>
      <Gap size="_2px" />
      <Row>
        <Typography variant="captionMedium" color="grey.600">
          {description}
        </Typography>
      </Row>
    </Box>
  );
};
