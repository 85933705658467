import React from 'react';
import { Typography } from '@mui/material';
import { StyledRow } from 'components/shared/detail-page/header/styled';
import { Gap } from 'components/shared/Gap';

type Props = {
  title?: string;
};

export const NameRow: React.FC<Props> = ({ title }) => {
  if (!title) {
    return null;
  }

  return (
    <StyledRow>
      <div>
        <Typography variant="h3">{title}</Typography>
        <Gap size="_12px" />
      </div>
    </StyledRow>
  );
};
