import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import i18n from 'localizations';
import { LevelCell } from 'components/shared/Cells/LevelCell';
import { StatusCell } from 'components/shared/Cells/StatusCell';
import { GuidCell } from 'components/shared/Cells/GuidCell';
import { GridCellExpand } from 'components/shared/Cells/GridCellExpand';
import { PersonalIdentityRow } from 'ui-interfaces/row-interfaces/identities/personal-identity-row';

export const columnsPersonal: GridColDef[] = [
  {
    field: 'firstName',
    headerName: i18n.t('identitiesPage.firstName'),
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    ),
  },
  {
    field: 'lastName',
    headerName: i18n.t('identitiesPage.lastName'),
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    ),
  },
  {
    field: 'id',
    headerName: i18n.t('identitiesPage.id'),
    flex: 1,
    renderCell: (params: GridRenderCellParams) => <GuidCell id={params.value} />,
  },
  {
    field: 'email',
    headerName: i18n.t('identitiesPage.email'),
    flex: 1.5,
    renderCell: (params: GridRenderCellParams) => (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    ),
  },
  {
    field: 'phone',
    headerName: i18n.t('identitiesPage.phone'),
    flex: 1.3,
  },
  {
    field: 'kycLevel',
    headerName: i18n.t('identitiesPage.kycLevel'),
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const { kycLevelCellProps } = params.row as PersonalIdentityRow;
      return <LevelCell {...kycLevelCellProps} />;
    },
  },
  {
    field: 'status',
    headerName: i18n.t('identitiesPage.status'),
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const { statusCellProps } = params.row as PersonalIdentityRow;
      return <StatusCell {...statusCellProps} />;
    },
  },
  {
    field: 'dateCreated',
    headerName: i18n.t('identitiesPage.dateCreated'),
    flex: 0.8,
  },
];
