import React from 'react';
import { ModalProps } from '@mui/material/Modal';
import { StyledContainer, StyledModal } from './styled';

const Modal: React.FC<ModalProps & { alignBottom?: boolean; children: React.ReactNode }> = ({
  children,
  alignBottom = false,
  ...props
}) => (
  <StyledModal
    {...props}
    alignBottom={alignBottom}
    BackdropProps={{
      ...props?.BackdropProps,
      style: { transitionDuration: '0s', ...props?.BackdropProps?.style },
    }}
  >
    <StyledContainer alignBottom={alignBottom}>{children}</StyledContainer>
  </StyledModal>
);
export { Modal };
