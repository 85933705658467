export const groupBy = <T>(arr: Array<T>, keyFunc: (el: T) => string): Map<string, Array<T>> => {
  const map = new Map<string, Array<T>>();

  arr.forEach(el => {
    const key = keyFunc(el);
    if (map.has(key)) {
      map.get(key)!.push(el);
    } else {
      map.set(key, [el]);
    }
  });

  return map;
};
