import { styled } from '@mui/material/styles';
import { css } from '@mui/material';
import { ReactComponent as ExportIcon } from 'assets/icons/export_24_24_styled.svg';

export const StyledExportIcon = styled(ExportIcon, {
  shouldForwardProp: prop => prop !== 'showAsMainDesign',
})<{ showAsMainDesign: boolean }>(
  ({ theme, showAsMainDesign }) => css`
    color: ${showAsMainDesign ? theme.palette.background.paper : theme.palette.grey['500']};
  `,
);
