import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { cardIconsMapping, formatUsdBalance, getCardType, isExternalAccount } from 'elements/utils';
import { Divider } from 'elements/components';
import { CustodialAccount, CustodialAccountTypeEnum, ExternalAccount, ExternalAccountTypeEnum } from 'elements/types';
import { ReactComponent as RoundedDollarIcon } from 'elements/assets/icons/roundedDollar.svg';
import { ReactComponent as SquaredAccountBalanceIcon } from 'elements/assets/icons/squaredAccountBalance.svg';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import PaymentMethodItem from 'elements/features/PaymentMethods/PaymentMethodItem';
import { PaymentMethodItemState } from 'elements/features/PaymentMethods/PaymentMethodItem/types';
import { StyledFormControlLabel, StyledRadio } from './styled';

type Props = {
  account: CustodialAccount | ExternalAccount;
};

const PaymentMethodRadioItem: React.FC<Props> = ({ account }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);
  const { isMobile } = useIsMobile();

  useEffect(() => {
    if (ref.current?.checked) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, []);

  const getAccountData = (): {
    icon: React.ReactNode;
    title: string;
    subtitle: string;
    postfix?: string;
  } => {
    if (isExternalAccount(account)) {
      if (account.type === ExternalAccountTypeEnum.CardExternalAccount) {
        const Icon = cardIconsMapping[account.network!];

        return {
          icon: <Icon />,
          title: t('elements.features.paymentMethods.cardEnding', { last4: account.last4 }),
          subtitle: getCardType(account.network!),
        };
      }

      return {
        icon: <SquaredAccountBalanceIcon />,
        title: t('elements.features.paymentMethods.externalBankAccount'),
        subtitle: t('elements.features.paymentMethods.bankAccount'),
        postfix: `*${account.last4}`,
      };
    }

    const mapAccountTypeToTitle = {
      [CustodialAccountTypeEnum.Business]: t('elements.features.paymentMethods.businessAccount'),
      [CustodialAccountTypeEnum.Personal]: t('elements.features.paymentMethods.personalAccount'),
      [CustodialAccountTypeEnum.RothIra]: t('elements.features.paymentMethods.account'),
      [CustodialAccountTypeEnum.SepIra]: t('elements.features.paymentMethods.account'),
      [CustodialAccountTypeEnum.SimpleIra]: t('elements.features.paymentMethods.account'),
      [CustodialAccountTypeEnum.TraditionalIra]: t('elements.features.paymentMethods.account'),
    };

    const textWithoutBalance = mapAccountTypeToTitle[account.type];

    return {
      icon: <RoundedDollarIcon />,
      title: `${textWithoutBalance} *${account.accountNumber.slice(-4)}`,
      subtitle: formatUsdBalance(account.balance, isMobile),
    };
  };
  const { icon, title, subtitle, postfix } = getAccountData();

  return (
    <>
      <StyledFormControlLabel
        value={account.id}
        control={<StyledRadio inputRef={ref} disableRipple />}
        label={
          <PaymentMethodItem
            icon={icon}
            title={title}
            subtitle={subtitle}
            postfix={postfix}
            reverse
            state={PaymentMethodItemState.NotEditable}
          />
        }
        labelPlacement="start"
      />
      <Divider />
    </>
  );
};

export default PaymentMethodRadioItem;
