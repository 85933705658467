import {
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  Link,
  linkClasses,
  styled,
  Typography,
} from '@mui/material';
import { Row } from 'components/shared/Flex/Row';
import { pxToRem } from 'utils/styling-utils';

const DIALOG_MARGIN = 32;
const MAX_DIALOG_HEIGHT = 704;
const MAX_DIALOG_OUTER_HEIGHT = MAX_DIALOG_HEIGHT + 2 * DIALOG_MARGIN;
export const StyledDialog = styled(Dialog)(() => ({
  [`& .${dialogClasses.paper}`]: {
    width: pxToRem(576),
    margin: pxToRem(DIALOG_MARGIN),
    maxHeight: `calc(100% - ${2 * DIALOG_MARGIN}px)`,
  },
  [`@media screen and (min-height: ${MAX_DIALOG_OUTER_HEIGHT}px)`]: {
    [`& .${dialogClasses.paper}`]: {
      maxHeight: pxToRem(MAX_DIALOG_HEIGHT),
    },
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: `${pxToRem(theme.gaps._40px)} ${pxToRem(theme.gaps._40px)} 0`,
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: `0 ${pxToRem(theme.gaps._40px)}`,
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: `${pxToRem(theme.gaps._24px)} ${pxToRem(theme.gaps._40px)}`,
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: pxToRem(theme.gaps._20px),
  right: pxToRem(theme.gaps._20px),
  height: pxToRem(36),
  width: pxToRem(36),
  padding: 0,
}));

export const StyledRadioFormControl = styled(FormControl)(() => ({
  width: '100%',
}));

export const StyledCheckboxFormControl = styled(FormControl)(() => ({
  width: '100%',
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette?.grey?.[200],
}));

export const StyledRow = styled(Row)(() => ({
  justifyContent: 'center',
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  ...theme.typography.buttonLarge,
  borderRadius: pxToRem(theme.gaps._4px),
  color: theme.palette.secondary.main,
  textDecorationColor: theme.palette.secondary.main,
  ':hover': {
    color: theme.palette.secondary.dark,
    textDecorationColor: theme.palette.secondary.dark,
    cursor: 'pointer',
  },
  ':visited': {
    color: theme.palette.secondary.main,
    textDecorationColor: theme.palette.secondary.main,
  },
  [`&.${linkClasses.focusVisible}`]: {
    color: theme.palette.secondary.dark,
    textDecorationColor: theme.palette.secondary.dark,
    outline: `1px solid ${theme.palette.secondary.dark}`,
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.overlineDefaultBold,
  color: theme.palette.grey[500],
  textTransform: 'uppercase',
}));
