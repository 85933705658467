import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ErrorIcon } from 'assets/icons/grid/error.svg';
import { IconWithLabels } from 'components/shared/IconWithLabels';

export const CustomErrorOverlay: React.FC = () => {
  const { t } = useTranslation();

  return <IconWithLabels icon={<ErrorIcon />} title={t('error.title')} subtitle={t('error.subtitle')} />;
};
