import React from 'react';
import { Typography } from '@mui/material';
import { RightCellBox } from 'components/shared/detail-page/rows/shared/RightCellBox';
import { Gap } from 'components/shared/Gap';
import { getBlockchainAssetIcon } from 'utils/wallets/token-details/get-blockchain-asset-icon';
import { NetworkChain } from 'ui-enums/response/network-chain';
import { getNetworkChainLabel } from 'utils/labels-mapping/get-network-chain-label';

type Props = {
  network: NetworkChain;
};

export const RightCell: React.FC<Props> = ({ network }) => {
  return (
    <RightCellBox>
      {getBlockchainAssetIcon(network)}
      <Gap size="_8px" isHorizontal />
      <Typography color="grey.600" variant="bodyDefaultBook">
        {getNetworkChainLabel(network)}
      </Typography>
    </RightCellBox>
  );
};
