import { WireWithdrawalState } from 'elements/element-transfer/contexts/Element';
import { CreateWireInstructionsModel } from 'models/request/transfers/create-wire-instructions-model';
import { InitiateWireWithdrawalTransferModel } from 'models/request/transfers/initiate-wire-withdrawal-transfer-model';

const composeDomesticWireWithdrawalRequestBody = (
  amount: string,
  custodialId: string,
  lockId: string,
  wireState: WireWithdrawalState,
): InitiateWireWithdrawalTransferModel => {
  const instructions: CreateWireInstructionsModel = {
    accountNumber: wireState.accountNumbers.accountNumber,
    routingNumber: wireState.accountNumbers.routingNumber,
    receiverName: wireState.recipientInfo.fullName,
    receiverAddress: {
      street1: wireState.recipientInfo.street1,
      street2: wireState.recipientInfo.street2 !== '' ? wireState.recipientInfo.street2 : undefined,
      city: wireState.recipientInfo.city,
      state: wireState.recipientInfo.state,
      postalCode: wireState.recipientInfo.postalCode,
      country: wireState.recipientInfo.country,
    },
    receiverBankAddress: {
      street1: wireState.bankInfo.street1,
      street2: wireState.bankInfo.street2 !== '' ? wireState.bankInfo.street2 : undefined,
      city: wireState.bankInfo.city,
      state: wireState.bankInfo.state,
      postalCode: wireState.bankInfo.postalCode,
      country: wireState.bankInfo.country,
    },
  };
  return {
    sourceCustodialAccountId: custodialId,
    amount: Number(amount),
    lockId,
    ...(wireState.note ? { comment: wireState.note } : {}),
    ...(wireState.memo ? { memo: wireState.memo } : {}),
    ...(wireState.recipientId
      ? { externalAccountId: wireState.recipientId }
      : { destinationWireInstructions: instructions }),
  };
};

export default composeDomesticWireWithdrawalRequestBody;
