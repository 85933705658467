import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { calculateCoinAmount, calculateDollarAmount } from 'elements/element-trade/utils/calculateTransactionAmount';
import { pricesLabels } from 'elements/element-trade/utils/labelsMapping';
import { formatCryptoBalance, formatUsdBalance } from 'elements/utils';
import { EstimatedValue } from 'elements/components';
import isCoinDisabled from 'elements/element-trade/utils/isCoinDisabled';
import { TradeFlow } from 'elements/element-trade/types';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { StyledDivider, StyledInlineBanner, StyledLinkButton } from './styled';

type Props = {
  totalLabel: string;
  amount: string;
  coinPrice: number;
  assetType: string;
  isError: boolean;
  isLoading: boolean;
  onReloadCoinPrice: () => void;
  flowType: TradeFlow;
};

const EstimatedPricesSection: React.FC<Props> = ({
  totalLabel,
  coinPrice,
  amount,
  assetType,
  isError,
  isLoading,
  onReloadCoinPrice,
  flowType,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  const estimatedLabel = pricesLabels[flowType].estimatedPrice(assetType);

  const isCoinUnavailable = isError || isCoinDisabled(assetType);

  const priceValue = isCoinUnavailable
    ? t('elements.trade.buySellPage.priceUnavailable')
    : formatUsdBalance(coinPrice, isMobile);

  const totalBuyAmount = amount
    ? formatCryptoBalance(calculateCoinAmount(amount, coinPrice), {
        withCommas: true,
        coinAbbreviation: assetType,
      })
    : '0.0 '.concat(assetType);

  const totalSellAmount = amount
    ? formatUsdBalance(calculateDollarAmount(amount, coinPrice), isMobile)
    : formatUsdBalance(0.0, isMobile);

  const selectedFlowAmount = flowType === TradeFlow.Buy ? totalBuyAmount : totalSellAmount;

  const total = isCoinUnavailable ? t('elements.trade.buySellPage.priceUnavailable') : selectedFlowAmount;

  return (
    <div>
      <EstimatedValue isLoading={isLoading} value={priceValue} isError={isError} label={estimatedLabel} />
      <StyledDivider />
      <EstimatedValue
        isLoading={isLoading}
        value={total}
        isError={isError}
        label={totalLabel}
        isTotal
        fontVariant="bodyDefaultMedium"
      />
      {isError && (
        <StyledInlineBanner icon={<CloseIcon />}>
          <span>
            <Typography variant="bodyDefaultBook">{t('elements.trade.buySellPage.errorMessagePart1')}</Typography>
            <StyledLinkButton onClick={onReloadCoinPrice}>
              <Typography variant="bodyDefaultMedium">{t('elements.trade.buySellPage.errorMessagePart2')}</Typography>
            </StyledLinkButton>
            <Typography variant="bodyDefaultBook">{t('elements.trade.buySellPage.errorMessagePart3')}</Typography>
          </span>
        </StyledInlineBanner>
      )}
    </div>
  );
};
export default EstimatedPricesSection;
