import i18n from 'localizations';
import { ValidationResult } from 'ui-interfaces/shared/validation-result';
import { Result } from 'ui-enums/result';
import { Input } from 'ui-interfaces/shared/inputs/input';
import { CaNamingInput } from 'ui-enums/custodial-accounts/inputs/ca-naming-input';
import { CaNamingInputParamsViewModel } from 'ui-interfaces/custodial-accounts-details/params/ca-naming-input-params-vm';

export class CaNameInputViewModel implements Input<string, CaNamingInput> {
  readonly id: string;

  readonly uiElementType: CaNamingInput;

  value: string;

  isValid = true;

  isTouched = false;

  errorMessage: string | undefined;

  private readonly initialValue: string;

  private validationResult: ValidationResult = {
    result: Result.success,
  };

  private static MAX_NUM_OF_CHARS = 50;

  constructor(params: CaNamingInputParamsViewModel<string>) {
    this.id = params.id;

    this.uiElementType = params.uiElementType;

    this.value = params.value;
    this.initialValue = params.value;
  }

  setValue(value: string) {
    this.value = value;
    this.isTouched = true;
    this.validate();
  }

  hasValueChanged(): boolean {
    return this.value.trim() !== this.initialValue;
  }

  private updateValidationState() {
    this.isValid = this.validationResult.result === Result.success;
    this.errorMessage = this.validationResult.errorMessage;
  }

  validate() {
    if (this.value.trim().length > CaNameInputViewModel.MAX_NUM_OF_CHARS) {
      this.validationResult = {
        result: Result.failure,
        errorMessage: i18n.t('validation.accountNameMustBe50CharactersOrLess'),
      };
      this.updateValidationState();
      return;
    }

    this.validationResult = {
      result: Result.success,
    };

    this.updateValidationState();
  }

  reset() {
    this.value = this.initialValue;
    this.isValid = true;
    this.isTouched = false;
    this.errorMessage = undefined;
    this.validationResult = {
      result: Result.success,
    };
  }
}
