import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonLayoutContainer, CommonLayoutHeader } from 'components/shared/layouts/CommonLayout';
import { Gap } from 'components/shared/Gap';
import { StyledTab } from 'components/shared/Tabs';
import { BreadCrumbsItems } from 'components/shared/Breadcrumbs';
import { StyledHeaderBox, StyledTabsBox } from 'components/identities/styled';
import { IdentitiesDetailsPageTabs } from 'ui-enums/tabs/identities/identities-details-page-tabs';
import { IIdentityDetailsPage } from 'ui-interfaces/identity-details/i-identity-details-page';
import { IdentityDetailsUrlParams } from 'navigation/url-params-type';
import { CustodialAccountsTab } from './Tabs/CustodialAccountsTab';
import { IdentityDetailsPageHeader } from './IdentityDetailsPageHeader';
import { IdentityDetailsPageTitleRow } from './IdentityDetailsPageTitleRow';
import { IdentityDetailsTab } from './Tabs/IdentityDetailsTab';
import { WalletsTab } from './Tabs/WalletsTab';
import { Error } from './layout/Error';
import { SuspensionIdentityModal } from './SuspensionIdentityModal';
import { IdentityDetailsPageTopRow } from './IdentityDetailsPageTopRow';

type Props = {
  tab: IdentitiesDetailsPageTabs;
  urlParams: Readonly<Partial<IdentityDetailsUrlParams>>;
  setIsDetailsPageError: React.Dispatch<React.SetStateAction<boolean>>;
  handleChange: (event: React.SyntheticEvent, newTab: IdentitiesDetailsPageTabs) => void;
  breadCrumbs: BreadCrumbsItems;
  loading: boolean;
  error: boolean;
  identityDetailsPage?: IIdentityDetailsPage;
};

export const IdentityDetailsPageContent: React.FC<Props> = ({
  tab,
  urlParams,
  setIsDetailsPageError,
  handleChange,
  breadCrumbs,
  loading,
  error,
  identityDetailsPage,
}) => {
  const { t } = useTranslation();

  // suspension modal - start
  const [isSuspensionModalOpen, setIsSuspensionModalOpen] = useState(false);

  const showSuspensionModal = () => setIsSuspensionModalOpen(true);
  const closeSuspensionModal = useCallback(() => setIsSuspensionModalOpen(false), []);
  // suspension modal - end

  if (error) {
    return (
      <Error
        breadCrumbs={breadCrumbs}
        tab={tab}
        handleChange={handleChange}
        isCustodialAccountsTabShown={identityDetailsPage?.isCustodialAccountsTabShown}
        isWalletsTabShown={identityDetailsPage?.isWalletsTabShown}
      />
    );
  }
  return (
    <CommonLayoutContainer>
      <CommonLayoutHeader>
        <StyledHeaderBox>
          <IdentityDetailsPageTopRow
            loading={loading}
            showSuspensionModal={showSuspensionModal}
            breadCrumbs={breadCrumbs}
            data={identityDetailsPage}
          />
          <Gap size="_20px" />
          <IdentityDetailsPageTitleRow data={identityDetailsPage?.pageTitle} loading={loading} />
          <Gap size="_20px" />
          <IdentityDetailsPageHeader data={identityDetailsPage?.pageHeader} loading={loading} />
          <Gap size="_40px" />
          <StyledTabsBox value={tab} onChange={handleChange}>
            <StyledTab label={t('identityDetailsPage.tabs.detailsTab')} value={IdentitiesDetailsPageTabs.details} />
            {identityDetailsPage?.isCustodialAccountsTabShown && (
              <StyledTab
                label={t('identityDetailsPage.tabs.accountsTab')}
                value={IdentitiesDetailsPageTabs.custodialAccounts}
              />
            )}
            {identityDetailsPage?.isWalletsTabShown && (
              <StyledTab label={t('identityDetailsPage.tabs.walletsTab')} value={IdentitiesDetailsPageTabs.wallets} />
            )}
          </StyledTabsBox>
        </StyledHeaderBox>
      </CommonLayoutHeader>
      <>
        {tab === IdentitiesDetailsPageTabs.details && (
          <IdentityDetailsTab
            urlParams={urlParams}
            identityDetailsPage={identityDetailsPage}
            setIsDetailsPageError={setIsDetailsPageError}
            loading={loading}
          />
        )}
        {tab === IdentitiesDetailsPageTabs.custodialAccounts && (
          <CustodialAccountsTab urlParams={urlParams} setIsDetailsPageError={setIsDetailsPageError} />
        )}
        {tab === IdentitiesDetailsPageTabs.wallets && (
          <WalletsTab urlParams={urlParams} setIsDetailsPageError={setIsDetailsPageError} />
        )}
      </>
      <SuspensionIdentityModal
        isOpen={isSuspensionModalOpen}
        onClose={closeSuspensionModal}
        isSuspendMode={identityDetailsPage?.isSuspendMode ?? false}
        name={identityDetailsPage?.pageTitle.title ?? ''}
        urlParams={urlParams}
      />
    </CommonLayoutContainer>
  );
};
