import React from 'react';
import { Element } from 'ui-interfaces/elements/element';
import { NativeElementButton } from 'components/shared/buttons/NativeElementButton';
import { CustomElementButton } from 'components/shared/buttons/CustomElementButton';

type Props = Element & {
  isSkeletonDisplayed: boolean;
};

export const ElementButton: React.FC<Props> = ({ isNativeElement, ...props }) => {
  if (isNativeElement) {
    return <NativeElementButton {...props} />;
  }

  return <CustomElementButton {...props} />;
};
