import { Typography } from '@mui/material';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { preventForwardProps, pxToRem } from 'elements/utils';

export const StyledTypography = elementsStyled(
  Typography,
  preventForwardProps(['isMobile']),
)<{ isMobile: boolean }>(
  ({ isMobile }) => css`
    font-family: MalloryMP, sans-serif;
    padding-top: ${isMobile ? 1 : 0.2}px;
    margin: 0 4px;
    font-style: normal;
    font-weight: normal;
    font-size: ${pxToRem(10)};
    line-height: 170%;
  `,
);
