import { IconButton, styled } from '@mui/material';
import { Row } from 'components/shared/Flex/Row';
import { pxToRem } from 'utils/styling-utils';

export const StyledRow = styled(Row)(() => ({
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  height: pxToRem(24),
  width: pxToRem(24),
  padding: 0,
  color: theme.palette.secondary.main,
}));
