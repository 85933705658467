import React from 'react';
import { useTranslation } from 'react-i18next';
import { RadioGroup, Typography } from '@mui/material';
import { Divider, Spacer } from 'elements/components';
import { CustodialAccount, ExternalAccount, ExternalAccountTypeEnum } from 'elements/types';
import PaymentMethodRadioItem from './PaymentMethodRadioItem';
import {
  StyledAccountBalanceOutlinedIcon,
  StyledAccountBalanceWalletOutlinedIcon,
  StyledNoAccountContainer,
} from './styled';

type Props = {
  withCustodialAccounts: boolean;
  withCardAccounts: boolean;
  withBankAccounts: boolean;
  currentPaymentAccountId: string;
  custodialAccountList: CustodialAccount[];
  externalAccountList: ExternalAccount[];
  onCurrentPaymentAccountIdChange: (currentPaymentAccountId: string) => void;
};

const PaymentReview: React.FC<Props> = ({
  withCustodialAccounts,
  withCardAccounts,
  withBankAccounts,
  currentPaymentAccountId,
  custodialAccountList,
  externalAccountList,
  onCurrentPaymentAccountIdChange,
}) => {
  const [cardAccountList, bankAccountList] = externalAccountList.reduce<[ExternalAccount[], ExternalAccount[]]>(
    (acc, el) => {
      if (el.type === ExternalAccountTypeEnum.CardExternalAccount) {
        return [[...acc[0], el], acc[1]];
      }
      if (el.type === ExternalAccountTypeEnum.AchExternalAccount) {
        return [acc[0], [...acc[1], el]];
      }
      return acc;
    },
    [[], []],
  );

  const { t } = useTranslation();

  return (
    <RadioGroup value={currentPaymentAccountId} onChange={e => onCurrentPaymentAccountIdChange(e.target.value)}>
      {withCustodialAccounts && (
        <>
          <Typography textTransform="uppercase" variant="bodyDefaultMedium" color="secondary.font">
            {t('elements.features.paymentMethods.availableBalancesTitle')}
          </Typography>
          {custodialAccountList.length ? (
            custodialAccountList.map(item => <PaymentMethodRadioItem account={item} key={item.id} />)
          ) : (
            <>
              <StyledNoAccountContainer>
                <StyledAccountBalanceWalletOutlinedIcon />
                <Typography variant="bodyDefaultMedium" color="secondary.font">
                  {t('elements.features.paymentMethods.noBalances')}
                </Typography>
              </StyledNoAccountContainer>
              <Divider />
            </>
          )}
          <Spacer size={24} />
        </>
      )}
      {withCardAccounts && cardAccountList.length > 0 && (
        <>
          <Typography textTransform="uppercase" variant="bodyDefaultMedium" color="secondary.font">
            {t('elements.features.paymentMethods.creditDebitTitle')}
          </Typography>
          {cardAccountList.map(item => (
            <PaymentMethodRadioItem account={item} key={item.id} />
          ))}
          <Spacer size={24} />
        </>
      )}
      {withBankAccounts && (
        <>
          <Typography textTransform="uppercase" variant="bodyDefaultMedium" color="secondary.font">
            {t('elements.features.paymentMethods.bankAccountsTitle')}
          </Typography>
          {bankAccountList.length ? (
            bankAccountList.map(item => <PaymentMethodRadioItem account={item} key={item.id} />)
          ) : (
            <>
              <StyledNoAccountContainer>
                <StyledAccountBalanceOutlinedIcon />
                <Typography variant="bodyDefaultMedium" color="secondary.font">
                  {t('elements.features.paymentMethods.noAccountLinked')}
                </Typography>
              </StyledNoAccountContainer>
              <Divider />
            </>
          )}
        </>
      )}
    </RadioGroup>
  );
};

export default PaymentReview;
