import i18n from 'localizations';
import { BusinessIdentityIndustry } from 'models/enums/identities/business-identity-industry';
import { BusinessIdentityDetailsResponseModel } from 'models/response/identities/business/business-identity-details-response-model';
import { formatEnumerableIdentityIndustry } from './format-enumerable-identity-industry';

export const formatIdentityIndustry = ({ businessInfo }: BusinessIdentityDetailsResponseModel) => {
  const { industry, otherIndustry } = businessInfo;

  if (!industry) {
    return i18n.t('identityDetailsPage.emptyFields.industry');
  }

  if (industry === BusinessIdentityIndustry.other) {
    return otherIndustry;
  }

  return formatEnumerableIdentityIndustry(industry);
};
