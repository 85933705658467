import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Gap } from 'components/shared/Gap';
import { ReactComponent as BackButton } from 'assets/icons/identity-details/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/identity-details/arrow-right.svg';
import { Row } from 'components/shared/Flex/Row';
import { StyledBackButton, StyledSecondLabel, StyledFirstLabel } from './styled';

export type BreadCrumbsItem = {
  label: string;
  path?: string;
};

export type BreadCrumbsItems = Array<BreadCrumbsItem>;

type Props = {
  items: BreadCrumbsItems;
};

const Breadcrumbs: React.FC<Props> = ({ items }) => {
  const navigate = useNavigate();
  const lastItem = items[items.length - 1];

  return (
    <Row sx={{ alignItems: 'center' }}>
      <StyledBackButton onClick={() => navigate(-1)}>
        <BackButton />
      </StyledBackButton>
      <Gap size="_24px" isHorizontal />
      {items.map((item: BreadCrumbsItem) => {
        if (item !== lastItem) {
          return (
            <Row sx={{ alignItems: 'center' }} key={item.label}>
              <StyledFirstLabel to={item.path!}>{item.label}</StyledFirstLabel>
              <Gap size="_12px" isHorizontal />
              <ArrowRight />
              <Gap size="_12px" isHorizontal />
            </Row>
          );
        }
        return <StyledSecondLabel key={item.label}>{lastItem.label}</StyledSecondLabel>;
      })}
    </Row>
  );
};

export default Breadcrumbs;
