import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Row } from 'components/shared/Flex/Row';
import { SelectedDoc } from 'ui-interfaces/identity-details/document-list/selected-doc';
import { ExpendSection } from 'components/shared/ExpandSection';
import { ExpendSectionRow } from 'components/shared/ExpandSection/ExpendSectionRow';
import { IdentityDetailsUrlParams } from 'navigation/url-params-type';
import { useFetchDocument } from 'hooks/use-fetch-document';
import { useFetchDocumentsList } from 'hooks/use-fetch-documents-list';
import { Gap } from 'components/shared/Gap';
import { DocumentColumnLabel, DateColumnLabel, PillColumnLabel, StyledDivider } from './styled';
import { DocumentsListRow } from './DocumentsListRow';
import { DocumentViewerModal } from '../DocumentViewerModal';

interface DocumentsListProps {
  urlParams: Readonly<Partial<IdentityDetailsUrlParams>>;
  setIsDetailsPageError: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DocumentsList: React.FC<DocumentsListProps> = ({ urlParams, setIsDetailsPageError }) => {
  const { t } = useTranslation();

  // Documents
  // List
  const {
    fetchIdentityDocumentsTrigger,
    documentsResult: { isError: isDocumentsError, isLoading: isDocumentsLoading },
    documents,
  } = useFetchDocumentsList(urlParams.identityType!);
  useEffect(() => {
    fetchIdentityDocumentsTrigger(urlParams.identityId!);
  }, [fetchIdentityDocumentsTrigger, urlParams.identityId]);

  // Documents
  // Item
  const { data: file, isError: isDocError, isLoading: isDocLoading, fetchImageContent } = useFetchDocument();
  const [isOpenModal, setOpenModal] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState<SelectedDoc>({ file: '', fileName: '', docType: '' });
  const [status, setStatus] = useState({ isDocLoading: false, isDocError: false });

  const handleOpenDocument = async (sideId: string, documentId: string, fileName: string, docType: string) => {
    setOpenModal(true);
    setSelectedDoc({ file: '', fileName: '', docType: '' });
    setSelectedDoc(prevState => ({ ...prevState, fileName, docType }));

    await fetchImageContent({
      identityId: urlParams.identityId!,
      sideId,
      documentId,
      type: urlParams.identityType!,
    });
  };
  useEffect(() => {
    setSelectedDoc(prevState => ({ ...prevState, file }));
    setStatus({ isDocError, isDocLoading });
  }, [file, isDocError, isDocLoading]);

  // handle all page error - start
  useEffect(() => {
    setIsDetailsPageError(isDocumentsError);
  }, [setIsDetailsPageError, isDocumentsError]);
  // handle all page error - end

  if ((!documents?.length && !isDocumentsLoading) || isDocumentsError || isDocumentsLoading) {
    return null;
  }

  return (
    <>
      <Gap size="_24px" />
      <ExpendSection sectionTitle={t('documentsList.sectionTitle')}>
        <Row>
          <DocumentColumnLabel>
            <Typography color="grey.600" variant="bodySmallBold">
              {t('documentsList.columnTitles.document')}
            </Typography>
          </DocumentColumnLabel>
          <DateColumnLabel>
            <Typography color="grey.600" variant="bodySmallBold">
              {t('documentsList.columnTitles.submitted')}
            </Typography>
          </DateColumnLabel>
          <PillColumnLabel>
            <Typography color="grey.600" variant="bodySmallBold">
              {t('documentsList.columnTitles.documentStatus')}
            </Typography>
          </PillColumnLabel>
        </Row>
        <StyledDivider />
        {documents?.map((doc, index) => (
          <ExpendSectionRow key={doc.sideId} isLastItem={index === documents.length - 1}>
            <DocumentsListRow
              {...doc}
              isLastItem={index === documents.length - 1}
              onSelect={handleOpenDocument}
              loading={isDocumentsLoading}
            />
          </ExpendSectionRow>
        ))}
        {isOpenModal && selectedDoc && (
          <DocumentViewerModal
            onClose={() => setOpenModal(false)}
            doc={selectedDoc.file}
            docType={selectedDoc.docType}
            docName={selectedDoc.fileName}
            loading={status.isDocLoading}
            error={status.isDocError}
          />
        )}
      </ExpendSection>
    </>
  );
};
