import { FormControlLabel, formControlLabelClasses, styled } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  alignItems: 'flex-start',
  margin: 0,
  padding: `${pxToRem(theme.gaps._12px)} 0`,
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  [`& .${formControlLabelClasses.label}`]: {
    marginLeft: pxToRem(theme.gaps._12px),
  },
}));
