import React, { useState } from 'react';
import { StyledImage } from './styled';

type Props = {
  url?: string;
  size: number;
  defaultAssetIcon: JSX.Element;
};

export const AssetImage: React.FC<Props> = ({ url, size, defaultAssetIcon }) => {
  const [isError, setIsError] = useState(false);

  if (isError || !url) {
    return defaultAssetIcon;
  }

  const onLoad = () => setIsError(false);
  const onError = () => setIsError(true);

  return <StyledImage size={size} src={url} onLoad={onLoad} onError={onError} />;
};
