import React from 'react';
import Typography from '@mui/material/Typography';
import { ShadowBox } from 'components/shared/detail-page/rows/shared/ShadowBox';
import { Gap } from 'components/shared/Gap';
import { IdentityDetailsNode } from 'ui-interfaces/identity-details/identity-details-node';
import { DetailNode } from '../DetailNode';

type Props = {
  sectionHeader: string;
  nodes: IdentityDetailsNode[];
};

export const Content: React.FC<Props> = ({ sectionHeader, nodes }) => {
  return (
    <>
      <Gap size="_24px" />
      <ShadowBox>
        <Typography variant="h6">{sectionHeader}</Typography>
        <Gap size="_16px" />
        {nodes.map((n, index, originalArray) => (
          <DetailNode key={n.id} node={n} hasBottomBorder={index !== originalArray.length - 1} />
        ))}
      </ShadowBox>
    </>
  );
};
