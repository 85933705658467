import React from 'react';
import { IInlineErrorBanner, WireAccountNumbersFormData } from 'elements/element-transfer/types';
import { CustomFormFieldError } from 'elements/types';
import WireAccountNumbersContent from './WireAccountNumbersContent';

type Props = {
  loading: boolean;
  error: IInlineErrorBanner;
  isDomestic: boolean;
  initialFormData: WireAccountNumbersFormData;
  routingNumberCustomError: CustomFormFieldError | null;
  onClose: () => void;
  onContinue: (data: WireAccountNumbersFormData) => void;
  onBack: () => void;
};

const WireAccountNumbersPage: React.FC<Props> = ({
  loading,
  error,
  isDomestic,
  initialFormData,
  routingNumberCustomError,
  onClose,
  onContinue,
  onBack,
}) => {
  return (
    <WireAccountNumbersContent
      loading={loading}
      isDomestic={isDomestic}
      initialFormData={initialFormData}
      routingNumberCustomError={routingNumberCustomError}
      onClose={onClose}
      onContinue={onContinue}
      onBack={onBack}
      error={error}
    />
  );
};

export default WireAccountNumbersPage;
