import React from 'react';
import { LeftCell } from 'components/shared/detail-page/rows/shared/LeftCell';
import { RowBox } from 'components/shared/detail-page/rows/shared/RowBox';
import { IPaymentTypeRow } from 'ui-interfaces/transaction-details/rows/i-payment-type-row';
import { RightCell } from './RightCell';

type Props = {
  data: IPaymentTypeRow;
  hasBottomBorder?: boolean;
};

export const PaymentTypeRow: React.FC<Props> = ({ data, hasBottomBorder = true }) => {
  return (
    <RowBox hasBottomBorder={hasBottomBorder}>
      <LeftCell value={data.label} large={data.largeLeftCell} />
      <RightCell value={data.value} paymentType={data.paymentType} />
    </RowBox>
  );
};
