import i18n from 'localizations';
import { CustodialAccountLabel } from 'models/enums/custodial-accounts/custodial-account-label';

export const getCustodialAccountLabel = (label: CustodialAccountLabel): string => {
  switch (label) {
    case CustodialAccountLabel.corporate:
      return i18n.t('enumMapping.custodialAccountLabel.corporate');
    default:
      return i18n.t('enumMapping.custodialAccountLabel.user');
  }
};
