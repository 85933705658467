import { ElementResultStatus } from 'elements/models/enums/element-result-status';

export const sendPaymentIdMessage = ({
  achDepositPaymentId,
  cryptoWithdrawalPaymentId,
  wireDomesticWithdrawalPaymentId,
  wireInternationalWithdrawalPaymentId,
}: {
  achDepositPaymentId?: string | null;
  cryptoWithdrawalPaymentId?: string | null;
  wireDomesticWithdrawalPaymentId?: string | null;
  wireInternationalWithdrawalPaymentId?: string | null;
}) => {
  return {
    transferPayment: {
      achDepositPaymentId: achDepositPaymentId || null,
      cryptoWithdrawalPaymentId: cryptoWithdrawalPaymentId || null,
      wireDomesticWithdrawalPaymentId: wireDomesticWithdrawalPaymentId || null,
      wireInternationalWithdrawalPaymentId: wireInternationalWithdrawalPaymentId || null,
    },
    status: ElementResultStatus.Success,
  };
};
