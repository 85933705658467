import { PaymentMethodTypesMap } from 'elements/features/PaymentMethods/utils/getPaymentMethodTypesMap';
import { ExternalAccount, ExternalAccountTypeEnum } from 'elements/types';

const getFilteredExternalAccountList = (
  externalAccountList: ExternalAccount[],
  paymentMethodTypesMap: PaymentMethodTypesMap,
): ExternalAccount[] =>
  externalAccountList.reduce<ExternalAccount[]>((acc, el) => {
    return (el.type === ExternalAccountTypeEnum.CardExternalAccount && paymentMethodTypesMap.card) ||
      (el.type === ExternalAccountTypeEnum.AchExternalAccount && paymentMethodTypesMap.ach)
      ? [...acc, el]
      : acc;
  }, []);

export default getFilteredExternalAccountList;
