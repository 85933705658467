import React from 'react';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { TruncatedText } from 'elements/components/TruncatedText';
import { StyledTab, StyledTabs } from './styled';

type Props<T> = {
  items: Array<{ value: T; title: string }>;
  value: T;
  onTabChange: (value: T) => void;
};

const Tabs = <T extends string>({ items, value, onTabChange, ...props }: Props<T>) => {
  const { isMobile } = useIsMobile();
  return (
    <StyledTabs variant="fullWidth" value={value} onChange={(_, v) => onTabChange(v)} isMobile={isMobile} {...props}>
      {items.map(tab => (
        <StyledTab
          key={tab.value}
          label={<TruncatedText variant="buttonLarge">{tab.title}</TruncatedText>}
          value={tab.value}
          disableRipple
        />
      ))}
    </StyledTabs>
  );
};
export { Tabs };
