import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Stack, Typography } from '@mui/material';
import useForm from 'elements/hooks/useForm';
import { BaseInput, InlineBanner, Spacer } from 'elements/components';
import { IInlineErrorBanner, WireAccountNumbersFormData } from 'elements/element-transfer/types';
import { CustomFormFieldError } from 'elements/types';
import BaseFieldset from 'elements/element-transfer/components/BaseFieldset';
import BaseStepper from 'elements/element-transfer/components/BaseStepper';
import Layout from 'elements/element-transfer/components/Layout';
import {
  MAX_ACCOUNT_NUMBER_LENGTH,
  MAX_SWIFT_CODE_LENGTH,
  ROUTING_NUMBER_LENGTH,
  useYupRules,
} from 'elements/element-transfer/hooks/useYupRules';
import { StyledBackdrop, StyledButton } from './styled';

type Props = {
  loading: boolean;
  isDomestic: boolean;
  initialFormData: WireAccountNumbersFormData;
  routingNumberCustomError: CustomFormFieldError | null;
  onClose: () => void;
  onBack: () => void;
  onContinue: (data: WireAccountNumbersFormData) => void;
  error: IInlineErrorBanner;
};

const WireAccountNumbersContent: React.FC<Props> = ({
  loading,
  isDomestic,
  initialFormData,
  routingNumberCustomError,
  onClose,
  onBack,
  onContinue,
  error: { error, errorMessage, errorTraceId },
}) => {
  const { t } = useTranslation();
  const { accountNumberRule, confirmAccountNumberRule, routingNumberRule, swiftCodeRule, baseOptionalRule } =
    useYupRules();
  const {
    formik: { submitForm, handleSubmit, values },
    helpers: { getFieldProps, getFieldErrorProps, getFieldErrorPropsWithCustomError, isFormDisabled },
  } = useForm({
    initialValues: initialFormData,
    enableReinitialize: true,
    yupShape: {
      accountNumber: accountNumberRule,
      confirmAccountNumber: confirmAccountNumberRule('accountNumber'),
      routingNumber: isDomestic ? routingNumberRule : baseOptionalRule,
      swiftCode: !isDomestic ? swiftCodeRule : baseOptionalRule,
    },
    onSubmit: (formValues, { setSubmitting }) => {
      onContinue(formValues);
      setSubmitting(false);
    },
  });

  const isDisabled = useMemo(
    () =>
      isFormDisabled || (!!routingNumberCustomError && routingNumberCustomError.matchedText === values.routingNumber),
    [isFormDisabled, routingNumberCustomError, values],
  );

  return (
    <Layout
      title={t('elements.transfer.customizableLabels.withdrawalWireAccountNumbersPageTitle')}
      onClose={onClose}
      onBack={onBack}
      isAbove
      footerButtons={
        <Stack gap="16px">
          <BaseStepper variant="dots" position="static" steps={4} activeStep={2} backButton={null} nextButton={null} />
          <StyledButton
            type="submit"
            isLoading={loading}
            disabled={isDisabled}
            onClick={submitForm}
            endIcon={<ArrowForwardIcon />}
          >
            <Typography variant="buttonLarge">{t('elements.common.continue')}</Typography>
          </StyledButton>
        </Stack>
      }
    >
      {error && (
        <>
          <InlineBanner
            text={errorMessage || t('elements.transfer.withdrawalWireReviewPage.processingError')}
            errorTraceId={errorTraceId}
          />
          <Spacer size={24} />
        </>
      )}
      <StyledBackdrop open={loading} />
      <Stack component="form" onSubmit={handleSubmit} mb="24px">
        <BaseFieldset>
          <Typography component="legend" variant="overlineDefaultMedium" color="secondary.font">
            {t('elements.transfer.withdrawalWireAccountNumbersPage.fieldsHeader')}
          </Typography>
          {isDomestic ? (
            <BaseInput
              label={t('elements.transfer.withdrawalWireAccountNumbersPage.routingNumberLabel')}
              {...getFieldProps('routingNumber')}
              {...getFieldErrorPropsWithCustomError('routingNumber', routingNumberCustomError)}
              maxLength={ROUTING_NUMBER_LENGTH}
            />
          ) : (
            <BaseInput
              label={t('elements.transfer.withdrawalWireAccountNumbersPage.swiftCodeLabel')}
              {...getFieldProps('swiftCode')}
              {...getFieldErrorProps('swiftCode')}
              maxLength={MAX_SWIFT_CODE_LENGTH}
            />
          )}
          <BaseInput
            label={t('elements.transfer.withdrawalWireAccountNumbersPage.accountNumberLabel')}
            {...getFieldProps('accountNumber')}
            {...getFieldErrorProps('accountNumber')}
            maxLength={MAX_ACCOUNT_NUMBER_LENGTH}
          />
          <BaseInput
            label={t('elements.transfer.withdrawalWireAccountNumbersPage.confirmAccountNumberLabel')}
            {...getFieldProps('confirmAccountNumber')}
            {...getFieldErrorProps('confirmAccountNumber')}
            maxLength={MAX_ACCOUNT_NUMBER_LENGTH}
          />
        </BaseFieldset>
      </Stack>
    </Layout>
  );
};

export default WireAccountNumbersContent;
