import React from 'react';
import { Gap } from 'components/shared/Gap';
import { ShadowBox } from 'components/shared/detail-page/rows/shared/ShadowBox';
import { loadingNodes } from './loading-nodes';
import { DetailNode } from '../DetailNode';

export const Loading: React.FC = () => {
  return (
    <>
      <Gap size="_24px" />
      <ShadowBox>
        {loadingNodes.map((n, index, originalArray) => (
          <DetailNode key={n.id} node={n} hasBottomBorder={index !== originalArray.length - 1} />
        ))}
      </ShadowBox>
    </>
  );
};
