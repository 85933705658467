import React, { useState } from 'react';
import { Collapse } from '@mui/material';
import { Gap } from 'components/shared/Gap';
import HeaderRow from 'components/tapi/transactions/TapiTransactionDetailsPage/HeaderRow';
import { DetailNode } from 'components/tapi/transactions/TapiTransactionDetailsPage/DetailNode';
import { ICollapsibleSection } from 'ui-interfaces/transaction-details/sections/i-collapsible-section';
import { ToggleButton } from './ToggleButton';
import { StyledBox, StyledRow } from './styled';

type Props = {
  data: ICollapsibleSection;
  isExpanded?: boolean;
};

export const CollapsibleSection: React.FC<Props> = ({ data, isExpanded = false }) => {
  const [collapsed, setCollapsed] = useState(isExpanded);

  return (
    <>
      <StyledBox>
        <StyledRow>
          <HeaderRow label={data.label} />
          <ToggleButton onClick={() => setCollapsed(prev => !prev)} isExpanded={!collapsed} />
        </StyledRow>

        <Collapse in={collapsed}>
          {data.nodes.map((node, index, originalArray) => (
            <DetailNode key={node.id} node={node} hasBottomBorder={index !== originalArray.length - 1} />
          ))}
        </Collapse>

        <Collapse in={!collapsed}>
          <Gap size="_32px" />
        </Collapse>
      </StyledBox>
      <Gap size="_48px" />
    </>
  );
};
