import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';
import { Row } from 'components/shared/Flex/Row';

export const StyledBox = styled(Box)(({ theme }) => ({
  alignSelf: 'center',
  width: pxToRem(1056),
  backgroundColor: theme.palette.background.paper,
  paddingTop: pxToRem(theme.gaps._32px),
  borderRadius: pxToRem(theme.gaps._8px),
  boxShadow: theme.customShadows.grey._2dp,
}));

export const StyledRow = styled(Row)(({ theme }) => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingInline: pxToRem(theme.gaps._24px),
}));
