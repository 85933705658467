import { UserDetailsBaseViewModel } from 'view-models/users/details/base/user-details-base-vm';
import { UserDetails } from 'ui-interfaces/user-details/user-details';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { UserDetailResponseModel } from 'models/response/users/user-detail-response-model';
import { createActions } from 'utils/users/create-actions';
import { UserRoleTemplateResponseModel } from 'models/response/users/user-role-template-response-model';

export class UserDetailsViewModelWithCurrentUser extends UserDetailsBaseViewModel implements UserDetails {
  constructor(
    userDetails: UserDetails,
    user: UserDetailResponseModel,
    currentUser: ShortUserResponseModel,
    userRoleTemplates?: UserRoleTemplateResponseModel[],
  ) {
    super(user);

    Object.assign(this, userDetails);

    this.isCurrentUser = user.id === currentUser.id;

    this.headerDetails.actions = createActions(user, userDetails, currentUser, userRoleTemplates);
  }
}
