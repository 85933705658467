import { TransactionDetails } from 'ui-interfaces/transaction-details/transaction-details';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { TransactionDetailResponseModel } from 'models/response/transactions/transaction-detail-response-model';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';
import { arraysHaveMatchedPermissions } from 'utils/user/arrays-have-matched-permissions';
import { DashboardRoles } from 'models/enums/user/dashboard-roles';
import { TransactionDetailsBaseViewModel } from '../transaction-details-base-vm';

export class TransactionDetailsViewModelWithUser extends TransactionDetailsBaseViewModel implements TransactionDetails {
  constructor(
    transactionDetails: TransactionDetails,
    transaction: TransactionDetailResponseModel,
    user: ShortUserResponseModel,
  ) {
    super(transaction);

    Object.assign(this, transactionDetails);

    this.isTransactionInitiatedByTheCurrentUser = user.id === transaction.initiatedBy;

    this.areActionsDisplayed =
      transaction.status.toLowerCase() === TransactionStatus.approvalNeeded.toLowerCase() &&
      arraysHaveMatchedPermissions([DashboardRoles.paymentApprover], user.permissions);
  }
}
