import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { LoadingButtonWithSkeleton } from 'components/shared/buttons/LoadingButtonWithSkeleton';
import { ReactComponent as ResendIcon } from 'assets/icons/settings/user-action/resend-white.svg';

type Props = {
  isLoading: boolean;
  onClick: () => void;
};
export const ResendInvitationButton: React.FC<Props> = ({ isLoading, onClick }) => {
  const { t } = useTranslation();

  return (
    <LoadingButtonWithSkeleton
      color="primary"
      variant="contained"
      size="large"
      icon={<ResendIcon />}
      text={
        <Typography variant="buttonMedium" color="background.paper">
          {t('userDetailsPage.buttons.resendInvitation')}
        </Typography>
      }
      loading={isLoading}
      onClick={onClick}
    />
  );
};
