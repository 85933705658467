import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as CloseIcon } from 'assets/icons/grey-close-icon.svg';
import { Gap } from 'components/shared/Gap';
import { Column } from 'components/shared/Flex/Column';
import { useYupRules } from 'hooks/use-yup-rules';
import { TextArea } from 'components/shared/TextArea';
import { useVerifyCustodialAccountMutation, VerifyAccountApiParams } from 'redux/api/api-custodial-accounts';
import { CustodialAccountStatus } from 'models/enums/custodial-accounts/custodial-account-status';
import {
  StyledButton,
  StyledCenteredTextTypography,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledForm,
  StyledIconButton,
  StyledLoadingButtonWithSkeleton,
  StyledTypographyCaption,
} from './styled';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isSuspendMode: boolean;
  custodialAccountId: string;
  accountName?: string;
  accountTitle: string;
};

export const SuspensionAccountModal: React.FC<Props> = ({
  isOpen,
  onClose,
  isSuspendMode,
  custodialAccountId,
  accountName,
  accountTitle,
}) => {
  const { t } = useTranslation();
  const { suspensionReasonRule, unSuspensionReasonRule } = useYupRules();

  const [verifyCustodialAccountTrigger, verifyCustodialAccountResult] = useVerifyCustodialAccountMutation();

  const onSubmit = (suspensionReason: string) => {
    verifyCustodialAccountTrigger({
      custodialAccountId,
      updateAccountStatusModel: {
        decision: isSuspendMode ? CustodialAccountStatus.suspended : CustodialAccountStatus.open,
        comment: suspensionReason,
      },
    } as VerifyAccountApiParams);
  };

  const { handleChange, handleBlur, handleSubmit, values, errors, touched, submitForm, validateForm, resetForm } =
    useFormik({
      initialValues: {
        suspensionReason: '',
      },
      validateOnChange: true,
      validationSchema: Yup.object({
        suspensionReason: isSuspendMode ? suspensionReasonRule : unSuspensionReasonRule,
      }),
      onSubmit: (onSubmitValues, { setSubmitting }) => {
        onSubmit(onSubmitValues.suspensionReason);
        setSubmitting(false);
      },
    });

  useEffect(() => {
    if (!isOpen) {
      resetForm({
        values: {
          suspensionReason: '',
        },
      });
      validateForm();
    }
  }, [isOpen, resetForm, validateForm]);

  useEffect(() => {
    if (verifyCustodialAccountResult.isSuccess || verifyCustodialAccountResult.isError) {
      onClose();
    }
  }, [verifyCustodialAccountResult.isSuccess, onClose, verifyCustodialAccountResult.isError]);

  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <StyledDialogTitle>
        <Column>
          <Gap size="_24px" />
          {isSuspendMode && (
            <Typography variant="h4">{t('custodialAccountDetailsPage.suspendAccountModal.suspendAccount')}</Typography>
          )}
          {!isSuspendMode && (
            <Typography variant="h4">
              {t('custodialAccountDetailsPage.suspendAccountModal.unSuspendAccount')}
            </Typography>
          )}
        </Column>
        <StyledIconButton onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Column>
          <Gap size="_12px" />
          {isSuspendMode && (
            <StyledTypographyCaption variant="bodyDefaultBook" color="text.secondary">
              {t('custodialAccountDetailsPage.suspendAccountModal.suspendCaption')}
            </StyledTypographyCaption>
          )}
          {!isSuspendMode && (
            <StyledTypographyCaption variant="bodyDefaultBook" color="text.secondary">
              {t('custodialAccountDetailsPage.suspendAccountModal.unSuspendCaption')}
            </StyledTypographyCaption>
          )}
          <Gap size="_24px" />
          {accountName && <StyledCenteredTextTypography variant="h5">{accountName}</StyledCenteredTextTypography>}
          <StyledCenteredTextTypography variant="h5">{accountTitle}</StyledCenteredTextTypography>
          <Gap size="_20px" />
          {isSuspendMode && (
            <Typography variant="buttonMedium">
              {t('custodialAccountDetailsPage.suspendAccountModal.suspensionReason')}
            </Typography>
          )}
          {!isSuspendMode && (
            <Typography variant="buttonMedium">
              {t('custodialAccountDetailsPage.suspendAccountModal.unSuspensionReason')}
            </Typography>
          )}
          <Gap size="_8px" />
          <StyledForm onSubmit={handleSubmit}>
            <TextArea
              name="suspensionReason"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.suspensionReason}
              placeholder={
                isSuspendMode
                  ? t('custodialAccountDetailsPage.suspendAccountModal.suspendTextAreaPlaceholder')
                  : t('custodialAccountDetailsPage.suspendAccountModal.unSuspendTextAreaPlaceholder')
              }
              error={!!errors.suspensionReason && touched.suspensionReason}
              errorText={errors.suspensionReason}
              height="100px"
              width="374px"
            />
          </StyledForm>
        </Column>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton variant="outlined" size="large" onClick={onClose}>
          <Typography variant="buttonMedium">{t('custodialAccountDetailsPage.suspendAccountModal.cancel')}</Typography>
        </StyledButton>
        <Gap isHorizontal size="_4px" />
        {isSuspendMode && (
          <StyledLoadingButtonWithSkeleton
            variant="contained"
            size="large"
            text={
              <Typography variant="buttonMedium" color="background.paper">
                {t('custodialAccountDetailsPage.suspendAccountModal.suspend')}
              </Typography>
            }
            color="error"
            loading={verifyCustodialAccountResult.isLoading}
            onClick={submitForm}
          />
        )}
        {!isSuspendMode && (
          <StyledLoadingButtonWithSkeleton
            variant="contained"
            size="large"
            text={
              <Typography variant="buttonMedium" color="background.paper">
                {t('custodialAccountDetailsPage.suspendAccountModal.unSuspend')}
              </Typography>
            }
            loading={verifyCustodialAccountResult.isLoading}
            onClick={submitForm}
          />
        )}
      </StyledDialogActions>
    </StyledDialog>
  );
};
