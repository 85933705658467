import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Stack } from 'elements/components';
import { CustodialAccount, CustodialAccountTypeEnum } from 'elements/types';
import { formatUsdBalance } from 'elements/utils';
import { ReactComponent as BusinessAccountIcon } from 'elements/element-transfer/assets/icons/businessAccount.svg';
import { ReactComponent as PersonalAccountIcon } from 'elements/element-transfer/assets/icons/personalAccount.svg';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { StyledContainer, StyledIconContainer, StyledTextContainer, StyledTruncatedTypography } from './styled';

type Props = {
  custodialAccount: CustodialAccount;
  horizontalPaddings?: boolean;
};

const DepositTo: React.FC<Props> = ({ custodialAccount, horizontalPaddings }) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  const { type, accountNumber } = custodialAccount;

  const mapAccountTypeToIcon = {
    [CustodialAccountTypeEnum.Business]: <BusinessAccountIcon />,
    [CustodialAccountTypeEnum.Personal]: <PersonalAccountIcon />,
    [CustodialAccountTypeEnum.RothIra]: <PersonalAccountIcon />,
    [CustodialAccountTypeEnum.SepIra]: <PersonalAccountIcon />,
    [CustodialAccountTypeEnum.SimpleIra]: <PersonalAccountIcon />,
    [CustodialAccountTypeEnum.TraditionalIra]: <PersonalAccountIcon />,
  };

  const formDepositToSubtitle = (account: CustodialAccount): string => {
    const mapAccountTypeToTitle = {
      [CustodialAccountTypeEnum.Business]: t('elements.transfer.depositAchAmountPage.businessAccount'),
      [CustodialAccountTypeEnum.Personal]: t('elements.transfer.depositAchAmountPage.personalAccount'),
      [CustodialAccountTypeEnum.RothIra]: t('elements.transfer.depositAchAmountPage.account'),
      [CustodialAccountTypeEnum.SepIra]: t('elements.transfer.depositAchAmountPage.account'),
      [CustodialAccountTypeEnum.SimpleIra]: t('elements.transfer.depositAchAmountPage.account'),
      [CustodialAccountTypeEnum.TraditionalIra]: t('elements.transfer.depositAchAmountPage.account'),
    };

    return `${formatUsdBalance(account.balance, isMobile)} - ${mapAccountTypeToTitle[account.type]}`;
  };

  const postfix = `*${accountNumber.slice(-4)}`;

  return (
    <StyledContainer direction="row" horizontalPaddings={horizontalPaddings}>
      <StyledIconContainer>{mapAccountTypeToIcon[type]}</StyledIconContainer>

      <StyledTextContainer>
        <Typography variant="captionMedium" color="secondary.font">
          {t('elements.transfer.depositAchAmountPage.depositTo')}
        </Typography>
        <Stack direction="row" fullWidth gap={0.5}>
          <StyledTruncatedTypography variant="bodyDefaultMedium" color="primary.font">
            {formDepositToSubtitle(custodialAccount)}
          </StyledTruncatedTypography>
          <Typography variant="bodyDefaultMedium" color="primary.font">
            {postfix}
          </Typography>
        </Stack>
      </StyledTextContainer>
    </StyledContainer>
  );
};

export default DepositTo;
