import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IdentityDetailsPageContent } from 'components/identities/IdentityDetailsPageContent';
import { IdentityDetailsUrlParams } from 'navigation/url-params-type';
import { getUser } from 'redux/api/api-users';
import { useBcIdentityDetailsPage } from 'hooks/breadcrumbs/use-bc-identity-details-page';
import { IdentitiesDetailsPageTabs } from 'ui-enums/tabs/identities/identities-details-page-tabs';
import { IIdentityDetailsPage } from 'ui-interfaces/identity-details/i-identity-details-page';
import { useLazyGetPersonalIdentityDetailsByIdQuery } from 'redux/api/api-personal-identities';
import { useLazyGetBusinessIdentityDetailsByIdQuery } from 'redux/api/api-business-identities';
import { IdentityType } from 'models/enums/identities/identity-type';
import { createPersonalIdentityDetailsViewModel } from 'factories/identities/create-personal-identity-details-view-model';
import { createBusinessIdentityDetailsViewModel } from 'factories/identities/create-business-identity-details-view-model';

export const IdentityDetailsPage: React.FC = () => {
  const { breadCrumbs } = useBcIdentityDetailsPage();
  const { data: user } = useSelector(getUser.select(undefined));
  const [tab, setTab] = useState(IdentitiesDetailsPageTabs.details);
  const handleChange = (event: React.SyntheticEvent, newTab: IdentitiesDetailsPageTabs) => {
    setTab(newTab);
  };

  const params = useParams<IdentityDetailsUrlParams>();
  const { identityId, identityType } = params;

  // identity details by id - start
  const [fetchPersonalIdentityTrigger, personalIdentityResult] = useLazyGetPersonalIdentityDetailsByIdQuery();
  const [fetchBusinessIdentityTrigger, businessIdentityResult] = useLazyGetBusinessIdentityDetailsByIdQuery();

  const [identityDetailsPage, setIdentityDetailsPage] = useState<IIdentityDetailsPage>();

  useEffect(() => {
    if (identityType === IdentityType.personal) {
      fetchPersonalIdentityTrigger(identityId!);
    }
  }, [fetchPersonalIdentityTrigger, identityId, identityType]);

  useEffect(() => {
    if (identityType === IdentityType.business) {
      fetchBusinessIdentityTrigger(identityId!);
    }
  }, [fetchBusinessIdentityTrigger, identityId, identityType]);

  useEffect(() => {
    if (personalIdentityResult.isSuccess && user) {
      setIdentityDetailsPage(createPersonalIdentityDetailsViewModel(personalIdentityResult.data, user));
    }
  }, [personalIdentityResult.data, personalIdentityResult.isSuccess, user]);

  useEffect(() => {
    if (businessIdentityResult.isSuccess && user) {
      setIdentityDetailsPage(createBusinessIdentityDetailsViewModel(businessIdentityResult.data, user));
    }
  }, [businessIdentityResult.data, businessIdentityResult.isSuccess, user]);
  // identity details by id - end

  // handle all page error - start
  const [isDetailsPageError, setIsDetailsPageError] = useState(false);

  useEffect(() => {
    setIsDetailsPageError(personalIdentityResult.isError);
  }, [personalIdentityResult.isError]);

  useEffect(() => {
    setIsDetailsPageError(businessIdentityResult.isError);
  }, [businessIdentityResult.isError]);
  // handle all page error - end

  return (
    <IdentityDetailsPageContent
      tab={tab}
      urlParams={params}
      setIsDetailsPageError={setIsDetailsPageError}
      handleChange={handleChange}
      breadCrumbs={breadCrumbs}
      loading={personalIdentityResult.isFetching || businessIdentityResult.isFetching}
      error={isDetailsPageError}
      identityDetailsPage={identityDetailsPage}
    />
  );
};
