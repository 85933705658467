import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/api/api-users';
import { DashboardRoles } from 'models/enums/user/dashboard-roles';
import { arraysHaveMatchedPermissions } from 'utils/user/arrays-have-matched-permissions';

export const usePermissionBasedAccess = (permissionsAllowingAccess: DashboardRoles[]) => {
  const [isAccessAllowed, setIsAccessAllowed] = useState(false);
  const { data: user } = useSelector(getUser.select(undefined));
  const assignedPermissions = user?.permissions;

  useEffect(() => {
    if (assignedPermissions) {
      setIsAccessAllowed(arraysHaveMatchedPermissions(permissionsAllowingAccess, assignedPermissions));
    }
  }, [permissionsAllowingAccess, assignedPermissions]);

  return isAccessAllowed;
};
