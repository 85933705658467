import { GridRowParams } from '@mui/x-data-grid';
import { NavigateFunction } from 'react-router-dom';
import { getRouteWithParams } from 'utils/get-route-with-params';
import { PATHS } from 'navigation/constants';
import { BusinessIdentityResponseModel } from 'models/response/identities/business/business-identity-response-model';
import { BusinessIdentityRow } from 'ui-interfaces/row-interfaces/identities/business-identity-row';
import { IdentityType } from 'models/enums/identities/identity-type';
import { IdentityDetailsUrlParams } from 'navigation/url-params-type';
import { FilterResult } from 'filters/interfaces/filter-result';
import { DownloadCSV } from 'hooks/use-export/interfaces';
import { API_ROUTES } from 'redux/api/api-routes';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { createBusinessIdentityViewModel } from 'factories/identities/create-business-identity-view-model';

export const onBusinessIdentitiesTransform =
  (user: ShortUserResponseModel) => (businessIdentity: BusinessIdentityResponseModel) =>
    createBusinessIdentityViewModel(businessIdentity, user);

export const onBusinessIdentityRowClick =
  (navigate: NavigateFunction) =>
  (params: GridRowParams<BusinessIdentityRow>): void => {
    const { row } = params;

    navigate(
      getRouteWithParams(PATHS.IDENTITY_DETAILS, {
        identityId: row.id,
        identityType: IdentityType.business,
      } as IdentityDetailsUrlParams),
    );
  };

export const onBusinessIdentitiesExportClick = (downloadCsv: DownloadCSV) => (filterResult?: FilterResult) => {
  downloadCsv({ urlSegment: API_ROUTES.private.BUSINESS_IDENTITIES, filterResult });
};
