import React from 'react';
import { Page as _Page, Document as _Document } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { PdfNode } from 'ui-interfaces/transaction-details/pdf/pdf-node';
import { PdfNodeElement } from './PdfNodeElement';
import { MainTitle } from './MainTitle';
import { MainContainer } from './MainContainer';
import { Header } from './Header';
import { styles } from './styles';

const Document: any = _Document;
const Page: any = _Page;

type Props = {
  nodes: PdfNode[];
};

export const PdfDoc: React.FC<Props> = ({ nodes }) => {
  const { t } = useTranslation();

  return (
    <Document>
      <Page style={styles.page} size="A4">
        <Header />
        <MainContainer>
          <MainTitle value={t('transactionDetailsPage.transactionHeaderLabel')} />
          {nodes.map((node, index, originalArray) => {
            return <PdfNodeElement key={node.id} node={node} hasBottomBorder={index !== originalArray.length - 1} />;
          })}
        </MainContainer>
      </Page>
    </Document>
  );
};
