import { PersonalIdentityDetailsResponseModel } from 'models/response/identities/personal/personal-identity-details-response-model';
import { IdentityDetailsPersonalViewModel } from 'view-models/identities/details/identity/identity-details-personal-vm';
import { IIdentityDetailsPage } from 'ui-interfaces/identity-details/i-identity-details-page';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';

export const createPersonalIdentityDetailsViewModel = (
  personalIdentityResponseModel: PersonalIdentityDetailsResponseModel,
  user: ShortUserResponseModel,
): IIdentityDetailsPage => {
  return new IdentityDetailsPersonalViewModel(personalIdentityResponseModel, user);
};
