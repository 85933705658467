import React from 'react';
import { StyledRow } from 'components/shared/detail-page/header/styled';
import { StatusCellProps } from 'ui-interfaces/statuses/status-cell-props';
import { Gap } from 'components/shared/Gap';
import { StatusCell } from 'components/shared/Cells/StatusCell';
import { StyledTypography } from './styled';

type Props = {
  title: string;
  statusCellProps: StatusCellProps;
  isAccountNameExist?: boolean;
};

export const TitleRow: React.FC<Props> = ({ title, statusCellProps, isAccountNameExist = false }) => {
  return (
    <StyledRow>
      <StyledTypography isAccountNameExist={isAccountNameExist}>{title}</StyledTypography>
      <Gap size="_12px" isHorizontal />
      <StatusCell {...statusCellProps} />
    </StyledRow>
  );
};
