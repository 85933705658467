import React from 'react';
import { Box } from '@mui/material';
import { Gap } from 'components/shared/Gap';
import { Row } from 'components/shared/Flex/Row';
import { CroppedLabel } from 'components/shared/CroppedLabel';
import { StyledRow } from './styled';

type Props = {
  icon: React.ReactNode;
  operation: string;
  method: string;
  maxTextWidth: number;
};

export const CellContent: React.FC<Props> = ({ icon, method, operation, maxTextWidth }) => {
  return (
    <StyledRow>
      {icon}
      <Gap size="_8px" isHorizontal />
      <Box overflow="hidden">
        <Row>
          <CroppedLabel title={operation} maxWidth={maxTextWidth} />
        </Row>
        <Gap size="_2px" />
        <Row>
          <CroppedLabel color="grey.600" title={method} maxWidth={maxTextWidth} />
        </Row>
      </Box>
    </StyledRow>
  );
};
