import React from 'react';
import { ReactComponent as TransferIcon } from 'assets/icons/element-button/transfer.svg';
import { ReactComponent as TradeIcon } from 'assets/icons/element-button/trade.svg';
import { ElementName } from 'elements/models/enums/element-name';

export const getButtonIconByElementName = (elementName: ElementName) => {
  switch (elementName) {
    case ElementName.TRANSFER:
      return <TransferIcon />;
    case ElementName.TRADE:
      return <TradeIcon />;
    default:
      return null;
  }
};
