import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import { omitCustomStyleProps } from 'utils/omitCustomStyleProps';

type StyledBoxProps = {
  hasBottomBorder: boolean;
};

export const StyledBox = styled(
  Box,
  omitCustomStyleProps(['hasBottomBorder']),
)<BoxProps & StyledBoxProps>(({ theme, hasBottomBorder }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderBottom: hasBottomBorder ? `1px solid ${theme.palette.grey['200']}` : 'none',
}));
