import React from 'react';
import { IAction } from 'ui-interfaces/user-details/actions/i-action';
import { ActionButton } from 'ui-enums/user-details/action-button';
import { EditButtonContainer } from 'components/settings/users/UserDetailsPageContent/layout/Actions/containers/EditButtonContainer';
import { RemoveButtonContainer } from 'components/settings/users/UserDetailsPageContent/layout/Actions/containers/RemoveButtonContainer';
import { ResendInvitationButtonContainer } from 'components/settings/users/UserDetailsPageContent/layout/Actions/containers/ResendInvitationButtonContainer';
import { ResetPasswordButtonContainer } from 'components/settings/users/UserDetailsPageContent/layout/Actions/containers/ResetPasswordButtonContainer';
import { IRemoveAction } from 'ui-interfaces/user-details/actions/i-remove-action';
import { IEditAction } from 'ui-interfaces/user-details/actions/i-edit-action';

export const createActionComponent = (action: IAction, userId: string): React.ReactElement => {
  switch (action.buttonType) {
    case ActionButton.edit: {
      const editAction = action as IEditAction;

      return <EditButtonContainer key={editAction.id} userId={userId} formViewModel={editAction.formViewModel} />;
    }

    case ActionButton.remove: {
      const a = action as IRemoveAction;
      return <RemoveButtonContainer key={action.id} userId={userId} destroyModalMainText={a.destroyModalMainText} />;
    }

    case ActionButton.resend:
      return <ResendInvitationButtonContainer key={action.id} userId={userId} />;

    case ActionButton.reset:
      return <ResetPasswordButtonContainer key={action.id} userId={userId} />;

    default: {
      const editAction = action as IEditAction;

      return <EditButtonContainer key={editAction.id} userId={userId} formViewModel={editAction.formViewModel} />;
    }
  }
};
