import React from 'react';
import { PaddedRowBox } from 'components/shared/detail-page/rows/shared/PaddedRowBox';
import { RightCell } from 'components/shared/detail-page/rows/shared/RightCell';
import { IMt103Indent1Row } from 'ui-interfaces/transaction-details/rows/mt103/i-mt103-indent-1-row';
import { LeftCell } from './LeftCell';

type Props = {
  data: IMt103Indent1Row;
  hasBottomBorder?: boolean;
};

export const MT103Indent1Row: React.FC<Props> = ({ data, hasBottomBorder }) => {
  return (
    <PaddedRowBox hasBottomBorder={hasBottomBorder}>
      <LeftCell label={data.label} />
      <RightCell value={data.value} />
    </PaddedRowBox>
  );
};
