import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { StyledIconButton } from './styled';

type Props = {
  isExpanded: boolean;
  onClick?: () => void;
};

export const ToggleButton: React.FC<Props> = ({ isExpanded, onClick }) => {
  return <StyledIconButton onClick={onClick}>{isExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}</StyledIconButton>;
};
