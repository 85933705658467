import React, { useState } from 'react';
import { Element } from 'ui-interfaces/elements/element';
import { ElementResult } from 'elements/models/types/element-result';
import { ElementName } from 'elements/models/enums/element-name';
import { TradeElement } from 'elements/element-trade';
import { TransferElement } from 'elements/element-transfer';
import { LoadingElementButton } from 'components/shared/buttons/LoadingElementButton';

export const CUSTOM_ELEMENTS = {
  [ElementName.TRADE]: TradeElement,
  [ElementName.TRANSFER]: TransferElement,
};

type Props = Omit<Element, 'isNativeElement'> & {
  isSkeletonDisplayed: boolean;
};

export const CustomElementButton: React.FC<Props> = ({
  name,
  elementType,
  elementConfig,
  onDoneCb,
  isSkeletonDisplayed,
  custodialAccountId,
  identityId,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);
  const onClose = (result?: ElementResult) => {
    setIsOpen(false);
    onDoneCb(elementType, result);
  };

  const ElementComponent = CUSTOM_ELEMENTS[name];

  return (
    <>
      <LoadingElementButton name={name} isLoading={false} onClick={onOpen} isSkeletonDisplayed={isSkeletonDisplayed} />
      {isOpen && (
        <ElementComponent
          onClose={onClose}
          identityId={identityId}
          custodialAccountId={custodialAccountId}
          {...elementConfig}
        />
      )}
    </>
  );
};
