import { styled, Button, alpha, buttonClasses } from '@mui/material';

export const StyledButton = styled(Button)(({ theme }) => ({
  color: `${alpha(theme.palette.background.paper, 0.7)} !important`,
  borderColor: `${alpha(theme.palette.background.paper, 0.15)}`,
  ':hover': {
    borderColor: `${alpha(theme.palette.background.paper, 0.25)}`,
  },
  [`&.${buttonClasses.focusVisible}`]: {
    borderColor: `${alpha(theme.palette.background.paper, 0.25)}`,
  },
  ':active': {
    color: `${alpha(theme.palette.background.paper, 0.5)} !important`,
    borderColor: `${alpha(theme.palette.background.paper, 0.1)}`,
  },
}));
