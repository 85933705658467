import React from 'react';
import { IPlainRow } from 'ui-interfaces/transaction-details/pdf/rows/i-plain-row';
import { Row } from '../shared/Row';
import { LeftCell } from '../shared/LeftCell';
import { RightCell } from '../shared/RightCell';

type Props = {
  data: IPlainRow;
};

export const PlainRow: React.FC<Props> = ({ data }) => {
  return (
    <Row>
      <LeftCell value={data.label} />
      <RightCell value={data.value} />
    </Row>
  );
};
