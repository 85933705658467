import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Stack, Typography } from '@mui/material';
import i18n from 'localizations';

import { getStateNameByValue } from 'elements/utils';
import shallowEqualFormData from 'elements/element-transfer/utils/shallowEqualFormData';
import useForm from 'elements/hooks/useForm';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import {
  MAX_CITY_LENGTH,
  MAX_FULL_NAME_LENGTH,
  MAX_POSTAL_CODE_LENGTH,
  MAX_STATE_NON_US_LENGTH,
  MAX_STREET1_LENGTH,
  MAX_STREET2_LENGTH,
  ZIP_CODE_LENGTH,
  useYupRules,
} from 'elements/element-transfer/hooks/useYupRules';
import { withdrawalWireRecipientInfoInitialState as emptyRecipientInfoFormData } from 'elements/element-transfer/contexts/Element/constants';
import { WireLocationType, WireRecipientInfoFormData } from 'elements/element-transfer/types';
import { COUNTRY_CODE_US, rawStatesObjectByCountryCode } from 'elements/constants';

import { BaseInput, BaseSelect, BaseSelectItem, Button, CountryDropdown, Spacer } from 'elements/components';
import BaseFieldset from 'elements/element-transfer/components/BaseFieldset';
import BaseRadioGroup from 'elements/element-transfer/components/BaseRadioGroup';
import BaseStepper from 'elements/element-transfer/components/BaseStepper';
import Layout from 'elements/element-transfer/components/Layout';

import { StyledWideDivider } from './styled';

type Props = {
  initialFormData: WireRecipientInfoFormData;
  onContinue: (data: WireRecipientInfoFormData) => void;
  onClose: () => void;
  onCustomClose: () => void;
  onBack: () => void;
  setIsConfirmCloseRequired: (val: boolean) => void;
};

const statesList = Object.values(rawStatesObjectByCountryCode);
const locationTypeRadioOptions = [
  {
    value: WireLocationType.Domestic,
    label: i18n.t('elements.transfer.withdrawalWireRecipientInfoPage.radioGroupDomesticLabel'),
  },
  {
    value: WireLocationType.International,
    label: i18n.t('elements.transfer.withdrawalWireRecipientInfoPage.radioGroupInternationalLabel'),
  },
];

const WireRecipientInfoPage: React.FC<Props> = ({
  initialFormData,
  onContinue,
  onClose,
  onCustomClose,
  onBack,
  setIsConfirmCloseRequired,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const [isUSA, setIsUSA] = useState(initialFormData.country === COUNTRY_CODE_US);

  const {
    fullNameRule,
    countryRule,
    locationTypeRule,
    street1Rule,
    street2Rule,
    cityRule,
    stateUSRule,
    stateNonUSRule,
    zipCodeRule,
    postalCodeRule,
  } = useYupRules();
  const {
    formik: { values, touched, setValues, setTouched, submitForm, handleSubmit },
    helpers: { getFieldProps, getFieldErrorProps, isFormDisabled },
  } = useForm({
    initialValues: initialFormData,
    enableReinitialize: true,
    yupShape: {
      fullName: fullNameRule,
      country: countryRule,
      street1: street1Rule,
      street2: street2Rule('elements.transfer.withdrawalWireValidation.apartment'),
      city: cityRule,
      state: isUSA ? stateUSRule : stateNonUSRule,
      postalCode: isUSA ? zipCodeRule : postalCodeRule,
      locationType: locationTypeRule,
    },
    onSubmit: (formValues, { setSubmitting }) => {
      onContinue(formValues);
      setSubmitting(false);
    },
  });

  const isUSAOrEmpty = useMemo(() => isUSA || !values.country, [isUSA, values.country]);

  const handleSetCountryValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isUSAKey = event.target.value === COUNTRY_CODE_US;
    const isNeedToReset = !(isUSAKey && !values.country);

    setValues(prev => ({
      ...prev,
      state: isNeedToReset ? initialFormData.state : prev.state,
      postalCode: isNeedToReset ? initialFormData.postalCode : prev.postalCode,
      country: event.target.value,
    }));

    if (isNeedToReset) {
      setTouched({
        ...touched,
        state: false,
        postalCode: false,
      });
    }
    setIsUSA(isUSAKey);
  };

  const isFormEmpty = useMemo(() => {
    return shallowEqualFormData(values, emptyRecipientInfoFormData);
  }, [values]);

  useEffect(() => {
    setIsConfirmCloseRequired(!isFormEmpty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormEmpty]);

  return (
    <Layout
      title={t('elements.transfer.customizableLabels.withdrawalWireRecipientInfoPageTitle')}
      onClose={!isFormEmpty ? onCustomClose : onClose}
      onBack={onBack}
      isAbove
      footerButtons={
        <Stack gap="16px">
          <BaseStepper variant="dots" position="static" steps={4} activeStep={0} backButton={null} nextButton={null} />
          <Button type="submit" disabled={isFormDisabled} onClick={submitForm}>
            <Typography variant="buttonLarge">{t('elements.common.continue')}</Typography>
            <ArrowForwardIcon />
          </Button>
        </Stack>
      }
    >
      <Stack component="form" onSubmit={handleSubmit} mb="24px">
        <BaseFieldset>
          <Typography component="legend" variant="overlineDefaultMedium" color="secondary.font">
            {t('elements.transfer.withdrawalWireRecipientInfoPage.fieldsHeader')}
          </Typography>
          <BaseInput
            label={t('elements.transfer.withdrawalWireRecipientInfoPage.fullNameLabel')}
            {...getFieldProps('fullName')}
            {...getFieldErrorProps('fullName')}
            maxLength={MAX_FULL_NAME_LENGTH}
          />
          <CountryDropdown
            label={t('elements.transfer.withdrawalWireRecipientInfoPage.countryLabel')}
            {...getFieldProps('country')}
            onChange={handleSetCountryValue}
            searchPlaceholder={t('elements.transfer.withdrawalWireRecipientInfoPage.countrySearchLabel')}
            fullWidth
          />
          <BaseInput
            label={t('elements.transfer.withdrawalWireRecipientInfoPage.street1Label')}
            {...getFieldProps('street1')}
            {...getFieldErrorProps('street1')}
            maxLength={MAX_STREET1_LENGTH}
          />
          <BaseInput
            label={t('elements.transfer.withdrawalWireRecipientInfoPage.street2Label')}
            {...getFieldProps('street2')}
            {...getFieldErrorProps('street2')}
            maxLength={MAX_STREET2_LENGTH}
          />
          <BaseInput
            label={t('elements.transfer.withdrawalWireRecipientInfoPage.cityLabel')}
            {...getFieldProps('city')}
            {...getFieldErrorProps('city')}
            maxLength={MAX_CITY_LENGTH}
          />
          <Stack direction="row" spacing="24px">
            {isUSAOrEmpty ? (
              <BaseSelect
                label={t('elements.transfer.withdrawalWireRecipientInfoPage.stateLabel')}
                {...getFieldProps('state')}
                topDropdown
                renderValueHandler={value => (
                  <Typography variant="bodyDefaultBook" color="primary.font">
                    {getStateNameByValue(value)}
                  </Typography>
                )}
              >
                {statesList.map(({ value, name }) => (
                  <BaseSelectItem value={value} key={value}>
                    <Typography variant="bodyDefaultBook" color="primary.font">
                      {name}
                    </Typography>
                  </BaseSelectItem>
                ))}
              </BaseSelect>
            ) : (
              <BaseInput
                label={t('elements.transfer.withdrawalWireRecipientInfoPage.provinceLabel')}
                {...getFieldProps('state')}
                {...getFieldErrorProps('state')}
                maxLength={MAX_STATE_NON_US_LENGTH}
              />
            )}
            <BaseInput
              label={
                isUSAOrEmpty
                  ? t('elements.transfer.withdrawalWireRecipientInfoPage.zipCode')
                  : t('elements.transfer.withdrawalWireRecipientInfoPage.postalCode')
              }
              {...getFieldProps('postalCode')}
              {...getFieldErrorProps('postalCode')}
              maxLength={isUSAOrEmpty ? ZIP_CODE_LENGTH : MAX_POSTAL_CODE_LENGTH}
            />
          </Stack>
        </BaseFieldset>
        <Spacer size={31} />
        <StyledWideDivider isMobile={isMobile} />
        <Spacer size={21} />
        <BaseRadioGroup
          header={t('elements.transfer.withdrawalWireRecipientInfoPage.radioGroupHeader')}
          labelledby="international-or-domestic-bank-radio-group"
          values={locationTypeRadioOptions}
          {...getFieldProps('locationType')}
        />
      </Stack>
    </Layout>
  );
};

export default WireRecipientInfoPage;
