import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { Backdrop, Typography, useTheme } from '@mui/material';
import { Button } from 'elements/components';
import { CustodialAccount, PaymentAccount } from 'elements/types';
import { ReactComponent as RoundedDollarIcon } from 'elements/element-transfer/assets/icons/roundedDollar.svg';
import DepositInfo from 'elements/element-transfer/components/Deposit/DepositInfo';
import Layout from 'elements/element-transfer/components/Layout';
import { ElementsCustomTheme } from 'elements/theme/createCustomTheme';
import { StyledInlineBanner } from './styled';

type Props = {
  depositAmount: number;
  isProcessingLoading: boolean;
  isProcessingError?: boolean;
  processingErrorMessage?: string;
  onBack: () => void;
  onClose: () => void;
  onDeposit: () => void;
  paymentAccount: PaymentAccount | null;
  custodialAccount: CustodialAccount | null;
};

const AchReviewPage: React.FC<Props> = ({
  depositAmount,
  isProcessingLoading,
  isProcessingError,
  processingErrorMessage,
  onBack,
  onClose,
  onDeposit,
  paymentAccount,
  custodialAccount,
}) => {
  const { t } = useTranslation();
  const theme = useTheme<ElementsCustomTheme>();

  return (
    <Layout
      title={t('elements.transfer.customizableLabels.depositAchReviewPageTitle')}
      onBack={onBack}
      onClose={onClose}
      footerButtons={
        <Button isLoading={isProcessingLoading} onClick={onDeposit} endIcon={<CheckIcon />}>
          <Typography variant="buttonLarge">
            {t('elements.transfer.depositAchReviewPage.depositButton', { depositAmount })}
          </Typography>
        </Button>
      }
    >
      {isProcessingError && (
        <StyledInlineBanner
          text={processingErrorMessage || t('elements.transfer.depositAchReviewPage.processingError')}
        />
      )}
      <Backdrop
        open={isProcessingLoading}
        sx={{ backgroundColor: `${theme.palette.background.whiteTransparent50}`, zIndex: 5 }}
      />
      <DepositInfo
        depositAmount={depositAmount}
        icon={RoundedDollarIcon}
        paymentAccount={paymentAccount}
        custodialAccount={custodialAccount}
      />
    </Layout>
  );
};

export default AchReviewPage;
