import {
  DepositCoin,
  TransferDirectionEnum,
  TransferFlow,
  WireAccountNumbersFormData,
  WireBankInfoFormData,
  WireInstructionsState,
  WireLocationType,
  WireRecipientInfoFormData,
  WireWithdrawalAdditionalDetails,
} from 'elements/element-transfer/types';
import { COUNTRY_CODE_US } from 'elements/constants';
import {
  CustodialAccount,
  CustomFormFieldError,
  ExternalAccount,
  ExternalAccountTypeEnum,
  IdentityData,
  PaymentAccount,
} from 'elements/types';
import {
  recipientsInitialState,
  withdrawalWireAccountNumbersInitialState,
  withdrawalWireBankInfoInitialState,
  withdrawalWireInitialState,
  withdrawalWireRecipientInfoInitialState,
} from 'elements/element-transfer/contexts/Element/constants';
import { mapWireExternalAccountTypeToLocationType } from 'elements/element-transfer/utils/wireWithdrawalUtils/mapWireExternalAccountTypeToLocationType';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { getPaymentMethodTypesMap } from 'elements/features/PaymentMethods';
import { getTransferOptionsList } from 'elements/element-transfer/utils/getTransferOptionsList';
import { PaymentType } from 'ui-enums/response/transactions/payment-type';
import { AssetResponseModel } from 'models/response/assets/asset-response-model';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import { ACTION, ActionType, RecipientsState, StateType } from './types';

// common
const setFlow = (value: TransferFlow): ActionType => ({
  type: ACTION.SET_FLOW,
  payload: value,
});

const setIsOrderPlaced = (value: boolean): ActionType => ({
  type: ACTION.SET_IS_ORDER_PLACED,
  payload: value,
});

const setCustodialAccount = (value: CustodialAccount): ActionType => ({
  type: ACTION.SET_CUSTODIAL_ACCOUNT,
  payload: value,
});

const setIdentityData = (value: IdentityData): ActionType => ({
  type: ACTION.SET_IDENTITY_DATA,
  payload: value,
});

const setAchDepositPaymentId = (value: string): ActionType => ({
  type: ACTION.SET_ACH_DEPOSIT_PAYMENT_ID,
  payload: value,
});
const setCryptoWithdrawalPaymentId = (value: string): ActionType => ({
  type: ACTION.SET_CRYPTO_WITHDRAWAL_PAYMENT_ID,
  payload: value,
});
const setWireDomesticWithdrawalPaymentId = (value: string): ActionType => ({
  type: ACTION.SET_WIRE_DOMESTIC_WITHDRAWAL_PAYMENT_ID,
  payload: value,
});
const setWireInternationalWithdrawalPaymentId = (value: string): ActionType => ({
  type: ACTION.SET_WIRE_INTERNATIONAL_WITHDRAWAL_PAYMENT_ID,
  payload: value,
});
const setPage = (location: Paths, state?: any): ActionType => ({
  type: ACTION.SET_PAGE,
  payload: {
    location,
    state: state || null,
  },
});
const setPaymentMethodTypesMap = (paymentMethodTypes: string): ActionType => ({
  type: ACTION.SET_PAYMENT_METHOD_TYPES_MAP,
  payload: getPaymentMethodTypesMap(paymentMethodTypes),
});
const setTransferOptionsList = (paymentMethodTypes: string): ActionType => ({
  type: ACTION.SET_TRANSFER_OPTIONS_LIST,
  payload: getTransferOptionsList(paymentMethodTypes),
});
const setTransferDirection = (transferDirection: TransferDirectionEnum): ActionType => ({
  type: ACTION.SET_TRANSFER_DIRECTION,
  payload: transferDirection,
});
const setLockId = (lockId: string): ActionType => ({
  type: ACTION.SET_LOCK_ID,
  payload: lockId,
});
const setPaymentType = (paymentType: PaymentType): ActionType => ({
  type: ACTION.SET_PAYMENT_TYPE,
  payload: paymentType,
});

// deposit ach
const setDepositAchAmount = (value: string): ActionType => ({
  type: ACTION.SET_DEPOSIT_ACH_AMOUNT,
  payload: value,
});

const setDepositAchExternalAccountList = (value: ExternalAccount[]): ActionType => ({
  type: ACTION.SET_DEPOSIT_ACH_EXTERNAL_ACCOUNTS,
  payload: value,
});

const setDepositAchPaymentAccount = (value: PaymentAccount): ActionType => ({
  type: ACTION.SET_DEPOSIT_ACH_PAYMENT_ACCOUNT,
  payload: value,
});

// deposit wire
const setDepositWireInstructions = (value: WireInstructionsState | null): ActionType => ({
  type: ACTION.SET_DEPOSIT_WIRE_INSTRUCTIONS,
  payload: value,
});

// deposit crypto
const setDepositCryptoCoinList = (value: DepositCoin[]): ActionType => ({
  type: ACTION.SET_DEPOSIT_CRYPTO_COIN_LIST,
  payload: value,
});

const setDepositCryptoSelectedCoin = (value: DepositCoin | null): ActionType => ({
  type: ACTION.SET_DEPOSIT_CRYPTO_SELECTED_COIN,
  payload: value,
});

const setDepositSelectedAsset = (value: AssetResponseModel | null): ActionType => ({
  type: ACTION.SET_DEPOSIT_SELECTED_ASSET,
  payload: value,
});

const setDepositCryptoWalletAddress = (value: string): ActionType => ({
  type: ACTION.SET_DEPOSIT_CRYPTO_WALLET_ADDRESS,
  payload: value,
});

// withdrawal wire
const setWithdrawalWireAmount = (value: string): ActionType => ({
  type: ACTION.SET_WITHDRAWAL_WIRE_AMOUNT,
  payload: value,
});

const setWithdrawalWireRecipientInfo = (value: WireRecipientInfoFormData): ActionType => ({
  type: ACTION.SET_WITHDRAWAL_WIRE_RECIPIENT_INFO,
  payload: value,
});

const resetWithdrawalWireRecipientInfo = (): ActionType => ({
  type: ACTION.RESET_WITHDRAWAL_WIRE_RECIPIENT_INFO,
});

const setWithdrawalWireBankInfo = (value: WireBankInfoFormData): ActionType => ({
  type: ACTION.SET_WITHDRAWAL_WIRE_BANK_INFO,
  payload: value,
});

const resetWithdrawalWireBankInfo = (): ActionType => ({
  type: ACTION.RESET_WITHDRAWAL_WIRE_BANK_INFO,
});

const setWithdrawalDomesticWireAccountNumbers = (value: WireAccountNumbersFormData): ActionType => ({
  type: ACTION.SET_WITHDRAWAL_DOMESTIC_WIRE_ACCOUNT_NUMBERS,
  payload: value,
});

const resetWithdrawalWireAccountNumbers = (): ActionType => ({
  type: ACTION.RESET_WITHDRAWAL_WIRE_ACCOUNT_NUMBERS,
});

const setWithdrawalWireRoutingNumberCustomError = (value: CustomFormFieldError | null): ActionType => ({
  type: ACTION.SET_WITHDRAWAL_WIRE_ROUTING_NUMBER_CUSTOM_ERROR,
  payload: value,
});

const setWithdrawalWireAdditionalDetails = (value: WireWithdrawalAdditionalDetails): ActionType => ({
  type: ACTION.SET_WITHDRAWAL_WIRE_ADDITIONAL_DETAILS,
  payload: value,
});

const setWithdrawalWireCloseConfirmationRequired = (value: boolean): ActionType => ({
  type: ACTION.SET_WITHDRAWAL_WIRE_CLOSE_CONFIRMATION_REQUIRED,
  payload: value,
});

const resetWithdrawalWireState = (): ActionType => ({ type: ACTION.RESET_WITHDRAWAL_WIRE_STATE });

const setWithdrawalWireRecipientSearchValue = (value: string): ActionType => ({
  type: ACTION.SET_WITHDRAWAL_WIRE_RECIPIENT_SEARCH_VALUE,
  payload: value,
});

const setWithdrawalWireRecipients = (value: RecipientsState): ActionType => ({
  type: ACTION.SET_WITHDRAWAL_WIRE_RECIPIENTS,
  payload: value,
});

const resetWithdrawalWireRecipients = (): ActionType => ({
  type: ACTION.RESET_WITHDRAWAL_WIRE_RECIPIENTS,
});

const mergeWithdrawalWireRecipientData = (value: ExternalAccount) => ({
  type: ACTION.MERGE_WITHDRAWAL_WIRE_RECIPIENT_DATA,
  payload: value,
});

const resetWithdrawalWireRecipientData = (): ActionType => ({
  type: ACTION.RESET_WITHDRAWAL_WIRE_RECIPIENT_DATA,
});

// withdrawal crypto
const setWithdrawalCryptoCoinList = (value: CustodialAccountBalanceModel[]): ActionType => ({
  type: ACTION.SET_WITHDRAWAL_CRYPTO_COIN_LIST,
  payload: value,
});

const setWithdrawalCryptoSelectedCoin = (value: CustodialAccountBalanceModel | null): ActionType => ({
  type: ACTION.SET_WITHDRAWAL_CRYPTO_SELECTED_COIN,
  payload: value,
});

const setWithdrawalCryptoCoinAmount = (value: string): ActionType => ({
  type: ACTION.SET_WITHDRAWAL_CRYPTO_COIN_AMOUNT,
  payload: value,
});

const setWithdrawalCryptoWalletAddress = (value: string): ActionType => ({
  type: ACTION.SET_WITHDRAWAL_CRYPTO_WALLET_ADDRESS,
  payload: value,
});

const setWithdrawalCryptoDestinationTag = (value: string): ActionType => ({
  type: ACTION.SET_WITHDRAWAL_CRYPTO_DESTINATION_TAG,
  payload: value,
});

export const elementReducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    // deposit ach
    case ACTION.SET_DEPOSIT_ACH_AMOUNT: {
      return {
        ...state,
        [TransferFlow.Deposit]: {
          ...state[TransferFlow.Deposit],
          ACH: {
            ...state[TransferFlow.Deposit].ACH,
            amount: action.payload,
          },
        },
      };
    }
    case ACTION.SET_DEPOSIT_ACH_EXTERNAL_ACCOUNTS: {
      return {
        ...state,
        [TransferFlow.Deposit]: {
          ...state[TransferFlow.Deposit],
          ACH: {
            ...state[TransferFlow.Deposit].ACH,
            externalAccountList: action.payload,
          },
        },
      };
    }
    case ACTION.SET_DEPOSIT_ACH_PAYMENT_ACCOUNT: {
      return {
        ...state,
        [TransferFlow.Deposit]: {
          ...state[TransferFlow.Deposit],
          ACH: {
            ...state[TransferFlow.Deposit].ACH,
            paymentAccount: action.payload,
          },
        },
      };
    }

    // deposit wire
    case ACTION.SET_DEPOSIT_WIRE_INSTRUCTIONS: {
      return {
        ...state,
        [TransferFlow.Deposit]: {
          ...state[TransferFlow.Deposit],
          WIRE: {
            ...state[TransferFlow.Deposit].WIRE,
            instructions: action.payload,
          },
        },
      };
    }

    // deposit crypto
    case ACTION.SET_DEPOSIT_CRYPTO_COIN_LIST: {
      return {
        ...state,
        [TransferFlow.Deposit]: {
          ...state[TransferFlow.Deposit],
          CRYPTO: {
            ...state[TransferFlow.Deposit].CRYPTO,
            coinList: action.payload,
          },
        },
      };
    }

    case ACTION.SET_DEPOSIT_CRYPTO_SELECTED_COIN: {
      return {
        ...state,
        [TransferFlow.Deposit]: {
          ...state[TransferFlow.Deposit],
          CRYPTO: {
            ...state[TransferFlow.Deposit].CRYPTO,
            selectedCoin: action.payload,
          },
        },
      };
    }

    case ACTION.SET_DEPOSIT_SELECTED_ASSET: {
      return {
        ...state,
        [TransferFlow.Deposit]: {
          ...state[TransferFlow.Deposit],
          CRYPTO: {
            ...state[TransferFlow.Deposit].CRYPTO,
            selectedAsset: action.payload,
          },
        },
      };
    }

    case ACTION.SET_DEPOSIT_CRYPTO_WALLET_ADDRESS: {
      return {
        ...state,
        [TransferFlow.Deposit]: {
          ...state[TransferFlow.Deposit],
          CRYPTO: {
            ...state[TransferFlow.Deposit].CRYPTO,
            walletAddress: action.payload,
          },
        },
      };
    }

    // withdrawal wire
    case ACTION.SET_WITHDRAWAL_WIRE_AMOUNT: {
      return {
        ...state,
        [TransferFlow.Withdrawal]: {
          ...state[TransferFlow.Withdrawal],
          WIRE: {
            ...state[TransferFlow.Withdrawal].WIRE,
            amount: action.payload,
          },
        },
      };
    }
    case ACTION.SET_WITHDRAWAL_WIRE_RECIPIENT_INFO: {
      return {
        ...state,
        [TransferFlow.Withdrawal]: {
          ...state[TransferFlow.Withdrawal],
          WIRE: {
            ...state[TransferFlow.Withdrawal].WIRE,
            recipientInfo: action.payload,
          },
        },
      };
    }
    case ACTION.RESET_WITHDRAWAL_WIRE_RECIPIENT_INFO: {
      return {
        ...state,
        [TransferFlow.Withdrawal]: {
          ...state[TransferFlow.Withdrawal],
          WIRE: {
            ...state[TransferFlow.Withdrawal].WIRE,
            recipientInfo: withdrawalWireRecipientInfoInitialState,
          },
        },
      };
    }
    case ACTION.SET_WITHDRAWAL_WIRE_BANK_INFO: {
      return {
        ...state,
        [TransferFlow.Withdrawal]: {
          ...state[TransferFlow.Withdrawal],
          WIRE: {
            ...state[TransferFlow.Withdrawal].WIRE,
            bankInfo: action.payload,
          },
        },
      };
    }
    case ACTION.RESET_WITHDRAWAL_WIRE_BANK_INFO: {
      return {
        ...state,
        [TransferFlow.Withdrawal]: {
          ...state[TransferFlow.Withdrawal],
          WIRE: {
            ...state[TransferFlow.Withdrawal].WIRE,
            bankInfo: {
              ...withdrawalWireBankInfoInitialState,
              country:
                state[TransferFlow.Withdrawal].WIRE.recipientInfo.locationType === WireLocationType.Domestic
                  ? COUNTRY_CODE_US
                  : '',
            },
          },
        },
      };
    }
    case ACTION.SET_WITHDRAWAL_DOMESTIC_WIRE_ACCOUNT_NUMBERS: {
      return {
        ...state,
        [TransferFlow.Withdrawal]: {
          ...state[TransferFlow.Withdrawal],
          WIRE: {
            ...state[TransferFlow.Withdrawal].WIRE,
            accountNumbers: action.payload,
          },
        },
      };
    }
    case ACTION.RESET_WITHDRAWAL_WIRE_ACCOUNT_NUMBERS: {
      return {
        ...state,
        [TransferFlow.Withdrawal]: {
          ...state[TransferFlow.Withdrawal],
          WIRE: {
            ...state[TransferFlow.Withdrawal].WIRE,
            accountNumbers: withdrawalWireAccountNumbersInitialState,
          },
        },
      };
    }
    case ACTION.SET_WITHDRAWAL_WIRE_ROUTING_NUMBER_CUSTOM_ERROR: {
      return {
        ...state,
        [TransferFlow.Withdrawal]: {
          ...state[TransferFlow.Withdrawal],
          WIRE: {
            ...state[TransferFlow.Withdrawal].WIRE,
            routingNumberCustomError: action.payload,
          },
        },
      };
    }
    case ACTION.SET_WITHDRAWAL_WIRE_ADDITIONAL_DETAILS: {
      return {
        ...state,
        [TransferFlow.Withdrawal]: {
          ...state[TransferFlow.Withdrawal],
          WIRE: {
            ...state[TransferFlow.Withdrawal].WIRE,
            note: action.payload.note,
            memo: action.payload.memo,
            purposeOfPayment: action.payload.purposeOfPayment,
          },
        },
      };
    }
    case ACTION.SET_WITHDRAWAL_WIRE_CLOSE_CONFIRMATION_REQUIRED: {
      return {
        ...state,
        [TransferFlow.Withdrawal]: {
          ...state[TransferFlow.Withdrawal],
          WIRE: {
            ...state[TransferFlow.Withdrawal].WIRE,
            isCloseConfirmationRequired: action.payload,
          },
        },
      };
    }
    case ACTION.RESET_WITHDRAWAL_WIRE_STATE: {
      return {
        ...state,
        [TransferFlow.Withdrawal]: {
          ...state[TransferFlow.Withdrawal],
          WIRE: withdrawalWireInitialState,
        },
      };
    }
    case ACTION.SET_WITHDRAWAL_WIRE_RECIPIENT_SEARCH_VALUE: {
      return {
        ...state,
        [TransferFlow.Withdrawal]: {
          ...state[TransferFlow.Withdrawal],
          WIRE: {
            ...state[TransferFlow.Withdrawal].WIRE,
            search: action.payload,
          },
        },
      };
    }
    case ACTION.SET_WITHDRAWAL_WIRE_RECIPIENTS: {
      return {
        ...state,
        [TransferFlow.Withdrawal]: {
          ...state[TransferFlow.Withdrawal],
          WIRE: {
            ...state[TransferFlow.Withdrawal].WIRE,
            recipients: {
              ...action.payload,
              list: [...state[TransferFlow.Withdrawal].WIRE.recipients.list, ...action.payload.list],
            },
          },
        },
      };
    }
    case ACTION.RESET_WITHDRAWAL_WIRE_RECIPIENTS: {
      return {
        ...state,
        [TransferFlow.Withdrawal]: {
          ...state[TransferFlow.Withdrawal],
          WIRE: {
            ...state[TransferFlow.Withdrawal].WIRE,
            recipients: recipientsInitialState,
          },
        },
      };
    }

    case ACTION.MERGE_WITHDRAWAL_WIRE_RECIPIENT_DATA: {
      return {
        ...state,
        [TransferFlow.Withdrawal]: {
          ...state[TransferFlow.Withdrawal],
          WIRE: {
            ...state[TransferFlow.Withdrawal].WIRE,
            recipientId: action.payload.id,
            recipientInfo: {
              ...state[TransferFlow.Withdrawal].WIRE.recipientInfo,
              fullName: action.payload.name || '',
              locationType:
                mapWireExternalAccountTypeToLocationType[
                  action.payload.type as
                    | ExternalAccountTypeEnum.WireExternalAccount
                    | ExternalAccountTypeEnum.InternationalWireExternalAccount
                ],
            },
            bankInfo: {
              ...state[TransferFlow.Withdrawal].WIRE.bankInfo,
              bankName: action.payload.bankName || '',
              country: action.payload.country || '',
            },
            accountNumbers: {
              ...state[TransferFlow.Withdrawal].WIRE.accountNumbers,
              accountNumber: action.payload.last4,
            },
          },
        },
      };
    }
    case ACTION.RESET_WITHDRAWAL_WIRE_RECIPIENT_DATA: {
      return {
        ...state,
        [TransferFlow.Withdrawal]: {
          ...state[TransferFlow.Withdrawal],
          WIRE: {
            ...state[TransferFlow.Withdrawal].WIRE,
            recipientId: '',
            recipientInfo: withdrawalWireRecipientInfoInitialState,
            bankInfo: withdrawalWireBankInfoInitialState,
            accountNumbers: withdrawalWireAccountNumbersInitialState,
            amount: '',
            memo: '',
            note: '',
            purposeOfPayment: '',
          },
        },
      };
    }

    // withdrawal crypto
    case ACTION.SET_WITHDRAWAL_CRYPTO_COIN_LIST: {
      return {
        ...state,
        [TransferFlow.Withdrawal]: {
          ...state[TransferFlow.Withdrawal],
          CRYPTO: {
            ...state[TransferFlow.Withdrawal].CRYPTO,
            coinList: action.payload,
          },
        },
      };
    }
    case ACTION.SET_WITHDRAWAL_CRYPTO_SELECTED_COIN: {
      return {
        ...state,
        [TransferFlow.Withdrawal]: {
          ...state[TransferFlow.Withdrawal],
          CRYPTO: {
            ...state[TransferFlow.Withdrawal].CRYPTO,
            selectedCoin: action.payload,
          },
        },
      };
    }
    case ACTION.SET_WITHDRAWAL_CRYPTO_COIN_AMOUNT: {
      return {
        ...state,
        [TransferFlow.Withdrawal]: {
          ...state[TransferFlow.Withdrawal],
          CRYPTO: {
            ...state[TransferFlow.Withdrawal].CRYPTO,
            amount: action.payload,
          },
        },
      };
    }
    case ACTION.SET_WITHDRAWAL_CRYPTO_WALLET_ADDRESS: {
      return {
        ...state,
        [TransferFlow.Withdrawal]: {
          ...state[TransferFlow.Withdrawal],
          CRYPTO: {
            ...state[TransferFlow.Withdrawal].CRYPTO,
            walletAddress: action.payload,
          },
        },
      };
    }
    case ACTION.SET_WITHDRAWAL_CRYPTO_DESTINATION_TAG: {
      return {
        ...state,
        [TransferFlow.Withdrawal]: {
          ...state[TransferFlow.Withdrawal],
          CRYPTO: {
            ...state[TransferFlow.Withdrawal].CRYPTO,
            destinationTag: action.payload,
          },
        },
      };
    }

    // common
    case ACTION.SET_CUSTODIAL_ACCOUNT: {
      return {
        ...state,
        custodialAccount: action.payload,
      };
    }
    case ACTION.SET_IDENTITY_DATA: {
      return {
        ...state,
        identityData: action.payload,
      };
    }
    case ACTION.SET_FLOW: {
      return {
        ...state,
        flowType: action.payload,
      };
    }
    case ACTION.SET_IS_ORDER_PLACED: {
      return {
        ...state,
        isOrderPlaced: action.payload,
      };
    }
    case ACTION.SET_ACH_DEPOSIT_PAYMENT_ID: {
      return {
        ...state,
        achDepositPaymentId: action.payload,
      };
    }
    case ACTION.SET_CRYPTO_WITHDRAWAL_PAYMENT_ID: {
      return {
        ...state,
        cryptoWithdrawalPaymentId: action.payload,
      };
    }
    case ACTION.SET_WIRE_DOMESTIC_WITHDRAWAL_PAYMENT_ID: {
      return {
        ...state,
        wireDomesticWithdrawalPaymentId: action.payload,
      };
    }
    case ACTION.SET_WIRE_INTERNATIONAL_WITHDRAWAL_PAYMENT_ID: {
      return {
        ...state,
        wireInternationalWithdrawalPaymentId: action.payload,
      };
    }

    case ACTION.SET_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }

    case ACTION.SET_PAYMENT_METHOD_TYPES_MAP: {
      return {
        ...state,
        paymentMethodTypesMap: action.payload,
      };
    }

    case ACTION.SET_TRANSFER_OPTIONS_LIST: {
      return {
        ...state,
        transferOptionsList: action.payload,
      };
    }

    case ACTION.SET_TRANSFER_DIRECTION: {
      return {
        ...state,
        transferDirection: action.payload,
      };
    }

    case ACTION.SET_LOCK_ID: {
      return {
        ...state,
        lockId: action.payload,
      };
    }

    case ACTION.SET_PAYMENT_TYPE: {
      return {
        ...state,
        paymentType: action.payload,
      };
    }

    default: {
      throw new Error('Unknown action.');
    }
  }
};

export {
  setFlow,
  setIsOrderPlaced,
  setCustodialAccount,
  setIdentityData,
  setAchDepositPaymentId,
  setCryptoWithdrawalPaymentId,
  setWireDomesticWithdrawalPaymentId,
  setWireInternationalWithdrawalPaymentId,
  setPage,
  setPaymentMethodTypesMap,
  setTransferOptionsList,
  setTransferDirection,
  setLockId,
  setPaymentType,
  setDepositAchAmount,
  setDepositAchExternalAccountList,
  setDepositAchPaymentAccount,
  setDepositWireInstructions,
  setDepositCryptoCoinList,
  setDepositCryptoSelectedCoin,
  setDepositSelectedAsset,
  setDepositCryptoWalletAddress,
  setWithdrawalWireRecipientInfo,
  resetWithdrawalWireRecipientInfo,
  setWithdrawalWireBankInfo,
  resetWithdrawalWireBankInfo,
  setWithdrawalDomesticWireAccountNumbers,
  resetWithdrawalWireAccountNumbers,
  setWithdrawalWireRoutingNumberCustomError,
  setWithdrawalWireAmount,
  setWithdrawalWireCloseConfirmationRequired,
  setWithdrawalWireAdditionalDetails,
  resetWithdrawalWireState,
  setWithdrawalWireRecipientSearchValue,
  setWithdrawalWireRecipients,
  resetWithdrawalWireRecipients,
  mergeWithdrawalWireRecipientData,
  resetWithdrawalWireRecipientData,
  setWithdrawalCryptoSelectedCoin,
  setWithdrawalCryptoCoinList,
  setWithdrawalCryptoCoinAmount,
  setWithdrawalCryptoWalletAddress,
  setWithdrawalCryptoDestinationTag,
};
