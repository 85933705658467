import React from 'react';
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { elementsCustomTheme } from './createCustomTheme';

const ThemeProvider: React.FC = ({ children }) => {
  return (
    <MuiThemeProvider theme={elementsCustomTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export { ThemeProvider };
