import i18n from 'localizations';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';

export const getCryptoTooltipMessage = (status: TransactionStatus): string | undefined => {
  switch (status.toLowerCase()) {
    case TransactionStatus.pending.toLowerCase():
      return i18n.t('transactionsPage.crypto.buy.tooltipMessage.pending');
    case TransactionStatus.processing.toLowerCase():
      return i18n.t('transactionsPage.crypto.buy.tooltipMessage.processing');
    default:
      return undefined;
  }
};
