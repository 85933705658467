import { Filter } from 'filters/interfaces/filter';
import { ValueFormatter } from 'filters/value-formatters/interfaces/value-formatter';

export interface SingleValueFilterParams {
  property: string;
  displayProperty: string;
  operators: string[];
  operatorsDisplayValues: string[];
  initialOperator: string;
  initialValue: string;
  valueFormatter?: ValueFormatter;
}

export class SingleValueFilter implements Filter {
  private property: string; // properties on BE

  private displayProperty: string;

  private readonly operators: string[]; // BE filter operators

  private readonly operatorsDisplayValues: string[];

  private selectedOperator: string;

  private readonly defaultOperator: string;

  private readonly defaultValue: string;

  private value: string;

  private readonly valueFormatter?: ValueFormatter;

  constructor(singleValueFilterParams: SingleValueFilterParams) {
    this.property = singleValueFilterParams.property;
    this.displayProperty = singleValueFilterParams.displayProperty;
    this.operators = singleValueFilterParams.operators;
    this.operatorsDisplayValues = singleValueFilterParams.operatorsDisplayValues;
    this.selectedOperator = singleValueFilterParams.initialOperator;
    this.defaultOperator = singleValueFilterParams.initialOperator;
    this.value = singleValueFilterParams.initialValue;
    this.defaultValue = singleValueFilterParams.initialValue;
    this.valueFormatter = singleValueFilterParams.valueFormatter;
  }

  getProperty() {
    return this.property;
  }

  getDisplayProperty(): string {
    return this.displayProperty;
  }

  getSelectedOperator() {
    return this.selectedOperator;
  }

  setSelectedOperator(operator: string) {
    if (this.operators.includes(operator)) {
      this.selectedOperator = operator;
    }
  }

  getAllOperators(): string[] {
    return this.operators;
  }

  getAllOperatorsDisplayValues(): string[] {
    return this.operatorsDisplayValues;
  }

  getSelectedValue(): string {
    return this.value;
  }

  setSelectedValue(value: string): void {
    if (this.valueFormatter) {
      this.value = this.valueFormatter(value);
    } else {
      this.value = value;
    }
  }

  resetAll(): void {
    this.selectedOperator = this.defaultOperator;
    this.value = this.defaultValue;
  }
}
