import React from 'react';
import { Typography } from '@mui/material';
import { ParticipantInfo } from 'elements/element-transfer/types';
import { getWireAddressLine3 } from 'elements/element-transfer/utils/getWireAddressLine3';
import { StyledContainer, StyledContent, StyledHeader } from './styled';

type Props = {
  header: string;
  participantInfo: ParticipantInfo;
};

const ParticipantInfoSection: React.FC<Props> = ({
  header,
  participantInfo: { name, street1, city, state, postalCode, street2 },
}) => {
  return (
    <StyledContainer>
      <StyledHeader>
        <Typography variant="overlineDefaultMedium">{header}</Typography>
      </StyledHeader>
      <StyledContent>
        <Typography variant="bodyDefaultMedium">{name}</Typography>
        <Typography variant="bodyDefaultBook">{street1}</Typography>
        {street2 && <Typography variant="bodyDefaultBook">{street2}</Typography>}
        <Typography variant="bodyDefaultBook">{getWireAddressLine3(city, state, postalCode)}</Typography>
      </StyledContent>
    </StyledContainer>
  );
};

export default ParticipantInfoSection;
