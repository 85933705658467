import { styled } from '@mui/material/styles';
import { Typography, Divider } from '@mui/material';
import { pxToRem, pxToVw } from 'utils/styling-utils';
import { Row } from 'components/shared/Flex/Row';

const MAX_WIDTH = 320;

export const FileName = styled(Typography)(() => ({
  flex: 1,
  paddingLeft: pxToRem(10),
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginInline: `-${pxToRem(theme.gaps._24px)}`,
}));

export const DocumentColumnLabel = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  paddingTop: pxToVw(theme.gaps._16px),
  paddingBottom: pxToVw(theme.gaps._16px),
  flex: 1.4,
}));

export const DateColumnLabel = styled(Row)(({ theme }) => ({
  alignItems: 'center',
  paddingTop: pxToVw(theme.gaps._16px),
  paddingBottom: pxToVw(theme.gaps._16px),
  flex: 0.7,
}));

export const PillColumnLabel = styled(Row)(({ theme }) => ({
  alignItems: 'center',
  paddingTop: pxToVw(theme.gaps._16px),
  paddingBottom: pxToVw(theme.gaps._16px),
  flex: 0.7,
}));

export const DateItem = styled(Row)(({ theme }) => ({
  alignItems: 'center',
  paddingTop: pxToVw(theme.gaps._16px),
  paddingBottom: pxToVw(theme.gaps._16px),
  flex: 0.7,
}));

export const PillItem = styled(Row)(({ theme }) => ({
  alignItems: 'center',
  paddingTop: pxToVw(theme.gaps._16px),
  paddingBottom: pxToVw(theme.gaps._16px),
  flex: 0.7,
}));

export const DocumentItem = styled(Row)(({ theme }) => ({
  alignItems: 'center',
  paddingTop: pxToVw(theme.gaps._16px),
  paddingBottom: pxToVw(theme.gaps._16px),
  flex: 1.4,
}));

export const DocumentRow = styled(Row)(() => ({
  maxWidth: MAX_WIDTH,
  alignItems: 'center',
}));
