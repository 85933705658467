import React from 'react';
import { CardTypes } from 'elements/types';
import { ReactComponent as AmericanExpress } from 'elements/assets/icons/cards/american-express.svg';
import { ReactComponent as DefaultCard } from 'elements/assets/icons/cards/default-card.svg';
import { ReactComponent as DinnersClub } from 'elements/assets/icons/cards/dinners-club.svg';
import { ReactComponent as Discover } from 'elements/assets/icons/cards/discover.svg';
import { ReactComponent as JCB } from 'elements/assets/icons/cards/jcb.svg';
import { ReactComponent as Maestro } from 'elements/assets/icons/cards/maestro.svg';
import { ReactComponent as MasterCard } from 'elements/assets/icons/cards/master-card.svg';
import { ReactComponent as Visa } from 'elements/assets/icons/cards/visa.svg';

export const cardIconsMapping: { [key in CardTypes]: React.FC } = {
  [CardTypes.Visa]: Visa,
  [CardTypes.Mastercard]: MasterCard,
  [CardTypes.Diners]: DinnersClub,
  [CardTypes.Discover]: Discover,
  [CardTypes.JCB]: JCB,
  [CardTypes.AmericanExpress]: AmericanExpress,
  [CardTypes.Maestro]: Maestro,
  [CardTypes.Other]: DefaultCard,
};
