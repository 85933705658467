import React from 'react';
import { Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import i18n from 'localizations';
import { ActionCell } from 'components/shared/Cells/ActionCell';
import { StatusCell } from 'components/shared/Cells/StatusCell';
import { UsersListRow } from 'ui-interfaces/row-interfaces/users/users-list-row';
import { AvatarCell } from 'components/shared/Cells/AvatarCell';
import { GridCellExpand } from 'components/shared/Cells/GridCellExpand';

export const columnsSettings: GridColDef[] = [
  {
    field: 'name',
    headerName: i18n.t('settingsPage.grid.cols.fullName'),
    flex: 1.2,
    renderCell: (params: GridRenderCellParams) => {
      const { avatarCellProps, isActive } = params.row as UsersListRow;
      return (
        <AvatarCell
          fullNameVariant="bodyDefaultMedium"
          isActive={isActive}
          {...avatarCellProps}
          width={params.colDef.computedWidth}
        />
      );
    },
  },
  {
    field: 'email',
    headerName: i18n.t('settingsPage.grid.cols.email'),
    flex: 1.5,
    renderCell: (params: GridRenderCellParams) => (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    ),
  },
  {
    field: 'phone',
    headerName: i18n.t('settingsPage.grid.cols.phone'),
    flex: 1.2,
  },
  {
    field: 'role',
    headerName: i18n.t('settingsPage.grid.cols.role'),
    flex: 1,
    renderCell(params) {
      const { roleCellProps } = params.row as UsersListRow;
      return <StatusCell {...roleCellProps} />;
    },
  },
  {
    field: 'createdAt',
    headerName: i18n.t('settingsPage.grid.cols.createdAt'),
    flex: 0.7,
    renderCell: (params: GridRenderCellParams) => {
      const { createdAt } = params.row as UsersListRow;
      return !createdAt ? (
        <Typography variant="bodyDefaultBook" color="grey.500">
          {i18n.t('settingsPage.grid.noData')}
        </Typography>
      ) : (
        createdAt
      );
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    renderCell: (params: GridRenderCellParams) => {
      const { actionsCellProps, id, destroyModalMainText } = params.row as UsersListRow;
      return <ActionCell {...actionsCellProps} userId={id} destroyModalMainText={destroyModalMainText} />;
    },
  },
];
