import { ILimitInput } from 'ui-interfaces/user-form/i-limit-input';
import { UserFormInput } from 'ui-enums/create-user-form/user-form-input';
import { RangeType } from 'models/enums/user/range-type';
import { LimitInputType } from 'ui-enums/create-user-form/limit-input-type';
import { UserLimitType } from 'models/enums/user/user-limit-type';
import { ILimitInputParams } from 'ui-interfaces/user-form/i-limit-input-params';
import { UserLimitModel } from 'models/response/users/user-limit-model';
import { mapUserLimitTypeToLimitInputType } from 'utils/user-form/map-user-limit-type-to-limit-input-type';

export class LimitInput implements ILimitInput {
  private readonly _id: string;

  uiElementType: UserFormInput = UserFormInput.limit;

  // type - start
  private _typeValue: UserLimitType;

  private readonly initialTypeValue: UserLimitType;
  // type - end

  // maxValue - start
  private _maxValue: string;

  private readonly initialMaxValue: string = '';
  // maxValue - end

  // range - start
  private _rangeValue: RangeType;

  private readonly initialRangeValue: RangeType = RangeType.week;
  // range - end

  private _limitInputType: LimitInputType;

  constructor(params: ILimitInputParams) {
    this._id = params.id;

    this._typeValue = params.typeValue;
    this.initialTypeValue = params.typeValue;

    this._maxValue = params.maxValue;

    this._rangeValue = params.rangeValue;

    this._limitInputType = params.limitInputType;
  }

  private resetMaxValue() {
    this._maxValue = this.initialMaxValue;
  }

  private resetRangeValue() {
    this._rangeValue = this.initialRangeValue;
  }

  get id(): string {
    return this._id;
  }

  get typeValue(): UserLimitType {
    return this._typeValue;
  }

  set typeValue(value: UserLimitType) {
    this._typeValue = value;
    this._limitInputType = mapUserLimitTypeToLimitInputType(value);
    this.resetMaxValue();
    this.resetRangeValue();
  }

  get maxValue(): string {
    return this._maxValue;
  }

  set maxValue(value: string) {
    this._maxValue = value;
  }

  get rangeValue(): RangeType {
    return this._rangeValue;
  }

  set rangeValue(value: RangeType) {
    this._rangeValue = value;
  }

  get limitInputType(): LimitInputType {
    return this._limitInputType;
  }

  hasValueChanged(): boolean {
    if (this._maxValue !== this.initialMaxValue) {
      return true;
    }

    return false;
  }

  getState(): UserLimitModel {
    return {
      type: this._typeValue,
      maxValue: parseFloat(this._maxValue),
      rangeType: this._rangeValue,
    };
  }
}
