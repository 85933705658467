import React from 'react';
import i18n from 'localizations';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';
import { StatusCellProps } from 'ui-interfaces/statuses/status-cell-props';

// labels - start
import { ReactComponent as SuccessLabelIcon } from 'assets/icons/statuses/transactions/labels/success.svg';
import { ReactComponent as PendingLabelIcon } from 'assets/icons/statuses/shared/labels/pending.svg';
import { ReactComponent as ProcessingLabelIcon } from 'assets/icons/statuses/transactions/labels/processing.svg';
import { ReactComponent as FailLabelIcon } from 'assets/icons/statuses/transactions/labels/fail.svg';
import { ReactComponent as ApprovalNeededIcon } from 'assets/icons/statuses/transactions/labels/approval-needed.svg';
import { ReactComponent as RejectedLabelIcon } from 'assets/icons/statuses/transactions/labels/rejected.svg';
import { ReactComponent as ApprovedLabelIcon } from 'assets/icons/statuses/transactions/labels/approved.svg';
import { ReactComponent as CanceledLabelIcon } from 'assets/icons/statuses/transactions/labels/canceled.svg';
// labels - end

export const mapTransactionStatusToStatusCellProps = (status: TransactionStatus): StatusCellProps => {
  switch (status.toLowerCase()) {
    case TransactionStatus.completed.toLowerCase(): {
      return {
        labelIcon: <SuccessLabelIcon />,
        pillowProps: {
          color: 'success',
          colorType: 'light2',
        },
      };
    }

    case TransactionStatus.failed.toLowerCase(): {
      return {
        labelIcon: <FailLabelIcon />,
        pillowProps: {
          color: 'error',
          colorType: 'light2',
        },
      };
    }

    case TransactionStatus.processing.toLowerCase(): {
      return {
        labelIcon: <ProcessingLabelIcon />,
        pillowProps: {
          color: 'magenta',
          colorType: 'light',
        },
      };
    }

    case TransactionStatus.pending.toLowerCase(): {
      return {
        labelIcon: <PendingLabelIcon />,
        pillowProps: {
          color: 'info',
          colorType: 'light3',
        },
      };
    }

    case TransactionStatus.approvalNeeded.toLowerCase(): {
      return {
        labelIcon: <ApprovalNeededIcon />,
        pillowProps: {
          color: 'warning',
          colorType: 'light3',
        },
        tooltipMessage: i18n.t('statuses.transactions.tooltip.approvalNeeded'),
      };
    }

    case TransactionStatus.rejected.toLowerCase(): {
      return {
        labelIcon: <RejectedLabelIcon />,
        pillowProps: {
          color: 'error',
          colorType: 'light2',
        },
        tooltipMessage: i18n.t('statuses.transactions.tooltip.rejected'),
      };
    }

    case TransactionStatus.approved.toLowerCase(): {
      return {
        labelIcon: <ApprovedLabelIcon />,
        pillowProps: {
          color: 'teal',
          colorType: 'main',
        },
      };
    }

    case TransactionStatus.cancelled.toLowerCase(): {
      return {
        labelIcon: <CanceledLabelIcon />,
        pillowProps: {
          color: 'grey',
          colorType: 100,
        },
      };
    }

    default:
      return {
        labelIcon: <ProcessingLabelIcon />,
        pillowProps: {
          color: 'magenta',
          colorType: 'light',
        },
      };
  }
};
