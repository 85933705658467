import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';
import { LabelLength } from 'ui-enums/label-length';
import { getSignOfTransaction } from 'utils/transactions/get-sign-of-transaction';
import { getDynamicAssetLabel } from 'utils/labels-mapping/get-dynamic-asset-label';

export const getBuyCryptoAmountForCryptoTransactionDetailsPage = (transaction: TransactionResponseModel): string => {
  switch (transaction.status.toLowerCase()) {
    case TransactionStatus.pending.toLowerCase():
    case TransactionStatus.failed.toLowerCase():
    case TransactionStatus.approvalNeeded.toLowerCase():
    case TransactionStatus.rejected.toLowerCase():
      return `-- ${getDynamicAssetLabel(LabelLength.short, transaction.assetTicker, transaction.assetName)}`;

    default:
      return transaction?.cryptoAmount
        ? `${getSignOfTransaction(transaction)}${transaction?.cryptoAmount} ${getDynamicAssetLabel(
            LabelLength.short,
            transaction.assetTicker,
            transaction.assetName,
          )}`
        : '';
  }
};
