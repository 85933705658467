import i18n from 'localizations';
import { Filter } from 'filters/interfaces/filter';
import { Operators } from 'filters/operators';
import { AccountBalancesFilterProperty } from 'ui-enums/account-balances/account-balances-filter-property';
import { SingleValueFilter } from 'filters/single-value-filter';

export const createAccountBalancesFilters: () => Filter[] = () => [
  new SingleValueFilter({
    property: AccountBalancesFilterProperty.assetTicker,
    displayProperty: i18n.t('custodialAccountDetailsPage.accountBalances.balanceAssetName'),
    operators: [Operators.contains],
    operatorsDisplayValues: [i18n.t('filters.operators.contains')],
    initialOperator: Operators.contains,
    initialValue: '',
  }),
  new SingleValueFilter({
    property: AccountBalancesFilterProperty.disbursable,
    displayProperty: i18n.t('custodialAccountDetailsPage.accountBalances.availableBalance'),
    operators: [Operators.equals, Operators.greaterThan, Operators.lessThan],
    operatorsDisplayValues: [
      i18n.t('filters.operators.equals'),
      i18n.t('filters.operators.greaterThan'),
      i18n.t('filters.operators.lessThan'),
    ],
    initialOperator: Operators.equals,
    initialValue: '',
  }),
  new SingleValueFilter({
    property: AccountBalancesFilterProperty.locked,
    displayProperty: i18n.t('custodialAccountDetailsPage.accountBalances.lockedBalance'),
    operators: [Operators.equals, Operators.greaterThan, Operators.lessThan],
    operatorsDisplayValues: [
      i18n.t('filters.operators.equals'),
      i18n.t('filters.operators.greaterThan'),
      i18n.t('filters.operators.lessThan'),
    ],
    initialOperator: Operators.equals,
    initialValue: '',
  }),
  new SingleValueFilter({
    property: AccountBalancesFilterProperty.pending,
    displayProperty: i18n.t('custodialAccountDetailsPage.accountBalances.pendingBalance'),
    operators: [Operators.equals, Operators.greaterThan, Operators.lessThan],
    operatorsDisplayValues: [
      i18n.t('filters.operators.equals'),
      i18n.t('filters.operators.greaterThan'),
      i18n.t('filters.operators.lessThan'),
    ],
    initialOperator: Operators.equals,
    initialValue: '',
  }),
  new SingleValueFilter({
    property: AccountBalancesFilterProperty.total,
    displayProperty: i18n.t('custodialAccountDetailsPage.accountBalances.totalBalance'),
    operators: [Operators.equals, Operators.greaterThan, Operators.lessThan],
    operatorsDisplayValues: [
      i18n.t('filters.operators.equals'),
      i18n.t('filters.operators.greaterThan'),
      i18n.t('filters.operators.lessThan'),
    ],
    initialOperator: Operators.equals,
    initialValue: '',
  }),
];
