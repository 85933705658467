import React, { useCallback } from 'react';
import { InputLabel, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { pxToRem } from 'utils/styling-utils';

type Props = {
  label: string;
  selectedValueIndex: number;
  items: string[];
  itemsDisplayValues: string[];
  onChange: (index: number) => void;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const ITEMS_TO_SHOW_WITH_NO_SCROLL = 6;

const MenuProps = {
  PaperProps: {
    sx: {
      maxHeight: ITEM_HEIGHT * ITEMS_TO_SHOW_WITH_NO_SCROLL + ITEM_PADDING_TOP,
    },
  },
};

const getOnSelectionChange =
  (items: string[]) => (event: SelectChangeEvent<string>, callback: (index: number) => void) => {
    const selectedItemIndex = items.findIndex(i => {
      return i === event.target.value;
    });

    callback(selectedItemIndex);
  };

export const DropDown: React.FC<Props> = ({ label, selectedValueIndex, items, itemsDisplayValues, onChange }) => {
  const onSelectionChange = useCallback(
    (event: SelectChangeEvent<string>, callback: (index: number) => void) =>
      getOnSelectionChange(items)(event, callback),
    [items],
  );

  return (
    <FormControl>
      <InputLabel
        sx={theme => ({
          ...theme.typography.bodyDefaultMedium,
        })}
      >
        {label}
      </InputLabel>
      <Select
        IconComponent={ExpandMoreIcon}
        sx={theme => ({
          width: pxToRem(220),
          height: pxToRem(52),
          borderRadius: pxToRem(theme.gaps._8px),
          ...theme.typography.bodyDefaultBook,
        })}
        label={label}
        value={items[selectedValueIndex]}
        onChange={(event: SelectChangeEvent<string>) => {
          onSelectionChange(event, onChange);
        }}
        MenuProps={MenuProps}
      >
        {items.map((i, index) => {
          return (
            <MenuItem
              sx={theme => ({
                paddingBlock: pxToRem(theme.gaps._12px),
                ...theme.typography.bodyDefaultBook,
              })}
              key={i}
              value={i}
            >
              {itemsDisplayValues[index]}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
