import { UserRoleTemplateResponseModel } from 'models/response/users/user-role-template-response-model';
import { IPermissionsCheckbox } from 'ui-interfaces/user-form/i-permissions-checkbox';
import { UserFormInput } from 'ui-enums/create-user-form/user-form-input';
import { getPermissionCheckboxLabels } from './get-permission-checkbox-labels';

export const generatePermissions = (
  userRoleTemplateResponseModel: UserRoleTemplateResponseModel,
): IPermissionsCheckbox[] => {
  return userRoleTemplateResponseModel.permissions.map((p, index) => {
    const { title, description } = getPermissionCheckboxLabels(p.key);

    return {
      id: p.key,
      value: index,
      dataValue: p.key,
      uiElementType: UserFormInput.checkbox,
      title,
      description,
    };
  });
};
