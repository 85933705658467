import React from 'react';
import i18n from 'localizations';
import { IdentityStatusModel } from 'models/enums/identities/identity-status-model';
import { StatusCellProps } from 'ui-interfaces/statuses/status-cell-props';

// labels - start
import { ReactComponent as ActiveLabelIcon } from 'assets/icons/statuses/identities/labels/active.svg';
import { ReactComponent as InactiveLabelIcon } from 'assets/icons/statuses/identities/labels/inactive.svg';
import { ReactComponent as SuspendedLabelIcon } from 'assets/icons/statuses/shared/labels/suspended.svg';
// labels - end

export const mapIdentityStatusModelToStatusCellProps = (
  status: IdentityStatusModel,
  isIdentitySuspendedByFortressSystem?: boolean,
): StatusCellProps => {
  switch (status) {
    case IdentityStatusModel.active:
      return {
        labelIcon: <ActiveLabelIcon />,
        pillowProps: {
          color: 'success',
          colorType: 'light2',
        },
        tooltipMessage: i18n.t('statuses.identities.tooltip.active'),
      };

    case IdentityStatusModel.inactive:
      return {
        labelIcon: <InactiveLabelIcon />,
        pillowProps: {
          color: 'grey',
          colorType: 100,
        },
        tooltipMessage: i18n.t('statuses.identities.tooltip.inactive'),
      };

    case IdentityStatusModel.suspended:
      return {
        labelIcon: <SuspendedLabelIcon />,
        pillowProps: {
          color: 'error',
          colorType: 'light2',
        },
        tooltipMessage: isIdentitySuspendedByFortressSystem
          ? i18n.t('statuses.identities.tooltip.suspendedByFortress')
          : i18n.t('statuses.identities.tooltip.suspended'),
      };

    default:
      return {
        labelIcon: <ActiveLabelIcon />,
        pillowProps: {
          color: 'success',
          colorType: 'light2',
        },
      };
  }
};
