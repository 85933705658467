import React from 'react';
import { SnackbarProviderProps } from 'notistack';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckIcon from '@mui/icons-material/Check';

const getSnackbarSetup = (): Partial<SnackbarProviderProps> => ({
  iconVariant: {
    error: <CancelOutlinedIcon sx={{ marginRight: '14px' }} />,
    success: <CheckIcon fontSize="small" sx={{ marginRight: '15px' }} />,
  },
  autoHideDuration: 3000,
  maxSnack: 1,
  disableWindowBlurListener: true,
  style: {
    padding: '16px 20px',
    borderRadius: '8px',
    alignItems: 'center',
    fontFamily: 'MalloryMP',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '170%',
    letterSpacing: '-0.02em',
  },
});

export { getSnackbarSetup };
