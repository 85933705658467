import React from 'react';
import { StyledAlert, StyledCloseIcon, StyledErrorIcon, StyledIconButton } from './styled';

type Props = {
  onClose: () => void;
};

export const FlatAlert: React.FC<Props> = ({ children, onClose }) => {
  return (
    <StyledAlert
      icon={<StyledErrorIcon />}
      action={
        <StyledIconButton onClick={onClose}>
          <StyledCloseIcon />
        </StyledIconButton>
      }
    >
      {children}
    </StyledAlert>
  );
};
