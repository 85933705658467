import { GridRowParams } from '@mui/x-data-grid';
import { NavigateFunction } from 'react-router-dom';
import { CustodialAccountsRow } from 'ui-interfaces/row-interfaces/custodial-accounts';
import { getRouteWithParams } from 'utils/get-route-with-params';
import { NESTED_PATHS } from 'navigation/constants';
import {
  CustodialAccountDetailsUnderIdentityDetailsUrlParams,
  IdentityDetailsUrlParams,
} from 'navigation/url-params-type';
import { FilterResult } from 'filters/interfaces/filter-result';
import { API_ROUTES } from 'redux/api/api-routes';
import { DownloadCSV } from 'hooks/use-export/interfaces';

export const onGridRowClick =
  (navigate: NavigateFunction, urlParams: Readonly<Partial<IdentityDetailsUrlParams>>) =>
  ({ id }: Pick<GridRowParams<CustodialAccountsRow>, 'id'>) => {
    navigate(
      getRouteWithParams(NESTED_PATHS.IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS, {
        custodialAccountId: id,
        identityId: urlParams.identityId,
        identityType: urlParams.identityType,
      } as CustodialAccountDetailsUnderIdentityDetailsUrlParams),
    );
  };

export const onCustodialAccountsExportClick =
  (downloadCsv: DownloadCSV, identityId: string) => (filterResult?: FilterResult) => {
    downloadCsv({
      urlSegment: API_ROUTES.private.CUSTODIAL_ACCOUNTS,
      preFilterParams: {
        [`filter[ownerIdentityId eq]`]: identityId,
      },
      filterResult,
    });
  };
