import { Box, Typography } from '@mui/material';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledSubtitle = elementsStyled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.primary.font};
    display: block;
    font-weight: 500;
    margin-bottom: 16px;
    overflow-wrap: break-word;
  `,
);

export const StyledMessage = elementsStyled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.secondary.font};
    display: block;
    font-weight: 500;
  `,
);

export const StyledContainer = elementsStyled(Box)(css`
  justify-content: center;
  flex-direction: column;
  text-align: center;
`);
