import { pxToRem } from 'elements/utils';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { BaseInput } from 'elements/components/BaseInput';

export const StyledBaseInput = elementsStyled(BaseInput)`
  & .MuiFilledInput-root {
    height: 60px;
    font-size: ${pxToRem(20)};
    line-height: ${pxToRem(30)};
    font-weight: 500;
  }
`;
