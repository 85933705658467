/* eslint-disable no-empty,no-console, no-undef */
export const shareText = async (text: string): Promise<void> => {
  const data: ShareData = {
    text,
  };
  if (navigator.share && text) {
    try {
      await navigator.share(data);
      return;
    } catch {}
  }
  console.log('Web Share API not supported!');
};
