import React from 'react';
import { ProtectedOutlet } from 'navigation/utils/ProtectedOutlet';
import { PATHS } from 'navigation/constants';
import { useAuth } from 'auth0/hooks/use-auth';

export const PrivateRoutesRestriction: React.FC = () => {
  const { isProcessing, isLoading, isAuthenticated } = useAuth();

  if (isProcessing || isLoading) {
    return null;
  }

  return <ProtectedOutlet fallbackRoute={PATHS.LOG_IN} fallbackCondition={!isAuthenticated} />;
};
