import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { isCannotLockTxAmount } from 'elements/api';
import { setErrorBanner } from 'elements/utils';
import { TryLockInitRequestModel } from 'models/request/current/try-lock-init-request-model';

type Props = {
  lockId?: string;
  setLockId: (id: string) => void;
  lockRequest: (lockParams: TryLockInitRequestModel) => Promise<string>;
  unlockRequest: (id: string) => Promise<void>;
};

export const useLockPaymentAmount = ({ lockId, setLockId, lockRequest, unlockRequest }: Props) => {
  const [locking, setLocking] = useState(false);
  const [unlocking, setUnlocking] = useState(!!lockId);

  const [lockError, setLockError] = useState(false);
  const [unlockError, setUnlockError] = useState(false);

  const clearLockError = () => setLockError(false);

  const lock = async (lockParams: TryLockInitRequestModel): Promise<boolean> => {
    try {
      setLocking(true);
      setLockError(false);

      const id = await lockRequest(lockParams);

      setLockId(id);
      return true;
    } catch (err) {
      const isLimitsError = isCannotLockTxAmount(err);
      setErrorBanner(err, enqueueSnackbar, !isLimitsError);

      if (isLimitsError) {
        setLockError(true);
      }
      return false;
    } finally {
      setLocking(false);
    }
  };

  const unlock = async () => {
    if (lockId) {
      try {
        setUnlocking(true);
        setUnlockError(false);

        await unlockRequest(lockId);
        setLockId('');
      } catch (err) {
        setUnlockError(true);
      } finally {
        setUnlocking(false);
      }
    }
  };

  useEffect(() => {
    if (lockId) {
      (async () => {
        await unlock();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    locking,
    unlocking,
    lockError,
    unlockError,
    lock,
    unlock,
    clearLockError,
  };
};
