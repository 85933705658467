import React from 'react';
import { Row } from 'components/shared/Flex/Row';
import { Gap } from 'components/shared/Gap';
import { ElementButton } from 'components/shared/buttons/ElementButton';
import { Element } from 'ui-interfaces/elements/element';

type Props = {
  elements: Element[];
  isPageLoading: boolean;
};

export const Elements: React.FC<Props> = ({ elements, isPageLoading }) => {
  return (
    <Row>
      {elements.map((element, index) => (
        <React.Fragment key={element.name}>
          <ElementButton {...element} isSkeletonDisplayed={isPageLoading} />
          {index + 1 !== elements.length && <Gap isHorizontal size="_20px" />}
        </React.Fragment>
      ))}
    </Row>
  );
};
