import i18n from 'localizations';
import { BeneficiaryRow } from 'ui-interfaces/row-interfaces/custodial-accounts/beneficiary-row';
import { AccountBeneficiaryResponseModel } from 'models/response/custodial-accounts/account-beneficiary-response-model';
import { formatDate } from 'utils/format-date';
import { capitalizeFirstLetter } from 'utils/capitalize-first-letter';
import { IdentityType } from 'models/enums/identities/identity-type';
import { StatusCellProps } from 'ui-interfaces/statuses/status-cell-props';
import { mapIdentityStatusModelToStatusCellProps } from 'utils/multi-mapping/statuses/map-identity-status-model-to-status-cell-props';

export class BeneficiaryViewModel implements BeneficiaryRow {
  readonly id: string;

  readonly identityType: IdentityType;

  readonly name: string;

  readonly relationship: string;

  readonly percentage: string;

  readonly lastUpdate: string;

  readonly statusCellProps: StatusCellProps;

  constructor(ab: AccountBeneficiaryResponseModel) {
    this.id = ab.identityId;
    this.identityType = ab.identityType;
    this.name = ab.identityDisplayName;
    this.relationship = ab.relationship
      ? capitalizeFirstLetter(ab.relationship)
      : i18n.t('custodialAccountDetailsPage.beneficiaries.grid.emptyFields.relationship');
    this.percentage = `${ab.percentageOfDivision}%`;
    this.lastUpdate = formatDate(ab.createdAt);
    this.statusCellProps = mapIdentityStatusModelToStatusCellProps(ab.identityStatus, !ab.isSuspendedByIntegrator);
  }
}
