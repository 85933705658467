import React from 'react';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { Row } from 'components/shared/Flex/Row';
import Breadcrumbs, { BreadCrumbsItem } from 'components/shared/Breadcrumbs';
import { StyledTopRow } from '../../IdentityDetailsPageTopRow/styled';
import { StyledLoadingButtonWithSkeleton } from './styled';

type Props = {
  breadCrumbs: BreadCrumbsItem[];
};

export const LoadingTopRow: React.FC<Props> = ({ breadCrumbs }) => {
  return (
    <StyledTopRow>
      <Breadcrumbs items={breadCrumbs} />
      <Row>
        <StyledLoadingButtonWithSkeleton
          variant="contained"
          size="large"
          loading={false}
          isSkeletonDisplayed
          text={
            <Typography variant="buttonMedium" color="background.paper">
              {t('identityDetailsPage.suspendActions.suspend')}
            </Typography>
          }
          onClick={() => {}}
        />
      </Row>
    </StyledTopRow>
  );
};
