import React, { useCallback, useEffect, useState } from 'react';
import { PathMatch, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  BeneficiariesByAccountIdApiParams,
  useLazyGetBeneficiariesByAccountIdQuery,
} from 'redux/api/api-custodial-accounts';
import { BeneficiaryRow } from 'ui-interfaces/row-interfaces/custodial-accounts/beneficiary-row';
import { CommonGridBox } from 'components/shared/layouts/CommonLayout';
import { SimplifiedGrid } from 'components/shared/grids/SimplifiedGrid';
import { onBeneficiaryCellClick, onBeneficiaryTransform } from 'handlers/tapi/custodial-accounts/handlers';
import { columnsBeneficiaries } from './columns-definitions';

type Props = {
  custodialAccountId: string;
  pathMatch: PathMatch | null;
  setIsDetailsPageError: React.Dispatch<React.SetStateAction<boolean>>;
};

export const BeneficiariesTab: React.FC<Props> = ({ custodialAccountId, pathMatch, setIsDetailsPageError }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // request boilerplate - start
  const [getBeneficiariesTrigger, beneficiariesResult] = useLazyGetBeneficiariesByAccountIdQuery();

  const fetchBeneficiaries = useCallback(
    () => getBeneficiariesTrigger({ custodialAccountId } as BeneficiariesByAccountIdApiParams, false),
    [getBeneficiariesTrigger, custodialAccountId],
  );

  const [rows, setRows] = useState<BeneficiaryRow[]>([]);
  const [loading, setLoading] = useState<boolean>(beneficiariesResult.isUninitialized);

  useEffect(() => {
    fetchBeneficiaries();
  }, [fetchBeneficiaries]);
  // request boilerplate - end

  useEffect(() => {
    if (beneficiariesResult.isSuccess && beneficiariesResult.data) {
      const transformedItems = beneficiariesResult.data.data.map(onBeneficiaryTransform);
      setRows(transformedItems);
      setLoading(beneficiariesResult.isFetching);
    }
  }, [beneficiariesResult.data, beneficiariesResult.isFetching, beneficiariesResult.isSuccess]);

  // handle all page error - start
  useEffect(() => {
    setIsDetailsPageError(Boolean(beneficiariesResult.error));
  }, [beneficiariesResult.error, setIsDetailsPageError]);
  // handle all page error - end

  return (
    <CommonGridBox>
      <SimplifiedGrid
        columns={columnsBeneficiaries}
        headerName={t('custodialAccountDetailsPage.beneficiaries.grid.title')}
        noResultsTitle={t('custodialAccountDetailsPage.beneficiaries.grid.noResultsTitle')}
        noResultsSubtitle={t('custodialAccountDetailsPage.beneficiaries.grid.noResultsSubtitle')}
        containerHeight="100%"
        rows={rows}
        isLoading={loading}
        error={beneficiariesResult.error}
        onCellClick={onBeneficiaryCellClick(navigate, pathMatch)}
      />
    </CommonGridBox>
  );
};
