import { FormControl, MenuItem, styled } from '@mui/material';
import { NumberField } from 'components/shared/inputs/NumberField';
import { pxToRem } from 'utils/styling-utils';

export const StyledFormControl = styled(FormControl)(() => ({
  flex: 1,
  width: 0,
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  ...theme.typography.bodyDefaultBook,
  padding: pxToRem(theme.gaps._12px),
}));

export const StyledNumberField = styled(NumberField)(() => ({
  flex: 1,
  width: 0,
}));

export const TypeFormControl = styled(FormControl)(() => ({
  flexGrow: 0,
  flexShrink: 0,
  flexBasis: pxToRem(150),
}));
