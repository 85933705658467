import { MobileStepper, css } from '@mui/material';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledStepper = elementsStyled(MobileStepper)(
  ({ theme }) => css`
    justify-content: center;
    padding: 0;
    & .MuiMobileStepper-dot {
      background-color: ${theme.palette.tertiary.main};
      margin: 0 6px;
    }

    & .MuiMobileStepper-dotActive {
      background-color: ${theme.palette.primary.main};
    }
  `,
);
