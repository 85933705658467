import { ListSubheader, MenuItem, css } from '@mui/material';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { pxToRem } from 'elements/utils';
import { BaseInput } from 'elements/components/BaseInput';

export const StyledSelect = elementsStyled(BaseInput, {
  shouldForwardProp: prop => prop !== 'isSelectBottomRounded',
})<{
  isSelectBottomRounded: boolean;
}>(
  ({ theme, isSelectBottomRounded }) => css`
    & .MuiFilledInput-root {
      border-radius: ${isSelectBottomRounded ? theme.borderRadius : `${theme.borderRadius} ${theme.borderRadius} 0 0`};

      & .MuiSelect-icon {
        right: ${pxToRem(12)};
        color: ${theme.palette.secondary.font};
      }
    }
  `,
);

export const BaseSelectItem = elementsStyled(MenuItem)(
  ({ theme }) =>
    css`
      background-color: ${theme.palette.background.custom || theme.palette.background.default};
      border-radius: ${theme.borderRadius};

      &:hover {
        background-color: ${theme.palette.tertiary.mainTransparent20};
      }

      &.Mui-selected,
      &.Mui-focusVisible,
      &.Mui-selected.Mui-focusVisible {
        background-color: ${theme.palette.tertiary.mainTransparent40};

        &:hover {
          background-color: ${theme.palette.tertiary.mainTransparent60};
        }
      }
    `,
);

export const BaseSelectSubheader = elementsStyled(ListSubheader)(
  ({ theme }) => css`
    padding: 10px 0;
    background-color: ${theme.palette.background.custom || theme.palette.background.default};
  `,
);
