import React, { useState, useEffect } from 'react';
import i18n from 'localizations';
import { ReactComponent as NoResultsIcon } from 'assets/icons/grid/no-results.svg';
import { ReactComponent as NoResultsIconSmall } from 'assets/icons/grid/no-results-small.svg';
import { ReactComponent as NoFilteredResultsIcon } from 'assets/icons/grid/no-filtered-results.svg';
import { ReactComponent as NoFilteredResultsIconSmall } from 'assets/icons/grid/no-filtered-results-small.svg';
import { IconWithLabels } from 'components/shared/IconWithLabels';
import { IconWithLabelsSmall } from '../IconWithLabelsSmall';

type Props = {
  title: string;
  subtitle: string;
  noFilteredData: boolean;
  isSmallLayout?: boolean;
};
export const CustomNoResultsOverlay: React.FC<Props> = ({ noFilteredData, isSmallLayout, ...otherProps }) => {
  // https://github.com/mui/mui-x/issues/4309
  // Workaround
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 300);
    return () => clearTimeout(timeout);
  }, []);

  if (!show) return null;

  if (noFilteredData) {
    return isSmallLayout ? (
      <IconWithLabelsSmall
        icon={<NoFilteredResultsIconSmall />}
        title={i18n.t('filters.noFilteredResultsTitle')}
        subtitle={i18n.t('filters.noFilteredResultsSubtitle')}
      />
    ) : (
      <IconWithLabels
        icon={<NoFilteredResultsIcon />}
        title={i18n.t('filters.noFilteredResultsTitle')}
        subtitle={i18n.t('filters.noFilteredResultsSubtitle')}
      />
    );
  }
  return isSmallLayout ? (
    <IconWithLabelsSmall icon={<NoResultsIconSmall />} {...otherProps} />
  ) : (
    <IconWithLabels icon={<NoResultsIcon />} {...otherProps} />
  );
};
