import React from 'react';
import { PaddedRowBox } from 'components/shared/detail-page/rows/shared/PaddedRowBox';
import { RightCell } from 'components/shared/detail-page/rows/shared/RightCell';
import { IMt103Row } from 'ui-interfaces/transaction-details/rows/mt103/i-mt103-row';
import { LeftCell } from './LeftCell';

type Props = {
  data: IMt103Row;
  hasBottomBorder?: boolean;
};

export const MT103Row: React.FC<Props> = ({ data, hasBottomBorder }) => {
  return (
    <PaddedRowBox hasBottomBorder={hasBottomBorder}>
      <LeftCell code={data.code} label={data.label} />
      <RightCell value={data.value} />
    </PaddedRowBox>
  );
};
