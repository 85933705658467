import React from 'react';
import { Typography } from '@mui/material';
import { LeftCellBox } from 'components/shared/detail-page/rows/shared/LeftCellBox';

type Props = {
  value: string;
  large?: boolean;
};

export const LeftCell: React.FC<Props> = ({ value, large }) => (
  <LeftCellBox large={large}>
    <Typography variant="bodyDefaultMedium">{value}</Typography>
  </LeftCellBox>
);
