import i18n from 'localizations';
import { CompoundLabel, TransactionsRow } from 'ui-interfaces/row-interfaces/transactions';
import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { get4LastChars } from 'utils/get-4-last-chars';
import { TransactionFiatBaseViewModel } from './base/transaction-fiat-base-vm';

export class TransactionDepositCheckViewModel extends TransactionFiatBaseViewModel implements TransactionsRow {
  readonly source: string;

  readonly destination: CompoundLabel;

  constructor(transaction: TransactionResponseModel) {
    super(transaction);
    this.source = transaction.sourceDisplayValue ?? i18n.t('transactionsPage.noData');
    this.destination = {
      start: `*${get4LastChars(transaction.destinationDisplayValue ?? '')}`,
      end: i18n.t('transactionsPage.account'),
    } as CompoundLabel;
  }
}
