import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { TransferDirectionEnum, TransferFlow, TransferOptionsEnum } from 'elements/element-transfer/types';
import { Tabs } from 'elements/components';
import Layout, { LayoutProps } from 'elements/element-transfer/components/Layout';
import { setFlow, setPage, useElement } from 'elements/element-transfer/contexts/Element';

// This additional layer over basic Layout component is used on potential first pages of the flow to display correct information
// If integrator provided only one Transfer Option and both Transfer Directions (Deposit+Withdrawal) we display Tabs and "Transfer" header on these pages

const FlowFirstPageLayout: React.FC<LayoutProps> = ({ children, title, onBack, subHeader, ...props }) => {
  const {
    dispatch,
    state: { transferOptionsList, transferDirection, flowType, page },
  } = useElement();
  const { t } = useTranslation();

  const withTabsAndTransferTitle =
    transferOptionsList.length === 1 && transferDirection === TransferDirectionEnum.DepositWithdrawal;

  const withBackButton = transferOptionsList.length > 1;

  const onTabChange = async (value: TransferFlow) => {
    dispatch(setFlow(value));

    if (transferOptionsList[0] === TransferOptionsEnum.Ach) {
      dispatch(setPage(value === TransferFlow.Deposit ? Paths.DepositAchAmount : Paths.WithdrawalAchAmount));
    }

    if (transferOptionsList[0] === TransferOptionsEnum.Crypto) {
      dispatch(setPage(value === TransferFlow.Deposit ? Paths.DepositCryptoAddresses : Paths.WithdrawalCryptoAmount));
    }

    if (transferOptionsList[0] === TransferOptionsEnum.Wire) {
      dispatch(setPage(value === TransferFlow.Deposit ? Paths.DepositWire : Paths.WithdrawalWireRecipientSearch));
    }
  };

  const tabValue = page.location.includes(TransferDirectionEnum.Withdrawal.toLowerCase())
    ? TransferFlow.Withdrawal
    : TransferFlow.Deposit;

  useEffect(() => {
    if (tabValue !== flowType) {
      dispatch(setFlow(tabValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      title={withTabsAndTransferTitle ? t('elements.transfer.customizableLabels.title') : title}
      subHeader={
        <>
          {withTabsAndTransferTitle && (
            <Tabs
              items={[
                { value: TransferFlow.Deposit, title: t('elements.transfer.customizableLabels.depositTitle') },
                { value: TransferFlow.Withdrawal, title: t('elements.transfer.customizableLabels.withdrawalTitle') },
              ]}
              value={tabValue}
              onTabChange={onTabChange}
              data-testid="tabs-test-id"
            />
          )}
          {subHeader}
        </>
      }
      onBack={withBackButton ? onBack : undefined}
      {...props}
    >
      {children}
    </Layout>
  );
};

export default FlowFirstPageLayout;
