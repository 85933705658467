import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Box } from '@mui/material';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledContainer = elementsStyled(Box)(
  ({ theme }) =>
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      padding: 10px 16px;
      border: 1px solid ${theme.palette.tertiary.main};
      border-radius: ${theme.borderRadius};
      cursor: pointer;
    `,
);

export const StyledMainContent = elementsStyled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const StyledTextContainer = elementsStyled(Box)`
  display: flex;
  align-self: start;
  align-items: start;
  flex-direction: column;
  gap: 4px;
`;

export const StyledMoreIcon = elementsStyled(ExpandMoreOutlinedIcon)(
  ({ theme }) =>
    css`
      color: ${theme.palette.secondary.font};
      opacity: 0.5;
    `,
);
