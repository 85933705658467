import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CustomizedGrid } from 'components/shared/grids/CustomizedGrid';
import { CommonGridBox } from 'components/shared/layouts/CommonLayout';
import { IdentityType } from 'models/enums/identities/identity-type';
import { CustodialAccountsApiParams, useLazyGetCustodialAccountsQuery } from 'redux/api/api-custodial-accounts';
import { FilterResult } from 'filters/interfaces/filter-result';
import { useTransformCollectionResponse } from 'hooks/use-transform-collection-response';
import { CustodialAccountResponseModel } from 'models/response/custodial-accounts/custodial-account-response-model';
import { CustodialAccountViewModel } from 'view-models/custodial-accounts/custodial-account-vm';
import { createCustodialAccountViewModel } from 'factories/custodial-accounts/create-custodial-account-view-model';
import { useExport } from 'hooks/use-export';
import { IdentityDetailsUrlParams } from 'navigation/url-params-type';
import {
  businessCustodialAccountsTabFilters,
  businessCustodialAccountsTabSelectionMacroCommand,
} from './business-custodial-accounts-tab-filter-config';
import {
  personalCustodialAccountsTabFilters,
  personalCustodialAccountsTabSelectionMacroCommand,
} from './personal-custodial-accounts-tab-filter-config';
import { columnsCustodialAccountsTab } from './columns-definitions';
import { onCustodialAccountsExportClick, onGridRowClick } from './handlers';

type Props = {
  urlParams: Readonly<Partial<IdentityDetailsUrlParams>>;
  setIsDetailsPageError: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CustodialAccountsTab: React.FC<Props> = ({ urlParams, setIsDetailsPageError }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [getCustodialAccountsTrigger, custodialAccountsResult] = useLazyGetCustodialAccountsQuery();

  const fetchCustodialAccounts = useCallback(
    (page?: number, filterResult?: FilterResult) =>
      getCustodialAccountsTrigger(
        { page, ownerIdentityId: urlParams.identityId, filterResult } as CustodialAccountsApiParams,
        false,
      ),
    [getCustodialAccountsTrigger, urlParams.identityId],
  );

  const [custodialAccountsRows, custodialAccountsResourceCount] = useTransformCollectionResponse<
    CustodialAccountResponseModel,
    CustodialAccountViewModel
  >(custodialAccountsResult.data, createCustodialAccountViewModel);

  const { downloadCsv, isLoading: isExportLoading, error: exportError } = useExport();

  useEffect(() => {
    fetchCustodialAccounts();
  }, [fetchCustodialAccounts]);

  // handle all page error - start
  useEffect(() => {
    setIsDetailsPageError(custodialAccountsResult.isError);
  }, [setIsDetailsPageError, custodialAccountsResult.isError]);
  // handle all page error - end

  return (
    <CommonGridBox>
      <CustomizedGrid
        columns={columnsCustodialAccountsTab}
        headerName={t('custodialAccountsPage.gridTitle')}
        filters={
          urlParams.identityType === IdentityType.personal
            ? personalCustodialAccountsTabFilters
            : businessCustodialAccountsTabFilters
        }
        selectionMacroCommand={
          urlParams.identityType === IdentityType.personal
            ? personalCustodialAccountsTabSelectionMacroCommand
            : businessCustodialAccountsTabSelectionMacroCommand
        }
        noResultsTitle={t('custodialAccountsPage.noResultsTitle')}
        noResultsSubtitle={t('custodialAccountsPage.noResultsSubtitle')}
        containerHeight="100%"
        rows={custodialAccountsRows}
        rowCount={custodialAccountsResourceCount}
        isLoading={custodialAccountsResult.isFetching}
        error={custodialAccountsResult.error || exportError}
        fetchOtherItems={fetchCustodialAccounts}
        onGridRowClick={onGridRowClick(navigate, urlParams)}
        onExportClick={onCustodialAccountsExportClick(downloadCsv, urlParams.identityId!)}
        isExportLoading={isExportLoading}
      />
    </CommonGridBox>
  );
};
