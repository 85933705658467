import { mainApi } from 'redux/api/main-api';
import { API_ROUTES } from 'redux/api/api-routes';
import {
  CollectionResponse,
  DocumentsResponse,
  BeneficialOwnersResponse,
  VerifyIdentityApiParams,
} from 'redux/api/types';
import { DEFAULT_PAGE_SIZE, FIRST_PAGE, GET_BUSINESS_IDENTITY_TAG } from 'redux/api/constants';
import { BusinessIdentityResponseModel } from 'models/response/identities/business/business-identity-response-model';
import { BusinessIdentityDetailsResponseModel } from 'models/response/identities/business/business-identity-details-response-model';
import { IdentityApiParams } from 'redux/api/api-personal-identities';
import { FilterResult } from 'filters/interfaces/filter-result';
import { getRouteWithParams } from 'utils/get-route-with-params';

const businessIdentitiesApi = mainApi.injectEndpoints({
  endpoints: build => ({
    getBusinessIdentities: build.query<CollectionResponse<BusinessIdentityResponseModel>, IdentityApiParams>({
      query: ({ page = FIRST_PAGE, pageSize = DEFAULT_PAGE_SIZE, filterResult }) => {
        if (filterResult) {
          const { property, operator, value } = filterResult as FilterResult;

          return {
            url: API_ROUTES.private.BUSINESS_IDENTITIES,
            params: { page, pageSize, [`filter[${property} ${operator}]`]: value.trim() },
          };
        }
        return {
          url: API_ROUTES.private.BUSINESS_IDENTITIES,
          params: { page, pageSize },
        };
      },
    }),
    getBusinessIdentityDetailsById: build.query<BusinessIdentityDetailsResponseModel, string>({
      query: (identityId: string) => ({
        url: getRouteWithParams(API_ROUTES.private.BUSINESS_IDENTITY, { identityId }),
      }),
      providesTags: [GET_BUSINESS_IDENTITY_TAG],
    }),
    getBusinessIdentityDocuments: build.query<DocumentsResponse, string>({
      query: (identityId: string) => ({
        url: getRouteWithParams(API_ROUTES.private.BUSINESS_IDENTITY_DOCUMENTS, { identityId }),
      }),
    }),
    getBusinessIdentityBeneficialOwners: build.query<BeneficialOwnersResponse, string>({
      query: (identityId: string) => ({
        url: getRouteWithParams(API_ROUTES.private.BUSINESS_IDENTITY_BENEFICIAL_OWNERS, { identityId }),
      }),
    }),
    verifyBusinessIdentity: build.mutation<undefined, VerifyIdentityApiParams>({
      query: ({ identityId, identityVerificationModel }) => ({
        url: getRouteWithParams(API_ROUTES.private.BUSINESS_IDENTITY_VERIFY, { identityId }),
        method: 'POST',
        body: identityVerificationModel,
      }),
      invalidatesTags: [GET_BUSINESS_IDENTITY_TAG],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetBusinessIdentitiesQuery,
  useLazyGetBusinessIdentityDetailsByIdQuery,
  useLazyGetBusinessIdentityDocumentsQuery,
  useLazyGetBusinessIdentityBeneficialOwnersQuery,
  useVerifyBusinessIdentityMutation,
} = businessIdentitiesApi;
