export enum DashboardRoles {
  paymentInitiator = 'paymentInitiator',
  paymentApprover = 'paymentApprover',
  userManager = 'userManager',
  corporateAccountViewer = 'corporateAccountViewer',
  adminManager = 'adminManager',
  pii = 'pii',
  caEditor = 'caEditor',
  accountSuspender = 'accountSuspender',
  identitySuspender = 'identitySuspender',
}
