import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustodialAccount, CustodialAccountTypeEnum, ExternalAccount, ExternalAccountTypeEnum } from 'elements/types';
import { cardIconsMapping, formatUsdBalance, getCardType, isExternalAccount } from 'elements/utils';
import { ReactComponent as RoundedDollarIcon } from 'elements/assets/icons/roundedDollar.svg';
import { ReactComponent as SquaredAccountBalanceIcon } from 'elements/assets/icons/squaredAccountBalance.svg';
import { ReactComponent as SquaredDollarIcon } from 'elements/assets/icons/squaredDollar.svg';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import PaymentMethodItem from '../PaymentMethodItem';
import { PaymentMethodItemState } from '../PaymentMethodItem/types';

type Props = {
  account?: CustodialAccount | ExternalAccount | null;
  onClick?: () => void;
  withBalance?: boolean;
  state?: PaymentMethodItemState;
};

const PaymentMethodButton: React.FC<Props> = ({ account, onClick, withBalance = false, state }) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  const getAccountData = (): {
    icon: React.ReactNode;
    title: string;
    subtitle: string;
    postfix?: string;
  } => {
    const title = t('elements.features.paymentMethods.buttonTitle');

    if (!account) {
      return {
        icon: <SquaredDollarIcon />,
        title,
        subtitle: t('elements.features.paymentMethods.noPaymentMethod'),
      };
    }

    if (isExternalAccount(account)) {
      const data = {
        icon: <SquaredAccountBalanceIcon />,
        title,
        subtitle: t('elements.features.paymentMethods.bankAccount'),
        postfix: `*${account.last4}`,
      };

      if (account.type === ExternalAccountTypeEnum.CardExternalAccount) {
        const Icon = cardIconsMapping[account.network!];

        return { ...data, icon: <Icon />, subtitle: getCardType(account.network!) };
      }

      return data;
    }

    const mapAccountTypeToTitle = {
      [CustodialAccountTypeEnum.Business]: t('elements.features.paymentMethods.businessAccount'),
      [CustodialAccountTypeEnum.Personal]: t('elements.features.paymentMethods.personalAccount'),
      [CustodialAccountTypeEnum.RothIra]: t('elements.features.paymentMethods.account'),
      [CustodialAccountTypeEnum.SepIra]: t('elements.features.paymentMethods.account'),
      [CustodialAccountTypeEnum.SimpleIra]: t('elements.features.paymentMethods.account'),
      [CustodialAccountTypeEnum.TraditionalIra]: t('elements.features.paymentMethods.account'),
    };

    const textWithoutBalance = mapAccountTypeToTitle[account.type];

    return {
      icon: <RoundedDollarIcon />,
      title,
      subtitle: withBalance
        ? `${formatUsdBalance(account.balance, isMobile)} - ${textWithoutBalance}`
        : textWithoutBalance,
      postfix: `*${account.accountNumber.slice(-4)}`,
    };
  };
  const { icon, title, subtitle, postfix } = getAccountData();

  return (
    <PaymentMethodItem
      icon={icon}
      title={title}
      subtitle={subtitle}
      postfix={postfix}
      onClick={onClick}
      state={state}
    />
  );
};
export { PaymentMethodButton, PaymentMethodItemState };
