import i18n from 'localizations';
import { PaymentType } from 'ui-enums/response/transactions/payment-type';
import { TransactionStatus } from 'ui-enums/response/transactions/transaction-status';
import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { formatDate, getTime } from 'utils/format-date';
import { CompoundLabel, TransactionsRow } from 'ui-interfaces/row-interfaces/transactions';
import { mapTransactionStatusToStatusCellProps } from 'utils/multi-mapping/statuses/map-transaction-status-to-status-cell-props';
import { StatusCellProps } from 'ui-interfaces/statuses/status-cell-props';
import { getStandardAmountFormatting } from 'utils/transactions/get-standard-amount-formatting';
import { ITransactionAssetCell } from 'ui-interfaces/row-interfaces/transactions/i-transaction-asset-cell';
import { AssetByNetworkIconSize } from 'ui-enums/asset-by-network-icon-size';
import { mapAssetByNetworkIconSizeToNumber } from 'utils/multi-mapping/asset-by-network-icon-size/map-asset-by-network-icon-size-to-number';
import { getDynamicAssetLabel } from 'utils/labels-mapping/get-dynamic-asset-label';
import { LabelLength } from 'ui-enums/label-length';
import { getStaticDefaultIconForTransaction } from 'utils/icons-mapping/transactions/get-static-default-icon-for-transaction';

export class TransactionBaseViewModel implements TransactionsRow {
  readonly id: string;

  readonly transactionId: string;

  readonly paymentId: string;

  readonly date: string;

  readonly time: string;

  readonly type: PaymentType;

  readonly status: TransactionStatus;

  readonly statusCellProps: StatusCellProps;

  readonly assetCellPops: ITransactionAssetCell;

  amount: CompoundLabel | string;

  statusTooltipMessage?: string | undefined;

  customAssetId?: string | undefined;

  color?: string | undefined;

  source: string | CompoundLabel;

  destination: string | CompoundLabel;

  protected static readonly assetIconSize = AssetByNetworkIconSize['28px'];

  constructor(transaction: TransactionResponseModel) {
    this.id = transaction.id;
    this.transactionId = transaction.externalId || '';
    this.paymentId = transaction.paymentId;
    this.date = formatDate(transaction.date);
    this.time = getTime(transaction.date);
    this.type = transaction.paymentType as PaymentType;
    this.status = transaction.status as TransactionStatus;
    this.statusCellProps = mapTransactionStatusToStatusCellProps(this.status);
    this.assetCellPops = {
      asset: {
        imageUrl: transaction.assetIconXs,
        shortName: getDynamicAssetLabel(LabelLength.short, transaction.assetTicker, transaction.assetName),
        longName: getDynamicAssetLabel(LabelLength.long, transaction.assetTicker, transaction.assetName),
        defaultAssetIcon: getStaticDefaultIconForTransaction(
          transaction.assetTicker,
          TransactionBaseViewModel.assetIconSize,
          [
            PaymentType.privateSecurityDeposit.toLowerCase(),
            PaymentType.privateSecurityInternal.toLowerCase(),
            PaymentType.privateSecurityWithdrawal.toLowerCase(),
          ].includes(transaction.paymentType.toLowerCase()),
        ),
      },
      size: mapAssetByNetworkIconSizeToNumber(TransactionBaseViewModel.assetIconSize),
    };
    this.amount = getStandardAmountFormatting(transaction);

    this.source = transaction.sourceDisplayValue ?? i18n.t('transactionsPage.noData');
    this.destination = transaction.destinationDisplayValue ?? i18n.t('transactionsPage.noData');
  }
}
