import { styled } from '@mui/material/styles';
import { ColorPartial, PaletteColor } from '@mui/material/styles/createPalette';
import { Color } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';
import { PillowProps } from './types';

export const StyledDiv = styled('div', {
  shouldForwardProp: prop => prop !== 'color' && prop !== 'colorType',
})<PillowProps>(({ theme, color, colorType }) => {
  let backgroundColor: string;
  if (color === 'grey') {
    const colorPartial = theme.palette[color] as ColorPartial;
    backgroundColor = colorPartial[colorType as keyof Color]!;
  } else {
    const paletteColor = theme.palette[color] as PaletteColor;
    backgroundColor = paletteColor[colorType as keyof PaletteColor]!;
  }

  return {
    paddingInline: pxToRem(theme.gaps._12px),
    paddingBlock: pxToRem(theme.gaps._8px / 3),
    borderRadius: pxToRem(theme.gaps._16px),
    backgroundColor,
  };
});
