import HelperErrorIcon from '@mui/icons-material/Close';
import { css } from '@mui/material';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { pxToRem } from 'elements/utils';

export const StyledHelperErrorIcon = elementsStyled(HelperErrorIcon)(
  ({ theme }) =>
    css`
      color: ${theme.palette.error.main};
      height: ${pxToRem(20)};
      flex-basis: ${pxToRem(20)};
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: ${pxToRem(4)};
    `,
);
