import { UserLimitType } from 'models/enums/user/user-limit-type';
import { LimitInputType } from 'ui-enums/create-user-form/limit-input-type';

export const mapUserLimitTypeToLimitInputType = (limitType: UserLimitType): LimitInputType => {
  switch (limitType) {
    case UserLimitType.initAmount:
    case UserLimitType.approveAmount:
      return LimitInputType.amount;
    case UserLimitType.initCount:
    case UserLimitType.approveCount:
      return LimitInputType.count;
    default:
      return LimitInputType.count;
  }
};
