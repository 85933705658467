import React from 'react';
import { Document as _Document, pdfjs } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist';
import useResize from 'hooks/use-resize';
import { TOOLS_HEIGHT } from '../DocumentViewerModal/styled';
import { StyledPage } from './styled';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Document: any = _Document;

interface DocumentViwerPDFProps {
  doc: string;
  currentPage: number;
  handleDocumentLoadSuccess: (pdf: PDFDocumentProxy) => void;
}

const PDFViewer: React.FC<DocumentViwerPDFProps> = ({ doc, currentPage, handleDocumentLoadSuccess }) => {
  const { height } = useResize();
  const pageHeight = height - TOOLS_HEIGHT;

  return (
    <Document file={doc} onLoadSuccess={handleDocumentLoadSuccess} loading="">
      <StyledPage pageNumber={currentPage} height={pageHeight} />
    </Document>
  );
};

export default PDFViewer;
