import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { ReactComponent as ErrorIcon } from 'assets/icons/doc-viewer/doc-model-error.svg';
import { StyledColumn } from './styled';

const DocumentViewerModalError: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledColumn>
      <ErrorIcon />
      <Typography color="background.paper" variant="h5">
        {t('documentsList.errorModalTitle')}
      </Typography>
      <Typography color="grey.300" variant="bodyDefaultBook">
        {t('documentsList.errorModalSubtitle')}
      </Typography>
    </StyledColumn>
  );
};

export default DocumentViewerModalError;
