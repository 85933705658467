import React from 'react';
import { CardType } from 'models/enums/transactions/card-type';
import { ReactComponent as DefaultCardIcon } from 'assets/icons/card-types/default-card.svg';
import { ReactComponent as MasterCardIcon } from 'assets/icons/card-types/master-card.svg';
import { ReactComponent as AmericanExpressIcon } from 'assets/icons/card-types/american-express.svg';
import { ReactComponent as DiscoverIcon } from 'assets/icons/card-types/discover.svg';
import { ReactComponent as MaestroIcon } from 'assets/icons/card-types/maestro.svg';
import { ReactComponent as JcbIcon } from 'assets/icons/card-types/jcb.svg';
import { ReactComponent as DinnersClubInternationalIcon } from 'assets/icons/card-types/dinners-club-international.svg';
import { ReactComponent as VisaIcon } from 'assets/icons/card-types/visa.svg';

export const getCardTypeIcon = (cardNetwork?: string) => {
  switch (cardNetwork) {
    case CardType.masterCard1: {
      return <MasterCardIcon />;
    }
    case CardType.masterCard2: {
      return <MasterCardIcon />;
    }
    case CardType.visa: {
      return <VisaIcon />;
    }
    case CardType.americanExpress1: {
      return <AmericanExpressIcon />;
    }
    case CardType.americanExpress2: {
      return <AmericanExpressIcon />;
    }
    case CardType.americanExpress3: {
      return <AmericanExpressIcon />;
    }
    case CardType.discover1: {
      return <DiscoverIcon />;
    }
    case CardType.discover2: {
      return <DiscoverIcon />;
    }
    // @TODO check variants of MAESTRO cards with BE
    case CardType.maestroCard1: {
      return <MaestroIcon />;
    }
    case CardType.maestroCard2: {
      return <MaestroIcon />;
    }
    case CardType.maestroCard3: {
      return <MaestroIcon />;
    }
    case CardType.jsb: {
      return <JcbIcon />;
    }
    case CardType.dinnersClubInternational: {
      return <DinnersClubInternationalIcon />;
    }
    default: {
      return <DefaultCardIcon />;
    }
  }
};
