import { Paths } from 'elements/element-transfer/navigation/routes';
import { TransferFlow, WireRecipientInfoFormData } from 'elements/element-transfer/types';
import {
  resetWithdrawalWireAccountNumbers,
  resetWithdrawalWireBankInfo,
  setPage,
  setWithdrawalWireAmount,
  setWithdrawalWireAdditionalDetails,
  setWithdrawalWireRecipientInfo,
  useElement,
} from 'elements/element-transfer/contexts/Element';
import trimAndRemoveExtraSpacesFormData from 'elements/element-transfer/utils/wireWithdrawalUtils/trimAndRemoveExtraSpacesFormData';

const useWireWithdrawalRecipientInfoPage = () => {
  const { state, dispatch } = useElement();

  const initialFormData = state[TransferFlow.Withdrawal].WIRE.recipientInfo;

  const continueHandler = (data: WireRecipientInfoFormData) => {
    dispatch(setWithdrawalWireRecipientInfo(trimAndRemoveExtraSpacesFormData(data)));
    if (initialFormData.locationType !== data.locationType) {
      dispatch(resetWithdrawalWireBankInfo());
      dispatch(resetWithdrawalWireAccountNumbers());
      dispatch(setWithdrawalWireAmount(''));
      dispatch(setWithdrawalWireAdditionalDetails({ memo: '', note: '', purposeOfPayment: '' }));
    }
    dispatch(setPage(Paths.WithdrawalWireBankInfo));
  };

  const backHandler = () => {
    dispatch(setPage(Paths.WithdrawalWireRecipientSearch));
  };

  return { initialFormData, continueHandler, backHandler };
};

export default useWireWithdrawalRecipientInfoPage;
