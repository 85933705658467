import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SelectChangeEvent } from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CloseIcon } from 'assets/icons/grey-close-icon.svg';
import { BaseTextField } from 'components/shared/inputs/BaseTextField';
import { LoadingButtonWithSkeleton } from 'components/shared/buttons/LoadingButtonWithSkeleton';
import { Gap } from 'components/shared/Gap';
import { CustodialAccountLabel } from 'models/enums/custodial-accounts/custodial-account-label';
import { ICaNamingModal } from 'ui-interfaces/custodial-accounts-details/i-ca-naming-modal';
import {
  UpdateCustodialAccountDetailApiParams,
  useUpdateCustodialAccountDetailsMutation,
} from 'redux/api/api-custodial-accounts';
import { usePermissionBasedAccess } from 'hooks/use-permission-based-access';
import { DashboardRoles } from 'models/enums/user/dashboard-roles';
import { PATHS } from 'navigation/constants';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledIconButton,
  StyledMenuItem,
  StyledSelect,
  StyledTypography,
} from './styled';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  custodialAccountId: string;
  data: ICaNamingModal;
};

export const CaNamingModal: React.FC<Props> = ({ isOpen, onClose, custodialAccountId, data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // permissions - start
  const isCorporateAccountViewerAllowed = usePermissionBasedAccess([DashboardRoles.corporateAccountViewer]);
  // permissions - end

  const [caName, setCaName] = useState<string>(() => data.formable.inputs[0].value);
  const [isCaNameError, setIsCaNameError] = useState(() => !data.formable.inputs[0].isValid);

  const [caLabel, setCaLabel] = useState<CustodialAccountLabel>(
    () => data.formable.inputs[1].value as CustodialAccountLabel,
  );

  useEffect(() => {
    if (!isOpen) {
      // sync ui state with view models
      data.formable.inputStateCollector.reset();
      setCaName(data.formable.inputs[0].value);
      setIsCaNameError(!data.formable.inputs[0].isValid);
      setCaLabel(data.formable.inputs[1].value as CustodialAccountLabel);
    }
  }, [data.formable.inputStateCollector, data.formable.inputs, isOpen]);

  // request - start
  const [updateCustodialAccountDetails, { isLoading, isSuccess }] = useUpdateCustodialAccountDetailsMutation();

  useEffect(() => {
    if (isSuccess) {
      if (!isCorporateAccountViewerAllowed) {
        navigate(PATHS.TAPI.CUSTODIAL_ACCOUNTS);
      } else {
        onClose();
      }
    }
  }, [isCorporateAccountViewerAllowed, isSuccess, navigate, onClose]);
  // request - end

  // handlers - start
  const onCaNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    data.formable.inputs[0].setValue(e.target.value);
    setCaName(data.formable.inputs[0].value);
    setIsCaNameError(!data.formable.inputs[0].isValid);
  };

  const onCaNameBlur = () => {
    data.formable.inputs[0].setValue(data.formable.inputs[0].value);
    setIsCaNameError(!data.formable.inputs[0].isValid);
  };

  const onCaLabelChange = (e: SelectChangeEvent<unknown>) => {
    data.formable.inputs[1].setValue(e.target.value as CustodialAccountLabel);
    setCaLabel(data.formable.inputs[1].value as CustodialAccountLabel);
  };

  const onSaveClick = () => {
    if (!data.formable.inputStateCollector.isStateValid()) {
      return;
    }

    if (!data.formable.inputStateCollector.isStateChanged()) {
      onClose();
      return;
    }

    updateCustodialAccountDetails({
      custodialAccountId,
      requestData: data.formable.inputStateCollector.getState(),
      hasViewCorporatePermission: isCorporateAccountViewerAllowed,
    } as UpdateCustodialAccountDetailApiParams);
  };
  // handlers - end

  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <StyledDialogTitle variant="h5">
        {t('custodialAccountDetailsPage.caNamingModal.editAccount')}
        <StyledIconButton onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </StyledDialogTitle>
      <Gap size="_24px" />
      <StyledDialogContent>
        <StyledTypography>{t('custodialAccountDetailsPage.caNamingModal.accountName')}</StyledTypography>
        <Gap size="_16px" />
        <BaseTextField
          name={data.formable.inputs[0].id}
          value={caName}
          label={t('custodialAccountDetailsPage.caNamingModal.addAccountName')}
          error={data.formable.inputs[0].isTouched && isCaNameError}
          helperText={data.formable.inputs[0].isTouched && isCaNameError && data.formable.inputs[0].errorMessage}
          onChange={onCaNameChange}
          onBlur={onCaNameBlur}
        />
        <Gap size="_20px" />
        <StyledTypography>{t('custodialAccountDetailsPage.caNamingModal.accountLabel')}</StyledTypography>
        <Gap size="_16px" />
        <StyledSelect IconComponent={ExpandMoreIcon} value={caLabel} onChange={onCaLabelChange}>
          <StyledMenuItem value={CustodialAccountLabel.user}>
            {t('enumMapping.custodialAccountLabel.user')}
          </StyledMenuItem>
          <StyledMenuItem value={CustodialAccountLabel.corporate}>
            {t('enumMapping.custodialAccountLabel.corporate')}
          </StyledMenuItem>
        </StyledSelect>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" size="large" onClick={onClose}>
          <Typography variant="buttonMedium">
            {t('custodialAccountDetailsPage.caNamingModal.cancelButtonLabel')}
          </Typography>
        </Button>
        <Gap isHorizontal size="_16px" />
        <LoadingButtonWithSkeleton
          variant="contained"
          size="large"
          text={
            <Typography variant="buttonMedium" color="background.paper">
              {t('custodialAccountDetailsPage.caNamingModal.saveButtonLabel')}
            </Typography>
          }
          loading={isLoading}
          disabled={
            !data.formable.inputStateCollector.isStateValid() || !data.formable.inputStateCollector.isStateChanged()
          }
          onClick={onSaveClick}
        />
      </StyledDialogActions>
    </StyledDialog>
  );
};
