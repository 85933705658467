import React from 'react';
import { StyledAccordionDetails } from './styled';

type Props = {
  isLastItem: boolean;
};

export const ExpendSectionRow: React.FC<Props> = ({ children, isLastItem }) => {
  return <StyledAccordionDetails isLastItem={isLastItem}>{children}</StyledAccordionDetails>;
};
