import { FetchDocProps } from 'hooks/use-fetch-document';
import { IHeader } from 'hooks/use-fetch-document/utils/prepare-header';
import { API_BASE_URL } from 'redux/api/api-routes';

interface Props extends FetchDocProps {
  header: IHeader;
}

export const fetchDocumentData = async ({ header, type, identityId, documentId, sideId }: Props) => {
  const res = await fetch(`${API_BASE_URL}${type}/${identityId}/documents/${documentId}/side/${sideId}`, header);
  return res;
};
