import { IconButton, css, darken } from '@mui/material';
import { Button } from 'elements/components';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { preventForwardProps } from 'elements/utils';

export const StyledCopyButton = elementsStyled(Button)(
  ({ theme }) => css`
    width: 115px;
    color: ${theme.palette.secondary.main};
    border: 2px solid ${theme.palette.secondary.main};

    @media (hover: hover) {
      &:hover {
        color: ${darken(theme.palette.secondary.main, 0.25)};
        border-color: ${darken(theme.palette.secondary.main, 0.25)};
      }
    }

    @media (hover: none) {
      &:hover {
        color: ${theme.palette.secondary.main};
        border: 2px solid ${theme.palette.secondary.main};
      }
    }
  `,
);

export const StyledIconBottom = elementsStyled(
  IconButton,
  preventForwardProps(['isHide']),
)<{ isHide: boolean }>(
  ({ theme, isHide }) =>
    css`
      padding: 4px;
      color: ${isHide ? theme.palette.secondary.font : theme.palette.primary.main};
    `,
);
