import React from 'react';
import Typography from '@mui/material/Typography';
import i18n from 'localizations';
import { IWalletMultiAssetsCell } from 'ui-interfaces/wallets/i-wallet-multi-assets-cell';
import { MoreAssetsElement, StyledBox } from './styled';
import { WalletMultiAssetIcon } from './WalletMultiAssetIcon';
import { WalletMultiAssetTooltip } from './WalletMultiAssetTooltip';

const MAX_ASSETS_COUNT = 5;

type Props = {
  data: IWalletMultiAssetsCell;
};

export const WalletMultiAssetsCell: React.FC<Props> = ({ data }) => {
  if (data.assets.length === 0) {
    return (
      <Typography variant="bodyDefaultBook" color="grey.500">
        {i18n.t('custodialAccountsPage.noAssets')}
      </Typography>
    );
  }
  const arrOverFiveElements: string[] = [];

  const assetsArr = data.assets.reduce<React.ReactNode[]>((accumulator, a, index) => {
    if (index < MAX_ASSETS_COUNT) {
      accumulator.push(
        <WalletMultiAssetIcon
          key={a.id}
          imageUrl={a.imageUrl}
          tooltipMessage={a.longName}
          size={data.size}
          defaultAssetIcon={a.defaultAssetIcon}
        />,
      );
      return accumulator;
    }
    arrOverFiveElements.push(a.longName);
    return accumulator;
  }, []);

  return (
    <StyledBox display="flex">
      {assetsArr}
      {arrOverFiveElements.length > 0 && (
        <WalletMultiAssetTooltip messageArr={arrOverFiveElements}>
          <MoreAssetsElement>
            <Typography variant="overlineSmallBold" color="grey.600">
              +{arrOverFiveElements.length}
            </Typography>
          </MoreAssetsElement>
        </WalletMultiAssetTooltip>
      )}
    </StyledBox>
  );
};
