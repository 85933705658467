import i18n from 'localizations';
import { TransactionResponseModel } from 'models/response/transactions/transaction-response-model';
import { MarkOfAmount } from 'models/enums/transactions/mark-of-amount';

export const getSignOfTransaction = (transaction: TransactionResponseModel): string => {
  switch (transaction.markOfAmount) {
    case MarkOfAmount.negative:
      return i18n.t('signs.minus');
    case MarkOfAmount.positive:
      return i18n.t('signs.plus');
    default:
      return '';
  }
};
