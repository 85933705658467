import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, Typography } from '@mui/material';
import { FlatAlert } from './FlatAlert';

export const SuspensionBanner: React.FC = () => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(true);

  const onClose = () => {
    setIsExpanded(false);
  };

  return (
    <Collapse in={isExpanded}>
      <FlatAlert onClose={onClose}>
        <Typography variant="bodyDefaultMedium" color="background.paper">
          {t('custodialAccountDetailsPage.ownersIdentitySuspended')}
        </Typography>
      </FlatAlert>
    </Collapse>
  );
};
