import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { ProtectedDestination } from 'navigation/utils/types';

export const ProtectedOutlet: React.FC<ProtectedDestination> = ({ fallbackRoute, fallbackCondition }) => {
  if (fallbackCondition) {
    return <Navigate to={fallbackRoute} replace />;
  }

  return <Outlet />;
};
