import React, { useEffect } from 'react';
import { pxToRem } from 'utils/styling-utils';
import { isOverflown } from 'utils/helpers';
import { Column } from 'components/shared/Flex/Column';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import { FooterTooltipInfoColumn } from '../FooterTooltipInfoColumn';
import { StyledSubtitleTypography, StyledTitleTypography } from './styled';

const MAX_WIDTH = 156;

type Props = {
  title: string;
  subtitle: string;
};

export const InfoColumn: React.FC<Props> = ({ title, subtitle }) => {
  const titleRef = React.useRef(null);
  const subtitleRef = React.useRef(null);
  const [showTooltip, setShowTooltip] = React.useState(false);

  useEffect(() => {
    const isTitleCurrentlyOverflown = isOverflown(titleRef.current!);
    const isSubtitleCurrentlyOverflown = isOverflown(subtitleRef.current!);

    setShowTooltip(isTitleCurrentlyOverflown || isSubtitleCurrentlyOverflown);
  }, [title, subtitle]);

  return (
    <CommonTooltip
      placement="right"
      title={<FooterTooltipInfoColumn title={title} subtitle={subtitle} isVisible />}
      disableHoverListener={!showTooltip}
      disableFocusListener={!showTooltip}
      disableTouchListener={!showTooltip}
    >
      <Column
        sx={{
          maxWidth: pxToRem(MAX_WIDTH),
        }}
      >
        <StyledTitleTypography ref={titleRef}>{title}</StyledTitleTypography>
        <StyledSubtitleTypography ref={subtitleRef}>{subtitle}</StyledSubtitleTypography>
      </Column>
    </CommonTooltip>
  );
};
