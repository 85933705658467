import { useRef, useCallback, useEffect } from 'react';
import { WindowWithFortressElementsJS } from 'elements/models/types/window-with-fortress-elemets-js';
import { ElementsJwtTypeModel } from 'models/enums/identities/elements-jwt-type-model';
import { ElementClient } from 'elements/models/types/element-client';
import { ElementResult } from 'elements/models/types/element-result';
import { ElementConfig } from 'elements/models/types/config/base/element-config';
import { useLazyGetJwtForElementQuery } from 'redux/api/api-custodial-accounts';

export const useCreateElement = <T extends ElementConfig = ElementConfig>(
  custodialAccountId: string,
  elementType: ElementsJwtTypeModel,
  elementConfig: T, // should be memoized with useMemo, e.g.  const config = useMemo<ElementConfigTrade>(() => ({ elementName: ElementName.TRADE, tradeDirection: TradeDirection.buy }), []);
  onDoneCb?: (elementType: ElementsJwtTypeModel, result?: ElementResult) => void,
) => {
  // setup - start
  const [fetchJwtForElementTrigger, jwtForElementResult] = useLazyGetJwtForElementQuery();

  const fetchJwtForElement = useCallback(
    () => fetchJwtForElementTrigger({ elementType, custodialAccountId }, false),
    [fetchJwtForElementTrigger, elementType, custodialAccountId],
  );

  const element = useRef<ElementClient | null>(null);
  // setup - end

  useEffect(() => {
    if (jwtForElementResult.isError) {
      element.current?.destroy();
      element.current = null;
    }
    if (jwtForElementResult.isSuccess && !jwtForElementResult.isFetching) {
      element.current?.run(jwtForElementResult.data.jwt);
    }
  }, [jwtForElementResult]);

  useEffect(
    () => () => {
      element.current?.destroy();
    },
    [],
  );

  const runElement = useCallback(() => {
    if (typeof (window as WindowWithFortressElementsJS).FortressElementsJS !== 'undefined') {
      const EJS = (window as WindowWithFortressElementsJS).FortressElementsJS;
      element.current = EJS.createElementClient(elementConfig);
      element.current?.done(result => {
        element.current?.destroy();
        onDoneCb?.(elementType, result);
      });
    } else {
      // no need to make api call if elements are not connected
      return;
    }

    fetchJwtForElement();
  }, [elementConfig, elementType, fetchJwtForElement, onDoneCb]);

  return {
    runElement,
    isLoading: jwtForElementResult.isFetching,
    error: jwtForElementResult.error,
  };
};
