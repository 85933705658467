import i18n from 'localizations';
import { DashboardRoles } from 'models/enums/user/dashboard-roles';

export const getUserPermissionLabel = (p: DashboardRoles) => {
  switch (p) {
    case DashboardRoles.paymentApprover:
      return i18n.t('permission.approveTransactions');
    case DashboardRoles.paymentInitiator:
      return i18n.t('permission.initiateTransactions');
    case DashboardRoles.userManager:
      return i18n.t('permission.usersManagement');
    case DashboardRoles.corporateAccountViewer:
      return i18n.t('permission.viewCorporateAccounts');
    case DashboardRoles.pii:
      return i18n.t('permission.pii');
    case DashboardRoles.adminManager:
      return i18n.t('permission.adminManager');
    case DashboardRoles.caEditor:
      return i18n.t('permission.caEditor');
    case DashboardRoles.accountSuspender:
      return i18n.t('permission.accountSuspender');
    case DashboardRoles.identitySuspender:
      return i18n.t('permission.identitySuspender');
    default:
      return i18n.t('notImplemented');
  }
};
