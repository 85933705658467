import i18n from 'localizations';
import { NetworkChain } from 'ui-enums/response/network-chain';

export const getNetworkChainLabel = (nc: NetworkChain) => {
  switch (nc.toLowerCase()) {
    case NetworkChain.bitcoinMainnet.toLowerCase():
    case NetworkChain.bitcoinTestnet.toLowerCase():
      return i18n.t('cryptoAssets.btc.long');

    case NetworkChain.bnbSmartChainMainnet.toLowerCase():
    case NetworkChain.bnbSmartChainTestnet.toLowerCase():
      return i18n.t('networkChain.bnb.long');

    case NetworkChain.mainnet.toLowerCase():
    case NetworkChain.goerli.toLowerCase():
    case NetworkChain.sepolia.toLowerCase():
    case NetworkChain.kovan.toLowerCase():
    case NetworkChain.rinkeby.toLowerCase():
      return i18n.t('cryptoAssets.eth.long');

    case NetworkChain.polygonMumbai.toLowerCase():
    case NetworkChain.polygonMainnet.toLowerCase():
    case NetworkChain.polygonAmoy.toLowerCase():
      return i18n.t('cryptoAssets.matic.long');

    case NetworkChain.solanaMainnet.toLowerCase():
    case NetworkChain.solanaDevnet.toLowerCase():
    case NetworkChain.solanaTestnet.toLowerCase():
      return i18n.t('cryptoAssets.sol.long');

    case NetworkChain.polkadotWestend.toLowerCase():
    case NetworkChain.polkadotMainnet.toLowerCase():
      return i18n.t('cryptoAssets.dot.long');

    case NetworkChain.cardanoMainnet.toLowerCase():
    case NetworkChain.cardanoTestnet.toLowerCase():
      return i18n.t('cryptoAssets.ada.long');

    case NetworkChain.avalancheFuji.toLowerCase():
    case NetworkChain.avalancheMainnet.toLowerCase():
      return i18n.t('cryptoAssets.avax.long');

    case NetworkChain.algorandMainnet.toLowerCase():
    case NetworkChain.algorandTestnet.toLowerCase():
      return i18n.t('cryptoAssets.algo.long');

    case NetworkChain.stellarMainnet.toLowerCase():
    case NetworkChain.stellarTestnet.toLowerCase():
      return i18n.t('cryptoAssets.xlm.long');

    case NetworkChain.litecoinMainnet.toLowerCase():
    case NetworkChain.litecoinTestnet.toLowerCase():
      return i18n.t('cryptoAssets.ltc.long');

    case NetworkChain.dogeMainnet.toLowerCase():
    case NetworkChain.dogeTestnet.toLowerCase():
      return i18n.t('cryptoAssets.doge.long');

    case NetworkChain.cosmosHub.toLowerCase():
    case NetworkChain.cosmosHubTestnet.toLowerCase():
      return i18n.t('cryptoAssets.atom.long');

    case NetworkChain.eosMainnet.toLowerCase():
    case NetworkChain.eosTestnet.toLowerCase():
      return i18n.t('cryptoAssets.eos.long');

    case NetworkChain.tezosMainnet.toLowerCase():
    case NetworkChain.tezosTestnet.toLowerCase():
      return i18n.t('cryptoAssets.xtz.long');

    case NetworkChain.nearMainnet.toLowerCase():
    case NetworkChain.nearTestnet.toLowerCase():
      return i18n.t('cryptoAssets.near.long');

    case NetworkChain.tronMainnet.toLowerCase():
    case NetworkChain.tronTestnet.toLowerCase():
      return i18n.t('cryptoAssets.trx.long');

    case NetworkChain.arbitrumGoerli.toLowerCase():
    case NetworkChain.arbitrumSepolia.toLowerCase():
    case NetworkChain.arbitrumMainnet.toLowerCase():
      return i18n.t('cryptoAssets.usdcArbitrum.long');

    case NetworkChain.bchMainnet.toLowerCase():
    case NetworkChain.bchTestnet.toLowerCase():
      return i18n.t('cryptoAssets.bch.long');

    case NetworkChain.rippleMainnet.toLowerCase():
    case NetworkChain.rippleTestnet.toLowerCase():
      return i18n.t('networkChain.xrp.long');

    case NetworkChain.flowMainnet.toLowerCase():
    case NetworkChain.flowTestnet.toLowerCase():
      return i18n.t('cryptoAssets.flow.long');

    case NetworkChain.hederaMainnet.toLowerCase():
    case NetworkChain.hederaTestnet.toLowerCase():
      return i18n.t('cryptoAssets.hbar.long');

    default:
      return i18n.t('notImplemented');
  }
};
