import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import {
  CommonLayoutContainer,
  CommonLayoutHeader,
  CommonLayoutContent,
  CommonPageTitle,
} from 'components/shared/layouts/CommonLayout';
import { Gap } from 'components/shared/Gap';
import { StyledTab, StyledTabs } from 'components/shared/Tabs';
import { IdentitiesPageTabs } from 'ui-enums/tabs/identities/identities-page-tabs';
import { BusinessIdentitiesTab } from './Tabs/BusinessIdentitiesTab';
import { PersonalIdentitiesTab } from './Tabs/PersonalIdentitiesTab';

type Props = {
  tab: IdentitiesPageTabs;
  setTab: (tab: IdentitiesPageTabs) => void;
};

export const IdentitiesPageContent: React.FC<Props> = ({ tab, setTab }) => {
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newTab: IdentitiesPageTabs) => {
    setTab(newTab);
  };

  return (
    <CommonLayoutContainer>
      <CommonLayoutHeader>
        <Gap size="_40px" />
        <CommonPageTitle>{t('identitiesPage.title')}</CommonPageTitle>
        <Gap size="_20px" />

        <Box>
          <StyledTabs value={tab} onChange={handleChange}>
            <StyledTab label={t('identitiesPage.personal')} value={IdentitiesPageTabs.personal} />
            <StyledTab label={t('identitiesPage.business')} value={IdentitiesPageTabs.business} />
          </StyledTabs>
        </Box>
      </CommonLayoutHeader>
      <CommonLayoutContent>
        {tab === IdentitiesPageTabs.personal && <PersonalIdentitiesTab />}
        {tab === IdentitiesPageTabs.business && <BusinessIdentitiesTab />}
      </CommonLayoutContent>
    </CommonLayoutContainer>
  );
};
