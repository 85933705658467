import { styled } from '@mui/material/styles';
import { Skeleton } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';
import { Row } from 'components/shared/Flex/Row';

export const StyledIdentityDetailsTitleRowSkeleton = styled(Skeleton)(({ theme }) => ({
  height: pxToRem(theme.gaps._32px),
  width: pxToRem(320),
  backgroundColor: theme.palette.grey['100'],
  borderRadius: pxToRem(theme.gaps._20px),
}));

export const StyledRow = styled(Row)(({ theme }) => ({
  alignItems: 'center',
  height: pxToRem(theme.gaps._32px),
}));
