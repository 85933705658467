import { css } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Button } from 'elements/components';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledButton = elementsStyled(Button)`
  width: 30%;
`;

export const StyledLoaderContainer = elementsStyled(Box)(css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
`);
