import { styled } from '@mui/material/styles';
import { AccordionDetails } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';

export const StyledAccordionDetails = styled(AccordionDetails, {
  shouldForwardProp: prop => prop !== 'isLastItem',
})<{ isLastItem: boolean }>(({ theme, isLastItem }) => ({
  padding: 0,
  marginBottom: isLastItem ? `-${pxToRem(theme.gaps._32px)}` : 0,
}));
