import React from 'react';
import { RightCell } from 'components/tapi/transactions/TapiTransactionDetailsPage/pdf/rows/shared/RightCell';
import { Row } from 'components/tapi/transactions/TapiTransactionDetailsPage/pdf/rows/shared/Row';
import { IPdfMt103Indent2Row } from 'ui-interfaces/transaction-details/pdf/rows/mt-103/i-pdf-mt103-indent-2-row';
import { LeftCell } from './LeftCell';

type Props = {
  data: IPdfMt103Indent2Row;
};

export const PdfMT103Indent2Row: React.FC<Props> = ({ data }) => {
  return (
    <Row>
      <LeftCell value={data.label} />
      <RightCell value={data.value} />
    </Row>
  );
};
