import i18n from 'localizations';
import { BusinessIdentityIndustry } from 'models/enums/identities/business-identity-industry';

export const formatEnumerableIdentityIndustry = (industry: BusinessIdentityIndustry) => {
  switch (industry) {
    case BusinessIdentityIndustry.b2B: {
      return i18n.t('identityDetailsPage.industry.b2B');
    }
    case BusinessIdentityIndustry.b2C: {
      return i18n.t('identityDetailsPage.industry.b2C');
    }
    case BusinessIdentityIndustry.accounting: {
      return i18n.t('identityDetailsPage.industry.accounting');
    }
    case BusinessIdentityIndustry.aerospace: {
      return i18n.t('identityDetailsPage.industry.aerospace');
    }
    case BusinessIdentityIndustry.agriculture: {
      return i18n.t('identityDetailsPage.industry.agriculture');
    }
    case BusinessIdentityIndustry.aquaticsMarine: {
      return i18n.t('identityDetailsPage.industry.aquaticsMarine');
    }
    case BusinessIdentityIndustry.automotive: {
      return i18n.t('identityDetailsPage.industry.automotive');
    }
    case BusinessIdentityIndustry.businessServices: {
      return i18n.t('identityDetailsPage.industry.businessServices');
    }
    case BusinessIdentityIndustry.charity: {
      return i18n.t('identityDetailsPage.industry.charity');
    }
    case BusinessIdentityIndustry.computerSoftware: {
      return i18n.t('identityDetailsPage.industry.computerSoftware');
    }
    case BusinessIdentityIndustry.construction: {
      return i18n.t('identityDetailsPage.industry.construction');
    }
    case BusinessIdentityIndustry.cryptoBlockchain: {
      return i18n.t('identityDetailsPage.industry.cryptoBlockchain');
    }
    case BusinessIdentityIndustry.education: {
      return i18n.t('identityDetailsPage.industry.education');
    }
    case BusinessIdentityIndustry.energy: {
      return i18n.t('identityDetailsPage.industry.energy');
    }
    case BusinessIdentityIndustry.engineering: {
      return i18n.t('identityDetailsPage.industry.engineering');
    }
    case BusinessIdentityIndustry.entertainment: {
      return i18n.t('identityDetailsPage.industry.entertainment');
    }
    case BusinessIdentityIndustry.environmentGreen: {
      return i18n.t('identityDetailsPage.industry.environmentGreen');
    }
    case BusinessIdentityIndustry.farming: {
      return i18n.t('identityDetailsPage.industry.farming');
    }
    case BusinessIdentityIndustry.fashion: {
      return i18n.t('identityDetailsPage.industry.fashion');
    }
    case BusinessIdentityIndustry.financialServices: {
      return i18n.t('identityDetailsPage.industry.financialServices');
    }
    case BusinessIdentityIndustry.franchise: {
      return i18n.t('identityDetailsPage.industry.franchise');
    }
    case BusinessIdentityIndustry.gaming: {
      return i18n.t('identityDetailsPage.industry.gaming');
    }
    case BusinessIdentityIndustry.gigEconomy: {
      return i18n.t('identityDetailsPage.industry.gigEconomy');
    }
    case BusinessIdentityIndustry.government: {
      return i18n.t('identityDetailsPage.industry.government');
    }
    case BusinessIdentityIndustry.hospitality: {
      return i18n.t('identityDetailsPage.industry.hospitality');
    }
    case BusinessIdentityIndustry.informationTechnology: {
      return i18n.t('identityDetailsPage.industry.informationTechnology');
    }
    case BusinessIdentityIndustry.insurance: {
      return i18n.t('identityDetailsPage.industry.insurance');
    }
    case BusinessIdentityIndustry.internet: {
      return i18n.t('identityDetailsPage.industry.internet');
    }
    case BusinessIdentityIndustry.international: {
      return i18n.t('identityDetailsPage.industry.international');
    }
    case BusinessIdentityIndustry.lawEnforcementMilitary: {
      return i18n.t('identityDetailsPage.industry.lawEnforcementMilitary');
    }
    case BusinessIdentityIndustry.lifestyle: {
      return i18n.t('identityDetailsPage.industry.lifestyle');
    }
    case BusinessIdentityIndustry.manufacturing: {
      return i18n.t('identityDetailsPage.industry.manufacturing');
    }
    case BusinessIdentityIndustry.marketingServices: {
      return i18n.t('identityDetailsPage.industry.marketingServices');
    }
    case BusinessIdentityIndustry.mediaPublishing: {
      return i18n.t('identityDetailsPage.industry.mediaPublishing');
    }
    case BusinessIdentityIndustry.medical: {
      return i18n.t('identityDetailsPage.industry.medical');
    }
    case BusinessIdentityIndustry.mobileApps: {
      return i18n.t('identityDetailsPage.industry.mobileApps');
    }
    case BusinessIdentityIndustry.music: {
      return i18n.t('identityDetailsPage.industry.music');
    }
    case BusinessIdentityIndustry.naturalResources: {
      return i18n.t('identityDetailsPage.industry.naturalResources');
    }
    case BusinessIdentityIndustry.networkMarketing: {
      return i18n.t('identityDetailsPage.industry.networkMarketing');
    }
    case BusinessIdentityIndustry.performingArts: {
      return i18n.t('identityDetailsPage.industry.performingArts');
    }
    case BusinessIdentityIndustry.personalServices: {
      return i18n.t('identityDetailsPage.industry.personalServices');
    }
    case BusinessIdentityIndustry.professionalServices: {
      return i18n.t('identityDetailsPage.industry.professionalServices');
    }
    case BusinessIdentityIndustry.realEstate: {
      return i18n.t('identityDetailsPage.industry.realEstate');
    }
    case BusinessIdentityIndustry.recreation: {
      return i18n.t('identityDetailsPage.industry.recreation');
    }
    case BusinessIdentityIndustry.retail: {
      return i18n.t('identityDetailsPage.industry.retail');
    }
    case BusinessIdentityIndustry.royalties: {
      return i18n.t('identityDetailsPage.industry.royalties');
    }
    case BusinessIdentityIndustry.science: {
      return i18n.t('identityDetailsPage.industry.science');
    }
    case BusinessIdentityIndustry.securities: {
      return i18n.t('identityDetailsPage.industry.securities');
    }
    case BusinessIdentityIndustry.shippingTransportation: {
      return i18n.t('identityDetailsPage.industry.shippingTransportation');
    }
    case BusinessIdentityIndustry.software: {
      return i18n.t('identityDetailsPage.industry.software');
    }
    case BusinessIdentityIndustry.storageWarehousing: {
      return i18n.t('identityDetailsPage.industry.storageWarehousing');
    }
    case BusinessIdentityIndustry.supplier: {
      return i18n.t('identityDetailsPage.industry.supplier');
    }
    case BusinessIdentityIndustry.technology: {
      return i18n.t('identityDetailsPage.industry.technology');
    }
    case BusinessIdentityIndustry.transportation: {
      return i18n.t('identityDetailsPage.industry.transportation');
    }
    case BusinessIdentityIndustry.wholesale: {
      return i18n.t('identityDetailsPage.industry.wholesale');
    }
    case BusinessIdentityIndustry.art: {
      return i18n.t('identityDetailsPage.industry.art');
    }
    default:
      return i18n.t('common.notImplemented');
  }
};
