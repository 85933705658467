// IMPORTANT card types enum is based on data from Trust DB;
export enum CardType {
  masterCard1 = 'Mastercard',
  masterCard2 = 'MASTERCARD',

  visa = 'Visa',

  americanExpress1 = 'American Express',
  americanExpress2 = 'AMERICANEXPRESS',
  americanExpress3 = 'AmEx',

  discover1 = 'Discover',
  discover2 = 'DISCOVER',

  // @TODO check variants of MAESTRO cards with BE
  maestroCard1 = 'Maestro',
  maestroCard2 = 'MAESTRO',
  maestroCard3 = 'maestro',

  jsb = 'JCB',
  dinnersClubInternational = 'Diners Club International',

  defaultCard = 'defaultCard',
}
