import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { DashboardRoles } from 'models/enums/user/dashboard-roles';
import { formatAddress } from 'containers/identities/utils/format-address';
import { AddressModel } from 'models/response/address-model';
import { formatObfuscatedIdentityAddress } from './format-obfuscated-identity-address';

export const formatIdentityAddress = (user: ShortUserResponseModel, address?: AddressModel) => {
  if (!user.permissions.includes(DashboardRoles.pii)) {
    return formatObfuscatedIdentityAddress(address);
  }

  return formatAddress(address);
};
