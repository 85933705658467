import { SideMenu } from 'ui-interfaces/side-menu/side-menu';
import { SideMenuListNode } from 'ui-interfaces/side-menu/side-menu-list-node';
import { SideMenuBaseViewModel } from 'view-models/side-menu/base/side-menu-base-vm';
import { PATHS } from 'navigation/constants';

export class SideMenuNotImplementedViewModel extends SideMenuBaseViewModel implements SideMenu {
  readonly nodes: SideMenuListNode[] = [];

  readonly initialNavigationPath: string = PATHS.TAPI.CUSTODIAL_ACCOUNTS;
}
