import React, { useState, useEffect } from 'react';
import i18n from 'localizations';
import { ReactComponent as NoResultsIcon } from 'assets/icons/grid/no-results.svg';
import { ReactComponent as NoFilteredResultsIcon } from 'assets/icons/grid/no-filtered-results.svg';
import { IconWithLabels } from '../IconWithLabels';
import { AutoHeightNoResultsOverlay } from '../AutoHeightNoResultsOverlay';

type Props = {
  title: string;
  subtitle: string;
  noFilteredData: boolean;
  isAutoHeight?: boolean;
};
export const CustomNoResultsOverlay: React.FC<Props> = ({ noFilteredData, isAutoHeight, ...otherProps }) => {
  // https://github.com/mui/mui-x/issues/4309
  // Workaround
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 300);
    return () => clearTimeout(timeout);
  }, []);

  if (!show) return null;

  if (noFilteredData) {
    if (isAutoHeight) {
      return (
        <AutoHeightNoResultsOverlay
          title={i18n.t('filters.noFilteredResultsTitle')}
          subtitle={i18n.t('filters.noFilteredResultsSubtitle')}
        />
      );
    }

    return (
      <IconWithLabels
        icon={<NoFilteredResultsIcon />}
        title={i18n.t('filters.noFilteredResultsTitle')}
        subtitle={i18n.t('filters.noFilteredResultsSubtitle')}
      />
    );
  }

  if (isAutoHeight) {
    return <AutoHeightNoResultsOverlay title={otherProps.title} subtitle={otherProps.subtitle} />;
  }

  return <IconWithLabels icon={<NoResultsIcon />} {...otherProps} />;
};
