import React from 'react';
import { Typography } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';
import { PropertyContainer } from './styled';

type Props = {
  label: string;
  value: string;
  isLast: boolean;
  index: number;
};

export const PropertyItem: React.FC<Props> = ({ label, value, isLast, index }) => {
  return (
    <PropertyContainer isLast={isLast} index={index}>
      <Typography
        variant="overlineSmallBold"
        color="grey.500"
        overflow="hidden"
        textOverflow="ellipsis"
        paddingBottom={pxToRem(4)}
      >
        {label}
      </Typography>
      <Typography variant="bodyDefaultMedium" color="grey.700" overflow="hidden" textOverflow="ellipsis">
        {value}
      </Typography>
    </PropertyContainer>
  );
};
