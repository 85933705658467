import { alpha, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Column } from 'components/shared/Flex/Column';
import { pxToRem } from 'utils/styling-utils';

export const TOOLS_HEIGHT = 88;

export const transformContentStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const Overlay = styled('div')(({ theme }) => ({
  backgroundColor: alpha(theme.palette.grey[700], 0.9),
  position: 'fixed',
  zIndex: theme.zIndex.drawer + 2,
  left: 0,
  top: 0,
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: pxToRem(TOOLS_HEIGHT),
}));

export const Tools = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[700],
  opacity: 1,
  display: 'flex',
  justifyContent: 'space-between',
  padding: pxToRem(32),
  width: '100%',
  height: pxToRem(TOOLS_HEIGHT),
  position: 'fixed',
  zIndex: theme.zIndex.drawer + 3,
  left: 0,
  top: 0,
}));

export const ModalContent = styled('div')(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 4,
  padding: pxToRem(32),
  opacity: 1,
}));

export const CloseBtn = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.grey[600],
  '&:hover': {
    borderColor: theme.palette.grey[600],
  },
}));

export const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledColumn = styled(Column)(({ theme }) => ({
  width: pxToRem(448),
  height: pxToRem(286),
  backgroundColor: alpha(theme.palette.background.paper, 0.08),
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledLoader = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.background.paper,
}));
