import { PaymentMethodTypesMap } from 'elements/features/PaymentMethods/utils/getPaymentMethodTypesMap';
import { CustodialAccount, ExternalAccount, PaymentAccount } from 'elements/types';
import { BuyCoin, SellCoin, TradeDirectionEnum, TradeFlow } from 'elements/element-trade/types';
import { Paths } from 'elements/element-trade/navigation/routes';
import { PaymentType } from 'ui-enums/response/transactions/payment-type';
import { AssetResponseModel } from 'models/response/assets/asset-response-model';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import { TradingPriceResponseModel } from 'models/response/trades/trading-price-response-model';

type FlowDetailsType<F> = {
  amount: string;
  coinsList: F[];
  coinsBuySellList: AssetResponseModel[] | CustodialAccountBalanceModel[];
  selectedCoin: null | F;
  selectedBuySellCoin: AssetResponseModel | CustodialAccountBalanceModel | null;
  coinPrice: number;
  custodialAccountId?: string;
  note: string;
};

export type StateType = {
  isElementStarted: boolean;
  isOrderSucceed: boolean;
  isOrderFailed: boolean;
  failedOrderMessage: string;
  failedOrderTraceId: string;
  shouldPriceReload: boolean;
  coinPriceError: boolean;

  tradeDirection: TradeDirectionEnum;
  flowType: TradeFlow;

  [TradeFlow.Buy]: Omit<FlowDetailsType<BuyCoin>, 'custodialAccountId'>;
  [TradeFlow.Sell]: FlowDetailsType<SellCoin>;

  custodialAccountList: CustodialAccount[];
  externalAccountList: ExternalAccount[];
  paymentAccount: PaymentAccount;
  sellPaymentId: string | null;
  buyTradePaymentId: string | null;
  buyDepositPaymentId: string | null;
  contactUsUrl: null | string;
  paymentMethodTypesMap: PaymentMethodTypesMap;
  cryptoAssetNetwork: string;
  serviceUnavailable: boolean;
  custodialAccountId: string;
  identityId: string;
  lockId: string;
  paymentType: PaymentType | null;
  page: { location: Paths; state?: any };
};

export enum ACTION {
  SET_ELEMENT_STARTED = 'SET_ELEMENT_STARTED',
  SET_FLOW = 'SET_FLOW',
  SET_COINS_LIST = 'SET_COINS_LIST',
  SET_BUY_SELL_COINS_LIST = 'SET_BUY_SELL_COINS_LIST',
  SET_SELECTED_COIN = 'SET_SELECTED_COIN',
  SET_BUY_SELL_SELECTED_COIN = 'SET_BUY_SELL_SELECTED_COIN',
  SET_AMOUNT = 'SET_AMOUNT',
  SET_IS_ORDER_SUCCEED = 'SET_IS_ORDER_SUCCEED',
  SET_IS_ORDER_FAILED = 'SET_IS_ORDER_FAILED',
  SET_COIN_PRICE = 'SET_COIN_PRICE',
  SET_NOTE = 'SET_NOTE',
  SET_CUSTODIAL_ACCOUNTS = 'SET_CUSTODIAL_ACCOUNTS',
  SET_EXTERNAL_ACCOUNTS = 'SET_EXTERNAL_ACCOUNTS',
  SET_PAYMENT_ACCOUNT = 'SET_PAYMENT_ACCOUNT',
  SET_SHOULD_PRICE_RELOAD = 'SET_SHOULD_PRICE_RELOAD',
  SET_SELL_PAYMENT_ID = 'SET_SELL_PAYMENT_ID',
  SET_BUY_TRADE_PAYMENT_ID = 'SET_BUY_TRADE_PAYMENT_ID',
  SET_BUY_DEPOSIT_PAYMENT_ID = 'SET_BUY_DEPOSIT_PAYMENT_ID',
  SET_CUSTODIAL_ACCOUNT_ID = 'SET_CUSTODIAL_ACCOUNT_ID',
  SET_IS_COIN_PRICE_ERROR = 'SET_IS_COIN_PRICE_ERROR',
  SET_IS_SERVICE_UNAVAILABLE = 'SET_IS_SERVICE_UNAVAILABLE',
  SET_PAGE = 'SET_PAGE',
  SET_LOCK_ID = 'SET_LOCK_ID',
  SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE',
}

export type ActionType =
  | {
      type: ACTION.SET_ELEMENT_STARTED;
    }
  | {
      type: ACTION.SET_FLOW;
      payload: TradeFlow;
    }
  | {
      type: ACTION.SET_COINS_LIST;
      payload: { list: BuyCoin[] | SellCoin[]; flowType: TradeFlow };
    }
  | {
      type: ACTION.SET_BUY_SELL_COINS_LIST;
      payload: { list: AssetResponseModel[] | CustodialAccountBalanceModel[]; flowType: TradeFlow };
    }
  | {
      type: ACTION.SET_SELECTED_COIN;
      payload: { coin: BuyCoin | SellCoin | null; flowType?: TradeFlow };
    }
  | {
      type: ACTION.SET_BUY_SELL_SELECTED_COIN;
      payload: { coin: AssetResponseModel | CustodialAccountBalanceModel | null; flowType?: TradeFlow };
    }
  | {
      type: ACTION.SET_AMOUNT;
      payload: string;
    }
  | {
      type: ACTION.SET_COIN_PRICE;
      payload: TradingPriceResponseModel;
    }
  | {
      type: ACTION.SET_NOTE;
      payload: string;
    }
  | {
      type: ACTION.SET_IS_ORDER_SUCCEED;
      payload: boolean;
    }
  | {
      type: ACTION.SET_IS_ORDER_FAILED;
      payload: { isFailed: boolean; message?: string; traceId?: string };
    }
  | {
      type: ACTION.SET_CUSTODIAL_ACCOUNTS;
      payload: CustodialAccount[];
    }
  | {
      type: ACTION.SET_EXTERNAL_ACCOUNTS;
      payload: ExternalAccount[];
    }
  | {
      type: ACTION.SET_PAYMENT_ACCOUNT;
      payload: PaymentAccount;
    }
  | {
      type: ACTION.SET_PAYMENT_TYPE;
      payload: PaymentType;
    }
  | {
      type: ACTION.SET_SHOULD_PRICE_RELOAD;
      payload: boolean;
    }
  | {
      type: ACTION.SET_SELL_PAYMENT_ID;
      payload: string;
    }
  | {
      type: ACTION.SET_BUY_TRADE_PAYMENT_ID;
      payload: string;
    }
  | {
      type: ACTION.SET_BUY_DEPOSIT_PAYMENT_ID;
      payload: string;
    }
  | {
      type: ACTION.SET_CUSTODIAL_ACCOUNT_ID;
      payload: string;
    }
  | {
      type: ACTION.SET_IS_COIN_PRICE_ERROR;
      payload: boolean;
    }
  | {
      type: ACTION.SET_IS_SERVICE_UNAVAILABLE;
      payload: boolean;
    }
  | {
      type: ACTION.SET_PAGE;
      payload: { location: Paths; state?: any };
    }
  | {
      type: ACTION.SET_LOCK_ID;
      payload: string;
    };
