import { NavigateFunction, PathMatch } from 'react-router-dom';
import { GridRowParams } from '@mui/x-data-grid';
import { DownloadCSV } from 'hooks/use-export/interfaces';
import { FilterResult } from 'filters/interfaces/filter-result';
import { API_ROUTES } from 'redux/api/api-routes';
import { createTokenViewModel } from 'factories/tokens/create-token-view-model';
import { TokenResponseModel } from 'models/response/tokens/token-response-model';
import { PATHS, NESTED_PATHS } from 'navigation/constants';
import { getRouteWithParams } from 'utils/get-route-with-params';
import {
  TokenDetailsUrlParams,
  TokenDetailsUnderWalletDetailsUnderIdetitiDetailsUrlParams,
} from 'navigation/url-params-type';

export const onResponseModelTransform = (token: TokenResponseModel) => {
  return createTokenViewModel(token);
};

export const onExportClick = (downloadCsv: DownloadCSV, walletId: string) => (filterResult?: FilterResult) => {
  downloadCsv({ urlSegment: getRouteWithParams(API_ROUTES.private.WALLET_TOKENS, { walletId }), filterResult });
};

export const onRowClick =
  (navigate: NavigateFunction, walletId: string, pathMatch: PathMatch | null) => (params: GridRowParams) => {
    const { row } = params;

    if (pathMatch?.pattern.path === NESTED_PATHS.IDENTITY_DETAILS__WALLET_DETAILS) {
      const { identityType, identityId } =
        pathMatch.params as TokenDetailsUnderWalletDetailsUnderIdetitiDetailsUrlParams;
      navigate(
        getRouteWithParams(NESTED_PATHS.IDENTITY_DETAILS__TOKEN_DETAILS, {
          walletId,
          tokenId: row.id,
          identityId,
          identityType,
        } as TokenDetailsUnderWalletDetailsUnderIdetitiDetailsUrlParams),
      );
    }

    if (pathMatch?.pattern.path === PATHS.WAPI.WALLET_DETAILS) {
      navigate(getRouteWithParams(PATHS.WAPI.TOKEN_DETAILS, { walletId, tokenId: row.id } as TokenDetailsUrlParams));
    }
  };
