import { styled, css } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Row } from 'components/shared/Flex/Row';
import { pxToRem } from 'utils/styling-utils';

export const StyledContainer = styled('div')(
  ({ theme }) =>
    css`
      padding-bottom: ${pxToRem(theme.gaps._24px)};
      padding-inline: ${pxToRem(theme.gaps._20px)};
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    `,
);

export const StyledHeaderLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  paddingTop: pxToRem(theme.gaps._24px),
}));

export const StyledMainRow = styled(Row)(() => ({
  width: '100%',
  justifyContent: 'space-between',
}));
