import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Typography } from '@mui/material';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { preventForwardProps } from 'elements/utils';

export const StyledItem = elementsStyled(
  Box,
  preventForwardProps(['withDivider']),
)<{ withDivider?: boolean }>(
  ({ theme: { palette }, withDivider, onClick }) =>
    css`
      display: flex;
      width: 100%;
      align-items: center;
      gap: 16px;
      cursor: ${onClick ? 'pointer' : 'auto'};
      -webkit-tap-highlight-color: transparent;

      ${withDivider &&
      css`
        padding: 16px 0;
        border-bottom: 1px solid ${palette.tertiary.main};
      `}
    `,
);

export const StyledContainer = elementsStyled(Box)`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export const StyledRow = elementsStyled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledLeftSection = elementsStyled(Box)`
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1 100000 auto;
`;

export const StyledRightSection = elementsStyled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 0;
  flex: 1 1 auto;
`;

export const StyledAmountContainer = elementsStyled(Box)`
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
`;

export const StyledTypography = elementsStyled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledArrowForwardIosIcon = elementsStyled(ArrowForwardIosIcon)(
  ({ theme }) =>
    css`
      margin: 0 5px 0 21px;
      width: 14px;
      height: 14px;
      color: ${theme.palette.secondary.font};
    `,
);
