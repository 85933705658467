import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ContentBlock from 'components/tapi/transactions/TapiTransactionsPage';
import { TransactionsPageTabs } from 'ui-enums/tabs/transactions/transactions-page-tabs';
import { getUser } from 'redux/api/api-users';

export const TapiTransactionsPage: React.FC = () => {
  // tabs - start
  const { data: user, isSuccess } = useSelector(getUser.select(undefined));
  const [isTabsMode, setIsTabsMode] = useState(false);

  useEffect(() => {
    if (isSuccess && user) {
      setIsTabsMode(user.organization.paymentApprovalIsRequired);
    }
  }, [user, isSuccess]);

  const [tab, setTab] = useState(TransactionsPageTabs.all);
  const handleTabChange = (event: React.SyntheticEvent, newTab: TransactionsPageTabs) => {
    setTab(newTab);
  };
  // tabs - end

  return <ContentBlock isTabsMode={isTabsMode} tab={tab} handleTabChange={handleTabChange} />;
};
