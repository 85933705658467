import { KeyboardArrowRight } from '@mui/icons-material';
import { Button } from '@mui/material';
import { css } from '@mui/material/styles';
import { elementsStyled } from 'elements/theme/createCustomTheme';

export const StyledButton = elementsStyled(Button)`
  gap: 12px;
  height: 52px;
  padding: 6px 0;
  margin: 6px 0;

  &:hover {
    background: transparent;
  }
`;

export const StyledKeyboardArrowRight = elementsStyled(KeyboardArrowRight)(
  ({ theme }) => css`
    color: ${theme.palette.secondary.font};
  `,
);
