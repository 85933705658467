import { styled, css } from '@mui/material/styles';
import { pxToRem } from 'utils/styling-utils';
import { ReactComponent as HelperErrorAttention } from 'assets/icons/helper-error-attention.svg';

export const StylesHelperErrorAttentionIcon = styled(HelperErrorAttention)(
  css`
    height: ${pxToRem(18)};
    flex-basis: ${pxToRem(18)};
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: ${pxToRem(4)};
    margin-right: ${pxToRem(8)};
  `,
);
