import React from 'react';
import { Column } from 'components/shared/Flex/Column';
import { StyledFooterTitleTypography, StyledFooterSubtitleTypography } from './styled';

type Props = {
  title: string;
  subtitle: string;
  isVisible: boolean;
};

export const FooterTooltipInfoColumn: React.FC<Props> = ({ title, subtitle, isVisible }) => {
  return (
    <Column>
      <StyledFooterTitleTypography isVisible={isVisible}>{title}</StyledFooterTitleTypography>
      <StyledFooterSubtitleTypography isVisible={isVisible}>{subtitle}</StyledFooterSubtitleTypography>
    </Column>
  );
};
