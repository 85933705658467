import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Stack, Typography } from '@mui/material';
import { Divider, TextCopiedSnackbar } from 'elements/components';
import { copyToClipboard } from 'elements/utils';
import { StyledCopyButton, StyledIconBottom } from './styled';

type Props = {
  label: string;
  value: string;
  maskSymbol?: string;
  maskCharCount?: number;
  isMasked?: boolean;
};
const ValueWithCopyButton: React.FC<Props> = ({ label, value, maskSymbol = '*', maskCharCount, isMasked = true }) => {
  const [isHide, setIsHide] = useState(true);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const textSecurity = useMemo(
    () => maskSymbol?.repeat(maskCharCount ?? value.length),
    [maskCharCount, maskSymbol, value.length],
  );

  const toggleHideHandler = () => setIsHide(prev => !prev);

  const onCopyHandler = async (): Promise<void> => {
    await copyToClipboard(value);
    enqueueSnackbar('message', {
      content: <TextCopiedSnackbar message={t('elements.transfer.depositWireInstructionsPage.snackbarMessage')} />,
    });
  };
  return (
    <Stack gap="16px">
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="overlineDefaultMedium" color="secondary.font">
            {label}
          </Typography>
          <Stack direction="row" alignItems="center" gap="12px">
            <Typography variant="bodyDefaultMedium" color={isMasked && isHide ? 'secondary.font' : 'primary.font'}>
              {isHide && isMasked ? textSecurity : value}
            </Typography>
            {isMasked && (
              <StyledIconBottom isHide={isHide} onClick={toggleHideHandler}>
                {isHide ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
              </StyledIconBottom>
            )}
          </Stack>
        </Stack>
        <StyledCopyButton buttonType="secondary" onClick={onCopyHandler}>
          <Typography variant="buttonLarge">{t('elements.common.copy')}</Typography>
        </StyledCopyButton>
      </Stack>
      <Divider />
    </Stack>
  );
};

export default ValueWithCopyButton;
