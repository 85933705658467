import React from 'react';
import { Box } from '@mui/material';
import { pxToRem } from 'utils/styling-utils';
import { FileType } from 'ui-enums/file-type';
import { getFileTypeErrorIcon } from './utils/get-file-type-error-icon';
import { getFileTypeIcon } from './utils/get-file-type-icon';

interface Props {
  isLoadError?: boolean;
  type: FileType;
}

export const ImageThumbnailPlaceholder: React.FC<Props> = ({ type, isLoadError }) => {
  const mediaTypeIcon = isLoadError ? getFileTypeErrorIcon(type) : getFileTypeIcon(type);
  return (
    <Box
      height={pxToRem(144)}
      width={pxToRem(144)}
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="8px"
      bgcolor="grey.100"
    >
      {mediaTypeIcon}
    </Box>
  );
};
