import React from 'react';

type Props = {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  size: number;
};

export const SvgStaticImage: React.FC<Props> = ({ icon: IconComponent, size }) => {
  return <IconComponent width={size} height={size} />;
};
