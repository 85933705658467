import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Gap } from 'components/shared/Gap';
import { getEnvLabel } from 'utils/labels-mapping/get-env-label';
import { StyledRow } from './styled';

export const EnvCell: React.FC = () => {
  const [label] = useState(() => getEnvLabel(process.env.REACT_APP_API_BASE_URL as string));

  if (!label) {
    return null;
  }

  return (
    <>
      <Gap size="_10px" isHorizontal />
      <StyledRow>
        <Typography variant="bodyDefaultBold" color="grey.50">
          {label}
        </Typography>
      </StyledRow>
    </>
  );
};
