import React from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { preventForwardProps } from 'elements/utils';

export const StyledTooltip = elementsStyled(
  ({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} />,
  preventForwardProps(['isMobile']),
)<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    padding: '16px',
    borderRadius: '8px',
    maxWidth: isMobile ? '223px' : '353px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
}));

export const StyledTypography = elementsStyled(Typography)`
  letter-spacing: 0.18px;
`;
