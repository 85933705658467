import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Stack } from 'elements/components';
import { ReactComponent as NoCoinsIcon } from 'elements/assets/icons/noCoinsIcon.svg';
import { StyledContainer, StyledMainContent, StyledMoreIcon, StyledTextContainer } from './styled';

const NoCoins: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <StyledMainContent>
        <NoCoinsIcon />
        <StyledTextContainer>
          <Typography variant="bodyDefaultMedium" color="primary.font">
            {t('elements.features.coinsDropdown.noCoinsLabel')}
          </Typography>
          <Stack direction="row" fullWidth gap={0.5}>
            <Typography variant="captionMedium" color="secondary.font">
              {t('elements.features.coinsDropdown.noAvailableLabel')}
            </Typography>
          </Stack>
        </StyledTextContainer>
      </StyledMainContent>
      <StyledMoreIcon />
    </StyledContainer>
  );
};
export default NoCoins;
