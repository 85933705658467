import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 32,
  },
  text: { fontSize: 8, fontFamily: 'Helvetica-Bold', lineHeight: '160%', letterSpacing: 0.24 },
  dot: { height: 3, width: 3, backgroundColor: '#000', borderRadius: '50%', marginRight: 4 },
});
