import { styled } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import { pxToRem } from 'utils/styling-utils';

const PHONE_INPUT_CLASSES = {
  formControl: 'form-control',
  flagDropdown: 'flag-dropdown',
  specialLabel: 'special-label',
  reactTelInput: 'react-tel-input',
};

export const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
  padding: '0 !important',
  alignSelf: 'stretch',
  [`& .${PHONE_INPUT_CLASSES.formControl}`]: {
    width: '100%',
    backgroundColor: 'transparent',
    ...theme.typography.bodyDefaultBook,
    padding: `${pxToRem(theme.gaps._16px)} ${pxToRem(theme.gaps._12px)}`,
    border: 'none !important',
    boxShadow: 'none !important',
    [`&:-webkit-autofill`]: {
      boxShadow: `0 0 0px 1000px ${theme.palette?.background?.paper} inset !important`,
    },
  },
  [`& .${PHONE_INPUT_CLASSES.flagDropdown}`]: {
    display: 'none',
  },
  [`& .${PHONE_INPUT_CLASSES.specialLabel}`]: {
    display: 'none',
  },
}));
