import React from 'react';
import { ReactComponent as InfoIcon } from 'assets/icons/blue-info-icon.svg';
import { StyledAlert, StyledIconButton, StyledCloseIcon } from './styled';

type Props = {
  onClose: () => void;
  className?: string;
};

const FlatInfoAlert: React.FC<Props> = ({ children, onClose, className }) => {
  return (
    <StyledAlert
      severity="info"
      className={className}
      icon={<InfoIcon />}
      action={
        <StyledIconButton onClick={onClose}>
          <StyledCloseIcon />
        </StyledIconButton>
      }
    >
      {children}
    </StyledAlert>
  );
};

export default FlatInfoAlert;
