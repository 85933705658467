import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.bodyDefaultMedium,
  color: theme.palette.background.paper,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const StyledSubtitleTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.bodySmallBook,
  color: theme.palette.background.paper,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
