import { TradeFlow } from 'elements/element-trade/types';
import i18n from 'localizations';

export const pricesLabels = {
  [TradeFlow.Buy]: {
    amount: i18n.t('elements.trade.buySellPage.amountLabelBuy'),
    amountError: (currency: string, minDollarAmount: number) =>
      i18n.t('elements.trade.buySellPage.amountErrorTextBuy', { currency, minDollarAmount }),
    estimatedPrice: (coinAbbreviation: string | null) =>
      i18n.t('elements.trade.buySellPage.estimatedPriceBuy', { coinAbbreviation }),
    total: i18n.t('elements.trade.buySellPage.totalPurchaseBuy'),
  },
  [TradeFlow.Sell]: {
    amount: i18n.t('elements.trade.buySellPage.amountLabelSell'),
    amountError: () => i18n.t('elements.trade.buySellPage.amountErrorTextSell'),
    estimatedPrice: (coinAbbreviation: string | null) =>
      i18n.t('elements.trade.buySellPage.estimatedPriceSell', { coinAbbreviation }),
    total: i18n.t('elements.trade.buySellPage.totalPurchaseSell'),
  },
};

export const reviewLabels = {
  [TradeFlow.Buy]: {
    title: i18n.t('elements.trade.reviewPage.titleBuy'),
    buttonLabel: i18n.t('elements.trade.reviewPage.confirmAndSubmitButtonLabelBuy'),
  },
  [TradeFlow.Sell]: {
    title: i18n.t('elements.trade.reviewPage.titleSell'),
    buttonLabel: i18n.t('elements.trade.reviewPage.SubmitButtonLabelSell'),
  },
};

export const orderDetailsLabels = {
  [TradeFlow.Buy]: {
    estimatedPrice: (coinAbbreviation: string | null) =>
      i18n.t('elements.trade.orderDetails.estimatedPriceBuy', { coinAbbreviation }),
    totalValue: i18n.t('elements.trade.orderDetails.totalCost'),
    remark: i18n.t('elements.trade.orderDetails.remarkBuyFlow'),
  },
  [TradeFlow.Sell]: {
    estimatedPrice: (coinAbbreviation: string | null) =>
      i18n.t('elements.trade.orderDetails.estimatedPriceSell', { coinAbbreviation }),
    totalValue: i18n.t('elements.trade.orderDetails.totalSell'),
    remark: i18n.t('elements.trade.orderDetails.remarkSellFlow'),
  },
};

export const orderPageLabels = {
  [TradeFlow.Buy]: {
    title: i18n.t('elements.trade.orderPage.titleBuy'),
  },
  [TradeFlow.Sell]: {
    title: i18n.t('elements.trade.orderPage.titleSell'),
  },
};
