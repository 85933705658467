import React from 'react';
import { StandardTextFieldProps, TextField } from '@mui/material';
import { StylesHelperErrorAttentionIcon } from './styled';

type BaseInputProps = Omit<StandardTextFieldProps, 'variant' | 'hidden'> & {
  name: string;
};

export const BaseTextField: React.FC<BaseInputProps> = ({
  name,
  InputProps,
  inputProps,
  error,
  helperText,
  label,
  fullWidth = true,
  autoComplete = 'off',
  value,
  ...props
}) => (
  <TextField
    hidden
    name={name}
    value={value}
    error={error}
    label={label}
    fullWidth={fullWidth}
    autoComplete={autoComplete}
    helperText={
      helperText ? (
        <>
          {error && <StylesHelperErrorAttentionIcon />}
          {helperText}
        </>
      ) : null
    }
    InputProps={{ disableUnderline: true, ...InputProps }}
    // eslint-disable-next-line react/jsx-no-duplicate-props
    inputProps={{ autoCapitalize: 'none', ...inputProps }}
    {...props}
  />
);
