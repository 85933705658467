import { UserAllowedSubApi } from 'models/enums/user/user-allowed-sub-api';

export class SubApiCases {
  // All
  static readonly trustWallet: UserAllowedSubApi[] = [UserAllowedSubApi.trust, UserAllowedSubApi.wallet];

  static readonly trustWalletCompliance: UserAllowedSubApi[] = [
    UserAllowedSubApi.trust,
    UserAllowedSubApi.wallet,
    UserAllowedSubApi.compliance,
  ];

  // Trust Api
  static readonly trust: UserAllowedSubApi[] = [UserAllowedSubApi.trust];

  static readonly trustCompliance: UserAllowedSubApi[] = [UserAllowedSubApi.trust, UserAllowedSubApi.compliance];

  // Wallet API without Identities
  static readonly wallet: UserAllowedSubApi[] = [UserAllowedSubApi.wallet];

  // Wallet API with Identities
  static readonly walletCompliance: UserAllowedSubApi[] = [UserAllowedSubApi.wallet, UserAllowedSubApi.compliance];

  // Default permissions (everything is allowed)
  static readonly default: UserAllowedSubApi[] = [
    UserAllowedSubApi.smartContract,
    UserAllowedSubApi.trust,
    UserAllowedSubApi.wallet,
    UserAllowedSubApi.compliance,
  ];
}
