import styled from '@emotion/styled';
import { Avatar, Typography } from '@mui/material';
import { Column } from 'components/shared/Flex/Column';
import { pxToRem } from 'utils/styling-utils';

export const StyledTypography = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const StyledColumn = styled(Column)(() => ({
  justifyContent: 'center',
}));

export const StyledAvatar = styled(Avatar)(() => ({
  width: pxToRem(36),
  height: pxToRem(36),
}));
