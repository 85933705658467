import { TransactionDetailResponseModel } from 'models/response/transactions/transaction-detail-response-model';
import { getFiatAmountForCryptoTransactionDetailsPage } from 'utils/transactions/get-fiat-amount-for-crypto-transaction-details-page';
import { LabelLength } from 'ui-enums/label-length';
import { getSignOfTransaction } from 'utils/transactions/get-sign-of-transaction';
import { getDynamicAssetLabel } from 'utils/labels-mapping/get-dynamic-asset-label';

export const getPdfAmountForCryptoSell = (transaction: TransactionDetailResponseModel): string => {
  const label = transaction.cryptoAmount
    ? `${getSignOfTransaction(transaction)}${transaction.cryptoAmount} ${getDynamicAssetLabel(
        LabelLength.short,
        transaction.assetTicker,
        transaction.assetName,
      )}`
    : '';

  const subLabel = getFiatAmountForCryptoTransactionDetailsPage(transaction);

  if (subLabel) {
    return `${label} / ${subLabel}`;
  }

  return label;
};
