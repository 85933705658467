import React from 'react';
import { Typography } from '@mui/material';
import { Stack, Tooltip } from 'elements/components';
import { StyledIconButton, StyledInfoIcon } from './styled';

type Props = {
  text: string;
  tooltipTitle: string;
  tooltipTexts: Array<string>;
};

const TextWithTooltipInfo: React.FC<Props> = ({ text, tooltipTitle, tooltipTexts }) => {
  return (
    <Stack direction="row" gap="4px">
      <Typography variant="bodySmallBook" color="secondary.font">
        {text}
      </Typography>
      <Tooltip title={tooltipTitle} texts={tooltipTexts}>
        <StyledIconButton disableRipple>
          <StyledInfoIcon />
        </StyledIconButton>
      </Tooltip>
    </Stack>
  );
};

export default TextWithTooltipInfo;
