import { UserLimitModel } from 'models/response/users/user-limit-model';
import { UserLimitType } from 'models/enums/user/user-limit-type';
import { LimitsGroup } from './limits-group';

export const keyFunc = (el: UserLimitModel): LimitsGroup => {
  switch (el.type) {
    case UserLimitType.approveAmount:
    case UserLimitType.approveCount:
      return LimitsGroup.approve;
    case UserLimitType.initAmount:
    case UserLimitType.initCount:
      return LimitsGroup.initiate;
    default:
      return LimitsGroup.approve;
  }
};
