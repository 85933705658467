import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { StyledBackdrop } from './styled';

export type Props = {
  isDisplayed: boolean;
};

const Loader: React.FC<Props> = ({ isDisplayed }) => (
  <StyledBackdrop open={isDisplayed}>
    <CircularProgress color="inherit" thickness={4} size={42} />
  </StyledBackdrop>
);

export default Loader;
