import React from 'react';
import VerificationContent from './Content';
import ResponsiveVerificationLayout from './Layout';
import { AccountVerificationProps } from './types';

const VerificationLayout: React.FC<AccountVerificationProps> = ({
  onBack,
  title,
  subtitle,
  onLetsGo,
  contactUsHref,
  isTertiaryButtonShown = true,
  pageTitle,
}) => (
  <ResponsiveVerificationLayout
    pageTitle={pageTitle}
    contactUsHref={contactUsHref}
    isTertiaryButtonShown={isTertiaryButtonShown}
    onBack={onBack}
    onLetsGo={onLetsGo}
  >
    <VerificationContent title={title} subtitle={subtitle} />
  </ResponsiveVerificationLayout>
);

export default VerificationLayout;
