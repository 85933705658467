import i18n from 'localizations';
import { ElementName } from 'elements/models/enums/element-name';

export const getButtonTextByElementName = (elementName: ElementName) => {
  switch (elementName) {
    case ElementName.TRANSFER:
      return i18n.t('elementButton.transfer');
    case ElementName.TRADE:
      return i18n.t('elementButton.trade');
    default:
      return '';
  }
};
