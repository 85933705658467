import React, { useState } from 'react';
import { Box, Collapse } from '@mui/material';
import { StyledContainer } from './styled';
import FlatInfoAlert from '../FlatInfoAlert';

const InlineInfoBanner: React.FC = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const onClose = () => {
    setIsExpanded(false);
  };

  return (
    <Box>
      <Collapse in={isExpanded}>
        <StyledContainer>
          <FlatInfoAlert onClose={onClose}>{children}</FlatInfoAlert>
        </StyledContainer>
      </Collapse>
    </Box>
  );
};

export default InlineInfoBanner;
