import { Auth0ClientSingleton } from 'auth0/auth0-client-singleton';

export interface IHeader {
  headers: {
    Authorization: string;
  };
}

export const prepareHeader = async () => {
  const auth0 = await Auth0ClientSingleton.getInstance();
  const token = await auth0.getTokenSilently();

  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};
