import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormGroup, RadioGroup, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { ReactComponent as CloseIcon } from 'assets/icons/grey-close-icon.svg';
import { Gap } from 'components/shared/Gap';
import useForm from 'hooks/use-form';
import { PhoneField } from 'components/shared/inputs/PhoneField';
import { BaseTextField } from 'components/shared/inputs/BaseTextField';
import { Row } from 'components/shared/Flex/Row';
import { useYupRules } from 'hooks/use-yup-rules';
import { LoadingButtonWithSkeleton } from 'components/shared/buttons/LoadingButtonWithSkeleton';
import { RadioGroupLabel } from 'components/shared/Radio';
import { RangeType } from 'models/enums/user/range-type';
import { CheckboxGroupLabel } from 'components/shared/Checkbox';
import { UserLimitType } from 'models/enums/user/user-limit-type';
import { IRoleTemplatesInputs } from 'ui-interfaces/user-form/i-role-templates-inputs';
import { IPermissionsCheckbox } from 'ui-interfaces/user-form/i-permissions-checkbox';
import { ILimitInput } from 'ui-interfaces/user-form/i-limit-input';
import { CreateUserRequestModel } from 'models/request/users/create-user-request-model';
import ErrorAlert from 'components/shared/user-form/ErrorAlert';
import { RoleRadio } from 'components/shared/user-form/RoleRadio';
import { PermissionsCheckbox } from 'components/shared/user-form/PermissionsCheckbox';
import { Limit } from 'components/shared/user-form/Limit';
import {
  StyledCheckboxFormControl,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledDivider,
  StyledIconButton,
  StyledLink,
  StyledRadioFormControl,
  StyledRow,
  StyledTypography,
} from './styled';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onUserCreate: (values: CreateUserRequestModel) => void;
  isLoading: boolean;
  errorMessage?: string;
  roleTemplatesInputs: IRoleTemplatesInputs<Pick<CreateUserRequestModel, 'roleTemplate' | 'permissions' | 'limits'>>;
};

export const CreateUserDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  onUserCreate,
  isLoading,
  errorMessage,
  roleTemplatesInputs,
}) => {
  const { t } = useTranslation();
  const { firstNameRule, lastNameRule, emailRuleWithPattern, phoneRule } = useYupRules();
  const {
    handleSubmit,
    formikHelpers: { getFormikProps, withOnChangeStringParameter },
    submitForm,
    formik: { resetForm, isValid: isFormValid, validateForm },
  } = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    validateOnChange: true,
    yupSchema: {
      firstName: firstNameRule,
      lastName: lastNameRule,
      email: emailRuleWithPattern,
      phone: phoneRule,
    },
    onSubmit: (values, { setSubmitting }) => {
      const roleTemplatesInputsState: Pick<CreateUserRequestModel, 'roleTemplate' | 'permissions' | 'limits'> =
        roleTemplatesInputs.getState();
      const formikState = values as Pick<CreateUserRequestModel, 'firstName' | 'lastName' | 'email' | 'phone'>;
      formikState.phone = `+${formikState.phone}`;
      onUserCreate({ ...formikState, ...roleTemplatesInputsState } as CreateUserRequestModel);
      setSubmitting(false);
    },
  });
  const phoneFormikProps = withOnChangeStringParameter(getFormikProps('phone'));
  const firstNameFormikProps = getFormikProps('firstName');
  const lastNameFormikProps = getFormikProps('lastName');
  const emailFormikProps = getFormikProps('email');

  // ui state (roleTemplatesInputs) - start
  const [roleIndex, setRoleIndex] = useState<number>(() => roleTemplatesInputs.selectedUserRoleTemplateIndex);

  const [permissions, setPermissions] = useState<IPermissionsCheckbox[]>(() => roleTemplatesInputs.permissions);

  const [approveLimits, setApproveLimits] = useState<ILimitInput[]>(
    () => roleTemplatesInputs.approveLimitsCollection.items,
  );

  const [initLimits, setInitLimits] = useState<ILimitInput[]>(() => roleTemplatesInputs.initLimitsCollection.items);

  const [approveLimitsErrorMessage, setApproveLimitsErrorMessage] = useState<string | undefined>(
    () => roleTemplatesInputs.approveLimitsCollection.errorMessage,
  );

  const [initLimitsErrorMessage, setInitLimitsErrorMessage] = useState<string | undefined>(
    () => roleTemplatesInputs.initLimitsCollection.errorMessage,
  );
  // ui state (roleTemplatesInputs) - end

  // roleTemplatesInputs handlers - start
  const onRoleIndexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    roleTemplatesInputs.selectedUserRoleTemplateIndex = parseInt(e.target.value, 10);
    setRoleIndex(roleTemplatesInputs.selectedUserRoleTemplateIndex);
    setPermissions(roleTemplatesInputs.permissions);
    setApproveLimits(roleTemplatesInputs.approveLimitsCollection.items);
    setInitLimits(roleTemplatesInputs.initLimitsCollection.items);
  };

  const onPermissionsCheckboxChange = (val: string) => {
    roleTemplatesInputs.selectPermission(parseInt(val, 10));
    setPermissions(roleTemplatesInputs.permissions);
    setApproveLimits(roleTemplatesInputs.approveLimitsCollection.items);
    setInitLimits(roleTemplatesInputs.initLimitsCollection.items);
  };

  // approve limits - start
  const onApproveLimitDelete = (id: string) => {
    roleTemplatesInputs.approveLimitsCollection.remove(id);
    setApproveLimits(roleTemplatesInputs.approveLimitsCollection.items);
    roleTemplatesInputs.approveLimitsCollection.validate();
    setApproveLimitsErrorMessage(roleTemplatesInputs.approveLimitsCollection.errorMessage);
  };

  const onApproveLimitTypeValueChange = (limit: ILimitInput, val: SelectChangeEvent<UserLimitType>) => {
    limit.typeValue = val.target.value as UserLimitType;
    setApproveLimits(roleTemplatesInputs.approveLimitsCollection.items);
    roleTemplatesInputs.approveLimitsCollection.validate();
    setApproveLimitsErrorMessage(roleTemplatesInputs.approveLimitsCollection.errorMessage);
  };

  const onApproveLimitMaxValueChange = (
    limit: ILimitInput,
    val: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    limit.maxValue = val.target.value;
    setApproveLimits(roleTemplatesInputs.approveLimitsCollection.items);
    roleTemplatesInputs.approveLimitsCollection.validate();
    setApproveLimitsErrorMessage(roleTemplatesInputs.approveLimitsCollection.errorMessage);
  };

  const onApproveLimitRangeValueChange = (limit: ILimitInput, val: SelectChangeEvent<RangeType>) => {
    limit.rangeValue = val.target.value as RangeType;
    setApproveLimits(roleTemplatesInputs.approveLimitsCollection.items);
    roleTemplatesInputs.approveLimitsCollection.validate();
    setApproveLimitsErrorMessage(roleTemplatesInputs.approveLimitsCollection.errorMessage);
  };

  const onApproveLimitAdd = () => {
    roleTemplatesInputs.approveLimitsCollection.add();
    setApproveLimits(roleTemplatesInputs.approveLimitsCollection.items);
  };
  // approve limits - end

  // init limits - start
  const onInitLimitDelete = (id: string) => {
    roleTemplatesInputs.initLimitsCollection.remove(id);
    setInitLimits(roleTemplatesInputs.initLimitsCollection.items);
    roleTemplatesInputs.initLimitsCollection.validate();
    setInitLimitsErrorMessage(roleTemplatesInputs.initLimitsCollection.errorMessage);
  };

  const onInitLimitTypeValueChange = (limit: ILimitInput, val: SelectChangeEvent<UserLimitType>) => {
    limit.typeValue = val.target.value as UserLimitType;
    setInitLimits(roleTemplatesInputs.initLimitsCollection.items);
    roleTemplatesInputs.initLimitsCollection.validate();
    setInitLimitsErrorMessage(roleTemplatesInputs.initLimitsCollection.errorMessage);
  };

  const onInitLimitMaxValueChange = (
    limit: ILimitInput,
    val: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    limit.maxValue = val.target.value;
    setInitLimits(roleTemplatesInputs.initLimitsCollection.items);
    roleTemplatesInputs.initLimitsCollection.validate();
    setInitLimitsErrorMessage(roleTemplatesInputs.initLimitsCollection.errorMessage);
  };

  const onInitLimitRangeValueChange = (limit: ILimitInput, val: SelectChangeEvent<RangeType>) => {
    limit.rangeValue = val.target.value as RangeType;
    setInitLimits(roleTemplatesInputs.initLimitsCollection.items);
    roleTemplatesInputs.initLimitsCollection.validate();
    setInitLimitsErrorMessage(roleTemplatesInputs.initLimitsCollection.errorMessage);
  };

  const onInitLimitAdd = () => {
    roleTemplatesInputs.initLimitsCollection.add();
    setInitLimits(roleTemplatesInputs.initLimitsCollection.items);
  };
  // init limits - end
  // roleTemplatesInputs handlers - end

  useEffect(() => {
    if (isOpen) {
      resetForm();
      validateForm();
      roleTemplatesInputs.reset();
      // sync ui state with view model
      setRoleIndex(roleTemplatesInputs.selectedUserRoleTemplateIndex);
      setPermissions(roleTemplatesInputs.permissions);
      setApproveLimits(roleTemplatesInputs.approveLimitsCollection.items);
      setInitLimits(roleTemplatesInputs.initLimitsCollection.items);
      setApproveLimitsErrorMessage(roleTemplatesInputs.approveLimitsCollection.errorMessage);
      setInitLimitsErrorMessage(roleTemplatesInputs.initLimitsCollection.errorMessage);
    }
  }, [isOpen, resetForm, roleTemplatesInputs, validateForm]);

  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <StyledDialogTitle variant="h5">
        {t('createUserDialog.title')}
        <StyledIconButton onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </StyledDialogTitle>
      {(approveLimitsErrorMessage || initLimitsErrorMessage || errorMessage) && (
        <>
          <Gap size="_24px" />
          <ErrorAlert>{approveLimitsErrorMessage || initLimitsErrorMessage || errorMessage}</ErrorAlert>
        </>
      )}
      <StyledDialogContent>
        <form onSubmit={handleSubmit}>
          <Gap size="_24px" />
          <Row>
            <BaseTextField {...firstNameFormikProps} label={t('createUserDialog.firstNameFieldLabel')} />
            <Gap isHorizontal size="_16px" />
            <BaseTextField {...lastNameFormikProps} label={t('createUserDialog.lastNameFieldLabel')} />
          </Row>
          <Gap size="_20px" />
          <BaseTextField {...emailFormikProps} label={t('createUserDialog.emailFieldLabel')} />
          <Gap size="_20px" />
          <PhoneField {...phoneFormikProps} label={t('createUserDialog.phoneNumberFieldLabel')} />
          <Gap size="_32px" />
          <StyledRadioFormControl>
            <RadioGroupLabel>{t('createUserDialog.roleTemplateFieldLabel')}</RadioGroupLabel>
            <Gap size="_8px" />
            <RadioGroup value={roleIndex} onChange={onRoleIndexChange}>
              {roleTemplatesInputs.roles.map(r => {
                return <RoleRadio key={r.id} data={r} />;
              })}
            </RadioGroup>
          </StyledRadioFormControl>
          <Gap size="_24px" />
          <StyledCheckboxFormControl>
            <CheckboxGroupLabel>{t('createUserDialog.permissionsFieldLabel')}</CheckboxGroupLabel>
            <Gap size="_8px" />
            <FormGroup>
              {permissions.map(p => {
                return (
                  <PermissionsCheckbox
                    key={p.id}
                    data={p}
                    isChecked={roleTemplatesInputs.checkedPermissions.has(p.value)}
                    onChange={onPermissionsCheckboxChange}
                  />
                );
              })}
            </FormGroup>
          </StyledCheckboxFormControl>
          {(approveLimits.length > 0 || initLimits.length > 0) && (
            <>
              <Gap size="_24px" />
              <Typography variant="h6">{t('createUserDialog.transactionLimitsSectionLabel')}</Typography>
              <Gap size="_24px" />
            </>
          )}
          {approveLimits.length > 0 && (
            <>
              <StyledTypography>{t('createUserDialog.approveLimitSectionLabel')}</StyledTypography>
              <Gap size="_16px" />
            </>
          )}
          {approveLimits.map((l, index, arr) => (
            <Limit
              key={l.id}
              id={l.id}
              onDelete={() => {
                onApproveLimitDelete(l.id);
              }}
              isDeletable={approveLimits.length > 1}
              possibleTypeValues={[UserLimitType.approveAmount, UserLimitType.approveCount]}
              possibleTypeValueWordings={[
                t('createUserDialog.amountLimitTypeOption'),
                t('createUserDialog.countLimitTypeOption'),
              ]}
              typeValue={l.typeValue}
              onTypeValueChange={val => {
                onApproveLimitTypeValueChange(l, val);
              }}
              maxValue={l.maxValue}
              onMaxValueChange={val => {
                onApproveLimitMaxValueChange(l, val);
              }}
              rangeValue={l.rangeValue}
              onRangeValueChange={val => {
                onApproveLimitRangeValueChange(l, val);
              }}
              limitInputType={l.limitInputType}
              isLastLimit={index === arr.length - 1}
            />
          ))}
          {approveLimits.length > 0 && !roleTemplatesInputs.approveLimitsCollection.isFull() && (
            <>
              <Gap size="_32px" />
              <StyledRow>
                <StyledLink onClick={onApproveLimitAdd}>
                  {t('createUserDialog.addApproveLimitSectionButtonLabel')}
                </StyledLink>
              </StyledRow>
            </>
          )}
          {approveLimits.length > 0 && <Gap size="_32px" />}
          {initLimits.length > 0 && (
            <>
              <StyledTypography>{t('createUserDialog.initCountLimitSectionLabel')}</StyledTypography>
              <Gap size="_16px" />
            </>
          )}
          {initLimits.map((l, index, arr) => (
            <Limit
              key={l.id}
              id={l.id}
              onDelete={() => {
                onInitLimitDelete(l.id);
              }}
              isDeletable={initLimits.length > 1}
              possibleTypeValues={[UserLimitType.initAmount, UserLimitType.initCount]}
              possibleTypeValueWordings={[
                t('createUserDialog.amountLimitTypeOption'),
                t('createUserDialog.countLimitTypeOption'),
              ]}
              typeValue={l.typeValue}
              onTypeValueChange={val => {
                onInitLimitTypeValueChange(l, val);
              }}
              maxValue={l.maxValue}
              onMaxValueChange={val => {
                onInitLimitMaxValueChange(l, val);
              }}
              rangeValue={l.rangeValue}
              onRangeValueChange={val => {
                onInitLimitRangeValueChange(l, val);
              }}
              limitInputType={l.limitInputType}
              isLastLimit={index === arr.length - 1}
            />
          ))}
          {initLimits.length > 0 && !roleTemplatesInputs.initLimitsCollection.isFull() && (
            <>
              <Gap size="_32px" />
              <StyledRow>
                <StyledLink onClick={onInitLimitAdd}>
                  {t('createUserDialog.addInitCountLimitSectionButtonLabel')}
                </StyledLink>
              </StyledRow>
            </>
          )}
          {initLimits.length > 0 && <Gap size="_32px" />}
          {approveLimits.length > 0 && initLimits.length > 0 && <StyledDivider />}
        </form>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" size="large" onClick={onClose}>
          <Typography variant="buttonMedium">{t('createUserDialog.cancelButtonLabel')}</Typography>
        </Button>
        <Gap isHorizontal size="_16px" />
        <LoadingButtonWithSkeleton
          variant="contained"
          size="large"
          text={
            <Typography variant="buttonMedium" color="background.paper">
              {t('createUserDialog.addButtonLabel')}
            </Typography>
          }
          loading={isLoading}
          disabled={!isFormValid}
          onClick={submitForm}
        />
      </StyledDialogActions>
    </StyledDialog>
  );
};
