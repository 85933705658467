import React from 'react';
import { CryptoAssetsGrid } from './CryptoAssetsGrid';
//  TODO: Reveal when wallet transaction feature will be ready for release
// import { CryptoAssetsTransactionsGrid } from './CryptoAssetsTransactionsGrid';

type Props = {
  walletId: string;
  setIsDetailsPageError: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CryptoAssetsTab: React.FC<Props> = ({ walletId, setIsDetailsPageError }) => (
  <>
    <CryptoAssetsGrid walletId={walletId} setIsDetailsPageError={setIsDetailsPageError} />
    {/* TODO: Reveal when wallet transaction feature will be ready for release */}
    {/* <CryptoAssetsTransactionsGrid /> */}
  </>
);
