import React from 'react';
import { Radio } from '@mui/material';
import { RadioFormControlLabel, RadioLabel } from 'components/shared/Radio';
import { IRoleRadio } from 'ui-interfaces/user-form/i-role-radio';

type Props = {
  data: IRoleRadio;
};

export const RoleRadio: React.FC<Props> = ({ data }) => {
  return (
    <RadioFormControlLabel
      value={data.value}
      control={<Radio />}
      label={<RadioLabel title={data.title} description={data.description} />}
    />
  );
};
