import { FormControl, Radio, RadioGroup, css } from '@mui/material';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { preventForwardProps } from 'elements/utils';

export const StyledFormControl = elementsStyled(FormControl)`
  gap: 16px;
`;

export const StyledRadioGroup = elementsStyled(
  RadioGroup,
  preventForwardProps(['withHover']),
)<{ withHover?: boolean }>(
  ({ theme, withHover }) => css`
    gap: 8px;

    ${withHover &&
    css`
      & .MuiFormControlLabel-root:hover {
        border-radius: 6px;
        background-color: ${theme.palette.secondary.mainTransparent10};
      }
    `}
  `,
);

export const StyledRadio = elementsStyled(Radio)(
  ({ theme }) => css`
    &.MuiRadio-root:hover {
      background-color: transparent;
    }

    padding: 0 9px;
    color: ${theme.palette.tertiary.main};
    &.Mui-checked {
      color: ${theme.palette.secondary.main};
    }
  `,
);
