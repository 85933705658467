import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLazyGetCustodialAccountsQuery } from 'redux/api/api-custodial-accounts';
import { useExport } from 'hooks/use-export';
import { CustodialAccountViewModel } from 'view-models/custodial-accounts/custodial-account-vm';
import { Filterable } from 'ui-interfaces/shared/filters/filterable';
import { CustodialAccountsListFiltersViewModel } from 'view-models/custodial-accounts/filters/custodial-accounts-list-filters-vm';
import { FilterResult } from 'filters/interfaces/filter-result';
import { CustomizedGrid } from 'components/shared/grids/CustomizedGrid';
import { onCustodialAccountRowClick, onCustodialAccountTransform } from '../handlers';
import { columnsCustodialAccounts } from '../columns-definitions';
import { onCustodialAccountsExportClick } from './handlers';

export const CustodialAccountsList: React.FC = () => {
  const navigate = useNavigate();
  const { downloadCsv, isLoading: isExportLoading, error: exportError } = useExport();
  const [getCustodialAccountsTrigger, custodialAccountsResult] = useLazyGetCustodialAccountsQuery();

  const fetchCustodialAccounts = useCallback(
    (page?: number, filterResult?: FilterResult) => getCustodialAccountsTrigger({ page, filterResult }, false),
    [getCustodialAccountsTrigger],
  );

  const [rows, setRows] = useState<CustodialAccountViewModel[]>([]);
  const [resourceCount, setResourceCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(custodialAccountsResult.isUninitialized);
  const [filterable] = useState<Filterable>(() => new CustodialAccountsListFiltersViewModel());

  useEffect(() => {
    fetchCustodialAccounts();
  }, [fetchCustodialAccounts]);

  useEffect(() => {
    if (custodialAccountsResult.isSuccess && custodialAccountsResult.data) {
      const transformedItems = custodialAccountsResult.data.data.map(onCustodialAccountTransform);
      setRows(transformedItems);
      setResourceCount(custodialAccountsResult.data.meta?.resourceCount ?? 0);
      setLoading(custodialAccountsResult.isFetching);
    }
  }, [custodialAccountsResult.data, custodialAccountsResult.isFetching, custodialAccountsResult.isSuccess]);

  const { t } = useTranslation();

  return (
    <CustomizedGrid
      columns={columnsCustodialAccounts}
      headerName={t('custodialAccountsPage.gridTitle')}
      filters={filterable.filters}
      selectionMacroCommand={filterable.selectionMacroCommand}
      noResultsTitle={t('custodialAccountsPage.noResultsTitle')}
      noResultsSubtitle={t('custodialAccountsPage.noResultsSubtitle')}
      containerHeight="100%"
      rows={rows}
      rowCount={resourceCount}
      isLoading={loading}
      error={custodialAccountsResult.error || exportError}
      fetchOtherItems={fetchCustodialAccounts}
      onGridRowClick={onCustodialAccountRowClick(navigate)}
      onExportClick={onCustodialAccountsExportClick(downloadCsv)}
      isExportLoading={isExportLoading}
    />
  );
};
