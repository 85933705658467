import i18n from 'localizations';
import { FilterValueSet } from 'filters/interfaces/filter-value-set';
import { CustodialAccountType } from 'models/enums/custodial-accounts/custodial-account-type';
import { Currency } from 'ui-enums/response/transactions/currency';
import { GenericValueSets } from 'filters/common-value-sets/base/generic-value-sets';
import { CustodialAccountStatus } from 'models/enums/custodial-accounts/custodial-account-status';

export class AccountsValueSets extends GenericValueSets {
  static readonly custodialAccountTypeValueSet: FilterValueSet[] = [
    {
      value: CustodialAccountType.personal,
      displayValue: i18n.t('custodialAccountsPage.type.personal'),
    },
    {
      value: CustodialAccountType.business,
      displayValue: i18n.t('custodialAccountsPage.type.business'),
    },
    {
      value: CustodialAccountType.rothIra,
      displayValue: i18n.t('custodialAccountsPage.type.rothIra'),
    },
    {
      value: CustodialAccountType.traditionalIra,
      displayValue: i18n.t('custodialAccountsPage.type.traditionalIra'),
    },
    {
      value: CustodialAccountType.sepIra,
      displayValue: i18n.t('custodialAccountsPage.type.sepIra'),
    },
    {
      value: CustodialAccountType.simpleIra,
      displayValue: i18n.t('custodialAccountsPage.type.simpleIra'),
    },
  ];

  static get currencyValueSet(): FilterValueSet[] {
    return GenericValueSets.currencyValueSet.reduce((accumulator, currentValue) => {
      if (currentValue.value === Currency.bnb) {
        accumulator.push({
          // workaround for BE bug https://fortressgroup.atlassian.net/browse/ID-1748
          value: 'bnbBnb',
          displayValue: i18n.t('cryptoAssets.bnb.long'),
        });
        return accumulator;
      }

      accumulator.push(currentValue);
      return accumulator;
    }, [] as FilterValueSet[]);
  }

  static readonly statusValueSet: FilterValueSet[] = [
    {
      value: CustodialAccountStatus.open,
      displayValue: i18n.t('custodialAccountsPage.statuses.open'),
    },
    {
      value: CustodialAccountStatus.closed,
      displayValue: i18n.t('custodialAccountsPage.statuses.closed'),
    },
    {
      value: CustodialAccountStatus.suspended,
      displayValue: i18n.t('custodialAccountsPage.statuses.suspended'),
    },
  ];
}
