import React from 'react';

import { UserBaseResponseModel } from 'models/response/users/user-base-response-model';
import { ActionCellAction } from 'ui-interfaces/users/action-cell-action';
import { Action } from 'ui-enums/users/action-cell-type';
import { ReactComponent as EditIcon } from 'assets/icons/settings/user-action/edit.svg';
import i18n from 'localizations';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { UserRoleTemplateResponseModel } from 'models/response/users/user-role-template-response-model';
import { RoleTemplate } from 'models/enums/user/role-template';

export class EditUserActionViewModel implements ActionCellAction {
  type = Action.edit;

  icon = (<EditIcon />);

  label = i18n.t('settingsPage.grid.actions.edit');

  isDisplayed = false;

  constructor(
    user: UserBaseResponseModel,
    currentUser: ShortUserResponseModel,
    userRoleTemplates?: UserRoleTemplateResponseModel[],
  ) {
    this.isDisplayed = EditUserActionViewModel.calculateIsDisplayed(user, currentUser, userRoleTemplates);
  }

  static calculateIsDisplayed(
    user: UserBaseResponseModel,
    currentUser: ShortUserResponseModel,
    userRoleTemplates?: UserRoleTemplateResponseModel[],
  ) {
    const isCurrentUser = user.id === currentUser.id;
    const roleTemplatesOfEditableUsers = userRoleTemplates
      ? userRoleTemplates.map(({ roleTemplate }) => roleTemplate)
      : [];
    const isEditAllowed = roleTemplatesOfEditableUsers.includes(user.roleTemplate);

    switch (true) {
      case isCurrentUser: {
        return true;
      }

      case user.roleTemplate === RoleTemplate.owner: {
        return false;
      }

      case user.roleTemplate === RoleTemplate.admin:
      case user.roleTemplate === RoleTemplate.editor:
      case user.roleTemplate === RoleTemplate.viewer: {
        if (user.isActive) {
          return isEditAllowed;
        }

        return false;
      }

      default:
        return isEditAllowed;
    }
  }
}
