import React from 'react';
import { RowBox } from 'components/shared/detail-page/rows/shared/RowBox';
import { LoadingLeftCell } from './LoadingLeftCell';
import { LoadingRightCell } from './LoadingRightCell';

type Props = {
  hasBottomBorder: boolean;
};

export const LoadingRow: React.FC<Props> = ({ hasBottomBorder = false }) => {
  return (
    <RowBox hasBottomBorder={hasBottomBorder}>
      <LoadingLeftCell />
      <LoadingRightCell />
    </RowBox>
  );
};
