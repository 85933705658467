import { Input } from 'ui-interfaces/shared/inputs/input';
import { CustodialAccountLabel } from 'models/enums/custodial-accounts/custodial-account-label';
import { CaNamingInput } from 'ui-enums/custodial-accounts/inputs/ca-naming-input';
import { CaNamingInputParamsViewModel } from 'ui-interfaces/custodial-accounts-details/params/ca-naming-input-params-vm';

export class CaLabelInputViewModel implements Input<CustodialAccountLabel, CaNamingInput> {
  readonly id: string;

  readonly uiElementType: CaNamingInput;

  value: CustodialAccountLabel;

  isValid = true;

  isTouched = false;

  errorMessage: string | undefined;

  private readonly initialValue: CustodialAccountLabel;

  constructor(params: CaNamingInputParamsViewModel<CustodialAccountLabel>) {
    this.id = params.id;

    this.uiElementType = params.uiElementType;

    this.value = params.value;
    this.initialValue = params.value;
  }

  setValue(value: CustodialAccountLabel): void {
    this.value = value;
    this.isTouched = true;
    this.validate();
  }

  hasValueChanged(): boolean {
    return this.value !== this.initialValue;
  }

  validate(): void {
    if (this.value) {
      this.isValid = true;
    }
  }

  reset(): void {
    this.value = this.initialValue;
    this.isValid = true;
    this.isTouched = false;
    this.errorMessage = undefined;
  }
}
