import React from 'react';
import { StyledContainer } from './styled';
import { ImageLoadingSkeleton } from './ImageLoadingSkeleton';
import { TextLoadingSkeleton } from './TextLoadingSkeleton';

export const ImageLoadingRow: React.FC = () => {
  return (
    <StyledContainer>
      <ImageLoadingSkeleton />
      <TextLoadingSkeleton />
    </StyledContainer>
  );
};
