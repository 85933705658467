import React from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumbs, { BreadCrumbsItems } from 'components/shared/Breadcrumbs';
import { CommonLayoutContainer, CommonLayoutContent, CommonLayoutHeader } from 'components/shared/layouts/CommonLayout';
import { Gap } from 'components/shared/Gap';
import { CommonErrorLayout } from 'components/shared/layouts/CommonErrorLayout';
import { HeaderBox } from 'components/shared/detail-page/HeaderBox';
import { StyledTab } from 'components/shared/Tabs';
import { StyledTabsBox } from 'components/identities/styled';
import { IdentitiesDetailsPageTabs } from 'ui-enums/tabs/identities/identities-details-page-tabs';

type Props = {
  breadCrumbs: BreadCrumbsItems;
  tab: IdentitiesDetailsPageTabs;
  handleChange: (event: React.SyntheticEvent, newTab: IdentitiesDetailsPageTabs) => void;
  isCustodialAccountsTabShown?: boolean;
  isWalletsTabShown?: boolean;
};

export const Error: React.FC<Props> = ({
  breadCrumbs,
  tab,
  handleChange,
  isCustodialAccountsTabShown,
  isWalletsTabShown,
}) => {
  const { t } = useTranslation();
  return (
    <CommonLayoutContainer>
      <CommonLayoutHeader>
        <HeaderBox>
          <Breadcrumbs items={breadCrumbs} />
        </HeaderBox>
        <StyledTabsBox value={tab} onChange={handleChange}>
          <Gap isHorizontal size="_32px" />
          <StyledTab label={t('identityDetailsPage.tabs.detailsTab')} value={IdentitiesDetailsPageTabs.details} />
          {isCustodialAccountsTabShown && (
            <StyledTab
              label={t('identityDetailsPage.tabs.accountsTab')}
              value={IdentitiesDetailsPageTabs.custodialAccounts}
            />
          )}
          {isWalletsTabShown && (
            <StyledTab label={t('identityDetailsPage.tabs.walletsTab')} value={IdentitiesDetailsPageTabs.wallets} />
          )}
        </StyledTabsBox>
      </CommonLayoutHeader>
      <CommonLayoutContent>
        <CommonErrorLayout />
      </CommonLayoutContent>
    </CommonLayoutContainer>
  );
};
