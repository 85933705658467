import React from 'react';
import { Typography } from '@mui/material';
import { RightCellBox } from 'components/shared/detail-page/rows/shared/RightCellBox';
import { Gap } from 'components/shared/Gap';
import { FileType } from 'ui-enums/file-type';
import { getFileTypeIcon } from 'utils/wallets/token-details/get-file-type-icon';

type Props = {
  value: string;
  mediaType: FileType;
};

export const RightCell: React.FC<Props> = ({ mediaType, value }) => {
  return (
    <RightCellBox>
      {getFileTypeIcon(mediaType)}
      <Gap size="_8px" isHorizontal />
      <Typography color="grey.600" variant="bodyDefaultBook">
        {value}
      </Typography>
    </RightCellBox>
  );
};
